import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, Row, UncontrolledAlert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";

import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";
import Camera_icon from "./../../../assets/icons/ebud-icons/Camera.svg";

import { checkUserPermission, convertDate } from "../../../helpers/utils";

import Block_icon from "../../../assets/icons/ebud-icons/Block.svg";
import UnBlock_icon from "../../../assets/icons/ebud-icons/Unblock.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import Avatar from "../../../assets/images/users/user.svg";
import ProfileAvatar from "../../../assets/images/users/Profile_avatar.png";

import { DATE_REGEX } from "../../../helpers/constants";

import {
  getUserDetails,
  blockUser,
  deleteUser,
  updateUserData,
  resetErrors,
} from "../../../store/users/actions";
import FileChooseAlert from "./FileChooseModal";
import { Dropdown, Flag } from "semantic-ui-react";
import { countryOptions } from "../../../components/UI/Countries";

const EditUserDetail = () => {
  const match = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [imageChoosePopUp, setImageChoosePopUp] = useState(false);
  const toggle = () => setShowPromptPopUp(!showPromptPopUp);
  const imageChoosetoggle = () => setImageChoosePopUp(!imageChoosePopUp);
  const [promptMessage, setPromptMessage] = useState({});
  const [date, setDate] = useState("");
  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [dateError, setDateError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [formChanged, setFormChanged] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [backToListing, setBackToListing] = useState(false);
  const [removeImage, setRemoveImage] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);
  const [countryCode, setCountryCode] = useState("+91");

  const { t } = useTranslation();

  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isDeleteable = userPermissions?.delete || false;
  const isBlockable = userPermissions?.edit || false;
  const [imageFile, setImageFile] = useState("");
  const [profilePic, setProfilePic] = useState("");

  const data = useSelector((state) => state.user);

  let handleCountryChange = (e, data) => {
    setCountryCode(data?.value);
  };

  useEffect(() => {
    dispatch(getUserDetails(match.id));
  }, [match.id, dispatch]);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Users"
        )
      );
    }
  }, [loginData]);

  useEffect(() => {
    setName(data?.userDetails?.name);
    setAbout(data?.userDetails?.about);
    setProfilePic(data?.userDetails?.profile_photo || Avatar);
    setDate(convertDate(data?.userDetails?.dob, "-"));
    setGender(data?.userDetails?.gender);
    setCitizenship(data?.userDetails?.citizenship);
    if (!data?.userDetails?.dob || !data?.userDetails?.gender)
      setIsButtonDisabled(true);
  }, [data?.userDetails]);

  useEffect(() => {
    let currentTime = new Date().getTime();
    let dateTime = new Date(date).getTime();

    if (
      date &&
      new RegExp(DATE_REGEX).test(date) &&
      currentTime > dateTime &&
      new Date(date).getFullYear() >= 1900 &&
      gender &&
      name &&
      citizenship
    )
      setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  }, [date, gender, name, citizenship]);

  useEffect(() => {
    backToListing &&
      history.push({
        pathname:
          history?.location?.state?.from === "userList"
            ? `/users`
            : `/user-details/${match.id}`,
        state: { from: "keepFilter", userUpdated: updated },
      });
  }, [backToListing, match.id, history]);

  useEffect(() => {
    scrollToAlertMessage();
  }, [dateError, genderError, typeof data?.error === "string" && data?.error]);

  const scrollToAlertMessage = () => {
    if (
      dateError ||
      genderError ||
      (typeof data?.error === "string" && data?.error)
    ) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  const deletePromptHandler = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("UserManagement.deleteConfirm"),
      type: "delete",
    });
  };
  const blockPromptHandler = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: data?.userDetails?.blocked
        ? t("UserManagement.unblockConfirm")
        : t("UserManagement.blockConfirm"),
      type: "block",
    });
  };

  const confirmBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
  };

  const updatedRedirect = () => {
    setUpdated(true);
    dispatch(resetErrors());
    setBackToListing(true);
  };

  const redirectMethod = () => {
    dispatch(resetErrors());
    setBackToListing(true);
  };

  const okHandler = () => {
    promptMessage.type === "back"
      ? redirectMethod()
      : promptMessage.type === "delete"
      ? dispatch(
          deleteUser({ data: match.id, callback: () => history.push("/users") })
        )
      : data?.userDetails?.blocked
      ? dispatch(blockUser({ data: { blocked: false }, id: match.id }))
      : dispatch(blockUser({ data: { blocked: true }, id: match.id }));
  };

  const handleUpdate = () => {
    if (date && gender && gender && name) {
      const data = {
        id: match.id,
        date_of_birth: convertDate(date, "/"),
        gender: gender,
        name,
        about,
        citizenship: citizenship,
        remove_pic: removeImage,
        // email: email,
        // password: password,
        // phone: mobileNo,
        // country_code: countryCode,
      };
      const datawithimage = {
        id: match.id,
        date_of_birth: convertDate(date, "/"),
        gender: gender,
        name,
        citizenship: citizenship,
        about,
        // email: email,
        // password: password,
        // phone: mobileNo,
        // country_code: countryCode,
      };
      console.log("Email:", email); // Log the email
      console.log("Password:", password); // Log the password
      console.log("Mobile No:", mobileNo); // Log the mobileNo

      if (email.trim() !== "") {
        data.email = email;
        datawithimage.email = email;
      }
      if (password.trim() !== "") {
        data.password = password;
        datawithimage.password = password;
      }
      if (mobileNo.trim() !== "") {
        data.phone = mobileNo;
        data.country_code = countryCode;
        datawithimage.phone = mobileNo;
        datawithimage.country_code = countryCode;
      }

      if (email.trim() !== "") {
        data.email = email;
        datawithimage.email = email;
      }
      if (password.trim() !== "") {
        data.password = password;
        datawithimage.password = password;
      }
      if (mobileNo.trim() !== "") {
        data.phone = mobileNo;
        data.country_code = countryCode;
        datawithimage.phone = mobileNo;
        datawithimage.country_code = countryCode;
      }
      console.log("222", data);

      dispatch(
        updateUserData(
          removeImage ? data : datawithimage,
          imageFile,
          updatedRedirect
        )
      );
    }
  };
  useEffect(() => {
    data?.error?.date_of_birth && setDateError(data?.error?.date_of_birth[0]);
    data?.error?.gender && setGenderError(data?.error?.gender[0]);
  }, [data?.error]);

  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };

  function srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }

  const readFile = async (file) => {
    if (file == "removed") {
      setRemoveImage(true);
      srcToFile(ProfileAvatar, "new.png", "image/png").then(function (file) {
        setImageFile("");
        const reader = new FileReader();
        reader.onload = () => {
          setProfilePic(reader.result);
        };
        reader.readAsDataURL(file);
      });
    } else {
      setRemoveImage(false);
      setImageFile(file?.target?.files[0]);
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePic(reader.result);
      };
      reader.readAsDataURL(file?.target?.files[0]);
    }
  };

  return (
    <div
      className="page-content userDetailWrap"
      data-testid="component-editUserDetail"
      ref={pageWrapRef}
    >
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            return confirmBrowserBack();
          }
        }}
        when={formChanged}
      />
      <BackButton
        label={
          history?.location?.state?.from === "userList"
            ? "Users"
            : "User Details"
        }
        handleClick={() => (formChanged ? confirmBack() : redirectMethod())}
      />
      <Breadcrumbs title="Tables" breadcrumbItem="Edit User Details" />
      <Col xl="12">
        {(dateError || genderError) && (
          <div ref={warningAlertRef}>
            <UncontrolledAlert
              color="danger"
              className="alert-dismissible fade show"
              role="alert"
            >
              {dateError || genderError}
            </UncontrolledAlert>
          </div>
        )}
        {typeof data?.error === "string" && data?.error && (
          <div ref={warningAlertRef}>
            <UncontrolledAlert
              color="danger"
              className="alert-dismissible fade show"
              role="alert"
            >
              {data?.error}
            </UncontrolledAlert>
          </div>
        )}

        <Card className="mb-0 p-4">
          <div className="text-center">
            <div className="clearfix"></div>
            <div className="edit-admin-profilePic-wrapper">
              <label onClick={() => setImageChoosePopUp(!imageChoosePopUp)}>
                <img
                  src={profilePic}
                  alt=""
                  className="avatar-xl rounded-circle img-thumbnail editAdmin-profile-pic"
                />
                <img
                  src={Camera_icon}
                  alt="Camera"
                  className="edit-admin-role-camera-icon"
                />
              </label>
            </div>

            <h5 className="mt-3 mb-1 ">{data?.userDetails?.name}</h5>
            <div className="d-flex justify-content-center align-items-center">
              <p className="text-muted">{data?.userDetails?.username}</p>
              <div className="badge bg-soft-success mx-3 mb-3 font-size-8">
                {data?.userDetails?.citizenship}
              </div>
            </div>
          </div>

          <div className="userDetailActions">
            <button
              className="action-buttons"
              title={data?.userDetails?.blocked ? "Unblock" : "Block"}
              onClick={() => blockPromptHandler()}
              disabled={!isBlockable}
            >
              <img
                src={data?.userDetails?.blocked ? UnBlock_icon : Block_icon}
                alt={data?.userDetails?.blocked ? "Unblock" : "Block"}
                style={{ opacity: !isBlockable ? "50%" : "" }}
              />
              <span>{data?.userDetails?.blocked ? "UNBLOCK" : "BLOCK"}</span>
            </button>
            <button
              className="action-buttons"
              title="Delete"
              onClick={() => deletePromptHandler()}
              disabled={!isDeleteable}
            >
              <img
                src={Delete_icon}
                alt="Delete"
                style={{ opacity: !isDeleteable ? "50%" : "" }}
              />
              <span className="color-red">DELETE</span>
            </button>
          </div>

          <hr className="my-4" />

          <div className="text-muted">
            <AvForm
              onValidSubmit={(e, v) => {
                handleUpdate(e, v);
              }}
            >
              <div className="table-responsive mt-4">
                <Row className="col-md-12 mb-4">
                  <div className="col-md-4">
                    <label
                      htmlFor="example-date-input"
                      className="col-md-10 col-form-label"
                    >
                      {t("UserManagement.name")}
                      <span className="mandatory">*</span>
                    </label>
                    <div className="col-md-10 dobWrapper">
                      <AvField
                        name="name"
                        type="text"
                        className="form-control cursor-pointer"
                        value={name}
                        id="example-date-input"
                        onChange={(e) => {
                          setName(e.target.value);
                          setFormChanged(true);
                        }}
                      />
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor="example-date-input"
                      className="col-md-10 col-form-label"
                    >
                      {t("UserManagement.about")}
                    </label>
                    <div className="col-md-10 dobWrapper">
                      <AvField
                        name="about"
                        type="text"
                        className="form-control cursor-pointer"
                        value={about}
                        id="example-date-input"
                        onChange={(e) => {
                          setAbout(e.target.value);
                          setFormChanged(true);
                        }}
                      />
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                    </div>
                  </div>
                  {data?.userDetails &&
                    data?.userDetails?.is_premium === false && (
                      <div className="col-md-4">
                        <label
                          htmlFor="example-date-input"
                          className="col-md-10 col-form-label"
                        >
                          Citizenship
                          <span className="mandatory">*</span>
                        </label>
                        <div className="col-md-10">
                          <AvField
                            type="select"
                            name="citizenship"
                            className="form-control cursor-pointer genderSelectField"
                            value={citizenship || ""}
                            onChange={(e) => {
                              setGenderError("");
                              setCitizenship(e.target.value);
                              setFormChanged(true);
                            }}
                          >
                            <option value="">Select</option>
                            <option value="RESIDENT">RESIDENT</option>
                            <option value="VISITOR">VISITOR</option>
                          </AvField>
                        </div>
                      </div>
                    )}

                  <div className="col-md-4">
                    <label
                      htmlFor="example-date-input"
                      className="col-md-10 col-form-label"
                    >
                      {t("UserManagement.dob")}
                      <span className="mandatory">*</span>
                    </label>
                    <div className="col-md-10 dobWrapper">
                      <AvField
                        name="date_of_birth"
                        type="date"
                        className="form-control cursor-pointer"
                        value={date}
                        id="example-date-input"
                        onChange={(e) => {
                          setDateError("");
                          setDate(e.target.value);
                          setFormChanged(true);
                        }}
                        max={new Date().toJSON().split("T")[0]}
                        min={"1900-01-01"}
                      />
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor="example-date-input"
                      className="col-md-10 col-form-label"
                    >
                      {t("UserManagement.gender")}
                      <span className="mandatory">*</span>
                    </label>
                    <div className="col-md-10">
                      <AvField
                        type="select"
                        name="gender"
                        className="form-control cursor-pointer genderSelectField"
                        value={gender || ""}
                        onChange={(e) => {
                          setGenderError("");
                          setGender(e.target.value);
                          setFormChanged(true);
                        }}
                      >
                        <option value="">Select</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Prefer Not to Say</option>
                      </AvField>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label
                      htmlFor="example-date-input"
                      className="col-md-10 col-form-label"
                    >
                      {t("UserManagement.status")}
                    </label>
                    <div className="col-md-10">
                      <p
                        // className="text-muted"
                        className={`badge ${
                          data?.userDetails?.account_status === "Inactive"
                            ? "bg-soft-orange"
                            : data?.userDetails?.account_status === "Active"
                            ? "bg-soft-green"
                            : data?.userDetails?.account_status === "Blocked"
                            ? "bg-soft-red"
                            : ""
                        } font-size-13`}
                      >
                        {data?.userDetails?.account_status}
                      </p>
                    </div>
                  </div>
                </Row>
                <Row
                  className="col-md-12 py-2"
                  style={{
                    border: "1px",
                    borderTop: "0.5px solid #F2F2F2",
                    borderTopWidth: "1px",
                  }}
                >
                  {/* <div className="row "> */}
                  {/* chnage password mobile email */}
                  <div className="col-md-4">
                    <label
                      htmlFor="example-date-input"
                      className="col-md-10 col-form-label"
                    >
                      {/* {t("UserManagement.name")} */}
                      Email
                      <span className="mandatory">*</span>
                    </label>
                    <div className="col-md-10 dobWrapper">
                      <AvField
                        name="email"
                        type="email"
                        className="form-control cursor-pointer"
                        value={email}
                        id="example-date-input"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setFormChanged(true);
                        }}
                      />
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                    </div>
                  </div>

                  <div className="   col-md-4 ">
                    <label
                      // htmlFor="example-date-input"
                      className="col-md-10 col-form-label"
                    >
                      Password
                      <span className="mandatory">*</span>
                    </label>
                    <div className="col-md-10 dobWrapper">
                      <AvField
                        name="name"
                        type="text"
                        className="form-control cursor-pointer"
                        value={password}
                        id="example-date-input"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setFormChanged(true);
                        }}
                      />
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label
                      // htmlFor="example-date-input"
                      className="col-md-10 col-form-label"
                    >
                      Mobile
                      <span className="mandatory">*</span>
                    </label>
                    <div className="row d-flex align-items-center">
                      <div className="col-md-5 col-xs-3">
                        <Dropdown
                          placeholder="Select Country Code"
                          fluid
                          search
                          selection
                          options={countryOptions}
                          onChange={handleCountryChange}
                          defaultValue={countryCode}
                          style={{
                            fontSize: "inherit",
                          }}
                        />
                      </div>
                      <div className="col-sm-6 col-md-7">
                        {" "}
                        <div className="col-md-10 dobWrapper">
                          <AvField
                            name="mobile"
                            type="number"
                            className="cursor-pointer"
                            value={mobileNo}
                            id="example-date-input"
                            onChange={(e) => {
                              setMobileNo(e.target.value);
                              setFormChanged(true);
                            }}
                          />
                          <i className="fa fa-calendar" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                <div className="p-4 text-center">
                  <button
                    type="submit"
                    disabled={isButtonDisabled}
                    className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                  >
                    {t("Common.update")}
                  </button>
                </div>
              </div>
            </AvForm>
          </div>
        </Card>
      </Col>
      <FileChooseAlert
        modal_center={imageChoosePopUp}
        setmodal_center={setImageChoosePopUp}
        readFile={readFile}
        setFormChanged={setFormChanged}
        toggle={imageChoosetoggle}
        setIsButtonDisabled={setIsButtonDisabled}
      />

      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default EditUserDetail;
