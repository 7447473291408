import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Card,
  Row,
  UncontrolledAlert,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { AvForm, AvField, AvCheckbox } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";

import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";
import Camera_icon from "./../../../assets/icons/ebud-icons/Camera.svg";
import premiumImage from "../../../assets/icons/premium.svg";
import verifedImage from "../../../assets/icons/verified.svg";

import {
  checkUserPermission,
  convertDate,
  convertToUAETime,
  convertTime,
} from "../../../helpers/utils";
import {
  DATE_WITH_TIME_IN_SECONDS,
  FE_DATE_FORMAT,
} from "../../../helpers/constants";

import Block_icon from "../../../assets/icons/ebud-icons/Block.svg";
import UnBlock_icon from "../../../assets/icons/ebud-icons/Unblock.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import Avatar from "../../../assets/images/users/user.svg";
import ProfileAvatar from "../../../assets/images/users/Profile_avatar.png";
import Crown from "../../../assets/icons/premium.svg";

import { DATE_REGEX } from "../../../helpers/constants";

import {
  getUserDetails,
  blockUser,
  deleteUser,
  updateUserData,
  resetErrors,
} from "../../../store/users/actions";
import {
  getAdminsData,
  storeRequest,
} from "../../../../src/store/adminManagement/admins/action";
import {
  editPayoutRequest,
  getPayoutStatus,
  updatePayoutRequest,
  processPayment,
  sendInReviewEmail,
  getPayoutRequests,
} from "../../../store/payouts/action";
import { Button, InputGroup, Input } from "reactstrap";
import { useAsync } from "../../../util/useAsync";
import { update } from "../../../helpers/api_helper";
import {
  API_EDIT_PAYOUT_METHOD,
  API_SEND_NOTIFICATION,
} from "../../../helpers/url_helper";
import { alert } from "../../../util/toast";
import { NotificationButton } from "./NotificationButton";
import { IdPreview } from "./Idpreviews";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
const EditPayoutRequestDetail = () => {
  const match = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const toggle = () => setShowPromptPopUp(!showPromptPopUp);
  const [promptMessage, setPromptMessage] = useState({});

  const [status, setStatus] = useState("");
  const [payout_status, setPayoutStatus] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [forwarded, setForwarded] = useState(false);
  const [forwarded_user, setForwardedUser] = useState("");
  const [review, setReview] = useState("");
  const [date, setDate] = useState("");
  const [gender, setGender] = useState("");

  const [about, setAbout] = useState("");
  const [dateError, setDateError] = useState("");
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [formChanged, setFormChanged] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [backToListing, setBackToListing] = useState(false);
  const [removeImage, setRemoveImage] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [dopayment, setDoPayment] = useState(true);
  const [showstatus, setShowstatus] = useState(true);
  const [paypalId, setPaypalId] = useState("");
  // const { isLoading, isError, run, error, isSuccess } = useAsync();
  const [commentValue, setCommentValue] = useState("");
  const [modal, setModal] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [recieptFile, setReceiptFile] = useState(null);
  const [paymentRefNo, setPaymentRefNo] = useState("");
  const [processDisabled, setProcessDisabled] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [payableAmount, setPayableAmount] = useState(0);
  const [changedAmount, setChangedAmount] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);
  const ref = React.useRef(null);
  const commentRef = React.useRef(null);

  const { t } = useTranslation();

  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isDeleteable = userPermissions?.delete || false;
  const isBlockable = userPermissions?.edit || false;
  const data = useSelector((state) => state.user);
  const payout = useSelector((state) => state.Payouts);
  const admins = useSelector((state) => state.admins);

  console.log("payout_payedit", payout);

  const toggleFlaxSummary = () => setModal(!modal);

  // useEffect(() => {
  //   if (isSuccess) {
  //     alert.success("Paypal id has been updated successfully");
  //   }
  // }, [isSuccess]);

  useEffect(() => {
    match?.id && dispatch(editPayoutRequest(match.id));
    dispatch(getAdminsData());
    dispatch(getPayoutStatus());
  }, [match.id, dispatch]);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Users"
        )
      );
    }
  }, [loginData]);

  useEffect(() => {
    backToListing &&
      history.push({
        pathname:
          history?.location?.state?.from === "payoutRequests"
            ? `/payout-requests/`
            : history?.location?.state?.from === "payoutStatement"
              ? `/payout-requests/`
              : `/payout-requests/${match.id}`,
        // state: { from: "keepFilter", PRUpdated: updated },
      });
  }, [backToListing, match.id, history]);

  useEffect(() => {
    scrollToAlertMessage();
  }, [
    dateError,
    genderError,
    typeof data?.error === "string" && data?.error,
    payout?.success,
  ]);

  const scrollToAlertMessage = () => {
    if (
      dateError ||
      genderError ||
      (typeof data?.error === "string" && data?.error) ||
      payout?.success
    ) {
      let element = warningAlertRef?.current;
      let top = element?.offsetTop;
      let height = element?.getBoundingClientRect()?.height;
      pageWrapRef?.current?.scrollTo(0, top - height - 30);
    }
  };

  const confirmBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
  };

  const redirectMethod = () => {
    dispatch(resetErrors());
    setBackToListing(true);
  };

  const okHandler = () => {
    if (promptMessage?.type === "send_mail") {
      const data = {
        payout_id: match?.id,
      };
      dispatch(
        sendInReviewEmail({
          data,
          callback: () => dispatch(editPayoutRequest(match.id)),
        })
      );
      return;
    }
    if (promptMessage?.type === "process-payment") {
      confirmprocessPayment();
      return;
    }
    // promptMessage.type === "back"
    //   ? redirectMethod()
    //   : promptMessage.type === "process-payment"
    //   ? confirmprocessPayment()
    //   : promptMessage.type === "delete"
    //   ? dispatch(
    //       deleteUser({
    //         data: match.id,
    //         callback: () => history.push("/payout-requests"),
    //       })
    //     )
    //   : data?.userDetails?.blocked
    //   ? dispatch(blockUser({ data: { blocked: false }, id: match.id }))
    //   : dispatch(blockUser({ data: { blocked: true }, id: match.id }));
  };

  const handleViewProfile = () => {
    history.push({
      pathname: `/user-details/${payout.payoutRequest_details?.detailed?.user_id}`,
    });
  };

  const handleStatusUpdate = () => {
    setStatus("");
    if (payout_status) {
      const data = {
        id: match.id,
        new_status_id: parseInt(payout_status),
        forwarded_to_id: forwarded_user,
        comments: review,
        old_status_id: parseInt(
          payout.payoutRequest_details?.detailed?.status_id
        ),
        send_mail: isChecked,
      };
      dispatch(
        updatePayoutRequest({
          data,
          callback: () => dispatch(editPayoutRequest(match.id)),
        })
      );
      setFormChanged(false);
      setIsChecked(false);
      setShowCheckbox(false);
    }
  };
  const handleAmountUpdate = () => {
    const data = {
      id: match.id,
      amount_paid: payableAmount,
    };
    dispatch(
      updatePayoutRequest({
        data,
        callback: () => dispatch(editPayoutRequest(match.id)),
      })
    );
    setFormChanged(false);
    setPayableAmount(null);
  };
  const sendInReviewMail = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: match?.id,
      content:
        "Are you sure you want to proceed with sending the Sell FLiX offer email to the user?",
      type: "send_mail",
    });
  };

  const handleViewStatement = () => {
    history.push({
      pathname: `/user-pp-statement/${payout.payoutRequest_details?.detailed?.user_id}`,
      // state: { from: "keepFilter", PRUpdated: updated },
      state: {
        from: "payoutRequestDetails",
        PRDetailsID: match.id,
      },
    });
  };

  const handleEdit = () => {
    history.push({
      pathname: `/payout-requests/${payout.payoutRequest_details?.detailed?.id}/edit-payment`,
      // state: { from: "keepFilter", PRUpdated: updated },
      state: {
        from: "payoutRequestDetails",
        PRDetailsID: match.id,
      },
    });
  };

  const handleReceiptFile = (event) => {
    const receipt = event.target.files[0];
    if (receipt) {
      setReceiptFile(receipt);
    }
  };

  const handlePayment = () => {
    setPaymentMethodError("");
    if (ref.current.value !== "") {
      setShowPromptPopUp(!showPromptPopUp);
      setPromptMessage({
        id: "",
        content: t("Payouts.ProcessPaymentConsent"),
        type: "process-payment",
      });
    } else {
      setPaymentMethodError("Please select a Payment Channel");
    }
  };
  const confirmprocessPayment = () => {
    const formData = new FormData();
    recieptFile && formData.append("file", recieptFile);
    formData.append(
      "data",
      JSON.stringify({
        payment_method: ref?.current?.value,
        comments: commentValue,
        payout_req_id: payout?.payoutRequest_details?.detailed?.id,
        amount: parseInt(
          payout?.payoutRequest_details?.detailed?.requested_amount
        ),
        currency: "USD",
        email: payout?.payoutRequest_details?.detailed?.paypal_id,
        transaction_number: paymentRefNo,
      })
    );

    dispatch(
      processPayment({
        formData,
        callback: () => dispatch(editPayoutRequest(match.id)),
      })
    );
  };

  useEffect(() => {
    if (paymentRefNo === "" || recieptFile === null || paymentMethod === "")
      setProcessDisabled(true);
    else setProcessDisabled(false);
  }, [paymentRefNo, recieptFile, paymentMethod]);

  useEffect(() => {
    data?.error?.date_of_birth && setDateError(data?.error?.date_of_birth[0]);
    data?.error?.gender && setGenderError(data?.error?.gender[0]);
  }, [data?.error]);
  useEffect(() => {
    // payout?.payout_status?.status?.length &&
    //   payout?.payout_status?.status?.map((dataValues, Datakey) => {
    //     if (
    //       dataValues.id === payout?.payoutRequest_details?.detailed?.status_id
    //     ) {
    //       delete payout?.payout_status?.status[Datakey]; //Removing Current Status
    //     }
    //     delete payout?.payout_status?.status[3]; //Removing Payment ProcessInitiated
    //     delete payout?.payout_status?.status[4]; //Removing Payment ProcessProcessed
    //   });
    payout?.payoutRequest_details?.detailed?.status_id &&
      payout?.payoutRequest_details?.detailed?.status_id === 4
      ? setDoPayment(false)
      : payout?.payoutRequest_details?.detailed?.status_id === 5
        ? setDoPayment(false)
        : payout?.payoutRequest_details?.detailed?.status_id === 6
          ? setDoPayment(false)
          : payout?.payoutRequest_details?.detailed?.status_id === 8
            ? setDoPayment(false)
            : setDoPayment(true);

    payout?.payoutRequest_details?.detailed?.status_id &&
      payout?.payoutRequest_details?.detailed?.status_id === 4
      ? setShowstatus(false)
      : payout?.payoutRequest_details?.detailed?.status_id === 5
        ? setShowstatus(false)
        : payout?.payoutRequest_details?.detailed?.status_id === 6
          ? setShowstatus(false)
          : payout?.payoutRequest_details?.detailed?.status_id === 8
            ? setShowstatus(false)
            : setShowstatus(true);
  }, [payout?.payout_status?.status, payout?.payoutRequest_details?.detailed]);

  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };

  const accordionClickHandler = (visibility) => {
    setVisibility(!visibility);
  };

  const chkForwarded = (forwarded, value) => {
    // if (value === "3") setForwarded(true);
    //Assuming 3 = Forward
    // else setForwarded(false);
    setStatus(value);

    console.log("val", value);
    if (value === "13") {
      console.log("d");
      setShowCheckbox(true);
    } else {
      setShowCheckbox(false);
    }

    setPayoutStatus(value);
    setFormChanged(true);
    console.log("payout status", payout_status);
  };

  // const onPayPalIdSave = () => {
  //   if (paypalId?.length) {
  //     run(
  //       update(
  //         `${API_EDIT_PAYOUT_METHOD}/${payout?.payoutRequest_details?.detailed?.user_id}`,
  //         { payment_method: "Paypal", paypal_id: paypalId }
  //       )
  //     );
  //   }
  // };

  const handlePreview = (imageUrl) => {
    setOpen(true);
    setImgUrl(imageUrl);
  };
  const userDetails = payout?.payoutRequest_details?.user_official_details;

  const isEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0;
  };

  return (
    <div
      className="page-content userDetailWrap"
      data-testid="component-editUserDetail"
      ref={pageWrapRef}
    >
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            return confirmBrowserBack();
          }
        }}
        when={formChanged}
      />
      <BackButton
        label="Payout Requests"
        handleClick={() => {
          history.push("/payout-requests");
        }}
      />
      <Breadcrumbs title="Tables" breadcrumbItem="Payout Request Details" />
      <Col xl="12">
        {(payout?.error || payout?.success) && (
          <div ref={warningAlertRef}>
            <UncontrolledAlert
              color={payout?.error ? "danger" : "success"}
              className="alert-dismissible fade show"
              role="alert"
            >
              {payout?.error || payout?.success}
            </UncontrolledAlert>
          </div>
        )}

        <section style={{ backgroundColor: "#f5f6f8" }}>
          <div className=" py-3">
            <div className="row">
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body text-center">
                    <div className="userDetails_profilePic cursor-pointer">
                      {payout?.payoutRequest_details?.detailed?.verified ===
                        "true" ? (
                        <img className="userDetails_crown" src={verifedImage} />
                      ) : payout?.payoutRequest_details?.detailed
                        ?.membership === "Premium" ? (
                        <img className="userDetails_crown" src={premiumImage} />
                      ) : null}
                      <img
                        src={
                          payout?.payoutRequest_details?.detailed
                            ?.profile_image || Avatar
                        }
                        alt="avatar"
                        className="rounded-circle img-fluid"
                        style={{ width: "150px" }}
                      />
                    </div>
                    <h5 className="my-3">
                      {payout?.payoutRequest_details?.detailed?.name}
                    </h5>
                    <p className="text-muted mb-1">
                      Username:{" "}
                      <b>{payout?.payoutRequest_details?.detailed?.username}</b>
                    </p>
                    <p className="text-muted mb-4">
                      {payout?.payoutRequest_details?.detailed?.country}
                    </p>
                    <div className="d-flex justify-content-center mb-2">
                      <button
                        type="button"
                        className="btn btn-primary"
                        // onClick={(e) => {
                        //   handleViewProfile();
                        // }}
                        onClick={() => {
                          const win = window.open(
                            `/user-details/${payout.payoutRequest_details?.detailed?.user_id}?tab=2`,
                            "_blank"
                          );
                          win.focus();
                        }}
                      >
                        View Profile
                      </button>

                      {/* <button
                        type="button"
                        className="btn btn-outline-primary ms-1"
                      >
                        Message
                      </button> */}
                    </div>
                  </div>
                </div>
                {payout?.payoutRequest_details?.user_payment_deatils?.length >
                  0 && (
                    <div className="card mb-4 mb-md-0">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <div className="d-flex align-items-center justify-content-start gap-1">
                            <span className="text-primary font-italic me-1" style={{ fontSize: '14px' }}>
                              Payment Details
                            </span>
                            {/* {payout?.payoutRequest_details?.admin_updated_payment_details && <i style={{ color: 'lightgray', fontSize: '10px' }}>Updated by Admin</i>} */}
                            {payout?.payoutRequest_details?.admin_updated_payment_details && <p className="badge bg-soft-gray">Updated by Admin</p>}
                          </div>

                          <button
                            type=""
                            className="btn btn-primary"
                            onClick={(e) => {
                              handleEdit();
                            }}
                          >
                            Edit
                          </button>
                        </div>

                        {payout?.payoutRequest_details?.user_payment_deatils &&
                          payout?.payoutRequest_details?.user_payment_deatils
                            ?.length > 0 && (
                            <div className="row d-flex mt-1 border-bottom">
                              <div className="col-md-6">
                                <p className="m-0">Payment Method :</p>{" "}
                              </div>
                              <div className="col-md-6 text-end ">
                                <span className="text-secondary ">
                                  {
                                    payout?.payoutRequest_details
                                      ?.user_payment_deatils[0].payment_method
                                  }
                                </span>
                              </div>
                            </div>
                          )}
                        {payout?.payoutRequest_details?.user_payment_deatils &&
                          payout?.payoutRequest_details?.user_payment_deatils
                            ?.length > 0 &&
                          payout?.payoutRequest_details?.user_payment_deatils
                            ?.sort((a, b) => a?.listing_order - b?.listing_order)
                            ?.map((detail, index) => (
                              <div
                                key={index}
                                className="row d-flex mt-1 border-bottom"
                              >
                                <div className="col-md-6">
                                  <p className="m-0">
                                    {detail?.input_label?.replace("*", "")}:
                                  </p>{" "}
                                </div>
                                <div className="col-md-6 text-end ">
                                  {detail?.input_data?.match(
                                    /^https:\/\/.*\.s3\.amazonaws\.com\/.*/
                                  ) ? (
                                    <span
                                      style={{
                                        color: "#b227c1",
                                        cursor: "pointer",
                                      }}
                                      className="text-decoration-underline pointer"
                                      onClick={() =>
                                        handlePreview(detail.input_data)
                                      }
                                    >
                                      Preview
                                    </span>
                                  ) : (
                                    <span className="text-secondary ">
                                      {detail?.input_data?.replace("#", " ")}
                                    </span>
                                  )}
                                </div>
                              </div>
                            ))}
                      </div>
                    </div>
                  )}

                {userDetails && !isEmpty(userDetails) && (
                  <div className="card mb-4 mb-md-0">
                    <div className="card-body">
                      <p className="mb-4">
                        <span className="text-primary font-italic me-1">
                          Official Details
                        </span>
                      </p>

                      <div
                        // key={index}
                        className="row d-flex mt-1 border-bottom"
                      >
                        <div className="col-md-6">
                          <p className="m-0">First Name</p>{" "}
                        </div>
                        <div className="col-md-6 text-end ">
                          {
                            payout?.payoutRequest_details?.user_official_details
                              ?.fname
                          }
                        </div>
                        <div className="col-md-6 mt-1">
                          <p className="m-0">Last Name</p>{" "}
                        </div>
                        <div className="col-md-6 text-end ">
                          {
                            payout?.payoutRequest_details?.user_official_details
                              ?.lname
                          }
                        </div>
                        {/* <div className="col-md-6 mt-1">
                          <p className="m-0">First Name</p>{" "}
                        </div>
                        <div className="col-md-6 text-end ">
                          {
                            payout?.payoutRequest_details?.detailed
                              ?.user_official_details?.fname
                          }
                        </div> */}
                        <div className="col-md-6 mt-1">
                          <p className="m-0">Passport or ID Number</p>{" "}
                        </div>
                        <div className="col-md-6 text-end ">
                          {
                            payout?.payoutRequest_details?.user_official_details
                              ?.id_number
                          }
                        </div>

                        <div className="col-md-6 mt-1">
                          <p className="m-0">Country</p>{" "}
                        </div>
                        <div className="col-md-6 text-end ">
                          {
                            payout?.payoutRequest_details?.user_official_details
                              ?.country
                          }
                        </div>
                        {/* <div className="col-md-6 mt-1">
                          <p className="m-0">Country</p>{" "}
                        </div>
                        <div className="col-md-6 text-end ">
                          {
                            payout?.payoutRequest_details?.detailed
                              ?.user_official_details?.country
                          }
                        </div> */}
                        <div className="col-md-6 mt-1">
                          <p className="m-0">Uploaded ID</p>{" "}
                        </div>
                        <div className="col-md-6 text-end ">
                          <span
                            style={{
                              color: "#b227c1",
                              cursor: "pointer",
                            }}
                            className="text-decoration-underline pointer"
                            onClick={() =>
                              handlePreview(
                                payout?.payoutRequest_details
                                  ?.user_official_details?.id_image
                              )
                            }
                          >
                            Preview
                          </span>
                        </div>
                        <div className="col-md-6 mt-1 ">
                          <p className="m-0">Phone</p>{" "}
                        </div>
                        <div className="col-md-6 text-end ">
                          {payout?.payoutRequest_details?.user_official_details
                            ?.country_code +
                            " " +
                            payout?.payoutRequest_details?.user_official_details
                              ?.phone_no}
                        </div>
                        <div className="col-md-6 mt-1">
                          <p className="m-0">City/State</p>{" "}
                        </div>
                        <div className="col-md-6 text-end ">
                          {
                            payout?.payoutRequest_details?.user_official_details
                              ?.city
                          }
                        </div>
                        <div className="col-md-6 mt-1">
                          <p className="m-0">Street Address</p>{" "}
                        </div>
                        <div className="col-md-6 text-end ">
                          {
                            payout?.payoutRequest_details?.user_official_details
                              ?.address
                          }
                        </div>
                        <div className="col-md-6 mt-1">
                          <p className="m-0">Postal Code</p>{" "}
                        </div>
                        <div className="col-md-6 text-end ">
                          {
                            payout?.payoutRequest_details?.user_official_details
                              ?.pincode
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <IdPreview imgurl={imgUrl} isOpen={isOpen} setOpen={setOpen} />
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row mb-2">
                          <div className="col-sm-9">
                            <button
                              type="button"
                              className="btn btn-primary"
                              style={{ float: "right" }}
                              onClick={(e) => {
                                handleViewStatement();
                              }}
                            >
                              View Statement
                            </button>
                          </div>
                          {/* <div className="col-sm-3">
                            <NotificationButton
                              user_id={
                                payout?.payoutRequest_details?.detailed?.user_id
                              }
                              flax={
                                payout?.payoutRequest_details
                                  ?.requested_points_for_review
                              }
                            />
                          </div> */}
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Payout Request Status</p>
                          </div>
                          <div className="col-sm-9">
                            <p
                              // className="text-muted"
                              className={`badge ${payout?.payoutRequest_details?.detailed
                                ?.status === "New Request"
                                ? "bg-soft-blue"
                                : payout?.payoutRequest_details?.detailed
                                  ?.status === "In Review"
                                  ? "bg-soft-orange"
                                  : payout?.payoutRequest_details?.detailed
                                    ?.status === "Forwarded"
                                    ? "bg-soft-gray"
                                    : payout?.payoutRequest_details?.detailed
                                      ?.status === "Completed"
                                      ? "bg-soft-green"
                                      : payout?.payoutRequest_details?.detailed
                                        ?.status === "Payment Process Initiated"
                                        ? "bg-soft-green"
                                        : payout?.payoutRequest_details?.detailed
                                          ?.status === "Request Declined"
                                          ? "bg-soft-red"
                                          : payout?.payoutRequest_details?.detailed
                                            ?.status === "Cancelled"
                                            ? "bg-soft-red"
                                            : payout?.payoutRequest_details?.detailed
                                              ?.status === "Under Finance Review" ||
                                              payout?.payoutRequest_details?.detailed
                                                ?.status === "Under MENA Review" ||
                                              payout?.payoutRequest_details?.detailed
                                                ?.status === "Mena Approved" ||
                                              payout?.payoutRequest_details?.detailed
                                                ?.status === "Approved by Chairman"
                                              ? "bg-soft-orange"
                                              : payout?.payoutRequest_details?.detailed
                                                ?.status ===
                                                "Violation/Suspicious Activity"
                                                ? "bg-soft-pink"
                                                : "bg-soft-orange"
                                } font-size-13`}
                            >
                              {payout?.payoutRequest_details?.detailed?.status}{" "}
                              {payout?.payoutRequest_details?.detailed
                                ?.status_id === 3 && (
                                  <>
                                    {" "}
                                    to{" "}
                                    {
                                      payout?.payoutRequest_details?.detailed
                                        ?.forwarded_to
                                    }
                                  </>
                                )}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Date of Payout Request</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                              {payout?.payoutRequest_details?.detailed
                                ?.requested_date &&
                                convertToUAETime(
                                  payout?.payoutRequest_details?.detailed
                                    ?.requested_date,
                                  DATE_WITH_TIME_IN_SECONDS
                                )}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Date of Join</p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-muted mb-0">
                              {payout?.payoutRequest_details?.detailed
                                ?.date_of_join &&
                                convertToUAETime(
                                  payout?.payoutRequest_details?.detailed
                                    ?.date_of_join,
                                  DATE_WITH_TIME_IN_SECONDS
                                )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-12">
                            <h5>FLiX Summary</h5>
                          </div>
                        </div>

                        <div className="row mt-4 mb-2">
                          <div className="col-sm-8">
                            <p className="mb-0 text-success fw-bold">
                              Total Credited
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <p className=" mb-0 text-end text-success fw-bold">
                              <i>FLiX </i>
                              {payout?.payoutRequest_details?.total_gained_usd}
                            </p>
                          </div>
                        </div>
                        <div className="row mt-2 mb-2">
                          <div className="col-sm-8">
                            <p className="mb-0 text-danger fw-bold">
                              Total Debited
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <p className=" mb-0 text-end text-danger fw-bold">
                              <i>FLiX </i>
                              {
                                payout?.payoutRequest_details
                                  ?.total_debited_flax
                              }
                            </p>
                          </div>
                        </div>
                        <div className="row mt-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0 text-danger fw-bold">
                              Total Withdrawn
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <p className=" mb-0 text-end text-danger fw-bold">
                              <i>FLiX </i>
                              {
                                payout?.payoutRequest_details
                                  ?.total_withdrawn_pp
                              }
                            </p>
                          </div>
                        </div>
                        <div className="row mt-2 ">
                          <div className="col-sm-8">
                            <p className="mb-0">
                              <b>Current Total Balance</b>
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end">
                              <i>FLiX </i>
                              <b>
                                {
                                  payout?.payoutRequest_details
                                    ?.total_current_bal
                                }
                              </b>
                            </p>
                          </div>
                        </div>
                        {payout?.payoutRequest_details?.detailed?.status_id !==
                          5 ? (
                          <>
                            <div className="row mt-2 pb-3 border-bottom">
                              <div className="col-sm-8">
                                <p className="mb-0 text-warning fw-bold">
                                  Total Payout Review in Pending
                                </p>
                              </div>
                              <div className="col-sm-4">
                                <p className=" mb-0 text-end text-warning fw-bold">
                                  <i>FLiX </i>
                                  {
                                    payout?.payoutRequest_details
                                      ?.requested_points_for_review
                                  }
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {payout?.payoutRequest_details?.detailed?.status_id !==
                          5 ? (
                          <>
                            <div className="row ">
                              <div className="col-sm-8">
                                <p className="mb-0">
                                  <b>Total Balance After Payout Processing</b>
                                </p>
                              </div>
                              <div className="col-sm-4">
                                <p className="text-muted mb-0 text-end">
                                  <i>FLiX </i>
                                  <b>
                                    {
                                      payout?.payoutRequest_details
                                        ?.bal_after_payment
                                    }
                                  </b>
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        <div className="row mt-2 ">
                          <div className="col-sm-8">
                            <p
                              onClick={toggleFlaxSummary}
                              className="mb-0 cursor-pointer text-decoration-underline text-primary"
                            >
                              View Detailed Summary
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* full flax summary details */}
                  <Modal centered isOpen={modal} toggle={toggleFlaxSummary}>
                    <ModalHeader toggle={toggleFlaxSummary}>
                      FLiX summary
                    </ModalHeader>
                    <ModalBody>
                      <div className="card-body">
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <h5 className="mb-0 text-success">Credited</h5>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">Generated </p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted text-end">
                              <i>FLiX </i>
                              {payout?.payoutRequest_details?.generated_usd}
                            </p>
                          </div>
                        </div>

                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">Mining </p>
                          </div>
                          <div className="col-sm-4 ">
                            <p className="text-muted text-end">
                              <i>FLiX </i>
                              {
                                payout?.payoutRequest_details
                                  ?.rewarded_without_manual
                              }
                            </p>
                          </div>
                        </div>

                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">Refunded</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end">
                              <i>FLiX </i>
                              {payout?.payoutRequest_details?.refundable_pp}
                            </p>
                          </div>
                        </div>

                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">Manual FLiX </p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end ">
                              <i>FLiX </i>
                              {payout?.payoutRequest_details?.manual_pp}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">Converted from COiNS</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end ">
                              <i>FLiX </i>
                              {payout?.payoutRequest_details?.sold_gifts_flax}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">Purchased</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end ">
                              <i>FLiX </i>
                              {payout?.payoutRequest_details?.purchased_flax}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">Received</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end ">
                              <i>FLiX </i>
                              {payout?.payoutRequest_details?.received_flax}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <h5 className="mb-0 mt-2 text-danger">Debited</h5>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">Converted to COiNS</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end ">
                              <i>FLiX </i>
                              {
                                payout?.payoutRequest_details
                                  ?.purchased_gifts_flax
                              }
                            </p>
                          </div>
                        </div>

                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">Sent</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end ">
                              <i>FLiX </i>
                              {payout?.payoutRequest_details?.sent_flax}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">Pending Review</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end ">
                              <i>FLiX </i>
                              {payout?.payoutRequest_details?.pending_flax}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">FLiX Utilized </p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end ">
                              <i>FLiX </i>

                              {
                                payout?.payoutRequest_details
                                  ?.podium_camera_purchase
                              }
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">Cancellation Deductions</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end ">
                              <i>FLiX </i>
                              {
                                payout?.payoutRequest_details
                                  ?.cancelled_deductions
                              }
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">FLiX on Hold</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end ">
                              <i>FLiX </i>
                              {payout?.payoutRequest_details?.violation_flix}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">Total Withdrawn</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end ">
                              <i>FLiX </i>
                              {
                                payout?.payoutRequest_details
                                  ?.total_withdrawn_pp
                              }
                            </p>
                          </div>
                        </div>

                        <div className="row mt-4 mb-2">
                          <div className="col-sm-8">
                            <p className="mb-0 text-success fw-bold">
                              Total Credited
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <p className=" mb-0 text-end text-success fw-bold">
                              <i>FLiX </i>
                              {payout?.payoutRequest_details?.total_gained_usd}
                            </p>
                          </div>
                        </div>
                        <div className="row mt-2 mb-2">
                          <div className="col-sm-8">
                            <p className="mb-0 text-danger fw-bold">
                              Total Debited
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <p className=" mb-0 text-end text-danger fw-bold">
                              <i>FLiX </i>
                              {
                                payout?.payoutRequest_details
                                  ?.total_debited_flax
                              }
                            </p>
                          </div>
                        </div>
                        <div className="row mt-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0 fw-bold">
                              Current Total Balance
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <p className=" mb-0 text-end text-danger fw-bold">
                              <i>FLiX </i>
                              <b>
                                {
                                  payout?.payoutRequest_details
                                    ?.total_current_bal
                                }
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                  <div className="col-md-6">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row ">
                          <div className="col-sm-12">
                            <h5 className="mb-4">Payout Request Summary</h5>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">FLiX Submitted for Review</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted text-end">
                              {
                                payout?.payoutRequest_details
                                  ?.requested_points_for_review
                              }
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">
                              Processing Charge (
                              {
                                payout?.payoutRequest_details
                                  ?.payout_processing_charge
                              }{" "}
                              %)
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end">
                              {
                                payout?.payoutRequest_details
                                  ?.processing_charge_applied
                              }
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">
                              Transfer Fee (
                              {payout?.payoutRequest_details?.transfer_fee}
                              %)
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted mb-0 text-end">
                              {
                                payout?.payoutRequest_details
                                  ?.transfer_fee_applied
                              }
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">Eligible FLiX for Payout</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted text-end">
                              {payout?.payoutRequest_details?.pp_for_payout}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">FLiX Rate (in USD)</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted text-end">
                              {payout?.payoutRequest_details?.flax_rate}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 border-bottom">
                          <div className="col-sm-8">
                            <p className="mb-0">Payable Amount in USD</p>
                          </div>
                          <div className="col-sm-4">
                            <p className="text-muted text-end">
                              {payout?.payoutRequest_details?.payable_amount}
                            </p>
                          </div>
                        </div>
                        {payout?.payoutRequest_details?.detailed?.status_id ===
                          5 && (
                            <div className="row mb-2 border-bottom">
                              <div className="col-sm-8">
                                <p className="mb-0"> Amount Paid in USD</p>
                              </div>
                              <div className="col-sm-4">
                                <p className="text-muted text-end">
                                  {payout?.payoutRequest_details?.amount_paid}
                                </p>
                              </div>
                            </div>
                          )}
                        {payout?.payoutRequest_details?.detailed?.status_id !==
                          5 ? (
                          <>
                            <div className="row pt-2 border-bottom">
                              <div className="col-sm-8">
                                <p className="mb-0">
                                  <strong>FLiX Balance after Payment</strong>
                                </p>
                              </div>
                              <div className="col-sm-4">
                                <p className="text-end mb-0">
                                  <strong>
                                    {
                                      payout?.payoutRequest_details
                                        ?.bal_after_payment
                                    }
                                  </strong>
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                  {payout?.payoutRequest_details?.detailed?.status_id !== 1 &&
                    payout?.payoutRequest_details?.detailed?.status_id !== 5 &&
                    payout?.payoutRequest_details?.detailed?.status_id !== 6 &&
                    payout?.payoutRequest_details?.detailed?.status_id !== 7 &&
                    payout?.payoutRequest_details?.detailed?.status_id !==
                    8 && (
                      <div className="col-md-6">
                        <div className="card mb-4">
                          <div className="card-body">
                            <div className="row mt-1 mb-2">
                              <div className="col-sm-5">
                                <p className="mb-0  fw-bolder">
                                  Amount Paid in USD :
                                </p>
                              </div>
                              <div className="col-sm-4">
                                <p className=" mb-0 text-start  fw-bolder">
                                  {payout?.payoutRequest_details?.amount_paid}
                                </p>
                              </div>
                            </div>
                            <AvForm>
                              <div className="row mt-4 mb-2">
                                <div className="col-sm-12">
                                  <p className="mb-0"></p>
                                </div>
                                <div className="col-sm-9">
                                  <AvField
                                    name="comment"
                                    type="number"
                                    className="form-control "
                                    id="example-data-input1"
                                    placeholder="Enter amount in USD"
                                    value={payableAmount || ""}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => {
                                      setPayableAmount(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className=" col-sm-9 ">
                                  <button
                                    type="button"
                                    disabled={payableAmount ? false : true}
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                      handleAmountUpdate();
                                    }}
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </AvForm>
                          </div>
                        </div>
                      </div>
                    )}
                </div>

                {showstatus && (
                  <AvForm
                  // onValidSubmit={(e, v) => {
                  //   handleUpdate(e, v);
                  // }}
                  >
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Update Status to</p>
                          </div>
                          <div className="col-sm-9">
                            <AvField
                              type="select"
                              name="user-status"
                              className="form-control cursor-pointer genderSelectField"
                              value={status || ""}
                              onChange={(e) => {
                                chkForwarded(visibility, e.target.value);
                              }}
                            >
                              <option value="">Select Status</option>
                              {payout?.payout_status?.status
                                ?.filter((status) => {
                                  if (
                                    payout?.payoutRequest_details?.detailed
                                      ?.status_id === 2 ||
                                    payout?.payoutRequest_details?.detailed
                                      ?.status_id === 9 ||
                                    payout?.payoutRequest_details?.detailed
                                      ?.status_id === 10 ||
                                    payout?.payoutRequest_details?.detailed
                                      ?.status_id === 11 ||
                                    payout?.payoutRequest_details?.detailed
                                      ?.status_id === 12 ||
                                    payout?.payoutRequest_details?.detailed
                                      ?.status_id === 13 ||
                                    payout?.payoutRequest_details?.detailed
                                      ?.status_id === 14 ||
                                    payout?.payoutRequest_details?.detailed
                                      ?.status_id === 15 ||
                                    payout?.payoutRequest_details?.detailed
                                      ?.status_id === 16 ||
                                    payout?.payoutRequest_details?.detailed
                                      ?.status_id === 17 ||
                                    payout?.payoutRequest_details?.detailed
                                      ?.status_id === 18 ||
                                    payout?.payoutRequest_details?.detailed
                                      ?.status_id === 19 ||
                                    payout?.payoutRequest_details?.detailed
                                      ?.status_id === 20 ||
                                    payout?.payoutRequest_details?.detailed
                                      ?.status_id === 21
                                  ) {
                                    return (
                                      status.id === 6 ||
                                      status?.id === 9 ||
                                      status?.id === 10 ||
                                      status?.id === 11 ||
                                      status?.id === 12 ||
                                      status?.id === 13 ||
                                      status?.id === 14 ||
                                      status?.id === 15 ||
                                      status?.id === 16 ||
                                      status?.id === 17 ||
                                      status?.id === 18 ||
                                      status?.id === 19 ||
                                      status?.id === 20 ||
                                      status?.id === 21
                                    );
                                  }
                                  return status.id === 2 || status.id === 6;
                                })
                                .map((status, index) => (
                                  <option value={status.id} key={index}>
                                    {status.status_name}
                                  </option>
                                ))}
                            </AvField>
                          </div>
                        </div>
                        <hr />
                        {/* {forwarded && (
                          <>
                            <div className="row">
                              <div className="col-sm-3">
                                <p className="mb-0">Forward to</p>
                              </div>
                              <div className="col-sm-9">
                                <AvField
                                  type="select"
                                  name="select-user"
                                  className="form-control cursor-pointer genderSelectField"
                                  value={gender || ""}
                                  onChange={(e) => {
                                    setGenderError("");
                                    setForwardedUser(e.target.value);
                                    setFormChanged(true);
                                  }}
                                >
                                  <option value="">Select User</option>
                                  {admins?.adminData?.users &&
                                    admins?.adminData?.users?.length > 0 &&
                                    admins?.adminData?.users?.map(
                                      (user, index) => (
                                        <option key={user?.id} value={user.id}>
                                          {user.name}({user.email})
                                        </option>
                                      )
                                    )}
                                </AvField>
                              </div>
                            </div>
                            <hr />
                          </>
                        )} */}

                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0"></p>
                          </div>
                          <div className="col-sm-9">
                            <AvField
                              name="comment"
                              type="textarea"
                              className="form-control cursor-pointer"
                              value={about}
                              id="example-data-input"
                              onChange={(e) => {
                                setReview(e.target.value);
                                setFormChanged(true);
                              }}
                            />
                          </div>
                          {showCheckbox && (
                            <div className="row mt-3">
                              <div className="col-sm-3">
                                <p className="mb-0"></p>
                              </div>
                              <div className="col-sm-9">
                                <div className=" d-flex ms-1 ">
                                  <AvField
                                    name="alert"
                                    type="checkbox"
                                    className="form-check-input me-2"
                                    id="example-data-input"
                                    checked={isChecked}
                                    onChange={(e) =>
                                      setIsChecked(e.target.checked)
                                    }
                                  />
                                  <label
                                    htmlFor="example-data-input"
                                    className="form-check-label cursor-pointer mb-0"
                                  >
                                    Tick this box to send an email alert about
                                    suspicious activity to the user.
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3"></div>
                          <div className=" col-sm-9 ">
                            <button
                              type="button"
                              disabled={payout_status === ""}
                              className="btn btn-primary"
                              onClick={(e) => {
                                handleStatusUpdate();
                              }}
                            >
                              Update Status
                            </button>

                            {payout?.payoutRequest_details?.detailed?.status !==
                              "New Request" && (
                                <button
                                  type="button"
                                  className="btn btn-primary ms-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    sendInReviewMail();
                                  }}
                                >
                                  Send Offer Mail
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </AvForm>
                )}
                <div className="card mb-4">
                  <div className="card-body">
                    <span className="text-primary font-italic me-1">
                      History of
                    </span>{" "}
                    Status Updates
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i
                        onClick={() => accordionClickHandler(visibility)}
                        className={`fa fa-angle-${visibility ? "up" : "down"}`}
                        style={{ cursor: "pointer" }}
                        aria-hidden="true"
                      ></i>
                    </span>
                    {visibility && (
                      <>
                        <hr />

                        {payout?.payoutRequest_details?.operations_history &&
                          payout?.payoutRequest_details?.operations_history[0]
                            ?.id ? (
                          payout?.payoutRequest_details?.operations_history?.map(
                            (status, index) => (
                              <div
                                className="status-accordion"
                                key={status?.id}
                              >
                                <div className="row">
                                  <div className="col-sm-12">
                                    <p className="mb-0">
                                      Updated Status to{" "}
                                      <b>
                                        {status.new_status}{" "}
                                        {status.new_status === "Forwarded" && (
                                          <>(to {status.forwarded_to})</>
                                        )}
                                      </b>{" "}
                                      from <b>{status.old_status}</b> on{" "}
                                      <b>{convertTime(status?.time_created)}</b>
                                    </p>
                                    {status.review_comment !== null ? (
                                      <>
                                        <p className="mb-0">
                                          Review Comments:{" "}
                                          {status.review_comment}
                                        </p>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="col-sm-9"></div>
                                </div>
                                <hr />
                              </div>
                            )
                          )
                        ) : (
                          <p> No Comments to Display. </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {dopayment && (
                  <div className="card mb-4">
                    <AvForm>
                      <div className="card-body">
                        <div className="row mb-2">
                          <div className="col-sm-3">
                            <p className="mb-0"></p>
                          </div>
                          <div className="col-sm-9">
                            <p className="text-danger">{paymentMethodError}</p>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-sm-3">
                            <p className="mb-0">Payment Channel</p>
                          </div>
                          <div className="col-sm-9">
                            <select
                              ref={ref}
                              name="payment-option"
                              className="form-control cursor-pointer genderSelectField"
                              onChange={(e) => {
                                setPaymentMethod(e?.target?.value);
                                setPaymentMethodError("");
                              }}
                            >
                              <option value="">Select Status</option>
                              <option value="Bank">BANK TRANSFER</option>
                              <option value="ManualPaypal">
                                MANUAL PAYPAL
                              </option>
                              {/* <option value="Paypal">PAYPAL</option> */}
                              <option value="WesternUnion">
                                WESTERN UNION
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">Message :</p>
                          </div>
                          <div className="col-sm-9 mb-4">
                            <AvField
                              ref={commentRef}
                              name="comments"
                              type="textarea"
                              className="form-control cursor-pointer"
                              value={""}
                              id="example-data-input-1"
                              onChange={(e) => {
                                setCommentValue(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0">
                              Transaction Reference Number :
                            </p>
                          </div>
                          <div className="col-sm-9 mb-4">
                            <AvInput
                              name="referencenum"
                              type="text"
                              // className="form-control cursor-pointer"
                              value={""}
                              id="example-data-input-1"
                              onChange={(e) => {
                                setPaymentRefNo(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0"> Transaction Receipt :</p>
                          </div>
                          <div className="col-sm-9 mb-4">
                            <AvInput
                              name="referencenum"
                              type="file"
                              // className="form-control cursor-pointer"
                              value={""}
                              className="form-control"
                              id="example-data-input-1"
                              onChange={handleReceiptFile}
                            // onChange={(e) => {
                            //   setCommentValue(e.target.value);
                            // }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="mb-0"></p>
                          </div>

                          <div className="col-sm-9">
                            {dopayment && (
                              <button
                                disabled={processDisabled}
                                type="button"
                                style={{ float: "right", marginLeft: "20px" }}
                                className="btn btn-primary"
                                onClick={(e) => {
                                  handlePayment();
                                }}
                              >
                                Process Payment
                              </button>
                            )}

                            {/* <button
                            type="button"
                            className="btn btn-primary"
                            style={{ float: "right" }}
                            onClick={(e) => {
                              handleViewStatement();
                            }}
                          >
                            View Statement
                          </button> */}
                          </div>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                )}
              </div>
              <div className="col-lg-8"></div>
            </div>
          </div>
        </section>
      </Col>

      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {(data?.loading || payout?.loading) && <Loader darkBg={true} />}
    </div>
  );
};

export default EditPayoutRequestDetail;
