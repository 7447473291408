import React, { useState, useEffect, useRef } from "react";
import { Col, Row, UncontrolledAlert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";

import Breadcrumbs from "../../../components/UI/Breadcrumb";

import Loader from "../../../components/Common/Loader";
import { getUserData } from "../../../store/users/actions";
import axios from "axios";
import {
  addSuperstarSuggestion,
  getSuperstarSuggestion,
  deleteSuperstarSuggestion,
} from "../../../store/settings/superstarSuggestion/actions";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import { checkUserPermission } from "../../../helpers/utils";

const SuperStarsSelection = () => {
  const loginData = useSelector((state) => state.Login);

  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.create || false;
  const isDeleteable = userPermissions?.delete || false;
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [index, setIndex] = useState();
  const [success, setSuccess] = useState(false);
  const [successData, setSuccessData] = useState("");
  const data = useSelector((state) => state.user);
  const superStarSuggestion = useSelector((state) => state.superStarSuggestion);
  const [superStarList, setsuperStarList] = useState([
    { user_id: "", rank: "", combined_name: "", name: "", username: "" },
  ]);

  useEffect(() => {
    dispatch(getSuperstarSuggestion());
  }, []);


  useEffect(() => {
    if (loginData?.rolePermissions?.role) {

      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Settings",
          "Superstar suggestion"
        )
      );
    }
  }, [loginData]);

  useEffect(() => {
    superStarSuggestion?.superStarList?.suggested_users?.length > 0
      ? setsuperStarList(
          superStarSuggestion?.superStarList?.suggested_users?.reverse()
        )
      : setsuperStarList([
          { user_id: "", rank: "", combined_name: "", name: "", username: "" },
        ]);
  }, [superStarSuggestion?.superStarList?.suggested_users]);

  const pageWrapRef = useRef(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const basicRequest = {
    sort: "name",
    sort_order: "asc",
    status_filter: ["Active"],
  };

  useEffect(() => {
    superStarList?.length &&
      superStarList?.map((star) => {
        if (star.username == "") {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      });
  }, [superStarList]);

  let [request, setRequest] = useState({ ...basicRequest });

  useEffect(() => {
    request && dispatch(getUserData(request));
  }, [request]);

  // handle input change
  const handlesuperStarInputChange = (id, index, name, username) => {
    const list = [...superStarList];
    list[index]["user_id"] = id;
    list[index]["rank"] = index + 1;
    list[index]["name"] = name;
    list[index]["username"] = username;
    list[index]["combined_name"] = name + "(" + username + ")";
    setsuperStarList(list);
  };

  // handle click event of the delete button
  const handlesuperStarDeleteClick = (e, index, id) => {
    e.preventDefault();
    const list = [...superStarList];
    list.splice(index, 1);
    id != "" &&
      dispatch(
        deleteSuperstarSuggestion({
          data: { suggested_users: [id] },
          callback: () => {
            setSuccess(true);
            setError("");
          },
        })
      );
    for (let i = index; i < list.length; i++) {
      list[i]["rank"] = i + 1;
    }
    setsuperStarList(list);
  };

  // handle click event of the Add button
  const handlesuperStarAddClick = (e) => {
    setsuperStarList([
      ...superStarList,
      { user_id: "", rank: "", combined_name: "", name: "", username: "" },
    ]);
  };

  // handle submit event

  const handleSubmit = (e) => {
    e.preventDefault();
    if (superStarList.length > 1) {
      for (let i = 0; i < superStarList.length - 1; i++) {
        for (let j = i + 1; j < superStarList.length; j++) {
          if (superStarList[i]["user_id"] == superStarList[j]["user_id"]) {
            return setError("Superstars must be unique");
          }
        }
      }
    }

    dispatch(
      addSuperstarSuggestion({
        data: { suggested_users: superStarList },
        callback: () => {
          dispatch(getSuperstarSuggestion());
          setSuccess(true);
          setSuccessData("Admin suggested stars updated ");
          setError("");
        },
      })
    );
  };

  // handle click event of delete all button

  const handleDeleteAll = (e) => {
    e.preventDefault();
    const arr1 = [];
    superStarList?.map((star) => arr1.push(star.user_id));
    dispatch(
      deleteSuperstarSuggestion({
        data: { suggested_users: arr1 },
        callback: () => {
          setsuperStarList([
            {
              user_id: "",
              rank: "",
              combined_name: "",
              name: "",
              username: "",
            },
          ]);
          setSuccess(true);
          setError("");
        },
      })
    );
  };

  return (
    <div className="page-content" id="editAllocationUserName" ref={pageWrapRef}>
      <Breadcrumbs
        title="Tables"
        breadcrumbItem={t("SuperStarSelection.Title")}
      />

      {success && (
        <div>
          <UncontrolledAlert
            color="success"
            className="alert-dismissible fade show"
            role="alert"
          >
            {superStarSuggestion?.success}
          </UncontrolledAlert>
        </div>
      )}

      {data?.error && (
        <div>
          <UncontrolledAlert
            color="danger"
            className="alert-dismissible fade show"
            role="alert"
          >
            {data?.error}
          </UncontrolledAlert>
        </div>
      )}

      {error && (
        <div>
          <UncontrolledAlert
            color="danger"
            className="alert-dismissible fade show"
            role="alert"
          >
            {error}
          </UncontrolledAlert>
        </div>
      )}
      <AvForm
        onValidSubmit={(e, v) => {
          handleSubmit(e, v);
        }}
      >
        <div className="mt-4">
          <Row className="col-md-7">
            {superStarList?.length &&
              superStarList?.map((x, i) => (
                <Row
                  className="col-md-12 addUsernameFieldWrap icon-align"
                  key={i}
                >
                  <Col xl={6} sm={10}>
                    <label
                      htmlFor="superstar"
                      className="col-md-8 col-form-label"
                    >
                      {t("SuperStarSelection.superstar")} {i + 1}
                      <span className="mandatory">*</span>
                    </label>
                    <div>
                      <AvInput
                        name="user_id"
                        autoComplete="off"
                        className="form-control admin-select-field "
                        value={x.combined_name}
                        placeholder="Search"
                        id="superstar"
                        onChange={(e) => {
                          setSuccess(false);
                          setInputVal(e.target.value);
                          setIndex(i);
                          {
                            e.target.value &&
                              setRequest({
                                ...request,
                                keyword: e.target.value,
                              });
                          }
                        }}
                      />
                    </div>
                  </Col>
                  <Col xl={6} md={4} className="super-star-btn-container">
                    {superStarList?.length - 1 === i && (
                      <>
                        <button
                          onClick={(e) => {
                            setSuccess(false);
                            handlesuperStarAddClick();
                          }}
                          className="btn btn-primary mx-3"
                          title="Add More"
                          disabled={!isEditable}
                        >
                          Add More
                        </button>
                      </>
                    )}
                    {superStarList?.length !== 1 && (
                      <button
                        className="btn btn-danger mx-3"
                        onClick={(e) => {
                          setSuccess(false);
                          handlesuperStarDeleteClick(e, i, x.user_id);
                        }}
                        title="Delete"
                        disabled={!isDeleteable}
                      >
                        Delete
                      </button>
                    )}
                  </Col>
                  <div style={{ position: "absolute" }}>
                    {inputVal.length && index == i ? (
                      <ul
                        className={`${
                          data?.data?.users?.length
                            ? "superstar-suggestion-list-container"
                            : "superstar-suggestion-empty-container"
                        } col-md-6`}
                      >
                        {data?.data?.users?.length
                          ? data?.data?.users?.map((user) => (
                              <li
                                key={user.id}
                                className=" superstar-suggestion-list"
                                value={user.id}
                              >
                                <a
                                  className="cursor-pointer superstar-suggestion-list_link"
                                  onClick={() => {
                                    handlesuperStarInputChange(
                                      user.id,
                                      i,
                                      user.name,
                                      user.username
                                    );
                                    setInputVal("");
                                  }}
                                >
                                  {user.name}({user.username})
                                </a>
                              </li>
                            ))
                          : !data?.loading && (
                              <li className="superstar-suggestion-list">
                                No data found
                              </li>
                            )}{" "}
                      </ul>
                    ) : null}
                  </div>
                </Row>
              ))}
            <Col xl={8}>
              <button
                className="btn btn-primary mx-1"
                title="Submit"
                disabled={disabled || !isEditable ? true : false}
              >
                Submit
              </button>
              {superStarList?.length > 1 && (
                <button
                  className="btn btn-danger mx-1"
                  disabled={disabled || !isDeleteable ? true : false}
                  onClick={handleDeleteAll}
                >
                  Delete All
                </button>
              )}
            </Col>
          </Row>
        </div>
      </AvForm>
      {/* {data?.loading && <Loader darkBg={true} />} */}
      {superStarSuggestion?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default SuperStarsSelection;
