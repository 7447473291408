import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

import PhoneField from '../../../../components/UI/PhoneField';
import Button from "../../../../components/UI/Button";

import { EMAIL_REGEX } from "../../../../helpers/constants";

const GetUserForm = ({
  selected,
  setGetUserDisabled,
  getUserDisabled,
  setMobile,
  setCountryCode,
  onValidSubmit,
  searchUsername,
  setSearchUsername
}) => {
  const { t } = useTranslation();
  let handleCountryChangeHandler = (e, data) => {
    setCountryCode(data?.value)
  }

  let handlePhoneNumberChangeHandler = (e) => {
    setMobile(e.target.value)
  }

  return (
    <AvForm
      onValidSubmit={(e, v) => {
        onValidSubmit(e, v);
      }}
    >
      <div className="mb-4 mt-4 allocateUsernameFieldWrapper">
        {/* <div className="col-md-4"> */}
        {selected === "1" && (
          <AvField
            className="userNameWrap"
            name="username"
            label={
              <>
                {t("AllocateUserName.UserName")}
                <span className="mandatory">*</span>
              </>
            }
            placeholder={t("AllocateUserName.UserName")}
            type="text"
            onChange={(e) => {
              setSearchUsername(e.target.value)
              e.target.value
                ? setGetUserDisabled(false)
                : setGetUserDisabled(true)
            }
            }
            value={searchUsername}
          />
        )}
        {selected === "2" && (
          <div>
            <PhoneField
              labelName={t("ReserveUserName.enterMobile")}
              countryPlaceholder="Select Country Code"
              phonePlaceholder={t("ReserveUserName.enterMobile")}
              handleCountryChange={handleCountryChangeHandler}
              handlePhoneNumberChange={handlePhoneNumberChangeHandler}
              mandatory={true}
            />
          </div>
        )}
        {selected === "3" && (
          <AvField
            className="emailFieldWrap"
            name="email"
            label={
              <>
                {t("AllocateUserName.EmailAddress")}
                <span className="mandatory">*</span>
              </>
            }
            placeholder={t("ReserveUserName.enterEmail")}
            type="text"
            onChange={(e) =>
              e.target.value && new RegExp(EMAIL_REGEX).test(e.target.value)
                ? setGetUserDisabled(false)
                : setGetUserDisabled(true)
            }
          />
        )}
        {/* </div> */}
        <div className="getUserDetailsWrap">
          <Button
            buttonClass={
              "btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
            }
            label={t("AllocateUserName.addNew.GetUserDetails")}
            disableBtn={getUserDisabled}
          />
        </div>
      </div>
    </AvForm>
  );
};

export default GetUserForm;
