/* LEGAL DOCUMENTS */
export const GET_LEGAL_DOCUMENTS = "GET_LEGAL_DOCUMENTS";
export const GET_LEGAL_DOCUMENTS_BEGIN = "GET_LEGAL_DOCUMENTS_BEGIN";
export const GET_LEGAL_DOCUMENTS_SUCCESS = "GET_LEGAL_DOCUMENTS_SUCCESS";
export const GET_LEGAL_DOCUMENTS_FAIL = "GET_LEGAL_DOCUMENTS_FAIL";

export const EDIT_LEGAL_DOCUMENTS = "EDIT_LEGAL_DOCUMENTS";
export const EDIT_LEGAL_DOCUMENTS_BEGIN = "EDIT_LEGAL_DOCUMENTS_BEGIN";
export const EDIT_LEGAL_DOCUMENTS_SUCCESS = "EDIT_LEGAL_DOCUMENTS_SUCCESS";
export const EDIT_LEGAL_DOCUMENTS_FAIL = "EDIT_LEGAL_DOCUMENTS_FAIL";

export const UPDATE_LEGAL_DOCUMENTS = "UPDATE_LEGAL_DOCUMENTS";
export const UPDATE_LEGAL_DOCUMENTS_BEGIN = "UPDATE_LEGAL_DOCUMENTS_BEGIN";
export const UPDATE_LEGAL_DOCUMENTS_SUCCESS = "UPDATE_LEGAL_DOCUMENTS_SUCCESS";
export const UPDATE_LEGAL_DOCUMENTS_FAIL = "UPDATE_LEGAL_DOCUMENTS_FAIL";

export const VIEW_PUBLISHED_BEGIN = "VIEW_PUBLISHED_BEGIN";
export const VIEW_PUBLISHED_SUCCESS = "VIEW_PUBLISHED_SUCCESS";
export const VIEW_PUBLISHED_FAIL = "VIEW_PUBLISHED_FAIL";

export const DELETE_LEGAL_DOCUMENT = "DELETE_LEGAL_DOCUMENT";
export const DELETE_LEGAL_DOCUMENT_BEGIN = "DELETE_LEGAL_DOCUMENT_BEGIN";
export const DELETE_LEGAL_DOCUMENT_SUCCESS = "DELETE_LEGAL_DOCUMENT_FAIL";
export const DELETE_LEGAL_DOCUMENT_FAIL = "DELETE_LEGAL_DOCUMENT_FAIL";

export const ENABLE_DISABLE_LEGAL_DOCUMENTS = "ENABLE_DISABLE_LEGAL_DOCUMENTS";
export const ENABLE_DISABLE_LEGAL_DOCUMENTS_BEGIN = "ENABLE_DISABLE_LEGAL_DOCUMENTS_BEGIN";
export const ENABLE_DISABLE_LEGAL_DOCUMENTS_SUCCESS = "ENABLE_DISABLE_LEGAL_DOCUMENTS_SUCCESS";
export const ENABLE_DISABLE_LEGAL_DOCUMENTS_FAIL = "ENABLE_DISABLE_LEGAL_DOCUMENTS_FAIL";
