/* PAYOUT REQUESTS */
export const STORE_PAYOUT_REQUEST = "STORE_PAYOUT_REQUEST";

export const GET_PAYOUT_REQUESTS = "GET_PAYOUT_REQUESTS";
export const GET_PAYOUT_REQUESTS_BEGIN = "GET_PAYOUT_REQUESTS_BEGIN";
export const GET_PAYOUT_REQUESTS_SUCCESS = "GET_PAYOUT_REQUESTS_SUCCESS";
export const GET_PAYOUT_REQUESTS_FAIL = "GET_PAYOUT_REQUESTS_FAIL";

export const EDIT_PAYOUT_REQUEST = "EDIT_PAYOUT_REQUEST";
export const EDIT_PAYOUT_REQUEST_BEGIN = "EDIT_PAYOUT_REQUEST_BEGIN";
export const EDIT_PAYOUT_REQUEST_SUCCESS = "EDIT_PAYOUT_REQUEST_SUCCESS";
export const EDIT_PAYOUT_REQUEST_FAIL = "EDIT_PAYOUT_REQUEST_FAIL";

export const UPDATE_PAYOUT_REQUEST = "UPDATE_PAYOUT_REQUEST";
export const UPDATE_PAYOUT_REQUEST_BEGIN = "UPDATE_PAYOUT_REQUEST_BEGIN";
export const UPDATE_PAYOUT_REQUEST_SUCCESS = "UPDATE_PAYOUT_REQUEST_SUCCESS";
export const UPDATE_PAYOUT_REQUEST_FAIL = "UPDATE_PAYOUT_REQUEST_FAIL";

export const PROCESS_PAYMENT = "PROCESS_PAYMENT";
export const PROCESS_PAYMENT_BEGIN = "PROCESS_PAYMENT_BEGIN";
export const PROCESS_PAYMENT_SUCCESS = "PROCESS_PAYMENT_SUCCESS";
export const PROCESS_PAYMENT_FAIL = "PROCESS_PAYMENT_FAIL";

/* PAYOUT STATUS */
export const GET_PAYOUT_STATUS = "GET_PAYOUT_STATUS";
export const GET_PAYOUT_STATUS_BEGIN = "GET_PAYOUT_STATUS_BEGIN";
export const GET_PAYOUT_STATUS_SUCCESS = "GET_PAYOUT_STATUS_SUCCESS";
export const GET_PAYOUT_STATUS_FAIL = "GET_PAYOUT_STATUS_FAIL";

export const ADD_PAYOUT_STATUS = "ADD_PAYOUT_STATUS";
export const ADD_PAYOUT_STATUS_BEGIN = "ADD_PAYOUT_STATUS_BEGIN";
export const ADD_PAYOUT_STATUS_SUCCESS = "ADD_PAYOUT_STATUS_SUCCESS";
export const ADD_PAYOUT_STATUS_FAIL = "ADD_PAYOUT_STATUS_FAIL";

export const EDIT_PAYOUT_STATUS = "EDIT_PAYOUT_STATUS";
export const EDIT_PAYOUT_STATUS_BEGIN = "EDIT_PAYOUT_STATUS_BEGIN";
export const EDIT_PAYOUT_STATUS_SUCCESS = "EDIT_PAYOUT_STATUS_SUCCESS";
export const EDIT_PAYOUT_STATUS_FAIL = "EDIT_PAYOUT_STATUS_FAIL";

export const UPDATE_PAYOUT_STATUS = "UPDATE_PAYOUT_STATUS";
export const UPDATE_PAYOUT_STATUS_BEGIN = "UPDATE_PAYOUT_STATUS_BEGIN";
export const UPDATE_PAYOUT_STATUS_SUCCESS = "UPDATE_PAYOUT_STATUS_SUCCESS";
export const UPDATE_PAYOUT_STATUS_FAIL = "UPDATE_PAYOUT_STATUS_FAIL";

export const DELETE_PAYOUT_STATUS = "DELETE_PAYOUT_STATUS";
export const DELETE_PAYOUT_STATUS_BEGIN = "DELETE_PAYOUT_STATUS_BEGIN";
export const DELETE_PAYOUT_STATUS_SUCCESS = "DELETE_PAYOUT_STATUS_SUCCESS";
export const DELETE_PAYOUT_STATUS_FAIL = "DELETE_PAYOUT_STATUS_FAIL";

export const GET_PAYOUT_STATEMENT = "GET_PAYOUT_STATEMENT";
export const GET_PAYOUT_STATEMENT_BEGIN = "GET_PAYOUT_STATEMENT_BEGIN";
export const GET_PAYOUT_STATEMENT_SUCCESS = "GET_PAYOUT_STATEMENT_SUCCESS";
export const GET_PAYOUT_STATEMENT_FAIL = "GET_PAYOUT_STATEMENT_FAIL";

export const SEND_IN_REVIEW_EMAIL = "SEND_IN_REVIEW_EMAIL";
export const SEND_IN_REVIEW_EMAIL_BEGIN = "SEND_IN_REVIEW_EMAIL_BEGIN";
export const SEND_IN_REVIEW_EMAIL_SUCCESS = "SEND_IN_REVIEW_EMAIL_SUCCESS";
export const SEND_IN_REVIEW_EMAIL_FAIL = "SEND_IN_REVIEW_EMAIL_FAIL";

export const UPDATE_PAYOUT_PAYMENT_DETAILS = "UPDATE_PAYOUT_PAYMENT_DETAILS"
export const UPDATE_PAYOUT_PAYMENT_DETAILS_BEGIN = "UPDATE_PAYOUT_PAYMENT_DETAILS_BEGIN"
export const UPDATE_PAYOUT_PAYMENT_DETAILS_SUCCESS = "UPDATE_PAYOUT_PAYMENT_DETAILS_SUCCESS"
export const UPDATE_PAYOUT_PAYMENT_DETAILS_FAIL = "UPDATE_PAYOUT_PAYMENT_DETAILS_FAIL"