import AvInput from "availity-reactstrap-validation/lib/AvInput";
import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import LanguageDropDown from "./LanguageDropDown";

const DefaultField = ({
  input,
  index,
  inputs,
  addNewField,
  removeField,
  handleInputChange,
  handleAddOption,
  handleRemoveOption,
  setInputs,
}) => {
  const [inputTypes, setInputTypes] = useState([]);

  useEffect(() => {
    setInputTypes(inputs.map((input) => input?.input_type));
  }, [inputs]);

  useEffect(() => {
    if (
      inputTypes[index] === "text" ||
      inputTypes[index] === "file" ||
      inputTypes[index] === "phone"
    ) {
      // Create a new array with two empty objects
      const updatedInputs = [...inputs];
      if (!updatedInputs[index].options?.length) {
        updatedInputs[index].options = [
          { input_type_value: "", listing_order: "" },
          { input_type_value: "", listing_order: "" },
        ];
        // Update the state with the new inputs array
        setInputs(updatedInputs);
      }
    }
  }, [inputTypes[index]]);

  // const handleAddInput = () => {
  //   setOptions([...options, Date.now()]);
  //   console.log("op", options);
  // };
  // const handleRemoveInput = (index) => {
  //   if (index > 1) {
  //     const updatedOptions = [...options];
  //     updatedOptions.splice(index, 1);
  //     setOptions(updatedOptions);
  //   }
  // };

  return (
    <Row className="my-5 position-relative border border-1 p-3 pt-5 relative shadow-sm ">
      <div className="d-flex justify-content-end position-absolute top-0 end-0   ">
        {" "}
        {index === inputs?.length - 1 && (
          <button
            type="button"
            onClick={addNewField}
            className="btn btn-success mx-3 mt-3 "
          >
            Add more +
          </button>
        )}
        {index > 0 && (
          <button
            type="button"
            onClick={() => removeField(index)}
            className="btn btn-danger mx-3 mt-3 "
          >
            Remove -
          </button>
        )}
      </div>
      {/* <div className="d-flex justify-content-end position-absolute top-0 end-0  ">
        {" "}
        <button className="btn btn-danger mx-5 my-2 ">Remove</button>
      </div> */}

      <div className="row mt-3 align-items-center" id="input-type">
        <div className="col-md-2 ">
          <label className="col-md-8 col-form-label">
            Input Type
            <span className="mandatory">*</span>
          </label>
          <select
            name="input_type"
            className="form-control mb-4"
            id="huddleType"
            // ref={ref}
            value={inputs[index]?.input_type}
            onChange={(e) => {
              const newInputTypes = [...inputTypes];
              newInputTypes[index] = e?.target?.value;
              setInputTypes(newInputTypes);
              handleInputChange(index, e);
            }}
          >
            <option value="">Select input type</option>
            <option value="drop-down">Dropdown</option>
            <option value="text">Text</option>
            <option value="checkbox">Checkbox</option>
            <option value="radio">Radio</option>
            <option value="file">File</option>
            <option value="phone">Phone</option>
          </select>
        </div>
        <div className="col-md-3">
          <label className="col-md-12 col-form-label">
            Enter Label
            <span className="mandatory">*</span>
          </label>
          <div className="col-md-12 dobWrapper">
            <AvInput
              name="input_label"
              type="text"
              className="form-control mb-4 "
              value={inputs[index]?.input_label}
              id="name-input"
              onChange={(e) => {
                handleInputChange(index, e);
              }}
              placeholder="Enter label text"
            />
          </div>
        </div>
        <div className="col-md-3">
          <label className="col-md-12 col-form-label">
            Enter info text
            <span className="mandatory">*</span>
          </label>
          <div className="col-md-12 dobWrapper">
            <AvInput
              name="input_infotext"
              type="text"
              className="form-control mb-4 "
              value={inputs[index]?.input_infotext}
              id="name-input"
              onChange={(e) => {
                handleInputChange(index, e);
              }}
              placeholder="Enter info text"
            />
          </div>
        </div>
        <div className="col-md-3">
          <label className="col-md-12 col-form-label">
            Enter Placeholder
            <span className="mandatory">*</span>
          </label>
          <div className="col-md-12 dobWrapper">
            <AvInput
              name="input_placeholder"
              type="text"
              className="form-control mb-4 "
              value={inputs[index]?.input_placeholder}
              id="name-input"
              onChange={(e) => {
                handleInputChange(index, e);
              }}
              placeholder="Enter placeholder"
            />
          </div>
        </div>
        <div className="col-md-1">
          <label className="col-md-12 col-form-label">
            Order
            <span className="mandatory">*</span>
          </label>
          <div className="col-md-12 dobWrapper">
            <AvInput
              name="listing_order"
              type="number"
              className="form-control mb-4 "
              value={inputs[index]?.listing_order}
              id="name-input"
              onChange={(e) => {
                handleInputChange(index, e);
              }}
              placeholder="Enter order"
            />
          </div>
        </div>
      </div>
      {(inputTypes[index] === "drop-down" ||
        inputTypes[index] === "checkbox" ||
        inputTypes[index] === "radio") && (
        <div className="row  align-items-center" id="input-type">
          <div className="col-md-12">
            <p
              style={{
                fontWeight: "bold",
              }}
              className="font-xs"
            >
              Options
            </p>
          </div>
          <div className="row align-items-center">
            {inputs[index]?.options?.map((option, optionIndex) => (
              <div key={optionIndex} className="row align-items-center">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6 input-field">
                      <label className="col-md-12 col-form-label">
                        {`option ${optionIndex + 1}`}
                        <span className="mandatory">*</span>
                      </label>
                      <div className="col-md-12 dobWrapper">
                        <AvInput
                          name="input_type_value"
                          type="text"
                          className="form-control mb-4 "
                          value={option?.input_type_value}
                          id="name-input"
                          onChange={(e) => {
                            const newInputs = [...inputs];
                            newInputs[index].options[
                              optionIndex
                            ].input_type_value = e.target.value;
                            setInputs(newInputs);
                          }}
                          placeholder="Enter options"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-6 input-field">
                        <label className="col-md-12 col-form-label">
                          order
                          <span className="mandatory">*</span>
                        </label>
                        <div className="col-md-12 dobWrapper">
                          <AvInput
                            name="listing_order"
                            type="number"
                            className="form-control mb-4 "
                            value={option?.listing_order}
                            id="name-input"
                            onChange={(e) => {
                              const newInputs = [...inputs];
                              newInputs[index].options[
                                optionIndex
                              ].listing_order = e.target.value;
                              setInputs(newInputs);
                            }}
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 mt-3  buttons">
                      <div className="row mt-4 ">
                        {optionIndex === inputs[index]?.options?.length - 1 && (
                          <div className="col-md-6">
                            <button
                              className="btn btn-success"
                              type="button"
                              onClick={() => handleAddOption(index)}
                            >
                              +
                            </button>
                          </div>
                        )}
                        {optionIndex > 1 && (
                          <div className="col-md-4">
                            <button
                              className="btn btn-danger"
                              type="button"
                              onClick={() =>
                                handleRemoveOption(index, optionIndex)
                              }
                            >
                              -
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Row>
  );
};

export default DefaultField;
