import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardBody, Row } from "reactstrap";
import { Container } from "semantic-ui-react";
import {
  postAtReportedPostColumnData,
  postAtReportsColumnData,
} from "../../../helpers/columns";
import { getPostReportsById } from "../../../store/postatManagement/actions";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Datatable from "../../../components/UI/Tables/Datatable";
import Loader from "../../../components/Common/Loader";
import { useLocation } from "react-router";
import { convertTime } from "../../../helpers/utils";
import BackButton from "../../../components/UI/BackButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getCategoryData } from "../../../store/reportedUser/action";
import { Card } from "@material-ui/core";
import { ImagePreview } from "../../../components/Common/imagePreview";
import {
  getHuddleReportedUsersCategories,
  getHuddlesCategories,
} from "../../../store/huddles/actions";

const PostReports = () => {
  const location = useLocation();
  const history = useHistory();
  const basicRequest = {
    // sort: "transfered_date",
    // sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
    postatId: location?.state?.postId,
  };
  //   const history = useHistory();
  const dispatch = useDispatch();

  const [imgUrl, setImgUrl] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [request, setRequest] = useState({ ...basicRequest });
  useEffect(() => {
    dispatch(getPostReportsById(request));
    dispatch(getHuddleReportedUsersCategories());
  }, [request]);

  const previewImage = (url) => {
    setOpen(true);
    setImgUrl(url);
  };

  const data = useSelector((state) => state?.ReportedPostatPosts);
  const reportCategories = useSelector((state) => state?.Huddles);

  const formatPostReports = useCallback(() => {
    return (
      reportCategories?.huddlereportedUserCategories?.categories?.length > 0 &&
      data?.postReports?.postat_reports?.length > 0 &&
      data?.postReports?.postat_reports?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        reported_by: (
          <div data-tag="allowRowEvents" title={result?.post_owner_info?.name}>
            {result?.reporter_info?.name}
          </div>
        ),
        comment: (
          <div data-tag="allowRowEvents" title={result?.report_message}>
            {result?.report_message}
          </div>
        ),
        reason: (
          <div data-tag="allowRowEvents" title={result?.message}>
            {reportCategories?.huddlereportedUserCategories?.categories
              ?.filter(
                (category) => category?.id === result?.report_category_id
              )
              ?.map((filteredCategory) => (
                <div key={filteredCategory?.id}>
                  {filteredCategory.category}
                </div>
              ))[0] || <div>null</div>}
          </div>
        ),

        reported_date: (
          <div data-tag="allowRowEvents" title={"reported date"}>
            {convertTime(result?.time_created)}
          </div>
        ),
      }))
    );
  }, [
    request,
    data?.postReports?.postat_reports,
    reportCategories?.huddlereportedUserCategories?.categories,
  ]);
  const postReports = useMemo(() => formatPostReports(), [formatPostReports]);

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        // id="userMgmtList"
        // ref={pageWrapRef}
      >
        <BackButton
          label="Reported Posts"
          handleClick={() =>
            history.push({
              pathname: "/postat-reported-posts",
              state: { from: "keepSearchText" },
            })
          }
        />
        <Container>
          {data?.postReports?.postat_reports?.length > 0 && (
            <div className=" ">
              <Card className="appSettingCard mt-3">
                <CardBody>
                  <div className="huddle-gen-info  py-5   d-flex  ">
                    <div className="col-6">
                      <h5 className="huddle-titles mb-3  ">
                        <span className="text-secondary">Post Owner : </span>
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            const win = window.open(
                              `/user-details/${data?.postReports?.postat_reports[0].post_owner}?tab=1`,
                              "_blank"
                            );
                            win.focus();
                          }}
                          className="text-black ms-2 cursor-pointer"
                        >
                          {data?.postReports?.postInfo?.post_owner_info.name}
                        </span>
                      </h5>

                      <h5 className=" huddle-titles   ">
                        <span className="text-secondary"> Reports Count: </span>{" "}
                        <span className="text-black ms-2">
                          {data?.postReports?.total}
                        </span>
                      </h5>
                    </div>
                    <div className="d-flex  ">
                      <img
                        onClick={(e) => {
                          e.preventDefault();
                          previewImage(
                            data?.postReports?.postat_reports[0]
                              ?.media_thumbnails[0]
                          );
                        }}
                        src={
                          data?.postReports?.postat_reports[0]
                            ?.media_thumbnails[0]
                        }
                        width={200}
                        height={200}
                        className="mr-5 editAdmin-profile-pic  cursor-pointer"
                        style={{ borderRadius: "8%" }}
                        // onClick={() => setmodalShow(true)}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
              <ImagePreview
                imgUrl={imgUrl}
                isOpen={isOpen}
                setOpen={setOpen}
                title="Reported Post"
              />
            </div>
          )}
          <Row className="mt-5">
            <Breadcrumbs title="Tables" breadcrumbItem="Post Reports" />
            <div className="container-fluid">
              <Datatable
                tableID={"adminTable"}
                columns={postAtReportsColumnData}
                rows={postReports}
                setRequest={setRequest}
                request={request}
                // dateFilter
                totalRecords={data?.postReports?.total}
                // search
                // dateType="Purchased Date Range"
                // add
                // dropDown
                // languages={languages}
                // addNewClickHandler={addNewClickHandler}
                // selectedLanguage={selectedLanguage}
                // setSelectedLanguage={setSelectedLanguage}
                // addNewLabel={t("Admins.addAdmin.addBtn")}
                // searchTerm={data?.request?.keyword}
                // loading={data?.loading}
                // tableRowClicked={(param) => tableRowClickHandler(param)}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
        </Container>

        {(data?.loading || reportCategories?.loading) && (
          <Loader darkBg={true} />
        )}
      </div>
    </>
  );
};

export default PostReports;
