import { Visibility } from "semantic-ui-react";
import i18n from "../i18n";

export const userColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: (
      <div>
        <div>{i18n.t("UserManagement.column.profile")}</div>
        <div>{i18n.t("UserManagement.column.img")}</div>
      </div>
    ),
    selector: "profile_image",
    width: 100 + "px",
  },
  {
    name: (
      <div>
        <div>Name</div>
        <div>(Username)</div>
      </div>
    ),
    selector: "name",
    sortable: true,
    minWidth: 200 + "px",
  },
  {
    name: "Manual FLiX",
    selector: "manual_pp",
    sortable: false,
    width: 150 + "px",
  },
  {
    name: "Phone/Email",
    selector: "phone",
    minWidth: 200 + "px",
  },
  {
    name: "Status",
    selector: "status",
    width: 70 + "px",
  },
  {
    name: "Superstar",
    selector: "superstar",
    sortable: true,
    width: 100 + "px",
  },
  {
    name: "Country",
    selector: "country",
    // sortable: true,
    width: 100 + "px",
  },
  {
    name: "Created On",
    selector: "created_on",
    sortable: true,
    width: 150 + "px",
  },
  {
    name: "Subscribed On",
    selector: "payment_date",
    sortable: true,
    width: 150 + "px",
  },
  {
    name: "Expiry Date",
    selector: "subsctiption_expiry_date",
    sortable: true,
    width: 150 + "px",
  },

  {
    name: "Rating",
    selector: "rating",
    // sortable: true,
    width: 70 + "px",
  },

  // {
  //   name: "Actions",
  //   width: 175 + "px",
  //   selector: "actions",
  //   ignoreRowClick: true,
  // },
];

export const userCoulumnForNonPremium = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: (
      <div>
        <div>{i18n.t("UserManagement.column.profile")}</div>
        <div>{i18n.t("UserManagement.column.img")}</div>
      </div>
    ),
    selector: "profile_image",
    width: 100 + "px",
  },
  {
    name: (
      <div>
        <div>Name</div>
        <div>(Username)</div>
      </div>
    ),
    selector: "name",
    sortable: true,
    minWidth: 150 + "px",
  },
  {
    name: "Phone/Email",
    selector: "phone",
    minWidth: 200 + "px",
  },
  {
    name: "Status",
    selector: "status",
    width: 70 + "px",
  },
  {
    name: "Superstar",
    selector: "superstar",
    sortable: true,
    width: 100 + "px",
  },
  {
    name: "Country",
    selector: "country",
    // sortable: true,
    width: 100 + "px",
  },
  {
    name: "Created On",
    selector: "created_on",
    sortable: true,
    width: 150 + "px",
  },
  {
    name: "Rating",
    selector: "rating",
    // sortable: true,
    width: 70 + "px",
  },
];

export const payoutrequestColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: (
      <div>
        <div>{i18n.t("UserManagement.column.profile")}</div>
        <div>{i18n.t("UserManagement.column.img")}</div>
      </div>
    ),
    selector: "profile_image",
    width: 100 + "px",
  },
  {
    name: (
      <div>
        <div>Name</div>
        <div>(Username)</div>
      </div>
    ),
    selector: "name",
    // sortable: true,
    width: 200 + "px",
  },

  {
    name: "Payment Method",
    selector: "payment_method",
    width: 200 + "px",
  },
  {
    name: "Requested On",
    selector: "requested_on",
    // sortable: true,
    width: 200 + "px",
  },
  {
    name: "Status",
    selector: "status",
    width: 250 + "px",
  },

  {
    name: "Requested FLiX",
    selector: "pp",
    width: 150 + "px",
    // sortable: true,
  },
];

export const payoutstatusColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: (
      <div>
        <div>Name of Status</div>
      </div>
    ),
    selector: "statusname",
    sortable: true,
    width: 300 + "px",
  },

  {
    name: "Active",
    selector: "active",
    width: 250 + "px",
  },
  {
    name: "Created On",
    selector: "created_on",
    sortable: true,
    width: 300 + "px",
  },
  {
    name: "Actions",
    width: 150 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const allocateUsernameColumnsData = [
  {
    name: i18n.t("AllocateUserName.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: i18n.t("AllocateUserName.column.phone"),
    selector: "phone",
    width: 200 + "px",
  },
  {
    name: i18n.t("ReserveUserName.email"),
    selector: "email",
    minWidth: 200 + "px",
  },
  {
    name: i18n.t("AllocateUserName.column.username"),
    selector: "current_username",
    sortable: true,
    minWidth: 200 + "px",
  },
  {
    name: i18n.t("AllocateUserName.column.suggested"),
    selector: "suggested_username",
    minWidth: 200 + "px",
  },
  {
    name: i18n.t("UserManagement.status"),
    selector: "status",
    width: 150 + "px",
  },
  {
    name: "Actions",
    selector: "actions",
    width: 100 + "px",
  },
];

export const reserveUsernameColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Username",
    selector: "username",
    sortable: true,
    minWidth: 200 + "px",
  },
  {
    name: "Reserved for",
    selector: "reserved_for",
    minWidth: 200 + "px",
  },
  {
    name: "Modified On",
    selector: "modified_on",
    sortable: true,
    width: 200 + "px",
  },
  {
    name: "Actions",
    selector: "actions",
    width: 100 + "px",
  },
];

export const registrationLoginHistoryColumnData = [
  {
    name: i18n.t("UserManagement.column.deviceLocation"),
    selector: "device_and_location",
    Width: 400 + "px",
  },
  {
    name: i18n.t("UserManagement.column.loginTime"),
    selector: "login_time",
    Width: 250 + "px",
  },
  {
    name: i18n.t("UserManagement.column.logoffTime"),
    selector: "logout_time",
    sortable: true,
    Width: 150 + "px",
  },
];

export const documentsColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: i18n.t("legalDocuments.Title"),
    selector: "title",
    width: 150 + "px",
  },
  {
    name: i18n.t("legalDocuments.Description"),
    selector: "description",
    minWidth: 400 + "px",
    maxWidth: 400 + "px",
    wrap: "yes",
  },
  {
    name: i18n.t("legalDocuments.CreatedOn"),
    selector: "time_created",
    Width: 250 + "px",
  },
  {
    name: i18n.t("legalDocuments.LastModifiedOn"),
    selector: "time_updated",
    sortable: true,
    Width: 250 + "px",
  },
  {
    name: i18n.t("legalDocuments.Status"),
    selector: "status",
    sortable: true,
    Width: 90 + "px",
  },
  {
    name: i18n.t("legalDocuments.UserNotified"),
    selector: "userNotified",
    Width: 90 + "px",
  },
  {
    name: i18n.t("legalDocuments.Actions"),
    selector: "action",
    Width: 100 + "px",
  },
];

export const templateColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: i18n.t("settings.templateTitle"),
    selector: "templateTitle",
    width: 350 + "px",
  },
  {
    name: i18n.t("settings.subject"),
    selector: "subject",
    minWidth: 350 + "px",
  },
  {
    name: i18n.t("settings.createdOn"),
    selector: "created_on",
    minWidth: 100 + "px",
  },
  {
    name: i18n.t("settings.actions"),
    selector: "action",
    minWidth: 70 + "px",
  },
];

export const subscriptionColumnData = [
  {
    name: i18n.t("subscription.column.paymentDate"),
    selector: "payment_date",
    width: 170 + "px",
  },
  {
    name: i18n.t("subscription.column.mode"),
    selector: "payment_method",
    width: 150 + "px",
  },
  {
    name: i18n.t("subscription.column.platform"),
    selector: "payment_platform",
    width: 150 + "px",
  },
  {
    name: i18n.t("subscription.column.amount"),
    selector: "payment_amount",
    width: 150 + "px",
  },
  {
    name: i18n.t("subscription.column.expiry"),
    selector: "payment_expiry",
    width: 150 + "px",
  },
  {
    name: i18n.t("subscription.column.status"),
    selector: "payment_status",
    width: 150 + "px",
  },
];

export const reportedData = [
  {
    name: i18n.t("reportedData.column.sNo"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: i18n.t("reportedData.column.profileImage"),
    selector: "profile_img",
    width: 100 + "px",
  },
  {
    name: (
      <div>
        <div>Reported User</div>
        <div>(Username)</div>
      </div>
    ),
    selector: "reported_name",
    width: 200 + "px",
  },
  {
    name: (
      <div>
        <div>Reported By</div>
        <div>(Username)</div>
      </div>
    ),
    selector: "reported_by",
    width: 200 + "px",
  },
  {
    name: i18n.t("reportedData.column.reportedDate"),
    selector: "reported_date",
    width: 220 + "px",
  },

  {
    name: i18n.t("reportedData.column.category"),
    selector: "category",
    width: 250 + "px",
  },
  {
    name: i18n.t("reportedData.column.noOfReports"),
    selector: "count",
    width: 70 + "px",
    sortable: true,
  },

  {
    name: i18n.t("reportedData.column.status"),
    selector: "status",
    width: 150 + "px",
  },
  {
    name: "Actions",
    selector: "actions",
    width: 100 + "px",
  },
  {
    name: "View Report",
    selector: "view",
    width: 150 + "px",
  },
];

export const appInstallationsColumnData = [
  {
    name: i18n.t("AppInstallations.column.sNo"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: i18n.t("AppInstallations.column.country"),
    selector: "country",
    width: 350 + "px",
    sortable: true,
  },
  {
    name: (
      <div>
        <div className="grouped-column-header">
          {i18n.t("AppInstallations.column.platform")}
        </div>
        <div className="grouped-column-subheader">
          <div>{i18n.t("AppInstallations.column.ios")}</div>
          <div>{i18n.t("AppInstallations.column.android")}</div>
        </div>
      </div>
    ),
    selector: "platform",
    width: 500 + "px",
  },
  {
    name: i18n.t("AppInstallations.column.total"),
    selector: "total",
    minWidth: 150 + "px",
    sortable: true,
  },
];

export const payoutStatementColumnData = [
  {
    name: i18n.t("PayoutStatement.column.sNo"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: i18n.t("PayoutStatement.column.date"),
    selector: "date",
    width: 250 + "px",
    sortable: true,
  },
  {
    name: i18n.t("PayoutStatement.column.details"),
    selector: "details",
    width: 400 + "px",
  },
  {
    name: i18n.t("PayoutStatement.column.credit"),
    selector: "credit",
    minWidth: 150 + "px",
    sortable: true,
  },
  // {
  //   name: i18n.t("PayoutStatement.column.debit"),
  //   selector: "debit",
  //   minWidth: 150 + "px",
  //   sortable: true,
  // },
  // {
  //   name: i18n.t("PayoutStatement.column.balance"),
  //   selector: "balance",
  //   minWidth: 150 + "px",
  //   sortable: true,
  // },
];
export const payoutStatement27ColumnData = [
  {
    name: i18n.t("PayoutStatement.column.sNo"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: i18n.t("PayoutStatement.column.date"),
    selector: "date",
    width: 250 + "px",
    sortable: true,
  },
  {
    name: i18n.t("PayoutStatement.column.details"),
    selector: "details",
    width: 400 + "px",
  },
  {
    name: i18n.t("PayoutStatement.column.level"),
    selector: "level",
    minWidth: 100 + "px",
    sortable: true,
  },
  {
    name: i18n.t("PayoutStatement.column.credit"),
    selector: "credit",
    minWidth: 100 + "px",
    sortable: true,
  },
];
export const payoutStatementRefundablePPColumnData = [
  {
    name: i18n.t("PayoutStatement.rpp_column.sNo"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: i18n.t("PayoutStatement.rpp_column.date"),
    selector: "date",
    width: 250 + "px",
    sortable: true,
  },
  {
    name: i18n.t("PayoutStatement.rpp_column.pp"),
    selector: "pp",
    width: 150 + "px",
  },
  {
    name: i18n.t("PayoutStatement.rpp_column.withdrawn"),
    selector: "withdrawn",
    width: 200 + "px",
  },
];

export const adminsColumnData = [
  {
    name: i18n.t("Admins.column.sNo"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: i18n.t("Admins.column.profileImage"),
    selector: "profile_image",
    width: 100 + "px",
  },
  {
    name: i18n.t("Admins.column.name"),
    selector: "name",
    minWidth: 200 + "px",
  },
  {
    name: i18n.t("Admins.column.role"),
    selector: "role",
    minWidth: 200 + "px",
  },
  {
    name: i18n.t("Admins.column.email"),
    selector: "email",
    minWidth: 200 + "px",
  },
  {
    name: i18n.t("Admins.column.status"),
    selector: "status",
    width: 90 + "px",
  },
  {
    name: i18n.t("Admins.column.actions"),
    width: 175 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const appReviewColoumns = [
  {
    name: i18n.t("Admins.column.sNo"),
    selector: "no",
    width: 40 + "px",
  },
  {
    name: i18n.t("AppReview.column.screenshot"),
    selector: "screenshot",
    width: 100 + "px",
  },
  {
    name: (
      <div>
        <div>Name</div>
        <div>(Username)</div>
      </div>
    ),
    selector: "user_name",
    minWidth: 100 + "px",
  },
  {
    name: i18n.t("AppReview.column.reviewedBy"),
    selector: "reviewed_by",
    minWidth: 100 + "px",
  },
  {
    name: i18n.t("AppReview.column.status"),
    selector: "statusCol",
    width: 100 + "px",
  },
  {
    name: i18n.t("AppReview.column.updatedOn"),
    selector: "time_updated",
    width: 200 + "px",
  },
  {
    name: i18n.t("AppReview.column.actions"),
    width: 200 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const rolePermissionsColumnData = [
  {
    name: i18n.t("RolePermisions.column.sNo"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: i18n.t("RolePermisions.column.role"),
    selector: "name",
    minWidth: 200 + "px",
  },
  {
    name: i18n.t("RolePermisions.column.actions"),
    width: 175 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];
export const appSubscriptionColumnData = [
  {
    name: i18n.t("AppSubscriptionStatistics.column.sNo"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: i18n.t("AppSubscriptionStatistics.column.date"),
    selector: "date",
    width: 350 + "px",
  },
  {
    name: (
      <div>
        <div className="grouped-column-header">
          {i18n.t("AppSubscriptionStatistics.column.platform")}
        </div>
        <div className="grouped-column-subheader">
          <div>{i18n.t("AppSubscriptionStatistics.column.ios")}</div>
          <div>{i18n.t("AppSubscriptionStatistics.column.android")}</div>
        </div>
      </div>
    ),
    selector: "platform",
    width: 500 + "px",
  },
  {
    name: i18n.t("AppSubscriptionStatistics.column.total"),
    selector: "total",
    minWidth: 150 + "px",
    sortable: true,
  },
];

export const userRequestColumnData = [
  {
    name: i18n.t("UserRequests.sNo"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: i18n.t("UserRequests.profileImage"),
    selector: "profile_image",
    width: 100 + "px",
  },
  {
    name: i18n.t("UserRequests.name"),
    selector: "name",
    minWidth: 200 + "px",
  },
  {
    name: i18n.t("UserRequests.username"),
    selector: "username",
    minWidth: 200 + "px",
  },
  {
    name: i18n.t("UserRequests.email"),
    selector: "email",
    minWidth: 200 + "px",
  },
  {
    name: i18n.t("UserRequests.phone"),
    selector: "phone",
    minWidth: 100 + "px",
  },
  {
    name: i18n.t("UserRequests.requestStatus"),
    selector: "request_status",
    width: 150 + "px",
  },
  {
    name: i18n.t("UserRequests.pp"),
    selector: "pp",
    width: 90 + "px",
  },
  {
    name: i18n.t("UserRequests.requestDate"),
    selector: "request_date",
    width: 120 + "px",
    sortable: true,
  },
  {
    name: i18n.t("UserRequests.actions"),
    width: 80 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const huddleColumnData = [
  {
    name: "",
    selector: "checkbox",
    width: 60 + "px",
    ignoreRowClick: true,
  },
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Huddle Name",
    selector: "profile_image",
    width: 250 + "px",
  },

  {
    name: "Huddle Manager",
    selector: "manager",
    minWidth: 150 + "px",
  },

  {
    name: "Category",
    selector: "category",
    minWidth: 150 + "px",
  },

  {
    name: "Created on",
    selector: "createdOn",
    minWidth: 200 + "px",
    sortable: true,
  },

  {
    name: "Participants Count",
    selector: "participant_count",
    sortable: true,
    width: 150 + "px",
  },
  {
    name: "Reports Count",
    selector: "report_count",
    minWidth: 50 + "px",
    sortable: true,
  },
  {
    name: "Status",
    selector: "status",
    width: 130 + "px",
  },
  {
    name: "Type",
    selector: "type",
    width: 170 + "px",
  },
];

export const groupColumnData = [
  {
    name: "",
    selector: "checkbox",
    width: 60 + "px",
    ignoreRowClick: true,
  },
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Group Name",
    selector: "profile_image",
    width: 250 + "px",
  },

  {
    name: "Group Manager",
    selector: "manager",
    minWidth: 150 + "px",
  },

  {
    name: "Category",
    selector: "category",
    minWidth: 150 + "px",
  },

  {
    name: "Created on",
    selector: "createdOn",
    minWidth: 200 + "px",
    sortable: true,
  },

  {
    name: "Participants Count",
    selector: "participant_count",
    sortable: true,
    width: 150 + "px",
  },
  {
    name: "Reports Count",
    selector: "report_count",
    minWidth: 50 + "px",
    sortable: true,
  },
  {
    name: "Status",
    selector: "status",
    width: 130 + "px",
  },
  {
    name: "Type",
    selector: "type",
    width: 170 + "px",
  },
];

export const huddleCategoryColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Category",
    selector: "category",
    minWidth: 150 + "px",
  },
  {
    name: "Huddle Type",
    selector: "huddle_type",
    minWidth: 150 + "px",
  },

  {
    name: "Actions",
    width: 175 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const huddleReportCategoryColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Category",
    selector: "category",
    minWidth: 150 + "px",
  },

  {
    name: "Actions",
    width: 175 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const manualPPColumn = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "FLiX Amount",
    selector: "pp",
    minWidth: 60 + "px",
    ignoreRowClick: true,
  },
  {
    name: "Status",
    width: 100 + "px",
    selector: "transactionType",
    ignoreRowClick: true,
  },
  {
    name: "Date",
    width: 175 + "px",
    selector: "addedOn",
    ignoreRowClick: true,
  },

  {
    name: "Added by",
    width: 150 + "px",
    selector: "created_by",
    ignoreRowClick: true,
  },
  {
    name: "Comments",
    width: 175 + "px",
    selector: "comments",
    ignoreRowClick: true,
  },
  {
    name: "Action",
    width: 100 + "px",
    selector: "action",
  },
];

export const manualCoinColumn = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "COiN Amount",
    selector: "coins",
    minWidth: 60 + "px",
    ignoreRowClick: true,
  },
  {
    name: "Status",
    width: 130 + "px",
    selector: "transactionType",
    ignoreRowClick: true,
  },
  {
    name: "Date",
    width: 175 + "px",
    selector: "addedOn",
    ignoreRowClick: true,
  },

  {
    name: "Added by",
    width: 150 + "px",
    selector: "created_by",
    ignoreRowClick: true,
  },
  {
    name: "Comments",
    width: 175 + "px",
    selector: "comments",
    ignoreRowClick: true,
  },
  {
    name: "Action",
    width: 100 + "px",
    selector: "action",
  },
];

export const huddleStatisticsColumn = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Huddle Name",
    selector: "profile_image",
    width: 250 + "px",
  },

  {
    name: "Huddle Manager",
    selector: "manager",
    minWidth: 150 + "px",
  },
  {
    name: "Total Post Count",
    selector: "total_posts",
    minWidth: 150 + "px",
  },
  {
    name: "User Post Count",
    selector: "user_posts",
    minWidth: 150 + "px",
  },
];
export const groupStatisticsColumn = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Group Name",
    selector: "profile_image",
    width: 250 + "px",
  },

  {
    name: "Group Manager",
    selector: "manager",
    minWidth: 150 + "px",
  },
  {
    name: "Total Post Count",
    selector: "total_posts",
    minWidth: 150 + "px",
  },
  {
    name: "User Post Count",
    selector: "user_posts",
    minWidth: 150 + "px",
  },
];

export const notificationCol = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Message",
    selector: "message",
    width: 250 + "px",
  },

  {
    name: "Status",
    selector: "status",
    minWidth: 150 + "px",
  },
  {
    name: "Push notification",
    selector: "push",
    minWidth: 150 + "px",
  },
  {
    name: "Created Date",
    selector: "time_created",
    minWidth: 150 + "px",
  },
  {
    name: "Action",
    selector: "action",
    minWidth: 150 + "px",
  },
];
export const flaxTransferPurposeColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Purpose",
    selector: "purpose",
    minWidth: 150 + "px",
  },

  {
    name: "Actions",
    width: 175 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const flaxTransferColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Sender",
    selector: "sender_image",
    width: 250 + "px",
  },

  {
    name: "Reciever",
    selector: "receiver_image",
    minWidth: 250 + "px",
  },
  {
    name: "FLiX Amount Send",
    selector: "flax_amount_send",
    minWidth: 150 + "px",
  },
  // {
  //   name: "Processing fee",
  //   selector: "flax_fee",
  //   minWidth: 200 + "px",
  // },
  {
    name: "Sender's FLiX Rate",
    selector: "flax_rating",
    minWidth: 150 + "px",
  },

  {
    name: "FLiX Amount received",
    selector: "flax_amount_received",
    minWidth: 150 + "px",
  },

  {
    name: "Transfer Purpose",
    selector: "transfer_purpose",
    minWidth: 150 + "px",
  },
  {
    name: "Transfered Date",
    selector: "transfered_date",
    minWidth: 200 + "px",
    sortable: true,
  },
];

export const blockedUserData = [
  {
    name: i18n.t("reportedData.column.sNo"),
    selector: "no",
    width: 80 + "px",
  },
  {
    name: "Profile Image",
    selector: "profile_img",
    width: 150 + "px",
  },
  {
    name: (
      <div>
        <div>Blocked User</div>
        <div>(Username)</div>
      </div>
    ),
    selector: "blocked_user",
    width: 200 + "px",
  },
  {
    name: (
      <div>
        <div>Blocked By</div>
        <div>(Username)</div>
      </div>
    ),
    selector: "blocked_by",
    width: 200 + "px",
  },
  {
    name: "Authority",
    selector: "status",
    width: 200 + "px",
  },
  {
    name: "Blocked date",
    selector: "blocked_date",
    width: 250 + "px",
  },
  {
    name: "Actions",
    selector: "actions",
    width: 150 + "px",
  },
];

export const flaxPackageColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "FLiX",
    selector: "flax",
    minWidth: 150 + "px",
  },
  {
    name: "USD",
    selector: "usd",
    minWidth: 100 + "px",
  },
  {
    name: "Best Value",
    selector: "best_value",
    minWidth: 150 + "px",
  },
  {
    name: "Most Popular",
    selector: "most_popular",
    minWidth: 150 + "px",
  },
  {
    name: "Android Product ID",
    selector: "android_id",
    minWidth: 200 + "px",
  },
  {
    name: "IOS Product ID",
    selector: "ios_id",
    minWidth: 200 + "px",
  },

  {
    name: "Actions",
    width: 175 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const flaxPurchaseColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Username",
    selector: "username",
    minWidth: 100 + "px",
  },
  {
    name: "FLiX",
    selector: "flax",
    minWidth: 150 + "px",
  },
  {
    name: "Purchased By",
    selector: "purchased_by_user",
    minWidth: 100 + "px",
  },
  {
    name: "Device",
    selector: "device_type",
    minWidth: 150 + "px",
  },
  {
    name: "Price",
    selector: "price",
    minWidth: 150 + "px",
  },
  {
    name: "Purchased On",
    selector: "purchased_on",
    minWidth: 150 + "px",
  },
];

export const giftPackageColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Gift Name",
    selector: "gift_name",
    minWidth: 100 + "px",
  },
  {
    name: "Gift",
    selector: "gift",
    minWidth: 100 + "px",
  },
  {
    name: "COiNS / FLiX",
    selector: "points",
    minWidth: 100 + "px",
  },
  {
    name: "Type",
    selector: "gift_type",
    minWidth: 100 + "px",
  },
  // {
  //   name: "Description",
  //   selector: "gift_desc",
  //   minWidth: 100 + "px",
  // },
  {
    name: "Actions",
    width: 175 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
  {
    name: "Visibilty",
    width: 175 + "px",
    selector: "visibility",
    ignoreRowClick: true,
  },
];

export const coinsPurchaseColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Username",
    selector: "username",
    minWidth: 150 + "px",
  },
  {
    name: "FLiX",
    selector: "flax",
    name: "Price",
    selector: "price",
    minWidth: 150 + "px",
  },
  {
    name: "COiNS",
    selector: "coins",
    minWidth: 150 + "px",
  },
  {
    name: "Device",
    selector: "device_type",
    minWidth: 150 + "px",
  },
  {
    name: "Purchased On",
    selector: "purchased_on",
    minWidth: 150 + "px",
  },
];

export const giftTransferColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Sender",
    selector: "sender_image",
    width: 150 + "px",
  },

  {
    name: "Reciever",
    selector: "receiver_image",
    minWidth: 150 + "px",
  },
  {
    name: "Gift ",
    selector: "gift_identifier",
    minWidth: 150 + "px",
  },

  {
    name: "Sent",
    selector: "send_coins",
    minWidth: 60 + "px",
  },
  {
    name: "Received",
    selector: "received_coins",
    minWidth: 60 + "px",
  },
  {
    name: "Source",
    selector: "sending_source",
    minWidth: 90 + "px",
  },
  {
    name: "Manager Share",
    selector: "manager_received_coins",
    minWidth: 80 + "px",
  },
  {
    name: "Flashat Share",
    selector: "flashat_points",
    minWidth: 80 + "px",
  },
  {
    name: "Transfered Date",
    selector: "transfered_date",
    minWidth: 200 + "px",
    sortable: true,
  },
];

export const podiumColumnData = [
  // {
  //   name: "",
  //   selector: "checkbox",
  //   width: 60 + "px",
  //   ignoreRowClick: true,
  // },
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Podium Name",
    selector: "profile_image",
    width: 250 + "px",
  },

  {
    name: "Podium Manager",
    selector: "manager",
    minWidth: 150 + "px",
  },

  {
    name: "Category",
    selector: "category",
    minWidth: 150 + "px",
  },

  {
    name: "Created on",
    selector: "createdOn",
    minWidth: 200 + "px",
    sortable: true,
  },

  // {
  //   name: "Status",
  //   selector: "status",
  //   width: 130 + "px",
  // },
  {
    name: "Type",
    selector: "type",
    width: 170 + "px",
  },
];

export const podiumCategoryColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Category",
    selector: "category",
    width: 800 + "px",
  },
  // {
  //   name: "Category Type",
  //   selector: "category_type",
  //   width: 450 + "px",
  // },
  {
    name: "Actions",
    selector: "actions",
    width: 150 + "px",
    ignoreRowClick: true,
  },
];

export const flashCategoryColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Category",
    selector: "category",
    width: 800 + "px",
  },
  // {
  //   name: "Category Type",
  //   selector: "category_type",
  //   width: 450 + "px",
  // },
  {
    name: "Actions",
    selector: "actions",
    width: 150 + "px",
    ignoreRowClick: true,
  },
];
export const userFlaxTransferColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "FLiX Amount Transfered",
    selector: "flax_amount",
    minWidth: 150 + "px",
  },

  {
    name: "Status",
    selector: "status",
    minWidth: 150 + "px",
  },
  {
    name: "Transfer Purpose",
    selector: "purpose",
    minWidth: 150 + "px",
  },
  {
    name: "Send To/ Recieved From",
    selector: "transfered_from",
    minWidth: 200 + "px",
    sortable: true,
  },
  {
    name: "Transfered Date",
    selector: "transfered_date",
    minWidth: 200 + "px",
    sortable: true,
  },
];

export const announcementColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Title",
    selector: "title",
    minWidth: 149 + "px",
  },
  {
    name: "Content",
    selector: "message",
    minWidth: 149 + "px",
  },

  {
    name: "Status",
    selector: "status",
    minWidth: 100 + "px",
    sortable: true,
  },
  {
    name: "Scheduled Time",
    selector: "scheduled_time",
    minWidth: 199 + "px",
  },
  {
    name: "Actions",
    selector: "actions",
    width: 200 + "px",
  },
];
export const userFlashCoumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Thumbnail",
    selector: "thumbnail",
    width: 100 + "px",
  },
  {
    name: "Category",
    selector: "category",
    width: 200 + "px",
  },
  {
    name: "Description",
    selector: "caption",
    width: 300 + "px",
  },
  {
    name: "Created on",
    selector: "created_on",
    minWidth: 200 + "px",
    sortable: true,
  },
  // {
  //   name: "Category Type",
  //   selector: "category_type",
  //   width: 450 + "px",
  // },
  {
    name: "Actions",

    width: 200 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];
export const paymentMethodsColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Payment Method",
    selector: "payment_method",
    minWidth: 150 + "px",
  },
  // {
  //   name: "Service Fee",
  //   selector: "service_fee",
  //   minWidth: 150 + "px",
  // },
  // {
  //   name: "Processing Fee",
  //   selector: "process_fee",
  //   minWidth: 150 + "px",
  // },
  // {
  //   name: "Minimum withdrawal amount",
  //   selector: "minimum_withdrawal_amount",
  //   minWidth: 200 + "px",
  // },
  {
    name: "Processing Time",
    selector: "process_time",
    minWidth: 150 + "px",
  },

  {
    name: "Icon",
    selector: "icon",
    minWidth: 100 + "px",
  },
  {
    name: "Actions",
    width: 175 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const paymentFormsColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Payment Method",
    selector: "payment_method",
    minWidth: 150 + "px",
  },
  {
    name: "country",
    selector: "country",
    minWidth: 150 + "px",
  },

  {
    name: "Actions",
    width: 175 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const flaxToCoinsConversionColummnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "FLiX",
    selector: "flax",
    minWidth: 150 + "px",
  },
  {
    name: "COiNS",
    selector: "coins",
    minWidth: 150 + "px",
  },

  {
    name: "Conversion Date",
    selector: "conversion_date",
    minWidth: 200 + "px",
    sortable: true,
  },
];

export const coinsToFlaxConversionColummnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "COiNS",
    selector: "coins",
    minWidth: 150 + "px",
  },
  {
    name: "FLiX",
    selector: "flax",
    minWidth: 150 + "px",
  },

  {
    name: "Conversion Date",
    selector: "conversion_date",
    minWidth: 200 + "px",
    sortable: true,
  },
];

export const userFlaxRatesColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Flax Rate",
    selector: "rate",
    minWidth: 100 + "px",
  },

  {
    name: "Podium Speaker",
    selector: "podium_speaker",
    minWidth: 150 + "px",
  },

  {
    name: "Huddle Posts",
    selector: "huddle_posts",
    minWidth: 150 + "px",
  },
  {
    name: "Huddle Comments",
    selector: "huddle_comments",
    minWidth: 150 + "px",
  },
  {
    name: "Huddle Replies",
    selector: "huddle_reply",
    minWidth: 150 + "px",
  },
  {
    name: "COiNS",
    selector: "coins_spent",
    minWidth: 150 + "px",
  },
  {
    name: "Flash",
    selector: "new_flash",
    minWidth: 150 + "px",
  },
  {
    name: "Admin Updated",
    selector: "admin_updated",
    minWidth: 150 + "px",
  },
  {
    name: "Restore Rating",
    selector: "restore_rating",
    minWidth: 150 + "px",
  },

  {
    name: "Date",
    selector: "date",
    minWidth: 200 + "px",
    sortable: true,
  },
];
export const giftCategoryColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Category",
    selector: "gift_category",
    minWidth: 150 + "px",
  },
  {
    name: "Listing Order",
    selector: "listing_order",
    minWidth: 150 + "px",
  },
  {
    name: "Category Identifier",
    selector: "category_identifier",
    minWidth: 150 + "px",
  },
  {
    name: "Actions",
    width: 175 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const postAtReportedPostColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Reported Postat",
    selector: "post_thumbnail",
    minWidth: 100 + "px",
  },
  {
    name: " Post Owner",
    selector: "post_owner_name",
    minWidth: 200 + "px",
  },
  {
    name: "Reports Count",
    selector: "reported_count",
    minWidth: 100 + "px",
  },
  {
    name: "Actions",
    width: 300 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const postAtReportsColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Reporter",
    selector: "reported_by",
    minWidth: 100 + "px",
  },
  {
    name: "Reason",
    selector: "reason",
    minWidth: 100 + "px",
  },
  {
    name: "Comment",
    selector: "comment",
    minWidth: 100 + "px",
  },
  {
    name: "Reported Date",
    selector: "reported_date",
    minWidth: 100 + "px",
  },
];

export const postAtReportedCommentColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  // {
  //   name: "Post Thumbnail",
  //   selector: "post_thumbnail",
  //   minWidth: 100 + "px",
  // },
  {
    name: "Post Owner",
    selector: "post_owner_name",
    minWidth: 200 + "px",
  },
  {
    name: "Reported Comment",
    selector: "reported_comment",
    minWidth: 100 + "px",
  },
  {
    name: "Commenter",
    selector: "commenter",
    minWidth: 100 + "px",
  },
  {
    name: "Report Count",
    selector: "reported_count",
    minWidth: 100 + "px",
  },
  {
    name: "Actions",
    width: 275 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];
export const postAtCommentReportsColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Reporter",
    selector: "reported_by",
    minWidth: 100 + "px",
  },
  {
    name: "Reason",
    selector: "reason",
    minWidth: 100 + "px",
  },
  {
    name: "Explanation",
    selector: "comment",
    minWidth: 100 + "px",
  },
  {
    name: "Reported Date",
    selector: "reported_date",
    minWidth: 100 + "px",
  },
];

export const postatMusicColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Song Name",
    selector: "song_name",
    minWidth: 100 + "px",
  },
  {
    name: "Movie Name",
    selector: "movie_name",
    minWidth: 100 + "px",
  },
  {
    name: "Track",
    selector: "track",
    minWidth: 300 + "px",
  },
  {
    name: "Song Description",
    selector: "description",
    minWidth: 200 + "px",
  },
  {
    name: "Composer Name",
    selector: "composer_name",
    minWidth: 100 + "px",
  },
  {
    name: "Singers",
    selector: "singers",
    minWidth: 100 + "px",
  },

  {
    name: "Actions",
    width: 175 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const flixCoinsStatementsColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Event",
    selector: "event",
    minWidth: 100 + "px",
  },
  {
    name: "Transaction Type",
    selector: "transcation_type",
    minWidth: 100 + "px",
  },
  {
    name: "Closing Balance",
    selector: "closing_balance",
    minWidth: 100 + "px",
  },
  {
    name: "Date",
    selector: "time_created",
    minWidth: 100 + "px",
  },
];

export const cameraPurchaseColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "From Date",
    selector: "time_created",
    minWidth: 100 + "px",
  },
  {
    name: "To Date",
    selector: "expiry_datetime",
    minWidth: 100 + "px",
  },
  {
    name: "FLiX amount",
    selector: "flax",
    minWidth: 100 + "px",
  },
];

export const challengeHistoryColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Challenge Name",
    selector: "challenge_name",
    minWidth: 100 + "px",
  },
  {
    name: "Podium Name",
    selector: "podium_name",
    minWidth: 100 + "px",
  },
  {
    name: "Podium Manager",
    selector: "podium_manager_name",
    minWidth: 100 + "px",
  },
  {
    name: "COiNS received by Podium Manager",
    selector: "podium_manager_coins",
    minWidth: 100 + "px",
  },
  {
    name: "Contributor",
    selector: "contributors_count",
    minWidth: 100 + "px",
  },
  {
    name: "Prize Amount ",
    selector: "prize",
    minWidth: 100 + "px",
  },
  {
    name: "Status ",
    selector: "status",
    minWidth: 100 + "px",
  },
  {
    name: "COiNS Credited",
    selector: "coins_won",
    minWidth: 100 + "px",
  },
  {
    name: "COiN Share for Flashat",
    selector: "flash_at_coins",
    minWidth: 100 + "px",
  },
  {
    name: "Time",
    selector: "time_created",
    minWidth: 150 + "px",
  },
];

export const contributorDetailsColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Contributor Name",
    selector: "name",
    minWidth: 100 + "px",
  },
  {
    name: "Contributed Amount ",
    selector: "coins_spent",
    minWidth: 100 + "px",
  },
];

export const giftChParticipantsColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Participant",
    selector: "name",
    minWidth: 100 + "px",
  },
  {
    name: "COiNS amount",
    selector: "coins_won",
    minWidth: 100 + "px",
  },
  {
    name: "Podium Manager’s Share",
    selector: "manager_coins",
    minWidth: 100 + "px",
  },
  {
    name: "Flashat’s Share.",
    selector: "flash_at_coins",
    minWidth: 100 + "px",
  },
  {
    name: "Status",
    selector: "status",
    minWidth: 100 + "px",
  },
];
export const otherChParticipantsColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Participant",
    selector: "name",
    minWidth: 100 + "px",
  },
  {
    name: "COiNS amount",
    selector: "coins_won",
    minWidth: 100 + "px",
  },

  {
    name: "Status",
    selector: "status",
    minWidth: 100 + "px",
  },
];
export const wholeChallengeHistoryColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Challenge Name",
    selector: "challenge_name",
    minWidth: 100 + "px",
  },
  {
    name: "Podium Name",
    selector: "podium_name",
    minWidth: 100 + "px",
  },
  {
    name: "Contributor",
    selector: "contributors_count",
    minWidth: 100 + "px",
  },
  {
    name: "Podium Manager",
    selector: "podium_manager_name",
    minWidth: 100 + "px",
  },
  {
    name: "COiNS credited to Manager",
    selector: "podium_manager_coins",
    minWidth: 100 + "px",
  },

  {
    name: "COiN Share for Flashat",
    selector: "flash_at_coins",
    minWidth: 100 + "px",
  },
  {
    name: "Time",
    selector: "time_created",
    minWidth: 150 + "px",
  },
];

export const restoreRatingHistoryColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "FLiX",
    selector: "flax",
    minWidth: 100 + "px",
  },
  {
    name: "Restored Time",
    selector: "time_created",
    minWidth: 100 + "px",
  },
];

export const allpostatColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Thumbnail",
    selector: "post_thumbnail",
    minWidth: 100 + "px",
  },
  {
    name: " Post Owner",
    selector: "post_owner_name",
    minWidth: 200 + "px",
  },

  {
    name: "Actions",
    width: 300 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];
export const allFlashCoumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Thumbnail",
    selector: "thumbnail",
    width: 100 + "px",
  },

  {
    name: "FLASH Publisher",
    selector: "post_owner",
    width: 200 + "px",
  },

  {
    name: "Category",
    selector: "category",
    width: 200 + "px",
  },
  {
    name: "Created on",
    selector: "created_on",
    minWidth: 200 + "px",
    sortable: true,
  },
  // {
  //   name: "Category Type",
  //   selector: "category_type",
  //   width: 450 + "px",
  // },
  {
    name: "Actions",

    width: 350 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];
export const reportedFlashColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Reported Flash",
    selector: "post_thumbnail",
    minWidth: 100 + "px",
  },
  {
    name: " Post Owner",
    selector: "post_owner_name",
    minWidth: 200 + "px",
  },
  {
    name: "Reports Count",
    selector: "reported_count",
    minWidth: 100 + "px",
  },
  {
    name: "Actions",
    width: 300 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const flashReportsColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Reporter",
    selector: "reported_by",
    minWidth: 100 + "px",
  },
  {
    name: "Reason",
    selector: "reason",
    minWidth: 100 + "px",
  },
  {
    name: "Explanation",
    selector: "comment",
    minWidth: 100 + "px",
  },
  {
    name: "Reported Date",
    selector: "reported_date",
    minWidth: 100 + "px",
  },
];
export const reportedFlashCommentsColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  // {
  //   name: "Post Thumbnail",
  //   selector: "post_thumbnail",
  //   minWidth: 100 + "px",
  // },
  {
    name: "Post Owner",
    selector: "post_owner_name",
    minWidth: 200 + "px",
  },
  {
    name: "Reported Comment",
    selector: "reported_comment",
    minWidth: 100 + "px",
  },
  {
    name: "Commenter",
    selector: "commenter",
    minWidth: 100 + "px",
  },
  {
    name: "Report Count",
    selector: "reported_count",
    minWidth: 100 + "px",
  },
  {
    name: "Actions",
    width: 275 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];
export const flashCommentReportsColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Reporter",
    selector: "reported_by",
    minWidth: 100 + "px",
  },
  {
    name: "Reason",
    selector: "reason",
    minWidth: 100 + "px",
  },
  {
    name: "Explanation",
    selector: "comment",
    minWidth: 100 + "px",
  },
  {
    name: "Reported Date",
    selector: "reported_date",
    minWidth: 100 + "px",
  },
];

export const flashBlockedUsersColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Name of Blocked User",
    selector: "blocked_user",
    minWidth: 150 + "px",
  },
  {
    name: "Name of the Minister",
    selector: "blocked_by",
    minWidth: 150 + "px",
  },
  {
    name: "Date",
    selector: "time_updated",
    minWidth: 150 + "px",
  },
  {
    name: "Actions",
    width: 275 + "px",
    selector: "actions",
    ignoreRowClick: true,
  },
];

export const newSubscriptionsColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "Date",
    selector: "payment_date",
    width: 200 + "px",
    sortable: true
  },

  {
    name: "User",
    selector: "user",
    width: 200 + "px",
  },

  {
    name: "Platform",
    selector: "platform",
    width: 100 + "px",
  },
  {
    name: "Expiry Date",
    selector: "expiry",
    minWidth: 200 + "px",

    // sortable: true,
  },
  {
    name: "Amount",
    selector: "amount",
    minWidth: 200 + "px",
    // sortable: true,
  },
  {
    name: "Subscription/Renewal",
    selector: "sub_renewal",
    minWidth: 200 + "px",
    sortable: true,
  },
  {
    name: "Status",
    selector: "refund",
    minWidth: 100 + "px",
    sortable: true,
  },
];

export const GRSSummaryColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Sender",
    selector: "sender",
    minWidth: 150 + "px",
  },
  {
    name: "Receiver",
    selector: "receiver",
    minWidth: 150 + "px",
  },
  {
    name: "Gifts Count",
    selector: "gift_count",
    minWidth: 150 + "px",
  },
  {
    name: "Total COiNS",
    selector: "gift_total",
    minWidth: 150 + "px",
  },
];
export const FlixGRSSummaryColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },
  {
    name: "Sender",
    selector: "sender",
    minWidth: 150 + "px",
  },
  {
    name: "Receiver",
    selector: "receiver",
    minWidth: 150 + "px",
  },
  {
    name: "Gifts Count",
    selector: "gift_count",
    minWidth: 150 + "px",
  },
  {
    name: "Total Flix",
    selector: "gift_total",
    minWidth: 150 + "px",
  },
];

export const wholeCoinsPurchaseColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "User Profile",
    selector: "username",
    minWidth: 150 + "px",
  },
  {
    name: "COiNS Package Purchased",
    selector: "coins",
    minWidth: 150 + "px",
  },
  {
    name: "Amount Purchased",
    selector: "price",
    minWidth: 150 + "px",
  },

  {
    name: "Purchased from",
    selector: "device_type",
    minWidth: 150 + "px",
  },
  {
    name: "Purchased On",
    selector: "purchased_on",
    minWidth: 150 + "px",
    sortable: true,
  },
];

export const wholeFlaxPurchaseColumnData = [
  {
    name: i18n.t("UserManagement.column.slno"),
    selector: "no",
    width: 60 + "px",
  },

  {
    name: "User Profile",
    selector: "username",
    minWidth: 100 + "px",
  },
  {
    name: "FLiX Package Purchased",
    selector: "flax",
    minWidth: 150 + "px",
  },
  {
    name: "Amount Paid",
    selector: "price",
    minWidth: 150 + "px",
  },
  {
    name: "Purchased From",
    selector: "device_type",
    minWidth: 150 + "px",
  },

  {
    name: "Purchased On",
    selector: "purchased_on",
    minWidth: 150 + "px",
    sortable: true,
  },
];
