import React, { useState } from "react";
import PropTypes from "prop-types";
import AvInput from "availity-reactstrap-validation/lib/AvInput";

const TagInput = ({ tags, setTags, placeholder }) => {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleRemoveTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  return (
    <div>
      <div style={styles.tagsContainer}>
        {tags.map((tag, index) => (
          <div key={index} style={styles.tag}>
            {tag}
            <span style={styles.close} onClick={() => handleRemoveTag(index)}>
              x
            </span>
          </div>
        ))}
      </div>
      <AvInput
        name="singers"
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        style={styles.input}
      />
    </div>
  );
};

TagInput.propTypes = {
  tags: PropTypes.array.isRequired,
  setTags: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

TagInput.defaultProps = {
  placeholder: "Type and press enter to add tags",
};

const styles = {
  tagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    marginBottom: "10px",
  },
  tag: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#e0e0e0",
    padding: "5px 10px",
    borderRadius: "15px",
  },
  close: {
    marginLeft: "5px",
    cursor: "pointer",
    color: "black",
  },
  input: {
    padding: "10px",
    width: "100%",
    boxSizing: "border-box",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
};

export default TagInput;
