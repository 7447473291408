import React, { useState } from "react";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const DateTimePicker = ({
  selectedDate,
  setSelectedDate,
  onDateChange,
  isValidDate = () => true, // Default allows all dates
  dateFormat = "DD/MM/YYYY", // Default date format
  timeFormat = "HH:mm", // Default time format (24-hour)
  placeholder = "Select Date and Time", // Customizable placeholder
}) => {
  // Handle date change
  const handleChange = (date) => {
    setSelectedDate(date);
    if (onDateChange) {
      onDateChange(date); // Trigger callback when date changes
    }
  };

  console.log("Selected dsate", selectedDate);

  return (
    <div>
      <DateTime
        value={selectedDate}
        onChange={handleChange}
        isValidDate={isValidDate}
        inputProps={{
          readOnly: true,
          className:
            "custom-input w-full py-2 px-3 border-2 border-blue-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white",
          placeholder: placeholder,
        }}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
      />
    </div>
  );
};

export default DateTimePicker;
