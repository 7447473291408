import {
  GET_REPORTED_USER,
  GET_REPORTED_USER_BEGIN,
  GET_REPORTED_USER_SUCCESS,
  GET_REPORTED_USER_FAIL,
  GET_REPORTED_CATEGORY,
  GET_REPORTED_CATEGORY_BEGIN,
  GET_REPORTED_CATEGORY_SUCCESS,
  GET_REPORTED_CATEGORY_FAIL,
  STORE_REPORTED_USER_REQUEST,
  DELETE_REPORTED_USER,
  DELETE_REPORTED_USER_BEGIN,
  DELETE_REPORTED_USER_SUCCESS,
  DELETE_REPORTED_USER_FAIL,
  RESET_MESSAGES,
  GET_REPORTED_USERS_CATEGORIES,
  GET_REPORTED_USERS_CATEGORIES_BEGIN,
  GET_REPORTED_USERS_CATEGORIES_SUCCESS,
  GET_REPORTED_USERS_CATEGORIES_FAIL,
  GET_REPORTED_USERS_CATEGORIES_BY_ID,
  GET_REPORTED_USERS_CATEGORIES_BY_ID_BEGIN,
  GET_REPORTED_USERS_CATEGORIES_BY_ID_SUCCESS,
  GET_REPORTED_USERS_CATEGORIES_BY_ID_FAIL,
  ADD_REPORTED_USERS_CATEGORIES,
  ADD_REPORTED_USERS_CATEGORIES_BEGIN,
  ADD_REPORTED_USERS_CATEGORIES_SUCCESS,
  ADD_REPORTED_USERS_CATEGORIES_FAIL,
  EDIT_REPORTED_USERS_CATEGORIES,
  EDIT_REPORTED_USERS_CATEGORIES_BEGIN,
  EDIT_REPORTED_USERS_CATEGORIES_SUCCESS,
  EDIT_REPORTED_USERS_CATEGORIES_FAIL,
  GET_REPORTED_USER_BY_ID_BEGIN,
  GET_REPORTED_USER_BY_ID,
  GET_REPORTED_USER_BY_ID_SUCCESS,
  GET_REPORTED_USER_BY_ID_FAIL,
  DELETE_REPORTED_USERS_CATEGORIES,
  DELETE_REPORTED_USERS_CATEGORIES_BEGIN,
  DELETE_REPORTED_USERS_CATEGORIES_SUCCESS,
  DELETE_REPORTED_USERS_CATEGORIES_FAIL,
} from "./actionTypes";

export const getReportedData = (request) => ({
  type: GET_REPORTED_USER,
  payload: { request },
});

export const getReportedDataBegin = () => ({
  type: GET_REPORTED_USER_BEGIN,
});

export const getReportedDataSuccess = (reportedData) => ({
  type: GET_REPORTED_USER_SUCCESS,
  payload: reportedData,
});

export const getReportedDataFail = (error) => ({
  type: GET_REPORTED_USER_FAIL,
  payload: error,
});

export const getCategoryData = () => ({
  type: GET_REPORTED_CATEGORY,
});

export const getCategoryDataBegin = () => ({
  type: GET_REPORTED_CATEGORY_BEGIN,
});

export const getCategoryDataSuccess = (reportCategory) => ({
  type: GET_REPORTED_CATEGORY_SUCCESS,
  payload: reportCategory,
});

export const getCategoryDataFail = (error) => ({
  type: GET_REPORTED_CATEGORY_FAIL,
  payload: error,
});

export const storeRequest = (request) => ({
  type: STORE_REPORTED_USER_REQUEST,
  payload: request,
});

/* ------------------------------------------- */
export const deleteReportedData = ({ data, callback }) => ({
  type: DELETE_REPORTED_USER,
  data: data,
  callback: callback,
});

export const deleteReportedDataBegin = () => ({
  type: DELETE_REPORTED_USER_BEGIN,
});

export const deleteReportedDataSuccess = (users) => ({
  type: DELETE_REPORTED_USER_SUCCESS,
  payload: users,
});

export const deleteReportedDataFail = (error) => ({
  type: DELETE_REPORTED_USER_FAIL,
  payload: error,
});
/* ------------------------------------------- */
export const resetMessage = () => ({
  type: RESET_MESSAGES,
});

// Reported Users Categories

export const getReportedUsersCategories = (request) => ({
  type: GET_REPORTED_USERS_CATEGORIES,
  request: request,
});

export const getReportedUsersCategoriesBegin = () => ({
  type: GET_REPORTED_USERS_CATEGORIES_BEGIN,
});

export const getReportedUsersCategoriesSuccess = (categories) => ({
  type: GET_REPORTED_USERS_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getReportedUsersCategoriesFail = (error) => ({
  type: GET_REPORTED_USERS_CATEGORIES_FAIL,
  payload: error,
});

export const getReportedUsersCategoriesById = ({ id }) => ({
  type: GET_REPORTED_USERS_CATEGORIES_BY_ID,
  id: id,
});

export const getReportedUsersCategoriesByIdBegin = () => ({
  type: GET_REPORTED_USERS_CATEGORIES_BY_ID_BEGIN,
});

export const getReportedUsersCategoriesByIdSuccess = (categories) => ({
  type: GET_REPORTED_USERS_CATEGORIES_BY_ID_SUCCESS,
  payload: categories,
});

export const getReportedUsersCategoriesByIdFail = (error) => ({
  type: GET_REPORTED_USERS_CATEGORIES_BY_ID_FAIL,
  payload: error,
});

export const addReportedUsersCategories = ({ category, callback }) => ({
  type: ADD_REPORTED_USERS_CATEGORIES,
  category: category,
  callback: callback,
});

export const addReportedUsersCategoriesBegin = () => ({
  type: ADD_REPORTED_USERS_CATEGORIES_BEGIN,
});

export const addReportedUsersCategoriesSuccess = (categories) => ({
  type: ADD_REPORTED_USERS_CATEGORIES_SUCCESS,
  payload: categories,
});

export const addReportedUsersCategoriesFail = (error) => ({
  type: ADD_REPORTED_USERS_CATEGORIES_FAIL,
  payload: error,
});

export const editReportedUsersCategories = ({ category, id, callback }) => ({
  type: EDIT_REPORTED_USERS_CATEGORIES,
  category: category,
  id: id,
  callback: callback,
});

export const editReportedUsersCategoriesBegin = () => ({
  type: EDIT_REPORTED_USERS_CATEGORIES_BEGIN,
});

export const editReportedUsersCategoriesSuccess = (categories) => ({
  type: EDIT_REPORTED_USERS_CATEGORIES_SUCCESS,
  payload: categories,
});

export const editReportedUsersCategoriesFail = (error) => ({
  type: EDIT_REPORTED_USERS_CATEGORIES_FAIL,
  payload: error,
});

export const deleteReportedUserCategories = ({ id, callback }) => ({
  type: DELETE_REPORTED_USERS_CATEGORIES,
  id: id,
  callback: callback,
});

export const deleteReportedUserCategoriesBegin = () => ({
  type: DELETE_REPORTED_USERS_CATEGORIES_BEGIN,
});

export const deleteReportedUserCategoriesSuccess = (categories) => ({
  type: DELETE_REPORTED_USERS_CATEGORIES_SUCCESS,
  payload: categories,
});

export const deleteReportedUserCategoriesFail = (error) => ({
  type: DELETE_REPORTED_USERS_CATEGORIES_FAIL,
  payload: error,
});

export const getReportedUserById = ({ id, request }) => ({
  type: GET_REPORTED_USER_BY_ID,
  id: id,
  request: request,
});

export const getReportedUserByIdBegin = () => ({
  type: GET_REPORTED_USER_BY_ID_BEGIN,
});

export const getReportedUserByIdSuccess = (reportData) => ({
  type: GET_REPORTED_USER_BY_ID_SUCCESS,
  payload: reportData,
});

export const getReportedUserByIdFail = (error) => ({
  type: GET_REPORTED_USER_BY_ID_FAIL,
  payload: error,
});
