import {
  GET_GENERAL_SETTINGS,
  GET_GENERAL_SETTINGS_BEGIN,
  GET_GENERAL_SETTINGS_SUCCESS,
  GET_GENERAL_SETTINGS_FAIL,
  SAVE_GENERAL_SETTINGS,
  SAVE_GENERAL_SETTINGS_BEGIN,
  SAVE_GENERAL_SETTINGS_SUCCESS,
  SAVE_GENERAL_SETTINGS_FAIL,
  SAVE_IP_FOR_REMOVE,
  SAVE_IP_FOR_REMOVE_BEGIN,
  SAVE_IP_FOR_REMOVE_SUCCESS,
  SAVE_IP_FOR_REMOVE_FAIL,
  GET_PP_SETTINGS,
  GET_PP_SETTINGS_BEGIN,
  GET_PP_SETTINGS_SUCCESS,
  GET_PP_SETTINGS_FAIL,
  SAVE_PP_SETTINGS,
  SAVE_PP_SETTINGS_BEGIN,
  SAVE_PP_SETTINGS_SUCCESS,
  SAVE_PP_SETTINGS_FAIL,
  GET_PAYOUT_ELIGIBILITY_SETTINGS,
  GET_PAYOUT_ELIGIBILITY_SETTINGS_SUCCESS,
  GET_PAYOUT_ELIGIBILITY_SETTINGS_FAIL,
  GET_PAYOUT_ELIGIBILITY_SETTINGS_BEGIN,
  UPDATE_PAYOUT_ELIGIBILITY_SETTINGS,
  UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_BEGIN,
  UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_SUCCESS,
  UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_FAIL,
} from "./actionTypes";

export const getGeneralSettings = () => ({
  type: GET_GENERAL_SETTINGS,
});

export const getGeneralSettingsBegin = () => ({
  type: GET_GENERAL_SETTINGS_BEGIN,
});

export const getGeneralSettingsSuccess = (settingDetails) => ({
  type: GET_GENERAL_SETTINGS_SUCCESS,
  payload: settingDetails,
});

export const getGeneralSettingsFail = (error) => ({
  type: GET_GENERAL_SETTINGS_FAIL,
  payload: error,
});

export const saveGeneralSettings = (generalSettingsDetails) => ({
  type: SAVE_GENERAL_SETTINGS,
  payload: generalSettingsDetails,
});

export const saveGeneralSettingsBegin = () => ({
  type: SAVE_GENERAL_SETTINGS_BEGIN,
});

export const saveGeneralSettingsSuccess = (response) => ({
  type: SAVE_GENERAL_SETTINGS_SUCCESS,
  payload: response,
});

export const saveGeneralSettingsFail = (error) => ({
  type: SAVE_GENERAL_SETTINGS_FAIL,
  payload: error,
});

export const saveIPForBlock = (generalSettingsDetails) => ({
  type: SAVE_IP_FOR_REMOVE,
  payload: generalSettingsDetails,
});

export const saveIPForBlockBegin = () => ({
  type: SAVE_IP_FOR_REMOVE_BEGIN,
});

export const saveIPForBlockSuccess = (response) => ({
  type: SAVE_IP_FOR_REMOVE_SUCCESS,
  payload: response,
});

export const saveIPForBlocksFail = (error) => ({
  type: SAVE_IP_FOR_REMOVE_FAIL,
  payload: error,
});

export const getPerformancePoints = () => ({
  type: GET_PP_SETTINGS,
});

export const getPerformancePointsBegin = () => ({
  type: GET_PP_SETTINGS_BEGIN,
});

export const getPerformancePointsSuccess = (pp_details) => ({
  type: GET_PP_SETTINGS_SUCCESS,
  payload: pp_details,
});

export const getPerformancePointsFail = (error) => ({
  type: GET_PP_SETTINGS_FAIL,
  payload: error,
});

export const savePPSettings = (ppDetails) => ({
  type: SAVE_PP_SETTINGS,
  payload: ppDetails,
});

export const savePPSettingsBegin = () => ({
  type: SAVE_PP_SETTINGS_BEGIN,
});

export const savePPSettingsSuccess = (response) => ({
  type: SAVE_PP_SETTINGS_SUCCESS,
  payload: response,
});

export const savePPSettingsFail = (error) => ({
  type: SAVE_PP_SETTINGS_FAIL,
  payload: error,
});
export const getPayoutEligibilitySettings = () => ({
  type: GET_PAYOUT_ELIGIBILITY_SETTINGS,
});

export const getPayoutEligibilitySettingsBegin = () => ({
  type: GET_PAYOUT_ELIGIBILITY_SETTINGS_BEGIN,
});

export const getPayoutEligibilitySettingsSuccess = (settingDetails) => ({
  type: GET_PAYOUT_ELIGIBILITY_SETTINGS_SUCCESS,
  payload: settingDetails,
});

export const getPayoutEligibilitySettingsFail = (error) => ({
  type: GET_PAYOUT_ELIGIBILITY_SETTINGS_FAIL,
  payload: error,
});

export const updatePayoutEligibilitySettings = ({ id, data }) => ({
  id: id,
  data: data,
  type: UPDATE_PAYOUT_ELIGIBILITY_SETTINGS,
});

export const updatePayoutEligibilitySettingsBegin = () => ({
  type: UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_BEGIN,
});

export const updatePayoutEligibilitySettingsSuccess = (data) => ({
  type: UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_SUCCESS,
  payload: data,
});

export const updatePayoutEligibilitySettingsFail = (error) => ({
  type: UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_FAIL,
  payload: error,
});
