import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";

const GRSFilter = ({ setRequest, request }) => {
  const [selectedUserRole, setSelectedUserRole] = useState("sender"); // Store a single value
  const [modal_center, setmodal_center] = useState(false);
  const { t } = useTranslation();

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const handleApply = () => {
    setRequest({
      ...request,
      filter: selectedUserRole, // Store the single selected value
    });
    console.log("filter", request);
    setmodal_center(false);
  };

  return (
    <div className="user-filterBtn-wrap" data-testid="component-filterPopup">
      <button
        type="button"
        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
        onClick={() => {
          tog_center();
        }}
        data-testid="component-filterButton"
      >
        <i
          style={{ paddingRight: "10px" }}
          className="fa fa-filter"
          aria-hidden="true"
        ></i>
        {t("filter.title")}
      </button>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={true}
        data-testid="component-filterModal"
      >
        <div className="modal-header userList-filter-header">
          <h5 className="modal-title mt-0">FILTER</h5>

          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body userList-filter-body">
          <div className="userList-filter-SectionWrap">
            <div className="userList-filter-checkBoxWrap alloc_UN_filterCheckboxWrap">
              <div>
                <div>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="user_as_sender"
                    onChange={() => setSelectedUserRole("sender")}
                    checked={selectedUserRole === "sender"}
                    data-testid="component-filter-activeCheck"
                  />
                  <label className="form-check-label" htmlFor="user_as_sender">
                    User As Sender
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="user_as_receiver"
                    onChange={() => setSelectedUserRole("receiver")}
                    checked={selectedUserRole === "receiver"}
                    data-testid="component-filter-InactiveCheck"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="user_as_receiver"
                  >
                    User As Receiver
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              tog_center();
            }}
            data-testid="component-filter-cancel"
          >
            CANCEL
          </button>
          <button
            type="button"
            className="btn bgcolor-btn-green"
            onClick={() => handleApply()}
            data-testid="component-filter-apply"
          >
            APPLY
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default GRSFilter;
