import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, Row, UncontrolledAlert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import parsePhoneNumber from "libphonenumber-js/mobile";

import BackButton from "../../../../components/UI/BackButton";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../../components/UI/ConfirmationAlert";
import Loader from "../../../../components/Common/Loader";
import PhoneField from "../../../../components/UI/PhoneField";
import History from "../../../../components/UI/History";

import Camera_icon from "../../../../assets/icons/ebud-icons/Camera.svg";
import Delete_icon from "../../../../assets/icons/ebud-icons/Delete.svg";
import Avatar from "../../../../assets/images/users/user.svg";

import {
  deleteAdminData,
  getAdminDetails,
  getSamplePassword,
  resetMessage,
  updateAdminDetails,
  addAdmin,
  clearPassword,
} from "../../../../../src/store/adminManagement/admins/action";

import { EMAIL_REGEX } from "../../../../helpers/constants";
import { checkUserPermission } from "../../../../helpers/utils";

const EditAdminDetail = () => {
  const match = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const isEditState = history?.location?.state?.isEdit;

  const { t } = useTranslation();
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [profilePic, setProfilePic] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [role, setRole] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [imageFile, setImageFile] = useState("");

  const [status, setStatus] = useState(
    !isEditState && t("Admins.filter.active")
  );
  const [token, setToken] = useState("");

  const [formChanged, setFormChanged] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [backToListing, setBackToListing] = useState(false);

  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isDeleteable = userPermissions?.delete || false;

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const data = useSelector((state) => state.admins);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(loginData?.rolePermissions?.role, "Admin Management", "Admins")
      );
    }
  }, [loginData]);

  useEffect(() => {
    isEditState && dispatch(getAdminDetails(match.id));
  }, [match.id, dispatch]);

  // useEffect(() => {
  //   dispatch(getAdminRolesData());
  // }, []);

  useEffect(() => {
    if (isEditState && data?.adminDetails) {
      setProfilePic(data?.adminDetails?.profile_photo || Avatar);
      setName(data?.adminDetails?.name);
      setEmail(data?.adminDetails?.email);
      setRole(data?.adminDetails?.role);
      setMobile(data?.adminDetails?.phone);
      setCountryCode(data?.adminDetails?.country_code);
      setStatus(data?.adminDetails?.status);
      setToken(data?.adminDetails?.mfa);
    }
  }, [data?.adminDetails]);

  useEffect(() => {
    if (
      name &&
      email &&
      new RegExp(EMAIL_REGEX).test(email) &&
      mobile &&
      parsePhoneNumber(`${countryCode}${mobile}`)?.isValid() &&
      status &&
      role
    ) {
      !isEditState
        ? password
          ? setIsButtonDisabled(false)
          : setIsButtonDisabled(true)
        : setIsButtonDisabled(false);
    } else setIsButtonDisabled(true);
  }, [name, email, status, mobile, countryCode, password, role]);

  useEffect(() => {
    backToListing &&
      history.push({
        pathname:
          history?.location?.state?.from === "admins"
            ? `/admin-management`
            : `/admin-details/${match.id}`,
        state: { from: "keepFilter" },
      });
  }, [backToListing, match.id, history]);

  useEffect(() => {
    scrollToAlertMessage();
  }, [
    nameError,
    emailError,
    mobileError,
    passwordError,
    typeof data?.error === "string" && data?.error,
  ]);

  useEffect(() => {
    setPassword(data?.samplePassword.password);
  }, [data?.samplePassword]);

  const scrollToAlertMessage = () => {
    if (
      (nameError || emailError || mobileError || passwordError) &&
      typeof data?.error === "string" &&
      data?.error
    ) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  const deletePromptHandler = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Admins.deleteConfirm"),
      type: "delete",
    });
  };

  const confirmBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
  };

  const redirectMethod = () => {
    // dispatch(resetMessage());
    dispatch(clearPassword());
    setBackToListing(true);
  };

  const okHandler = () => {
    promptMessage.type === "back"
      ? redirectMethod()
      : dispatch(
          deleteAdminData({
            data: match.id,
            callback: () => history.push("/admin-management"),
          })
        );
  };

  const readFile = (file) => {
    setImageFile(file?.target?.files[0])
    const reader = new FileReader();
    reader.onload = () => {
      setProfilePic(reader.result);
    };
    reader.readAsDataURL(file?.target?.files[0]);
  };

  const handleUpdate = () => {
    if (name && role && email && mobile  && status) {
    const selectedRole = data?.adminData?.roles?.find((adminRole) =>adminRole.name === role)

      const uploadData = {
        name: name,
        role_id: selectedRole.id,
        email: email,
        country_code: countryCode,
        phone: mobile,
        password: password,
        status: status,
        id:match.id,
      };
      isEditState
        ? dispatch(
            updateAdminDetails(uploadData,imageFile, () =>
              redirectMethod()
            )
          )
        : dispatch(addAdmin(uploadData,imageFile, () => redirectMethod()));
    }
  };

  const handleGeneratePassword = () => {
    dispatch(getSamplePassword());
  };

  useEffect(() => {
    data?.error?.name && setNameError(data?.error?.name[0]);
    data?.error?.email && setEmailError(data?.error?.email[0]);
    data?.error?.mobile && setMobileError(data?.error?.mobile[0]);
    data?.error?.password && setPasswordError(data?.error?.passwordError[0]);
  }, [data?.error]);

  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };

  return (
    <div
      className="page-content userDetailWrap"
      data-testid="component-editAdminDetail"
      ref={pageWrapRef}
    >
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            return confirmBrowserBack();
          }
        }}
        when={formChanged}
      />
      <BackButton
        label={
          history?.location?.state?.from === "admins"
            ? t("Admins.Title")
            : "Admin Details"
        }
        handleClick={() => (formChanged ? confirmBack() : redirectMethod())}
      />
      <Breadcrumbs
        title="Tables"
        breadcrumbItem={
          isEditState ? t("Admins.editAdmin.Title") : t("Admins.addAdmin.Title")
        }
      />
      <Col xl="12">
        {(nameError || emailError || mobileError || passwordError) && (
          <div ref={warningAlertRef}>
            <UncontrolledAlert
              color="danger"
              className="alert-dismissible fade show"
              role="alert"
            >
              {nameError || emailError || mobileError || passwordError}
            </UncontrolledAlert>
          </div>
        )}
        {typeof data?.error === "string" && data?.error && (
          <div ref={warningAlertRef}>
            <UncontrolledAlert
              color="danger"
              className="alert-dismissible fade show"
              role="alert"
            >
              {data?.error}
            </UncontrolledAlert>
          </div>
        )}

        <Card className="mb-0 p-4">
          <div className="userDetailActions">
            {isEditState && (
              <button
                className="action-buttons"
                title="Delete"
                onClick={() => deletePromptHandler()}
                disabled = {status === "Deleted" || !isDeleteable}
              >
                <img src={Delete_icon} alt="Delete" 
                style={{ opacity: status === "Deleted" || !isDeleteable ? "50%" : "" }}/>
                <span className="color-red"> {t("Admins.delete")}</span>
              </button>
            )}
          </div>

          <div className="edit-admin-profilePic-wrapper">
            <label>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => {
                  readFile(e);
                  setFormChanged(true);
                }}
              />
              <img
                src={profilePic}
                alt=""
                className="avatar-xl rounded-circle img-thumbnail editAdmin-profile-pic"
              />
              <img
                src={Camera_icon}
                alt="Camera"
                className="edit-admin-role-camera-icon"
              />
            </label>
          </div>
          <hr className="my-4" />
          <div className="text-muted">
            <AvForm
              onValidSubmit={(e, v) => {
                handleUpdate(e, v);
              }}
            >
              <div className="table-responsive mt-4">
                <Row className="col-md-12 editAdminRow">
                  <div className="col-md-5">
                    <label
                      htmlFor="name-input"
                      className="col-md-8 col-form-label"
                    >
                      {t("Admins.editAdmin.name")}
                      <span className="mandatory">*</span>
                    </label>
                    <div className="col-md-8 dobWrapper">
                      <AvField
                        name="adminName"
                        type="text"
                        className="form-control cursor-pointer"
                        value={name}
                        id="name-input"
                        onChange={(e) => {
                          setNameError("");
                          setName(e.target.value);
                          setFormChanged(true);
                        }}
                        placeholder={t("Admins.editAdmin.enterName")}
                        
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label
                      htmlFor="role-input"
                      className="col-md-8 col-form-label"
                    >
                      {t("Admins.editAdmin.role")}
                      <span className="mandatory">*</span>
                    </label>
                    <div className="dobWrapper">
                      <AvField
                        name="adminRole"
                        type="select"
                        className="form-control cursor-pointer admin-select-field"
                        value={role}
                        id="role-input"
                        onChange={(e) => {                    
                          setRole(e.target.value);      
                          setFormChanged(true);
                        }}
                      >
                        <option value="">Select</option>
                        {data?.adminData?.roles?.map((role) => (
                          <option value={role.name}>{role.name}</option>
                        ))}
                      </AvField>
                    </div>
                  </div>
                </Row>
                <Row className="col-md-12 editAdminRow">
                  <div className="col-md-5">
                    <label
                      htmlFor="email-input"
                      className="col-md-8 col-form-label"
                    >
                      {t("Admins.editAdmin.email")}
                      <span className="mandatory">*</span>
                    </label>
                    <div className="col-md-8 dobWrapper">
                      <AvField
                        name="adminEmail"
                        type="email"
                        errorMessage={t("Common.error.email")}
                        className="form-control cursor-pointer"
                        value={email}
                        id="email-input"
                        onChange={(e) => {
                          setEmailError("");
                          setEmail(e.target.value);
                          setFormChanged(true);
                        }}
                        placeholder={t("Admins.editAdmin.enterEmail")}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 cursor-pointer">
                    <PhoneField
                      labelName={t("Admins.editAdmin.mobile")}
                      countryPlaceholder="Select Country Code"
                      phonePlaceholder={t("Admins.editAdmin.enterMobile")}
                      handleCountryChange={(e,data) =>
                        setCountryCode(data?.value)
                      }
                      handlePhoneNumberChange={(e) => setMobile(e.target.value)}
                      mandatory={true}
                      countryFieldValue={countryCode}
                      phoneFieldValue={mobile} 
                      countryMinWidth = "132px"
                      phoneMinWidth = "97px"               
                    />
                  </div>
                </Row>

                <Row className="col-md-12 editAdminRow">
                  <div className="col-md-5">
                    <label
                      htmlFor="example-date-input"
                      className="col-md-8 col-form-label"
                    >
                      {t("Admins.editAdmin.password")}
                      <span className="mandatory">*</span>
                    </label>
                    <div style={{ display: "flex" }}>
                      <AvField
                        name="adminPassword"
                        type="text"
                        className="col-md-6 form-control cursor-pointer"
                        value={password}
                        id="password-input"
                        onChange={(e) => {
                          setPasswordError("");
                          setPassword(e.target.value);
                          setFormChanged(true);
                        }}
                        placeholder={t("Admins.editAdmin.enterPassword")}
                      />
                      <button
                        type="button"
                        onClick={() => handleGeneratePassword()}
                        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green edit-admin-password"
                      >
                        {t("Admins.editAdmin.generatePassword")}
                      </button>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label
                      htmlFor="status-input"
                      className="col-md-8 col-form-label"
                    >
                      {t("Admins.editAdmin.status")}
                      <span className="mandatory">*</span>
                    </label>
                    <div className="dobWrapper">
                      <AvField
                        name="adminStatus"
                        type="select"
                        className="form-control cursor-pointer admin-select-field"
                        value={status}
                        id="status-input"
                        onChange={(e) => {
                          setStatus(e.target.value);
                          setFormChanged(true);
                        }}
                      >
                        <option value="">Select</option>
                        <option>{t("Admins.filter.active")}</option>
                        <option disabled={!isEditState}>
                          {t("Admins.filter.deleted")}
                        </option>
                        <option disabled={!isEditState}>
                          {t("Admins.filter.blocked")}
                        </option>
                      </AvField>
                    </div>
                  </div>
                </Row>
                {isEditState && (
                  <Row className="col-md-12 editAdminRow">
                    <div className="col-md-5">
                      <label
                        htmlFor="token-input"
                        className="col-md-8 col-form-label"
                      >
                        {t("Admins.editAdmin.token")}
                      </label>
                      <div className="col-md-8 dobWrapper">
                        <AvField
                          name="adminToken"
                          type="text"
                          className="form-control"
                          value={token}
                          id="token-input"
                          disabled
                        />
                      </div>
                    </div>
                  </Row>
                )}
                <div className="edit-admin-history-container">
                  {isEditState && (
                    <History
                      createdDate={data?.adminDetails?.created_on}
                      createdBy={data?.adminDetails?.created_by}
                      lastModifiedBy={data?.adminDetails?.modified_by}
                      lastModifiedDate={data?.adminDetails?.modified_on}
                    />
                  )}
                </div>
                <div className="p-4 text-center">
                  <button
                    type="submit"
                    disabled={isButtonDisabled}
                    className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                  >
                    {isEditState ? t("Common.update") : t("Common.save")}
                  </button>
                </div>
              </div>
            </AvForm>
          </div>
        </Card>
      </Col>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default EditAdminDetail;
