import {
  ADD_PODIUMS_CATEGORIES,
  ADD_PODIUMS_CATEGORIES_BEGIN,
  ADD_PODIUMS_CATEGORIES_FAIL,
  ADD_PODIUMS_CATEGORIES_SUCCESS,
  DELETE_PODIUMS,
  DELETE_PODIUMS_BEGIN,
  DELETE_PODIUMS_CATEGORIES,
  DELETE_PODIUMS_CATEGORIES_BEGIN,
  DELETE_PODIUMS_CATEGORIES_FAIL,
  DELETE_PODIUMS_CATEGORIES_SUCCESS,
  DELETE_PODIUMS_FAIL,
  DELETE_PODIUMS_SUCCESS,
  EDIT_PODIUMS,
  EDIT_PODIUMS_BEGIN,
  EDIT_PODIUMS_CATEGORIES,
  EDIT_PODIUMS_CATEGORIES_BEGIN,
  EDIT_PODIUMS_CATEGORIES_FAIL,
  EDIT_PODIUMS_CATEGORIES_SUCCESS,
  EDIT_PODIUMS_FAIL,
  EDIT_PODIUMS_SUCCESS,
  GET_CHALLENGES_HISTORY,
  GET_CHALLENGES_HISTORY_BEGIN,
  GET_CHALLENGES_HISTORY_BY_USER_ID,
  GET_CHALLENGES_HISTORY_BY_USER_ID_BEGIN,
  GET_CHALLENGES_HISTORY_BY_USER_ID_FAIL,
  GET_CHALLENGES_HISTORY_BY_USER_ID_SUCCESS,
  GET_CHALLENGES_HISTORY_FAIL,
  GET_CHALLENGES_HISTORY_SUCCESS,
  GET_PODIUMS,
  GET_PODIUMS_BEGIN,
  GET_PODIUMS_BY_ID,
  GET_PODIUMS_BY_ID_BEGIN,
  GET_PODIUMS_BY_ID_FAIL,
  GET_PODIUMS_BY_ID_SUCCESS,
  GET_PODIUMS_CATEGORIES,
  GET_PODIUMS_CATEGORIES_BEGIN,
  GET_PODIUMS_CATEGORIES_BY_ID,
  GET_PODIUMS_CATEGORIES_BY_ID_BEGIN,
  GET_PODIUMS_CATEGORIES_BY_ID_FAIL,
  GET_PODIUMS_CATEGORIES_BY_ID_SUCCESS,
  GET_PODIUMS_CATEGORIES_FAIL,
  GET_PODIUMS_CATEGORIES_SUCCESS,
  GET_PODIUMS_FAIL,
  GET_PODIUMS_STAT,
  GET_PODIUMS_SUCCESS,
  GET_SPECIFIC_CHALLENGE_DETAILS,
  GET_SPECIFIC_CHALLENGE_DETAILS_BEGIN,
  GET_SPECIFIC_CHALLENGE_DETAILS_FAIL,
  GET_SPECIFIC_CHALLENGE_DETAILS_SUCCESS,
  RESET_PODIUMS_ERRORS,
  STORE_PODIUM_REQUESTS,
} from "./actionTypes";

export const getPodiums = (request) => ({
  type: GET_PODIUMS,
  request: request,
});

export const getPodiumStatistics = (request) => ({
  type: GET_PODIUMS_STAT,
  request: request,
});

export const getPodiumsBegin = () => ({
  type: GET_PODIUMS_BEGIN,
});

export const getPodiumsSuccess = (podiums) => ({
  type: GET_PODIUMS_SUCCESS,
  payload: podiums,
});

export const getPodiumsFail = (error) => ({
  type: GET_PODIUMS_FAIL,
  payload: error,
});

export const storePodiumRequests = (request) => ({
  type: STORE_PODIUM_REQUESTS,
  request: request,
});

export const getPodiumsById = ({ id }) => ({
  type: GET_PODIUMS_BY_ID,
  id: id,
});

export const getPodiumsByIdBegin = () => ({
  type: GET_PODIUMS_BY_ID_BEGIN,
});

export const getPodiumsByIdSuccess = (podium) => ({
  type: GET_PODIUMS_BY_ID_SUCCESS,
  payload: podium,
});

export const getPodiumsByIdFail = (error) => ({
  type: GET_PODIUMS_BY_ID_FAIL,
  payload: error,
});

export const editPodium = (data, id, callback) => ({
  type: EDIT_PODIUMS,
  // file: file,
  id: id,
  payload: data,
  callback: callback,
});

export const editPodiumBegin = () => ({
  type: EDIT_PODIUMS_BEGIN,
});

export const editPodiumSuccess = (podium) => ({
  type: EDIT_PODIUMS_SUCCESS,
  payload: podium,
});

export const editPodiumFail = (error) => ({
  type: EDIT_PODIUMS_FAIL,
  payload: error,
});

export const deletePodium = ({ id, callback }) => ({
  type: DELETE_PODIUMS,
  id: id,
  callback: callback,
});

export const deletePodiumBegin = () => ({
  type: DELETE_PODIUMS_BEGIN,
});

export const deletePodiumSuccess = (msg) => ({
  type: DELETE_PODIUMS_SUCCESS,
  message: msg,
});

export const deletePodiumFail = (error) => ({
  type: DELETE_PODIUMS_FAIL,
  payload: error,
});

export const getPodiumsCategories = (request) => ({
  type: GET_PODIUMS_CATEGORIES,
  request: request,
});

export const getPodiumsCategoriesBegin = () => ({
  type: GET_PODIUMS_CATEGORIES_BEGIN,
});

export const getPodiumsCategoriesSuccess = (categories) => ({
  type: GET_PODIUMS_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getPodiumsCategoriesFail = (error) => ({
  type: GET_PODIUMS_CATEGORIES_FAIL,
  payload: error,
});
export const addPodiumsCategories = ({ category, callback }) => ({
  type: ADD_PODIUMS_CATEGORIES,
  category: category,
  callback: callback,
});

export const addPodiumsCategoriesBegin = () => ({
  type: ADD_PODIUMS_CATEGORIES_BEGIN,
});

export const addPodiumsCategoriesSuccess = (category) => ({
  type: ADD_PODIUMS_CATEGORIES_SUCCESS,
  payload: category,
});

export const addPodiumsCategoriesFail = (error) => ({
  type: ADD_PODIUMS_CATEGORIES_FAIL,
  payload: error,
});

export const getPodiumsCategoriesById = (id) => ({
  type: GET_PODIUMS_CATEGORIES_BY_ID,
  id: id,
});

export const getPodiumsCategoriesByIdBegin = () => ({
  type: GET_PODIUMS_CATEGORIES_BY_ID_BEGIN,
});

export const getPodiumsCategoriesByIdSuccess = (category) => ({
  type: GET_PODIUMS_CATEGORIES_BY_ID_SUCCESS,
  payload: category,
});

export const getPodiumsCategoriesByIdFail = (error) => ({
  type: GET_PODIUMS_CATEGORIES_BY_ID_FAIL,
  payload: error,
});
export const editPodiumsCategories = ({
  category,
  id,
  categoryList,
  // callback,
}) => ({
  type: EDIT_PODIUMS_CATEGORIES,
  params: category,
  id: id,
  categoryList: categoryList,
  // callback: callback,
});

export const resetPodiumsErrors = () => ({
  type: RESET_PODIUMS_ERRORS,
});

export const editPodiumsCategoriesBegin = () => ({
  type: EDIT_PODIUMS_CATEGORIES_BEGIN,
});

export const editPodiumsCategoriesSuccess = (category) => ({
  type: EDIT_PODIUMS_CATEGORIES_SUCCESS,
  payload: category,
});

export const editPodiumsCategoriesFail = (error) => ({
  type: EDIT_PODIUMS_CATEGORIES_FAIL,
  payload: error,
});
export const deletePodiumsCategories = ({ id, callback }) => ({
  type: DELETE_PODIUMS_CATEGORIES,
  id: id,
  callback: callback,
});

export const deletePodiumsCategoriesBegin = () => ({
  type: DELETE_PODIUMS_CATEGORIES_BEGIN,
});

export const deletePodiumsCategoriesSuccess = (category) => ({
  type: DELETE_PODIUMS_CATEGORIES_SUCCESS,
  payload: category,
});

export const deletePodiumsCategoriesFail = (error) => ({
  type: DELETE_PODIUMS_CATEGORIES_FAIL,
  payload: error,
});

export const getChallengesHistory = (request) => ({
  type: GET_CHALLENGES_HISTORY,
  request: request,
});

export const getChallengesHistoryBegin = () => ({
  type: GET_CHALLENGES_HISTORY_BEGIN,
});

export const getChallengesHistorySuccess = (challenges) => ({
  type: GET_CHALLENGES_HISTORY_SUCCESS,
  payload: challenges,
});

export const getChallengesHistoryFail = (error) => ({
  type: GET_CHALLENGES_HISTORY_FAIL,
  payload: error,
});
export const getChallengesHistoryByUserId = ({ request, userId }) => ({
  type: GET_CHALLENGES_HISTORY_BY_USER_ID,
  request: request,
  userId: userId,
});

export const getChallengesHistoryByUserIdBegin = () => ({
  type: GET_CHALLENGES_HISTORY_BY_USER_ID_BEGIN,
});

export const getChallengesHistoryByUserIdSuccess = (challenges) => ({
  type: GET_CHALLENGES_HISTORY_BY_USER_ID_SUCCESS,
  payload: challenges,
});

export const getChallengesHistoryByUserIdFail = (error) => ({
  type: GET_CHALLENGES_HISTORY_BY_USER_ID_FAIL,
  payload: error,
});

export const getSpecificChallengeDetails = ({
  request,
  podiumId,
  challengeId,
}) => ({
  type: GET_SPECIFIC_CHALLENGE_DETAILS,
  request: request,
  podiumId: podiumId,
  challengeId: challengeId,
});

export const getSpecificChallengeDetailsBegin = () => ({
  type: GET_SPECIFIC_CHALLENGE_DETAILS_BEGIN,
});

export const getSpecificChallengeDetailsSuccess = (challengeDetails) => ({
  type: GET_SPECIFIC_CHALLENGE_DETAILS_SUCCESS,
  payload: challengeDetails,
});

export const getSpecificChallengeDetailsFail = (error) => ({
  type: GET_SPECIFIC_CHALLENGE_DETAILS_FAIL,
  payload: error,
});
