import {
  GET_GENERAL_SETTINGS_BEGIN,
  GET_GENERAL_SETTINGS_SUCCESS,
  GET_GENERAL_SETTINGS_FAIL,
  SAVE_GENERAL_SETTINGS_BEGIN,
  SAVE_GENERAL_SETTINGS_SUCCESS,
  SAVE_GENERAL_SETTINGS_FAIL,
  SAVE_IP_FOR_REMOVE_BEGIN,
  SAVE_IP_FOR_REMOVE_SUCCESS,
  SAVE_IP_FOR_REMOVE_FAIL,
  GET_PP_SETTINGS_BEGIN,
  GET_PP_SETTINGS_SUCCESS,
  GET_PP_SETTINGS_FAIL,
  SAVE_PP_SETTINGS_BEGIN,
  SAVE_PP_SETTINGS_SUCCESS,
  SAVE_PP_SETTINGS_FAIL,
  GET_PAYOUT_ELIGIBILITY_SETTINGS_BEGIN,
  GET_PAYOUT_ELIGIBILITY_SETTINGS_SUCCESS,
  GET_PAYOUT_ELIGIBILITY_SETTINGS_FAIL,
  UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_BEGIN,
  UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_SUCCESS,
  UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  loading: false,
  generalSettings: {},
  error: "",
  success: "",
  pp_error: "",
  pp_success: "",
  pp_loading: "",
  ppSettings: {},
  payoutEligibilitySettings: {},
};

const AppSettings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GENERAL_SETTINGS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case GET_GENERAL_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        generalSettings: action.payload,
      };

    case GET_GENERAL_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SAVE_GENERAL_SETTINGS_BEGIN:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };

    case SAVE_GENERAL_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        generalSettings: action.payload.result,
        success: "General settings updated",
        pp_success: "",
      };

    case SAVE_GENERAL_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case SAVE_IP_FOR_REMOVE_BEGIN:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };

    case SAVE_IP_FOR_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        generalSettings: action.payload.result,
        success: "Removed blocking ips",
        pp_success: "",
      };

    case SAVE_IP_FOR_REMOVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case GET_PP_SETTINGS_BEGIN:
      return {
        ...state,
        pp_loading: true,
        pp_error: "",
        pp_success: "",
      };

    case GET_PP_SETTINGS_SUCCESS:
      return {
        ...state,
        pp_loading: false,
        pp_success: "",
        pp_error: "",
        ppSettings: action.payload,
      };

    case GET_PP_SETTINGS_FAIL:
      return {
        ...state,
        pp_loading: false,
        pp_success: "",
        pp_error: action.payload,
      };

    case SAVE_PP_SETTINGS_BEGIN:
      return {
        ...state,
        pp_loading: true,
        pp_error: "",
        pp_success: "",
      };

    case SAVE_PP_SETTINGS_SUCCESS:
      return {
        ...state,
        pp_loading: false,
        pp_error: "",
        ppSettings: action.payload,
        pp_success: "Performance Points Updated",
        success: "",
      };

    case SAVE_PP_SETTINGS_FAIL:
      return {
        ...state,
        pp_loading: false,
        pp_error: action.payload,
        pp_success: "",
      };
    case GET_PAYOUT_ELIGIBILITY_SETTINGS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case GET_PAYOUT_ELIGIBILITY_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        payoutEligibilitySettings: action.payload,
      };

    case GET_PAYOUT_ELIGIBILITY_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default AppSettings;
