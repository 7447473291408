import { Link } from "react-router-dom";
const redirectURL = (data) => {
    let redirectUrl =
        data.action === "user_delete" ||
            data.action === "new_subscription" ||
            data.action === "renew_subscription"
            ? "/users"
            : data.action === "report_user"
                ? "/reported-data"
                : data.action === "reject_username" || data.action === "accept_username"
                    ? `/allocate-usernames-edit/${data.associate_obj_id}`
                    : "";
    return redirectUrl
}

const NotificationTimeLine = ({ arrNotifications }) => {
    return (
        <ul className="verti-timeline list-unstyled">
            {arrNotifications.map((notification, key) => {
                return (

                    <li className="event-list" key={key}  >
                        <Link to={redirectURL(notification)}>
                            <div className="event-date text-primary">{notification.delivered_time}</div>
                            <p className="text-muted mx-2"><i>{notification.title.toUpperCase()}</i> : {notification.body}</p>
                        </Link>
                    </li>
                )
            })}
        </ul >
    );
}
export default NotificationTimeLine;