/* USERS */
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_DATA_BEGIN = "GET_USER_DATA_BEGIN";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAIL = "GET_USER_DETAILS_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_BEGIN = "DELETE_USER_BEGIN";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const BLOCK_USER = "BLOCK_USER";
export const BLOCK_USER_BEGIN = "BLOCK_USER_BEGIN";
export const BLOCK_USER_SUCCESS = "BLOCK_USER_SUCCESS";
export const BLOCK_USER_FAIL = "BLOCK_USER_FAIL";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_BEGIN = "GET_USER_DETAILS_BEGIN";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";

// Export user details

export const EXPORT_USER_EMAIL = "EXPORT_USER_EMAIL";
export const EXPORT_USER_EMAIL_BEGIN = "EXPORT_USER_EMAIL_BEGIN";
export const EXPORT_USER_EMAIL_SUCCESS = "EXPORT_USER_EMAIL_SUCCESS";
export const EXPORT_USER_EMAIL_FAIL = "EXPORT_USER_EMAIL_FAIL";

export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_DETAILS_BEGIN = "UPDATE_USER_DETAILS_BEGIN";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAIL = "UPDATE_USER_DETAILS_FAIL";

export const STORE_USER_REQUEST = "STORE_USER_REQUEST";

export const RESET_ERRORS = "RESET_ERRORS";

export const LOGOUT_USER = "LOGOUT_USER";

export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const SEND_NOTIFICATION_BEGIN = "SEND_NOTIFICATION_BEGIN";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAIL = "SEND_NOTIFICATION_FAIL";

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

export const GET_BLOCKED_USERS = "GET_BLOCKED_USERS";
export const GET_BLOCKED_USERS_BEGIN = "GET_BLOCKED_USERS_BEGIN";
export const GET_BLOCKED_USERS_SUCCESS = "GET_BLOCKED_USERS_SUCCESS";
export const GET_BLOCKED_USERS_FAIL = "GET_BLOCKED_USERS_FAIL";
