/*TEMPLATES */
export const GET_TEMPLATE_LISTING = "GET_TEMPLATE_LISTING";
export const GET_TEMPLATE_LISTING_BEGIN = "GET_TEMPLATE_LISTING_BEGIN";
export const GET_TEMPLATE_LISTING_SUCCESS = "GET_TEMPLATE_LISTING_SUCCESS";
export const GET_TEMPLATE_LISTING_FAIL = "GET_TEMPLATE_LISTING_FAIL";

export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const EDIT_TEMPLATE_BEGIN = "EDIT_TEMPLATE_BEGIN";
export const EDIT_TEMPLATE_SUCCESS = "EDIT_TEMPLATE_SUCCESS";
export const EDIT_TEMPLATE_FAIL = "EDIT_TEMPLATE_FAIL";

export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const UPDATE_TEMPLATE_BEGIN = "UPDATE_TEMPLATE_BEGIN";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_FAIL = "UPDATE_TEMPLATE_FAIL";

export const ADD_TEMPLATE = "ADD_TEMPLATE";
export const ADD_TEMPLATE_BEGIN = "ADD_TEMPLATE_BEGIN";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_FAIL = "ADD_TEMPLATE_FAIL";
