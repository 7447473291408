import {
    GET_APP_INSTALLATIONS,
    GET_APP_INSTALLATIONS_BEGIN,
    GET_APP_INSTALLATIONS_SUCCESS,
    GET_APP_INSTALLATIONS_FAIL,
    GET_INSTALLATION_COUNTRY,
    GET_INSTALLATION_COUNTRY_BEGIN,
    GET_INSTALLATION_COUNTRY_SUCCESS,
    GET_INSTALLATION_COUNTRY_FAIL,
    STORE_APP_INSTALLATIONS_REQUEST,
    GET_INSTALLATION_REGION,
    GET_INSTALLATION_REGION_BEGIN,
    GET_INSTALLATION_REGION_SUCCESS,
    GET_INSTALLATION_REGION_FAIL,
    UPDATE_INSTALLATION_REGION_BEGIN,
    UPDATE_INSTALLATION_REGION_SUCCESS,
    UPDATE_INSTALLATION_REGION_FAIL,
    UPDATE_INSTALLATION_REGION,
    SAVE_INSTALLATION_REGION,
    SAVE_INSTALLATION_REGION_BEGIN

} from './actionTypes';

export const getInstallationData = (request) => ({
    type: GET_APP_INSTALLATIONS,
    payload: { request }
})

export const getInstallationDataBegin = () => ({
    type: GET_APP_INSTALLATIONS_BEGIN,
})

export const getInstallationDataSuccess = (installationData) => ({
    type: GET_APP_INSTALLATIONS_SUCCESS,
    payload: installationData
})

export const getInstallationDataFail = (error) => ({
    type: GET_APP_INSTALLATIONS_FAIL,
    payload: error
})

export const getCountryData = () => ({
    type: GET_INSTALLATION_COUNTRY
})

export const getCountryDataBegin = () => ({
    type: GET_INSTALLATION_COUNTRY_BEGIN
})

export const getCountryDataSuccess = (installationCountry) => ({
    type: GET_INSTALLATION_COUNTRY_SUCCESS,
    payload: installationCountry
})

export const getCountryDataFail = (error) => ({
    type: GET_INSTALLATION_COUNTRY_FAIL,
    payload: error
})

export const getRegionData = () => ({
    type: GET_INSTALLATION_REGION
})

export const getRegionDataBegin = () => ({
    type:GET_INSTALLATION_REGION_BEGIN
})

export const getRegionDataSuccess = (installationCountry) => ({
    type: GET_INSTALLATION_REGION_SUCCESS,
    payload: installationCountry
})

export const getRegionDataFail = (error) => ({
    type: GET_INSTALLATION_REGION_FAIL,
    payload: error
})

export const updateRegionData = (data) => ({
    type: UPDATE_INSTALLATION_REGION,
    payload: data
})

export const updateRegionDataBegin = () => ({
    type:UPDATE_INSTALLATION_REGION_BEGIN
})

export const updateRegionDataSuccess = (installationCountry) => ({
    type: UPDATE_INSTALLATION_REGION_SUCCESS,
    payload: installationCountry
})

export const updateRegionDataFail = (error) => ({
    type: UPDATE_INSTALLATION_REGION_FAIL,
    payload: error
})

export const storeRequest = (request) => ({
    type: STORE_APP_INSTALLATIONS_REQUEST,
    payload: request,
})


export const saveRegionData = (data) => ({
    type: SAVE_INSTALLATION_REGION,
    payload: data
})

export const saveRegionDataBegin = () => ({
    type:SAVE_INSTALLATION_REGION_BEGIN
})