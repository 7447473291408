import {
  GET_USER_FLAX_OR_COINS_STATEMENTS_BEGIN,
  GET_USER_FLAX_OR_COINS_STATEMENTS_FAIL,
  GET_USER_FLAX_OR_COINS_STATEMENTS_SUCCESS,
} from "./actionTypes";

export const INIT_STATE = {
  loading: true,
  error: "",
  statements: [],
  request: {
    sort: "transfered_date",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    page_count: 50,
    keyword: "",
    success: "",
  },
};

const userFlaxStatementsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_FLAX_OR_COINS_STATEMENTS_BEGIN:
      return {
        ...state,
        loading: true,
        statements: [],
      };
    case GET_USER_FLAX_OR_COINS_STATEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        statements: action.payload,
      };
    case GET_USER_FLAX_OR_COINS_STATEMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default userFlaxStatementsReducer;
