import {
  ADD_GIFT_CATEGORIES,
  ADD_GIFT_CATEGORIES_BEGIN,
  ADD_GIFT_CATEGORIES_FAIL,
  ADD_GIFT_CATEGORIES_SUCCESS,
  DELETE_GIFT_CATEGORIES,
  DELETE_GIFT_CATEGORIES_BEGIN,
  DELETE_GIFT_CATEGORIES_FAIL,
  DELETE_GIFT_CATEGORIES_SUCCESS,
  EDIT_GIFT_CATEGORIES,
  EDIT_GIFT_CATEGORIES_BEGIN,
  EDIT_GIFT_CATEGORIES_FAIL,
  EDIT_GIFT_CATEGORIES_SUCCESS,
  GET_GIFT_CATEGORIES,
  GET_GIFT_CATEGORIES_BEGIN,
  GET_GIFT_CATEGORIES_BY_ID,
  GET_GIFT_CATEGORIES_BY_ID_BEGIN,
  GET_GIFT_CATEGORIES_BY_ID_FAIL,
  GET_GIFT_CATEGORIES_BY_ID_SUCCESS,
  GET_GIFT_CATEGORIES_FAIL,
  GET_GIFT_CATEGORIES_SUCCESS,
  RESET_GIFT_CATEGORY_ERRORS,
} from "./actionTypes";

export const getGiftCategories = (request) => ({
  type: GET_GIFT_CATEGORIES,
  request: request,
});

export const getGiftCategoriesBegin = () => ({
  type: GET_GIFT_CATEGORIES_BEGIN,
});

export const getGiftCategoriesSuccess = (categories) => ({
  type: GET_GIFT_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getGiftCategoriesFail = (error) => ({
  type: GET_GIFT_CATEGORIES_FAIL,
  payload: error,
});

export const addGiftCategories = ({ category, callback }) => ({
  type: ADD_GIFT_CATEGORIES,
  category: category,
  callback: callback,
});

export const addGiftCategoriesBegin = () => ({
  type: ADD_GIFT_CATEGORIES_BEGIN,
});

export const addGiftCategoriesSuccess = (category) => ({
  type: ADD_GIFT_CATEGORIES_SUCCESS,
  payload: category,
});

export const addGiftCategoriesFail = (error) => ({
  type: ADD_GIFT_CATEGORIES_FAIL,
  payload: error,
});

export const getGiftCategoriesById = (id) => ({
  type: GET_GIFT_CATEGORIES_BY_ID,
  id: id,
});

export const getGiftCategoriesByIdBegin = () => ({
  type: GET_GIFT_CATEGORIES_BY_ID_BEGIN,
});

export const getGiftCategoriesByIdSuccess = (category) => ({
  type: GET_GIFT_CATEGORIES_BY_ID_SUCCESS,
  payload: category,
});

export const getGiftCategoriesByIdFail = (error) => ({
  type: GET_GIFT_CATEGORIES_BY_ID_FAIL,
  payload: error,
});

export const editGiftCategories = ({
  id,
  packageData,
  // callback,
}) => ({
  type: EDIT_GIFT_CATEGORIES,
  id: id,
  categoryList: packageData,
  // callback: callback,
});
export const editGiftCategoriesBegin = () => ({
  type: EDIT_GIFT_CATEGORIES_BEGIN,
});

export const editGiftCategoriesSuccess = (category) => ({
  type: EDIT_GIFT_CATEGORIES_SUCCESS,
  payload: category,
});

export const editGiftCategoriesFail = (error) => ({
  type: EDIT_GIFT_CATEGORIES_FAIL,
  payload: error,
});

export const deleteGiftCategories = ({ id, callback }) => ({
  type: DELETE_GIFT_CATEGORIES,
  id: id,
  callback: callback,
});

export const deleteGiftCategoriesBegin = () => ({
  type: DELETE_GIFT_CATEGORIES_BEGIN,
});

export const deleteGiftCategoriesSuccess = (category) => ({
  type: DELETE_GIFT_CATEGORIES_SUCCESS,
  payload: category,
});

export const deleteGiftCategoriesFail = (error) => ({
  type: DELETE_GIFT_CATEGORIES_FAIL,
  payload: error,
});

export const resetGiftCategoryErrors = () => ({
  type: RESET_GIFT_CATEGORY_ERRORS,
});
