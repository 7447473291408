import { call, put, takeLatest } from "redux-saga/effects";
import {
  EDIT_LEGAL_DOCUMENTS,
  GET_LEGAL_DOCUMENTS,
  UPDATE_LEGAL_DOCUMENTS,
  DELETE_LEGAL_DOCUMENT,
  ENABLE_DISABLE_LEGAL_DOCUMENTS,
} from "./actionTypes";
import {
  getLegalDocumentsBegin,
  getLegalDocumentsSuccess,
  getLegalDocumentsFail,
  editLegalDocumentBegin,
  editLegalDocumentSuccess,
  editLegalDocumentFail,
  updateLegalDocumentBegin,
  updateLegalDocumentSuccess,
  updateLegalDocumentFail,
  viewPublishedDocumentBegin,
  viewPublishedDocumentSuccess,
  viewPublishedDocumentFail,
  deleteLegalDocumentBegin,
  deleteLegalDocumentSuccess,
  deleteLegalDocumentFail,
  enableDisableLegalDocumentBegin,
  enableDisableLegalDocumentFail,
  enableDisableLegalDocumentSuccess,
} from "./action";
import {
  API_GET_LEGAL_DOCUMENTS,
  API_GET_LEGAL_DOCUMET_BY_ID,
} from "../../helpers/url_helper";

import { get, update, post, del } from "../../helpers/api_helper";

function* getLegalDocuments({ payload }) {
  try {
    yield put(getLegalDocumentsBegin());
    const response = yield call(get, API_GET_LEGAL_DOCUMENTS, payload);
    console.log("lang", response);
    yield put(getLegalDocumentsSuccess(response.result));
  } catch (error) {
    yield put(getLegalDocumentsFail(error));
  }
}

function* editLegalDocument({ payload }) {
  try {
    yield put(editLegalDocumentBegin());
    const response = yield call(
      get,
      API_GET_LEGAL_DOCUMET_BY_ID.replace("{id}", payload.id),
      {
        ...payload,
      }
    );
    yield put(editLegalDocumentSuccess(response.result));
    if (response.result?.privacy_policies?.status === "Drafted") {
      try {
        yield put(viewPublishedDocumentBegin());
        const publishedVersion = yield call(
          get,
          API_GET_LEGAL_DOCUMET_BY_ID.replace(
            "{id}",
            response.result?.last_published_id
          )
        );
        yield put(viewPublishedDocumentSuccess(publishedVersion.result));
      } catch (err) {
        yield put(viewPublishedDocumentFail(err));
      }
    }
  } catch (error) {
    yield put(editLegalDocumentFail(error));
  }
}

function* updateLegalDocuments({ id, payload, method, callback }) {
  try {
    yield put(updateLegalDocumentBegin(payload));
    const response =
      method === "put"
        ? yield call(update, API_GET_LEGAL_DOCUMET_BY_ID.replace("{id}", id), {
            ...payload,
          })
        : yield call(post, API_GET_LEGAL_DOCUMET_BY_ID.replace("{id}", id), {
            ...payload,
          });
    callback && callback();
    yield put(
      updateLegalDocumentSuccess(
        payload.status === "Drafted"
          ? "Added as Draft"
          : "Legal document updated and published successfully"
      )
    );
  } catch (error) {
    yield put(updateLegalDocumentFail(error));
  }
}

function* enableDisableDocument({ id, payload, callback }) {
  try {
    yield put(enableDisableLegalDocumentBegin());
    yield call(update, API_GET_LEGAL_DOCUMET_BY_ID.replace("{id}", id), {
      ...payload,
    });
    callback && callback();
    enableDisableLegalDocumentSuccess();
  } catch (error) {
    yield put(enableDisableLegalDocumentFail(error));
  }
}

function* deleteLegalDocument({ payload, callback }) {
  try {
    yield put(deleteLegalDocumentBegin());
    yield call(del, API_GET_LEGAL_DOCUMET_BY_ID.replace("{id}", payload));
    callback && callback();
    yield put(deleteLegalDocumentSuccess("Draft deleted successfully"));
  } catch (error) {
    yield put(deleteLegalDocumentFail(error));
  }
}

function* contentManagementSaga() {
  yield takeLatest(GET_LEGAL_DOCUMENTS, getLegalDocuments);
  yield takeLatest(EDIT_LEGAL_DOCUMENTS, editLegalDocument);
  yield takeLatest(UPDATE_LEGAL_DOCUMENTS, updateLegalDocuments);
  yield takeLatest(DELETE_LEGAL_DOCUMENT, deleteLegalDocument);
  yield takeLatest(ENABLE_DISABLE_LEGAL_DOCUMENTS, enableDisableDocument);
}

export default contentManagementSaga;
