import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, Col, Container, Row, UncontrolledAlert } from "reactstrap";
import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Loader from "../../../components/Common/Loader";
import DefaultField from "../DefaultField";
import LanguageDropDown from "../LanguageDropDown";
import {
  addGlobalPaymentSystem,
  getGlobalPaymentSystemsById,
  resetGlobalPaymentSystemErrors,
} from "../../../store/GlobalPaymentManagement/actions";
import { getPaymentMethods } from "../../../store/paymentManagement/actions";
import { getCountries } from "../../../store/commonCountries/actions";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const EditPaymentSystem = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const warningAlertRef = useRef(null);
  const location = useLocation();
  const pageWrapRef = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [language, setLanguage] = useState("english");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [country, setCountry] = useState("");
  const { id, country_id } = useParams();
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const method = useSelector((state) => state?.PaymentMethods);

  const initialInput = [
    {
      input_type: "",
      input_label: "",
      input_infotext: "",
      input_placeholder: "",
      listing_order: "",
      options: [
        { input_type_value: "", listing_order: "" },
        { input_type_value: "", listing_order: "" },
      ],
    },
  ];

  const [inputs, setInputs] = useState(initialInput);

  const data = useSelector((state) => state?.GlobalPaymentSystems);

  useEffect(() => {
    setPaymentMethodId(data?.globalPaymentSystemData?.payment_method_id);
  }, [data?.globalPaymentSystemData?.payment_method_id]);

  useEffect(() => {
    const fetchedInputs = data?.globalPaymentSystemData?.inputs;

    if (Array.isArray(fetchedInputs)) {
      setInputs([...fetchedInputs]);
    } else {
      setInputs([...initialInput]);
    }
  }, [data?.globalPaymentSystemData?.inputs]);

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      {
        input_type: "",
        input_label: "",
        input_infotext: "",
        input_placeholder: "",
        listing_order: "",
        options: [
          { input_type_value: "", listing_order: "" },
          { input_type_value: "", listing_order: "" },
        ],
      },
    ]);
  };
  useEffect(() => {
    const invalidInput = inputs?.some((input) => {
      if (
        !input?.input_type ||
        !input?.input_label ||
        !input?.listing_order ||
        !language ||
        (["text", "file", "phone"].includes(input?.input_type) &&
          (!input?.input_type || !input?.input_label || !input?.listing_order))
      ) {
        return true;
      }
      if (!["text", "file", "phone"].includes(input?.input_type)) {
        return input?.options?.some(
          (option) => !option?.input_type_value || !option?.listing_order
        );
      }
      return false;
    });
    setDisabled(invalidInput);
  }, [inputs, language]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  };
  const handleRemoveInput = (index) => {
    if (index > 0) {
      const updatedInputs = [...inputs];
      updatedInputs.splice(index, 1);
      setInputs(updatedInputs);
    }
  };

  const handleAddOption = (index) => {
    const newInputs = [...inputs];
    newInputs[index]?.options.push({ input_type_value: "", listing_order: "" });
    setInputs(newInputs);
  };

  const handleRemoveOption = (inputIndex, optionIndex) => {
    const newInputs = [...inputs];
    newInputs[inputIndex]?.options.splice(optionIndex, 1);
    setInputs(newInputs);
  };
  useEffect(() => {
    if (language !== "") {
      dispatch(
        getGlobalPaymentSystemsById({
          country_id: country_id,
          payment_method_identifier: location?.state?.id,
          language,
        })
      );
    }
    return () => {
      dispatch(resetGlobalPaymentSystemErrors());
    };
  }, [language, paymentMethod]);
  useEffect(() => {
    dispatch(getPaymentMethods(language));
  }, [language]);

  const handleSubmit = (event, values) => {
    event.preventDefault();
    const finalInputs = inputs.map((input) => ({
      ...input,
      options: ["text", "file", "phone"].includes(input?.input_type)
        ? []
        : input.options,
    }));

    const finalPayload = {
      payment_method_id: paymentMethodId,
      language: language,
      country_id: country_id,
      inputs: finalInputs,
    };

    dispatch(
      addGlobalPaymentSystem({
        system: finalPayload,
        // callback: () => {
        //   // history.push("/payment-forms");
        // },
      })
    );
  };
  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error, data?.success]);

  const scrollToAlertMessage = () => {
    if (data?.error || data?.success) {
      let element = warningAlertRef?.current;
      let top = element?.offsetTop;
      let height = element?.getBoundingClientRect()?.height;
      pageWrapRef?.current?.scrollTo(0, top - height - 30);
    }
  };
  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        ref={pageWrapRef}
      >
        <BackButton
          label="Payment Forms"
          handleClick={() => history.push("/payment-forms")}
        />
        <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Edit Payment Forms" />
            {(data?.error || data?.success) && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color={
                    data?.error || data?.success === "Payment details not found"
                      ? "danger"
                      : "success"
                  }
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error || data?.success}
                </UncontrolledAlert>
              </div>
            )}
            <Col xl="12">
              <Card className="mb-0 p-4 my-4">
                <div className="text-muted">
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <div
                      className="table-responsive mt-4"
                      style={{ overflowX: "hidden" }}
                    >
                      <div
                        style={{
                          backgroundColor: "lightgray",
                        }}
                        className="row mb-4  justify-content-center align-items-center"
                      >
                        <LanguageDropDown
                          setLanguage={setLanguage}
                          initialInput={initialInput}
                          setInputs={setInputs}
                        />
                      </div>
                      <div className="col-md-4 row justify-content-center align-items-center"></div>

                      {inputs?.map((input, index) => {
                        return (
                          <DefaultField
                            inputs={inputs}
                            input={input}
                            index={index}
                            addNewField={handleAddInput}
                            removeField={handleRemoveInput}
                            handleAddOption={handleAddOption}
                            handleRemoveOption={handleRemoveOption}
                            handleInputChange={handleInputChange}
                            setInputs={setInputs}
                          />
                        );
                      })}

                      <div className="mt-4">
                        <button
                          type="submit"
                          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                          // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                          disabled={disabled}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default EditPaymentSystem;
