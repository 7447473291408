import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_ACCOUNT_VERIFICATION_STATUS,
  ADD_ACCOUNT_VERIFICATION_STATUS,
} from "./actionTypes";

import {
  getAccountVerificationStatusDataBegin,
  getAccountVerificationStatusDataFail,
  getAccountVerificationStatusDataSuccess,
  addAccountVerificationStatusBegin,
  addAccountVerificationStatusSuccess,
  addAccountVerificationStatusFail,
} from "./action";

import { get, update } from "../../helpers/api_helper";
import {
  ACCOUNT_VERIFICATION_STATUS,
  ACCOUNT_VERIFICATION_STATUS_BY_ID,
} from "../../helpers/url_helper";

function* getAccountVerificationStatusData({ payload }) {
  try {
    yield put(getAccountVerificationStatusDataBegin());
    const response = yield call(
      get,
      ACCOUNT_VERIFICATION_STATUS,
      payload.request
    );
    yield put(getAccountVerificationStatusDataSuccess(response.result));
  } catch (error) {
    yield put(getAccountVerificationStatusDataFail(error));
  }
}

function* addAccountVerificationStatus(payload) {
  try {
    yield put(addAccountVerificationStatusBegin());
    const response = yield call(
      update,
      ACCOUNT_VERIFICATION_STATUS_BY_ID.replace("{id}", payload.data)
    );

    yield put(addAccountVerificationStatusSuccess(response.result));
    payload.callback && payload.callback();
  } catch (error) {
    yield put(addAccountVerificationStatusFail(error));
  }
}

function* AccountVerificationStatusSaga() {
  yield takeLatest(
    GET_ACCOUNT_VERIFICATION_STATUS,
    getAccountVerificationStatusData
  );
  yield takeLatest(
    ADD_ACCOUNT_VERIFICATION_STATUS,
    addAccountVerificationStatus
  );
}

export default AccountVerificationStatusSaga;
