import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { Col, Card, Row, UncontrolledAlert } from "reactstrap";

import BackButton from "../../../../components/UI/BackButton";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import Button from "../../../../components/UI/Button";
import ConfirmationAlert from "../../../../components/UI/ConfirmationAlert";
import Loader from "../../../../components/Common/Loader";
import PreviewTemplate from "./PreviewTemplate";

import EmailTemplate from "./EmailTemplate";

import {
  editTemplate,
  updateTemplate,
} from "../../../../store/settings/templates/actions";

const EditTemplate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.templateSettings);

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [backToListing, setBackToListing] = useState(false);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  useEffect(() => {
    dispatch(editTemplate(match.id));
  }, [match.id]);

  useEffect(() => {
    setSubject(data?.templateDetails?.subject);
    setBody(data?.templateDetails?.body);
  }, [data]);

  useEffect(() => {
    backToListing && history.push("/settings-template");
  }, [backToListing]);

  function tog_center() {
    setOpenPreviewModal(!openPreviewModal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const redirectMethod = () => {
    setBackToListing(true);
  };

  const confirmBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
  };

  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };

  const okHandler = () => {
    promptMessage.type === "back" && redirectMethod();
  };

  const handleSubmit = () => {
    dispatch(
      updateTemplate({
        id: match?.id,
        data: { body: body, subject: subject },
        callback: () => redirectMethod(),
      })
    );
  };

  return (
    <div className="page-content">
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            return confirmBrowserBack();
          }
        }}
        when={formChanged}
      />
      <BackButton
        label={t("Sidebar.Item13")}
        handleClick={() => (formChanged ? confirmBack() : redirectMethod())}
      />
      <Breadcrumbs
        title="Tables"
        breadcrumbItem={t("settings.editPolicyTitle")}
      />
      {data?.error && (
        <div>
          <UncontrolledAlert
            color="danger"
            className="alert-dismissible fade show"
            role="alert"
          >
            {data?.error}
          </UncontrolledAlert>
        </div>
      )}
      <Col xl="12">
        <Card className="p-4">
          <div>
            <Row className="col-md-12">
              <div className="col-md-4">
                <label className="col-md-10 col-form-label">
                  {t("settings.editTemplateLabel")}
                </label>
                <div className="col-md-10">
                  <div className="text-muted template-title-wrap">
                    {data?.templateDetails?.title}
                  </div>
                </div>
              </div>
            </Row>
            {data?.templateDetails?.template_type === "email" && (
              <EmailTemplate
                subject={subject}
                setSubject={setSubject}
                setFormChanged={setFormChanged}
                body={body}
                setBody={setBody}
                setButtonDisabled={setButtonDisabled}
                previewButtonHandler={() => tog_center()}
              />
            )}
            {data?.templateDetails?.template_type === "text" && (
              <label className="col-form-label">
                {t("settings.smsTemplate")}
                <span className="mandatory">*</span>
              </label>
            )}
            {data?.templateDetails?.template_type === "push" && (
              <label className="col-form-label">
                {t("settings.pushTemplate")}
                <span className="mandatory">*</span>
              </label>
            )}
            {(data?.templateDetails?.template_type === "text" ||
              data?.templateDetails?.template_type === "push") && (
              <textarea
                id="settings_otp_textarea"
                name=""
                rows="10"
                cols=""
                onChange={(e) => {
                  setFormChanged(true);
                  setBody(e.target.value);
                  e.target.value
                    ? setButtonDisabled(false)
                    : setButtonDisabled(true);
                }}
                value={body}
              />
            )}
          </div>
          <div className="mt-4 editDocument">
            <Button
              buttonClass="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
              label={t("Common.update")}
              disableBtn={buttonDisabled}
              handleClick={() => handleSubmit()}
            />
          </div>
        </Card>
      </Col>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.editLoading && <Loader darkBg={true} />}

      <PreviewTemplate
        openPreviewModal={openPreviewModal}
        modalToggle={() => tog_center()}
        closeButtonClick={() => setOpenPreviewModal(false)}
        body={body}
      />
    </div>
  );
};

export default EditTemplate;
