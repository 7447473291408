import {
  ADD_GLOBAL_PAYMENT_SYSTEM,
  ADD_GLOBAL_PAYMENT_SYSTEM_BEGIN,
  ADD_GLOBAL_PAYMENT_SYSTEM_FAIL,
  ADD_GLOBAL_PAYMENT_SYSTEM_SUCCESS,
  DELETE_GLOBAL_PAYMENT_SYSTEM,
  DELETE_GLOBAL_PAYMENT_SYSTEM_BEGIN,
  DELETE_GLOBAL_PAYMENT_SYSTEM_FAIL,
  DELETE_GLOBAL_PAYMENT_SYSTEM_SUCCESS,
  GET_GLOBAL_PAYMENT_SYSTEMS,
  GET_GLOBAL_PAYMENT_SYSTEMS_BEGIN,
  GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID,
  GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_BEGIN,
  GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_FAIL,
  GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_SUCCESS,
  GET_GLOBAL_PAYMENT_SYSTEMS_FAIL,
  GET_GLOBAL_PAYMENT_SYSTEMS_SUCCESS,
  RESET_GLOBAL_PAYMENT_SYSTEM_ERRORS,
} from "./actionTypes";

export const getGlobalPaymentSystems = (request) => ({
  type: GET_GLOBAL_PAYMENT_SYSTEMS,
  request: request,
});

export const getGlobalPaymentSystemsBegin = () => ({
  type: GET_GLOBAL_PAYMENT_SYSTEMS_BEGIN,
});

export const getGlobalPaymentSystemsSuccess = (systems) => ({
  type: GET_GLOBAL_PAYMENT_SYSTEMS_SUCCESS,
  payload: systems,
});

export const getGlobalPaymentSystemsFail = (error) => ({
  type: GET_GLOBAL_PAYMENT_SYSTEMS_FAIL,
  payload: error,
});

export const addGlobalPaymentSystem = ({ system, callback }) => ({
  type: ADD_GLOBAL_PAYMENT_SYSTEM,
  system: system,
  callback: callback,
});

export const addGlobalPaymentSystemBegin = () => ({
  type: ADD_GLOBAL_PAYMENT_SYSTEM_BEGIN,
});

export const addGlobalPaymentSystemSuccess = (system) => ({
  type: ADD_GLOBAL_PAYMENT_SYSTEM_SUCCESS,
  payload: system,
});

export const addGlobalPaymentSystemFail = (error) => ({
  type: ADD_GLOBAL_PAYMENT_SYSTEM_FAIL,
  payload: error,
});

export const getGlobalPaymentSystemsById = ({
  country_id,
  payment_method_identifier,
  language,
}) => ({
  type: GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID,
  country_id: country_id,
  payment_method_identifier: payment_method_identifier,
  language: language,
});

export const getGlobalPaymentSystemsByIdBegin = () => ({
  type: GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_BEGIN,
});

export const getGlobalPaymentSystemsByIdSuccess = (system) => ({
  type: GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_SUCCESS,
  payload: system,
});

export const getGlobalPaymentSystemsByIdFail = (error) => ({
  type: GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_FAIL,
  payload: error,
});

export const deleteGlobalPaymentSystem = ({
  payment_method_identifier,
  country_id,
  callback,
}) => ({
  type: DELETE_GLOBAL_PAYMENT_SYSTEM,
  payment_method_identifier: payment_method_identifier,
  country_id: country_id,
  callback: callback,
});

export const deleteGlobalPaymentSystemBegin = () => ({
  type: DELETE_GLOBAL_PAYMENT_SYSTEM_BEGIN,
});

export const deleteGlobalPaymentSystemSuccess = (system) => ({
  type: DELETE_GLOBAL_PAYMENT_SYSTEM_SUCCESS,
  payload: system,
});

export const deleteGlobalPaymentSystemFail = (error) => ({
  type: DELETE_GLOBAL_PAYMENT_SYSTEM_FAIL,
  payload: error,
});

export const resetGlobalPaymentSystemErrors = () => ({
  type: RESET_GLOBAL_PAYMENT_SYSTEM_ERRORS,
});
