import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Input,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../../assets/images/users/user.svg";

import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import Block_icon from "../../../assets/icons/ebud-icons/Block.svg";
import UnBlock_icon from "../../../assets/icons/ebud-icons/Unblock.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";
import Camera_icon from "../../../assets/icons/ebud-icons/Camera.svg";
import {
  getHuddlesById,
  deleteHuddle,
  blockHuddle,
  editHuddle,
  resetHuddlesErrors,
} from "../../../store/huddles/actions";
import BackButton from "../../../components/UI/BackButton";
import AvForm from "availity-reactstrap-validation/lib/AvForm";

import Crown from "../../../assets/icons/premium.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { editPodium, getPodiumsById } from "../../../store/podiums/actions";
import { convertToUAETime } from "../../../helpers/utils";
import defaultProfile from "../../../assets/images/users/podium-default.svg";

const PodiumDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams("");
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [modalShow, setmodalShow] = useState(false);
  const [about, setAbout] = useState("");
  const [formChanged, setFormChanged] = useState(false);
  const [name, setName] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [imageFile, setImageFile] = useState("");
  const data = useSelector((state) => state.Podiums);
  const warningAlertRef = useRef(null);
  console.log("podium details", data);

  const handleBackClick = () => {
    const query = new URLSearchParams(location.search);
    const returnUrl = query.get("return") || "/podiums";
    const [url, search] = returnUrl.split("?");
    history.push({
      pathname: url,
      search: search,
      state: { from: "podiumDetails" },
    });
  };
  console.log("params", params);
  useEffect(() => {
    params?.id && dispatch(getPodiumsById({ id: params?.id }));
  }, [params?.id]);
  console.log("1212", data);

  useEffect(() => {
    setName(data?.podiumData?.name);
    setAbout(data?.podiumData?.about);
    setProfilePic(data?.podiumData?.group_photo || Avatar);
  }, [data?.podiumData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a new FormData instance
    const formData = new FormData();

    // Append form data
    formData.append("name", name);
    formData.append("about", about);

    // If there is an image file, append it as well
    if (imageFile) {
      formData.append("image", imageFile);
    }

    // If there is an ID, you can append it too
    if (params?.id) {
      formData.append("id", params.id);
    }

    // Dispatch the action with formData instead of a plain object
    dispatch(editPodium(formData, params?.id, changeEditMode));
  };
  const changeEditMode = () => {
    dispatch(getPodiumsById({ id: params?.id }));
    setEditMode(false);
  };
  const readFile = (file) => {
    setImageFile(file?.target?.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      setProfilePic(reader.result);
    };
    reader.readAsDataURL(file?.target?.files[0]);
  };

  return (
    <div
      className="page-content"
      c
      data-testid="component-usermgmt"
      id="userMgmtList"
    >
      <BackButton
        label={"Podiums"}
        handleClick={handleBackClick}
        data-tesid="component-userListBack"
      />
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        {(data?.error || data?.success) && (
          <div ref={warningAlertRef}>
            <UncontrolledAlert
              color={data?.error ? "danger" : "success"}
              className="alert-dismissible fade show"
              role="alert"
            >
              {data?.error || data?.success}
            </UncontrolledAlert>
          </div>
        )}
        <Row>
          {/* <h2 title={data?.huddleData?.name} className="huddle-user-name ">
        {" "}
        {data?.huddleData?.name}
      </h2> */}
          <div className="col-lg-12 col-md-12 ">
            <Card className="appSettingCard mt-3">
              <CardBody>
                {editMode ? (
                  <div className="huddle-gen-info my-5 py-5 mx-5">
                    <div className="huddle-gen-img edit-admin-profilePic-wrapper ">
                      <label>
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            readFile(e);
                            setFormChanged(true);
                          }}
                        />
                        <img
                          src={profilePic}
                          alt=""
                          style={{ borderRadius: "8%" }}
                          className="avatar-xl  editAdmin-profile-pic"
                        />
                        <img
                          src={Camera_icon}
                          alt="Camera"
                          className="edit-admin-role-camera-icon editAdmin-profile-pic"
                        />
                      </label>
                    </div>
                    <form
                      className="col-md-6"
                      onSubmit={(e, v) => {
                        handleSubmit(e, v);
                      }}
                    >
                      <div className="col-md-12">
                        <div className="col-md-12">
                          <p
                            htmlFor="example-date-input"
                            className="col-md-10 col-form-label  "
                          >
                            Podium Name
                            <span className="mandatory">*</span>
                          </p>
                          <div className="col-md-10 dobWrapper">
                            <Input
                              name="name"
                              type="text"
                              className="form-control"
                              id="example-date-input"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                                setFormChanged(true);
                              }}
                            />
                            <i
                              className="fa fa-calendar"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <p
                            htmlFor="example-date-input"
                            className="col-md-10 col-form-label"
                          >
                            Bio
                            <span className="mandatory">*</span>
                          </p>
                          <div className="col-md-10 dobWrapper">
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="8"
                              value={about}
                              onChange={(e) => {
                                setAbout(e.target.value);
                                setFormChanged(true);
                              }}
                            ></textarea>

                            <i
                              className="fa fa-calendar"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </div>
                      <div className="p-4 text-center">
                        <button
                          type="submit"
                          //   disabled={disableSubmit}
                          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton "
                        >
                          Submit
                        </button>
                        <button
                          type="submit"
                          // disabled={isButtonDisabled}
                          onClick={() => setEditMode(false)}
                          className="btn btn-light cust-saveButton mx-4"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="huddle-gen-info  py-5 d-flex ">
                    <div className="huddle-gen-img huddleDetails_profilePic edit-admin-profilePic-wrapper ">
                      <img
                        src={
                          data?.poidumData?.profile_pic
                            ? data?.poidumData?.profile_pic
                            : defaultProfile
                        }
                        className="avatar-xl mr-5 editAdmin-profile-pic cursor-pointer"
                        style={{ borderRadius: "8%" }}
                        onClick={() => setmodalShow(true)}
                      />
                      {/* {data?.huddleData?.is_premium ? (
                        <img src={Crown} className="huddleDetails_crown" />
                      ) : null} */}
                    </div>
                    <div>
                      <h5 className="huddle-titles mb-4 ">
                        {data?.podiumData?.name}
                      </h5>
                      <h5 className="huddle-gen-subtitle huddle-titles   mb-5 text-muted ">
                        {data?.podiumData?.about}
                      </h5>
                    </div>
                  </div>
                )}
                <div className="userDetailActions">
                  {/* {editMode ? null : (
                    <button
                      className="action-buttons"
                      title="Edit"
                      onClick={() => setEditMode(true)}
                      //   disabled={
                      //     !isEditable ||
                      //     data?.huddleData?.status == "admin_deleted"
                      //   }
                    >
                      <img
                        src={Edit_icon}
                        alt="Edit"
                        // style={{
                        //   opacity:
                        //     !isEditable ||
                        //     data?.huddleData?.status == "admin_deleted"
                        //       ? "50%"
                        //       : "",
                        //   cursor:
                        //     !isEditable ||
                        //     data?.huddleData?.status == "admin_deleted"
                        //       ? "not-allowed"
                        //       : "",
                        // }}
                      />
                      <span>EDIT</span>
                    </button>
                  )} */}
                  <button
                    className="action-buttons"
                    // title={
                    //   data?.huddleData?.status == "blocked" ||
                    //   data?.huddleData?.status == "admin_blocked"
                    //     ? "Unblock"
                    //     : "Block"
                    // }
                    // onClick={() => blockPromptHandler()}
                    // disabled={
                    //   !isBlockable ||
                    //   data?.huddleData?.status == "admin_deleted"
                    // }
                  >
                    {/* <img
                      src={
                        data?.huddleData?.status == "blocked" ||
                        data?.huddleData?.status == "admin_blocked"
                          ? UnBlock_icon
                          : Block_icon
                      }
                      alt={
                        data?.huddleData?.status == "blocked" ||
                        data?.huddleData?.status == "admin_blocked"
                          ? "Unblock"
                          : "Block"
                      }
                      style={{
                        opacity:
                          !isBlockable ||
                          data?.huddleData?.status == "admin_deleted"
                            ? "50%"
                            : "",
                      }}
                    /> */}
                    {/* <span>
                      {data?.huddleData?.status == "blocked" ||
                      data?.huddleData?.status == "admin_blocked"
                        ? "UNBLOCK"
                        : "BLOCK"}
                    </span> */}
                  </button>

                  {/* <button
                    className="action-buttons"
                    title="Delete"
                    onClick={() => deletePromptHandler()}
                    disabled={
                      !isDeleteable ||
                      data?.huddleData?.status == "admin_deleted"
                    }
                  >
                    <img
                      src={Delete_icon}
                      alt="Delete"
                      style={{
                        opacity:
                          !isDeleteable ||
                          data?.huddleData?.status == "admin_deleted"
                            ? "50%"
                            : "",
                      }}
                    />
                    <span className="color-red">DELETE</span>
                  </button> */}
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-lg-7 col-md-12 ">
            <Card className="appSettingCard mt-3">
              <CardBody>
                <table>
                  <tr className="huddle-rows">
                    <td className="huddle-columns ">
                      <h5 className="huddle-user-value">Manager</h5>
                    </td>
                    <td className="huddle-columns ">
                      <p className="huddle-user-value">
                        {" "}
                        {data?.podiumData?.manager_name}
                      </p>
                    </td>
                  </tr>
                  {/* <tr className="huddle-rows">
                    <td className="huddle-columns ">
                      <h5 className="huddle-user-value">Admin</h5>
                    </td>
                    <td className="huddle-columns ">
                      <p className="huddle-user-value">
                        {data?.huddleData?.participants?.length &&
                          data?.huddleData?.participants?.map(
                            (participant, i) =>
                              participant?.role == "admin" ? (
                                <>
                                  <span>
                                    {`${participant?.member_name}${
                                      i <
                                      data.huddleData.participants.length - 1
                                        ? ", "
                                        : ""
                                    } `}{" "}
                                  </span>
                                </>
                              ) : null
                          )}
                      </p>
                    </td>
                  </tr> */}
                  <tr className="huddle-rows">
                    <td className="huddle-columns ">
                      <h5 className="huddle-user-value">Created on</h5>
                    </td>
                    <td className="huddle-columns ">
                      <p className="huddle-user-value">
                        {" "}
                        {data?.podiumData?.created &&
                          convertToUAETime(
                            data?.podiumData?.created,
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                      </p>
                    </td>
                  </tr>
                  <tr className="huddle-rows">
                    <td className="huddle-columns ">
                      <h5 className="huddle-user-value">Type</h5>
                    </td>
                    <td className="huddle-columns ">
                      <p className="huddle-user-value">
                        <span
                        // className={`badge ${
                        //   data?.huddleData?.status === "blocked" ||
                        //   data?.huddleData?.status === "admin_blocked"
                        //     ? "bg-soft-orange"
                        //     : data?.huddleData?.status === "active"
                        //     ? "bg-soft-green"
                        //     : data?.huddleData?.status === "admin_deleted" ||
                        //       data?.huddleData?.status === "deleted"
                        //     ? "bg-soft-red"
                        //     : ""
                        // } font-size-13`}
                        >
                          {data?.podiumData?.type}
                        </span>
                      </p>
                    </td>
                  </tr>
                  {/* <tr className="huddle-rows">
                    <td className="huddle-columns ">
                      <h5 className="huddle-user-value">Type</h5>
                    </td>
                    <td className="huddle-columns ">
                      <p className="huddle-user-value ">
                        {data?.huddleData?.private
                          ? "Private"
                          : `Public / ${
                              data?.huddleData?.request_to_join
                                ? "Request to Join"
                                : "Open  For All"
                            }`}
                      </p>
                    </td>
                  </tr> */}
                  {/* <tr className="huddle-rows">
                    <td className="huddle-columns ">
                      <h5 className="huddle-user-value">Reported messages</h5>
                    </td>
                    <td className="huddle-columns ">
                      {data?.huddleData?.report_count > 0 ? (
                        <a
                          href={`/huddle-reported-messages/${data?.huddleData?.id}`}
                          className="huddle-user-value"
                          title="Click to see reported messages"
                        >
                          {data?.huddleData?.report_count}
                        </a>
                      ) : (
                        <p className="huddle-user-value">
                          {data?.huddleData?.report_count}
                        </p>
                      )}
                    </td>
                  </tr> */}
                </table>
              </CardBody>
            </Card>
          </div>

          {/* <div className="col-lg-5 col-md-12 ">
            <Card className=" mt-3 participants-container">
              <CardBody className="px-0">
                <h5 className=" mb-2 huddle-columns">
                  No of participants ({data?.huddleData?.participants?.length})
                </h5>
                <table>
                  {data?.huddleData?.participants?.length
                    ? data?.huddleData?.participants?.map((participant) => (
                        <tr className="huddle-rows-participant ">
                          <td className="huddle-participant-columns ">
                            <div className="d-flex ">
                              <div class="huddle-user-avatar mx-3">
                                {participant?.member_name
                                  ?.slice(0, 2)
                                  ?.toUpperCase()}
                              </div>
                              <a
                                onClick={(e) =>
                                  profilePicClick(e, participant?.member_id)
                                }
                                style={{ cursor: "pointer" }}
                                className="participant-name"
                              >
                                {participant?.member_name}
                              </a>
                            </div>
                          </td>
                          <td className="huddle-participant-columns  ">
                            <div
                              class="badge huddle-user-badge"
                              data-tag="allowRowEvents"
                            >
                              {participant?.role}
                            </div>
                          </td>
                        </tr>
                      ))
                    : null}
                </table>
              </CardBody>
            </Card>
          </div> */}
        </Row>
      </Container>
      {/* <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      /> */}
      {/* <ImageModal
        modal_center={modalShow}
        setmodal_center={setmodalShow}
        path={profilePic}
        toggle={toggle}
      /> */}
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default PodiumDetails;
