import React from "react";
import { useDropzone } from "react-dropzone";

const Upload = ({ label, className, setImportFile }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: ".csv",
  });

  acceptedFiles.map((file) => setImportFile(file));
  return (
    <div {...getRootProps({ className: "upload-wrapper" })} data-testid="fileUpload-wrap">
      <input {...getInputProps()} data-testid="fileUpload-inputField"/>
      <button className={className} data-testid="fileUpload-button">{label}</button>
    </div>
  );
};

export default Upload;
