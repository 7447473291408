export const GET_POSTAT_REPORTED_POSTS = "GET_POSTAT_REPORTED_POSTS";
export const GET_POSTAT_REPORTED_POSTS_BEGIN =
  "GET_POSTAT_REPORTED_POSTS_BEGIN";
export const GET_POSTAT_REPORTED_POSTS_SUCCESS =
  "GET_POSTAT_REPORTED_POSTS_SUCCESS";
export const GET_POSTAT_REPORTED_POSTS_FAIL = "GET_POSTAT_REPORTED_POSTS_FAIL";
export const RESET_POSTAT_REPORTED_POSTS_ERRORS =
  "RESET_POSTAT_REPORTED_POSTS_ERRORS";

export const GET_POST_REPORTS_BY_ID = "GET_POST_REPORTS_BY_ID";
export const GET_POST_REPORTS_BY_ID_BEGIN = "GET_POST_REPORTS_BY_ID_BEGIN";
export const GET_POST_REPORTS_BY_ID_SUCCESS = "GET_POST_REPORTS_BY_ID_SUCCESS";
export const GET_POST_REPORTS_BY_ID_FAIL = "GET_POST_REPORTS_BY_ID_FAIL";

export const DELETE_POSTAT = "DELETE_POSTAT";
export const DELETE_POSTAT_BEGIN = "DELETE_POSTAT_BEGIN";
export const DELETE_POSTAT_SUCCESS = "DELETE_POSTAT_SUCCESS";
export const DELETE_POSTAT_FAIL = "DELETE_POSTAT_FAIL";

export const GET_POSTAT_REPORTED_COMMENTS = "GET_POSTAT_REPORTED_COMMENTS";
export const GET_POSTAT_REPORTED_COMMENTS_BEGIN =
  "GET_POSTAT_REPORTED_COMMENTS_BEGIN";
export const GET_POSTAT_REPORTED_COMMENTS_SUCCESS =
  "GET_POSTAT_REPORTED_COMMENTS_SUCCESS";
export const GET_POSTAT_REPORTED_COMMENTS_FAIL =
  "GET_POSTAT_REPORTED_COMMENTS_FAIL";
export const RESET_POSTAT_REPORTED_COMMENTS_ERRORS =
  "RESET_POSTAT_REPORTED_COMMENTS_ERRORS";

export const DELETE_POSTAT_REPORTED_COMMENT = "DELETE_POSTAT_REPORTED_COMMENT";
export const DELETE_POSTAT_REPORTED_COMMENT_BEGIN =
  "DELETE_POSTAT_REPORTED_COMMENT_BEGIN";
export const DELETE_POSTAT_REPORTED_COMMENT_SUCCESS =
  "DELETE_POSTAT_REPORTED_COMMENT_SUCCESS";
export const DELETE_POSTAT_REPORTED_COMMENT_FAIL =
  "DELETE_POSTAT_REPORTED_COMMENT_FAIL";

export const GET_COMMENT_REPORTS_BY_ID = "GET_COMMENT_REPORTS_BY_ID";
export const GET_COMMENT_REPORTS_BY_ID_BEGIN =
  "GET_COMMENT_REPORTS_BY_ID_BEGIN";
export const GET_COMMENT_REPORTS_BY_ID_SUCCESS =
  "GET_COMMENT_REPORTS_BY_ID_SUCCESS";
export const GET_COMMENT_REPORTS_BY_ID_FAIL = "GET_COMMENT_REPORTS_BY_ID_FAIL";

export const GET_POSTAT_MUSIC = "GET_POSTAT_MUSIC";
export const GET_POSTAT_MUSIC_BEGIN = "GET_POSTAT_MUSIC_BEGIN";
export const GET_POSTAT_MUSIC_SUCCESS = "GET_POSTAT_MUSIC_SUCCESS";
export const GET_POSTAT_MUSIC_FAIL = "GET_POSTAT_MUSIC_FAIL";

export const ADD_POSTAT_MUSIC = "ADD_POSTAT_MUSIC";
export const ADD_POSTAT_MUSIC_BEGIN = "ADD_POSTAT_MUSIC_BEGIN";
export const ADD_POSTAT_MUSIC_SUCCESS = "ADD_POSTAT_MUSIC_SUCCESS";
export const ADD_POSTAT_MUSIC_FAIL = "ADD_POSTAT_MUSIC_FAIL";
export const DELETE_POSTAT_MUSIC = "DELETE_POSTAT_MUSIC";
export const DELETE_POSTAT_MUSIC_BEGIN = "DELETE_POSTAT_MUSIC_BEGIN";
export const DELETE_POSTAT_MUSIC_SUCCESS = "DELETE_POSTAT_MUSIC_SUCCESS";
export const DELETE_POSTAT_MUSIC_FAIL = "DELETE_POSTAT_MUSIC_FAIL";

export const GET_POSTAT_MUSIC_BY_ID = "GET_POSTAT_MUSIC_BY_ID";
export const GET_POSTAT_MUSIC_BY_ID_BEGIN = "GET_POSTAT_MUSIC_BY_ID_BEGIN";
export const GET_POSTAT_MUSIC_BY_ID_SUCCESS = "GET_POSTAT_MUSIC_BY_ID_SUCCESS";
export const GET_POSTAT_MUSIC_BY_ID_FAIL = "GET_POSTAT_MUSIC_BY_ID_FAIL";

export const EDIT_POSTAT_MUSIC = "EDIT_POSTAT_MUSIC";
export const EDIT_POSTAT_MUSIC_BEGIN = "EDIT_POSTAT_MUSIC_BEGIN";
export const EDIT_POSTAT_MUSIC_SUCCESS = "EDIT_POSTAT_MUSIC_SUCCESS";
export const EDIT_POSTAT_MUSIC_FAIL = "EDIT_POSTAT_MUSIC_FAIL";
export const RESET_MUSIC_ERRORS = "RESET_MUSIC_ERRORS";

export const GET_ALL_POSTAT_POSTS = "GET_ALL_POSTAT_POSTS";
export const GET_ALL_POSTAT_POSTS_BEGIN = "GET_ALL_POSTAT_POSTS_BEGIN";
export const GET_ALL_POSTAT_POSTS_SUCCESS = "GET_ALL_POSTAT_POSTS_SUCCESS";
export const GET_ALL_POSTAT_POSTS_FAIL = "GET_ALL_POSTAT_POSTS_FAIL";

export const IGNORE_POSTAT_REPORTED_POSTAT = "IGNORE_POSTAT_REPORTED_POSTAT";
export const IGNORE_POSTAT_REPORTED_POSTAT_BEGIN =
  "IGNORE_POSTAT_REPORTED_POSTAT_BEGIN";
export const IGNORE_POSTAT_REPORTED_POSTAT_SUCCESS =
  "IGNORE_POSTAT_REPORTED_POSTAT_SUCCESS";
export const IGNORE_POSTAT_REPORTED_POSTAT_FAIL =
  "IGNORE_POSTAT_REPORTED_POSTAT_FAIL";

export const IGNORE_POSTAT_REPORTED_COMMENTS =
  "IGNORE_POSTAT_REPORTED_COMMENTS";
export const IGNORE_POSTAT_REPORTED_COMMENTS_BEGIN =
  "IGNORE_POSTAT_REPORTED_COMMENTS_BEGIN";
export const IGNORE_POSTAT_REPORTED_COMMENTS_SUCCESS =
  "IGNORE_POSTAT_REPORTED_COMMENTS_SUCCESS";
export const IGNORE_POSTAT_REPORTED_COMMENTS_FAIL =
  "IGNORE_POSTAT_REPORTED_COMMENTS_FAIL";
