export const GET_FLASH_CATEGORIES = "GET_FLASH_CATEGORIES";
export const GET_FLASH_CATEGORIES_BEGIN = "GET_FLASH_CATEGORIES_BEGIN";
export const GET_FLASH_CATEGORIES_SUCCESS = "GET_FLASH_CATEGORIES_SUCCESS";
export const GET_FLASH_CATEGORIES_FAIL = "GET_FLASH_CATEGORIES_FAIL";

export const GET_FLASH_CATEGORIES_BY_ID = "GET_FLASH_CATEGORIES_BY_ID";
export const GET_FLASH_CATEGORIES_BY_ID_BEGIN =
  "GET_FLASH_CATEGORIES_BY_ID_BEGIN";
export const GET_FLASH_CATEGORIES_BY_ID_SUCCESS =
  "GET_FLASH_CATEGORIES_BY_ID_SUCCESS";
export const GET_FLASH_CATEGORIES_BY_ID_FAIL =
  "GET_FLASH_CATEGORIES_BY_ID_FAIL";

export const ADD_FLASH_CATEGORIES = "ADD_FLASH_CATEGORIES";
export const ADD_FLASH_CATEGORIES_BEGIN = "ADD_FLASH_CATEGORIES_BEGIN";
export const ADD_FLASH_CATEGORIES_SUCCESS = "ADD_FLASH_CATEGORIES_SUCCESS";
export const ADD_FLASH_CATEGORIES_FAIL = "ADD_FLASH_CATEGORIES_FAIL";

export const EDIT_FLASH_CATEGORIES = "EDIT_FLASH_CATEGORIES";
export const EDIT_FLASH_CATEGORIES_BEGIN = "EDIT_FLASH_CATEGORIES_BEGIN";
export const EDIT_FLASH_CATEGORIES_SUCCESS = "EDIT_FLASH_CATEGORIES_SUCCESS";
export const EDIT_FLASH_CATEGORIES_FAIL = "EDIT_FLASH_CATEGORIES_FAIL";

export const DELETE_FLASH_CATEGORIES = "DELETE_FLASH_CATEGORIES";
export const DELETE_FLASH_CATEGORIES_BEGIN = "DELETE_FLASH_CATEGORIES_BEGIN";
export const DELETE_FLASH_CATEGORIES_SUCCESS =
  "DELETE_FLASH_CATEGORIES_SUCCESS";
export const DELETE_FLASH_CATEGORIES_FAIL = "DELETE_FLASH_CATEGORIES_FAIL";

export const RESET_FLASH_CATEGORY_ERRORS = "RESET_FLASH_CATEGORY_ERRORS";

// User Specific Flashes
export const GET_FLASHES = "GET_FLASHES";
export const GET_FLASHES_STAT = "GET_FLASHES_STAT";
export const GET_FLASHES_BEGIN = "GET_FLASHES_BEGIN";
export const GET_FLASHES_SUCCESS = "GET_FLASHES_SUCCESS";
export const GET_FLASHES_FAIL = "GET_FLASHES_FAIL";

export const EDIT_FLASHES = "EDIT_FLASHES";
export const EDIT_FLASHES_BEGIN = "EDIT_FLASHES_BEGIN";
export const EDIT_FLASHES_SUCCESS = "EDIT_FLASHES_SUCCESS";
export const EDIT_FLASHES_FAIL = "EDIT_FLASHES_FAIL";

export const DELETE_FLASHES = "DELETE_FLASHES";
export const DELETE_FLASHES_BEGIN = "DELETE_FLASHES_BEGIN";
export const DELETE_FLASHES_SUCCESS = "DELETE_FLASHES_SUCCESS";
export const DELETE_FLASHES_FAIL = "DELETE_FLASHES_FAIL";

// for whole flashes listing in the app
export const GET_ALL_FLASH = "GET_ALL_FLASH";
export const GET_ALL_FLASH_BEGIN = "GET_ALL_FLASH_BEGIN";
export const GET_ALL_FLASH_SUCCESS = "GET_ALL_FLASH_SUCCESS";
export const GET_ALL_FLASH_FAIL = "GET_ALL_FLASH_FAIL";

export const GET_REPORTED_FLASH = "GET_REPORTED_FLASH";
export const GET_REPORTED_FLASH_BEGIN = "GET_REPORTED_FLASH_BEGIN";
export const GET_REPORTED_FLASH_SUCCESS = "GET_REPORTED_FLASH_SUCCESS";
export const GET_REPORTED_FLASH_FAIL = "GET_REPORTED_FLASH_FAIL";
export const GET_FLASH_REPORTS = "GET_FLASH_REPORTS";
export const GET_FLASH_REPORTS_BEGIN = "GET_FLASH_REPORTS_BEGIN";
export const GET_FLASH_REPORTS_SUCCESS = "GET_FLASH_REPORTS_SUCCESS";
export const GET_FLASH_REPORTS_FAIL = "GET_FLASH_REPORTS_FAIL";

export const GET_FLASH_REPORTED_COMMENT = "GET_FLASH_REPORTED_COMMENT";
export const GET_FLASH_REPORTED_COMMENT_BEGIN =
  "GET_FLASH_REPORTED_COMMENT_BEGIN";
export const GET_FLASH_REPORTED_COMMENT_SUCCESS =
  "GET_FLASH_REPORTED_COMMENT_SUCCESS";
export const GET_FLASH_REPORTED_COMMENT_FAIL =
  "GET_FLASH_REPORTED_COMMENT_FAIL";
export const DELETE_FLASH_REPORTED_COMMENT = "DELETE_FLASH_REPORTED_COMMENT";
export const DELETE_FLASH_REPORTED_COMMENT_BEGIN =
  "DELETE_FLASH_REPORTED_COMMENT_BEGIN";
export const DELETE_FLASH_REPORTED_COMMENT_SUCCESS =
  "DELETE_FLASH_REPORTED_COMMENT_SUCCESS";
export const DELETE_FLASH_REPORTED_COMMENT_FAIL =
  "DELETE_FLASH_REPORTED_COMMENT_FAIL";

export const GET_FLASH_COMMENT_REPORTS = "GET_FLASH_COMMENT_REPORTS";
export const GET_FLASH_COMMENT_REPORTS_BEGIN =
  "GET_FLASH_COMMENT_REPORTS_BEGIN";
export const GET_FLASH_COMMENT_REPORTS_SUCCESS =
  "GET_FLASH_COMMENT_REPORTS_SUCCESS";
export const GET_FLASH_COMMENT_REPORTS_FAIL = "GET_FLASH_COMMENT_REPORTS_FAIL";

export const GET_FLASH_BLOCKED_USERS = "GET_FLASH_BLOCKED_USERS";
export const GET_FLASH_BLOCKED_USERS_BEGIN = "GET_FLASH_BLOCKED_USERS_BEGIN";
export const GET_FLASH_BLOCKED_USERS_SUCCESS =
  "GET_FLASH_BLOCKED_USERS_SUCCESS";
export const GET_FLASH_BLOCKED_USERS_FAIL = "GET_FLASH_BLOCKED_USERS_FAIL";

export const BLOCK_OR_UNBLOCK_USER_FROM_FLASH =
  "BLOCK_OR_UNBLOCK_USER_FROM_FLASH";
export const BLOCK_OR_UNBLOCK_USER_FROM_FLASH_BEGIN =
  "BLOCK_OR_UNBLOCK_USER_FROM_FLASH_BEGIN";
export const BLOCK_OR_UNBLOCK_USER_FROM_FLASH_SUCCESS =
  "BLOCK_OR_UNBLOCK_USER_FROM_FLASH_SUCCESS";
export const BLOCK_OR_UNBLOCK_USER_FROM_FLASH_FAIL =
  "BLOCK_OR_UNBLOCK_USER_FROM_FLASH_FAIL";
