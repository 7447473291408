import {
  GET_ROLE_DATA,
  GET_ROLE_DATA_BEGIN,
  GET_ROLE_DATA_SUCCESS,
  GET_ROLE_DATA_FAIL,
  GET_ROLE_PERMISSIONS,
  GET_ROLE_PERMISSIONS_BEGIN,
  GET_ROLE_PERMISSIONS_SUCCESS,
  GET_ROLE_PERMISSIONS_FAIL,
  GET_PERMISSIONS_MASTER_DATA,
  GET_PERMISSIONS_MASTER_DATA_BEGIN,
  GET_PERMISSIONS_MASTER_DATA_SUCCESS,
  GET_PERMISSIONS_MASTER_DATA_FAIL,
  GET_ADMINS_ROLE,
  GET_ADMINS_ROLE_BEGIN,
  GET_ADMINS_ROLE_SUCCESS,
  GET_ADMINS_ROLE_FAIL,
  DELETE_ROLE,
  DELETE_ROLE_BEGIN,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  UPDATE_ROLE_PERMISSIONS,
  UPDATE_ROLE_PERMISSIONS_BEGIN,
  UPDATE_ROLE_PERMISSIONS_SUCCESS,
  UPDATE_ROLE_PERMISSIONS_FAIL,
  ADD_ROLE,
  ADD_ROLE_BEGIN,
  ADD_ROLE_FAIL,
  ADD_ROLE_SUCCESS,
  STORE_ROLE_PERMISSIONS_REQUEST,
  RESET_MESSAGES,
  RESET_ROLE_PERMISSIONS,
  SET_DELETE_ERROR_MESSAGE,
  SET_VIEW_ERROR_MESSAGE,
} from "./actionTypes";

export const getRolesData = (request) => ({
  type: GET_ROLE_DATA,
  payload: { request },
});

export const getRolesDataBegin = () => ({
  type: GET_ROLE_DATA_BEGIN,
});

export const getRolesDataSuccess = (roleData) => ({
  type: GET_ROLE_DATA_SUCCESS,
  payload: roleData,
});

export const getRolesDataFail = (error) => ({
  type: GET_ROLE_DATA_FAIL,
  payload: error,
});
/* ------------------------------------------- */
export const getRolePermission = (id) => {
  return {
    type: GET_ROLE_PERMISSIONS,
    payload: id,
  };
};

export const getRolePermissionBegin = () => ({
  type: GET_ROLE_PERMISSIONS_BEGIN,
});

export const getRolePermissionSuccess = (rolePermissions) => ({
  type: GET_ROLE_PERMISSIONS_SUCCESS,
  payload: rolePermissions,
});

export const getRolePermissionFail = (error) => ({
  type: GET_ROLE_PERMISSIONS_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const getAdminsRole = (request) => {      //Find admins with a particular role
  return {
    type: GET_ADMINS_ROLE,
    payload: { request }
  };
};

export const getAdminsRoleBegin = () => ({
  type: GET_ADMINS_ROLE_BEGIN,
});

export const getAdminsRoleSuccess = (admins) => ({
  type: GET_ADMINS_ROLE_SUCCESS,
  payload: admins,
});

export const getAdminsRoleFail = (error) => ({
  type: GET_ADMINS_ROLE_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const getPermissionsMasterData = () => ({
  type: GET_PERMISSIONS_MASTER_DATA,
});

export const getPermissionsMasterDataBegin = () => ({
  type: GET_PERMISSIONS_MASTER_DATA_BEGIN,
});

export const getPermissionsMasterDataSuccess = (permissionsData) => ({
  type: GET_PERMISSIONS_MASTER_DATA_SUCCESS,
  payload: permissionsData,
});

export const getPermissionsMasterDataFail = (error) => ({
  type: GET_PERMISSIONS_MASTER_DATA_FAIL,
  payload: error,
});

/* ------------------------------------------- */
export const deleteRoleData = ({ data, callback }) => ({
  type: DELETE_ROLE,
  data: data,
  callback: callback,
});

export const deleteRoleDataBegin = () => ({
  type: DELETE_ROLE_BEGIN,
});

export const deleteRoleDataSuccess = (role) => ({
  type: DELETE_ROLE_SUCCESS,
  payload: role,
});

export const deleteRoleDataFail = (error) => ({
  type: DELETE_ROLE_FAIL,
  payload: error,
});
/* ------------------------------------------- */
export const updateRolePermissions = ({ data, callback }) => ({
  type: UPDATE_ROLE_PERMISSIONS,
  id: data?.id,
  params: { ...data },
  callback: callback,
});

export const updateRolePermissionsBegin = () => ({
  type: UPDATE_ROLE_PERMISSIONS_BEGIN,
});

export const updateRolePermissionsSuccess = (role) => ({
  type: UPDATE_ROLE_PERMISSIONS_SUCCESS,
  payload: role,
});

export const updateRolePermissionsFail = (error) => ({
  type: UPDATE_ROLE_PERMISSIONS_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const addRole = ({ data, callback }) => ({
  type: ADD_ROLE,
  data: data,
  callback: callback,
});

export const addRoleBegin = () => ({
  type: ADD_ROLE_BEGIN,
});

export const addRoleSuccess = (role) => ({
  type: ADD_ROLE_SUCCESS,
  payload: role,
});

export const addRoleFail = (error) => ({
  type: ADD_ROLE_FAIL,
  payload: error,
});
/* ------------------------------------------- */
export const storeRequest = (request) => ({
  type: STORE_ROLE_PERMISSIONS_REQUEST,
  payload: request,
});

export const resetMessage = () => ({
  type: RESET_MESSAGES,
});

export const resetRolePermissions = () => ({
  type: RESET_ROLE_PERMISSIONS,
});

export const setDeleteErrorMessage = () => ({
  type: SET_DELETE_ERROR_MESSAGE,
});

export const setViewErrorMessage = () => ({
  type: SET_VIEW_ERROR_MESSAGE,
});
