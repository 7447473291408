import {
  ADD_FLAX_PACKAGES,
  ADD_FLAX_PACKAGES_BEGIN,
  ADD_FLAX_PACKAGES_FAIL,
  ADD_FLAX_PACKAGES_SUCCESS,
  DELETE_FLAX_PACKAGES,
  DELETE_FLAX_PACKAGES_BEGIN,
  DELETE_FLAX_PACKAGES_FAIL,
  DELETE_FLAX_PACKAGES_SUCCESS,
  GET_FLAX_PACKAGES,
  GET_FLAX_PACKAGES_BEGIN,
  GET_FLAX_PACKAGES_BY_ID,
  GET_FLAX_PACKAGES_BY_ID_BEGIN,
  GET_FLAX_PACKAGES_BY_ID_FAIL,
  GET_FLAX_PACKAGES_BY_ID_SUCCESS,
  GET_FLAX_PACKAGES_FAIL,
  GET_FLAX_PACKAGES_SUCCESS,
  GET_FLAX_PURCHASE_HISTORY,
  GET_FLAX_PURCHASE_HISTORY_BEGIN,
  GET_FLAX_PURCHASE_HISTORY_FAIL,
  GET_FLAX_PURCHASE_HISTORY_SUCCESS,
  UPDATE_FLAX_PACKAGES,
  UPDATE_FLAX_PACKAGES_BEGIN,
  UPDATE_FLAX_PACKAGES_FAIL,
  UPDATE_FLAX_PACKAGES_SUCCESS,
  RESET_FLAX_PACKAGE_ERRORS,
  GET_FLAX_PURCHASE_HISTORY_BY_USER_ID,
  GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_BEGIN,
  GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_SUCCESS,
  GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_FAIL,
  EXPORT_FLIX_PURCHASE_SUMMARY,
  EXPORT_FLIX_PURCHASE_SUMMARY_BEGIN,
  EXPORT_FLIX_PURCHASE_SUMMARY_SUCCESS,
  EXPORT_FLIX_PURCHASE_SUMMARY_FAIL,
} from "./actionTypes";

export const getFlaxPackages = (request) => ({
  type: GET_FLAX_PACKAGES,
  request: request,
});

export const getFlaxPackagesBegin = () => ({
  type: GET_FLAX_PACKAGES_BEGIN,
});

export const getFlaxPackagesSuccess = (flaxPackages) => ({
  type: GET_FLAX_PACKAGES_SUCCESS,
  payload: flaxPackages,
});

export const getFlaxPackagesFail = (error) => ({
  type: GET_FLAX_PACKAGES_FAIL,
  payload: error,
});

export const deleteFlaxPackages = ({ id, callback }) => ({
  type: DELETE_FLAX_PACKAGES,
  id: id,
  callback: callback,
});

export const deleteFlaxPackagesBegin = () => ({
  type: DELETE_FLAX_PACKAGES_BEGIN,
});

export const deleteFlaxPackagesSuccess = (purpose) => ({
  type: DELETE_FLAX_PACKAGES_SUCCESS,
  payload: purpose,
});

export const deleteFlaxPackagesFail = (error) => ({
  type: DELETE_FLAX_PACKAGES_FAIL,
  payload: error,
});

export const addFlaxPackages = ({ packageData, callback }) => ({
  type: ADD_FLAX_PACKAGES,
  packageData: packageData,
  callback: callback,
});

export const addFlaxPackagesBegin = () => ({
  type: ADD_FLAX_PACKAGES_BEGIN,
});

export const addFlaxPackagesSuccess = (packageData) => ({
  type: ADD_FLAX_PACKAGES_SUCCESS,
  payload: packageData,
});

export const addFlaxPackagesFail = (error) => ({
  type: ADD_FLAX_PACKAGES_FAIL,
  payload: error,
});
export const getFlaxPackagesById = (id) => ({
  type: GET_FLAX_PACKAGES_BY_ID,
  id: id,
});

export const getFlaxPackagesByIdBegin = () => ({
  type: GET_FLAX_PACKAGES_BY_ID_BEGIN,
});

export const getFlaxPackagesByIdSuccess = (flaxPackages) => ({
  type: GET_FLAX_PACKAGES_BY_ID_SUCCESS,
  payload: flaxPackages,
});

export const getFlaxPackagesByIdFail = (error) => ({
  type: GET_FLAX_PACKAGES_BY_ID_FAIL,
  payload: error,
});
export const updateFlaxPackages = ({ id, packageData, callback }) => ({
  id: id,
  packageData: packageData,
  callback: callback,
  type: UPDATE_FLAX_PACKAGES,
});

export const updateFlaxPackagesBegin = () => ({
  type: UPDATE_FLAX_PACKAGES_BEGIN,
});

export const updateFlaxPackagesSuccess = (packageData) => ({
  type: UPDATE_FLAX_PACKAGES_SUCCESS,
  payload: packageData,
});

export const updateFlaxPackagesFail = (error) => ({
  type: UPDATE_FLAX_PACKAGES_FAIL,
  payload: error,
});
export const getFlaxPurchaseHistory = (request) => ({
  type: GET_FLAX_PURCHASE_HISTORY,
  request: request,
});

export const getFlaxPurchaseHistoryBegin = () => ({
  type: GET_FLAX_PURCHASE_HISTORY_BEGIN,
});

export const getFlaxPurchaseHistorySuccess = (purchaseHistory) => ({
  type: GET_FLAX_PURCHASE_HISTORY_SUCCESS,
  payload: purchaseHistory,
});

export const getFlaxPurchaseHistoryFail = (error) => ({
  type: GET_FLAX_PURCHASE_HISTORY_FAIL,
  payload: error,
});
export const ResetFlaxPackageErrors = () => ({
  type: RESET_FLAX_PACKAGE_ERRORS,
});

export const getFlaxPurchaseHistoryByUserId = ({ request, userId }) => ({
  type: GET_FLAX_PURCHASE_HISTORY_BY_USER_ID,
  request: request,
  user_id: userId,
});

export const getFlaxPurchaseHistoryByUserIdBegin = () => ({
  type: GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_BEGIN,
});

export const getFlaxPurchaseHistoryByUserIdSuccess = (purchaseHistory) => ({
  type: GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_SUCCESS,
  payload: purchaseHistory,
});

export const getFlaxPurchaseHistoryByUserIdFail = (error) => ({
  type: GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_FAIL,
  payload: error,
});

// *
export const exportFlixPurchaseSummary = ({ saveAs, request }) => ({
  type: EXPORT_FLIX_PURCHASE_SUMMARY,
  saveAs: saveAs,
  request: request,
})

export const exportFlixPurchaseSummaryBegin = () => ({
  type: EXPORT_FLIX_PURCHASE_SUMMARY_BEGIN,
})

export const exportFlixPurchaseSummarySuccess = () => ({
  type: EXPORT_FLIX_PURCHASE_SUMMARY_SUCCESS,
})

export const exportFlixPurchaseSummaryFail = (error) => ({
  type: EXPORT_FLIX_PURCHASE_SUMMARY_FAIL,
  payload: error,
})
