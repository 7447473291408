import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";

import Button from "../../../components/UI/Button";
import Checkbox from "../../../components/UI/Checkbox";

const ViewPublishedPopup = ({
  modal_center,
  tog_center,
  enabled,
  enableDisableDocument,
  data,
}) => {
  return (
    <Modal isOpen={modal_center} toggle={tog_center} centered={true}>
      <div className="modal-header userList-filter-header">
        <h5 className="modal-title mt-0">Published Version</h5>

        <button type="button" onClick={tog_center} className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body userList-filter-body">
        <div className="termsAndConditionInfoWrapper">
          <div>
            <label>Terms and Conditions</label>
            <div className="text-muted">
              Published on {data?.published?.privacy_policies?.published_date}
            </div>
          </div>
          <div className="form-check form-switch form-switch-lg col-md-4 enableDocumentSwitchWrap">
            <div>
              <label className="form-check-label" htmlFor="enableDocument">
                Enable Document
              </label>
            </div>
            <div className="switchWrapper">
              <Checkbox
                id={"enableDocument"}
                value={enabled}
                handleChange={(e) => {
                  enableDisableDocument(e, data?.document?.last_published_id);
                }}
                className="form-check-input notifyCheckbox"
              />
            </div>
          </div>
        </div>

        <div
          className="publishedBody"
          dangerouslySetInnerHTML={{
            __html: data?.published?.privacy_policies?.description,
          }}
        />
      </div>

      <div className="modal-footer">
        <Button
          label={"OK"}
          buttonClass="btn bgcolor-btn-green"
          handleClick={tog_center}
        />
      </div>
    </Modal>
  );
};

export default ViewPublishedPopup;
