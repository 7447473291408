import React, { useEffect, useState } from "react";

import FilterComponent from "../../pages/UserMangement/FilterPopup";
import PayoutFilterComponent from "../../pages/Payouts/PayoutRequestManagement/FilterPopup";
import UsernameFilter from "../../pages/UsernameManagement/AllocateUsernames/filterPopup";
import ReportedFilter from "../../pages/ReportedData/FilterPopup";
import AppInstallationsFilter from "../../pages/Statistics/HeaderFilter";
import PayoutStatementFilter from "../../pages/Payouts/PayoutRequestManagement/StatementHeaderFilter";
import AdminManagementFilter from "../../pages/AdminManagement/Admins/FilterPopup";
import AppReviewFilter from "../../pages/AppReviews/FilterPopup";
import Upload from "../UI/FileUpload";
import UserRequestFilter from "../../pages/UserMangement/AccountVerification/userRequestFilter";
import HuddleFilter from "../../pages/HuddleManagement/huddleFilter";
import ConfirmationAlert from "../UI/ConfirmationAlert";
import DateFilterComponent from "../../pages/UserMangement/DateFilterPopup";
import PodiumFilter from "../../pages/PodiumManagement/PodiumFilter";
import UserCountryFilterComponent from "../../pages/UserMangement/CountryFilterPopup";
import ExpiryFilterPopup from "../../pages/UserMangement/ExpiryFilterPopup";
import { useDispatch } from "react-redux";
import { ROLE_PERMISSIONS } from "../../helpers/url_helper";
import { getAdminDetails } from "../../store/adminManagement/admins/action";
import { CSVLink } from "react-csv";
import RevenueFilter from "../../pages/UserMangement/TabSection/UserMatrix/revenueFilter";
import GRSFilter from "../../pages/UserMangement/TabSection/UserMatrix/grsfilter";
import AppSubscriptionsFilter from "../../pages/Statistics/PlatformFilter"
import PlatformFilter from "../../pages/Statistics/PlatformFilter";

const Table_headerButton = ({
  filter,
  isPremium,
  payoutfilter,
  upload,
  add,
  dropDown,
  languages,
  selectedLanguage,
  setSelectedLanguage,
  setRequest,
  request,
  setImportFile,
  addNewClickHandler,
  membershipFilter,
  statusFilter,
  usernameFilter,
  reportedFilter,
  statisticsFilter,
  payoutStatementFilter,
  addNewLabel,
  reportStatusFilter,
  categoryFilter,
  countryFilter,
  adminRoleFilter,
  adminStatusFilter,
  adminManagementFilter,
  countries,
  reqstatusFilter,
  reqplatformFilter,
  reqmembershipFilter,
  userRequestfilter,
  huddleFilter,
  appReviewFilter,
  deleteLabel,
  deleteDisabled,
  deleteClickHandler,
  showDeleteButton,
  deleteConfirmationMsg,
  dateFilter,
  regions,
  dateType,
  podiumFilter,
  reqTypeFilter,

  userCountryFilter,
  expiryFilterPermissions,
  exportcsv,
  csvData,
  columns,
  revenueFilter,
  citizenShipFilter,
  grsFilter,
  filterAppSubscriptions,
  filterFlixPurchaseSummary,
  filterCoinsPurchaseSummary,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(false);
  const csvHeaders = columns.map((col) => ({
    label: col.name,
    key: col.selector,
  }));

  return (
    <div className="table-header-buttons">
      {dateFilter && !filter && (
        <DateFilterComponent
          dateType={dateType}
          setRequest={setRequest}
          request={request}
          dateFilter={dateFilter}
        />
      )}
      {userCountryFilter && (
        <UserCountryFilterComponent
          setRequest={setRequest}
          request={request}
          userCountryFilter={userCountryFilter}
          countries={countries}
        />
      )}

      {filter && (
        <>
          {expiryFilterPermissions && expiryFilterPermissions?.view && (
            <ExpiryFilterPopup
              setRequest={setRequest}
              request={request}
              isPremium={isPremium}
              statusFilter={statusFilter}
              membershipFilter={membershipFilter}
              dateFilter={dateFilter}
            // dateType={dateType}
            />
          )}
          <FilterComponent
            setRequest={setRequest}
            request={request}
            isPremium={isPremium}
            statusFilter={statusFilter}
            membershipFilter={membershipFilter}
            citizenShipFilter={citizenShipFilter}
            dateFilter={dateFilter}
            dateType={dateType}
          />
        </>
      )}

      {payoutfilter && (
        <PayoutFilterComponent
          setRequest={setRequest}
          request={request}
          statusFilter={statusFilter}
          membershipFilter={membershipFilter}
        />
      )}
      {userRequestfilter && (
        <UserRequestFilter
          setRequest={setRequest}
          request={request}
          reqstatusFilter={reqstatusFilter}
        />
      )}
      {usernameFilter && (
        <UsernameFilter
          setRequest={setRequest}
          request={request}
          statusFilter={statusFilter}
        />
      )}
      {huddleFilter && (
        <HuddleFilter
          setRequest={setRequest}
          request={request}
          reqstatusFilter={reqstatusFilter}
          reqmembershipFilter={reqmembershipFilter}
        />
      )}
      {podiumFilter && (
        <PodiumFilter
          setRequest={setRequest}
          request={request}
          reqTypeFilter={reqTypeFilter}
        />
      )}
      {adminManagementFilter && (
        <AdminManagementFilter
          setRequest={setRequest}
          request={request}
          adminStatusFilter={adminStatusFilter}
          adminRoleFilter={adminRoleFilter}
        />
      )}
      {reportedFilter && (
        <ReportedFilter
          setRequest={setRequest}
          request={request}
          reportStatusFilter={reportStatusFilter}
          categoryFilter={categoryFilter}
        />
      )}
      {statisticsFilter && (
        <AppInstallationsFilter
          setRequest={setRequest}
          request={request}
          countryFilter={countryFilter}
          countries={countries}
          regions={regions}
          {...rest}
        />
      )}

      {appReviewFilter && (
        <AppReviewFilter setRequest={setRequest} request={request} />
      )}
      {payoutStatementFilter && (
        <PayoutStatementFilter setRequest={setRequest} request={request} />
      )}
      {(filterAppSubscriptions || filterFlixPurchaseSummary || filterCoinsPurchaseSummary) && (
        <PlatformFilter setRequest={setRequest} request={request} reqplatformFilter={reqplatformFilter} filterAppSubscriptions={filterAppSubscriptions} />
      )}
      {upload && (
        <Upload
          setImportFile={setImportFile}
          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
          label="IMPORT"
        />
      )}
      {dropDown && (
        <div className="dropdown-wrap ">
          <select
            style={{
              width: "100px",
              height: "30px",
            }}
            className=" form-select mb-4"
            value={selectedLanguage}
            onChange={(e) => {
              setSelectedLanguage(e.target.value);
            }}
          >
            {languages?.map((language, index) => (
              <option key={language} value={language}>
                {language}
              </option>
            ))}
          </select>
        </div>
      )}
      {add && (
        <button
          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
          onClick={() => addNewClickHandler()}
        >
          {addNewLabel}
        </button>
      )}
      {showDeleteButton && (
        <>
          <button
            disabled={deleteDisabled}
            className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
            onClick={() => setOpen(true)}
          >
            {deleteLabel}
          </button>
          <ConfirmationAlert
            content={deleteConfirmationMsg}
            modal_center={isOpen}
            title={"Are you sure?"}
            setmodal_center={() => setOpen(false)}
            onOK={deleteClickHandler}
          />
        </>
      )}
      {revenueFilter && (
        <RevenueFilter request={request} setRequest={setRequest} />
      )}

      {grsFilter && <GRSFilter request={request} setRequest={setRequest} />}

      {exportcsv && csvData?.length > 0 && (
        <CSVLink data={csvData} headers={csvHeaders} filename="table_data.csv">
          <button className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green">
            Export CSV
          </button>
        </CSVLink>
      )}
    </div>
  );
};

export default Table_headerButton;
