import {
  GET_USER_DATA_BEGIN,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAIL,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  BLOCK_USER_BEGIN,
  BLOCK_USER_SUCCESS,
  BLOCK_USER_FAIL,
  GET_USER_DETAILS_BEGIN,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER_DETAILS_BEGIN,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAIL,
  STORE_USER_REQUEST,
  RESET_ERRORS,
  EXPORT_USER_EMAIL_BEGIN,
  EXPORT_USER_EMAIL_SUCCESS,
  EXPORT_USER_EMAIL_FAIL,
  SEND_NOTIFICATION_BEGIN,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAIL,
  GET_BLOCKED_USERS_BEGIN,
  GET_BLOCKED_USERS_SUCCESS,
  GET_BLOCKED_USERS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  data: {},
  error: "",
  userDetails: {},

  blockedUsers: [],
  success: "",
  notification_loading: false,
  request: {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["Active"],
    page_count: 50,
    keyword: "",
  },
};

const user = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_DATA_BEGIN:
      return {
        ...state,
        data: {},
        loading: true,
        error: "",
        success: "",
        userDetails: {},
      };

    case GET_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        userDetails: {},
        success: "",
      };

    case GET_USER_DATA_FAIL:
      return {
        ...state,
        data: {},
        loading: false,
        error: action.payload,
        userDetails: {},
        success: "",
      };

    case DELETE_USER_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BLOCK_USER_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case BLOCK_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };

    case BLOCK_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case GET_USER_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        userDetails: {},
        success: "",
      };

    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        userDetails: action.payload,
      };

    case GET_USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        userDetails: {},
      };

    case UPDATE_USER_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload,
      };

    case UPDATE_USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Export user details

    case EXPORT_USER_EMAIL_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case EXPORT_USER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "",
      };

    case EXPORT_USER_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case STORE_USER_REQUEST:
      return {
        ...state,
        request: action.payload,
      };

    case RESET_ERRORS: {
      return {
        ...state,
        error: "",
        success: "",
      };
    }

    case SEND_NOTIFICATION_BEGIN:
      return {
        ...state,
        notification_loading: true,
        error: "",
      };

    case SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification_loading: false,
        error: "",
        success: action.payload,
      };

    case SEND_NOTIFICATION_FAIL:
      return {
        ...state,
        notification_loading: false,
        error: action.payload,
      };
    case GET_BLOCKED_USERS_BEGIN:
      return {
        ...state,
        blockedUsers: [],
        loading: true,
      };
    case GET_BLOCKED_USERS_SUCCESS:
      return {
        ...state,
        blockedUsers: action.payload,
        loading: false,
      };

    case GET_BLOCKED_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default user;
