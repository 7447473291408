import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Loader from "../../../components/Common/Loader";

import {
  getAllocatedNames,
  storeRequest,
} from "../../../store/usernames/allocateUserNames/action";

import { allocateUsernameColumnsData } from "../../../helpers/columns";

import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import { checkUserPermission } from "../../../helpers/utils";

const AllocateUsernames = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const allocatedData = useSelector((state) => state.allocatedUsername);
  const { t } = useTranslation();

  const loginData = useSelector((state) => state.Login);
  const [userPermissions,setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;
  const isAddEnabled = userPermissions?.create || false;

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const basicRequest = {
    sort: "modified",
    sort_order: "desc",
    page: 1,
    page_count: 50,
  };

  const [request, setRequest] = useState(
    history?.location?.state?.from === "keepFilter"
      ? { ...allocatedData?.request }
      : { ...basicRequest }
  );

  useEffect(() => {
    scrollToAlertMessage();
  }, [allocatedData?.error]);

  const scrollToAlertMessage = () => {
    if (allocatedData?.error) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {   
    if(loginData?.rolePermissions?.role){
      setUserPermissions(checkUserPermission(loginData?.rolePermissions?.role,"Username Management","Allocate Username"))
    }
  }, [loginData]);

  useEffect(() => {
    history?.location?.state?.from === "keepFilter"
      ? setRequest({ ...allocatedData?.request })
      : setRequest({ ...basicRequest });
  }, [history?.location?.state?.from]);

  useEffect(() => {
    request && dispatch(getAllocatedNames(request));
    request && dispatch(storeRequest(request));
  }, [request]);

  const addNewClickHandler = () => {
    history.push("/allocate-usernames-new");
  };

  const formatAllocatedUsernameData = useCallback(() => {
    return allocatedData?.data?.allocations?.map((username, index) => ({
      ...username,
      no: (request?.page - 1) * request?.page_count + index + 1,
      phone: (
        <>
          {username?.country_code &&
            username?.phone &&
            `${username?.country_code} - ${username?.phone}`}
        </>
      ),
      status: (
        <div
          className={
            username.status === "Deleted"
              ? "badge bg-soft-gray font-size-13"
              : username.status === "Pending"
              ? "badge bg-soft-orange font-size-13"
              : username.status === "Forcefully Changed"
              ? "badge bg-soft-blue font-size-13"
              : username.status === "Accepted"
              ? "badge bg-soft-green font-size-13"
              : username.status === "Rejected"
              ? "badge bg-soft-red font-size-13"
              : null
          }
        >
          {username.status}
        </div>
      ),
      actions: (
        <div className="cust-table-actions-wrap">
          <button
            className=" color-violet"
            title="Edit"
            onClick={() =>
              history.push(`/allocate-usernames-edit/${username.id}`)
            }
            disabled={!isEditable}
          >
            <img src={Edit_icon} alt="Edit" style={{'opacity':!isEditable ? "50%":""}}/>
          </button>
        </div>
      ),
    }));
  }, [allocatedData?.data?.allocations,isEditable]);

  //memoized user data
  const allocatedUsernameData = useMemo(() => formatAllocatedUsernameData(), [
    formatAllocatedUsernameData,
  ]);

  return (
    <div className="page-content" id="allocateUNList" ref={pageWrapRef}>
      <Container fluid>
        <Row>
          <Breadcrumbs
            title="Tables"
            breadcrumbItem={t("AllocateUserName.Title")}
          />
          {allocatedData?.error && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {allocatedData?.error}
              </UncontrolledAlert>
            </div>
          )}
          <Datatable
            tableID={"allocatedUserNames"}
            rows={allocatedUsernameData}
            columns={allocateUsernameColumnsData}
            add={isAddEnabled}
            search
            usernameFilter
            addNewClickHandler={addNewClickHandler}
            request={request}
            setRequest={setRequest}
            totalRecords={allocatedData?.data?.total}
            loading={allocatedData?.loading}
            addNewLabel={t("AllocateUserName.add")}
            searchTerm={allocatedData?.request?.keyword}
            tableCardClassName={"snoTable"}
          />
        </Row>
      </Container>
      {allocatedData?.loading && <Loader darkBg={true}/>}
    </div>
  );
};

export default AllocateUsernames;
