import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardBody, Row } from "reactstrap";
import { Container } from "semantic-ui-react";
import {
  flashReportsColumnData,
  postAtReportedPostColumnData,
  postAtReportsColumnData,
} from "../../../helpers/columns";
import { getPostReportsById } from "../../../store/postatManagement/actions";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Datatable from "../../../components/UI/Tables/Datatable";
import Loader from "../../../components/Common/Loader";
import { useLocation } from "react-router";
import { convertTime } from "../../../helpers/utils";
import BackButton from "../../../components/UI/BackButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getCategoryData } from "../../../store/reportedUser/action";
import { Card } from "@material-ui/core";
import { ImagePreview } from "../../../components/Common/imagePreview";
import {
  getHuddleReportedUsersCategories,
  getHuddlesCategories,
} from "../../../store/huddles/actions";
import { getFlashReports } from "../../../store/flashManagement/actions";
import { VideoPreview } from "../../UserMangement/TabSection/Flashes/VideoPreview";

const FlashReports = () => {
  const location = useLocation();
  const history = useHistory();
  const basicRequest = {
    // sort: "transfered_date",
    // sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
  };
  //   const history = useHistory();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState({});
  const [imgUrl, setImgUrl] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [request, setRequest] = useState({ ...basicRequest });
  useEffect(() => {
    dispatch(getFlashReports(request, location?.state?.postId));
    // dispatch(getHuddleReportedUsersCategories());
  }, []);

  const previewVideo = (url) => {
    setOpen(true);
    setSelectedRow({ video_url: data?.flashReports?.flash_details?.video_url });
  };

  const data = useSelector((state) => state?.FlashCategories);

  const formatFlashReports = useCallback(() => {
    return (
      data?.flashReports?.items?.length > 0 &&
      data?.flashReports?.items?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        reported_by: (
          <div
            data-tag="allowRowEvents"
            title={result?.reported_by_details?.name}
          >
            {result?.reported_by_details?.name}
          </div>
        ),
        comment: (
          <div data-tag="allowRowEvents" title={result?.report_text}>
            {result?.report_text}
          </div>
        ),
        reason: (
          <div data-tag="allowRowEvents" title={result?.reason}>
            {result?.reason}
          </div>
        ),

        reported_date: (
          <div data-tag="allowRowEvents" title={"reported date"}>
            {convertTime(result?.time_created)}
          </div>
        ),
      }))
    );
  }, [request, data?.flashReports?.items]);
  const postReports = useMemo(() => formatFlashReports(), [formatFlashReports]);

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        // id="userMgmtList"
        // ref={pageWrapRef}
      >
        <BackButton
          label="Reported Flash"
          handleClick={() => history.push("/reported-flash")}
        />
        <Container>
          {data?.flashReports && data?.flashReports?.flash_details && (
            <div className=" ">
              <Card className="appSettingCard mt-3">
                <CardBody>
                  <div className="huddle-gen-info  py-5   d-flex  ">
                    <div className="col-6">
                      <h5 className="huddle-titles mb-3  ">
                        <span className="text-secondary">Post Owner : </span>
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            const win = window.open(
                              `/user-details/${data?.flashReports?.flash_details?.owner_details?.id}?tab=1`,
                              "_blank"
                            );
                            win.focus();
                          }}
                          className="text-black ms-2 cursor-pointer"
                        >
                          {
                            data?.flashReports?.flash_details?.owner_details
                              ?.name
                          }
                        </span>
                      </h5>

                      <h5 className=" huddle-titles   ">
                        <span className="text-secondary"> Reports Count: </span>{" "}
                        <span className="text-black ms-2">
                          {data?.flashReports?.flash_details?.reports_count}
                        </span>
                      </h5>
                    </div>
                    <div className="d-flex  ">
                      <img
                        onClick={(e) => {
                          e.preventDefault();
                          previewVideo(
                            data?.flashReports?.flash_details?.video_url
                          );
                        }}
                        src={data?.flashReports?.flash_details?.thumbnail_url}
                        width={200}
                        height={200}
                        className="mr-5 editAdmin-profile-pic  cursor-pointer"
                        style={{ borderRadius: "8%" }}
                        // onClick={() => setmodalShow(true)}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
              {/* <ImagePreview
                imgUrl={imgUrl}
                isOpen={isOpen}
                setOpen={setOpen}
                title="Reported Post"
              /> */}
              <VideoPreview
                selectedRow={selectedRow}
                isOpen={isOpen}
                setOpen={setOpen}
              />
            </div>
          )}
          <Row className="mt-5">
            <Breadcrumbs title="Tables" breadcrumbItem="Flash Reports" />
            <div className="container-fluid">
              <Datatable
                tableID={"adminTable"}
                columns={flashReportsColumnData}
                rows={postReports}
                setRequest={setRequest}
                request={request}
                // dateFilter
                totalRecords={data?.flashReports?.total}
                // search
                // dateType="Purchased Date Range"
                // add
                // dropDown
                // languages={languages}
                // addNewClickHandler={addNewClickHandler}
                // selectedLanguage={selectedLanguage}
                // setSelectedLanguage={setSelectedLanguage}
                // addNewLabel={t("Admins.addAdmin.addBtn")}
                // searchTerm={data?.request?.keyword}
                // loading={data?.loading}
                // tableRowClicked={(param) => tableRowClickHandler(param)}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
        </Container>

        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default FlashReports;
