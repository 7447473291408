import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";

import Avatar from "../../../assets/images/users/user.svg";

//i18n
import { withTranslation } from "react-i18next";

import Loader from "../../../components/Common/Loader";

import {
  markAllAsRead,
  getNotifications,
  resetUnseenCount,
} from "../../../store/notification/actions";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const data = useSelector((state) => state.notification);

  const markAllRead = () => {
    dispatch(markAllAsRead({}));
  };

  const readNotification = (item) => {
    dispatch(
      markAllAsRead({
        notification_id: item.id,
      })
    );
    let redirectUrl =
      item.action === "user_delete" ||
      item.action === "renew_subscription" ||
      item.action === "new_subscription"
        ? "/users"
        : item.action === "report_user"
        ? "/reported-data"
        : item.action === "reject_username" || item.action === "accept_username"
        ? `/allocate-usernames-edit/${item.associate_obj_id}`
        : "";
    history.push(redirectUrl);
  };
  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i
            className="uil-bell"
            onClick={() => {
              dispatch(getNotifications());
              dispatch(resetUnseenCount());
            }}
          ></i>
          {data?.unseenCount?.unseen_count > 0 && (
            <span className="badge bg-danger rounded-pill">
              {data?.unseenCount?.unseen_count}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16">
                  {props.t("Notifications.Title")}
                </h6>
              </Col>
              {data?.notification?.notifications?.length > 0 && (
                <div className="col-auto">
                  <a href="#!" className="small" onClick={() => markAllRead()}>
                    Mark all as read
                  </a>
                </div>
              )}
            </Row>
          </div>
          {data?.loading ? (
            <Loader darkBg={false} />
          ) : data?.notification?.notifications?.length ? (
            <SimpleBar style={{ height: "230px" }}>
              {data?.notification?.notifications?.map((item) => {
                return (
                  <Link
                    className={`text-reset notification-item notification-dropdown-item${
                      item.is_read ? "" : " unread"
                    }`}
                    onClick={() => readNotification(item)}
                  >
                    <div className="d-flex align-items-start">
                      <div className="avatar-xs me-3">
                        <img
                          src={
                            item.icon_path === null ? Avatar : item.icon_path
                          }
                          className="notification-icon"
                        />
                      </div>
                      <div className="flex-1 notificationInfo">
                        <h8 className="mt-0 mb-1">{item.title}</h8>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{item.body}</p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline"></i>{" "}
                            {item.delivered_time}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </SimpleBar>
          ) : null}
          {/* {data?.notification?.notifications?.length > 0 && ( */}
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="/notifications"
            >
              <i className="uil-arrow-circle-right me-1"></i>
              {props.t("View all")}
            </Link>
          </div>
          {/* )} */}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
