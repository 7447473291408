import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import Datatable from "../../../../components/UI/Tables/Datatable";
import {
  coinsToFlaxConversionColummnData,
  flaxToCoinsConversionColummnData,
  giftTransferColumnData,
} from "../../../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../../helpers/constants";
import { convertTime, convertToUAETime } from "../../../../helpers/utils";

import Loader from "../../../../components/Common/Loader";
import { getFlaxCoinsConversionHistory } from "../../../../store/coinsFlaxConversion/actions";
import {
  getGiftTransferHistory,
  getGiftTransferHistoryByUserId,
} from "../../../../store/GiftTransfers/actions";
import premiumImage from "../../../../assets/icons/premium.svg";
import defaultProfile from "../../../../assets/images/users/user.svg";
import CountUp from "react-countup";
import Coin_bal_icon from "../../../../assets/icons/sdfl_icons/coin-bal-icon.svg";
import Flix_bal_icon from "../../../../assets/icons/sdfl_icons/flix-bal-icon.svg";

const FlixGiftRevenueShare = ({ toggle, modal, userId }) => {
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "transfered_date",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 10,
    keyword: "",
    gift_type: "flix",
    filter: [],
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const data = useSelector((state) => state.giftTransfer);

  useEffect(() => {
    request &&
      userId &&
      dispatch(getGiftTransferHistoryByUserId({ request, userId }));
  }, [request, request?.actions, modal]);

  const giftTransferData =
    data?.giftTransferDataByUser?.gift_transfers?.length >= 0 &&
    data?.giftTransferDataByUser?.gift_transfers?.map((transfers, index) => {
      // Check if sender_details and receiver_details are not null
      const senderDetails = transfers?.sender_details || {};
      const receiverDetails = transfers?.receiver_details || {};

      return {
        no: (request?.page - 1) * request?.page_count + index + 1,
        sender_image: (
          <div
            onClick={() => {
              window.open(
                `/user-details/${senderDetails?.sender_id}`,
                "_blank"
              );
            }}
            className="profileWrap"
            data-tag="allowRowEvents"
          >
            {senderDetails.premium ? (
              <div className="huddle_premium_icon" data-tag="allowRowEvents">
                <img
                  src={premiumImage}
                  data-tag="allowRowEvents"
                  alt="Premium"
                />
              </div>
            ) : null}

            <div className="d-flex">
              <div data-tag="allowRowEvents">
                <img
                  data-tag="allowRowEvents"
                  className="table_profileImg"
                  src={
                    senderDetails.profile_photo
                      ? senderDetails.profile_photo
                      : defaultProfile
                  }
                  alt="Profile"
                />
              </div>

              <div
                className="mx-2 mt-3"
                title={senderDetails.name}
                data-tag="allowRowEvents"
              >
                {senderDetails.name}{" "}
              </div>
            </div>
          </div>
        ),
        receiver_image: (
          <div
            onClick={() => {
              window.open(
                `/user-details/${receiverDetails?.receiver_id}`,
                "_blank"
              );
            }}
            className="profileWrap"
            data-tag="allowRowEvents"
          >
            {receiverDetails.premium ? (
              <div className="huddle_premium_icon" data-tag="allowRowEvents">
                <img
                  src={premiumImage}
                  data-tag="allowRowEvents"
                  alt="Premium"
                />
              </div>
            ) : null}

            <div className="d-flex">
              <div data-tag="allowRowEvents">
                <img
                  data-tag="allowRowEvents"
                  className="table_profileImg"
                  src={
                    receiverDetails.profile_photo
                      ? receiverDetails.profile_photo
                      : defaultProfile
                  }
                  alt="Profile"
                />
              </div>

              <div
                className="mx-2 mt-3"
                title={receiverDetails.name}
                data-tag="allowRowEvents"
              >
                {receiverDetails.name}{" "}
              </div>
            </div>
          </div>
        ),
        gift_identifier: (
          <>
            <div data-tag="allowRowEvents" title={transfers?.gift_name}>
              {transfers?.gift_name}
            </div>
            <div data-tag="allowRowEvents" title={transfers?.gift_name}>
              <img width={28} src={transfers?.gift_static_url} alt="gift" />
            </div>
          </>
        ),

        send_coins: (
          <div
            data-tag="allowRowEvents"
            title={`${senderDetails?.name} --> ${receiverDetails?.name}`}
          >
            {transfers?.sender_details?.sent_flix}
          </div>
        ),
        received_coins: (
          <div
            data-tag="allowRowEvents"
            title={`${senderDetails?.name} --> ${receiverDetails?.name}`}
          >
            {receiverDetails?.received_flix}
          </div>
        ),
        sending_source: (
          <div
            data-tag="allowRowEvents"
            title={`${senderDetails?.name} --> ${receiverDetails?.name}`}
          >
            {transfers?.sending_source}
          </div>
        ),
        manager_received_coins: (
          <div
            data-tag="allowRowEvents"
            title={`${senderDetails?.name} --> ${receiverDetails?.name}`}
          >
            {transfers?.manager_received_flix}
          </div>
        ),
        flashat_points: (
          <div
            data-tag="allowRowEvents"
            title={`${senderDetails?.name} --> ${receiverDetails?.name}`}
          >
            {transfers?.flashat_points}
          </div>
        ),
        transfered_date: (
          <div
            data-tag="allowRowEvents"
            title={`${senderDetails?.name} --> ${receiverDetails?.name}`}
          >
            {convertTime(transfers?.gift_sent_time)}
          </div>
        ),
      };
    });

  const csvData =
    data?.giftTransferDataByUser?.gift_transfers?.length >= 0 &&
    data?.giftTransferDataByUser?.gift_transfers?.map((transfers, index) => {
      const senderDetails = transfers?.sender_details || {};
      const receiverDetails = transfers?.receiver_details || {};

      return {
        no: (request?.page - 1) * request?.page_count + index + 1,
        sender_image: senderDetails.name || "N/A",
        receiver_image: receiverDetails.name || "N/A",
        gift_identifier: transfers?.gift_name || "N/A",
        send_coins: transfers?.sender_details?.sent_flix || 0,
        received_coins: transfers?.receiver_details?.received_flix || 0,
        sending_source: transfers?.sending_source || "N/A",
        manager_received_coins: transfers?.manager_received_flix || 0,
        flashat_points: transfers?.flashat_points || 0,
        transfered_date: convertTime(transfers?.gift_sent_time) || "N/A",
      };
    });

  return (
    <div>
      <Modal
        centered
        isOpen={modal}
        toggle={toggle}
        style={{ minWidth: "1000px", maxWidth: "1200px" }}
      >
        <ModalBody>
          <>
            <Container
              fluid
              id="userManagementWrapper"
              data-testid="userMgmtWrap"
            >
              <Row className="mt-4">
                <Breadcrumbs
                  title="Tables"
                  breadcrumbItem="FLiX Gift Revenue Share"
                />
                {!data?.loading && (
                  <>
                    <Col md={3} xl={3}>
                      <Card>
                        <CardBody>
                          <div className="float-end mt-2">
                            <img src={Coin_bal_icon} alt="coin" />
                          </div>
                          <div>
                            <h4 className="mb-1 mt-1">
                              <span>
                                <CountUp
                                  end={
                                    +data?.giftTransferDataByUser?.sent_gifts
                                  }
                                />
                              </span>
                            </h4>
                            <p className="text-muted mb-0">Total FLiX Sent</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={3} xl={3}>
                      <Card>
                        <CardBody>
                          <div className="float-end mt-2">
                            <img src={Coin_bal_icon} alt="coins" />
                          </div>
                          <div>
                            <h4 className="mb-1 mt-1">
                              <span>
                                <CountUp
                                  end={
                                    +data?.giftTransferDataByUser
                                      ?.received_gifts
                                  }
                                />
                              </span>
                            </h4>
                            <p className="text-muted mb-0">
                              Total FLiX Received
                            </p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={3} xl={3}>
                      <Card>
                        <CardBody>
                          <div className="float-end mt-2">
                            <img src={Coin_bal_icon} alt="coins" />
                          </div>
                          <div>
                            <h4 className="mb-1 mt-1">
                              <span>
                                <CountUp
                                  end={
                                    +data?.giftTransferDataByUser
                                      ?.manager_received_point
                                  }
                                />
                              </span>
                            </h4>
                            <p className="text-muted mb-0">Manager Share</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </>
                )}

                <Datatable
                  // defaultSortField={request?.sort || "transfered_date"}
                  // defaultSortAsc={
                  //   request?.sort_order === "asc" ? true : false
                  // }
                  // dateFilter
                  // dateType="Transfered Date Range"
                  tableID={"flaxTable"}
                  columns={giftTransferColumnData}
                  rows={giftTransferData}
                  setRequest={setRequest}
                  request={request}
                  totalRecords={data?.giftTransferDataByUser?.total}
                  // search
                  // searchTerm={data?.request?.keyword}
                  // reqstatusFilter={request?.status_filter}
                  loading={data?.loading}
                  tableCardClassName={"snoTable"}
                  exportcsv
                  csvData={csvData}
                  revenueFilter
                />
              </Row>
            </Container>
            {data?.loading && <Loader darkBg={true} />}
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default FlixGiftRevenueShare;
