import {
  GET_SUBSCRIPTION_DETAILS,
  GET_SUBSCRIPTION_DETAILS_BEGIN,
  GET_SUBSCRIPTION_DETAILS_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_FAIL,
} from "./actionTypes";

export const getSubscriptionDetails = (id, request) => ({
  type: GET_SUBSCRIPTION_DETAILS,
  payload: { id, request },
});

export const getSubscriptionDetailsBegin = () => ({
  type: GET_SUBSCRIPTION_DETAILS_BEGIN,
});

export const getSubscriptionDetailsSuccess = (subscriptionDetails) => ({
  type: GET_SUBSCRIPTION_DETAILS_SUCCESS,
  payload: subscriptionDetails,
});

export const getSubscriptionDetailsFail = (error) => ({
  type: GET_SUBSCRIPTION_DETAILS_FAIL,
  payload: error,
});
