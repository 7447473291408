import {
  GET_ALLOCATED_NAMES_BEGIN,
  GET_ALLOCATED_NAMES_SUCCESS,
  GET_ALLOCATED_NAMES_FAIL,
  GET_ALLOCATED_NAME_ID_BEGIN,
  GET_ALLOCATED_NAME_ID_SUCCESS,
  GET_ALLOCATED_NAME_ID_FAIL,
  GET_ALLOCATED_NAME_HISTORY_BEGIN,
  GET_ALLOCATED_NAME_HISTORY_SUCCESS,
  GET_ALLOCATED_NAME_HISTORY_FAIL,
  USER_SEARCH_BEGIN,
  USER_SEARCH_SUCCESS,
  USER_SEARCH_FAIL,
  STORE_USER_REQUEST,
  DELETE_SUGGESTION_BEGIN,
  DELETE_SUGGESTION_SUCCESS,
  DELETE_SUGGESTION_FAIL,
  ALLOCATE_USERNAME_BEGIN,
  ALLOCATE_USERNAME_SUCCESS,
  ALLOCATE_USERNAME_FAIL,
  UPDATE_ALLOCATED_USERNAME_BEGIN,
  UPDATE_ALLOCATED_USERNAME_SUCCESS,
  UPDATE_ALLOCATED_USERNAME_FAIL,
  CHECK_USERNAME_AVAILABILITY_BEGIN,
  CHECK_USERNAME_AVAILABILITY_SUCCESS,
  CHECK_USERNAME_AVAILABILITY_FAIL,
  RESET_MESSAGES,
  RESET_MESSAGES_AND_USERNAME,
  RESET_STATE
} from "./actionTypes";

const INIT_STATE = {
  data: {},
  userDetails: {},
  error: "",
  loading: false,
  historyDetails: [],
  request: {
    sort: "modified",
    sort_order: "desc",
    page: 1,
    page_count: 50,
  },
  available: "",
  availableError: ""
};

const allocatedUsername = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALLOCATED_NAMES_BEGIN:
      return {
        ...state,
        data: {},
        loading: true,
        user: undefined,
        error: "",
        allocateError: "",
        success: '',
        availableError: ''
      };
    case GET_ALLOCATED_NAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        user: undefined,
        error: '',
        allocateError: "",
        availableError: ''
      };
    case GET_ALLOCATED_NAMES_FAIL:
      return {
        ...state,
        data: {},
        loading: false,
        user: undefined,
        error: action.payload,
        allocateError: "",
        availableError: ''
      };
    case USER_SEARCH_BEGIN:
      return {
        ...state,
        loading: true,
        user: undefined,
        error: "",
        allocateError: "",
        availableError: "",
        available: "",
        success: ""
      };
    case USER_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        allocateError: ""
      };
    case USER_SEARCH_FAIL:
      return {
        ...state,
        user: undefined,
        loading: false,
        error: action.payload,
        allocateError: ""
      };

    case GET_ALLOCATED_NAME_ID_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        userDetails: {},
      };

    case GET_ALLOCATED_NAME_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        userDetails: action.payload,
      };

    case GET_ALLOCATED_NAME_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        userDetails: {},
      };

    case GET_ALLOCATED_NAME_HISTORY_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        historyDetails: [],
        available: "",
        availableError: ""
      };

    case GET_ALLOCATED_NAME_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        historyDetails: action.payload,
      };

    case GET_ALLOCATED_NAME_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        historyDetails: [],
      };

    case DELETE_SUGGESTION_BEGIN:
      return {
        ...state,
        loading: true,
        allocateError: "",
        success: ""
      }

    case DELETE_SUGGESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        allocateError: "",
        success: "Suggestion deleted successfully"
      }

    case DELETE_SUGGESTION_FAIL:
      return {
        ...state,
        loading: false,
        allocateError: action.payload,
        success: "",
      }
    case ALLOCATE_USERNAME_BEGIN:
      return {
        ...state,
        loading: true,
        allocateError: "",
        available: "",
        success: "",
      };

    case ALLOCATE_USERNAME_SUCCESS:
      return {
        ...state,
        loading: false,
        allocateError: "",
        success: action.payload,
        user: undefined,
      };

    case ALLOCATE_USERNAME_FAIL:
      return {
        ...state,
        loading: false,
        allocateError: action.payload,
        success: "",
      };

    case UPDATE_ALLOCATED_USERNAME_BEGIN:
      return {
        ...state,
        loading: true,
        allocateError: "",
        available: "",
        success: "",
      };

    case UPDATE_ALLOCATED_USERNAME_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case UPDATE_ALLOCATED_USERNAME_FAIL:
      return {
        ...state,
        loading: false,
        success: "",
      };

    case STORE_USER_REQUEST:
      return {
        ...state,
        request: action.payload,
      };

    case CHECK_USERNAME_AVAILABILITY_BEGIN:
      return {
        ...state,
        loading: true,
        available: "",
        availableError: "",
        allocateError: "",
        availableUsername: ""
      }

    case CHECK_USERNAME_AVAILABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        availableError: "",
        available: "Username is available",
        availableUsername: action?.payload?.username
      }

    case CHECK_USERNAME_AVAILABILITY_FAIL:
      return {
        ...state,
        loading: false,
        available: "",
        availableError: action.payload,
        availableUsername: ""
      }
    case RESET_MESSAGES:
      return {
        ...state,
        available: "",
        availableError: "",
        success: "",
        error: "",
      }
    case RESET_MESSAGES_AND_USERNAME:
      return {
        ...state,
        available: "",
        availableError: "",
        availableUsername: "",
        success: "",
        error: "",
      }
    case RESET_STATE:
      return INIT_STATE;
    default:
      return state;
  }
};

export default allocatedUsername;
