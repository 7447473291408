import {
  GET_ROLE_DATA_BEGIN,
  GET_ROLE_DATA_SUCCESS,
  GET_ROLE_DATA_FAIL,
  GET_ROLE_PERMISSIONS_BEGIN,
  GET_ROLE_PERMISSIONS_SUCCESS,
  GET_ROLE_PERMISSIONS_FAIL,
  GET_PERMISSIONS_MASTER_DATA_BEGIN,
  GET_PERMISSIONS_MASTER_DATA_SUCCESS,
  GET_PERMISSIONS_MASTER_DATA_FAIL,
  GET_ADMINS_ROLE_BEGIN,
  GET_ADMINS_ROLE_SUCCESS,
  GET_ADMINS_ROLE_FAIL,
  DELETE_ROLE_BEGIN,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  UPDATE_ROLE_PERMISSIONS_BEGIN,
  UPDATE_ROLE_PERMISSIONS_SUCCESS,
  UPDATE_ROLE_PERMISSIONS_FAIL,
  ADD_ROLE_BEGIN,
  ADD_ROLE_FAIL,
  ADD_ROLE_SUCCESS,
  STORE_ROLE_PERMISSIONS_REQUEST,
  RESET_ROLE_PERMISSIONS,
  RESET_MESSAGES,
  SET_DELETE_ERROR_MESSAGE,
  SET_VIEW_ERROR_MESSAGE,
} from "./actionTypes";

const INIT_STATE = {
  loading: false,
  request: {
    page: 1,
    page_count: 50,
  },
  roleData: {},
  rolePermissions: {},
  permissionsData: {},
  adminsData: [],
  success: "",
  error: "",
};

const rolePermissions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLE_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        roleData: {},
      };
    case GET_ROLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        roleData: action.payload,
      };
    case GET_ROLE_DATA_FAIL:
      return {
        ...state,
        roleData: {},
        loading: false,
        error: action.payload,
      };
    /**----------------------------- */

    case GET_ROLE_PERMISSIONS_BEGIN:
      return {
        ...state,
        rolePermissions: {},
      };

    case GET_ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        rolePermissions: action.payload,
      };

    case GET_ROLE_PERMISSIONS_FAIL:
      return {
        ...state,
        rolePermissions: {},
        error: action.payload,
      };
    /**----------------------------- */

    case GET_ADMINS_ROLE_BEGIN:
      return {
        ...state,
        adminsData: [],
      };

    case GET_ADMINS_ROLE_SUCCESS:
      return {
        ...state,
        adminsData: action.payload,
      };

    case GET_ADMINS_ROLE_FAIL:
      return {
        ...state,
        adminsData: [],
        error: action.payload,
      };
    /**----------------------------- */
    case GET_PERMISSIONS_MASTER_DATA_BEGIN:
      return {
        ...state,
        permissionsData: {},
      };

    case GET_PERMISSIONS_MASTER_DATA_SUCCESS:
      return {
        ...state,
        permissionsData: action.payload,
      };

    case GET_PERMISSIONS_MASTER_DATA_FAIL:
      return {
        ...state,
        permissionsData: {},
        error: action.payload,
      };
    /**----------------------------- */
    case ADD_ROLE_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case ADD_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Role added successfully",
      };

    case ADD_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };
    /* ------------------------------------------- */
    case UPDATE_ROLE_PERMISSIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case UPDATE_ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: "Permissions updated successfully",
        rolePermissions: action.payload,
      };

    case UPDATE_ROLE_PERMISSIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };
    /* ------------------------------------------- */
    case DELETE_ROLE_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: "Role deleted successfully",
      };

    case DELETE_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };
    /* ------------------------------------------- */

    case STORE_ROLE_PERMISSIONS_REQUEST:
      return {
        ...state,
        request: action.payload,
      };

    /* ------------------------------------------- */

    case RESET_MESSAGES:
      return {
        ...state,
        success: "",
        error: "",
      };

    case RESET_ROLE_PERMISSIONS:
      return {
        ...state,
        rolePermissions: {},
      };

    case SET_DELETE_ERROR_MESSAGE:
      return {
        ...state,
        success: "",
        error:
          "Cannot Delete this Role! This role that you are trying to delete is currently assigned to admin(s)",
      };

      case SET_VIEW_ERROR_MESSAGE:
        return {
          ...state,
          success: "",
          error:
            "This admin needs 'View' permission to do the selected operation",
        };

    default:
      return state;
  }
};

export default rolePermissions;
