import { 
        GET_REGISTRATION_LOGIN_HISTORY,
        GET_REGISTRATION_LOGIN_HISTORY_BEGIN,
        GET_REGISTRATION_LOGIN_HISTORY_SUCCESS,
        GET_REGISTRATION_LOGIN_HISTORY_FAIL
     } from './actionTypes';

export const getRegistrationAndLogin = (id,request) => ({
    type:GET_REGISTRATION_LOGIN_HISTORY,
    payload:{id,request}
})

export const getRegistrationAndLoginBegin = () => ({
    type:GET_REGISTRATION_LOGIN_HISTORY_BEGIN,
})

export const getRegistrationAndLoginSuccess = (reg_log_details) => ({
    type:GET_REGISTRATION_LOGIN_HISTORY_SUCCESS,
    payload:reg_log_details
})

export const getRegistrationAndLoginFail = (error) => ({
    type:GET_REGISTRATION_LOGIN_HISTORY_FAIL,
    payload:error
})