import React from "react";
import defaultProfile from "../../../../assets/images/users/user.svg";
import verifedImage from "../../../../assets/icons/verified.svg";
import premiumImage from "../../../../assets/icons/premium.svg";

const parentUser = ({
  image_url,
  name,
  username,
  user_category,
  is_premium,
  is_deleted,
  id,
  is_verified,
  profilePicClick,
}) => {
  return (
    <div>
      {!is_deleted ? (
        <div className="superStarAndUser">
          <div className="superStarAvatharWrap">
            <a
              onClick={profilePicClick}
              style={{ cursor: "pointer" }}
              target="_blank"
            >
              <img src={image_url === null ? defaultProfile : image_url} />
              {is_verified === true ? (
                <img className="user_isPremiumImage" src={verifedImage} />
              ) : is_premium === true ? (
                <img className="user_isPremiumImage" src={premiumImage} />
              ) : null}
            </a>
          </div>
          <div className="superStarDetailsWrap">
            <div className="superStarName">
              <span className="user_name">{name}</span>{" "}
              <span className="user_category">{user_category}</span>
            </div>
            <div className="superStarUserName">
              <span className="text-muted">{username}</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="level_users deletedUser bg-soft-red p-1">
          <div>User ID {id} is deleted</div>
        </div>
      )}
      <div className="dottedWrapper"></div>
    </div>
  );
};

export default parentUser;
