
export const GET_ACCOUNT_VERIFICATION_STATUS = "GET_ACCOUNT_VERIFICATION_STATUS";
export const GET_ACCOUNT_VERIFICATION_STATUS_BEGIN = "GET_ACCOUNT_VERIFICATION_STATUS_BEGIN";
export const GET_ACCOUNT_VERIFICATION_STATUS_SUCCESS = "GET_ACCOUNT_VERIFICATION_STATUS_SUCCESS";
export const GET_ACCOUNT_VERIFICATION_STATUS_FAIL = "GET_ACCOUNT_VERIFICATION_STATUS_FAIL";

export const ADD_ACCOUNT_VERIFICATION_STATUS = "ADD_ACCOUNT_VERIFICATION_STATUS";
export const ADD_ACCOUNT_VERIFICATION_STATUS_BEGIN = "ADD_ACCOUNT_VERIFICATION_STATUS_BEGIN";
export const ADD_ACCOUNT_VERIFICATION_STATUS_SUCCESS = "ADD_ACCOUNT_VERIFICATION_STATUS_SUCCESS";
export const ADD_ACCOUNT_VERIFICATION_STATUS_FAIL = "ADD_ACCOUNT_VERIFICATION_STATUS_FAIL";

