import {
  GET_USER_FLAX_RATES,
  GET_USER_FLAX_RATES_BEGIN,
  GET_USER_FLAX_RATES_FAIL,
  GET_USER_FLAX_RATES_SUCCESS,
  GET_USER_RATING_HISTORY,
  GET_USER_RATING_HISTORY_BEGIN,
  GET_USER_RATING_HISTORY_FAIL,
  GET_USER_RATING_HISTORY_SUCCESS,
} from "./actionTypes";

export const getUserFlaxRates = ({ request, userId }) => ({
  type: GET_USER_FLAX_RATES,
  request: request,
  userId: userId,
});

export const getUserFlaxRatesBegin = () => ({
  type: GET_USER_FLAX_RATES_BEGIN,
});

export const getUserFlaxRatesSuccess = (flaxRates) => ({
  type: GET_USER_FLAX_RATES_SUCCESS,
  payload: flaxRates,
});

export const getUserFlaxRatesFail = (error) => ({
  type: GET_USER_FLAX_RATES_FAIL,
  payload: error,
});
export const getUserRatingHistory = ({ request, userId }) => ({
  type: GET_USER_RATING_HISTORY,
  request: request,
  userId: userId,
});

export const getUserRatingHistoryBegin = () => ({
  type: GET_USER_RATING_HISTORY_BEGIN,
});

export const getUserRatingHistorySuccess = (ratingHistory) => ({
  type: GET_USER_RATING_HISTORY_SUCCESS,
  payload: ratingHistory,
});

export const getUserRatingHistoryFail = (error) => ({
  type: GET_USER_RATING_HISTORY_FAIL,
  payload: error,
});
