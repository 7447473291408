import {
  EXPORT_GRS_SUMMARY_BY_USER_ID,
  EXPORT_GRS_SUMMARY_BY_USER_ID_BEGIN,
  EXPORT_GRS_SUMMARY_BY_USER_ID_FAIL,
  EXPORT_GRS_SUMMARY_BY_USER_ID_SUCCESS,
  GET_GIFT_TRANSFER_HISTORY,
  GET_GIFT_TRANSFER_HISTORY_BEGIN,
  GET_GIFT_TRANSFER_HISTORY_BY_USER_ID,
  GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_BEGIN,
  GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_FAIL,
  GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_SUCCESS,
  GET_GIFT_TRANSFER_HISTORY_FAIL,
  GET_GIFT_TRANSFER_HISTORY_SUCCESS,
  GET_GRS_SUMMARY_BY_USER_ID,
  GET_GRS_SUMMARY_BY_USER_ID_BEGIN,
  GET_GRS_SUMMARY_BY_USER_ID_FAIL,
  GET_GRS_SUMMARY_BY_USER_ID_SUCCESS,
} from "./actionTypes";

export const getGiftTransferHistory = (request) => ({
  type: GET_GIFT_TRANSFER_HISTORY,
  request: request,
});

export const getGiftTransferHistoryBegin = () => ({
  type: GET_GIFT_TRANSFER_HISTORY_BEGIN,
});

export const getGiftTransferHistorySuccess = (giftTransfers) => ({
  type: GET_GIFT_TRANSFER_HISTORY_SUCCESS,
  payload: giftTransfers,
});

export const getGiftTransferHistoryFail = (error) => ({
  type: GET_GIFT_TRANSFER_HISTORY_FAIL,
  payload: error,
});

export const getGiftTransferHistoryByUserId = ({ request, userId }) => ({
  type: GET_GIFT_TRANSFER_HISTORY_BY_USER_ID,
  request: request,
  userId: userId,
});

export const getGiftTransferHistoryByUserIdBegin = () => ({
  type: GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_BEGIN,
});

export const getGiftTransferHistoryByUserIdSuccess = (giftTransfers) => ({
  type: GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_SUCCESS,
  payload: giftTransfers,
});

export const getGiftTransferHistoryByUserIdFail = (error) => ({
  type: GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_FAIL,
  payload: error,
});

export const getGRSSummaryByUserId = ({ request, userId }) => ({
  type: GET_GRS_SUMMARY_BY_USER_ID,
  request: request,
  userId: userId,
});

export const getGRSSummaryByUserIdBegin = () => ({
  type: GET_GRS_SUMMARY_BY_USER_ID_BEGIN,
});

export const getGRSSummaryByUserIdSuccess = (grsSummary) => ({
  type: GET_GRS_SUMMARY_BY_USER_ID_SUCCESS,
  payload: grsSummary,
});

export const getGRSSummaryByUserIdFail = (error) => ({
  type: GET_GRS_SUMMARY_BY_USER_ID_FAIL,
  payload: error,
});

export const exportGRSSummaryByUserId = ({ request, userId, exportParam }) => ({
  type: EXPORT_GRS_SUMMARY_BY_USER_ID,
  request: request,
  userId: userId,
  exportParam: exportParam,
});
export const exportGRSSummaryByUserIdBegin = () => ({
  type: EXPORT_GRS_SUMMARY_BY_USER_ID_BEGIN,
});

export const exportGRSSummaryByUserIdSuccess = (data) => ({
  type: EXPORT_GRS_SUMMARY_BY_USER_ID_SUCCESS,
  payload: data,
});

export const exportGRSSummaryByUserIdFail = (error) => ({
  type: EXPORT_GRS_SUMMARY_BY_USER_ID_FAIL,
  payload: error,
});
