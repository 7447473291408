import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import { postAtReportedCommentColumnData } from "../../../helpers/columns";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Datatable from "../../../components/UI/Tables/Datatable";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Common/Loader";
import {
  deletePostatReportedComment,
  getPostatReportedComments,
  resetPostatErrors,
} from "../../../store/postatManagement/actions";
import Block_icon from "../../../assets/icons/ebud-icons/Block.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";

import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import { blockUser } from "../../../store/users/actions";
import { checkUserPermission } from "../../../helpers/utils";

const PostatReportedComments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [imgUrl, setImgUrl] = useState("");
  const [isOpen, setOpen] = useState(false);
  const warningAlertRef = useRef();
  const basicRequest = {
    // sort: "transfered_date",
    // sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
  };
  const history = useHistory();
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const [request, setRequest] = useState({ ...basicRequest });
  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isDeleteable = userPermissions?.delete || false;
  const data = useSelector((state) => state?.ReportedPostatPosts);

  useEffect(() => {
    dispatch(getPostatReportedComments(request));
    return () => {
      dispatch(resetPostatErrors());
    };
  }, [request]);
  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Postat Management",
          "Reported comments"
        )
      );
    }
  }, [loginData]);

  const formatPostatReportedComments = useCallback(() => {
    return (
      data?.reportedComments &&
      data?.reportedComments?.postat_reports?.length > 0 &&
      data?.reportedComments?.postat_reports?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        post_owner_name: (
          <div data-tag="allowRowEvents" title={result?.post_owner_info?.name}>
            {result?.post_owner_info?.name}
          </div>
        ),
        reported_count: (
          <div data-tag="allowRowEvents" title={result?.reported_count}>
            {result?.reported_count}
          </div>
        ),
        reported_comment: (
          <div data-tag="allowRowEvents" title={result?.message}>
            {result?.message}
          </div>
        ),
        commenter: (
          <>
            <div data-tag="allowRowEvents" title={result?.reported_comment}>
              {result?.comment_owner_info?.name}
            </div>
            {result?.comment_owner_block_status && (
              <div className="badge bg-soft-secondary font-size-6 my-auto ">
                Blocked user
              </div>
            )}
          </>
        ),
        actions: (
          <div>
            <div className="cust-table-actions-wrap">
              {!result?.comment_owner_block_status && (
                <button
                  onClick={() =>
                    blockPromptHandler(
                      result?.comment_owner,
                      result.post_id,
                      result?.comment_id
                    )
                  }
                  className=" action-btn"
                  style={{
                    fontSize: "11px",
                    padding: "5px",
                    backgroundColor: "#dedfe0",
                    color: "#969696",
                    fontWeight: "bold",
                    opacity: !isDeleteable ? "50%" : "",
                    cursor: !isDeleteable ? "not-allowed" : "",
                  }}
                  title="Block Owner"
                  disabled={!isDeleteable}
                >
                  BLOCK AND DELETE
                </button>
              )}

              <button
                onClick={() =>
                  deletePromptHandler(result?.comment_id, result?.post_id)
                }
                className=" action-btn"
                title="Delete Post"
                disabled={!isDeleteable}
                style={{
                  fontSize: "11px",
                  padding: "5px",
                  backgroundColor: "#fadede",
                  fontWeight: "bold",
                  color: "#fa5f5f",
                  opacity: !isDeleteable ? "50%" : "",
                  cursor: !isDeleteable ? "not-allowed" : "",
                }}
              >
                DELETE
              </button>
            </div>
          </div>
        ),
      }))
    );
  }, [request, data?.reportedComments?.postat_reports, userPermissions]);
  const reportedComments = useMemo(
    () => formatPostatReportedComments(),
    [formatPostatReportedComments]
  );

  const deletePromptHandler = (commentId, postatId) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      commentId: commentId,
      postatId: postatId,
      content: `Are you sure you want to delete this Comment from the App?`,
      type: "delete",
    });
  };

  const blockPromptHandler = (userId, postId, commentId) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      userId: userId,
      postatId: postId,
      commentId: commentId,
      content: `Are you sure you want to block this user and delete the comment from the Postat?   `,
      type: "block-del",
    });
  };

  const okHandler = () => {
    promptMessage.type === "delete"
      ? dispatch(
          deletePostatReportedComment({
            commentId: promptMessage.commentId,
            postatId: promptMessage?.postatId,
            callback: () => dispatch(getPostatReportedComments()),
          })
        )
      : dispatch(
          blockUser({
            data: { blocked: true },
            id: promptMessage.userId,
          })
        ) &&
        dispatch(
          deletePostatReportedComment({
            commentId: promptMessage.commentId,
            postatId: promptMessage?.postatId,
            callback: () => dispatch(getPostatReportedComments()),
          })
        );
  };

  const tableRowClickHandler = (param) => {
    // history.push(`/payout-requests/${param.id}`);
    history.push({
      pathname: `/postat-reported-comments/${param.comment_id}/reports`,
      state: { postId: param.post_id, commentId: param.comment_id },
    });
  };

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        // id="userMgmtList"
        // ref={pageWrapRef}
      >
        <Container className="conatiner-fluid">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Reported Comments" />
            {data?.error && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color="danger"
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error}
                </UncontrolledAlert>
              </div>
            )}
            <div className="container-fluid">
              <Datatable
                tableID={"adminTable"}
                columns={postAtReportedCommentColumnData}
                rows={reportedComments}
                setRequest={setRequest}
                request={request}
                // dateFilter
                totalRecords={data?.reportedComments?.total}
                // search
                sp_search
                sp_search_param="commentOwnerId"
                // dateType="Purchased Date Range"
                // add
                // dropDown
                // languages={languages}
                // addNewClickHandler={addNewClickHandler}
                // selectedLanguage={selectedLanguage}
                // setSelectedLanguage={setSelectedLanguage}
                // addNewLabel={t("Admins.addAdmin.addBtn")}
                // searchTerm={data?.request?.keyword}
                // loading={data?.loading}
                tableRowClicked={(param) => tableRowClickHandler(param)}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
          {/* <PostPreview imgUrl={imgUrl} isOpen={isOpen} setOpen={setOpen} /> */}
        </Container>

        <ConfirmationAlert
          {...promptMessage}
          modal_center={showPromptPopUp}
          setmodal_center={setShowPromptPopUp}
          onOK={okHandler}
        />
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default PostatReportedComments;
