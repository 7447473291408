import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getLocalStorage } from "../../helpers/utils";
import { getRolePermission } from "../../store/actions";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const loginData = useSelector((state) => state.Login);
  const dispatch = useDispatch();
  const roleId = getLocalStorage("AUTH_DETAILS")?.user?.role?.id;

  useEffect(() => {
    if ((loginData?.rolePermissions && Object.keys(loginData?.rolePermissions).length === 0) && roleId) {
      dispatch(getRolePermission(roleId));
    }
  }, [loginData?.rolePermissions?.role]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !getLocalStorage("AUTH_DETAILS")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
