import {
  GET_USER_DATA,
  GET_USER_DATA_BEGIN,
  GET_USER_DATA_FAIL,
  GET_USER_DATA_SUCCESS,
  DELETE_USER,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  BLOCK_USER,
  BLOCK_USER_BEGIN,
  BLOCK_USER_SUCCESS,
  BLOCK_USER_FAIL,
  GET_USER_DETAILS,
  GET_USER_DETAILS_BEGIN,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_BEGIN,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAIL,
  STORE_USER_REQUEST,
  RESET_ERRORS,
  LOGOUT_USER,
  EXPORT_USER_EMAIL,
  EXPORT_USER_EMAIL_BEGIN,
  EXPORT_USER_EMAIL_SUCCESS,
  EXPORT_USER_EMAIL_FAIL,
  SEND_NOTIFICATION,
  SEND_NOTIFICATION_BEGIN,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION,
  GET_BLOCKED_USERS,
  GET_BLOCKED_USERS_BEGIN,
  GET_BLOCKED_USERS_SUCCESS,
  GET_BLOCKED_USERS_FAIL,
} from "./actionTypes";

/* ------------------------------------------- */

export const storeRequest = (request) => ({
  type: STORE_USER_REQUEST,
  payload: request,
});

/* ------------------------------------------- */

export const getUserData = (request) => ({
  type: GET_USER_DATA,
  payload: request,
});

export const getUserDataBegin = () => ({
  type: GET_USER_DATA_BEGIN,
});

export const getUserDataSuccess = (users) => ({
  type: GET_USER_DATA_SUCCESS,
  payload: users,
});

export const getUserDataFail = (error) => ({
  type: GET_USER_DATA_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const deleteUser = ({ data, callback }) => ({
  type: DELETE_USER,
  data: data,
  callback: callback,
});

export const deleteUserBegin = () => ({
  type: DELETE_USER_BEGIN,
});

export const deleteUserSuccess = (users) => ({
  type: DELETE_USER_SUCCESS,
  payload: users,
});

export const deleteUserFail = (error) => ({
  type: DELETE_USER_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const blockUser = ({ data, callback, id }) => ({
  type: BLOCK_USER,
  data: data,
  callback: callback,
  id: id,
});

export const blockUserBegin = () => ({
  type: BLOCK_USER_BEGIN,
});

export const blockUserSuccess = (users) => ({
  type: BLOCK_USER_SUCCESS,
  payload: users,
});

export const blockUserFail = (error) => ({
  type: BLOCK_USER_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const getUserDetails = (id) => ({
  type: GET_USER_DETAILS,
  payload: id,
});

export const getUserDetailBegin = () => ({
  type: GET_USER_DETAILS_BEGIN,
});

export const getUserDetailSuccess = (user) => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload: user,
});

export const getUserDetailFail = (error) => ({
  type: GET_USER_DETAILS_FAIL,
  payload: error,
});

/* ------------------------------------------- */

// Export user details

export const exportUserEmail = ({ saveAs }) => ({
  type: EXPORT_USER_EMAIL,
  saveAs: saveAs,
});

export const exportUserEmailBegin = () => ({
  type: EXPORT_USER_EMAIL_BEGIN,
});

export const exportUserEmailSuccess = (users) => ({
  type: EXPORT_USER_EMAIL_SUCCESS,
  payload: users,
});

export const exportUserEmailFail = (error) => ({
  type: EXPORT_USER_EMAIL_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const updateUserData = (data, file, callback) => ({
  type: UPDATE_USER_DETAILS,
  file: file,
  params: data,
  callback: callback,
});

export const updateUserDataBegin = () => ({
  type: UPDATE_USER_DETAILS_BEGIN,
});

export const updateUserDataSuccess = (user) => ({
  type: UPDATE_USER_DETAILS_SUCCESS,
  payload: user,
});

export const updateUserDataFail = (error) => ({
  type: UPDATE_USER_DETAILS_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const resetErrors = () => ({
  type: RESET_ERRORS,
});

/* ------------------------------------------- */

export const logoutUser = ({ data, callback }) => ({
  type: LOGOUT_USER,
  data: data,
  callback: callback,
});

/* ------------------------------------------- */

export const sendNotification = (data, callback) => ({
  type: SEND_NOTIFICATION,
  data: data,
  callback: callback,
});

export const sendNotificationBegin = () => ({
  type: SEND_NOTIFICATION_BEGIN,
});

export const sendNotificationSuccess = (notification) => ({
  type: SEND_NOTIFICATION_SUCCESS,
  payload: notification,
});

export const sendNotificationFail = (error) => ({
  type: SEND_NOTIFICATION_FAIL,
  payload: error,
});

export const updateNotification = (data, callback) => ({
  type: UPDATE_NOTIFICATION,
  data: data,
  callback: callback,
});

export const getBlockedUsers = (request) => ({
  type: GET_BLOCKED_USERS,
  payload: { request },
});

export const getBlockedUsersBegin = () => ({
  type: GET_BLOCKED_USERS_BEGIN,
});

export const getBlockedUsersSuccess = (blockedUsers) => ({
  type: GET_BLOCKED_USERS_SUCCESS,
  payload: blockedUsers,
});
export const getBlockedUsersFail = (error) => ({
  type: GET_BLOCKED_USERS_FAIL,
  payload: error,
});
