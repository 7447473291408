import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_ROLE_DATA,
  GET_ROLE_PERMISSIONS,
  GET_PERMISSIONS_MASTER_DATA,
  DELETE_ROLE,
  ADD_ROLE,
  UPDATE_ROLE_PERMISSIONS,
  GET_ADMINS_ROLE
} from "./actionTypes";

import {
  getRolesDataBegin,
  getRolesDataFail,
  getRolesDataSuccess,
  getRolePermissionBegin,
  getRolePermissionSuccess,
  getRolePermissionFail,
  getPermissionsMasterDataBegin,
  getPermissionsMasterDataSuccess,
  getPermissionsMasterDataFail,
  getAdminsRoleBegin,
  getAdminsRoleSuccess,
  getAdminsRoleFail,
  deleteRoleDataBegin,
  deleteRoleDataSuccess,
  deleteRoleDataFail,
  addRoleBegin,
  addRoleSuccess,
  addRoleFail,
  updateRolePermissionsBegin,
  updateRolePermissionsSuccess,
  updateRolePermissionsFail,
} from "./action";

import { get, update, del, post } from "../../../helpers/api_helper";
import {
  ADMIN_ROLES,
  ROLE_PERMISSIONS,
  ROLE_BY_ID,
  PERMISSIONS_MASTER_DATA,
  ADMINS_ROLE_BY_ID
} from "../../../helpers/url_helper";

function* getRoles({ payload }) {
  try {
    yield put(getRolesDataBegin());
    const response = yield call(get, ADMIN_ROLES, payload.request);
    yield put(getRolesDataSuccess(response.result));
  } catch (error) {
    yield put(getRolesDataFail(error));
  }
}

function* getPermissionsMasterData() {
  try {
    yield put(getPermissionsMasterDataBegin());
    const response = yield call(get, PERMISSIONS_MASTER_DATA);
    yield put(getPermissionsMasterDataSuccess(response.result));
  } catch (error) {
    yield put(getPermissionsMasterDataFail(error));
  }
}

function* getRolePermissions({ payload }) {
  try {
    yield put(getRolePermissionBegin());
    const response = yield call(get, ROLE_PERMISSIONS.replace("{id}", payload));
    yield put(getRolePermissionSuccess(response.result));
  } catch (error) {
    yield put(getRolePermissionFail(error));
  }
}

function* deleteRole({ data, callback }) {
  try {
    yield put(deleteRoleDataBegin());
    const response = yield call(del, ROLE_BY_ID.replace("{id}", data));
    callback && callback();
    yield put(deleteRoleDataSuccess(response.result));
  } catch (error) {
    yield put(deleteRoleDataFail(error));
  }
}

function* addRole({ data, callback }) {
  try {
    yield put(addRoleBegin());
    const response = yield call(post, ADMIN_ROLES, { ...data });
    callback && callback();
    yield put(addRoleSuccess(response.result));
  } catch (error) {
    yield put(addRoleFail(error));
  }
}

function* updateRolePermissions({ id, params, callback }) {
  try {
    yield put(updateRolePermissionsBegin());
    const response = yield call(update, ROLE_BY_ID.replace("{id}", id), {
      ...params,
    });
    yield put(updateRolePermissionsSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(updateRolePermissionsFail(error));
  }
}

function* getAdminsRole({ payload }) {
  try {
    yield put(getAdminsRoleBegin());
    const response = yield call(get, ADMINS_ROLE_BY_ID, payload.request);
    yield put(getAdminsRoleSuccess(response.result));
  } catch (error) {
    yield put(getAdminsRoleFail(error));
  }
}

function* rolePermissionsSaga() {
  yield takeLatest(GET_ROLE_DATA, getRoles);
  yield takeLatest(GET_PERMISSIONS_MASTER_DATA, getPermissionsMasterData);
  yield takeLatest(GET_ROLE_PERMISSIONS, getRolePermissions);
  yield takeLatest(GET_ADMINS_ROLE, getAdminsRole);
  yield takeLatest(DELETE_ROLE, deleteRole);
  yield takeLatest(ADD_ROLE, addRole);
  yield takeLatest(UPDATE_ROLE_PERMISSIONS, updateRolePermissions);
}

export default rolePermissionsSaga;
