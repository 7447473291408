import { call, put, takeLatest } from "redux-saga/effects";
import {
  updateFlixRateBegin,
  updateFlixRateSuccess,
  updateFlixRateFail,
  getFlixRateBegin,
  getFlixRateSuccess,
  getFlixRateFail,
} from "./action";

import { get, post } from "../../../helpers/api_helper";
import { GET_FLIX_RATE, UPDATE_FLIX_RATE } from "./actionTypes";

function* updateFlixRate({ payload, callback }) {
  try {
    yield put(updateFlixRateBegin());

    const response = yield call(
      post,
      "/admin/users/userflaxrates/rateupdate",
      payload
    );

    if (callback) {
      callback();
    }

    yield put(updateFlixRateSuccess(response.message));
  } catch (error) {
    yield put(updateFlixRateFail(error));
  }
}
function* getFlixRate({ payload }) {
  try {
    yield put(getFlixRateBegin());
    const response = yield call(
      get,
      "/admin/users/userflaxrates/rateupdate",
      payload
    );
    yield put(getFlixRateSuccess(response.result));
  } catch (error) {
    yield put(getFlixRateFail(error));
  }
}

function* updateFlixRateSaga() {
  yield takeLatest(UPDATE_FLIX_RATE, updateFlixRate);
  yield takeLatest(GET_FLIX_RATE, getFlixRate);
}
export default updateFlixRateSaga;
