import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Row } from "reactstrap";
import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { coinsPurchaseColumnData } from "../../../helpers/columns";
import { convertTime, convertToUAETime } from "../../../helpers/utils";
import {
  DATE_WITH_TIME,
  DATE_WITH_TIME_IN_SECONDS,
} from "../../../helpers/constants";
import { getFlaxPurchaseHistory } from "../../../store/flaxPackages/actions";
import { getCoinsPurchaseHistory } from "../../../store/CoinsPurchaseHistory/actions";
import Loader from "../../../components/Common/Loader";

const GiftPurchaseHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const basicRequest = {
    // sort: "transfered_date",
    // sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  useEffect(() => {
    request && dispatch(getCoinsPurchaseHistory(request));
  }, [request]);

  const data = useSelector((state) => state.coinsPurchase);

  const formatCoinPurchaseData = useCallback(() => {
    return (
      data?.coinsPurchaseHistory?.coin_purchase &&
      data?.coinsPurchaseHistory?.coin_purchase?.length &&
      data?.coinsPurchaseHistory?.coin_purchase?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,
        username: (
          <div data-tag="allowRowEvents" title={result?.name}>
            {result?.name}
          </div>
        ),
        coins: (
          <div data-tag="allowRowEvents" title={result?.coins}>
            {result?.coins}
          </div>
        ),
        device_type: (
          <div data-tag="allowRowEvents" title={result?.device_type}>
            {result?.device_type}
          </div>
        ),
        purchased_on: (
          <div data-tag="allowRowEvents" title={result?.time_created}>
            {convertTime(result?.time_created)}
          </div>
        ),
      }))
    );
  });
  const coinsPurchaseHistory = useMemo(
    () => formatCoinPurchaseData(),
    [formatCoinPurchaseData]
  );
  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        // id="userMgmtList"
        // ref={pageWrapRef}
      >
        <Container>
          <Row>
            <Breadcrumbs
              title="Tables"
              breadcrumbItem="Coins Purchase History"
            />
            <div className="container-fluid">
              <Datatable
                tableID={"adminTable"}
                columns={coinsPurchaseColumnData}
                rows={coinsPurchaseHistory}
                setRequest={setRequest}
                request={request}
                // dateFilter
                // dateType="Purchased Date Range"
                totalRecords={data?.coinsPurchaseHistory?.total}
                search
                // add
                // dropDown
                // languages={languages}
                // addNewClickHandler={addNewClickHandler}
                // selectedLanguage={selectedLanguage}
                // setSelectedLanguage={setSelectedLanguage}
                // addNewLabel={t("Admins.addAdmin.addBtn")}
                // searchTerm={data?.request?.keyword}
                // loading={data?.loading}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
        </Container>
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default GiftPurchaseHistory;
