import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import user4 from "../../../assets/images/users/user.svg";

import { removeItem, getLocalStorage } from "../../../helpers/utils";

import { logoutUser } from "../../../store/users/actions";

const ProfileMenu = () => {
  const [menu, setMenu] = useState(false);

  const history = useHistory();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const auth = getLocalStorage("AUTH_DETAILS");
  const logout = () => {
    dispatch(
      logoutUser({
        data: { access_token: auth?.access_token },
        callback: () => {
          removeItem("AUTH_DETAILS");
          history.push("/login");
        },
      })
    );
  };
  
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            alt="Header Avatar"
            src={auth?.user?.profile?.profile_photo || user4}
          />
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
            {auth?.user?.username || auth?.user?.email}
          </span>
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <div className="dropdown-item" onClick={() => logout()}>
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{t("Logout")}</span>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
