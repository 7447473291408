import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, UncontrolledAlert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Datatable from "../../../../components/UI/Tables/Datatable";

import { getSubscriptionDetails } from "../../../../store/users/userSubscriptions/action";

import { subscriptionColumnData } from "../../../../helpers/columns";
import { convertToUAETime } from "../../../../helpers/utils";
import { DATE_WITH_TIME, FE_DATE_FORMAT } from "../../../../helpers/constants";
import Loader from "../../../../components/Common/Loader";

const UserSubscription = ({ userError, activeTab }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useParams();

  let subscriptionDetails = useSelector((state) => state.subscriptionDetails);

  const basicRequest = {
    page: 1,
    page_count: 50,
  };

  const [request, setRequest] = useState({ ...basicRequest });

  useEffect(() => {
    if (activeTab == "3") {
      dispatch(getSubscriptionDetails(match.id, { ...request }));
    }
  }, [request, activeTab]);

  const formatUserData = useCallback(() => {
    return subscriptionDetails?.subscriptionDetails?.payment_info?.map(
      (record, index) => ({
        ...record,
        payment_amount: (
          <div>
            {record.currency} {record.payment_amount}
          </div>
        ),
        payment_platform: <div>{record.device_type}</div>,
        payment_expiry:
          record.expiration_date &&
          convertToUAETime(record.expiration_date, DATE_WITH_TIME),
        payment_date:
          record.payment_date &&
          convertToUAETime(record.payment_date, FE_DATE_FORMAT),
        payment_status: <div>{record.status}</div>,
      })
    );
  });

  //memoized Payment data
  const PaymentData = useMemo(() => formatUserData(), [formatUserData]);
  return (
    <>
      {userError || subscriptionDetails?.error ? (
        <div>
          <UncontrolledAlert
            color="danger"
            className="alert-dismissible fade show"
            role="alert"
          >
            {userError || subscriptionDetails?.error}
          </UncontrolledAlert>
        </div>
      ) : (
        <Col xl="12">
          <Row className="col-md-12 m-0">
            <div className=" mb-4">
              <div className="col-md-12">
                <h5
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {t("subscription.title")}
                </h5>
              </div>

              <div className="col-md-3  subscribedOnWrap pt-4">
                <label
                  style={{
                    color: "black",
                  }}
                  className=""
                >
                  {t("subscription.subTitle")}
                </label>
                <div className="text-muted">
                  <strong>
                    {subscriptionDetails?.subscriptionDetails?.subscribed_on &&
                      convertToUAETime(
                        subscriptionDetails?.subscriptionDetails?.subscribed_on,
                        DATE_WITH_TIME
                      )}
                  </strong>
                </div>
              </div>
              <div className="col-md-3 subscribedOnWrap pt-4">
                <label
                  style={{
                    color: "black",
                  }}
                  className=""
                >
                  Current Subscription Status:
                </label>
                <div className="text-muted">
                  <div
                    class={
                      "badge bg-soft-" +
                      (subscriptionDetails?.subscriptionDetails?.status &&
                      subscriptionDetails?.subscriptionDetails?.status ===
                        "Active"
                        ? "green "
                        : "red ") +
                      "font-size-13"
                    }
                    data-tag="allowRowEvents"
                  >
                    {subscriptionDetails?.subscriptionDetails?.status &&
                      subscriptionDetails?.subscriptionDetails?.status}{" "}
                  </div>
                </div>
              </div>
            </div>
            <Datatable
              title={t("subscription.table")}
              tableID={"subscriptionTable"}
              columns={subscriptionColumnData}
              rows={PaymentData}
              setRequest={setRequest}
              request={request}
              totalRecords={subscriptionDetails?.total}
              loading={subscriptionDetails?.loading}
            />
          </Row>
        </Col>
      )}
      {subscriptionDetails?.loading && <Loader darkBg={true} />}
    </>
  );
};

export default UserSubscription;
