import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { Button } from "semantic-ui-react";
import { useManualPP } from "./useManualPP";
import { useEffect } from "react";

export const ManualPPForm = (props) => {
  const { onFormFieldChange, onSubmit, formData } = useManualPP(props.userId, true, props.transactionType);

  return (
    <Form>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="amount">The amount to be added</Label>
            <Input
              id="Amount"
              name="pp"
              placeholder="Amount"
              type="number"
              onChange={onFormFieldChange}
              value={formData.pp}
              invalid={!!formData.validation.pp}
            />
            <span className="text-danger">{formData.validation.pp}</span>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label for="addedBy">Added by</Label>
            <Input
              id="addedBy"
              name="addedBy"
              placeholder="Added By"
              type="string"
              disabled
              value={formData.user.name}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col sm="12">
          <FormGroup>
            <Label for="comment">Comments</Label>
            <Input
              id="comment"
              name="comments"
              type="textarea"
              value={formData.comments}
              onChange={onFormFieldChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col sm="12" style={{ textAlign: "center" }}>
          <Button style={{ background: "#F3D802" }} onClick={onSubmit} disabled={props.loading || !formData.pp || !!formData.validation.pp}>
            {" "}
            {props.addLoading ? "Saving..." : "Save"}{" "}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}