import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../components/UI/Button";
import { EMAIL_REGEX } from '../../../../helpers/constants';

import { checkUsernameAvailability, resetErrorWithUsername } from '../../../../store/usernames/allocateUserNames/action';

const UpdateAllocatedName = ({ userId, name,
    username,
    status,
    suggestedUserName,
    modal_center,
    setmodal_center,
    title,
    onOK,
    confirmationMessage }) => {

    const [suggestName, setSuggestName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [warning, setWarning] = useState("")

    const data = useSelector(state => state.allocatedUsername);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        (status === 'Pending' || status === 'Rejected') ? setSuggestName(suggestedUserName) : setSuggestName('')
    }, [suggestedUserName, status])

    useEffect(() => {
        (suggestName && suggestName === username) ? setErrorMessage('Username is similar to previous Username') : setErrorMessage('');

        if (suggestName && new RegExp(EMAIL_REGEX).test(suggestName)) {
            setWarning(t('AllocateUserName.formatWarn'))
        } else setWarning("")
    }, [suggestName])




    const checkAvailabiltyHandler = () => {
        dispatch(checkUsernameAvailability({ username: suggestName, user_id: userId }))
    }
    return (
        <div>
            <Modal isOpen={modal_center} centered={true} onClosed={() => {
                (status === 'Pending' || status === 'Rejected') ? setSuggestName(suggestedUserName) : setSuggestName('')
                dispatch(resetErrorWithUsername());
            }}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0">{title}</h5>
                    <button
                        type="button"
                        onClick={() => setmodal_center(false)}
                        className="close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div>
                        <div>{confirmationMessage}</div>
                        <div className="updateNameWrap">
                            <label>{(status === 'Pending') || (status === 'Rejected') ? 'Suggested Username' : (status === 'Forcefully Changed') || (status === 'Accepted') || (status === 'Deleted') ? 'New Username' : null}</label>
                            {data?.loading && <span className="spinnerClass"><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>}
                            <div className="updateNameInputWrap">
                                <input type="text"
                                    className="inputField_CSS"
                                    onChange={(e) => { dispatch(resetErrorWithUsername()); setSuggestName(e.target.value) }}
                                    value={suggestName}
                                    maxLength="50"
                                    placeholder={t('ReserveUserName.usernamePlaceholder')}
                                />
                                <button
                                    className="btn waves-effect waves-light cust_no_shadow check_availability_button"
                                    disabled={suggestName === suggestedUserName || !suggestName || suggestName === username || data?.loading}
                                    onClick={() => checkAvailabiltyHandler()}
                                >CHECK AVAILABILITY</button>
                            </div>
                            <div className="color-red">{errorMessage || data?.availableError}</div>
                            <div className="color-green">{data?.available}</div>
                            {warning && <p className="bg-soft-orange warningSection"><i className="fa fa-exclamation-triangle warningIcon"></i>{warning}</p>}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        buttonClass={
                            "btn bgcolor-btn-green"
                        }
                        label={t("Common.yes")}
                        handleClick={() => {
                            onOK(suggestName);
                            setmodal_center(false);
                            setSuggestName('');
                        }}
                        disableBtn={suggestedUserName !== suggestName && (!data?.available || data?.availableUsername !== suggestName)}
                    />
                    <Button
                        buttonClass={
                            "btn btn-light"
                        }
                        label={t("Common.no")}
                        handleClick={() => {
                            setmodal_center(false);
                        }
                        }
                    />
                </div>
            </Modal>
        </div >
    )
}

export default UpdateAllocatedName
