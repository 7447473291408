import {
  ADD_GLOBAL_PAYMENT_SYSTEM_BEGIN,
  ADD_GLOBAL_PAYMENT_SYSTEM_FAIL,
  ADD_GLOBAL_PAYMENT_SYSTEM_SUCCESS,
  DELETE_GLOBAL_PAYMENT_SYSTEM_BEGIN,
  DELETE_GLOBAL_PAYMENT_SYSTEM_FAIL,
  DELETE_GLOBAL_PAYMENT_SYSTEM_SUCCESS,
  EDIT_GLOBAL_PAYMENT_SYSTEM_BEGIN,
  EDIT_GLOBAL_PAYMENT_SYSTEM_FAIL,
  EDIT_GLOBAL_PAYMENT_SYSTEM_SUCCESS,
  GET_GLOBAL_PAYMENT_SYSTEMS_BEGIN,
  GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_BEGIN,
  GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_FAIL,
  GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_SUCCESS,
  GET_GLOBAL_PAYMENT_SYSTEMS_FAIL,
  GET_GLOBAL_PAYMENT_SYSTEMS_SUCCESS,
  RESET_GLOBAL_PAYMENT_SYSTEM_ERRORS,
} from "./actionTypes";

export const INIT_STATE = {
  globalPaymentSystems: {},
  globalPaymentSystemData: {},
  error: "",
  loading: false,
  request: {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    page_count: 50,
    keyword: "",
    success: "",
  },
};

const GlobalPaymentSystems = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GLOBAL_PAYMENT_SYSTEMS_BEGIN:
      return {
        ...state,
        loading: true,
        globalPaymentSystems: {},
        huddleMessages: "",
      };
    case GET_GLOBAL_PAYMENT_SYSTEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        globalPaymentSystems: action.payload,
        error: "",
      };
    case GET_GLOBAL_PAYMENT_SYSTEMS_FAIL:
      return {
        ...state,
        loading: false,
        globalPaymentSystems: {},
        error: action.payload,
      };

    case GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        globalPaymentSystemData: {},
        huddleMessages: "",
      };
    case GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        globalPaymentSystemData: action.payload,
        error: "",
      };
    case GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        globalPaymentSystemData: {},
        error: action.payload,
      };

    case ADD_GLOBAL_PAYMENT_SYSTEM_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_GLOBAL_PAYMENT_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: "",
      };
    case ADD_GLOBAL_PAYMENT_SYSTEM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_GLOBAL_PAYMENT_SYSTEM_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_GLOBAL_PAYMENT_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case DELETE_GLOBAL_PAYMENT_SYSTEM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_GLOBAL_PAYMENT_SYSTEM_ERRORS:
      return {
        ...state,
        error: "",
        success: "",
      };
    default:
      return state;
  }
};

export default GlobalPaymentSystems;
