import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";

const AdminsFilter = ({
  setRequest,
  request,
  adminStatusFilter,
  adminRoleFilter,
}) => {
  const [admin_filter_active, set_admin_filter_active] = useState("Active");
  const [admin_filter_deleted, set_admin_filter_deleted] = useState(false);
  const [admin_filter_blocked, set_admin_filter_blocked] = useState(false);
  const [roleList_filter, set_roleList_filter] = useState({});

  const [modal_center, setmodal_center] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    adminStatusFilter?.includes("Active")
      ? set_admin_filter_active("Active")
      : set_admin_filter_active(false);
    adminStatusFilter?.includes("Deleted")
      ? set_admin_filter_deleted("Deleted")
      : set_admin_filter_deleted(false);
    adminStatusFilter?.includes("Blocked")
      ? set_admin_filter_blocked("Blocked")
      : set_admin_filter_blocked(false);
  }, [adminStatusFilter, modal_center]);

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const filter_clearAll = () => {
    set_admin_filter_active(false);
    set_admin_filter_deleted(false);
    set_admin_filter_blocked(false);
    set_roleList_filter({});
  };

  const handleApply = () => {
    setRequest({
      ...request,
      status_filter: [
        admin_filter_active,
        admin_filter_deleted,
        admin_filter_blocked,
      ].filter((item) => item),
      role_filter: Object.entries(roleList_filter)
        .filter((item) => item[1])
        .map((role) => role[0]),
    });
    setmodal_center(false);
  };

  const adminRoleChange = (event) => {
    set_roleList_filter({
      ...roleList_filter,
      [`${event.target.id}`]: event.target.checked,
    });
  };

  return (
    <div className="user-filterBtn-wrap" data-testid="component-filterPopup">
      <button
        type="button"
        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
        onClick={() => {
          tog_center();
        }}
        data-testid="component-filterButton"
      >
        <i
          style={{ paddingRight: "10px" }}
          className="fa fa-filter"
          aria-hidden="true"
        ></i>
        {t("Admins.filter.title")}
      </button>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={true}
      >
        <div className="modal-header userList-filter-header">
          <h5 className="modal-title mt-0">{t("Admins.filter.title")}</h5>
          <button
            onClick={() => filter_clearAll()}
            className="btn waves-effect waves-light cust_no_shadow userList-filter-clearAll"
          >
            {t("Admins.filter.clear")}
          </button>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body userList-filter-body">
          <div className="userList-filter-SectionWrap">
            <h5 className="userList-filter-SectionTitle">
              {t("Admins.filter.role")}
            </h5>
            <div className="adminList-filter-checkBoxWrap">
              {adminRoleFilter?.map((roleList) => {
                return (
                  <div key={roleList.id}>
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      value={roleList_filter[roleList.id] || false}
                      id={roleList.id}
                      name={roleList?.name}
                      onChange={(event) => adminRoleChange(event)}
                      checked={roleList_filter[`${roleList.id}`] || false}
                    />
                    <label className="form-check-label" htmlFor={roleList.id}>
                      {roleList?.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="userList-filter-SectionWrap">
            <h5 className="userList-filter-SectionTitle">
              {t("Admins.filter.status")}
            </h5>
            <div className="adminList-filter-checkBoxWrap">
              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={admin_filter_active}
                  id="admin_filter_active"
                  onChange={() =>
                    admin_filter_active
                      ? set_admin_filter_active(!admin_filter_active)
                      : set_admin_filter_active("Active")
                  }
                  checked={admin_filter_active}
                />
                <label
                  className="form-check-label"
                  htmlFor="admin_filter_active"
                >
                  {t("Admins.filter.active")}
                </label>
              </div>

              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={admin_filter_deleted}
                  id="admin_filter_deleted"
                  onChange={() =>
                    admin_filter_deleted
                      ? set_admin_filter_deleted(!admin_filter_deleted)
                      : set_admin_filter_deleted("Deleted")
                  }
                  checked={admin_filter_deleted}
                />
                <label
                  className="form-check-label"
                  htmlFor="admin_filter_deleted"
                >
                  {t("Admins.filter.deleted")}
                </label>
              </div>

              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={admin_filter_blocked}
                  id="admin_filter_blocked"
                  onChange={() =>
                    admin_filter_blocked
                      ? set_admin_filter_blocked(!admin_filter_blocked)
                      : set_admin_filter_blocked("Blocked")
                  }
                  checked={admin_filter_blocked}
                />
                <label
                  className="form-check-label"
                  htmlFor="admin_filter_blocked"
                >
                  {t("Admins.filter.blocked")}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              tog_center();
            }}
          >
            {t("Admins.filter.cancel")}
          </button>
          <button
            type="button"
            className="btn bgcolor-btn-green"
            onClick={() => handleApply()}
          >
            {t("Admins.filter.apply")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminsFilter;
