import {
  ADD_MANUAL_PP_BEGIN,
  ADD_MANUAL_PP_SUCCESS,
  ADD_MANUAL_PP_ERROR,
  DELETE_MANUAL_PP_BEGIN,
  DELETE_MANUAL_PP_SUCCESS,
  DELETE_MANUAL_PP_ERROR,
  GET_MANUAL_PP_BEGIN,
  GET_MANUAL_PP_ERROR,
  GET_MANUAL_PP_SUCCESS,
  RESET_MANUAL_PP_STATES,
} from "./actionTypes";

const initialState = {
  manualPPs: [],
  loading: false,
  error: "",
  addLoading: false,
  success: "",
  userId: "",
  loader: {},
};

function manualPPReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MANUAL_PP_BEGIN:
      let message = state.success;
      if (state.userId !== action.payload.user_id?.toString()) {
        message = "";
      }

      return {
        ...state,
        loading: true,
        error: "",
        manualPPs: [],
        userId: action.payload.user_id,
        success: message,
      };
    case GET_MANUAL_PP_SUCCESS:
      return { ...state, manualPPs: action.payload, loading: false, error: "" };
    case GET_MANUAL_PP_ERROR:
      return {
        ...state,
        loading: false,
        manualPPs: [],
        error: action.payload,
        success: "",
      };
    case ADD_MANUAL_PP_BEGIN:
      return { ...state, addLoading: true, error: "", success: "" };
    case ADD_MANUAL_PP_SUCCESS:
      return {
        ...state,
        addLoading: false,
        error: "",
        success: action.payload.message,
      };
    case ADD_MANUAL_PP_ERROR:
      return {
        ...state,
        addLoading: false,
        error: "Something went wrong",
        success: "",
      };
    case DELETE_MANUAL_PP_BEGIN:
      return { ...state, deleteLoading: true, error: "", success: "" };
    case DELETE_MANUAL_PP_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        error: "",
        success: action.payload.message,
      };
    case DELETE_MANUAL_PP_ERROR:
      return {
        ...state,
        deleteLoading: false,
        error: action.payload,
        success: "",
      };
    case RESET_MANUAL_PP_STATES:
      return { ...state, error: "", success: "" };

    default:
      return state;
  }
}

export default manualPPReducer;
