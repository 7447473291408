import React from "react";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";

import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";

import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";
import { paymentFormsColumnData } from "../../../helpers/columns";
import { getPaymentMethods } from "../../../store/paymentManagement/actions";
import {
  deleteGlobalPaymentSystem,
  getGlobalPaymentSystems,
} from "../../../store/GlobalPaymentManagement/actions";

const GlobalPaymentSystem = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["Active"],
    page_count: 50,
    keyword: "",
  };
  const [request, setRequest] = useState(basicRequest);
  const pageWrapRef = useRef(null);
  const warningAlertRef = useRef(null);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const data = useSelector((state) => state?.GlobalPaymentSystems);

  useEffect(() => {
    dispatch(getGlobalPaymentSystems(request));
  }, [request]);

  const formatPaymentFormsData = useCallback(() => {
    return (
      data?.loading === false &&
      data?.globalPaymentSystems?.length &&
      data?.globalPaymentSystems?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        payment_method: (
          <div data-tag="allowRowEvents" title={result?.payment_method}>
            {result?.payment_method}
          </div>
        ),
        country: (
          <div data-tag="allowRowEvents" title={result?.country_name}>
            {result?.country_name}
          </div>
        ),
        // icon: (
        //   <div data-tag="allowRowEvents" title={result?.payment_method}>
        //     <img width={28} src={result?.icon} alt="gift" />
        //     {}
        //   </div>
        // ),

        actions: (
          <div className="cust-table-actions-wrap">
            <button
              className=" color-violet action-btn"
              title="Edit"
              onClick={() =>
                history.push({
                  pathname: `/payment-forms-edit/${result?.payment_method_id}/${result?.country_id}`,
                  state: { id: result.payment_method_identifier },
                })
              }
              // disabled={!isEditable}
            >
              <img
                src={Edit_icon}
                alt="Edit"
                // style={{
                //   opacity: !isEditable ? "50%" : "",
                //   cursor: !isEditable ? "not-allowed" : "",
                // }}
              />
            </button>
            <button
              onClick={() =>
                deletePromptHandler({
                  payment_method_identifier: result?.payment_method_identifier,
                  country_id: result?.country_id,
                  country_name: result?.country_name,
                  payment_method: result?.payment_method,
                })
              }
              className=" color-red action-btn"
              title="Delete"
              // disabled={!isDeleteable}
            >
              <img
                src={Delete_icon}
                alt="Delete"
                // style={{
                //   opacity: !isDeleteable ? "50%" : "",
                //   cursor: !isDeleteable ? "not-allowed" : "",
                // }}
              />
            </button>
          </div>
        ),
      }))
    );
  }, [request, data?.globalPaymentSystems]);
  const paymentFormsData = useMemo(
    () => formatPaymentFormsData(),
    [formatPaymentFormsData]
  );

  const deletePromptHandler = ({
    payment_method_identifier,
    country_id,
    country_name,
    payment_method,
  }) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      payment_method_identifier: payment_method_identifier,
      id: country_id,
      content: `Are you sure you want to delete 
      Payment method ${payment_method} from ${country_name}?`,
      type: "delete",
    });
  };
  const okHandler = () => {
    dispatch(
      deleteGlobalPaymentSystem({
        payment_method_identifier: promptMessage?.payment_method_identifier,
        country_id: promptMessage?.id,
        callback: () => dispatch(getGlobalPaymentSystems()),
      })
    );
  };

  const addNewClickHandler = () => {
    history.push("/payment-forms-addNew");
  };
  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Payment Forms" />
          <div className="container">
            <Datatable
              tableID={"adminTable"}
              columns={paymentFormsColumnData}
              rows={paymentFormsData}
              // setRequest={setRequest}
              // request={request}
              // totalRecords={data.adminData?.total}
              // search
              add
              addNewClickHandler={addNewClickHandler}
              addNewLabel={t("Admins.addAdmin.addBtn")}
              // searchTerm={data?.request?.keyword}
              // loading={data?.loading}
              tableCardClassName={"snoTable"}
            />
          </div>
        </Row>
      </Container>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default GlobalPaymentSystem;
