import React from "react";
import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";

const LinkedTo = ({ selected, selectOption }) => {
  const { t } = useTranslation();

  return (
    <Row className="col-md-12 mb-4">
      <div className="col-md-4">
        <div className="form-check">
          <input
            className="form-check-input cursor-pointer"
            type="radio"
            name="allocateNameLinkedTo"
            id="allocate_username"
            value="1"
            checked={selected === "1"}
            onChange={(e) => selectOption(e)}
          />
          <label className="form-check-label" htmlFor="allocate_username">
            {t("AllocateUserName.UserName")}
          </label>
        </div>
      </div>

      <div className="col-md-4">
        <div className="form-check">
          <input
            className="form-check-input cursor-pointer"
            type="radio"
            name="allocateNameLinkedTo"
            id="allocate_mobileNo"
            value="2"
            onChange={(e) => selectOption(e)}
            checked={selected === "2"}
          />
          <label className="form-check-label" htmlFor="allocate_mobileNo">
            {t("ReserveUserName.mobile")}
          </label>
        </div>
      </div>

      <div className="col-md-4">
        <div className="form-check">
          <input
            className="form-check-input cursor-pointer"
            type="radio"
            name="allocateNameLinkedTo"
            id="allocate_email"
            value="3"
            checked={selected === "3"}
            onChange={(e) => selectOption(e)}
          />
          <label className="form-check-label" htmlFor="allocate_email">
            {t("AllocateUserName.EmailAddress")}
          </label>
        </div>
      </div>
    </Row>
  );
};

export default LinkedTo;
