import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import { connect } from "react-redux";

//Import Components
import MiniWidget from "./mini-widget";
import Breadcrumbs from "../../components/UI/Breadcrumb";
import Loader from "../../components/Common/Loader";

// actions
import { getDashboardData } from "../../store/dashboard/actions";
import StatiticsChart from "./StatiticsChart";

const Dashboard = (props) => {
  console.log("props", props?.data);
  useEffect(() => {
    props.getDashboardData();
  }, []);
  return (
    <div data-testid="component-dashboard">
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Dashboard" />
          <Row>
            {props?.data?.length
              ? props?.data?.map((section, index) => {
                  if (section[0]?.title !== "Active PP") {
                    return <MiniWidget key={index} reports={section} />;
                  }
                  return null;
                })
              : null}
          </Row>
          {/* <Row>
            <Col>
              <Card>
                <CardBody>
                  {props?.data?.length ? <StatiticsChart /> : null}
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
        {props?.loading && <Loader darkBg={true} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { ...state.dashboard };
};

export default connect(mapStateToProps, { getDashboardData })(Dashboard);
