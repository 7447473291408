import React, { useRef, useState, useEffect } from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import Datatable from "../../../../components/UI/Tables/Datatable";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  huddleStatisticsColumn,
  groupStatisticsColumn,
} from "../../../../helpers/columns";
import {
  getHuddleStatistics,
  resetHuddlesErrors,
} from "../../../../store/huddles/actions";
import { useSelector, useDispatch } from "react-redux";
import premiumImage from "../../../../assets/icons/premium.svg";
import defaultProfile from "../../../../assets/images/users/user.svg";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../../helpers/constants";
import { convertToUAETime } from "../../../../helpers/utils";
import Loader from "../../../../components/Common/Loader";

export const HuddleStatistics = ({ type, userId, tab }) => {
  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    membership_filter: [""],
    page_count: 50,
    keyword: "",
    user_id: userId,
    type,
    tab,
  };
  const data = useSelector((state) => state.Huddles);
  const [request, setRequest] = useState({ ...basicRequest });
  const warningAlertRef = useRef();
  const pageWrapRef = useRef();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    request && dispatch(getHuddleStatistics(request));
    return () => {
      dispatch(resetHuddlesErrors());
    };
  }, [request]);

  const tableRowClickHandler = (param) => {
    const baseUrl = type === "private" ? "/group-details" : "/huddle-details";
    history.push(
      `${baseUrl}/${param.id}?return=${location.pathname}${location.search}`
    );
  };

  const huddlesData = data?.huddles?.huddles?.map((huddles, index) => ({
    ...huddles,
    no: (request?.page - 1) * request?.page_count + index + 1,
    createdOn: convertToUAETime(
      huddles.time_created,
      DATE_WITH_TIME_IN_SECONDS
    ),
    profile_image: (
      <div className="profileWrap" data-tag="allowRowEvents">
        {huddles.manager_premium_status ? (
          <div className="huddle_premium_icon" data-tag="allowRowEvents">
            <img src={premiumImage} data-tag="allowRowEvents" />
          </div>
        ) : null}

        <div className="d-flex">
          <div data-tag="allowRowEvents">
            <img
              data-tag="allowRowEvents"
              className="table_profileImg"
              src={
                huddles?.group_photo === null
                  ? defaultProfile
                  : huddles?.huddle_profile_image
              }
            />
          </div>

          <div
            className="mx-2 mt-3"
            title={huddles.huddle_name}
            data-tag="allowRowEvents"
          >
            {huddles.huddle_name}{" "}
            {huddles.tribe === true && (
              <>
                <div className="badge bg-warning font-size-12">Tribe</div>
              </>
            )}
          </div>
        </div>
      </div>
    ),
    manager: (
      <div data-tag="allowRowEvents" title={huddles.created_by}>
        {huddles.created_by}
      </div>
    ),
    total_posts: (
      <div data-tag="allowRowEvents" title={huddles.time_created}>
        {huddles?.total_posts}
      </div>
    ),
    user_posts: (
      <div data-tag="allowRowEvents" title={huddles.report_count}>
        {huddles?.user_posts}
      </div>
    ),
  }));

  return (
    <div
      className={userId || "page-content"}
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          {data?.error && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            </div>
          )}
          {data?.success && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="success"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.success}
              </UncontrolledAlert>
            </div>
          )}

          {!data.loading && (
            <Datatable
              tableID={"adminTable"}
              columns={
                tab === "created_groups" || tab === "joined_groups"
                  ? groupStatisticsColumn
                  : huddleStatisticsColumn
              }
              rows={huddlesData}
              setRequest={setRequest}
              request={request}
              totalRecords={data?.huddles?.total}
              search
              huddleFilter
              tableRowClicked={(param) => tableRowClickHandler(param)}
              addNewLabel={t("Admins.addAdmin.addBtn")}
              searchTerm={data?.request?.keyword}
              reqstatusFilter={request?.status_filter}
              reqmembershipFilter={request?.membership_filter}
              loading={data?.loading}
              tableCardClassName={"snoTable"}
            />
          )}
          {data.loading && <Loader />}
        </Row>
      </Container>
    </div>
  );
};
