import React, { useState, useEffect, useRef } from "react";
import { Col, Card, CardTitle, UncontrolledAlert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory, Prompt } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import parsePhoneNumber from 'libphonenumber-js/mobile'

import BackButton from "../../../../components/UI/BackButton";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../../components/UI/ConfirmationAlert";
import Loader from "../../../../components/Common/Loader";

import UserDetail from "../Shared/UserDetail";
import GetUserForm from "./GetUserForm";
import AllocateForm from "./AllocateForm";
import LinkedTo from "./LinkedTo";

import { EMAIL_REGEX } from '../../../../helpers/constants';

import { getUser, allocateUsername, checkUsernameAvailability, resetError, resetErrorWithUsername } from "../../../../store/usernames/allocateUserNames/action";

const AllocateAddNew = () => {
  const [selected, setSelected] = useState("1");
  const [getUserDisabled, setGetUserDisabled] = useState(true);
  const [mobile, setMobile] = useState();
  const [countryCode, setCountryCode] = useState("+91")
  const [newUsername, setNewUsername] = useState();
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [warning, setWarning] = useState("");
  const [backToListing, setBackToListing] = useState(false)
  const [formChanged, setFormChanged] = useState(false)
  const [searchUsername, setSearchUsername] = useState('')

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.allocatedUsername);
  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.success, data?.error])

  const scrollToAlertMessage = () => {
    if (data?.success || data?.error) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  }

  useEffect(() => {
    if (newUsername) {
      setFormChanged(true)
      if (new RegExp(EMAIL_REGEX).test(newUsername)) {
        setWarning(t('AllocateUserName.formatWarn'))
      }
    } else setWarning("")
  }, [newUsername])

  useEffect(() => {
    mobile && parsePhoneNumber(`${countryCode}${mobile}`)?.isValid()
      ? setGetUserDisabled(false)
      : setGetUserDisabled(true);
  }, [mobile, countryCode]);

  useEffect(() => {
    if (backToListing) {
      dispatch(resetErrorWithUsername());
      history.push({
        pathname: "/allocate-usernames",
        state: { from: "keepFilter" },
      });
    }
  }, [backToListing]);


  const selectOption = (e) => {
    setSelected(e?.target?.value);
    setGetUserDisabled(true);
    setMobile();
  };
  const getUserDetails = (e, values) => {
    selected === "2"
      ? dispatch(
        getUser({
          data: {
            phone: mobile,
            country_code: countryCode,
          }, history: history
        })
      )
      : dispatch(getUser({ data: { ...values }, history: history }));
  };

  const cancelClick = () => {
    if (newUsername) {
      setShowPromptPopUp(!showPromptPopUp);
      setPromptMessage({
        id: "",
        content: t("Common.leavePage"),
        type: "back",
      });
    } else {
      history.push({
        pathname: "/allocate-usernames",
        state: { from: "keepFilter" },
      });
    }
  };
  const callbackFn = () => {
    setSearchUsername('');
    setNewUsername();
    setSelected("1");
    setGetUserDisabled(true);
    setFormChanged(false);
  }
  const okHandler = () => {
    promptMessage?.type === 'back' && setBackToListing(true)
    promptMessage?.type === 'forcefullyChange' &&
      dispatch(allocateUsername({
        data: { username: newUsername, user_id: data?.user?.id, force: true },
        callback: () => callbackFn()
      }));
  }

  const handleAllocate = (type) => {
    if (type === 'forcefullyChange') {
      setShowPromptPopUp(!showPromptPopUp);
      setPromptMessage({
        id: "",
        content: t("AllocateUserName.confirmAllocate"),
        type: "forcefullyChange",
      });
    } else {
      dispatch(allocateUsername({
        data: { username: newUsername, user_id: data?.user?.id },
        callback: () => callbackFn()
      }))
    }
  }

  const checkAvailabiltyHandler = () => {
    dispatch(checkUsernameAvailability({ username: newUsername, user_id: data?.user?.id }))
  }

  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  }

  return (
    <div className="page-content" ref={pageWrapRef}>
      <Prompt message={(location, action) => {
        if (action === 'POP') {
          return confirmBrowserBack()
        }
      }} when={formChanged} />
      <BackButton
        label={t("AllocateUserName.Title")}
        handleClick={() => cancelClick()}
      />
      <Breadcrumbs
        title="Tables"
        breadcrumbItem={t("AllocateUserName.addNew.Title")}
      />
      {data?.success &&
        <div ref={warningAlertRef}>
          <UncontrolledAlert
            color="success"
            className="alert-dismissible fade show"
            role="alert"
          >
            {data?.success}
          </UncontrolledAlert>
        </div>
      }

      {data?.error && <div ref={warningAlertRef}><UncontrolledAlert
        color="danger"
        className="alert-dismissible fade show"
        role="alert"
      >
        {data?.error}
      </UncontrolledAlert></div>
      }
      <Col xl="12"> 
        <Card className="p-4">
          <CardTitle className="h3 mt-0 mb-4">
            {t("ReserveUserName.linkedTo")} :
          </CardTitle>
          <LinkedTo selectOption={selectOption} selected={selected} />
          <GetUserForm
            getUserDisabled={getUserDisabled}
            setGetUserDisabled={setGetUserDisabled}
            selected={selected}
            setMobile={setMobile}
            onValidSubmit={getUserDetails}
            setCountryCode={setCountryCode}
            searchUsername={searchUsername}
            setSearchUsername={setSearchUsername}
          />
        </Card>
      </Col>

      {data?.user &&
        <Col xl="12">
          <Card className="newAllocate_detailWrapper">
            <UserDetail user={data?.user} />
            <AllocateForm newUsername={newUsername} cancelClick={cancelClick} setNewUsername={setNewUsername}
              handleAllocate={handleAllocate} warning={warning} data={data} checkAvailabiltyHandler={checkAvailabiltyHandler} resetError={resetError} />
          </Card>
        </Col>
      }
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true}/>}
    </div>
  );
};

export default AllocateAddNew;
