export const GET_USER_FLAX_RATES = "GET_USER_FLAX_RATES";
export const GET_USER_FLAX_RATES_BEGIN = "GET_USER_FLAX_RATES_BEGIN";
export const GET_USER_FLAX_RATES_SUCCESS = "GET_USER_FLAX_RATES_SUCCESS";
export const GET_USER_FLAX_RATES_FAIL = "GET_USER_FLAX_RATES_FAIL";

export const GET_USER_RATING_HISTORY = "GET_USER_RATING_HISTORY";
export const GET_USER_RATING_HISTORY_BEGIN = "GET_USER_RATING_HISTORY_BEGIN";
export const GET_USER_RATING_HISTORY_SUCCESS =
  "GET_USER_RATING_HISTORY_SUCCESS";
export const GET_USER_RATING_HISTORY_FAIL = "GET_USER_RATING_HISTORY_FAIL";
