import { useState } from "react";
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Card,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { Huddles } from "../Huddles";

const StatisticsTab = () => {
  const [activeTab, setTab] = useState("created_huddles");
  return (
    <Col xl="12">
      <Card className="mb-0">
        <Nav tabs className="nav-tabs-custom nav-justified flex">
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "created_huddles",
              })}
              onClick={() => {
                setTab("created_huddles");
              }}
            >
              <i className="uil fa fa-users font-size-20"></i>
              <span className="d-none d-sm-block">Created Huddles</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "admin_huddles" })}
              onClick={() => {
                setTab("admin_huddles");
              }}
            >
              <i className="uil fa fa-users font-size-20"></i>
              <span className="d-none d-sm-block">Admin Huddles</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "joined_huddles" })}
              onClick={() => {
                setTab("joined_huddles");
              }}
            >
              <i className="uil fa fa-users font-size-20"></i>
              <span className="d-none d-sm-block">Joined Huddles</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "created_groups" })}
              onClick={() => {
                setTab("created_groups");
              }}
            >
              <i className="uil fa fa-users font-size-20"></i>
              <span className="d-none d-sm-block">Created Groups</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "joined_groups" })}
              onClick={() => {
                setTab("joined_groups");
              }}
            >
              <i className="uil fa fa-users font-size-20"></i>
              <span className="d-none d-sm-block">Joined Groups</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="p-4">
          <TabPane tabId="created_huddles">
            {activeTab === "created_huddles" && (
              <Huddles
                activeTab={activeTab}
                tab="created_huddles"
                type="public"
              />
            )}
          </TabPane>
          <TabPane tabId="admin_huddles">
            {activeTab === "admin_huddles" && (
              <Huddles
                activeTab={activeTab}
                tab="admin_huddles"
                type="public"
              />
            )}
          </TabPane>
          <TabPane tabId="joined_huddles">
            {activeTab === "joined_huddles" && (
              <Huddles
                activeTab={activeTab}
                tab="joined_huddles"
                type="public"
              />
            )}
          </TabPane>
          <TabPane tabId="created_groups">
            {activeTab === "created_groups" && (
              <Huddles
                activeTab={activeTab}
                tab="created_groups"
                type="private"
              />
            )}
          </TabPane>
          <TabPane tabId="joined_groups">
            {activeTab === "joined_groups" && (
              <Huddles
                activeTab={activeTab}
                tab="joined_groups"
                type="private"
              />
            )}
          </TabPane>
        </TabContent>
      </Card>
    </Col>
  );
};

export default StatisticsTab;
