import React, { useState } from "react";
import { Row, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import HtmlEditor from "../../../components/UI/HtmlEditor";
import Button from "../../../components/UI/Button";
import Checkbox from "../../../components/UI/Checkbox";

//  To Select the Clicked Button
// https://www.positronx.io/how-to-add-active-class-in-map-items-in-react-js/

const EditDocumentForm = ({
  data,
  date,
  enabled,
  setDate,
  isDraftDisabled,
  isPublishDisabled,
  title,
  setTitle,
  description,
  setDescription,
  notify,
  setNotify,
  enableDisableDocument,
  keyChanges,
  setKeyChanges,
  submitDoument,
  handleLanguageClick,
  setPromptMessage,
  setShowPromptPopUp,
  setFormChanged,
  lang,
  setLang,
}) => {
  const { t } = useTranslation();
  let status = data?.document?.privacy_policies?.status;
  const [languageToggle, setLanguageToggle] = useState(false);
  const [active, setActive] = useState("english");
  let langList = [
    { id: "english", lan_name: "English" },
    { id: "hindi", lan_name: "Hindi" },
    { id: "tagalog", lan_name: "Tagalog" },
    { id: "arabic", lan_name: "Arabic" },
    { id: "french", lan_name: "French" },
    { id: "russian", lan_name: "Russian" },
    { id: "spanish", lan_name: "Spanish" },
    { id: "kurdish", lan_name: "Kurdish" },
    { id: "persian", lan_name: "Persian" },
  ];

  return (
    <>
      <div class="clearfix"></div>
      <div className="row">
        <div className="col-md-1">
          <ul className="metismenu list-unstyled language_list">
            {langList?.length &&
              langList?.map((lan) => (
                <>
                  <li>
                    <button
                      type="button"
                      className={`btn ${active == lan.id && "btn-primary"}`}
                      data-toggle="modal"
                      data-target={lan.lan_name}
                      onClick={() => {
                        setActive(lan.id);
                        setLang(lan.id);
                        setLanguageToggle(true);
                        setFormChanged(false);
                        handleLanguageClick(lan.id, lan.lan_name);
                      }}
                    >
                      <span className="mm-active-lang mx-2">
                        {lan.lan_name}
                      </span>
                    </button>
                  </li>
                  <br />
                </>
              ))}
          </ul>
        </div>
        <div className="col-md-11">
          <div className="">
            <Row className="col-md-12">
              <div className="col-md-4">
                <label
                  htmlFor="legalEffectiveDate"
                  className="col-md-10 col-form-label"
                >
                  {t("legalDocuments.DocumentTitle")}
                </label>
                <div className="col-md-10">
                  <Input
                    name="title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setFormChanged(true);
                    }}
                  />
                </div>
              </div>

              {/* <div className="col-md-4">
            <label
              htmlFor="legalEffectiveDate"
              className="col-md-10 col-form-label"
            >
              {t("legalDocuments.EffectiveDateTitle")}
              <span className="mandatory">*</span>
            </label>
             <div className="col-md-10 dobWrapper">
              <input
                name="effective_date"
                type="date"
                className="form-control"
                value={date}
                id="effective_date_input"
                onChange={(e) => {
                  setDate(e.target.value);
                  setFormChanged(true);
                }}
                min={
                  new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                    .toJSON()
                    .split("T")[0]
                }
              />
            </div> 
          </div> */}
              {status === "Published" && (
                <div className="form-check form-switch form-switch-lg col-md-4 enableDocumentSwitchWrap">
                  <div className="enableDocument_label">
                    <label
                      className="form-check-label"
                      htmlFor="enableDocument"
                    >
                      Enable Document
                    </label>
                  </div>
                  <div className="switchWrapper">
                    <Checkbox
                      id={"enableDocument"}
                      value={enabled}
                      handleChange={(e) => {
                        enableDisableDocument(e);
                      }}
                      className="form-check-input"
                    />
                  </div>
                </div>
              )}
            </Row>
          </div>

          <hr />
          <HtmlEditor
            title="Description"
            editorState={description}
            setEditorState={setDescription}
            mandatory
          />
          <HtmlEditor
            title="Key Points"
            editorState={keyChanges}
            setEditorState={setKeyChanges}
          />
          {/* <div className="notify-date-wrapper">
        <div className="notifyUserWrapper">
          <Checkbox
            id={"editDocumentNotify"}
            value={notify}
            handleChange={() => setNotify(!notify)}
            className="form-check-input notifyCheckbox"
          />
          <label htmlFor="editDocumentNotify">Notify User</label>
        </div>
        {notify && (
          <div className="dobWrapper">
            <input
              name="effective_date"
              type="date"
              className="form-control"
              value={date}
              id="effective_date_input"
              onChange={(e) => {
                setDate(e.target.value);
                setFormChanged(true);
              }}
              min={
                new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                  .toJSON()
                  .split("T")[0]
              }
            />
          </div>
        )}
      </div> */}

          <div className="notifyUser">
            <div className="notifyUserWrapper col-md-2">
              <Checkbox
                id={"editDocumentNotify"}
                value={notify}
                handleChange={() => setNotify(!notify)}
                className="form-check-input notifyCheckbox"
              />
              <label htmlFor="editDocumentNotify">Notify User</label>
            </div>
            {notify && (
              <div className="col-md-3">
                <label
                  htmlFor="legalEffectiveDate"
                  className="col-md-10 col-form-label"
                >
                  {t("legalDocuments.EffectiveDateTitle")}
                  <span className="mandatory">*</span>
                </label>
                <div className="col-md-10 dobWrapper">
                  <input
                    name="effective_date"
                    type="date"
                    className="form-control"
                    value={date}
                    id="effective_date_input"
                    onChange={(e) => {
                      setDate(e.target.value);
                      setFormChanged(true);
                    }}
                    min={
                      new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                        .toJSON()
                        .split("T")[0]
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="mt-4 editDocument">
            <Button
              label={
                data?.document?.privacy_policies?.status === "Published"
                  ? "ADD AS DRAFT"
                  : "SAVE TO DRAFT"
              }
              buttonClass="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
              handleClick={() => submitDoument("draft")}
              disableBtn={isDraftDisabled}
            />
            <Button
              label="PUBLISH"
              buttonClass="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
              handleClick={() => {
                setShowPromptPopUp(true);
                setPromptMessage({
                  content: t("legalDocuments.publishConfirmation"),
                  type: "publish",
                  okText: "PUBLISH",
                  cancelText: "CANCEL",
                });
              }}
              disableBtn={isPublishDisabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDocumentForm;
