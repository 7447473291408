export const countryOptions = [
  { key: 'af', value: '+93', flag: 'af', text: 'Afghanistan (+93)' },
  { key: 'ax', value: '+358', flag: 'ax', text: 'Aland Islands (+358)' },
  { key: 'al', value: '+355', flag: 'al', text: 'Albania (+355)' },
  { key: 'dz', value: '+213', flag: 'dz', text: 'Algeria (+213)' },
  { key: 'as', value: '+1684', flag: 'as', text: 'American Samoa (+1684)' },
  { key: 'ad', value: '+376', flag: 'ad', text: 'Andorra (+376)' },
  { key: 'ao', value: '+244', flag: 'ao', text: 'Angola (+244)' },
  { key: 'ai', value: '+1264', flag: 'ai', text: 'Anguilla (+1264)' },
  //Antartica
  { key: 'ag', value: '+1268', flag: 'ag', text: 'Antigua (+1268)' },
  { key: 'ar', value: '+54', flag: 'ar', text: 'Argentina (+54)' },
  { key: 'am', value: '+374', flag: 'am', text: 'Armenia (+374)' },
  { key: 'aw', value: '+297', flag: 'aw', text: 'Aruba (+297)' },
  { key: 'au', value: '+61', flag: 'au', text: 'Australia (+61)' },
  { key: 'at', value: '+43', flag: 'at', text: 'Austria (+43)' },
  { key: 'az', value: '+994', flag: 'az', text: 'Azerbaijan (+994)' },
  { key: 'bs', value: '+1242', flag: 'bs', text: 'Bahamas (+1242)' },
  { key: 'bh', value: '+973', flag: 'bh', text: 'Bahrain (+973)' },
  { key: 'bd', value: '+880', flag: 'bd', text: 'Bangladesh (+880)' },
  { key: 'bb', value: '+1246', flag: 'bb', text: 'Barbados (+1246)' },
  { key: 'by', value: '+375', flag: 'by', text: 'Belarus (+375)' },
  { key: 'be', value: '+32', flag: 'be', text: 'Belgium (+32)' },
  { key: 'bz', value: '+501', flag: 'bz', text: 'Belize (+501)' },
  { key: 'bj', value: '+229', flag: 'bj', text: 'Benin (+229)' },
  { key: 'bm', value: '+1441', flag: 'bm', text: 'Bermuda (+1441)' },
  { key: 'bt', value: '+975', flag: 'bt', text: 'Bhutan (+975)' },
  { key: 'bo', value: '+591', flag: 'bo', text: 'Bolivia (+591)' },
  { key: 'ba', value: '+387', flag: 'ba', text: 'Bosnia (+387)' },
  { key: 'bw', value: '+267', flag: 'bw', text: 'Botswana (+267)' },
  { key: 'bv', value: '+55', flag: 'bv', text: 'Bouvet Island (+55)' },
  { key: 'br', value: '+55', flag: 'br', text: 'Brazil (+55)' },
  { key: 'vg', value: '+1284', flag: 'vg', text: 'British Virgin Islands (+1284)' },
  { key: 'bn', value: '+673', flag: 'bn', text: 'Brunei (+673)' },
  { key: 'bg', value: '+359', flag: 'bg', text: 'Bulgaria (+359)' },
  { key: 'bf', value: '+226', flag: 'bf', text: 'Burkina Faso (+226)' },
  { key: 'mm', value: '+95', flag: 'mm', text: 'Burma (+95)' },
  { key: 'bi', value: '+256', flag: 'bi', text: 'Burundi (+256)' },
  { key: 'tc', value: '+1649', flag: 'tc', text: 'Caicos Islands (+1649)' },
  { key: 'kh', value: '+855', flag: 'kh', text: 'Cambodia (+855)' },
  { key: 'cm', value: '+237', flag: 'cm', text: 'Cameroon (+237)' },
  { key: 'ca', value: '+1', flag: 'ca', text: 'Canada (+1)' },
  { key: 'cv', value: '+238', flag: 'cv', text: 'Cape Verde (+238)' },
  { key: 'ky', value: '+345', flag: 'ky', text: 'Cayman Islands (+345)' },
  { key: 'cf', value: '+236', flag: 'cf', text: 'Central African Republic (+236)' },
  { key: 'td', value: '+235', flag: 'td', text: 'Chad (+235)' },
  { key: 'cl', value: '+56', flag: 'cl', text: 'Chile (+56)' },
  { key: 'cn', value: '+86', flag: 'cn', text: 'China (+86)' },
  { key: 'cx', value: '+61', flag: 'cx', text: 'Christmas Island (+61)' },
  { key: 'cc', value: '+61', flag: 'cc', text: 'Cocos Islands (+61)' },
  { key: 'co', value: '+57', flag: 'co', text: 'Colombia (+57)' },
  { key: 'km', value: '+269', flag: 'km', text: 'Comoros (+269)' },
  { key: 'cg', value: '+242', flag: 'cg', text: 'Congo Brazzaville (+242)' },
  { key: 'cd', value: '+243', flag: 'cd', text: 'Congo (+243)' },
  { key: 'ck', value: '+682', flag: 'ck', text: 'Cook Islands (+682)' },
  { key: 'cr', value: '+506', flag: 'cr', text: 'Costa Rica (+506)' },
  { key: 'ci', value: '+225', flag: 'ci', text: 'Cote Divoire (+225)' },
  { key: 'hr', value: '+385', flag: 'hr', text: 'Croatia (+385)' },
  { key: 'cu', value: '+53', flag: 'cu', text: 'Cuba (+53)' },
  { key: 'cy', value: '+357', flag: 'cy', text: 'Cyprus (+357)' },
  { key: 'cz', value: '+420', flag: 'cz', text: 'Czech Republic (+420)' },
  { key: 'dk', value: '+45', flag: 'dk', text: 'Denmark (+45)' },
  { key: 'dj', value: '+253', flag: 'dj', text: 'Djibouti (+253)' },
  { key: 'dm', value: '+1767', flag: 'dm', text: 'Dominica (+1767)' },
  { key: 'do', value: '+1849', flag: 'do', text: 'Dominican Republic (+1849)' },
  { key: 'ec', value: '+593', flag: 'ec', text: 'Ecuador (+593)' },
  { key: 'eg', value: '+20', flag: 'eg', text: 'Egypt (+20)' },
  { key: 'sv', value: '+503', flag: 'sv', text: 'El Salvador (+503)' },
  { key: 'gb', value: '+44', flag: 'gb', text: 'England (+44)' },
  { key: 'gq', value: '+240', flag: 'gq', text: 'Equatorial Guinea (+240)' },
  { key: 'er', value: '+291', flag: 'er', text: 'Eritrea (+291)' },
  { key: 'ee', value: '+372', flag: 'ee', text: 'Estonia (+372)' },
  { key: 'et', value: '+251', flag: 'et', text: 'Ethiopia (+251)' },
  { key: 'fk', value: '+500', flag: 'fk', text: 'Falkland Islands (+500)' },
  { key: 'fo', value: '+298', flag: 'fo', text: 'Faroe Islands (+298)' },
  { key: 'fj', value: '+679', flag: 'fj', text: 'Fiji (+679)' },
  { key: 'fi', value: '+358', flag: 'fi', text: 'Finland (+358)' },
  { key: 'fr', value: '+33', flag: 'fr', text: 'France (+33)' },
  { key: 'gf', value: '+594', flag: 'gf', text: 'French Guiana (+594)' },
  { key: 'pf', value: '+689', flag: 'pf', text: 'French Polynesia (+689)' },
  { key: 'ga', value: '+241', flag: 'ga', text: 'Gabon (+241)' },
  { key: 'gm', value: '+220', flag: 'gm', text: 'Gambia (+220)' },
  { key: 'ge', value: '+995', flag: 'ge', text: 'Georgia (+995)' },
  { key: 'de', value: '+49', flag: 'de', text: 'Germany (+49)' },
  { key: 'gh', value: '+233', flag: 'gh', text: 'Ghana (+233)' },
  { key: 'gi', value: '+350', flag: 'gi', text: 'Gibraltar (+350)' },
  { key: 'gr', value: '+30', flag: 'gr', text: 'Greece (+30)' },
  { key: 'gl', value: '+299', flag: 'gl', text: 'Greenland (+299)' },
  { key: 'gd', value: '+1473', flag: 'gd', text: 'Grenada (+1473)' },
  { key: 'gp', value: '+590', flag: 'gp', text: 'Guadeloupe (+590)' },
  { key: 'gu', value: '+1671', flag: 'gu', text: 'Guam (+1671)' },
  { key: 'gt', value: '+502', flag: 'gt', text: 'Guatemala (+502)' },
  //Guernsey
  { key: 'gw', value: '+245', flag: 'gw', text: 'Guinea-Bissau (+245)' },
  { key: 'gn', value: '+224', flag: 'gn', text: 'Guinea (+224)' },
  { key: 'gy', value: '+595', flag: 'gy', text: 'Guyana (+595)' },
  { key: 'ht', value: '+509', flag: 'ht', text: 'Haiti (+509)' },
  { key: 'hm', value: '+672', flag: 'hm', text: 'Heard Island (+672)' },
  { key: 'hn', value: '+504', flag: 'hn', text: 'Honduras (+504)' },
  { key: 'hk', value: '+852', flag: 'hk', text: 'Hong Kong (+852)' },
  { key: 'hu', value: '+36', flag: 'hu', text: 'Hungary (+36)' },
  { key: 'is', value: '+354', flag: 'is', text: 'Iceland (+354)' },
  { key: 'in', value: '+91', flag: 'in', text: 'India (+91)' },
  { key: 'io', value: '+246', flag: 'io', text: 'Indian Ocean Territory (+246)' },
  { key: 'id', value: '+62', flag: 'id', text: 'Indonesia (+62)' },
  { key: 'ir', value: '+98', flag: 'ir', text: 'Iran (+98)' },
  { key: 'iq', value: '+964', flag: 'iq', text: 'Iraq (+964)' },
  { key: 'ie', value: '+353', flag: 'ie', text: 'Ireland (+353)' },
  //Isle of man
  { key: 'il', value: '+972', flag: 'il', text: 'Israel (+972)' },
  { key: 'it', value: '+39', flag: 'it', text: 'Italy (+39)' },
  { key: 'jm', value: '+1876', flag: 'jm', text: 'Jamaica (+1876)' },
  { key: 'jp', value: '+81', flag: 'jp', text: 'Japan (+81)' },
  //Jersey
  { key: 'jo', value: '+962', flag: 'jo', text: 'Jordan (+962)' },
  { key: 'kz', value: '+77', flag: 'kz', text: 'Kazakhstan (+77)' },
  { key: 'ke', value: '+254', flag: 'ke', text: 'Kenya (+254)' },
  { key: 'ki', value: '+686', flag: 'ki', text: 'Kiribati (+686)' },
  { key: 'kw', value: '+965', flag: 'kw', text: 'Kuwait (+965)' },
  { key: 'kg', value: '+996', flag: 'kg', text: 'Kyrgyzstan (+996)' },
  { key: 'la', value: '+856', flag: 'la', text: 'Laos (+856)' },
  { key: 'lv', value: '+371', flag: 'lv', text: 'Latvia (+371)' },
  { key: 'lb', value: '+961', flag: 'lb', text: 'Lebanon (+961)' },
  { key: 'ls', value: '+266', flag: 'ls', text: 'Lesotho (+266)' },
  { key: 'lr', value: '+231', flag: 'lr', text: 'Liberia (+231)' },
  { key: 'ly', value: '+218', flag: 'ly', text: 'Libya (+218)' },
  { key: 'li', value: '+423', flag: 'li', text: 'Liechtenstein (+423)' },
  { key: 'lt', value: '+370', flag: 'lt', text: 'Lithuania (+370)' },
  { key: 'lu', value: '+352', flag: 'lu', text: 'Luxembourg (+352)' },
  { key: 'mo', value: '+853', flag: 'mo', text: 'Macau (+853)' },
  { key: 'mk', value: '+389', flag: 'mk', text: 'Macedonia (+389)' },
  { key: 'mg', value: '+261', flag: 'mg', text: 'Madagascar (+261)' },
  { key: 'mw', value: '+265', flag: 'mw', text: 'Malawi (+265)' },
  { key: 'my', value: '+60', flag: 'my', text: 'Malaysia (+60)' },
  { key: 'mv', value: '+960', flag: 'mv', text: 'Maldives (+960)' },
  { key: 'ml', value: '+223', flag: 'ml', text: 'Mali (+223)' },
  { key: 'mt', value: '+356', flag: 'mt', text: 'Malta (+356)' },
  { key: 'mh', value: '+692', flag: 'mh', text: 'Marshall Islands (+692)' },
  { key: 'mq', value: '+596', flag: 'mq', text: 'Martinique (+596)' },
  { key: 'mr', value: '+222', flag: 'mr', text: 'Mauritania (+222)' },
  { key: 'mu', value: '+230', flag: 'mu', text: 'Mauritius (+230)' },
  { key: 'yt', value: '+262', flag: 'yt', text: 'Mayotte (+262)' },
  { key: 'mx', value: '+52', flag: 'mx', text: 'Mexico (+52)' },
  { key: 'fm', value: '+691', flag: 'fm', text: 'Micronesia (+691)' },
  { key: 'md', value: '+373', flag: 'md', text: 'Moldova (+373)' },
  { key: 'mc', value: '+377', flag: 'mc', text: 'Monaco (+377)' },
  { key: 'mn', value: '+976', flag: 'mn', text: 'Mongolia (+976)' },
  { key: 'me', value: '+382', flag: 'me', text: 'Montenegro (+382)' },
  { key: 'ms', value: '+1664', flag: 'ms', text: 'Montserrat (+1664)' },
  { key: 'ma', value: '+212', flag: 'ma', text: 'Morocco (+212)' },
  { key: 'mz', value: '+258', flag: 'mz', text: 'Mozambique (+258)' },
  { key: 'na', value: '+264', flag: 'na', text: 'Namibia (+264)' },
  { key: 'nr', value: '+674', flag: 'nr', text: 'Nauru (+674)' },
  { key: 'np', value: '+977', flag: 'np', text: 'Nepal (+977)' },
  { key: 'an', value: '+599', flag: 'an', text: 'Netherlands Antilles (+599)' },
  { key: 'nl', value: '+31', flag: 'nl', text: 'Netherlands (+31)' },
  { key: 'nc', value: '+687', flag: 'nc', text: 'New Caledonia (+687)' },
  { key: 'pg', value: '+675', flag: 'pg', text: 'New Guinea (+675)' },
  { key: 'nz', value: '+64', flag: 'nz', text: 'New Zealand (+64)' },
  { key: 'ni', value: '+505', flag: 'ni', text: 'Nicaragua (+505)' },
  { key: 'ne', value: '+227', flag: 'ne', text: 'Niger (+227)' },
  { key: 'ng', value: '+234', flag: 'ng', text: 'Nigeria (+234)' },
  { key: 'nu', value: '+683', flag: 'nu', text: 'Niue (+683)' },
  { key: 'nf', value: '+672', flag: 'nf', text: 'Norfolk Island (+672)' },
  { key: 'kp', value: '+850', flag: 'kp', text: 'North Korea (+850)' },
  { key: 'mp', value: '+1670', flag: 'mp', text: 'Northern Mariana Islands (+1670)' },
  { key: 'no', value: '+47', flag: 'no', text: 'Norway (+47)' },
  { key: 'om', value: '+968', flag: 'om', text: 'Oman (+968)' },
  { key: 'pk', value: '+92', flag: 'pk', text: 'Pakistan (+92)' },
  { key: 'pw', value: '+680', flag: 'pw', text: 'Palau (+680)' },
  { key: 'ps', value: '+970', flag: 'ps', text: 'Palestine (+970)' },
  { key: 'pa', value: '+507', flag: 'pa', text: 'Panama (+507)' },
  { key: 'py', value: '+595', flag: 'py', text: 'Paraguay (+595)' },
  { key: 'pe', value: '+51', flag: 'pe', text: 'Peru (+51)' },
  { key: 'ph', value: '+63', flag: 'ph', text: 'Philippines (+63)' },
  { key: 'pn', value: '+872', flag: 'pn', text: 'Pitcairn Islands (+872)' },
  { key: 'pl', value: '+48', flag: 'pl', text: 'Poland (+48)' },
  { key: 'pt', value: '+351', flag: 'pt', text: 'Portugal (+351)' },
  { key: 'pr', value: '+1939', flag: 'pr', text: 'Puerto Rico (+1939)' },
  { key: 'qa', value: '+974', flag: 'qa', text: 'Qatar (+974)' },
  { key: 're', value: '+262', flag: 're', text: 'Reunion (+262)' },
  { key: 'ro', value: '+40', flag: 'ro', text: 'Romania (+40)' },
  { key: 'ru', value: '+7', flag: 'ru', text: 'Russia (+7)' },
  { key: 'rw', value: '+250', flag: 'rw', text: 'Rwanda (+250)' },
  //Saint Barthelemy
  { key: 'sh', value: '+290', flag: 'sh', text: 'Saint Helena (+290)' },
  { key: 'kn', value: '+1869', flag: 'kn', text: 'Saint Kitts and Nevis (+1869)' },
  { key: 'lc', value: '+1758', flag: 'lc', text: 'Saint Lucia (+1758)' },
  //Saint Martin
  { key: 'pm', value: '+508', flag: 'pm', text: 'Saint Pierre (+508)' },
  { key: 'vc', value: '+1784', flag: 'vc', text: 'Saint Vincent (+1784)' },
  { key: 'ws', value: '+685', flag: 'ws', text: 'Samoa (+685)' },
  { key: 'sm', value: '+378', flag: 'sm', text: 'San Marino (+378)' },
  { key: 'gs', value: '+500', flag: 'gs', text: 'Sandwich Islands (+500)' },
  { key: 'st', value: '+239', flag: 'st', text: 'Sao Tome (+239)' },
  { key: 'sa', value: '+966', flag: 'sa', text: 'Saudi Arabia (+966)' },
  { key: 'sn', value: '+221', flag: 'sn', text: 'Senegal (+221)' },
  //{ key: 'cs', value: 'cs', flag: 'cs', text: 'Serbia ()' },
  { key: 'rs', value: '+381', flag: 'rs', text: 'Serbia (+381)' },
  { key: 'sc', value: '+248', flag: 'sc', text: 'Seychelles (+248)' },
  { key: 'sl', value: '+232', flag: 'sl', text: 'Sierra Leone (+232)' },
  { key: 'sg', value: '+65', flag: 'sg', text: 'Singapore (+65)' },
  { key: 'sk', value: '+421', flag: 'sk', text: 'Slovakia (+421)' },
  { key: 'si', value: '+386', flag: 'si', text: 'Slovenia (+386)' },
  { key: 'sb', value: '+677', flag: 'sb', text: 'Solomon Islands (+677)' },
  { key: 'so', value: '+252', flag: 'so', text: 'Somalia (+252)' },
  { key: 'za', value: '+27', flag: 'za', text: 'South Africa (+27)' },
  { key: 'kr', value: '+82', flag: 'kr', text: 'South Korea (+82)' },
  //South Sudan
  { key: 'es', value: '+34', flag: 'es', text: 'Spain (+34)' },
  { key: 'lk', value: '+94', flag: 'lk', text: 'Sri Lanka (+94)' },
  { key: 'sd', value: '+249', flag: 'sd', text: 'Sudan (+249)' },
  { key: 'sr', value: '+597', flag: 'sr', text: 'Suriname (+597)' },
  { key: 'sj', value: '+47', flag: 'sj', text: 'Svalbard (+47)' },
  { key: 'sz', value: '+268', flag: 'sz', text: 'Swaziland (+268)' },
  { key: 'se', value: '+46', flag: 'se', text: 'Sweden (+46)' },
  { key: 'ch', value: '+41', flag: 'ch', text: 'Switzerland (+41)' },
  { key: 'sy', value: '+963', flag: 'sy', text: 'Syria (+963)' },
  { key: 'tw', value: '+886', flag: 'tw', text: 'Taiwan (+886)' },
  { key: 'tj', value: '+992', flag: 'tj', text: 'Tajikistan (+992)' },
  { key: 'tz', value: '+255', flag: 'tz', text: 'Tanzania (+255)' },
  { key: 'th', value: '+66', flag: 'th', text: 'Thailand (+66)' },
  { key: 'tl', value: '+670', flag: 'tl', text: 'Timorleste (+670)' },
  { key: 'tg', value: '+228', flag: 'tg', text: 'Togo (+228)' },
  { key: 'tk', value: '+690', flag: 'tk', text: 'Tokelau (+690)' },
  { key: 'to', value: '+676', flag: 'to', text: 'Tonga (+676)' },
  { key: 'tt', value: '+1868', flag: 'tt', text: 'Trinidad (+1868)' },
  { key: 'tn', value: '+216', flag: 'tn', text: 'Tunisia (+216)' },
  { key: 'tr', value: '+90', flag: 'tr', text: 'Turkey (+90)' },
  { key: 'tm', value: '+993', flag: 'tm', text: 'Turkmenistan (+993)' },
  //Turks and Caicos Islands
  { key: 'tv', value: '+688', flag: 'tv', text: 'Tuvalu (+688)' },
  { key: 'ug', value: '+256', flag: 'ug', text: 'Uganda (+256)' },
  { key: 'ua', value: '+380', flag: 'ua', text: 'Ukraine (+380)' },
  { key: 'ae', value: '+971', flag: 'ae', text: 'United Arab Emirates (+971)' },
  { key: 'us', value: '+1', flag: 'us', text: 'United States (+1)' },
  { key: 'uy', value: '+598', flag: 'uy', text: 'Uruguay (+598)' },
  { key: 'um', value: '+246', flag: 'um', text: 'Us Minor Islands (+246)' },
  { key: 'vi', value: '+1340', flag: 'vi', text: 'Us Virgin Islands (+1340)' },
  { key: 'uz', value: '+998', flag: 'uz', text: 'Uzbekistan (+998)' },
  { key: 'vu', value: '+678', flag: 'vu', text: 'Vanuatu (+678)' },
  { key: 'va', value: '+379', flag: 'va', text: 'Vatican City (+379)' },
  { key: 've', value: '+58', flag: 've', text: 'Venezuela (+58)' },
  { key: 'vn', value: '+84', flag: 'vn', text: 'Vietnam (+84)' },
  { key: 'wf', value: '+681', flag: 'wf', text: 'Wallis and Futuna (+681)' },
  { key: 'eh', value: '+212', flag: 'eh', text: 'Western Sahara (+212)' },
  { key: 'ye', value: '+967', flag: 'ye', text: 'Yemen (+967)' },
  { key: 'zm', value: '+260', flag: 'zm', text: 'Zambia (+260)' },
  { key: 'zw', value: '+263', flag: 'zw', text: 'Zimbabwe (+263)' },
]

  