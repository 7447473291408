import {
  GET_ACCOUNT_VERIFICATION_STATUS,
  GET_ACCOUNT_VERIFICATION_STATUS_BEGIN,
  GET_ACCOUNT_VERIFICATION_STATUS_SUCCESS,
  GET_ACCOUNT_VERIFICATION_STATUS_FAIL,
  ADD_ACCOUNT_VERIFICATION_STATUS,
  ADD_ACCOUNT_VERIFICATION_STATUS_BEGIN,
  ADD_ACCOUNT_VERIFICATION_STATUS_FAIL,
  ADD_ACCOUNT_VERIFICATION_STATUS_SUCCESS,
} from "./actionTypes";

export const getAccountVerificationStatusData = (request) => ({
  type: GET_ACCOUNT_VERIFICATION_STATUS,
  payload: { request },
});

export const getAccountVerificationStatusDataBegin = () => ({
  type: GET_ACCOUNT_VERIFICATION_STATUS_BEGIN,
});

export const getAccountVerificationStatusDataSuccess = (AccountVerificationStatusData) => ({
  type: GET_ACCOUNT_VERIFICATION_STATUS_SUCCESS,
  payload: AccountVerificationStatusData,
});

export const getAccountVerificationStatusDataFail = (error) => ({
  type: GET_ACCOUNT_VERIFICATION_STATUS_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const addAccountVerificationStatus = (data, callback) => ({
  type: ADD_ACCOUNT_VERIFICATION_STATUS,
  data: data,
  callback: callback,
});

export const addAccountVerificationStatusBegin = () => ({
  type: ADD_ACCOUNT_VERIFICATION_STATUS_BEGIN,
});

export const addAccountVerificationStatusSuccess = (AccountVerificationStatus) => ({
  type: ADD_ACCOUNT_VERIFICATION_STATUS_SUCCESS,
  payload: AccountVerificationStatus,
});

export const addAccountVerificationStatusFail = (error) => ({
  type: ADD_ACCOUNT_VERIFICATION_STATUS_FAIL,
  payload: error,
});