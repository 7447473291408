import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import { DatePicker } from "./DatePicker";

const Filter_userMgmt_component = ({
  setRequest,
  request,
  statusFilter,
  membershipFilter,
  dateFilter,
  isPremium,
  dateType,
  citizenShipFilter,
}) => {
  const [userList_filter_active, set_userList_filter_active] =
    useState("Active");
  const [userList_filter_inactive, set_userList_filter_inactive] =
    useState(false);
  const [userList_filter_blocked, set_userList_filter_blocked] =
    useState(false);
  const [userList_filter_recentUsers, set_userList_filter_recentUsers] =
    useState(false);
  const [userList_filter_free, set_userList_filter_free] = useState(false);
  const [userList_filter_premium, set_userList_filter_premium] =
    useState(false);
  const [userList_filter_verified, set_userList_filter_verified] =
    useState(false);
  const [userList_filter_empowered, set_userList_filter_empowered] =
    useState(false);
  const [userList_filter_podium_camera, set_userList_filter_podium_camera] =
    useState(false);

  const [userList_filter_citizen, set_userList_filter_citizen] =
    useState(false);
  const [userList_filter_ambassador, set_userList_filter_ambassador] =
    useState(false);
  const [userList_filter_leader, set_userList_filter_leader] = useState(false);
  const [userList_filter_officer, set_userList_filter_officer] =
    useState(false);
  const [userList_filter_minister, set_userList_filter_minister] =
    useState(false);
  const [userList_filter_president, set_userList_filter_president] =
    useState(false);

  // const [
  //   userList_subscription_sorting_asc,
  //   setUserList_subscription_sorting_asc,
  // ] = useState(false);
  // const [
  //   userList_subscription_sorting_desc,
  //   setUserList_subscription_sorting_desc,
  // ] = useState(false);
  // const [subscriptionSortValue, setSubscriptionSortValue] = useState("");
  const [modal_center, setmodal_center] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    statusFilter?.includes("Active")
      ? set_userList_filter_active("Active")
      : set_userList_filter_active(false);
    statusFilter?.includes("Inactive")
      ? set_userList_filter_inactive("Inactive")
      : set_userList_filter_inactive(false);
    statusFilter?.includes("Blocked")
      ? set_userList_filter_blocked("Blocked")
      : set_userList_filter_blocked(false);
    statusFilter?.includes("recent_users")
      ? set_userList_filter_recentUsers("recent_users")
      : set_userList_filter_recentUsers(false);
    statusFilter?.includes("Empowered")
      ? set_userList_filter_empowered("Empowered")
      : set_userList_filter_empowered(false);
    statusFilter?.includes("PodiumCamera")
      ? set_userList_filter_podium_camera("PodiumCamera")
      : set_userList_filter_podium_camera(false);
  }, [statusFilter, modal_center]);

  useEffect(() => {
    membershipFilter?.includes("Free")
      ? set_userList_filter_free("Free")
      : set_userList_filter_free(false);
    membershipFilter?.includes("Premium")
      ? set_userList_filter_premium("Premium")
      : set_userList_filter_premium(false);
    membershipFilter?.includes("Verified")
      ? set_userList_filter_verified("Verified")
      : set_userList_filter_verified(false);
  }, [membershipFilter, modal_center]);

  useEffect(() => {
    citizenShipFilter?.includes("CITIZEN")
      ? set_userList_filter_citizen("CITIZEN")
      : set_userList_filter_citizen(false);
    citizenShipFilter?.includes("AMBASSADOR")
      ? set_userList_filter_ambassador("AMBASSADOR")
      : set_userList_filter_ambassador(false);
    citizenShipFilter?.includes("OFFICER")
      ? set_userList_filter_officer("OFFICER")
      : set_userList_filter_officer(false);
    citizenShipFilter?.includes("MINISTER")
      ? set_userList_filter_minister("MINISTER")
      : set_userList_filter_minister(false);
    citizenShipFilter?.includes("PRESIDENT")
      ? set_userList_filter_president("PRESIDENT")
      : set_userList_filter_president(false);
    citizenShipFilter?.includes("LEADER")
      ? set_userList_filter_leader("LEADER")
      : set_userList_filter_leader(false);
  }, [statusFilter, modal_center]);

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  // useEffect(() => {
  //   userList_subscription_sorting_desc && setSubscriptionSortValue("desc");
  //   userList_subscription_sorting_asc && setSubscriptionSortValue("asc");
  //   !userList_subscription_sorting_desc &&
  //     !userList_subscription_sorting_asc &&
  //     setSubscriptionSortValue("");
  // }, [userList_subscription_sorting_asc, userList_subscription_sorting_desc]);

  const filter_clearAll = () => {
    set_userList_filter_active(false);
    set_userList_filter_inactive(false);
    set_userList_filter_blocked(false);
    set_userList_filter_recentUsers(false);
    set_userList_filter_empowered(false);
    set_userList_filter_free(false);
    set_userList_filter_premium(false);
    set_userList_filter_verified(false);
    set_userList_filter_podium_camera(false);
    set_userList_filter_citizen(false);
    set_userList_filter_ambassador(false);
    set_userList_filter_officer(false);
    set_userList_filter_minister(false);
    set_userList_filter_president(false);
    set_userList_filter_leader(false);
    // setUserList_subscription_sorting_asc(false);
    // setUserList_subscription_sorting_desc(false);
    // setSubscriptionSortValue("");
  };
  const handleApply = () => {
    setRequest({
      ...request,
      citizenship_filter: [
        userList_filter_citizen,
        userList_filter_ambassador,
        userList_filter_officer,
        userList_filter_minister,
        userList_filter_president,
        userList_filter_leader,
      ].filter((item) => item),
      status_filter: [
        userList_filter_active,
        userList_filter_inactive,
        userList_filter_blocked,
        userList_filter_recentUsers,
        userList_filter_empowered,
        userList_filter_podium_camera,
      ].filter((item) => item),
      // sort: subscriptionSortValue === "" ? "created_on" : "payment_date",
      // sort_order: subscriptionSortValue,
    });
    setmodal_center(false);
  };

  const onDateSelection = (date) => {
    setRequest({
      ...request,
      subscribed_from_date: date.from_date,
      subscribed_to_date: date.to_date,
    });
  };
  return (
    <div className="user-filterBtn-wrap" data-testid="component-filterPopup">
      {dateFilter && (
        <DatePicker dateType={dateType} OnDateSelection={onDateSelection} />
      )}
      <button
        type="button"
        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
        onClick={() => {
          tog_center();
        }}
        data-testid="component-filterButton"
      >
        <i
          style={{ paddingRight: "10px" }}
          className="fa fa-filter"
          aria-hidden="true"
        ></i>
        FILTER
      </button>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={true}
      >
        <div className="modal-header userList-filter-header">
          <h5 className="modal-title mt-0">FILTER</h5>
          <button
            onClick={() => filter_clearAll()}
            className="btn waves-effect waves-light cust_no_shadow userList-filter-clearAll"
          >
            CLEAR ALL
          </button>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
            // data-dismiss="modal"
            // aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body userList-filter-body">
          <div className="userList-filter-SectionWrap">
            <h5 className="userList-filter-SectionTitle">Status</h5>
            <div className="userList-filter-checkBoxWrap">
              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={userList_filter_active}
                  id="userList_filter_active"
                  onChange={() =>
                    userList_filter_active
                      ? set_userList_filter_active(!userList_filter_active)
                      : set_userList_filter_active("Active")
                  }
                  checked={userList_filter_active}
                />
                <label
                  className="form-check-label"
                  htmlFor="userList_filter_active"
                >
                  Active
                </label>
              </div>

              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={userList_filter_inactive}
                  id="userList_filter_inactive"
                  onChange={() =>
                    userList_filter_inactive
                      ? set_userList_filter_inactive(!userList_filter_inactive)
                      : set_userList_filter_inactive("Inactive")
                  }
                  checked={userList_filter_inactive}
                />
                <label
                  className="form-check-label"
                  htmlFor="userList_filter_inactive"
                >
                  Inactive
                </label>
              </div>

              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={userList_filter_blocked}
                  id="userList_filter_blocked"
                  onChange={() =>
                    userList_filter_blocked
                      ? set_userList_filter_blocked(!userList_filter_blocked)
                      : set_userList_filter_blocked("Blocked")
                  }
                  checked={userList_filter_blocked}
                />
                <label
                  className="form-check-label"
                  htmlFor="userList_filter_blocked"
                >
                  Blocked
                </label>
              </div>

              {isPremium && (
                <div>
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value={userList_filter_recentUsers}
                    id="userList_filter_active"
                    onChange={() =>
                      userList_filter_recentUsers
                        ? set_userList_filter_recentUsers(
                            !userList_filter_recentUsers
                          )
                        : set_userList_filter_recentUsers("recent_users")
                    }
                    checked={userList_filter_recentUsers}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="userList_filter_recentUsers"
                  >
                    Recent Users
                  </label>
                </div>
              )}
            </div>

            <div className="userList-filter-checkBoxWrap mt-4">
              {isPremium && (
                <div>
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value={set_userList_filter_empowered}
                    id="userList_filter_active"
                    onChange={() =>
                      userList_filter_empowered
                        ? set_userList_filter_empowered(
                            !userList_filter_empowered
                          )
                        : set_userList_filter_empowered("Empowered")
                    }
                    checked={userList_filter_empowered}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="userList_filter_active"
                  >
                    Empowered
                  </label>
                </div>
              )}

              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={userList_filter_podium_camera}
                  id="userList_filter_camera_active"
                  onChange={() =>
                    userList_filter_podium_camera
                      ? set_userList_filter_podium_camera(
                          !userList_filter_podium_camera
                        )
                      : set_userList_filter_podium_camera("PodiumCamera")
                  }
                  checked={userList_filter_podium_camera}
                />
                <label
                  className="form-check-label"
                  htmlFor="userList_filter_camera_active"
                >
                  Podium Camera
                </label>
              </div>
            </div>
          </div>

          {isPremium && (
            <div className="userList-filter-SectionWrap">
              <h5 className="userList-filter-SectionTitle">Citizenship</h5>
              <div className="userList-filter-checkBoxWrap">
                <div>
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value={userList_filter_citizen}
                    id="userList_filter_citizen"
                    onChange={() =>
                      userList_filter_citizen
                        ? set_userList_filter_citizen(!userList_filter_citizen)
                        : set_userList_filter_citizen("CITIZEN")
                    }
                    checked={userList_filter_citizen}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="userList_filter_citizen"
                  >
                    Citizen
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value={userList_filter_ambassador}
                    id="userList_filter_ambassador"
                    onChange={() =>
                      userList_filter_ambassador
                        ? set_userList_filter_ambassador(
                            !userList_filter_ambassador
                          )
                        : set_userList_filter_ambassador("AMBASSADOR")
                    }
                    checked={userList_filter_ambassador}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="userList_filter_ambassador"
                  >
                    Ambassador
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value={userList_filter_leader}
                    id="userList_filter_leader"
                    onChange={() =>
                      userList_filter_leader
                        ? set_userList_filter_leader(!userList_filter_leader)
                        : set_userList_filter_leader("LEADER")
                    }
                    checked={userList_filter_leader}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="userList_filter_leader"
                  >
                    Leader
                  </label>
                </div>
              </div>

              <div className="userList-filter-checkBoxWrap mt-4">
                <div>
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value={userList_filter_officer}
                    id="userList_filter_officer"
                    onChange={() =>
                      userList_filter_officer
                        ? set_userList_filter_officer(!userList_filter_officer)
                        : set_userList_filter_officer("OFFICER")
                    }
                    checked={userList_filter_officer}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="userList_filter_officer"
                  >
                    Officer
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value={userList_filter_minister}
                    id="userList_filter_minister"
                    onChange={() =>
                      userList_filter_minister
                        ? set_userList_filter_minister(
                            !userList_filter_minister
                          )
                        : set_userList_filter_minister("MINISTER")
                    }
                    checked={userList_filter_minister}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="userList_filter_minister"
                  >
                    Minister
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value={userList_filter_president}
                    id="userList_filterpresidentr"
                    onChange={() =>
                      userList_filter_president
                        ? set_userList_filter_president(
                            !userList_filter_president
                          )
                        : set_userList_filter_president("PRESIDENT")
                    }
                    checked={userList_filter_president}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="userList_filter_president"
                  >
                    The President
                  </label>
                </div>
              </div>
            </div>
          )}

          {/* subscription filter  */}
          {/* {isPremium && (
            <div className="userList-filter-SectionWrap">
              <h5 className="userList-filter-SectionTitle">
                Subscription Date
              </h5>
              <div className="userList-filter-checkBoxWrap">
                <div>
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value={userList_subscription_sorting_asc}
                    id="userList_filter_active"
                    onChange={() => {
                      setUserList_subscription_sorting_asc(
                        !userList_subscription_sorting_asc
                      );
                      setUserList_subscription_sorting_desc(false);
                    }}
                    checked={userList_subscription_sorting_asc}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="userList_filter_active"
                  >
                    Ascending
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    value={userList_subscription_sorting_desc}
                    id="userList_filter_inactive"
                    onChange={() => {
                      setUserList_subscription_sorting_desc(
                        !userList_subscription_sorting_desc
                      );
                      setUserList_subscription_sorting_asc(false);
                    }}
                    checked={userList_subscription_sorting_desc}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="userList_filter_inactive"
                  >
                    Descending
                  </label>
                </div>
              </div>
            </div>
          )} */}

          {/* <div className="userList-filter-SectionWrap">
            <h5 className="userList-filter-SectionTitle">Membership</h5>
            <div className="userList-filter-checkBoxWrap">
              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={userList_filter_free}
                  id="userList_filter_free"
                  onChange={() =>
                    userList_filter_free
                      ? set_userList_filter_free(!userList_filter_free)
                      : set_userList_filter_free("Free")
                  }
                  checked={userList_filter_free}
                />
                <label
                  className="form-check-label"
                  htmlFor="userList_filter_free"
                >
                  Free
                </label>
              </div>

              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={userList_filter_premium}
                  id="userList_filter_premium"
                  onChange={() =>
                    userList_filter_premium
                      ? set_userList_filter_premium(!userList_filter_premium)
                      : set_userList_filter_premium("Premium")
                  }
                  checked={userList_filter_premium}
                />
                <label
                  className="form-check-label"
                  htmlFor="userList_filter_premium"
                >
                  Premium
                </label>
              </div>

              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={userList_filter_verified}
                  id="userList_filter_verified"
                  onChange={() =>
                    userList_filter_verified
                      ? set_userList_filter_verified(!userList_filter_verified)
                      : set_userList_filter_verified("Verified")
                  }
                  checked={userList_filter_verified}
                />
                <label
                  className="form-check-label"
                  htmlFor="userList_filter_verified"
                >
                  Verified
                </label>
              </div>

            </div>
          </div> */}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              tog_center();
            }}
          >
            CANCEL
          </button>
          <button
            type="button"
            className="btn bgcolor-btn-green"
            onClick={() => handleApply()}
          >
            APPLY
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Filter_userMgmt_component;
