import { es } from "date-fns/locale";
import React from "react";
import { Modal } from "reactstrap";

const PodiumCameraConfirmationAlert = ({
  id,
  content,
  modal_center,
  setmodal_center,
  title,
  onOK,
  okText,
  cancelText,
  citizenShip,
  onClose,
  cameraPurchased,
}) => {
  console.log("citizenship", citizenShip);

  return (
    <Modal isOpen={modal_center} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{title}</h5>
        <button
          type="button"
          onClick={() => {
            onClose();
            setmodal_center(false);
          }}
          className="close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">{content}</div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn bgcolor-btn-green"
          onClick={() => {
            onOK && onOK({ cameraViolation: true });
            setmodal_center(false);
          }}
        >
          {okText || "YES"}
        </button>
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            setmodal_center(false);
            if (
              citizenShip !== "CITIZEN" &&
              citizenShip !== "AMBASSADOR" &&
              citizenShip !== "OFFICER" &&
              citizenShip !== "LEADER" &&
              citizenShip !== "MINISTER" &&
              !cameraPurchased
            ) {
              onOK && onOK({ cameraViolation: false });
            } else {
              onClose();
            }
          }}
        >
          {cancelText || "NO"}
        </button>
      </div>
    </Modal>
  );
};

export default PodiumCameraConfirmationAlert;
