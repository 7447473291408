import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Alert, Container, CardBody, Card } from "reactstrap";
import { useTranslation } from "react-i18next";

import { connect, useSelector } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  checkNextValidScreen,
  getLocalStorage,
} from "../../helpers/utils"

// actions
import { apiError, socialLogin, loginUser, loginAuthentication, loginSuccess, getRolePermission } from "../../store/actions";

// import images
import logo from "../../assets/images/Messej-logo.svg";

const Login = (props) => {
  let [showPwd, setShowPwd] = useState(true);
  let [userName, setUserName] = useState();
  let [password, setPassword] = useState();
  let [otp, setOtp] = useState('');

  let [disableOtp, setDisableOtp] = useState(true);
  let [disableLogin, setDisableLogin] = useState(true);
  let [disableAuthentication, setDisableAuthentication] = useState(true);

  const [nextValidScreen, setNextValidScreen] = useState({});
  const { t } = useTranslation();

  const showPasswordClass = "fa fa-eye-slash";
  const hidePasswordClass = "fa fa-eye";

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, () => setDisableAuthentication(false));
  };

  const redirectToValidScreen = (roleData) => {
    setNextValidScreen(checkNextValidScreen(roleData));
    props.history.push(nextValidScreen);
  }

  // handleOtpAuthentication
  const handleAuthentication = (event, values) => {
    props.loginAuthentication(values, props.otpToken?.result, props.history, () => getUserPermissions());
  };

  const getUserPermissions = () => {
    props.getRolePermission(getLocalStorage("AUTH_DETAILS").user.role.id, (roleData) => redirectToValidScreen(roleData))
  }

  useEffect(() => {
    document.body.className = "authentication-bg cust-auth-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  //Checking whether there is username and password text for enabling Login Button
  useEffect(() => {
    if (!userName || !password) {
      setDisableOtp(true);
    } else {
      setDisableOtp(false);
    }
  }, [userName, password]);

  useEffect(() => {
    otp && otp.length === 6 ? setDisableLogin(false) : setDisableLogin(true)
  }, [otp])

  return getLocalStorage("AUTH_DETAILS") ? (
    <Redirect to={nextValidScreen} />
  ) :
    (
      <div data-testid="component-login">
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <Link to="/" className="mb-5 d-block auth-logo">
                    <img
                      src={logo}
                      alt=""
                      height="150"
                      className="logo logo-dark"
                      data-testid="component-login-logo"
                    />
                  </Link>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card>
                  <CardBody className="p-4">
                    <div
                      className="text-center mt-2"
                      data-testid="component-login-welcomeText"
                    >
                      <h5 className="text-primary">{t("Login.Welcome")}</h5>
                      <p className="text-muted">{t("Login.Title")}</p>
                      <hr></hr>
                    </div>
                    <div className="p-2 mt-4">
                      {disableAuthentication ? <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v);
                        }}
                      >
                        {props.error && typeof props.error === "string" ? (
                          <Alert color="danger">{props.error}</Alert>
                        ) : null}

                        <div className="mb-3">
                          <div className="cust-login-fieldWrapper">
                            <AvField
                              name="email"
                              label={t("Login.Email")}
                              value={userName}
                              className="form-control cust-login-textField"
                              placeholder={t("Login.Placeholder")}
                              type="text"
                              onChange={(e) => setUserName(e.target.value)}
                              data-testid="component-login-userName"
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          <div className="cust-login-fieldWrapper">
                            <AvField
                              name="password"
                              label={t("Login.Password")}
                              value={password}
                              className="cust-login-textField"
                              type={showPwd ? "password" : "text"}
                              placeholder={t("Login.Placeholder2")}
                              onChange={(e) => setPassword(e.target.value)}
                              data-testid="component-login-password"
                            />
                            <div
                              className="cust-pwd-eye"
                              onClick={() => setShowPwd(!showPwd)}
                              data-testid="component-login-passwordMask"
                            >
                              <i
                                className={
                                  showPwd ? showPasswordClass : hidePasswordClass
                                }
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3 loginBtnWrap">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light custom-red-btn"
                            type="submit"
                            disabled={disableOtp}
                          >
                            {t("Login.Btn")}
                          </button>
                        </div>
                      </AvForm> :
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleAuthentication(e, v);
                          }}
                        >{props.error && typeof props.error === "string" ? (
                          <Alert color="danger">{props.error}</Alert>
                        ) : null}
                          <div className="mb-3">
                            <div className="cust-login-fieldWrapper">
                              <AvField
                                name="otp"
                                label={t("Login.OTP")}
                                value={otp}
                                className="form-control cust-login-textField"
                                placeholder={t("Login.Placeholder3")}
                                type="text"
                                onChange={(e) => setOtp(e.target.value)}
                                data-testid="component-login-otp"
                              />
                            </div>
                          </div>
                          <div className="mt-3 loginBtnWrap">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light custom-red-btn"
                              type="submit"
                              disabled={disableLogin}
                            >
                              {t("Login.LoginBtn")}
                            </button>
                          </div>
                        </AvForm>}
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p></p>
                </div>
                <div className="mt-5 text-center">
                  <p className="copyright-text">
                    {t("Common.CopyRight1")} © {new Date().getFullYear()}.{" "}
                    {t("Common.CopyRight2")}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
};

const mapStateToProps = (state) => {
  return { ...state.Login };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin, loginAuthentication, loginSuccess, getRolePermission })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  otpToken: PropTypes.any,
  loginUser: PropTypes.func,
  loginAuthentication: PropTypes.func,
  getRolePermission: PropTypes.func
};
