import {
  GET_GIFT_TRANSFER_HISTORY_BEGIN,
  GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_BEGIN,
  GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_FAIL,
  GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_SUCCESS,
  GET_GIFT_TRANSFER_HISTORY_FAIL,
  GET_GIFT_TRANSFER_HISTORY_SUCCESS,
  GET_GRS_SUMMARY_BY_USER_ID_BEGIN,
  GET_GRS_SUMMARY_BY_USER_ID_FAIL,
  GET_GRS_SUMMARY_BY_USER_ID_SUCCESS,
} from "./actionTypes";

export const INIT_STATE = {
  loading: true,
  error: "",
  giftTransferData: {},
  giftTransferDataByUser: {},
  request: {
    sort: "transfered_date",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    page_count: 50,
    keyword: "",
    success: "",
  },
};

const giftTransfer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GIFT_TRANSFER_HISTORY_BEGIN:
      return {
        ...state,
        loading: true,
        giftTransferData: {},
      };
    case GET_GIFT_TRANSFER_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        giftTransferData: action.payload,
        error: "",
      };
    case GET_GIFT_TRANSFER_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        giftTransferData: {},
        error: action.payload,
      };
    case GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_BEGIN:
      return {
        ...state,
        loading: true,
        giftTransferDataByUser: {},
      };
    case GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        giftTransferDataByUser: action.payload,
      };
    case GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_GRS_SUMMARY_BY_USER_ID_BEGIN:
      return {
        ...state,
        loading: true,
        grsSummaryDataByUser: {},
      };
    case GET_GRS_SUMMARY_BY_USER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        grsSummaryDataByUser: action.payload,
      };
    case GET_GRS_SUMMARY_BY_USER_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default giftTransfer;
