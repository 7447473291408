export const GET_FLAX_TRANSFER_HISTORY = "GET_FLAX_TRANSFER_HISTORY";
export const GET_FLAX_TRANSFER_HISTORY_BEGIN =
  "GET_FLAX_TRANSFER_HISTORY_BEGIN";
export const GET_FLAX_TRANSFER_HISTORY_SUCCESS =
  "GET_FLAX_TRANSFER_HISTORY_SUCCESS";
export const GET_FLAX_TRANSFER_HISTORY_FAIL = "GET_FLAX_TRANSFER_HISTORY_FAIL";
export const GET_FLAX_TRANSFER_PURPOSES = "GET_FLAX_TRANSFER_PURPOSES";
export const GET_FLAX_TRANSFER_PURPOSES_BEGIN =
  "GET_FLAX_TRANSFER_PURPOSES_BEGIN";
export const GET_FLAX_TRANSFER_PURPOSES_SUCCESS =
  "GET_FLAX_TRANSFER_PURPOSES_SUCCESS";
export const GET_FLAX_TRANSFER_PURPOSES_FAIL =
  "GET_FLAX_TRANSFER_PURPOSES_FAIL";

export const ADD_FLAX_TRANSFER_PURPOSE = "ADD_FLAX_TRANSFER_PURPOSE";
export const ADD_FLAX_TRANSFER_PURPOSE_BEGIN =
  "ADD_FLAX_TRANSFER_PURPOSE_BEGIN";
export const ADD_FLAX_TRANSFER_PURPOSE_SUCCESS =
  "ADD_FLAX_TRANSFER_PURPOSE_SUCCESS";
export const ADD_FLAX_TRANSFER_PURPOSE_FAIL = "ADD_FLAX_TRANSFER_PURPOSE_FAIL";
export const DELETE_FLAX_TRANSFER_PURPOSE = "DELETE_FLAX_TRANSFER_PURPOSE";
export const DELETE_FLAX_TRANSFER_PURPOSE_BEGIN =
  "DELETE_FLAX_TRANSFER_PURPOSE_BEGIN";
export const DELETE_FLAX_TRANSFER_PURPOSE_SUCCESS =
  "DELETE_FLAX_TRANSFER_PURPOSE_SUCCESS";
export const DELETE_FLAX_TRANSFER_PURPOSE_FAIL =
  "DELETE_FLAX_TRANSFER_PURPOSE_FAIL";

export const GET_FLAX_TRANSFER_PURPOSE_BY_ID =
  "GET_FLAX_TRANSFER_PURPOSES_BY_ID";
export const GET_FLAX_TRANSFER_PURPOSE_BY_ID_BEGIN =
  "GET_FLAX_TRANSFER_PURPOSES_BY_ID_BEGIN";
export const GET_FLAX_TRANSFER_PURPOSE_BY_ID_SUCCESS =
  "GET_FLAX_TRANSFER_PURPOSES_BY_ID_SUCCESS";
export const GET_FLAX_TRANSFER_PURPOSE_BY_ID_FAIL =
  "GET_FLAX_TRANSFER_PURPOSES_BY_ID_FAIL";

export const EDIT_FLAX_TRANSFER_PURPOSE = "EDIT_FLAX_TRANSFER_PURPOSE";
export const EDIT_FLAX_TRANSFER_PURPOSE_BEGIN =
  "EDIT_FLAX_TRANSFER_PURPOSE_BEGIN";
export const EDIT_FLAX_TRANSFER_PURPOSE_SUCCESS =
  "EDIT_FLAX_TRANSFER_PURPOSE_SUCCESS";
export const EDIT_FLAX_TRANSFER_PURPOSE_FAIL =
  "EDIT_FLAX_TRANSFER_PURPOSE_FAIL";
export const RESET_PURPOSE_ERRORS = "RESET_PURPOSE_ERRORS";

//action types for individual user flax transfer history

export const GET_FLAX_TRANSFER_HISTORY_BY_USER_ID =
  "GET_FLAX_TRANSFER_HISTORY_BY_USER_ID";
export const GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_BEGIN =
  "GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_BEGIN";

export const GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_SUCCESS =
  "GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_SUCCESS";
export const GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_FAIL =
  "GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_FAIL";
