import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import Loader from "../../components/Common/Loader";
import Breadcrumbs from "../../components/UI/Breadcrumb";
import Datatable from "../../components/UI/Tables/Datatable";
import { wholeCoinsPurchaseColumnData } from "../../helpers/columns";
import { convertTime } from "../../helpers/utils";
import { getCoinsPurchaseHistory, exportCoinsPurchaseSummary } from "../../store/CoinsPurchaseHistory/actions";

import saveAs from "file-saver";
import { useTranslation } from "react-i18next";

const CoinPurchaseSummary = () => {
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "purchased_on",
    sort_order: "desc",
    page: 1,
    page_count: 10,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCoinsPurchaseHistory(request));
  }, [request]);

  const data = useSelector((state) => state.coinsPurchase);
  const profilePicClick = (e, id) => {
    e.preventDefault();
    const win = window.open(`/user-details/${id}?tab=1`, "_blank");
    win.focus();
  };

  const formatCoinPurchaseData = useCallback(() => {
    return (
      data?.coinsPurchaseHistory?.coin_purchase &&
      data?.coinsPurchaseHistory?.coin_purchase?.length &&
      data?.coinsPurchaseHistory?.coin_purchase?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,
        username: (
          <div
            onClick={(e) => {
              profilePicClick(e, result?.user_id);
            }}
            data-tag="allowRowEvents"
            title={result?.name}
          >
            {result?.name}
          </div>
        ),
        coins: (
          <div data-tag="allowRowEvents" title={result?.coins}>
            {result?.coins}
          </div>
        ),
        device_type: (
          <div data-tag="allowRowEvents" title={result?.device_type}>
            {result?.device_type}
          </div>
        ),
        price: (
          <div data-tag="allowRowEvents" title={result?.flax}>
            {result?.price + " " + (result.currency ? result.currency : "")}
          </div>
        ),
        purchased_on: (
          <div data-tag="allowRowEvents" title={result?.time_created}>
            {convertTime(result?.time_created)}
          </div>
        ),
      }))
    );
  });

  const addNewClickHandler = () => {
    dispatch(exportCoinsPurchaseSummary({ saveAs: saveAs, request: { ...request, export: "True" }}));
  };
  const coinsPurchaseHistory = useMemo(
    () => formatCoinPurchaseData(),
    [formatCoinPurchaseData]
  );
  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
    //   ref={pageWrapRef}
    >
      <Container id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="COiNS Purchase Summary" />

          <Datatable
            defaultSortField={request?.sort || "purchased_on"}
            defaultSortAsc={
              request?.sort_order === "asc" ? true : false
            }
            // dateFilter
            // dateType="Transfered Date Range"
            tableID={"flaxTable"}
            columns={wholeCoinsPurchaseColumnData}
            rows={coinsPurchaseHistory}
            setRequest={setRequest}
            request={request}
            reqplatformFilter={request?.device_types}
            totalRecords={data?.coinsPurchaseHistory?.total}
            search
            // searchTerm={data?.request?.keyword}
            // reqstatusFilter={request?.status_filter}
            loading={data?.loading}
            tableCardClassName={"snoTable"}
            filterCoinsPurchaseSummary
            addNewClickHandler={addNewClickHandler}
            addNewLabel={t("UserManagement.export")}
            add
            dateFilter
            dateType="Transfered Date Range"
          />
        </Row>
      </Container>

      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default CoinPurchaseSummary;
