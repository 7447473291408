import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import Loader from "../../../components/Common/Loader";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Datatable from "../../../components/UI/Tables/Datatable";
import { reportedFlashColumnData } from "../../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../helpers/constants";
import { checkUserPermission, convertToUAETime } from "../../../helpers/utils";
import { getFlaxPurchaseHistory } from "../../../store/flaxPackages/actions";
import {
  deletePostat,
  getPostatReportedPosts,
} from "../../../store/postatManagement/actions";
import Block_icon from "../../../assets/icons/ebud-icons/Block.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";

import { blockUser } from "../../../store/users/actions";
import { ImagePreview } from "../../../components/Common/imagePreview";
import {
  deleteFlashes,
  getReportedFlash,
} from "../../../store/flashManagement/actions";
import { VideoPreview } from "../../UserMangement/TabSection/Flashes/VideoPreview";

const ReportedFlash = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [imgUrl, setImgUrl] = useState("");
  const [isOpen, setOpen] = useState(false);
  const basicRequest = {
    // sort: "transfered_date",
    // sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
  };
  const history = useHistory();
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const warningAlertRef = useRef(null);

  const [request, setRequest] = useState({ ...basicRequest });

  const loginData = useSelector((state) => state.Login);

  const [userPermissions, setUserPermissions] = useState({});
  const [flashPermissions, setFlashPermissions] = useState({});
  const isDeleteable = flashPermissions?.delete || false;
  const isBlockable = userPermissions?.edit || false;

  useEffect(() => {
    dispatch(getReportedFlash(request));
  }, [request]);
  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setFlashPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Flash Management",
          "Reported flash"
        )
      );
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Users"
        )
      );
    }
  }, [loginData]);
  const data = useSelector((state) => state.FlashCategories);

  const previewVideo = (url) => {
    setOpen(true);
    setSelectedRow({ video_url: url });
  };

  const formatReportedFlash = useCallback(() => {
    return (
      data?.reportedFlash &&
      data?.reportedFlash?.items?.length > 0 &&
      data?.reportedFlash?.items?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        post_thumbnail: (
          <div
            onClick={(e) => {
              e.preventDefault();
              previewVideo(result?.video_url);
            }}
            title={"click to preview"}
          >
            <img width={28} src={result?.thumbnail_url} alt="user" />
          </div>
        ),
        post_owner_name: (
          // <div
          //   className="profileWrap"
          //   title={result?.post_owner_info?.name}
          //   data-tag="allowRowEvents"
          // >
          //   <div className="d-flex">
          //     <div data-tag="allowRowEvents">
          //       <img
          //         className="table_profileImg"
          //         src={result?.post_owner_info?.profile_url}
          //       />
          //     </div>

          //     <div className="mx-2 mt-3" title={result?.name}>
          //       {result?.post_owner_info?.name}{" "}
          //     </div>
          //   </div>
          // </div>
          <>
            <div data-tag="allowRowEvents" title={result?.owner?.name}>
              {result?.owner?.name}
            </div>
            <div data-tag="allowRowEvents" title={result?.owner?.username}>
              {result?.owner?.username}
            </div>
            {result?.owner?.user_blocked && (
              <div className="badge bg-soft-secondary font-size-6 my-auto ">
                Blocked user
              </div>
            )}
          </>
        ),
        reported_count: (
          <div data-tag="allowRowEvents" title={result?.reported_count}>
            {result?.reports_count}
          </div>
        ),
        actions: (
          <div>
            <div className="cust-table-actions-wrap">
              {!result?.owner?.user_blocked && (
                <button
                  onClick={() =>
                    blockPromptHandler(result?.owner?.id, result.flash_id)
                  }
                  className=" action-btn"
                  style={{
                    fontSize: "11px",
                    padding: "5px",
                    backgroundColor: "#dedfe0",
                    color: "#969696",
                    fontWeight: "bold",
                    opacity: !isDeleteable || !isBlockable ? "50%" : "",
                    cursor: !isDeleteable || !isBlockable ? "not-allowed" : "",
                  }}
                  title="Block Owner"
                  disabled={!isDeleteable || !isBlockable}
                >
                  BLOCK AND DELETE
                </button>
              )}

              <button
                onClick={() => deletePromptHandler(result?.flash_id)}
                className=" action-btn"
                title="Delete Post"
                disabled={!isDeleteable}
                style={{
                  fontSize: "11px",
                  padding: "5px",
                  backgroundColor: "#fadede",
                  fontWeight: "bold",
                  color: "#fa5f5f",
                  opacity: !isDeleteable ? "50%" : "",
                  cursor: !isDeleteable ? "not-allowed" : "",
                }}
              >
                DELETE
              </button>
            </div>
          </div>
        ),
      }))
    );
  }, [request, data?.reportedFlash?.items]);
  const reportedPosts = useMemo(
    () => formatReportedFlash(),
    [formatReportedFlash]
  );

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to delete this Flash from the App?`,
      type: "delete",
    });
  };

  const blockPromptHandler = (userId, postId) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      userId: userId,
      postId: postId,
      content: `Are you sure you want to block this user and delete the Flash from the App? `,
      type: "block-del",
    });
  };

  const okHandler = () => {
    promptMessage.type === "delete"
      ? dispatch(
          deleteFlashes({
            id: promptMessage?.id,

            callback: () => dispatch(getReportedFlash(request)),
          })
        )
      : dispatch(
          blockUser({
            data: { blocked: true },
            id: promptMessage?.userId,
          })
        ) &&
        dispatch(
          deleteFlashes({
            id: promptMessage?.postId,

            callback: () => dispatch(getReportedFlash(request)),
          })
        );
  };

  const tableRowClickHandler = (param) => {
    // history.push(`/payout-requests/${param.id}`);
    history.push({
      pathname: `/reported-flash/${param.flash_id}`,
      state: { postId: param.flash_id },
    });
  };

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        // id="userMgmtList"
        // ref={pageWrapRef}
      >
        <Container id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Reported Flash" />
            {data?.error && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color="danger"
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error}
                </UncontrolledAlert>
              </div>
            )}
            <div className="container-fluid">
              <Datatable
                tableID={"userTable"}
                columns={reportedFlashColumnData}
                rows={reportedPosts}
                setRequest={setRequest}
                request={request}
                // dateFilter
                totalRecords={data?.reportedFlash?.total}
                // search
                // dateType="Purchased Date Range"
                // add
                // dropDown
                // languages={languages}
                // addNewClickHandler={addNewClickHandler}
                // selectedLanguage={selectedLanguage}
                // setSelectedLanguage={setSelectedLanguage}
                // addNewLabel={t("Admins.addAdmin.addBtn")}
                // searchTerm={data?.request?.keyword}
                // loading={data?.loading}
                tableRowClicked={(param) => tableRowClickHandler(param)}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
          <VideoPreview
            selectedRow={selectedRow}
            isOpen={isOpen}
            setOpen={setOpen}
          />
          {/* <ImagePreview
            imgUrl={imgUrl}
            isOpen={isOpen}
            setOpen={setOpen}
            title="Reported Post"
          /> */}
        </Container>
        <ConfirmationAlert
          {...promptMessage}
          modal_center={showPromptPopUp}
          setmodal_center={setShowPromptPopUp}
          onOK={okHandler}
        />

        {(data?.loading || data?.deleteLoading) && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default ReportedFlash;
