import {
  ADD_ANNOUNCEMENTS_BEGIN,
  ADD_ANNOUNCEMENTS_FAIL,
  ADD_ANNOUNCEMENTS_SUCCESS,
  DELETE_ANNOUNCEMENTS_BEGIN,
  DELETE_ANNOUNCEMENTS_FAIL,
  DELETE_ANNOUNCEMENTS_SUCCESS,
  GET_ANNOUNCEMENTS_BEGIN,
  GET_ANNOUNCEMENTS_BY_ID_BEGIN,
  GET_ANNOUNCEMENTS_BY_ID_FAIL,
  GET_ANNOUNCEMENTS_BY_ID_SUCCESS,
  GET_ANNOUNCEMENTS_FAIL,
  GET_ANNOUNCEMENTS_SUCCESS,
  RESET_ANNOUNCEMENT_ERRORS,
} from "./actionTypes";

export const INIT_STATE = {
  announcements: {},
  announcementData: {},
  loading: false,
  error: "",
  unseenCount: "",
  success: "",
};

const announcement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ANNOUNCEMENTS_BEGIN:
      return {
        ...state,
        announcements: [],
        loading: true,
      };
    case GET_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        announcements: action.payload,
        loading: false,
      };
    case GET_ANNOUNCEMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_ANNOUNCEMENTS_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        announcementData: {},
      };
    case GET_ANNOUNCEMENTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        announcementData: action.payload,
      };
    case GET_ANNOUNCEMENTS_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        announcementData: {},
        error: action.payload,
      };
    case DELETE_ANNOUNCEMENTS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    case DELETE_ANNOUNCEMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_ANNOUNCEMENTS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: "",
      };
    case ADD_ANNOUNCEMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_ANNOUNCEMENT_ERRORS:
      return {
        ...state,
        error: "",
        success: "",
      };

    default:
      return state;
  }
};
export default announcement;
