import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {GET_REGISTRATION_LOGIN_HISTORY} from './actionTypes';
import {
    getRegistrationAndLoginBegin,
    getRegistrationAndLoginSuccess,
    getRegistrationAndLoginFail
} from './action';

import { get } from "../../../helpers/api_helper";
import {GET_REGN_LOGIN_HISTORY} from '../../../helpers/url_helper';


function* getRegistrationLoginData({payload}) {
    try{
        yield put(getRegistrationAndLoginBegin())
        const response = yield call(get,GET_REGN_LOGIN_HISTORY.replace("{id}",payload.id),payload.request)
        yield put(getRegistrationAndLoginSuccess(response.result))
    }catch (error){
        yield put(getRegistrationAndLoginFail(error))
    }
}



function* registrationLoginSaga() {
    yield takeLatest(GET_REGISTRATION_LOGIN_HISTORY, getRegistrationLoginData);
}

export default registrationLoginSaga