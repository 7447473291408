import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import Breadcrumbs from "../../components/UI/Breadcrumb";
import Datatable from "../../components/UI/Tables/Datatable";
import {
  allFlashCoumnData,
  newSubscriptionsColumnData,
} from "../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../helpers/constants";
import {
  checkUserPermission,
  convertTime,
  convertToUAETime,
} from "../../helpers/utils";
import {
  blockOrUnblockUserFromFlash,
  deleteFlashes,
  getAllFlash,
  getFlashes,
} from "../../store/flashManagement/actions";
import { exportAppSubscriptionDetails } from "../../store/appSubscription/action"

import saveAs from "file-saver";

import Delete_icon from "../../assets/icons/ebud-icons/Delete.svg";
import Loader from "../../components/Common/Loader";
import ConfirmationAlert from "../../components/UI/ConfirmationAlert";
import { blockUser } from "../../store/users/actions";
import { getNewSubscriptions } from "../../store/appSubscription/action";
import { useTranslation } from "react-i18next";

const AppSubscriptions = () => {
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "payment_date",
    sort_order: "desc",
    page: 1,
    page_count: 50,
    keyword: "",
    export: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });

  const loginData = useSelector((state) => state.Login);
  const data = useSelector((state) => state.appSubscriptions);

  const { t } = useTranslation();

  const subscriptions = data?.newSubscriptions?.subscriptions_list?.map(
    (item, index) => ({
      ...item,

      no: (request?.page - 1) * request?.page_count + index + 1,
      payment_date: (
        <>
          <div data-tag="allowRowEvents" title={item?.payment_date}>
            {convertTime(item?.payment_date)}
          </div>
        </>
      ),
      user: (
        <>
          {item?.username ? (
            <div
              onClick={(e) => {
                profilePicClick(e, item?.user_id);
              }}
            >
              <div data-tag="allowRowEvents" title={item?.name}>
                {item?.name}
              </div>

              <div data-tag="allowRowEvents" title={item?.username}>
                {item?.username}
              </div>
            </div>
          ) : (
            <div className="badge bg-soft-secondary font-size-6 my-auto ">
              Deleted User
            </div>
          )}
        </>
      ),
      platform: (
        <>
          <div data-tag="allowRowEvents" title={item?.device_type}>
            {item?.device_type}
          </div>
        </>
      ),
      expiry: (
        <>
          <div data-tag="allowRowEvents" title={item?.expiration_date}>
            {convertTime(item?.expiration_date)}
          </div>
        </>
      ),
      amount: (
        <>
          <div data-tag="allowRowEvents" title={item?.payment_amount}>
            {item?.payment_amount + " " + item?.currency}
          </div>
        </>
      ),
      sub_renewal: (
        <>
          <div data-tag="allowRowEvents" title="">
            {item?.renew ? "Renewal" : "Subscription"}
          </div>
        </>
      ),
      refund: (
        <>
          <div data-tag="allowRowEvents" title={item?.status}>
            {item?.status}
          </div>
        </>
      ),
    })
  );

  const addNewClickHandler = () => {
    dispatch(exportAppSubscriptionDetails({ saveAs: saveAs, request: { ...request, export: "True" }}));
  };

  useEffect(() => {
    dispatch(getNewSubscriptions(request));
  }, [request]);
  const profilePicClick = (e, id) => {
    e.preventDefault();
    const win = window.open(`/user-details/${id}?tab=1`, "_blank");
    win.focus();
  };
  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
    //   ref={pageWrapRef}
    >
      <Container id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="App Subscriptions" />
          {/* {data?.error && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            </div>
          )} */}

          <Datatable
            defaultSortField={request?.sort || "payment_date"}
            defaultSortAsc={request?.sort_order === "asc" ? true : false}
            tableID={"reviewTable"}
            columns={newSubscriptionsColumnData}
            rows={subscriptions}
            setRequest={setRequest}
            request={request}
            totalRecords={data?.newSubscriptions?.total}
            search
            filterAppSubscriptions
            add
            //   podiumFilter
            //   reqTypeFilter={request.type}
            // tableRowClicked={(param) => onRowClicked(param)}
            // addNewLabel={t("Admins.addAdmin.addBtn")}
            addNewClickHandler={addNewClickHandler}
            addNewLabel={t("UserManagement.export")}
            // searchTerm={data?.request?.keyword}
            // reqstatusFilter={request?.status_filter}
            reqplatformFilter={request?.device_types}
            // reqmembershipFilter={request?.membership_filter}
            // statusFilter={data?.request?.status_filter}
            // loading={data?.loading}
            tableCardClassName={"snoTable"}
            // deleteLabel="DELETE"
            // deleteClickHandler={deleteAll}
            // deleteDisabled={!selectedHuddles.length}
            // showDeleteButton={true}
            // deleteConfirmationMsg="Do you really want to delete the selected huddles?"
            dateFilter
            dateType="Transfered Date Range"
          />
        </Row>
      </Container>

      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default AppSubscriptions;
