import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, UncontrolledAlert } from "reactstrap";
import Loader from "../../../../components/Common/Loader";

import Checkbox from "../../../../components/UI/Checkbox";
import ConfirmationAlert from "../../../../components/UI/ConfirmationAlert";
import {
  addEmpowermentDetails,
  getEmpowermentDetails,
  updateEmpowermentDetails,
} from "../../../../store/users/empowerment/action";

const Empowerment = ({ userId, activeTab }) => {
  const dispatch = useDispatch();
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);
  const [hasChanged, setHasChanged] = useState(true);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [checkBoxValues, setCheckBoxValues] = useState({
    allow_hide_flag: false,
    allow_change_location: false,
    allow_block_user_huddle: false,
    allow_block_user_flashat: false,
    allow_delete_post_huddle: false,
    allow_enter_huddle: false,
    allow_delete_flash_video: false,
    allow_end_podium: false,
    // allow_join_hidden_podium: false,
    allow_end_speaking_session_podium: false,
    allow_block_user_speaking_podium: false,
    allow_delete_comments_podium: false,
    allow_block_user_comments_podium: false,
    // allow_block_user_podium: false,
    // allow_hide_online_status: false,
    // allow_delete_postat_post: false,
    // allow_freeze_user_comments_podium: false,
    allow_block_user_posting_postat: false,
    allow_block_user_posting_flash: false,
    allow_block_user_posting_huddle: false,
    // allow_see_hidden_users_podium: false,
  });
  const data = useSelector((state) => state.empowermentDetails);

  const handleCheckboxChange = (checkboxName) => {
    setCheckBoxValues((prevValues) => ({
      ...prevValues,
      [checkboxName]: !prevValues[checkboxName],
    }));
    setHasChanged(false);
  };
  // useEffect(() => {
  //   if (
  //     checkBoxValues.allow_block_user_huddle === true ||
  //     checkBoxValues.allow_delete_post_huddle === true
  //   ) {
  //     setCheckBoxValues((prevValues) => ({
  //       ...prevValues,
  //       allow_enter_huddle: true,
  //     }));
  //   }
  // }, [
  //   checkBoxValues.allow_delete_post_huddle,
  //   checkBoxValues.allow_block_user_huddle,
  //   checkBoxValues.allow_enter_huddle,
  // ]);

  useEffect(() => {
    const empowermentDetails = data?.empowermentDetails;

    setCheckBoxValues({
      allow_hide_flag: empowermentDetails?.allow_hide_flag || false,
      allow_change_location: empowermentDetails?.allow_change_location || false,
      allow_block_user_huddle:
        empowermentDetails?.allow_block_user_huddle || false,
      allow_block_user_flashat:
        empowermentDetails?.allow_block_user_flashat || false,
      allow_delete_post_huddle:
        empowermentDetails?.allow_delete_post_huddle || false,
      allow_enter_huddle: empowermentDetails?.allow_enter_huddle || false,
      allow_delete_flash_video:
        empowermentDetails?.allow_delete_flash_video || false,
      allow_end_podium: empowermentDetails?.allow_end_podium || false,
      // allow_join_hidden_podium: empowermentDetails?.allow_join_hidden_podium || false,
      allow_end_speaking_session_podium: empowermentDetails?.allow_end_speaking_session_podium || false,
      allow_block_user_speaking_podium: empowermentDetails?.allow_block_user_speaking_podium || false,
      allow_delete_comments_podium: empowermentDetails?.allow_delete_comments_podium || false,
      allow_block_user_comments_podium: empowermentDetails?.allow_block_user_comments_podium || false,
      // allow_block_user_podium: empowermentDetails?.allow_block_user_podium || false,
      // allow_hide_online_status: empowermentDetails?.allow_hide_online_status || false,
      // allow_delete_postat_post: empowermentDetails?.allow_delete_postat_post || false,
      // allow_freeze_user_comments_podium: empowermentDetails?.allow_freeze_user_comments_podium || false,
      allow_block_user_posting_postat: empowermentDetails?.allow_block_user_posting_postat || false,
      allow_block_user_posting_flash: empowermentDetails?.allow_block_user_posting_flash || false,
      allow_block_user_posting_huddle: empowermentDetails?.allow_block_user_posting_huddle || false,
      // allow_see_hidden_users_podium: empowermentDetails?.allow_see_hidden_users_podium || false
    });
  }, [data.empowermentDetails]);

  useEffect(() => {
    // Fetch empowerment details when userId changes
    if (activeTab === "7") dispatch(getEmpowermentDetails(userId));
  }, [activeTab]);
  const scrollToAlertMessage = () => {
    if (data?.error) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      content: "Are you sure you want to modify the user permissions?",
    });
  };
  const okHandler = () => {
    setHasChanged(true);
    dispatch(addEmpowermentDetails({ id: userId, payload: checkBoxValues }));
  };

  return (
    <div ref={pageWrapRef} className="p-5">
      <div ref={warningAlertRef}>
        {(data?.error || data?.success) && (
          <UncontrolledAlert
            color={data?.error ? "danger" : "success"}
            className="alert-dismissible fade show"
            role="alert"
          >
            {data?.success || data?.error}
          </UncontrolledAlert>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        {/* Map through the checkbox labels and render Checkbox component */}
        {Object.entries(checkBoxValues).map(([key, value]) => (
          <Row key={key} className="p-4">
            <Col md="6">
              <label className={`empowerment-labels pl-5`}>
                {labelTexts[key]}
              </label>
            </Col>
            <Col md="6">
              <div className="form-check form-switch form-switch-lg enableDocumentSwitchWrap px-0 pt-2 pb-4">
                <div className="px-5">
                  <Checkbox
                    value={value}
                    id={key}
                    name="push"
                    handleChange={() => handleCheckboxChange(key)}
                    className="form-check-input"
                  />
                </div>
              </div>
            </Col>
          </Row>
        ))}
        <Row className="p-4">
          <Col md="12" className="">
            <button
              disabled={hasChanged}
              type="submit"
              className="btn bgcolor-btn-green"
            >
              Update
            </button>
          </Col>
        </Row>
      </form>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

const labelTexts = {
  allow_hide_flag: "Allow To Hide Flag",
  allow_change_location: "Allow To Change Location",
  allow_block_user_huddle: "Allow To Block Any User from Any Huddle",
  allow_block_user_flashat: "Allow To Block Any User From Flashat ",
  allow_delete_post_huddle: "Allow To Delete Any Post From Any Huddle",
  allow_enter_huddle:
    "Allow to Enter Any Huddle (Join & Request To Join) Without Authorization",
  allow_delete_flash_video: "Allow To Delete Any Flash Video",
  allow_end_podium: "Allow To End Podium",
  // allow_join_hidden_podium: "Allow To Join Hidden In Podiums",
  allow_end_speaking_session_podium: "Allow To End Speaking Session Of Any Speaker In Podiums",
  allow_block_user_speaking_podium: "Allow To Block Any User From Speaking In Podiums",
  allow_delete_comments_podium: "Allow To Delete Any Comments From Podiums",
  allow_block_user_comments_podium: "Allow To Block Any User From Writing Comments In Podiums",
  // allow_block_user_podium: "Allow To Block Any User From Podiums",
  // allow_hide_online_status: "Allow To Hide Online Status",
  // allow_delete_postat_post: "Allow To Delete Any Postat Post",
  // allow_freeze_user_comments_podium: "Allow To Freeze Comments Of Any User In Podiums",
  allow_block_user_posting_postat: "Allow To Block Any User From Posting In Postat",
  allow_block_user_posting_flash: "Allow To Block Any User From Publishing Flash Videos",
  allow_block_user_posting_huddle: "Allow To Block Any User From Posting On Huddles",
  // allow_see_hidden_users_podium: "Allow To See Hidden Users In Podium",
};

export default Empowerment;
