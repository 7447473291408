import React,{useState,useEffect} from 'react';
import { Modal } from "reactstrap";

const ReportedDataFilter = ({setRequest,request,reportStatusFilter,categoryFilter}) => {
    const [modal_center, setmodal_center] = useState(false);
    const [reportedActive,setReportedActive] = useState(false);
    const [reportedCancelled,setReportedCancelled] = useState(false);
    const [categoryVal,setCategoryVal] = useState({});
    
    function tog_center() {
        removeBodyCss();
        if(!modal_center) {
            const removedValues = Object.keys(categoryVal)?.filter(x => !request?.report_categories?.includes(x));
            removedValues?.map(item => delete categoryVal[item]);
        }
        Object.keys(categoryVal).forEach(v => categoryVal[v] = true)
        request?.status_filter?.includes('active') ? setReportedActive('active') : setReportedActive(false);
        request?.status_filter?.includes('cancelled') ? setReportedCancelled('cancelled') : setReportedCancelled(false);
        setmodal_center(!modal_center);

    }
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const filter_clearAll = () => {
        setReportedActive(false);
        setReportedCancelled(false);
        setCategoryVal({})
    }

    const handleApply = () => {
        setRequest({
            ...request,
            status_filter:[
                reportedActive,
                reportedCancelled
            ].filter((item) => item),
            report_categories:Object.entries(categoryVal).filter(item => item[1]).map((category) => category[0])

        })
        tog_center()
    }

    const reportedCategoryChange = (event) => {
        setCategoryVal({...categoryVal,[`${event.target.name}`]:event.target.checked})
    }

    return (
        <div className="">
            <button
                type="button"
                className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                onClick={() => {
                tog_center();
                }}
            >
                <i
                style={{ paddingRight: "10px" }}
                className="fa fa-filter"
                aria-hidden="true"
                ></i>
                FILTER
            </button>

            <Modal
                isOpen={modal_center}
                toggle={() => {
                tog_center();
                }}
                centered={true}
            >
                <div className="modal-header userList-filter-header">
                    <h5 className="modal-title mt-0">FILTER</h5>
                    <button
                        onClick={() => filter_clearAll()}
                        className="btn waves-effect waves-light cust_no_shadow userList-filter-clearAll"
                        data-testid="component-filterClearall"
                    >
                        CLEAR ALL
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                        setmodal_center(false);
                        }}
                        className="close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body userList-filter-body">
                    <div className="reportedUser-filter-SectionWrap">
                        <h5 className="reportedUser-filter-SectionTitle">Status</h5>
                        <div className="reportedUser_statusWrap">
                            <div className="userList-filter-checkBoxWrap">
                                <div>
                                    <input
                                    className="form-check-input cursor-pointer"
                                    type="checkbox"
                                    value={reportedActive}
                                    id="reported_status_active"
                                    onChange={() => 
                                        reportedActive ? setReportedActive(!reportedActive):
                                        setReportedActive("active")
                                    }
                                    checked={reportedActive}
                                    />
                                    <label
                                    className="form-check-label"
                                    htmlFor="reported_status_active"
                                    >
                                    Active
                                    </label>
                                </div>
                            </div>

                            <div>
                                <input
                                className="form-check-input cursor-pointer"
                                type="checkbox"
                                value={reportedCancelled}
                                id="reported_status_cancelled"
                                onChange={() => 
                                    reportedCancelled ? setReportedCancelled(!reportedCancelled):
                                    setReportedCancelled("cancelled")
                                }
                                checked={reportedCancelled}
                                />
                                <label
                                className="form-check-label"
                                htmlFor="reported_status_cancelled"
                                >
                                Cancelled
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="userList-filter-SectionWrap">
                        <h5 className="userList-filter-SectionTitle">Category</h5>
                        <div className="reportedUser-filter-checkBoxWrap">
                            {categoryFilter?.report_categories?.map((category) => {
                                return (
                                    <div key={category.category}>
                                        <input
                                        className="form-check-input cursor-pointer"
                                        type="checkbox"
                                        value={categoryVal[category?.category_type] || false}
                                        id={category.category}
                                        name={category.category_type}
                                        onChange={(event) => reportedCategoryChange(event)}
                                        checked={categoryVal[`${category.category_type}`] || false}
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor={category.category}
                                        >
                                        {category.category}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                    tog_center();
                    }}
                    data-testid="component-filter-cancel"
                >
                    CANCEL
                </button>
                <button
                    type="button"
                    className="btn bgcolor-btn-green"
                    onClick={() => handleApply()}
                    data-testid="component-filter-apply"
                >
                    APPLY
                </button>
                </div>
            </Modal>
        </div>
    )
}

export default ReportedDataFilter
