import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";

const PodiumFilter = ({ request, setRequest, reqTypeFilter }) => {
  const [modal_center, setmodal_center] = useState(false);
  const [privatePodium, setPrivatePodium] = useState(false);
  const [publicPodium, setPublicPodium] = useState(false);
  const { t } = useTranslation();

  //   useEffect(() => {
  //     reqTypeFilter?.includes("private")
  //       ? setPrivatePodium("private")
  //       : setPrivatePodium(false);
  //     reqTypeFilter?.includes("public")
  //       ? setPublicPodium("public")
  //       : setPublicPodium(false);
  //   }, [reqTypeFilter, modal_center]);

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  const filter_clearAll = () => {
    setPrivatePodium(false);
    setPublicPodium(false);
  };
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const handleApply = () => {
    setRequest({
      ...request,

      type: privatePodium || publicPodium,
    });
    setmodal_center(false);
  };
  return (
    <div className="user-filterBtn-wrap" data-testid="component-filterPopup">
      <button
        type="button"
        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
        onClick={() => {
          tog_center();
        }}
        data-testid="component-filterButton"
      >
        <i
          style={{ paddingRight: "10px" }}
          className="fa fa-filter"
          aria-hidden="true"
        ></i>
        FILTER
      </button>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={true}
      >
        <div className="modal-header userList-filter-header">
          <h5 className="modal-title mt-0">FILTER</h5>
          <button
            onClick={() => filter_clearAll()}
            className="btn waves-effect waves-light cust_no_shadow userList-filter-clearAll"
          >
            CLEAR ALL
          </button>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
            // data-dismiss="modal"
            // aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body userList-filter-body">
          <div className="userList-filter-SectionWrap">
            <h5 className="userList-filter-SectionTitle">Podium Type</h5>
            <div className="userList-filter-checkBoxWrap">
              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={privatePodium}
                  id="userList_filter_free"
                  onChange={() => {
                    if (privatePodium) {
                      setPrivatePodium(!privatePodium);
                    } else {
                      setPrivatePodium("private");
                      setPublicPodium(false);
                    }
                  }}
                  checked={privatePodium}
                />
                <label
                  className="form-check-label"
                  htmlFor="userList_filter_free"
                >
                  private
                </label>
              </div>

              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={publicPodium}
                  id="userList_filter_premium"
                  onChange={() => {
                    if (publicPodium) {
                      setPublicPodium(!publicPodium);
                    } else {
                      setPublicPodium("public");
                      setPrivatePodium(false);
                    }
                  }}
                  checked={publicPodium}
                />
                <label
                  className="form-check-label"
                  htmlFor="userList_filter_premium"
                >
                  public
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              tog_center();
            }}
          >
            CANCEL
          </button>
          <button
            type="button"
            className="btn bgcolor-btn-green"
            onClick={() => handleApply()}
          >
            APPLY
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default PodiumFilter;
