import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  GET_ALLOCATED_NAMES, GET_ALLOCATED_NAME_ID,
  GET_ALLOCATED_NAME_HISTORY, USER_SEARCH, ALLOCATE_USERNAME, DELETE_SUGGESTION, UPDATE_ALLOCATED_USERNAME,
  CHECK_USERNAME_AVAILABILITY
} from "./actionTypes";
import {
  getAllocatedNamesBegin,
  getAllocatedNamesSuccess,
  getAllocatedNamesFail,
  getAllocateUserNameBegin,
  getAllocateUserNameSuccess,
  getAllocateUserNameFail,
  getAllocateUserHistoryBegin,
  getAllocateUserHistorySuccess,
  getAllocateUserHistoryFail,
  getUserBegin,
  getUserSuccess,
  getUserFail,
  deleteSuggestionBegin,
  deleteSuggestionSuccess,
  deleteSuggestionFail,
  allocateUsernameBegin,
  allocateUsernameSuccess,
  allocateUsernameFail,
  updateAllocatedUserNameBegin,
  updateAllocatedUserNameSuccess,
  updateAllocatedUserNameFail,
  checkUsernameAvailabilityBegin,
  checkUsernameAvailabilitySuccess,
  checkUsernameAvailabilityFail
} from "./action";
import {
  ALLOCATE_USERNAME_BY_ID,
  GET_ALLOCATED_USERNAME_HISTORY,
  API_USER_SEARCH,
  API_ALLOCATE_USERNAME,
  API_CHECK_AVAILABILITY
} from "../../../helpers/url_helper";
import { get, post, del, update } from "../../../helpers/api_helper";

function* getAllocatedNames({ payload }) {
  try {
    yield put(getAllocatedNamesBegin());
    const response = yield call(get, API_ALLOCATE_USERNAME, payload);
    yield put(getAllocatedNamesSuccess(response.result));
  } catch (error) {
    yield put(getAllocatedNamesFail(error));
  }
}

function* getUser({ payload, history }) {
  try {
    yield put(getUserBegin());
    const response = yield call(get, API_USER_SEARCH, {
      ...payload,
    });
    yield put(getUserSuccess(response.result?.allocation ? undefined : response.result));
    response.result?.allocation && history.push(`/allocate-usernames-edit/${response.result?.allocation}`)
  } catch (error) {
    yield put(getUserFail(error));
  }
}

function* getAllocatedNameDetail({ id }) {
  try {
    yield put(getAllocateUserNameBegin());
    const response = yield call(
      get,
      ALLOCATE_USERNAME_BY_ID.replace("{id}", id)
    );
    yield put(getAllocateUserNameSuccess(response.result));
  } catch (error) {
    yield put(getAllocateUserNameFail(error));
  }
}

function* getAllocatedNameHistory({ id }) {
  try {
    yield put(getAllocateUserHistoryBegin());
    const response = yield call(get, GET_ALLOCATED_USERNAME_HISTORY.replace("{id}", id));
    yield put(getAllocateUserHistorySuccess(response.result));
  } catch (error) {
    yield put(getAllocateUserHistoryFail(error));
  }
}

function* deleteSuggestion({ id, callback }) {
  try {
    yield put(deleteSuggestionBegin());
    const response = yield call(
      del,
      ALLOCATE_USERNAME_BY_ID.replace("{id}", id)
    );
    callback && callback();
    yield put(deleteSuggestionSuccess(response.result));
  } catch (error) {
    yield put(deleteSuggestionFail(error));
  }
}

function* allocateUsername({ data, callback, history }) {
  try {
    yield put(allocateUsernameBegin());
    const response = yield call(post, API_ALLOCATE_USERNAME, { ...data });
    yield put(allocateUsernameSuccess(data?.force ? 'Forcefully changed the username' : 'Suggestion sent to user successfully'));
    if (window.location.href.includes('allocate-usernames-edit')) {
      window.location.href = `/allocate-usernames-edit/${response?.result?.id}`;
    }


    callback && callback();
  } catch (error) {
    yield put(allocateUsernameFail(error));
  }
}

function* updateAllocatedUsername({ id, payload, callback, history }) {
  try {
    yield put(updateAllocatedUserNameBegin());
    const response = yield call(
      update,
      ALLOCATE_USERNAME_BY_ID.replace("{id}", id),
      {
        ...payload
      }
    );
    callback && callback();

    yield put(updateAllocatedUserNameSuccess(payload?.force ? 'Forcefully changed the username' : 'Suggestion sent to user successfully'));
    window.location.href =`/allocate-usernames-edit/${response?.result?.id}`;
  } catch (error) {
    yield put(updateAllocatedUserNameFail(error));
  }
}

function* checkUsernameAvailability({ payload }) {
  try {
    yield put(checkUsernameAvailabilityBegin());
    let response = yield call(get, API_CHECK_AVAILABILITY, payload);
    response = {
      ...response,
      username: payload?.username
    }
    yield put(checkUsernameAvailabilitySuccess(response));
  } catch (error) {
    yield put(checkUsernameAvailabilityFail(error));
  }
}

function* allocatedUsernameSaga() {
  yield takeEvery(GET_ALLOCATED_NAMES, getAllocatedNames);
  yield takeEvery(GET_ALLOCATED_NAME_ID, getAllocatedNameDetail);
  yield takeEvery(GET_ALLOCATED_NAME_HISTORY, getAllocatedNameHistory);
  yield takeEvery(USER_SEARCH, getUser);
  yield takeLatest(DELETE_SUGGESTION, deleteSuggestion);
  yield takeEvery(ALLOCATE_USERNAME, allocateUsername);
  yield takeEvery(UPDATE_ALLOCATED_USERNAME, updateAllocatedUsername)
  yield takeEvery(CHECK_USERNAME_AVAILABILITY, checkUsernameAvailability)
}

export default allocatedUsernameSaga;
