import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";

import {
  getPayoutStatus,
  deletePayoutstatus,
} from "../../../store/payouts/action";

import Camera_icon from "../../../assets/icons/ebud-icons/Camera.svg";
import Block_icon from "../../../assets/icons/ebud-icons/Block.svg";
import UnBlock_icon from "../../../assets/icons/ebud-icons/Unblock.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import defaultProfile from "../../../assets/images/users/user.svg";
import premiumImage from "../../../assets/icons/premium.svg";
import saveAs from "file-saver";
import verifedImage from "../../../assets/icons/verified.svg";
import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";

import { payoutstatusColumnData } from "../../../helpers/columns";
import { checkUserPermission, convertToUAETime } from "../../../helpers/utils";
import {
  DATE_WITH_TIME_IN_SECONDS,
  FE_DATE_FORMAT,
} from "../../../helpers/constants";

const PayoutStatusManagement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Payouts);
  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;
  const isDeleteable = userPermissions?.delete || false;

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();

  const [request, setRequest] = useState();

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Users"
        )
      );
    }
  }, [loginData]);

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error]);

  const scrollToAlertMessage = () => {
    if (data?.error) {
      let element = warningAlertRef?.current;
      let top = element?.offsetTop;
      let height = element?.getBoundingClientRect()?.height;
      pageWrapRef?.current?.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {
    dispatch(getPayoutStatus());
  }, [request]);

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: "Are you sure to delete this Status ?",
      type: "delete",
    });
  };

  // const blockPromptHandler = (id, status) => {
  //   setShowPromptPopUp(!showPromptPopUp);
  //   setPromptMessage({
  //     id: id,
  //     content: `Are you sure you want to ${
  //       status === "Blocked" ? "unblock" : "block"
  //     } this user?`,
  //     type: status === "Blocked" ? "unblock" : "block",
  //     status: status,
  //   });
  // };

  const okHandler = () => {
    if (promptMessage.type === "delete")
      dispatch(
        deletePayoutstatus({
          data: promptMessage.id,
          callback: () => dispatch(getPayoutStatus()),
        })
      );
    //   : promptMessage.type === "block"
    //   ? dispatch(
    //       blockUser({
    //         data: { blocked: true },
    //         id: promptMessage.id,
    //         callback: () => dispatch(getPayoutRequests(request)),
    //       })
    //     )
    //   : dispatch(
    //       blockUser({
    //         data: { blocked: false },
    //         id: promptMessage.id,
    //         callback: () => dispatch(getPayoutRequests(request)),
    //       })
    //     );
  };

  const statusTable =
    data?.payout_status?.status?.length &&
    data?.payout_status?.status?.map((status, index) => ({
      ...status,
      no: index + 1,
      statusname: (
        <div title={status.status_name}>
          <div data-tag="allowRowEvents">{status.status_name}</div>
        </div>
      ),
      created_on: (
        <div data-tag="allowRowEvents" title={status.time_created}>
          {status.time_created &&
            convertToUAETime(status.time_created, DATE_WITH_TIME_IN_SECONDS)}
        </div>
      ),
      active: (
        <div
          className={`badge ${
            status.status === "1" ? "bg-soft-green" : "bg-soft-gray"
          } font-size-13`}
          data-tag="allowRowEvents"
        >
          {status.status === "1" ? "Active" : "Inactive"}
        </div>
      ),
      actions: (
        <div className="cust-table-actions-wrap">
          <button
            className=" color-violet action-btn"
            title="Edit"
            onClick={() =>
              history.push({
                pathname: `/payout-status/${status.id}`,
                state: { from: "payoutStatus" },
              })
            }
            disabled={!isEditable}
          >
            <img
              src={Edit_icon}
              alt="View Details"
              style={{
                opacity: !isEditable ? "50%" : "",
                cursor: !isEditable ? "not-allowed" : "",
              }}
            />
          </button>
          <button
            onClick={() => deletePromptHandler(status.id)}
            className=" color-orange action-btn"
            title="Delete"
            disabled={!isEditable}
          >
            <img
              src={Delete_icon}
              style={{
                opacity: !isEditable ? "50%" : "",
                cursor: !isEditable ? "not-allowed" : "",
              }}
            />
          </button>
          {/* 
        <button
          onClick={() => deletePromptHandler(user.id)}
          className=" color-red action-btn"
          title="Delete"
          disabled={!isDeleteable}
        >
          <img
            src={Delete_icon}
            alt="Delete"
            style={{
              opacity: !isDeleteable ? "50%" : "",
              cursor: !isDeleteable ? "not-allowed" : "",
            }}
          />
        </button> */}
        </div>
      ),
    }));

  //memoized user data

  const tableRowClickHandler = (param) => {
    history.push(`/payout-status/${param.id}`);
  };

  const addNewClickHandler = () => {
    history.push(`/payout-add-status/`);
  };

  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Container id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Payout Status" />
          {(data?.error || data?.success) && (
            <UncontrolledAlert
              color={data?.error ? "danger" : "success"}
              className="alert-dismissible fade show col-12"
              role="alert"
            >
              {data?.error || data?.success}
            </UncontrolledAlert>
          )}
          <div className="container">
            <Datatable
              defaultSortField={request?.sort || "created_on"}
              defaultSortAsc={request?.sort_order === "asc" ? true : false}
              tableID={"userTable"}
              add
              addNewClickHandler={addNewClickHandler}
              addNewLabel={"ADD NEW"}
              columns={payoutstatusColumnData}
              rows={statusTable}
              setRequest={setRequest}
              request={request}
              totalRecords={data?.data?.total}
              tableRowClicked={(param) => tableRowClickHandler(param)}
              searchTerm={data?.request?.keyword}
              statusFilter={data?.request?.status_filter}
              membershipFilter={data?.request?.membership_filter}
              loading={data?.loading}
              tableCardClassName={"snoTable"}
            />
          </div>
        </Row>
      </Container>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default PayoutStatusManagement;
