import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  getFlaxCoinsConversionHistoryBegin,
  getFlaxCoinsConversionHistoryFail,
  getFlaxCoinsConversionHistorySuccess,
} from "./actions";

import {
  GET_FLAX_COINS_CONVERSION_HISTORY,
  
} from "./actionTypes";
import { get } from "../../helpers/api_helper";

function* getFlaxCoinsConversionHistory({request,userId}) {
  try {
    yield put(getFlaxCoinsConversionHistoryBegin());
    const response = yield call(
      get,
      `/admin/gift/conversionhistory?user_id=${userId}`,
      request
    );

    yield put(getFlaxCoinsConversionHistorySuccess(response.result));
  } catch (error) {
    yield put(getFlaxCoinsConversionHistoryFail(error));
  }
}

function* flaxCoinsConversionSaga() {
  yield takeLatest(GET_FLAX_COINS_CONVERSION_HISTORY, getFlaxCoinsConversionHistory);
}

export default flaxCoinsConversionSaga;
