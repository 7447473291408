import {
    GET_FLAX_COINS_CONVERSION_HISTORY,
    GET_FLAX_COINS_CONVERSION_HISTORY_BEGIN,
    GET_FLAX_COINS_CONVERSION_HISTORY_FAIL,
    GET_FLAX_COINS_CONVERSION_HISTORY_SUCCESS,
  } from "./actionTypes";
  
  export const getFlaxCoinsConversionHistory = ({request,userId}) => ({
    type: GET_FLAX_COINS_CONVERSION_HISTORY,
    request: request,
    userId:userId,
   
  });
  
  export const getFlaxCoinsConversionHistoryBegin = () => ({
    type: GET_FLAX_COINS_CONVERSION_HISTORY_BEGIN,
  });
  
  export const getFlaxCoinsConversionHistorySuccess = (conversionHistory) => ({
    type: GET_FLAX_COINS_CONVERSION_HISTORY_SUCCESS,
    payload: conversionHistory,
  });
  
  export const getFlaxCoinsConversionHistoryFail = (error) => ({
    type: GET_FLAX_COINS_CONVERSION_HISTORY_FAIL,
    payload: error,
  });
  