import React, { useEffect, useState } from "react";
import { Card, CardTitle, CardBody, Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/UI/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Common/Loader";
import TablePagination from "../../components/Common/Pagination";
import { getAllNotifications } from "../../store/notification/actions";
import { checkUserPermission } from "../../helpers/utils";
import AppInstallationsFilter from "../Statistics/HeaderFilter";
import moment from "moment";
import NotificationTimeLine from "./NotificationTimeLine";

const Notifications = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.notification);
  const loginData = useSelector((state) => state.Login);
  const [request, setRequest] = useState({
    page: 1,
    page_count: 50,
    type_filter: "all",
    from_date: moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format("DD-MM-YYYY"),
    to_date: moment(new Date()).format("DD-MM-YYYY"),
    sort_order: "desc",
  });

  useEffect(() => {
    dispatch(getAllNotifications(request));
  }, [request]);

  const handleSortClick = () => {
    if (request.sort_order == "desc") {
      setRequest({ ...request, sort_order: "asc" });
    } else {
      setRequest({ ...request, sort_order: "desc" });
    }
  };

  return (
    <div className="page-content" data-testid="component-notification">
      <Container fluid>
        <Row>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Breadcrumbs title="Tables" breadcrumbItem={t("Notifications.Title")} />
            </div>
          </div>
          <Col xl={3} >
            <Card>
              <CardBody>
                <div className="form-control mt-3">
                  <AppInstallationsFilter request={request} setRequest={setRequest} />
                </div>
                <div className="mt-3">
                  <label className="form-label"> Notification Types</label>
                  <select className="py-2 form-select mb-3" onChange={(e) =>
                    setRequest({ ...request, type_filter: e.target.value })}>
                    <option value="all">All</option>
                    {loginData?.rolePermissions?.role?.permissions?.Notification
                      ?.length &&
                      loginData?.rolePermissions?.role?.permissions?.Notification?.map(
                        (notification, index) =>
                          notification?.view ? (
                            <option value={notification?.sub_module} key={index}>
                              {notification?.sub_module}
                            </option>
                          ) : null
                      )}
                  </select>
                </div>

                {data?.allNotifications?.notifications?.length ? (
                  <div className="mt-3">
                    <label className="form-label"> Sort By </label>
                    <div className="form-control" >

                      <span onClick={handleSortClick} title={`${request.sort_order == "asc" ? "Ascending" : "Descending"}`}> Date
                        {request.sort_order == "desc" ? (
                          <i className="fas fa-sort-amount-down  float-end" ></i>
                        ) : (
                          <i className="fas fa-sort-amount-up  float-end"></i>
                        )}
                      </span>
                    </div>
                  </div>
                ) : null}
              </CardBody>
            </Card>
          </Col>
          <Col xl={9} >
            <div className="">
              <Card>
                {!data?.allLoading ? (
                  <CardBody>
                    {(data?.allNotifications?.notifications?.length && !data?.allLoading) ?
                      (<NotificationTimeLine arrNotifications={data.allNotifications.notifications} />)
                      : (<h4>No Records Found...</h4>)
                    }
                  </CardBody>) :
                  null}
              </Card>
            </div>
            {!data?.allLoading && data?.allNotifications?.total > 0 && (
              <TablePagination
                setRequest={setRequest}
                request={request}
                totalRecords={data?.allNotifications?.total}
                loading={data?.allLoading}
              />
            )}
          </Col>
        </Row>
      </Container>
      {data?.allLoading && <Loader darkBg={true} />}
    </div >
  );
};
export default Notifications;
