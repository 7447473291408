import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const TotalFlixList = ({
  toggle,
  modal,
  gf,
  rff,
  cfc,
  rf,
  pf,
  ctc,
  cpf,
  uf,
  sf,
  ffs,
}) => {
  return (
    <>
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Total FLiX Summary</ModalHeader>
        <ModalBody>
          <div className="card-body">
            {/* <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <h5 className="mb-0 text-success">Credited</h5>
              </div>
            </div> */}
            <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <p className="mb-0">Generated FLiX </p>
              </div>
              <div className="col-sm-4">
                <p className="text-muted text-end">
                  {gf}
                  <i className="ms-1">FLiX </i>
                </p>
              </div>
            </div>

            <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <p className="mb-0">Refunded FLiX </p>
              </div>
              <div className="col-sm-4 ">
                <p className="text-muted text-end">
                  {rff}
                  <i className="ms-1">FLiX </i>
                </p>
              </div>
            </div>

            <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <p className="mb-0">Converted from COiNS</p>
              </div>
              <div className="col-sm-4">
                <p className="text-muted mb-0 text-end">
                  {cfc}
                  <i className="ms-1">FLiX </i>
                </p>
              </div>
            </div>

            <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <p className="mb-0">Rewarded FLiX </p>
              </div>
              <div className="col-sm-4">
                <p className="text-muted mb-0 text-end ">
                  {rf}
                  <i className="ms-1">FLiX </i>
                </p>
              </div>
            </div>
            <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <p className="mb-0">Purchased FLiX</p>
              </div>
              <div className="col-sm-4">
                <p className="text-muted mb-0 text-end ">
                  {pf}
                  <i className="ms-1">FLiX </i>
                </p>
              </div>
            </div>
            <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <p className="mb-0">Converted to COiNS</p>
              </div>
              <div className="col-sm-4">
                <p className="text-muted mb-0 text-end ">
                  {ctc}
                  <i className="ms-1">FLiX </i>
                </p>
              </div>
            </div>
            <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <p className="mb-0">Cancelled Processing Fee</p>
              </div>
              <div className="col-sm-4">
                <p className="text-muted mb-0 text-end ">
                  {cpf}
                  <i className="ms-1">FLiX </i>
                </p>
              </div>
            </div>

            <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <p className="mb-0">Utilized</p>
              </div>
              <div className="col-sm-4">
                <p className="text-muted mb-0 text-end ">
                  {uf}
                  <i className="ms-1">FLiX </i>
                </p>
              </div>
            </div>

            <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <p className="mb-0">Sending Fee</p>
              </div>
              <div className="col-sm-4">
                <p className="text-muted mb-0 text-end ">
                  {sf}
                  <i className="ms-1">FLiX </i>
                </p>
              </div>
            </div>
            <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <p className="mb-0">Flashat's FLiX Share </p>
              </div>
              <div className="col-sm-4">
                <p className="text-muted mb-0 text-end ">
                  {ffs}
                  <i className="ms-1">FLiX </i>
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TotalFlixList;
