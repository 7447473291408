import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_GENERAL_SETTINGS,
  SAVE_GENERAL_SETTINGS,
  SAVE_IP_FOR_REMOVE,
  GET_PP_SETTINGS,
  SAVE_PP_SETTINGS,
  GET_PAYOUT_ELIGIBILITY_SETTINGS_BEGIN,
  GET_PAYOUT_ELIGIBILITY_SETTINGS,
  UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_FAIL,
  UPDATE_PAYOUT_ELIGIBILITY_SETTINGS,
} from "./actionTypes";

import {
  getGeneralSettingsBegin,
  getGeneralSettingsSuccess,
  getGeneralSettingsFail,
  saveGeneralSettingsBegin,
  saveGeneralSettingsSuccess,
  saveGeneralSettingsFail,
  saveIPForBlockBegin,
  saveIPForBlockSuccess,
  saveIPForBlocksFail,
  getPerformancePointsBegin,
  getPerformancePointsSuccess,
  getPerformancePointsFail,
  savePPSettingsBegin,
  savePPSettingsSuccess,
  savePPSettingsFail,
  getPayoutEligibilitySettingsBegin,
  getPayoutEligibilitySettingsSuccess,
  getPayoutEligibilitySettingsFail,
  updatePayoutEligibilitySettingsBegin,
  updatePayoutEligibilitySettingsSuccess,
  updatePayoutEligibilitySettingsFail,
} from "./actions";

import {
  get,
  update,
  delMultiple,
  updateFormData,
} from "../../../helpers/api_helper";
import {
  API_GENERAL_SETTING,
  API_PERFORMANCE_POINTS,
  API_IP_REMOVAL,
  API_PAYOUT_ELIGIBILITY_SETTING,
  API_UPDATE_PAYOUT_ELIGIBILITY_SETTING,
} from "../../../helpers/url_helper";

function* getGeneralSettings() {
  try {
    yield put(getGeneralSettingsBegin());
    const response = yield call(get, API_GENERAL_SETTING);
    yield put(getGeneralSettingsSuccess(response.result));
  } catch (err) {
    yield put(getGeneralSettingsFail(err));
  }
}

function* saveGeneralSettings({ payload }) {
  try {
    yield put(saveGeneralSettingsBegin());
    const response = yield call(update, API_GENERAL_SETTING, payload);
    yield put(saveGeneralSettingsSuccess(response));
  } catch (err) {
    yield put(saveGeneralSettingsFail(err));
  }
}

function* saveIPForBlock({ payload }) {
  try {
    yield put(saveIPForBlockBegin());
    const response = yield call(delMultiple, API_IP_REMOVAL, payload);
    yield put(saveIPForBlockSuccess(response));
  } catch (err) {
    yield put(saveIPForBlocksFail(err));
  }
}

function* getPPSettings() {
  try {
    yield put(getPerformancePointsBegin());
    const response = yield call(get, API_PERFORMANCE_POINTS);
    yield put(getPerformancePointsSuccess(response.result));
  } catch (error) {
    yield put(getPerformancePointsFail(error));
  }
}

function* savePPSettings({ payload }) {
  try {
    yield put(savePPSettingsBegin());
    const response = yield call(update, API_PERFORMANCE_POINTS, payload);
    yield put(savePPSettingsSuccess(response.result));
  } catch (error) {
    yield put(savePPSettingsFail(error));
  }
}

function* getPayoutEligibilitySettings() {
  try {
    yield put(getPayoutEligibilitySettingsBegin());
    const response = yield call(get, API_PAYOUT_ELIGIBILITY_SETTING);
    yield put(getPayoutEligibilitySettingsSuccess(response.result));
  } catch (err) {
    yield put(getPayoutEligibilitySettingsFail(err));
  }
}
function* updatePayoutEligibilitySettingsSaga({ id, data }) {
  try {
    yield put(updatePayoutEligibilitySettingsBegin());

    const response = yield call(
      updateFormData,
      API_UPDATE_PAYOUT_ELIGIBILITY_SETTING.replace("{id}", id), // Update the API endpoint accordingly
      data
    );
    console.log("ewewew", response);
    // callback && callback();
    yield put(updatePayoutEligibilitySettingsSuccess(response.message));
  } catch (error) {
    yield put(updatePayoutEligibilitySettingsFail(error));
  }
}

function* appSettingsSaga() {
  yield takeLatest(GET_GENERAL_SETTINGS, getGeneralSettings);
  yield takeLatest(SAVE_GENERAL_SETTINGS, saveGeneralSettings);
  yield takeLatest(GET_PP_SETTINGS, getPPSettings);
  yield takeLatest(SAVE_PP_SETTINGS, savePPSettings);
  yield takeLatest(SAVE_IP_FOR_REMOVE, saveIPForBlock);
  yield takeLatest(
    GET_PAYOUT_ELIGIBILITY_SETTINGS,
    getPayoutEligibilitySettings
  );
  yield takeLatest(
    UPDATE_PAYOUT_ELIGIBILITY_SETTINGS,
    updatePayoutEligibilitySettingsSaga
  );
}

export default appSettingsSaga;
