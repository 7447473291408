import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Datatable from "../../../components/UI/Tables/Datatable";
import { allFlashCoumnData } from "../../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../helpers/constants";
import {
  checkUserPermission,
  convertTime,
  convertToUAETime,
} from "../../../helpers/utils";
import {
  blockOrUnblockUserFromFlash,
  deleteFlashes,
  getAllFlash,
  getFlashes,
} from "../../../store/flashManagement/actions";
import { VideoPreview } from "../../UserMangement/TabSection/Flashes/VideoPreview";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import Loader from "../../../components/Common/Loader";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import { blockUser } from "../../../store/users/actions";

const AllFlashes = () => {
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const [isOpen, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const [userPermissions, setUserPermissions] = useState({});
  const [flashPermissions, setFlashPermissions] = useState({});
  const isDeleteable = flashPermissions?.delete || false;
  const isBlockable = userPermissions?.edit || false;
  const loginData = useSelector((state) => state.Login);
  const data = useSelector((state) => state.FlashCategories);
  console.log("all flash ", data);

  useEffect(() => {
    dispatch(getAllFlash(request));
  }, [request]);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setFlashPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Flash Management",
          "Flash"
        )
      );
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Users"
        )
      );
    }
  }, [loginData]);
  const profilePicClick = (e, id) => {
    e.preventDefault();
    const win = window.open(`/user-details/${id}?tab=1`, "_blank");
    win.focus();
  };
  const allflashData = data?.allFlash?.flash_list?.map((flash, index) => ({
    ...flash,

    no: (request?.page - 1) * request?.page_count + index + 1,
    // createdOn: convertToUAETime(flash.time_created, DATE_WITH_TIME_IN_SECONDS),
    thumbnail: (
      <div className="profileWrap" data-tag="allowRowEvents">
        <div data-tag="allowRowEvents">
          {/* {result?.gift_json && isLottieAnimation(result?.gift_json) && (
          <Lottie
            style={{
              width: "30px",
              height: "40px",
            }}
            animationData={result?.gift_json && result?.gift_json}
          />
        )} */}
          <img
            data-tag="allowRowEvents"
            className="table_profileImg"
            width={28}
            src={flash?.thumbnail_url}
            alt="flash"
          />
        </div>
        {}
      </div>
    ),
    post_owner: (
      <>
        <div
          data-tag="allowRowEvents"
          title={flash?.sender_details?.name}
          onClick={(e) => profilePicClick(e, flash?.sender_details?.id)}
        >
          {flash?.sender_details?.name}
        </div>
        {flash?.sender_details?.flash_blocked && (
          <div className="badge bg-soft-secondary font-size-6 my-auto ">
            Blocked from flash
          </div>
        )}
      </>
    ),

    category: (
      <div data-tag="allowRowEvents" title={flash.category}>
        {flash.category}
      </div>
    ),

    created_on: (
      <div data-tag="allowRowEvents" title={flash.time_created}>
        {convertTime(flash.time_created)}
      </div>
    ),

    actions: (
      <div>
        <div className="cust-table-actions-wrap">
          {!flash?.sender_details?.user_blocked && (
            <button
              onClick={() =>
                blockPromptHandler(flash?.sender_details?.id, flash?.id)
              }
              className=" action-btn"
              style={{
                fontSize: "11px",
                padding: "5px",
                backgroundColor: "#dedfe0",
                color: "#969696",
                fontWeight: "bold",
                opacity: !isDeleteable || !isBlockable ? "50%" : "",
                cursor: !isDeleteable || !isBlockable ? "not-allowed" : "",
              }}
              title="Block Owner"
              disabled={!isDeleteable || !isBlockable}
            >
              BLOCK AND DELETE
            </button>
          )}

          <button
            onClick={() => deletePromptHandler(flash.id)}
            className=" action-btn"
            title="Delete Post"
            disabled={!isDeleteable}
            style={{
              fontSize: "11px",
              padding: "5px",
              backgroundColor: "#fadede",
              fontWeight: "bold",
              color: "#fa5f5f",
              opacity: !isDeleteable ? "50%" : "",
              cursor: !isDeleteable ? "not-allowed" : "",
            }}
          >
            DELETE
          </button>
          {!flash?.sender_details?.flash_blocked && (
            <button
              onClick={() => blockFlashPromptHandler(flash?.sender_details?.id)}
              className=" action-btn"
              title="Block from flash"
              // disabled={!isDeleteable}
              style={{
                fontSize: "11px",
                padding: "5px",
                backgroundColor: "lightblue",
                fontWeight: "bold",
                color: "#036799",
                // opacity: !isDeleteable ? "50%" : "",
                // cursor: !isDeleteable ? "not-allowed" : "",
              }}
            >
              BLOCK FROM FLASH
            </button>
          )}
        </div>
      </div>
    ),
  }));
  const blockFlashPromptHandler = (userId) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      userId: userId,
      action: "block",
      content: `Are you sure you want to block the user from posting Flash videos? `,
      type: "flash-block",
    });
  };
  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to delete this Flash from the App?`,
      type: "delete",
    });
  };

  const blockPromptHandler = (userId, postId) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      userId: userId,
      postId: postId,
      content: `Are you sure you want to block this user and delete the Flash from the App? `,
      type: "block-del",
    });
  };

  const okHandler = () => {
    if (promptMessage?.type === "flash-block") {
      dispatch(
        blockOrUnblockUserFromFlash({
          id: promptMessage?.userId,
          action: promptMessage?.action,

          callback: () => dispatch(getAllFlash(request)),
        })
      );
    } else {
      promptMessage?.type === "delete"
        ? dispatch(
            deleteFlashes({
              id: promptMessage?.id,

              callback: () => dispatch(getAllFlash(request)),
            })
          )
        : dispatch(
            blockUser({
              data: { blocked: true },
              id: promptMessage?.userId,
            })
          ) &&
          dispatch(
            deleteFlashes({
              id: promptMessage?.postId,

              callback: () => dispatch(getAllFlash(request)),
            })
          );
    }
  };

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error, data?.success]);

  const scrollToAlertMessage = () => {
    if (data?.error || data?.success) {
      let element = warningAlertRef?.current;
      let top = element?.offsetTop;
      let height = element?.getBoundingClientRect().height;
      pageWrapRef?.current?.scrollTo(0, top - height - 30);
    }
  };

  const onRowClicked = (review) => {
    setOpen(true);
    setSelectedRow(review);
  };
  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Container id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="All Flash" />
          {data?.error && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            </div>
          )}

          <Datatable
            //   defaultSortField={request?.sort || "created_on"}
            //   defaultSortAsc={request?.sort_order === "asc" ? true : false}
            tableID={"reviewTable"}
            columns={allFlashCoumnData}
            rows={allflashData}
            setRequest={setRequest}
            request={request}
            totalRecords={data?.allFlash?.total}
            search
            //   podiumFilter
            //   reqTypeFilter={request.type}
            tableRowClicked={(param) => onRowClicked(param)}
            // addNewLabel={t("Admins.addAdmin.addBtn")}
            searchTerm={data?.request?.keyword}
            // reqstatusFilter={request?.status_filter}
            // reqmembershipFilter={request?.membership_filter}
            // loading={data?.loading}
            tableCardClassName={"snoTable"}
            // deleteLabel="DELETE"
            // deleteClickHandler={deleteAll}
            // deleteDisabled={!selectedHuddles.length}
            // showDeleteButton={true}
            // deleteConfirmationMsg="Do you really want to delete the selected huddles?"
          />
        </Row>
        <VideoPreview
          selectedRow={selectedRow}
          isOpen={isOpen}
          setOpen={setOpen}
        />
      </Container>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />

      {(data?.loading || data?.deleteLoading) && <Loader darkBg={true} />}
    </div>
  );
};

export default AllFlashes;
