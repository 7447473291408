import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Modal, ModalBody, Row } from "reactstrap";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import Datatable from "../../../../components/UI/Tables/Datatable";
import {
  flixCoinsStatementsColumnData,
  userFlaxRatesColumnData,
} from "../../../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../../helpers/constants";
import {
  convertTime,
  convertToUAETime,
  formatDateTime,
} from "../../../../helpers/utils";

import Loader from "../../../../components/Common/Loader";
import { getUserFlaxRates } from "../../../../store/users/flaxRates/actions";
import { getUserFlaxOrCoinsStatements } from "../../../../store/flaxStatements/actions";

const CoinsStatements = ({ toggle, modal, userId }) => {
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "conversion_date",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 10,
    keyword: "",
    type: "coins",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const data = useSelector((state) => state.Statements);
  console.log("coins ", data);

  useEffect(() => {
    request &&
      userId &&
      dispatch(getUserFlaxOrCoinsStatements({ request, userId }));
  }, [request]);
  console.log("flax rates", data);

  const coinsStatementsData =
    data?.statements?.user_statement?.length >= 0 &&
    data?.statements?.user_statement?.map((result, index) => {
      // Check if sender_details and receiver_details are not null

      return {
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,
        time_created: (
          <div data-tag="allowRowEvents" title={result?.time_created}>
            {convertTime(result?.time_created)}
          </div>
        ),
      };
    });

  return (
    <div>
      <Modal
        centered
        isOpen={modal}
        toggle={toggle}
        style={{ minWidth: "900px", maxWidth: "1200px" }}
      >
        {/* <ModalHeader toggle={toggle}>Flax summary</ModalHeader> */}
        <ModalBody>
          <>
            <Container id="userManagementWrapper" data-testid="userMgmtWrap">
              <Row>
                <Breadcrumbs title="Tables" breadcrumbItem="COiNS Statements" />

                <Datatable
                  // defaultSortField={request?.sort || "transfered_date"}
                  // defaultSortAsc={
                  //   request?.sort_order === "asc" ? true : false
                  // }
                  // dateFilter
                  // dateType="Transfered Date Range"
                  tableID={"flaxTable"}
                  columns={flixCoinsStatementsColumnData}
                  rows={coinsStatementsData}
                  setRequest={setRequest}
                  request={request}
                  totalRecords={data?.statements?.total}
                  // search
                  // searchTerm={data?.request?.keyword}
                  // reqstatusFilter={request?.status_filter}
                  // loading={data?.loading}
                  tableCardClassName={"snoTable"}
                />
              </Row>
            </Container>
            {data?.loading && <Loader darkBg={true} />}
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CoinsStatements;
