import { useParams } from "react-router-dom";
import HuddleManagement from "../../../HuddleManagement"
import { HuddleStatistics } from "./HuddlesStatistics";


export const Huddles = ({tab, type}) => {
    const params = useParams();
    return (
       <HuddleStatistics userId={params.id} tab={tab} type={type}/>
    )
}