import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Card, CardBody } from "reactstrap";
import CountUp from "react-countup";
import TotalFlixList from "./TotalFlixList";
import TotalCoinsList from "./TotalCoinsList";

const MiniWidget = ({ reports }) => {
  const [flixModal, setFlixModal] = useState(false);
  const [coinsModal, setCoinsModal] = useState(false);
  const toggleTotalFlix = () => setFlixModal(!flixModal);
  const toggleTotalCoins = () => setCoinsModal(!coinsModal);
  const handleClick = (i, report) => {
    report?.title === "Total FLiX" && toggleTotalFlix();
    report?.title === "Total COiNS" && toggleTotalCoins();
  };

  return (
    <>
      {reports.map((report, key) => {
        return (
          <Col md={6} xl={3} key={key}>
            <Card
              onClick={() => {
                handleClick(key, report);
              }}
            >
              <CardBody>
                <div className="float-end mt-2">
                  <img src={report.iconPath} alt={report.title} />
                </div>
                <div>
                  <h4 className="mb-1 mt-1">
                    <span>
                      <CountUp end={+report.value} />
                    </span>
                  </h4>
                  <p className="text-muted mb-0">{report.title}</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        );
      })}
      {flixModal && reports && (
        <TotalFlixList
          cpf={reports[0]?.cancelled_processing_fee}
          cfc={reports[0]?.converted_from_coins}
          ctc={reports[0]?.converted_to_coins}
          ffc={reports[0]?.flashat_flix_share}
          gf={reports[0]?.generated_flix}
          pf={reports[0]?.purchased_flix}
          rff={reports[0]?.refunded_flix}
          rf={reports[0]?.rewarded_flix}
          sf={reports[0]?.sending_fee}
          uf={reports[0]?.utilized_flix}
          ffs={reports[0]?.flashat_flix_share}
          toggle={toggleTotalFlix}
          modal={flixModal}
        />
      )}
      {coinsModal && (
        <TotalCoinsList
          toggle={toggleTotalCoins}
          modal={coinsModal}
          tpc={reports[1]?.purchased_coins}
          ccff={reports[1]?.coins_converted_from_flix}
          cctf={reports[1]?.coins_converted_to_flix}
          rsf={reports[1]?.revenue_share_flashat}
        />
      )}
    </>
  );
};

export default MiniWidget;

MiniWidget.propTypes = {
  reports: PropTypes.array,
};
