import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../store/users/actions";
import defaultProfile from "../../assets/images/users/user.svg";
import Loader from "./Loader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Spinner = () => <div className="spinner"></div>;

const Specific_search = ({
  setRequest,
  request,
  sp_search_param,
  allPostatSearchIdentifier,
}) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const data = useSelector((state) => state.user);
  const newRequest = {
    keyword: "",
  };
  const [userRequest, setUserRequest] = useState({ ...newRequest });
  const [showSuggestionBox, setShowSuggestionBox] = useState(true);

  console.log("request", request);

  useEffect(() => {
    // delay 300ms after the user entered key
    const delayDebounceFn = setTimeout(() => {
      if (searchText.length >= 3) {
        setUserRequest({ ...newRequest, keyword: searchText });
        dispatch(getUserData({ ...newRequest, keyword: searchText }));
        setShowSuggestionBox(true);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn); // Cleanup the timeout if the effect is called again
  }, [searchText]);
  // useEffect(() => {
  //   if (location?.state?.from === "keepSearchText") {
  //     setSearchText(data?.request?.keyword);
  //     setShowSuggestionBox(false);
  //   } else {
  //     setSearchText("");
  //   }
  // }, [location?.state?.from, data?.request?.keyword]);

  const userClick = (e, id) => {
    e?.preventDefault();
    setShowSuggestionBox(false);
    const updatedRequest = { ...request, [sp_search_param]: id };
    if (allPostatSearchIdentifier) {
      delete updatedRequest.tab; // Remove the 'tab' key
      setRequest(updatedRequest);
    } else {
      setRequest({ ...request, [sp_search_param]: id });
    }
  };

  return (
    <div className="position-relative">
      <div className="SearchWrapper">
        <input
          type="text"
          placeholder="Search"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          className="form-control tableSearchField"
        />
        {searchText?.length >= 3 && (
          <div
            className="clearIconWrapper"
            onClick={() => {
              setSearchText("");
              const updatedRequest = { ...request };

              delete updatedRequest[sp_search_param]; // Remove the 'tab' key

              if (allPostatSearchIdentifier) {
                setRequest({ ...updatedRequest, tab: "all" });
              } else {
                delete updatedRequest.tab;
                setRequest(updatedRequest);
              }
            }}
            style={{
              position: "absolute",
              right: "40px", // Adjust the position as needed
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
        )}
        <div
          className="searchIconWrapper"
          //   onClick={searchButtonHandler}
        >
          <i className="fa fa-search" aria-hidden="true"></i>
        </div>
      </div>
      {searchText?.length >= 3 && showSuggestionBox && (
        <div className="SearchResultsWrapper">
          {data?.loading ? (
            <Spinner />
          ) : (
            <>
              {data?.data?.users?.length > 0 ? (
                data?.data?.users?.map((result, index) => (
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={(e) => userClick(e, result?.id)}
                    key={index}
                    className="SearchResultItem"
                  >
                    <img
                      src={result.profile_image || defaultProfile}
                      alt={`${result.name}'s profile`}
                      className="profileImage"
                    />
                    <div className="resultText">
                      <div className="name">{result.name}</div>
                      <div className="username">{result.username}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="NoResults">No results found</div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Specific_search;
