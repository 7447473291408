import { connect } from "react-redux";
import {
  getAppReviewRequests,
  updateAppReview,
} from "../../store/appReview/action";
import { useEffect, useState, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  UncontrolledAlert,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
} from "reactstrap";
import Breadcrumbs from "../../components/UI/Breadcrumb";
import Loader from "../../components/Common/Loader";
import Datatable from "../../components/UI/Tables/Datatable";
import { useTranslation } from "react-i18next";
import ConfirmationAlert from "../../components/UI/ConfirmationAlert";
import { appReviewColoumns } from "../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../helpers/constants";
import { convertToUAETime } from "../../helpers/utils";
import { ImagePreview } from "./ImagePreview";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
function AppReviews(data) {
  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    page_count: 50,
    keyword: "",
    status_filter: ["New"],
  };
  const [request, setRequest] = useState(basicRequest);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const history = useHistory();

  const toggle = () => {};

  useEffect(() => {
    data.getAppReviewRequests(request);
  }, [request]);

  const approvePromtHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: t("AppReview.approveConfirm"),
      type: "approve",
      status: "Accepted",
      callback: () => data.getAppReviewRequests(request),
    });
  };

  const rejectPromtHandler = (id, status) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to reject this request?`,
      type: "reject",
      status: "Rejected",
      callback: () => data.getAppReviewRequests(request),
    });
  };
  const okHandler = (params) => {
    const reviewId = params?.id || promptMessage?.id;
    const callbackFn = params?.callback || promptMessage?.callback;
    const statusMessage = params?.status || promptMessage?.status;
    data.updateAppReview({
      id: reviewId,
      request: { status: statusMessage },
      callback: callbackFn,
    });
  };

  const popupButtonHandler = (status) => {
    setOpen(false);
    okHandler({
      id: selectedRow.id,
      content: `Are you sure you want to reject this request?`,
      type: status,
      status: status,
      callback: () => data.getAppReviewRequests(request),
    });
  };

  const onRowClicked = (review) => {
    setOpen(true);
    setSelectedRow(review);
  };
  const formatReviewData = useCallback(() => {
    return data?.appReviews?.list?.map((review, index) => ({
      ...review,
      no: (request?.page - 1) * request?.page_count + index + 1,
      screenshot: (
        <div className="profileWrap" data-tag="allowRowEvents">
          <div data-tag="allowRowEvents">
            <img
              data-tag="allowRowEvents"
              className="table_profileImg"
              src={review.screenshot_url}
              alt="screenshot"
            />
          </div>
        </div>
      ),
      user_name: (
        <div
          className="cursor-pointer"
          onClick={() => {
            history.push(`/user-details/${review?.user_id}`);
          }}
        >
          <div>{review.name}</div>
          <div className="text-muted">{`(${review.user_name})`}</div>
        </div>
      ),
      statusCol: (
        <span
          className={
            review.status === "Rejected"
              ? "badge bg-danger"
              : review.status === "Accepted"
              ? "badge bg-success"
              : "badge bg-info"
          }
        >
          {review.status}
        </span>
      ),
      time_updated: (
        <span>
          {convertToUAETime(review.time_updated, DATE_WITH_TIME_IN_SECONDS)}
        </span>
      ),
      actions:
        review.status === "New" ? (
          <div className="cust-table-actions-wrap">
            <button
              onClick={() => approvePromtHandler(review.id)}
              className=" color-green action-btn"
            >
              Approve
            </button>
            <button
              onClick={() => rejectPromtHandler(review.id)}
              className=" color-red action-btn"
              title="Delete"
            >
              Reject
            </button>
          </div>
        ) : review.status === "Accepted" ? (
          <div className="cust-table-actions-wrap">
            {" "}
            <button
              onClick={() => rejectPromtHandler(review.id)}
              title="Delete"
              className="text-white action-btn bg-danger"
              style={{ fontSize: "12px", padding: "5px" }}
            >
              Reject
            </button>
          </div>
        ) : (
          <div className="cust-table-actions-wrap">
            <button
              onClick={() => approvePromtHandler(review.id)}
              className="text-white action-btn bg-success"
              style={{ fontSize: "12px", padding: "5px" }}
            >
              Approve
            </button>
          </div>
        ),
    }));
  }, [data?.appReviews]);

  const reviewRowData = useMemo(() => formatReviewData(), [formatReviewData]);

  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      // ref={pageWrapRef}
    >
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="App Review Screenshots" />
          {data?.error && (
            <div>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            </div>
          )}

          <Datatable
            tableID={"reviewTable"}
            columns={appReviewColoumns}
            rows={reviewRowData}
            setRequest={setRequest}
            request={request}
            totalRecords={data.appReviews?.total}
            search
            appReviewFilter
            tableRowClicked={(param) => onRowClicked(param)}
            searchTerm={data?.appReviews?.request?.keyword}
            statusFilter={data?.request?.status_filter || request.status_filter}
            adminRoleFilter={data.adminData?.roles}
            loading={data?.loading}
            tableCardClassName={"snoTable"}
          />
        </Row>
        <ConfirmationAlert
          {...promptMessage}
          modal_center={showPromptPopUp}
          setmodal_center={setShowPromptPopUp}
          onOK={okHandler}
        />
        <ImagePreview
          selectedRow={selectedRow}
          popupButtonHandler={popupButtonHandler}
          isOpen={isOpen}
          setOpen={setOpen}
        />
      </Container>

      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
}

function mapStateToProps(state) {
  return { ...state.AppReviews };
}

export default connect(mapStateToProps, {
  getAppReviewRequests,
  updateAppReview,
})(AppReviews);
