import {
  GET_LEGAL_DOCUMENTS,
  GET_LEGAL_DOCUMENTS_BEGIN,
  GET_LEGAL_DOCUMENTS_SUCCESS,
  GET_LEGAL_DOCUMENTS_FAIL,
  EDIT_LEGAL_DOCUMENTS,
  EDIT_LEGAL_DOCUMENTS_BEGIN,
  EDIT_LEGAL_DOCUMENTS_SUCCESS,
  EDIT_LEGAL_DOCUMENTS_FAIL,
  UPDATE_LEGAL_DOCUMENTS,
  UPDATE_LEGAL_DOCUMENTS_BEGIN,
  UPDATE_LEGAL_DOCUMENTS_SUCCESS,
  UPDATE_LEGAL_DOCUMENTS_FAIL,
  VIEW_PUBLISHED_BEGIN,
  VIEW_PUBLISHED_SUCCESS,
  VIEW_PUBLISHED_FAIL,
  DELETE_LEGAL_DOCUMENT,
  DELETE_LEGAL_DOCUMENT_BEGIN,
  DELETE_LEGAL_DOCUMENT_SUCCESS,
  DELETE_LEGAL_DOCUMENT_FAIL,
  ENABLE_DISABLE_LEGAL_DOCUMENTS,
  ENABLE_DISABLE_LEGAL_DOCUMENTS_BEGIN,
  ENABLE_DISABLE_LEGAL_DOCUMENTS_SUCCESS,
  ENABLE_DISABLE_LEGAL_DOCUMENTS_FAIL,
} from "./actionTypes";

/*---------------------------------------------*/
export const getLegalDocuments = (request) => ({
  type: GET_LEGAL_DOCUMENTS,
  payload: request,
});

export const getLegalDocumentsBegin = () => ({
  type: GET_LEGAL_DOCUMENTS_BEGIN,
});

export const getLegalDocumentsSuccess = (documents) => ({
  type: GET_LEGAL_DOCUMENTS_SUCCESS,
  payload: documents,
});

export const getLegalDocumentsFail = (error) => ({
  type: GET_LEGAL_DOCUMENTS_FAIL,
  payload: error,
});

/*---------------------------------------------*/

export const editLegalDocument = (load) => ({
  type: EDIT_LEGAL_DOCUMENTS,
  payload: load,
});

export const editLegalDocumentBegin = () => ({
  type: EDIT_LEGAL_DOCUMENTS_BEGIN,
});

export const editLegalDocumentSuccess = (document) => ({
  type: EDIT_LEGAL_DOCUMENTS_SUCCESS,
  payload: document,
});

export const editLegalDocumentFail = (error) => ({
  type: EDIT_LEGAL_DOCUMENTS_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const deleteLegalDocument = ({ data, callback }) => ({
  type: DELETE_LEGAL_DOCUMENT,
  payload: data,
  callback: callback,
});

export const deleteLegalDocumentBegin = () => ({
  type: DELETE_LEGAL_DOCUMENT_BEGIN,
});

export const deleteLegalDocumentSuccess = (response) => ({
  type: DELETE_LEGAL_DOCUMENT_SUCCESS,
  payload: response,
});

export const deleteLegalDocumentFail = (error) => ({
  type: DELETE_LEGAL_DOCUMENT_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const viewPublishedDocumentBegin = () => ({
  type: VIEW_PUBLISHED_BEGIN,
});

export const viewPublishedDocumentSuccess = (document) => ({
  type: VIEW_PUBLISHED_SUCCESS,
  payload: document,
});

export const viewPublishedDocumentFail = (error) => ({
  type: VIEW_PUBLISHED_FAIL,
  payload: error,
});

/*---------------------------------------------*/

export const updateLegalDocument = ({ id, data, method, callback }) => ({
  type: UPDATE_LEGAL_DOCUMENTS,
  id: id,
  payload: data,
  method: method,
  callback: callback,
});

export const updateLegalDocumentBegin = (payload) => ({
  type: UPDATE_LEGAL_DOCUMENTS_BEGIN,
  payload: payload,
});

export const updateLegalDocumentSuccess = (response) => ({
  type: UPDATE_LEGAL_DOCUMENTS_SUCCESS,
  payload: response,
});

export const updateLegalDocumentFail = (error) => ({
  type: UPDATE_LEGAL_DOCUMENTS_FAIL,
  payload: error,
});

/*---------------------------------------------*/

export const enableDisableLegalDocument = ({ id, data, method, callback }) => ({
  type: ENABLE_DISABLE_LEGAL_DOCUMENTS,
  id: id,
  payload: data,
  method: method,
  callback: callback,
});

export const enableDisableLegalDocumentBegin = () => ({
  type: ENABLE_DISABLE_LEGAL_DOCUMENTS_BEGIN,
});

export const enableDisableLegalDocumentSuccess = (response) => ({
  type: ENABLE_DISABLE_LEGAL_DOCUMENTS_SUCCESS,
  payload: document,
});

export const enableDisableLegalDocumentFail = (error) => ({
  type: ENABLE_DISABLE_LEGAL_DOCUMENTS_FAIL,
  payload: error,
});
