import { call, put, takeLatest } from "@redux-saga/core/effects";
import { del, get, post, updateFormData } from "../../helpers/api_helper";
import { API_GET_GLOBAL_PAYMENT_SYSTEMS } from "../../helpers/url_helper"; // Assuming the URL helper for global payment systems is used
import {
  addGlobalPaymentSystemBegin,
  addGlobalPaymentSystemFail,
  addGlobalPaymentSystemSuccess,
  deleteGlobalPaymentSystemBegin,
  deleteGlobalPaymentSystemFail,
  deleteGlobalPaymentSystemSuccess,
  editGlobalPaymentSystemBegin,
  editGlobalPaymentSystemFail,
  editGlobalPaymentSystemSuccess,
  getGlobalPaymentSystemsBegin,
  getGlobalPaymentSystemsByIdBegin,
  getGlobalPaymentSystemsByIdFail,
  getGlobalPaymentSystemsByIdSuccess,
  getGlobalPaymentSystemsFail,
  getGlobalPaymentSystemsSuccess,
} from "./actions";
import {
  ADD_GLOBAL_PAYMENT_SYSTEM,
  DELETE_GLOBAL_PAYMENT_SYSTEM,
  EDIT_GLOBAL_PAYMENT_SYSTEM,
  GET_GLOBAL_PAYMENT_SYSTEMS,
  GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID,
} from "./actionTypes";

function* getGlobalPaymentSystemsSaga(res) {
  try {
    yield put(getGlobalPaymentSystemsBegin());
    const response = yield call(
      get,
      "/admin/flax/sellflax/paymentmethods/countrywise",
      res.request
    );

    yield put(getGlobalPaymentSystemsSuccess(response.result));
  } catch (error) {
    yield put(getGlobalPaymentSystemsFail(error));
  }
}

function* addGlobalPaymentSystemSaga({ system, callback }) {
  try {
    yield put(addGlobalPaymentSystemBegin());
    const response = yield call(post, API_GET_GLOBAL_PAYMENT_SYSTEMS, system);

    yield put(addGlobalPaymentSystemSuccess(response.message));
    callback && callback();
  } catch (error) {
    yield put(addGlobalPaymentSystemFail(error));
  }
}

function* getGlobalPaymentSystemsByIdSaga({
  id,
  country_id,
  language,
  payment_method_identifier,
}) {
  try {
    yield put(getGlobalPaymentSystemsByIdBegin());
    const response = yield call(
      get,
      `/admin/flax/sellflax/paymentforms?country_id=${country_id}&language=${language}&payment_method_identifier=${payment_method_identifier}`
    );
    yield put(getGlobalPaymentSystemsByIdSuccess(response.result));
  } catch (error) {
    yield put(getGlobalPaymentSystemsByIdFail(error));
  }
}

function* deleteGlobalPaymentSystemSaga({
  country_id,
  payment_method_identifier,
  callback,
}) {
  try {
    yield put(deleteGlobalPaymentSystemBegin());
    const response = yield call(
      del,
      `/admin/flax/sellflax/paymentforms?country_id=${country_id}&payment_method_identifier=${payment_method_identifier}`
    );
    callback && callback();
    yield put(deleteGlobalPaymentSystemSuccess(response.result));
  } catch (error) {
    yield put(deleteGlobalPaymentSystemFail(error));
  }
}

function* globalPaymentSystemSaga() {
  yield takeLatest(GET_GLOBAL_PAYMENT_SYSTEMS, getGlobalPaymentSystemsSaga);
  yield takeLatest(ADD_GLOBAL_PAYMENT_SYSTEM, addGlobalPaymentSystemSaga);
  yield takeLatest(
    GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID,
    getGlobalPaymentSystemsByIdSaga
  );

  yield takeLatest(DELETE_GLOBAL_PAYMENT_SYSTEM, deleteGlobalPaymentSystemSaga);
}
export default globalPaymentSystemSaga;
