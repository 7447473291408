import React from 'react';
import {
    Form,
    Input,
    InputGroup,
    Button
  } from "reactstrap";

import { useManualPP } from './useManualPP';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
  
  export const ManualPPInlineForm = ({userId}) => {
    const {formData, onSubmit, onFormFieldChange} = useManualPP(userId, false);
    const [loading, setLoading] = useState(false);
    const {addLoading} = useSelector((state) => state.manualPP);

    useEffect(() => {
         if(!addLoading || formData?.validation?.pp) {
          setLoading(false);
         }
    }, [addLoading, formData])


    return (
        <Form onSubmit={(e) => { e.nativeEvent.preventDefault(); setLoading(true); onSubmit(e)}}>
        <InputGroup>
        <Input
                id="Amount"
                name="pp"
                placeholder="Amount"
                type="number"
                onChange={onFormFieldChange}
                value={formData.pp}
                invalid={!!formData.validation.pp}
              />
        <Button color="success" onClick={(e) => { e.nativeEvent.preventDefault(); onSubmit(e); setLoading(true)}} disabled={loading || formData?.validation.pp}>
         {loading  ? 'saving..' : 'Save'}
       </Button>
  </InputGroup>
  <span className="text-danger">{formData.validation.pp}</span>
        </Form>
    )
}