import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal } from 'reactstrap';

const PlatformFilter = ({
    setRequest,
    request,
    filterAppSubscriptions,
    reqplatformFilter,
}) => {
    const [platformIos, setPlatformIos] = useState("Active")
    const [platformAndroid, setPlatformAndroid] = useState("Active")
    const [platformWeb, setPlatformWeb] = useState("Active")

    const [modal_center, setmodal_center] = useState(false);
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        reqplatformFilter?.includes("ios")
            ? setPlatformIos("ios")
            : setPlatformIos(false);
        reqplatformFilter?.includes("android")
            ? setPlatformAndroid("android")
            : setPlatformAndroid(false);
        reqplatformFilter?.includes("web")
            ? setPlatformWeb("web")
            : setPlatformWeb(false);
    }, [reqplatformFilter, modal_center]);

    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const filter_clearAll = () => {
        setPlatformIos(false);
        setPlatformAndroid(false);
        setPlatformWeb(false);
    };

    const handleApply = () => {
        setRequest({
            ...request,
            device_types: [
                platformIos,
                platformAndroid,
                platformWeb,
            ].filter((item) => item),
        });
        setmodal_center(false);
    };

    return (
        <div className="user-filterBtn-wrap">
            <button
                type="button"
                className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                onClick={() => {
                    tog_center();
                }}
                data-testid="component-filterButton"
            >
                <i
                    style={{ paddingRight: "10px" }}
                    className="fa fa-filter"
                    aria-hidden="true"
                ></i>
                FILTER
            </button>

            <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered={true}
            >
                <div className="modal-header userList-filter-header">
                    <h5 className="modal-title mt-0">FILTER</h5>
                    <button
                        onClick={() => filter_clearAll()}
                        className="btn waves-effect waves-light cust_no_shadow userList-filter-clearAll"
                    >
                        CLEAR ALL
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_center(false);
                        }}
                        className="close"
                    // data-dismiss="modal"
                    // aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body userList-filter-body">
                    <div className="userList-filter-SectionWrap">
                        <h5 className="userList-filter-SectionTitle">Platform</h5>
                        <div className="userList-filter-checkBoxWrap">
                            <div>
                                <input
                                    className="form-check-input cursor-pointer"
                                    type="checkbox"
                                    value={platformIos}
                                    id="platform_ios"
                                    onChange={() =>
                                        platformIos
                                            ? setPlatformIos(!platformIos)
                                            : setPlatformIos("ios")
                                    }
                                    checked={platformIos}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="platform_ios"
                                >
                                    IOS
                                </label>
                            </div>

                            <div>
                                <input
                                    className="form-check-input cursor-pointer"
                                    type="checkbox"
                                    value={platformAndroid}
                                    id="platform_android"
                                    onChange={() =>
                                        platformAndroid
                                            ? setPlatformAndroid(!platformAndroid)
                                            : setPlatformAndroid("android")
                                    }
                                    checked={platformAndroid}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="platform_android"
                                >
                                    Android
                                </label>
                            </div>

                            {!filterAppSubscriptions && <div>
                                <input
                                    className="form-check-input cursor-pointer"
                                    type="checkbox"
                                    value={platformWeb}
                                    id="platform_web"
                                    onChange={() =>
                                        platformWeb
                                            ? setPlatformWeb(!platformWeb)
                                            : setPlatformWeb("web")
                                    }
                                    checked={platformWeb}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="platform_web"
                                >
                                    Web
                                </label>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                            tog_center();
                        }}
                    >
                        CANCEL
                    </button>
                    <button
                        type="button"
                        className="btn bgcolor-btn-green"
                        onClick={() => handleApply()}
                    >
                        APPLY
                    </button>
                </div>
            </Modal>
        </div>
    )
}

export default PlatformFilter
