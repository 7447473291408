import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Card, Row, UncontrolledAlert } from "reactstrap";
import BackButton from "../../../components/UI/BackButton";
import {
  editFlaxTransferPurpose,
  getFlaxTransferPurposeById,
  getFlaxTransferPurposes,
  resetPurposeErrors,
} from "../../../store/flaxTransfer/actions";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { Prompt, useLocation, useParams } from "react-router";
import { useState } from "react";
import Loader from "../../../components/Common/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Checkbox from "../../../components/UI/Checkbox";

const EditPurpose = () => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const data = useSelector((state) => state.FlaxTransfer);

  const dispatch = useDispatch();
  const [purposeText, setPurposeText] = useState("");
  const [formChanged, setFormchanged] = useState(false);
  const [status, setStatus] = useState(true);

  const [updatedPurposeList, setUpdatedPurposeList] = useState([]);

  useEffect(() => {
    if (purposeText === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [purposeText]);

  useEffect(() => {
    dispatch(getFlaxTransferPurposes());
    dispatch(getFlaxTransferPurposeById(location.state.id));

    dispatch(resetPurposeErrors());
  }, []);

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error, data?.success]);

  const scrollToAlertMessage = () => {
    if (data?.error || data?.success) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {
    setPurposeText(data?.flaxTransferPurposeData?.result?.result[0]?.purpose);
    setStatus(data?.flaxTransferPurposeData?.result?.result[0]?.status);
  }, [data?.flaxTransferPurposeData?.result?.result[0]?.purpose]);

  useEffect(() => {
    for (let i = 0; i < updatedPurposeList.length; i++) {
      if (
        updatedPurposeList[i]?.purpose === "" ||
        updatedPurposeList[i]?.purpose.trim() == ""
      ) {
        setFormchanged(false);
        break;
      }
      setFormchanged(true);
    }
  }, [updatedPurposeList]);

  const handleChange = (id, language, value) => {
    setFormchanged(true);
    const updatedList = [...updatedPurposeList];
    const existingIndex = updatedList.findIndex(
      (item) => item.language === language
    );

    if (existingIndex !== -1) {
      updatedList[existingIndex] = {
        ...updatedList[existingIndex],
        purpose: value.trim(),
        id: id,
        purpose_identifier: params?.id,
      };
    } else {
      updatedList.push({
        id: id,
        language: language,
        purpose: value.trim(),
        purpose_identifier: params?.id,
        status: status,
      });
    }

    setUpdatedPurposeList(updatedList);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormchanged(false);
    const updatedList = Object.values(updatedPurposeList);

    dispatch(
      editFlaxTransferPurpose({
        purpose_identifier: params.id,
        id: location?.state?.id,
        purpose: updatedList,
        // callback: () => {
        //   history.push("/flax-transfer-purposes");
        // },
      })
    );
  };
  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      {/* <Prompt
        message={(location, action) => {
          if (action === "POP") {
            return confirmBrowserBack();
          }
        }}
        when={formChanged}
      /> */}
      <BackButton
        label="FLiX Transfer Purposes"
        handleClick={() => history.push("/FLix-transfer-purposes")}
      />
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Edit Purpose" />
          {(data?.error || data?.success) && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color={data?.error ? "danger" : "success"}
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error || data?.success}
              </UncontrolledAlert>
            </div>
          )}
          <Col xl="8">
            <Card className="mb-0 p-4 my-4">
              <div className="text-muted">
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleSubmit(e, v);
                  }}
                >
                  <div
                    className="table-responsive mt-4"
                    style={{ overflowX: "hidden" }}
                  >
                    <div className="d-flex mb-4">
                      <div className="col-md-6">
                        <label className="col-md-8 col-form-label">
                          Transfer Purpose
                          {/* <span className="mandatory">*</span> */}
                        </label>
                        <div className="col-md-8 dobWrapper">
                          <AvInput
                            name="adminName"
                            type="text"
                            className="form-control mb-4 "
                            value={purposeText}
                            id="name-input"
                            onChange={(e) => {
                              setPurposeText(e.target.value);
                            }}
                            readOnly
                            placeholder="Enter Transfer Purpose"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="d-flex mb-4">
                      <div className="d-flex col-md-4">
                        <label className="col-md-4 col-form-label">
                          Enable Purpose
                        </label>
                        <div className="col-md-6 dobWrapper">
                          <div className="form-check form-switch form-switch-lg enableDocumentSwitchWrap ">
                            <div className="px-5 ">
                              <Checkbox
                                value={status}
                                // id={"allow_change_location"}
                                name="push"
                                handleChange={() => {
                                  setStatus(!status);
                                  setFormchanged(true);
                                }}
                                className="form-check-input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {data?.flaxTransferPurposeData?.result?.result?.map(
                      (result, i) => (
                        <div key={result?.language} className="col-md-12">
                          {result?.language === "english" ? (
                            <div className="d-flex mx-1">
                              <label className="col-md-2 lang-font col-form-label">
                                {`${result?.language
                                  ?.slice(0, 1)
                                  .toUpperCase()}${result?.language?.slice(1)}`}
                              </label>
                              <div className="col-md-6">
                                <div className="col-md-8 dobWrapper">
                                  <AvInput
                                    name="adminName"
                                    type="text"
                                    className="form-control mb-4 "
                                    value={result?.purpose}
                                    id="name-input"
                                    onChange={(e) => {
                                      handleChange(
                                        result?.id,
                                        result?.language,
                                        e.target.value
                                      );
                                    }}
                                    placeholder="Enter Transfer Purpose"
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex mx-1">
                              <label className="col-md-2 lang-font col-form-label">
                                {`${result?.language
                                  ?.slice(0, 1)
                                  .toUpperCase()}${result?.language?.slice(1)}`}
                              </label>{" "}
                              <div className="col-md-6">
                                <div className="col-md-8 dobWrapper">
                                  <AvInput
                                    name="adminName"
                                    type="text"
                                    className="form-control mb-4 "
                                    value={result?.purpose}
                                    id="name-input"
                                    onChange={(e) => {
                                      handleChange(
                                        result?.id,
                                        result?.language,
                                        e.target.value
                                      );
                                    }}
                                    placeholder="Enter Transfer Purpose"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    )}

                    <div className="mt-4">
                      <button
                        type="submit"
                        // disabled={isButtonDisabled}
                        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                        // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                        disabled={!formChanged}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </AvForm>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      /> */}
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default EditPurpose;
