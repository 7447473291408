import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  getUserFlaxRatesBegin,
  getUserFlaxRatesFail,
  getUserFlaxRatesSuccess,
  getUserRatingHistoryBegin,
  getUserRatingHistoryFail,
  getUserRatingHistorySuccess,
} from "./actions";
import { API_GET_USER_FLAX_RATES } from "../../../helpers/url_helper";
import { GET_USER_FLAX_RATES, GET_USER_RATING_HISTORY } from "./actionTypes";
import { get } from "../../../helpers/api_helper";

function* getUserFlaxRatesSaga(res) {
  try {
    yield put(getUserFlaxRatesBegin());
    const response = yield call(
      get,
      `/admin/users/userflaxrates?user_id=${res?.userId}`,
      res.request
    );

    yield put(getUserFlaxRatesSuccess(response.result));
  } catch (error) {
    yield put(getUserFlaxRatesFail(error));
  }
}

function* getUserRatingHistorySaga(res) {
  try {
    yield put(getUserRatingHistoryBegin());
    const response = yield call(
      get,
      `/admin/users/userflaxrates/restorehistory?user_id=${res?.userId}`,
      res.request
    );

    yield put(getUserRatingHistorySuccess(response.result));
  } catch (error) {
    yield put(getUserRatingHistoryFail(error));
  }
}

function* userFlaxRatesSaga() {
  yield takeLatest(GET_USER_FLAX_RATES, getUserFlaxRatesSaga);
  yield takeLatest(GET_USER_RATING_HISTORY, getUserRatingHistorySaga);
}

export default userFlaxRatesSaga;
