import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { addManualPP } from "../../../../store/manualPP/actions";
import { getLocalStorage } from "../../../../helpers/utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const initialData = {
  pp: "",
  comments: "",
  user_id: "",
  user: { name: "", id: "" },
  validation: {
    pp: ''
  },
  reload: true,
};

export const useManualPP = (userId, reload, transactionType) => {

  const auth = getLocalStorage("AUTH_DETAILS");
  const match = useParams();
  const [formData, setData] = useState(() => ({ ...initialData, action: transactionType, user_id: match?.id, user: { name: auth.user.username, id: auth.user.id }, reload: reload }));
  const dispatch = useDispatch();

  useEffect(() => {
    setData({ ...formData, action: transactionType })
  }, [transactionType])

  const onFormFieldChange = (event) => {
    let message = '';
    const { name, value } = event.target;
    if (name === 'pp') {
      const intValue = parseFloat(value);
      if (intValue < 1 || intValue > 100000) {
        message = 'Please ensure that the value is greater than 0 and not more than 1 lakh.';
      }
    }
    setData({ ...formData, [name]: value, validation: { [name]: message } });
  };
  const onSubmit = (event) => {
    event.preventDefault();
    if (!formData.pp) {
      setData({ ...formData, validation: { pp: 'Please fill the flax value' } })
      return;
    }
    const { user, ...payload } = formData;
    dispatch(addManualPP({ ...payload, "pp": Number(payload.pp) }));
    setData({ ...initialData, action: transactionType, user_id: match?.id, user: { name: auth.user.username, id: auth.user.id }, reload: reload });
  };

  return { formData, onFormFieldChange, onSubmit, setData };
}