import React from "react";

const FallbackComponent = () => {
  return (
    <div className="error-boundary-container">
      <div className="error-boundary-title">Something went wrong!</div>
      <p className="error-boundary-message">Something seriously went wrong somewhere. Check the logs.</p>
    </div>
  );
};

export default FallbackComponent;
