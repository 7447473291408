import {
  GET_ADMINS_BEGIN,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAIL,
  GET_ADMIN_ROLES_BEGIN,
  GET_ADMIN_ROLES_SUCCESS,
  GET_ADMIN_ROLES_FAIL,
  STORE_ADMINS_REQUEST,
  DELETE_ADMIN_BEGIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAIL,
  GET_ADMIN_DETAILS_BEGIN,
  GET_ADMIN_DETAILS_SUCCESS,
  GET_ADMIN_DETAILS_FAIL,
  GET_SAMPLE_PASSWORD_SUCCESS,
  GET_SAMPLE_PASSWORD_BEGIN,
  GET_SAMPLE_PASSWORD_FAIL,
  UPDATE_ADMIN_DETAILS_BEGIN,
  UPDATE_ADMIN_DETAILS_SUCCESS,
  UPDATE_ADMIN_DETAILS_FAIL,
  ADD_ADMIN_BEGIN,
  ADD_ADMIN_FAIL,
  ADD_ADMIN_SUCCESS,
  BLOCK_ADMIN_BEGIN,
  BLOCK_ADMIN_SUCCESS,
  BLOCK_ADMIN_FAIL,
  RESET_MESSAGES,
  CLEAR_PASSWORD,
} from "./actionTypes";

const INIT_STATE = {
  success:"",
  error: "",
  loading: false,
  request: {
    page: 1,
    page_count: 50,
    status_filter: ["Active"],
  },
  adminData: {},
  adminDetails: {},
  adminRoles: {},
  samplePassword: "",
};

const admins = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADMINS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        adminData: {},
      };
    case GET_ADMINS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        adminData: action.payload,
      };
    case GET_ADMINS_FAIL:
      return {
        ...state,
        adminData: {},
        loading: false,
        error: action.payload,
      };
    /* ------------------------------------------- */

    case STORE_ADMINS_REQUEST:
      return {
        ...state,
        request: action.payload,
      };
    /* ------------------------------------------- */
    case DELETE_ADMIN_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: "Admin deleted successfully",
      };

    case DELETE_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };
    /* ------------------------------------------- */

    case GET_ADMIN_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        adminDetails: {},
      };

    case GET_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        adminDetails: action.payload,
      };

    case GET_ADMIN_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        adminDetails: {},
      };

    /* ------------------------------------------- */

    case GET_SAMPLE_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        samplePassword: "",
        success: "",
      };

    case GET_SAMPLE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        samplePassword: action.payload,
      };

    case GET_SAMPLE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        samplePassword: "",
      };

    /* ------------------------------------------- */
    case UPDATE_ADMIN_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case UPDATE_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: "Updated successfully",
      };

    case UPDATE_ADMIN_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };
    /* ------------------------------------------- */
    case ADD_ADMIN_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case ADD_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Admin created successfully",
      };

    case ADD_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };
    /* ------------------------------------------- */
    case BLOCK_ADMIN_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case BLOCK_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };

    case BLOCK_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };
    /* ------------------------------------------- */
    case RESET_MESSAGES:
      return {
        ...state,
        success: "",
        error: "",
      };

    case CLEAR_PASSWORD:
      return {
        ...state,
        samplePassword: "",
      };

    default:
      return state;
  }
};

export default admins;
