import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UncontrolledAlert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Loader from "../../../components/Common/Loader";
import GeneralSettings from "./generalSettings";
import PPSettings from "./ppSettings";
import PayoutSettings from "./payoutSettings";
import PayoutGeneralSettings from "./payoutGeneralSettings";
import IPRemovalSettings from "./removeIP";

import {
  getGeneralSettings,
  saveGeneralSettings,
  getPerformancePoints,
  savePPSettings,
  saveIPForBlock,
} from "../../../store/settings/appSettings/actions";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import PayoutMinimumEligibilitySettings from "./payoutMinimumEligibilitySettings";

const AppSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appSettings = useSelector((state) => state.appSettings);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const [freeUserBroadcastMin, setFreeUserBroadcastMin] = useState("");
  const [premiumUserBroadcastMin, setPremiumUserBroadcastMin] = useState("");
  const [freeUserOTOMTextMin, setFreeUserOTOMTextMin] = useState("");
  const [premiumUserOTOMTextMin, setPremiumUserOTOMTextMin] = useState("");

  const [huddleAdminsLimitForFree, setHuddleAdminsLimitForFree] = useState("");
  const [huddleMessageDeleteTimeoutFree, setHuddleMessageDeleteTimeoutFree] =
    useState("");
  const [huddleParticipantsLimitForFree, setHuddleParticipantsLimitForFree] =
    useState("");

  const [huddleAdminsLimitForPremium, setHuddleAdminsLimitForPremium] =
    useState("");
  const [
    huddleMessageDeleteTimeoutPremium,
    setHuddleMessageDeleteTimeoutPremium,
  ] = useState("");
  const [
    huddleParticipantsLimitForPremium,
    setHuddleParticipantsLimitForPremium,
  ] = useState("");

  const [huddleVoiceNoteLimitForPremium, setHuddleVoiceNoteLimitForPremium] =
    useState("");
  const [huddleVoiceNoteLimitForFree, setHuddleVoiceNoteLimitForFree] =
    useState("");
  const [otomVoiceNoteLimitForPremium, setOtomVoiceNoteLimitForPremium] =
    useState("");
  const [otomVoiceNoteLimitForFree, setOtomVoiceNoteLimitForFree] =
    useState("");
  const [videoLimitForPremium, setVideoLimitForPremium] = useState("");
  const [videoLimitForFree, setVideoLimitForFree] = useState("");

  const [policyInterval, setPolicyInterval] = useState("");
  const [minDearsforVerifiedStatus, setMinDearsforVerifiedStatus] =
    useState("");

  const [birthdayCelebEligibility, setBirthdayCelebEligibility] = useState("");
  const [level1, setLevel1] = useState("");
  const [level2, setLevel2] = useState("");
  const [level3, setLevel3] = useState("");
  const [level4, setLevel4] = useState("");
  const [level5, setLevel5] = useState("");
  const [level6, setLevel6] = useState("");
  const [level7, setLevel7] = useState("");

  // Constant related to IPSave
  const [iptodelete, setiptodelete] = useState("");

  // Constant related to Payouts
  const [payoutProcessingFee, setpayoutProcessingFee] = useState("");
  const [payoutPPFirsttime, setpayoutPPFirsttime] = useState("");
  const [payoutPPSecondtime, setpayoutPPSecondtime] = useState("");
  const [payoutPPThirdtime, setpayoutPPThirdtime] = useState("");
  const [payoutPPFourthtime, setpayoutPPFourthtime] = useState("");
  const [payoutRefundablePPAmount, setpayoutRefundablePPAmount] = useState("");
  const [payoutRefundablePPtype, setpayoutRefundablePPtype] = useState("");
  const [payoutTransferFee, setPayoutTransferFee] = useState("");
  console.log("payout transfer fee", payoutTransferFee);

  const [payoutMinLikes, setPayoutMinLikes] = useState("");
  const [payoutMinBroadCasts, setPayoutMinBroadCasts] = useState("");
  const [payoutMinDears, setPayoutMinDears] = useState("");
  const [payoutMinHuddles, setPayoutMinHuddles] = useState("");
  const [
    payoutMinTotalParticipantsInHuddle,
    setpayoutMinTotalParticipantsInHuddle,
  ] = useState("");
  const [payoutMinAppSharesMonthly, setPayoutMinAppSharesMonthly] =
    useState("");
  const [payoutMinPointsforNextReview, setPayoutMinPointsforNextReview] =
    useState("");
  const [
    payoutMinTotalPostsbyOthersParticipants,
    setPayoutMinTotalPostsbyOthersParticipants,
  ] = useState("");
  const [payoutMinNoFans, setPayoutMinNoFans] = useState("");
  const [payoutMinNoLikers, setPayoutMinNoLikers] = useState("");
  const [payoutMinNoFollowers, setPayoutMinNoFollowers] = useState("");
  const [huddleSellMinParticipants, setHuddleSellMinParticipants] =
    useState("");

  const [flashDurationForPremium, setFlashDurationForPremium] = useState("");
  const [flashDurationForFree, setFlashDurationForFree] = useState("");
  const [flaxConversionRate, setFlaxConversionRate] = useState("");
  const [minimumPointConversion, setMinimumPointConversion] = useState("");

  useEffect(() => {
    dispatch(getGeneralSettings());
    dispatch(getPerformancePoints());
    console.log("tranfer", payoutTransferFee);
  }, []);

  useEffect(() => {
    setLevel1(appSettings?.ppSettings?.level_one);
    setLevel2(appSettings?.ppSettings?.level_two);
    setLevel3(appSettings?.ppSettings?.level_three);
    setLevel4(appSettings?.ppSettings?.level_four);
    setLevel5(appSettings?.ppSettings?.level_five);
    setLevel6(appSettings?.ppSettings?.level_six);
    setLevel7(appSettings?.ppSettings?.level_seven);
  }, [appSettings.ppSettings]);

  useEffect(() => {
    setFreeUserBroadcastMin(
      appSettings?.generalSettings?.broadcast_message_delete_timeout_free
    );
    setPremiumUserBroadcastMin(
      appSettings?.generalSettings?.broadcast_message_delete_timeout_premium
    );
    setFreeUserOTOMTextMin(
      appSettings?.generalSettings?.otom_delete_timeout_free
    );
    setPremiumUserOTOMTextMin(
      appSettings?.generalSettings?.otom_delete_timeout_premium
    );
    setPolicyInterval(
      appSettings?.generalSettings?.privacy_policy_popup_interval
    );
    setMinDearsforVerifiedStatus(
      appSettings?.generalSettings?.min_dears_for_verified_status
    );
    setHuddleAdminsLimitForFree(
      appSettings?.generalSettings?.huddle_admins_limit_for_free
    );
    setHuddleAdminsLimitForPremium(
      appSettings?.generalSettings?.huddle_admins_limit_for_premium
    );
    setHuddleMessageDeleteTimeoutFree(
      appSettings?.generalSettings?.huddle_message_delete_timeout_free
    );
    setHuddleMessageDeleteTimeoutPremium(
      appSettings?.generalSettings?.huddle_message_delete_timeout_premium
    );
    setHuddleParticipantsLimitForFree(
      appSettings?.generalSettings?.huddle_participants_limit_for_free
    );
    setHuddleParticipantsLimitForPremium(
      appSettings?.generalSettings?.huddle_participants_limit_for_premium
    );

    setHuddleVoiceNoteLimitForFree(
      appSettings?.generalSettings?.huddle_voice_message_length_free
    );
    setHuddleVoiceNoteLimitForPremium(
      appSettings?.generalSettings?.huddle_voice_message_length_premium
    );
    setOtomVoiceNoteLimitForFree(
      appSettings?.generalSettings?.otom_voice_message_length_free
    );
    setOtomVoiceNoteLimitForPremium(
      appSettings?.generalSettings?.otom_voice_message_length_premium
    );
    setVideoLimitForFree(
      appSettings?.generalSettings?.maximum_video_record_duration_free
    );
    setVideoLimitForPremium(
      appSettings?.generalSettings?.maximum_video_record_duration_premium
    );
    //Payout Details
    setpayoutProcessingFee(appSettings?.generalSettings?.payout_processing_fee);

    setPayoutTransferFee(appSettings?.generalSettings?.transfer_fee);
    setpayoutPPFirsttime(
      appSettings?.generalSettings?.minimum_pp_for_first_payout
    );
    setpayoutPPSecondtime(
      appSettings?.generalSettings?.minimum_pp_for_second_payout
    );
    setpayoutPPThirdtime(
      appSettings?.generalSettings?.minimum_pp_for_third_payout
    );
    setpayoutPPFourthtime(
      appSettings?.generalSettings?.minimum_pp_for_fourth_payout
    );
    setpayoutRefundablePPAmount(appSettings?.generalSettings?.refundable_pp);
    setpayoutRefundablePPtype(appSettings?.generalSettings?.refundable_pp);

    setPayoutMinLikes(appSettings?.generalSettings?.payout_min_likes);
    setPayoutMinBroadCasts(appSettings?.generalSettings?.payout_min_broadcasts);
    setPayoutMinDears(appSettings?.generalSettings?.payout_min_customers);
    setPayoutMinAppSharesMonthly(
      appSettings?.generalSettings?.payout_min_app_shares_monthly
    );
    setPayoutMinHuddles(appSettings?.generalSettings?.payout_min_huddles);
    setPayoutMinNoFans(appSettings?.generalSettings?.payout_min_no_fans);
    setPayoutMinNoLikers(appSettings?.generalSettings?.payout_min_no_likers);
    setPayoutMinNoFollowers(
      appSettings?.generalSettings?.payout_min_no_followers
    );
    setPayoutMinPointsforNextReview(
      appSettings?.generalSettings?.payout_min_points_for_next_review
    );
    setpayoutMinTotalParticipantsInHuddle(
      appSettings?.generalSettings?.payout_min_total_participants_in_huddles
    );
    setPayoutMinTotalPostsbyOthersParticipants(
      appSettings?.generalSettings
        ?.payout_min_total_posts_by_others_participants
    );
    setHuddleSellMinParticipants(
      appSettings?.generalSettings?.huddle_sell_participant_count
    );
    setFlashDurationForPremium(
      appSettings?.generalSettings?.flash_premium_duration
    );
    setFlashDurationForFree(appSettings?.generalSettings?.flash_free_duration);
    setMinimumPointConversion(
      appSettings?.generalSettings?.minimum_point_conversion
    );
    setFlaxConversionRate(appSettings?.generalSettings?.flax_conversion_rate);
    setBirthdayCelebEligibility(
      appSettings?.generalSettings?.flax_rate_birthday
    );
  }, [appSettings.generalSettings]);

  const okHandler = () => {
    if (promptMessage?.type === "ip_save") {
      dispatch(saveIPForBlock({ ip_adresses: [iptodelete] }));
    }
    if (promptMessage?.type === "general") {
      dispatch(
        saveGeneralSettings({
          broadcast_message_delete_timeout_free: freeUserBroadcastMin,
          broadcast_message_delete_timeout_premium: premiumUserBroadcastMin,
          otom_delete_timeout_free: freeUserOTOMTextMin,
          otom_delete_timeout_premium: premiumUserOTOMTextMin,
          privacy_policy_popup_interval: policyInterval,
          min_dears_for_verified_status: minDearsforVerifiedStatus,
          huddle_admins_limit_for_free: huddleAdminsLimitForFree,
          huddle_admins_limit_for_premium: huddleAdminsLimitForPremium,
          huddle_message_delete_timeout_free: huddleMessageDeleteTimeoutFree,
          huddle_message_delete_timeout_premium:
            huddleMessageDeleteTimeoutPremium,
          huddle_participants_limit_for_free: huddleParticipantsLimitForFree,
          huddle_participants_limit_for_premium:
            huddleParticipantsLimitForPremium,
          huddle_voice_message_length_premium: huddleVoiceNoteLimitForPremium,
          huddle_voice_message_length_free: huddleVoiceNoteLimitForFree,
          otom_voice_message_length_premium: otomVoiceNoteLimitForPremium,
          otom_voice_message_length_free: otomVoiceNoteLimitForFree,
          maximum_video_record_duration_premium: videoLimitForPremium,
          maximum_video_record_duration_free: videoLimitForFree,
          payout_min_customers: payoutMinDears,
          payout_min_huddles: payoutMinHuddles,
          payout_min_likes: payoutMinLikes,
          payout_min_broadcasts: payoutMinBroadCasts,
          payout_min_no_fans: payoutMinNoFans,
          payout_min_no_likers: payoutMinNoLikers,
          payout_min_no_followers: payoutMinNoFollowers,
          payout_min_points_for_next_review: payoutMinPointsforNextReview,
          payout_min_total_participants_in_huddles:
            payoutMinTotalParticipantsInHuddle,
          payout_min_total_posts_by_others_participants:
            payoutMinTotalPostsbyOthersParticipants,
          payout_min_app_shares_monthly: payoutMinAppSharesMonthly,
          payout_processing_fee: payoutProcessingFee,
          transfer_fee: payoutTransferFee,
          minimum_pp_for_first_payout: payoutPPFirsttime,
          minimum_pp_for_second_payout: payoutPPSecondtime,
          minimum_pp_for_third_payout: payoutPPThirdtime,
          minimum_pp_for_fourth_payout: payoutPPFourthtime,
          refundable_pp: payoutRefundablePPAmount,
          payout_refundable_pp_type: payoutRefundablePPtype,
          huddle_sell_participant_count: huddleSellMinParticipants,
          flash_free_duration: flashDurationForFree,
          flash_premium_duration: flashDurationForPremium,
          flax_conversion_rate: flaxConversionRate,
          minimum_point_conversion: minimumPointConversion,
          flax_rate_birthday: birthdayCelebEligibility,
        })
      );
    }

    if (promptMessage?.type === "pp_save") {
      dispatch(
        savePPSettings({
          level_one: level1,
          level_two: level2,
          level_three: level3,
          level_four: level4,
          level_five: level5,
          level_six: level6,
          level_seven: level7,
        })
      );
    }
  };

  const ipSaveHandler = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: `Are you sure you want to update App settings ?`,
      type: "ip_save",
    });
  };
  const generalSettingsSave = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: `Are you sure you want to update App settings ?`,
      type: "general",
    });
  };

  const pp_saveHandler = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: `Are you sure you want to update App settings ?`,
      type: "pp_save",
    });
  };
  return (
    <div className="page-content">
      <Breadcrumbs title="Tables" breadcrumbItem={t("AppSettings.Title")} />
      {(appSettings?.error || appSettings?.success) && (
        <div>
          <UncontrolledAlert
            color={appSettings?.error ? "danger" : "success"}
            className="alert-dismissible fade show"
            role="alert"
          >
            {appSettings?.error || appSettings?.success}
          </UncontrolledAlert>
        </div>
      )}

      {(appSettings?.pp_error || appSettings?.pp_success) && (
        <div>
          <UncontrolledAlert
            color={appSettings?.pp_error ? "danger" : "success"}
            className="alert-dismissible fade show"
            role="alert"
          >
            {appSettings?.pp_error || appSettings?.pp_success}
          </UncontrolledAlert>
        </div>
      )}

      <div className="appSettingsWrapper">
        <GeneralSettings
          freeUserBroadcastMin={freeUserBroadcastMin}
          premiumUserBroadcastMin={premiumUserBroadcastMin}
          freeUserOTOMTextMin={freeUserOTOMTextMin}
          premiumUserOTOMTextMin={premiumUserOTOMTextMin}
          policyInterval={policyInterval}
          minDearsforVerifiedStatus={minDearsforVerifiedStatus}
          huddleAdminsLimitForFree={huddleAdminsLimitForFree}
          huddleMessageDeleteTimeoutFree={huddleMessageDeleteTimeoutFree}
          huddleParticipantsLimitForFree={huddleParticipantsLimitForFree}
          huddleAdminsLimitForPremium={huddleAdminsLimitForPremium}
          huddleMessageDeleteTimeoutPremium={huddleMessageDeleteTimeoutPremium}
          huddleParticipantsLimitForPremium={huddleParticipantsLimitForPremium}
          huddleVoiceNoteLimitForFree={huddleVoiceNoteLimitForFree}
          huddleVoiceNoteLimitForPremium={huddleVoiceNoteLimitForPremium}
          otomVoiceNoteLimitForFree={otomVoiceNoteLimitForFree}
          otomVoiceNoteLimitForPremium={otomVoiceNoteLimitForPremium}
          videoLimitForFree={videoLimitForFree}
          videoLimitForPremium={videoLimitForPremium}
          huddleSellMinParticipants={huddleSellMinParticipants}
          flashDurationForPremium={flashDurationForPremium}
          flashDurationForFree={flashDurationForFree}
          minimumPointConversion={minimumPointConversion}
          flaxConversionRate={flaxConversionRate}
          birthdayCelebEligibility={birthdayCelebEligibility}
          huddleSellMinParticpantsUpdate={(e) =>
            setHuddleSellMinParticipants(e.target.value)
          }
          freeUserMinBroadcastUpdate={(e) =>
            setFreeUserBroadcastMin(e.target.value)
          }
          premiumUserMinBroadcastUpdate={(e) =>
            setPremiumUserBroadcastMin(e.target.value)
          }
          freeUserMinOTOMTextUpdate={(e) =>
            setFreeUserOTOMTextMin(e.target.value)
          }
          premiumUserMinOTOMTextUpdate={(e) =>
            setPremiumUserOTOMTextMin(e.target.value)
          }
          policyIntervalUpdate={(e) => setPolicyInterval(e.target.value)}
          minDearsforVerifiedStatusUpdate={(e) =>
            setMinDearsforVerifiedStatus(e.target.value)
          }
          huddleAdminsLimitForFreeUpdate={(e) =>
            setHuddleAdminsLimitForFree(e.target.value)
          }
          huddleAdminsLimitForPremiumUpdate={(e) =>
            setHuddleAdminsLimitForPremium(e.target.value)
          }
          huddleMessageDeleteTimeoutFreeUpdate={(e) =>
            setHuddleMessageDeleteTimeoutFree(e.target.value)
          }
          huddleMessageDeleteTimeoutPremiumUpdate={(e) =>
            setHuddleMessageDeleteTimeoutPremium(e.target.value)
          }
          huddleParticipantsLimitForFreeUpdate={(e) =>
            setHuddleParticipantsLimitForFree(e.target.value)
          }
          huddleParticipantsLimitForPremiumUpdate={(e) =>
            setHuddleParticipantsLimitForPremium(e.target.value)
          }
          huddleVoiceNoteLimitFreeUpdate={(event) =>
            setHuddleVoiceNoteLimitForFree(event.target.value)
          }
          huddleVoiceNoteLimitPremiumUpdate={(event) =>
            setHuddleVoiceNoteLimitForPremium(event.target.value)
          }
          otomVoiceNoteLimitPremiumUpdate={(event) =>
            setOtomVoiceNoteLimitForPremium(event.target.value)
          }
          otomVoiceNoteLimitFreeUpdate={(event) =>
            setOtomVoiceNoteLimitForFree(event.target.value)
          }
          videoLimitPremiumUpdate={(event) =>
            setVideoLimitForPremium(event.target.value)
          }
          videoLimitFreeUpdate={(event) =>
            setVideoLimitForFree(event.target.value)
          }
          flashDurationForPremiumUpdate={(event) => {
            setFlashDurationForPremium(event.target.value);
          }}
          flashDurationForFreeUpdate={(event) => {
            setFlashDurationForFree(event.target.value);
          }}
          minimumPointConversionUpdate={(event) => {
            setMinimumPointConversion(event.target.value);
          }}
          flaxConversionRateUpdate={(event) => {
            setFlaxConversionRate(event.target.value);
          }}
          birthdayCelebEligibilityUpdate={(event) => {
            setBirthdayCelebEligibility(event.target.value);
          }}
          generalSettingsSaveHandler={() => generalSettingsSave()}
        />

        <PPSettings
          level1={level1}
          level2={level2}
          level3={level3}
          level4={level4}
          level5={level5}
          level6={level6}
          level7={level7}
          level1Change={(e) => {
            setLevel1(e.target.value);
          }}
          level2Change={(e) => {
            setLevel2(e.target.value);
          }}
          level3Change={(e) => {
            setLevel3(e.target.value);
          }}
          level4Change={(e) => {
            setLevel4(e.target.value);
          }}
          level5Change={(e) => {
            setLevel5(e.target.value);
          }}
          level6Change={(e) => {
            setLevel6(e.target.value);
          }}
          level7Change={(e) => {
            setLevel7(e.target.value);
          }}
          pp_save={() => pp_saveHandler()}
        />

        {(appSettings.loading || appSettings.pp_loading) && (
          <Loader darkBg={true} />
        )}
      </div>
      {/* <div className="appSettingsWrapper">
        <PayoutSettings
          payoutMinLikes={payoutMinLikes}
          payoutMinBroadCasts={payoutMinBroadCasts}
          payoutMinDears={payoutMinDears}
          payoutMinHuddles={payoutMinHuddles}
          payoutMinTotalParticipantsInHuddle={
            payoutMinTotalParticipantsInHuddle
          }
          payoutMinAppSharesMonthly={payoutMinAppSharesMonthly}
          payoutMinPointsforNextReview={payoutMinPointsforNextReview}
          payoutMinTotalPostsbyOthersParticipants={
            payoutMinTotalPostsbyOthersParticipants
          }
          payoutMinNoFans={payoutMinNoFans}
          payoutMinNoLikers={payoutMinNoLikers}
          payoutMinNoFollowers={payoutMinNoFollowers}
          payoutMinLikesChange={(e) => {
            setPayoutMinLikes(e.target.value);
          }}
          payoutMinBroadCastsChange={(e) => {
            setPayoutMinBroadCasts(e.target.value);
          }}
          payoutMinDearsChange={(e) => {
            setPayoutMinDears(e.target.value);
          }}
          payoutMinHuddlesChange={(e) => {
            setPayoutMinHuddles(e.target.value);
          }}
          payoutMinTotalParticipantsInHuddleChange={(e) => {
            setpayoutMinTotalParticipantsInHuddle(e.target.value);
          }}
          payoutMinAppSharesMonthlyChange={(e) => {
            setPayoutMinAppSharesMonthly(e.target.value);
          }}
          payoutMinPointsforNextReviewChange={(e) => {
            setPayoutMinPointsforNextReview(e.target.value);
          }}
          payoutMinTotalPostsbyOthersParticipantsChange={(e) => {
            setPayoutMinTotalPostsbyOthersParticipants(e.target.value);
          }}
          payoutMinNoFansChange={(e) => {
            setPayoutMinNoFans(e.target.value);
          }}
          payoutMinNoLikersChange={(e) => {
            setPayoutMinNoLikers(e.target.value);
          }}
          payoutMinNoFollowersChange={(e) => {
            setPayoutMinNoFollowers(e.target.value);
          }}
          payout_save={() => generalSettingsSave()}
        />
      </div> */}
      <div className="appSettingsWrapper">
        <PayoutGeneralSettings
          payoutProcessingFee={payoutProcessingFee}
          payoutPPFirsttime={payoutPPFirsttime}
          payoutPPSecondtime={payoutPPSecondtime}
          payoutPPThirdtime={payoutPPThirdtime}
          payoutPPFourthtime={payoutPPFourthtime}
          payoutRefundablePPAmount={payoutRefundablePPAmount}
          payoutRefundablePPtype={payoutRefundablePPtype}
          payoutTransferFee={payoutTransferFee}
          payoutProcessingFeeChange={(e) => {
            setpayoutProcessingFee(e.target.value);
          }}
          payoutPPFirsttimeChange={(e) => {
            setpayoutPPFirsttime(e.target.value);
          }}
          payoutPPSecondtimeChange={(e) => {
            setpayoutPPSecondtime(e.target.value);
          }}
          payoutPPThirdtimeChange={(e) => {
            setpayoutPPThirdtime(e.target.value);
          }}
          payoutPPFourthtimeChange={(e) => {
            setpayoutPPFourthtime(e.target.value);
          }}
          payoutRefundablePPAmountChange={(e) => {
            setpayoutRefundablePPAmount(e.target.value);
          }}
          payoutRefundablePPtypeChange={(e) => {
            setpayoutRefundablePPtype(e.target.value);
          }}
          payoutTransferFeeChange={(e) => {
            setPayoutTransferFee(e.target.value);
          }}
          payout_save={() => generalSettingsSave()}
        />
        <IPRemovalSettings
          iptodelete={iptodelete}
          iptodeleteChange={(e) => {
            setiptodelete(e.target.value);
          }}
          ipSaveHandler={() => ipSaveHandler()}
        />
      </div>
      {/* <div className="full-width-wrapper">
        <PayoutMinimumEligibilitySettings />
      </div> */}
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
    </div>
  );
};

export default AppSettings;
