import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Datatable from "../../../components/UI/Tables/Datatable";
import {
  allFlashCoumnData,
  flashBlockedUsersColumnData,
} from "../../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../helpers/constants";
import {
  checkUserPermission,
  convertTime,
  convertToUAETime,
} from "../../../helpers/utils";
import {
  blockOrUnblockUserFromFlash,
  deleteFlashes,
  getAllFlash,
  getFlashBlockedUsers,
  getFlashes,
} from "../../../store/flashManagement/actions";
import { VideoPreview } from "../../UserMangement/TabSection/Flashes/VideoPreview";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import Loader from "../../../components/Common/Loader";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import { blockUser } from "../../../store/users/actions";

const FlashBlockedUsers = () => {
  const dispatch = useDispatch();
  const basicRequest = {
    // sort: "created_on",
    // sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const [isOpen, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const [userPermissions, setUserPermissions] = useState({});
  // const [flashPermissions, setFlashPermissions] = useState({});
  // const isDeleteable = flashPermissions?.delete || false;
  // const isBlockable = userPermissions?.edit || false;
  const loginData = useSelector((state) => state.Login);
  const data = useSelector((state) => state.FlashCategories);
  console.log("flash block", data);

  useEffect(() => {
    dispatch(getFlashBlockedUsers(request));
  }, [request]);

  // useEffect(() => {
  //   if (loginData?.rolePermissions?.role) {
  //     setFlashPermissions(
  //       checkUserPermission(
  //         loginData?.rolePermissions?.role,
  //         "Flash Management",
  //         "Flash"
  //       )
  //     );
  //     setUserPermissions(
  //       checkUserPermission(
  //         loginData?.rolePermissions?.role,
  //         "User Management",
  //         "Users"
  //       )
  //     );
  //   }
  // }, [loginData]);
  const profilePicClick = (e, id) => {
    e.preventDefault();
    const win = window.open(`/user-details/${id}?tab=1`, "_blank");
    win.focus();
  };
  const flashBlockedUsersData =
    data?.flashBlockedUsers?.blocked_users_list?.map((flash, index) => ({
      ...flash,

      no: (request?.page - 1) * request?.page_count + index + 1,
      // createdOn: convertToUAETime(flash.time_created, DATE_WITH_TIME_IN_SECONDS),
      blocked_user: (
        <div
          data-tag="allowRowEvents"
          title={flash?.user_details?.name}
          onClick={(e) => profilePicClick(e, flash?.user_details?.id)}
        >
          {flash?.user_details?.name}
        </div>
      ),
      blocked_by: (
        <div
          data-tag="allowRowEvents"
          title={flash?.blocked_by_user_details?.name}
          onClick={(e) =>
            profilePicClick(e, flash?.blocked_by_user_details?.id)
          }
        >
          {flash?.blocked_by_user_details?.name}
        </div>
      ),

      time_updated: (
        <div data-tag="allowRowEvents" title={flash.time_updated}>
          {convertTime(flash.time_updated)}
        </div>
      ),

      actions: (
        <div>
          <div className="cust-table-actions-wrap">
            <button
              onClick={() => unblockPromptHandler(flash?.user_details?.id)}
              className=" action-btn"
              style={{
                fontSize: "11px",
                padding: "5px",
                backgroundColor: "#dedfe0",
                color: "#969696",
                fontWeight: "bold",

                // opacity: !isDeleteable || !isBlockable ? "50%" : "",
                // cursor: !isDeleteable || !isBlockable ? "not-allowed" : "",
              }}
              title="Unblock"

              // disabled={!isDeleteable || !isBlockable}
            >
              UNBLOCK
            </button>
          </div>
        </div>
      ),
    }));

  const unblockPromptHandler = (userId) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      userId: userId,
      action: "unblock",
      content: `Are you sure you want to unblock the user from posting Flash videos? `,
      type: "unblock",
    });
  };

  const okHandler = () => {
    promptMessage.type === "unblock" &&
      dispatch(
        blockOrUnblockUserFromFlash({
          id: promptMessage?.userId,
          action: promptMessage?.action,

          callback: () => dispatch(getFlashBlockedUsers()),
        })
      );
  };

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error, data?.success]);

  const scrollToAlertMessage = () => {
    if (data?.error || data?.success) {
      let element = warningAlertRef?.current;
      let top = element?.offsetTop;
      let height = element?.getBoundingClientRect().height;
      pageWrapRef?.current?.scrollTo(0, top - height - 30);
    }
  };

  const onRowClicked = (review) => {
    setOpen(true);
    setSelectedRow(review);
  };
  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Container id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Blocked Users" />
          {data?.error && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            </div>
          )}

          <Datatable
            //   defaultSortField={request?.sort || "created_on"}
            //   defaultSortAsc={request?.sort_order === "asc" ? true : false}
            tableID={"reviewTable"}
            columns={flashBlockedUsersColumnData}
            rows={flashBlockedUsersData}
            setRequest={setRequest}
            request={request}
            // totalRecords={data?.allFlash?.total}
            //   search
            //   podiumFilter
            //   reqTypeFilter={request.type}
            // tableRowClicked={(param) => onRowClicked(param)}
            // addNewLabel={t("Admins.addAdmin.addBtn")}
            // searchTerm={data?.request?.keyword}
            // reqstatusFilter={request?.status_filter}
            // reqmembershipFilter={request?.membership_filter}
            // loading={data?.loading}
            tableCardClassName={"snoTable"}
            // deleteLabel="DELETE"
            // deleteClickHandler={deleteAll}
            // deleteDisabled={!selectedHuddles.length}
            // showDeleteButton={true}
            // deleteConfirmationMsg="Do you really want to delete the selected huddles?"
          />
        </Row>
        <VideoPreview
          selectedRow={selectedRow}
          isOpen={isOpen}
          setOpen={setOpen}
        />
      </Container>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />

      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default FlashBlockedUsers;
