import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPostatSignedCookies } from "../../../store/cookies/action";

const TrackComponent = ({ item, cookies, onPlay, musicId }) => {
  const audioRef = useRef(null);

  const handlePlay = () => {
    onPlay(audioRef.current);
  };

  return (
    <div key={musicId} data-tag="allowRowEvents" title="song">
      {cookies &&
      cookies["CloudFront-Policy"] &&
      cookies["CloudFront-Key-Pair-Id"] &&
      cookies["CloudFront-Signature"] ? (
        <audio
          id={musicId}
          ref={audioRef}
          onPlay={handlePlay}
          controls
          style={{ width: "250px" }}
        >
          <source
            src={`${item?.media_meta?.media_url}?Policy=${encodeURIComponent(
              cookies?.["CloudFront-Policy"]
            )}&Key-Pair-Id=${encodeURIComponent(
              cookies?.["CloudFront-Key-Pair-Id"]
            )}&Signature=${encodeURIComponent(
              cookies?.["CloudFront-Signature"]
            )}`}
            type="audio/mpeg"
          />
          Your browser does not support the audio element.
        </audio>
      ) : (
        <p>No audio available</p>
      )}
    </div>
  );
};

export default TrackComponent;
