export const LOGIN_AUTH = "LOGIN_AUTH"
export const AUTH_SUCCESS = "AUTH_SUCCESS"

export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS";
export const GET_USER_PERMISSIONS_BEGIN = "GET_USER_PERMISSIONS_BEGIN";
export const GET_USER_PERMISSIONS_SUCCESS = "GET_USER_PERMISSIONS_SUCCESS";
export const GET_USER_PERMISSIONS_FAIL = "GET_USER_PERMISSIONS_FAIL";

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const RESET_USER_PERMISSIONS = "RESET_USER_PERMISSIONS"
