import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";

const TreePagination = ({
  loading,
  totalRecords,
  level,
  paginationAction,
  page,
}) => {
  // States
  const [activePage, setActivePage] = useState(page || 1);
  const [perPage, setPerPage] = useState(100);
  const [premiumFilter, setPremiumFilter] = useState("premium");

  /**
   * Function that call on change of Premium Status Filter
   * @param {*} event
   */
  const onChangePremiumFilter = (event) => {
    setPremiumFilter(event.target.value);
    paginationAction(
      { page: activePage, limit: perPage, filter_value: event.target.value },
      level
    );
  };

  return (
    <div className="table_bottom_wrap">
      <div className="page_dd_wrap">
        <div className="perPage-wrap">
          <label className="col-form-label cust-select-label-wrap">
            {" "}
            Per Page{" "}
          </label>
          <select
            className="form-control tableRecordsSelect cursor-pointer"
            onChange={(e) => {
              setPerPage(parseInt(e.target.value, 10));
              setActivePage(1);
              paginationAction(
                {
                  page: 1,
                  limit: e.target.value,
                  filter_value: premiumFilter,
                },
                level
              );
            }}
            value={perPage}
          >
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>500</option>
            <option>1000</option>
          </select>
        </div>

        {/* Free/Premium Satus Filter  */}
        <div className="perPage-wrap">
          <label className="col-form-label cust-select-label-wrap">
            {" "}
            Filter:{" "}
          </label>
          <select
            className="form-control tableRecordsSelect"
            onChange={onChangePremiumFilter}
            value={premiumFilter}
          >
            <option selected value="premium">
              Premium
            </option>
            <option value="free">Free</option>
            <option value="expired">Expired</option>
            <option value="">All</option>
          </select>
        </div>

        {!loading && totalRecords > 0 && (
          <div className="total-records">
            {`${activePage * perPage - (perPage - 1)}-${
              activePage * perPage > totalRecords
                ? totalRecords
                : activePage * perPage
            } of ${totalRecords}`}{" "}
          </div>
        )}
      </div>

      <div className="pagination_wrap">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={perPage}
          totalItemsCount={totalRecords || 0}
          pageRangeDisplayed={5}
          itemClass="page-item"
          linkClass="page-link"
          onChange={(val) => {
            setActivePage(val);
            paginationAction(
              { page: val, limit: perPage, filter_value: premiumFilter },
              level
            );
          }}
        />
      </div>
    </div>
  );
};

export default TreePagination;
