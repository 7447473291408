import { get } from "../../helpers/api_helper";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  getUserFlaxOrCoinsStatementsBegin,
  getUserFlaxOrCoinsStatementsFail,
  getUserFlaxOrCoinsStatementsSuccess,
} from "./actions";
import { GET_USER_FLAX_OR_COINS_STATEMENTS } from "./actionTypes";
import { API_GET_USER_FLAX_OR_COINS_STATEMENTS } from "../../helpers/url_helper";

function* getUserFlaxOrCoinsStatementsSaga({ request, userId }) {
  try {
    yield put(getUserFlaxOrCoinsStatementsBegin());
    const response = yield call(
      get,
      `/admin/users/flaxcoinstatements?user_id=${userId}`,
      request
    );
    yield put(getUserFlaxOrCoinsStatementsSuccess(response.result));
  } catch (error) {
    yield put(getUserFlaxOrCoinsStatementsFail(error));
  }
}

function* flaxorCoinsStatementsSaga() {
  yield takeLatest(
    GET_USER_FLAX_OR_COINS_STATEMENTS,
    getUserFlaxOrCoinsStatementsSaga
  );
}

export default flaxorCoinsStatementsSaga;
