import {
  GET_PAYOUT_REQUESTS_BEGIN,
  GET_PAYOUT_REQUESTS_SUCCESS,
  GET_PAYOUT_REQUESTS_FAIL,
  EDIT_PAYOUT_REQUEST_BEGIN,
  EDIT_PAYOUT_REQUEST_SUCCESS,
  EDIT_PAYOUT_REQUEST_FAIL,
  UPDATE_PAYOUT_REQUEST_BEGIN,
  UPDATE_PAYOUT_REQUEST_SUCCESS,
  UPDATE_PAYOUT_REQUEST_FAIL,
  PROCESS_PAYMENT_BEGIN,
  PROCESS_PAYMENT_SUCCESS,
  PROCESS_PAYMENT_FAIL,
  GET_PAYOUT_STATUS_BEGIN,
  GET_PAYOUT_STATUS_SUCCESS,
  GET_PAYOUT_STATUS_FAIL,
  ADD_PAYOUT_STATUS_BEGIN,
  ADD_PAYOUT_STATUS_SUCCESS,
  ADD_PAYOUT_STATUS_FAIL,
  EDIT_PAYOUT_STATUS_BEGIN,
  EDIT_PAYOUT_STATUS_SUCCESS,
  EDIT_PAYOUT_STATUS_FAIL,
  UPDATE_PAYOUT_STATUS_BEGIN,
  UPDATE_PAYOUT_STATUS_SUCCESS,
  UPDATE_PAYOUT_STATUS_FAIL,
  DELETE_PAYOUT_STATUS_BEGIN,
  DELETE_PAYOUT_STATUS_SUCCESS,
  DELETE_PAYOUT_STATUS_FAIL,
  GET_PAYOUT_STATEMENT_BEGIN,
  GET_PAYOUT_STATEMENT_SUCCESS,
  GET_PAYOUT_STATEMENT_FAIL,
  SEND_IN_REVIEW_EMAIL_BEGIN,
  SEND_IN_REVIEW_EMAIL_SUCCESS,
  SEND_IN_REVIEW_EMAIL_FAIL,
  UPDATE_PAYOUT_PAYMENT_DETAILS_BEGIN,
  UPDATE_PAYOUT_PAYMENT_DETAILS_SUCCESS,
  UPDATE_PAYOUT_PAYMENT_DETAILS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  data: {},
  payout_requests: {},
  payoutRequest_details: {},
  payout_status: {},
  payout_statement: {},
  error: "",
  success: "",
};

const Payouts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYOUT_REQUESTS_BEGIN:
      return {
        ...state,
        data: {},
        loading: true,
        error: "",
        success: "",
        payout_requests: {},
      };

    case GET_PAYOUT_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_PAYOUT_REQUESTS_FAIL:
      return {
        ...state,
        data: {},
        loading: false,
        error: action.payload,
      };

    case EDIT_PAYOUT_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
        payoutRequest_details: {},
        error: "",
        // success: "",
      };

    case EDIT_PAYOUT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        payoutRequest_details: action.payload,
        payout_requests: {},
        // success: "",
      };

    case EDIT_PAYOUT_REQUEST_FAIL:
      return {
        ...state,
        payoutRequest_details: {},
        loading: false,
        error: action.payload,
      };

    case UPDATE_PAYOUT_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case UPDATE_PAYOUT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case UPDATE_PAYOUT_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PROCESS_PAYMENT_BEGIN:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };

    case PROCESS_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        payoutRequest_details: action.payload.result,
        success: "Payment Processing Initiated",
      };

    case PROCESS_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case GET_PAYOUT_STATUS_BEGIN:
      return {
        ...state,
        data: {},
        loading: true,
        error: "",
        success: "",
        payout_status: {},
      };

    case GET_PAYOUT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        payout_status: action.payload,
      };

    case GET_PAYOUT_STATUS_FAIL:
      return {
        ...state,
        data: {},
        loading: false,
        error: action.payload,
      };

    case ADD_PAYOUT_STATUS_BEGIN:
      return {
        ...state,
        loading: true,
        payout_status: {},
        error: "",
        success: "",
      };

    case ADD_PAYOUT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case ADD_PAYOUT_STATUS_FAIL:
      return {
        ...state,
        payout_status: {},
        loading: false,
        error: action.payload,
      };

    case EDIT_PAYOUT_STATUS_BEGIN:
      return {
        ...state,
        loading: true,
        payout_status: {},
        error: "",
        success: "",
      };

    case EDIT_PAYOUT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        payout_status: action.payload,
      };

    case EDIT_PAYOUT_STATUS_FAIL:
      return {
        ...state,
        payout_status: {},
        loading: false,
        error: action.payload,
      };

    case UPDATE_PAYOUT_STATUS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case UPDATE_PAYOUT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case UPDATE_PAYOUT_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_PAYOUT_STATUS_BEGIN:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      };

    case DELETE_PAYOUT_STATUS_SUCCESS:
      return {
        ...state,
        editLoading: false,
        error: "",
        success: action.payload,
      };

    case DELETE_PAYOUT_STATUS_FAIL:
      return {
        ...state,
        editLoading: false,
        error: action.payload,
        success: "",
      };

    case GET_PAYOUT_STATEMENT_BEGIN:
      return {
        ...state,
        data: {},
        loading: true,
        error: "",
        success: "",
        payout_statement: {},
      };

    case GET_PAYOUT_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        payout_statement: action.payload,
      };

    case GET_PAYOUT_STATEMENT_FAIL:
      return {
        ...state,
        data: {},
        loading: false,
        error: action.payload,
      };

    case SEND_IN_REVIEW_EMAIL_BEGIN:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };

    case SEND_IN_REVIEW_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        // emailDetails: action.payload.result,
        success: "Review Email Sent Successfully",
      };

    case SEND_IN_REVIEW_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case UPDATE_PAYOUT_PAYMENT_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      }

    case UPDATE_PAYOUT_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: "Payout Payment Details Updated Successfully",
      }

    case UPDATE_PAYOUT_PAYMENT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      }

    default:
      return state;
  }
};

export default Payouts;
