export const GET_GIFT_PACKAGES = "GET_GIFT_PACKAGES";
export const GET_GIFT_PACKAGES_BEGIN = "GET_GIFT_PACKAGES_BEGIN";
export const GET_GIFT_PACKAGES_SUCCESS = "GET_GIFT_PACKAGES_SUCCESS";
export const GET_GIFT_PACKAGES_FAIL = "GET_GIFT_PACKAGES_FAIL";

export const DELETE_GIFT_PACKAGES = "DELETE_GIFT_PACKAGES";
export const DELETE_GIFT_PACKAGES_BEGIN = "DELETE_GIFT_PACKAGES_BEGIN";
export const DELETE_GIFT_PACKAGES_SUCCESS = "DELETE_GIFT_PACKAGES_SUCCESS";
export const DELETE_GIFT_PACKAGES_FAIL = "DELETE_GIFT_PACKAGES_FAIL";

export const ADD_GIFT_PACKAGES = "ADD_GIFT_PACKAGES";
export const ADD_GIFT_PACKAGES_BEGIN = "ADD_GIFT_PACKAGES_BEGIN";
export const ADD_GIFT_PACKAGES_SUCCESS = "ADD_GIFT_PACKAGES_SUCCESS";
export const ADD_GIFT_PACKAGES_FAIL = "ADD_GIFT_PACKAGES_FAIL";

export const UPDATE_GIFT_PACKAGES = "UPDATE_GIFT_PACKAGES";
export const UPDATE_GIFT_PACKAGES_BEGIN = "UPDATE_GIFT_PACKAGES_BEGIN";
export const UPDATE_GIFT_PACKAGES_SUCCESS = "UPDATE_GIFT_PACKAGES_SUCCESS";
export const UPDATE_GIFT_PACKAGES_FAIL = "UPDATE_GIFT_PACKAGES_FAIL";

export const GET_GIFT_PACKAGES_BY_ID = "GET_GIFT_PACKAGES_BY_ID";
export const GET_GIFT_PACKAGES_BY_ID_BEGIN = "GET_GIFT_PACKAGES_BY_ID_BEGIN";
export const GET_GIFT_PACKAGES_BY_ID_SUCCESS =
  "GET_GIFT_PACKAGES_BY_ID_SUCCESS";
export const GET_GIFT_PACKAGES_BY_ID_FAIL = "GET_GIFT_PACKAGES_BY_ID_FAIL";
export const RESET_GIFT_PACKAGE_ERRORS = "RESET_GIFT_PACKAGE_ERRORS";
