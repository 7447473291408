import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, Row, UncontrolledAlert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";

import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";
import Camera_icon from "./../../../assets/icons/ebud-icons/Camera.svg";
import Datatable from "../../../components/UI/Tables/Datatable";

import { checkUserPermission, convertDate } from "../../../helpers/utils";

import moment from "moment";

import Avatar from "../../../assets/images/users/user.svg";
import { DATE_REGEX } from "../../../helpers/constants";
import {
  payoutStatementColumnData,
  payoutStatement27ColumnData,
  payoutStatementRefundablePPColumnData,
} from "../../../helpers/columns";

import {
  getAdminsData,
  storeRequest,
} from "../../../store/adminManagement/admins/action";
import { getPayoutStatement } from "../../../store/payouts/action";

const PayoutStatement = () => {
  const match = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const toggle = () => setShowPromptPopUp(!showPromptPopUp);
  const [promptMessage, setPromptMessage] = useState({});
  // const [payoutStatementRow, setpayoutStatementRow] = useState({});

  const [dateError, setDateError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [formChanged, setFormChanged] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [backToListing, setBackToListing] = useState(false);

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();

  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const data = useSelector((state) => state.user);
  const payout = useSelector((state) => state.Payouts);
  const admins = useSelector((state) => state.admins);
  const [ppLevel1Row, setppLevel1Row] = useState({});
  const [ppLevel2Row, setppLevel2Row] = useState({});
  const [refundablePPRow, setRefundablePPRow] = useState({});

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Users"
        )
      );
    }
  }, [loginData]);

  const basicRequest = {
    id: match.id,
    sort: "total",
    sort_order: "desc",
    page: 1,
    page_count: 50,
    date_range_filter: [],
    start_date: moment(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ).format("DD-MM-YYYY"),
    end_date: moment(new Date()).format("DD-MM-YYYY"),
  };
  const [request, setRequest] = useState({ ...basicRequest });
  useEffect(() => {
    dispatch(getPayoutStatement(request));
    dispatch(getAdminsData());
  }, [request, dispatch]);

  useEffect(() => {
    backToListing &&
      history.push({
        pathname:
          history?.location?.state?.from === "payoutRequestDetails"
            ? `/payout-requests/${history?.location?.state?.PRDetailsID}`
            : `/payout-requests/`,
        state: { from: "payoutStatement" },
        // state: { from: "keepFilter", PRUpdated: updated },
      });
    console.log(history);
  }, [backToListing, match.id, history]);

  useEffect(() => {
    scrollToAlertMessage();
  }, [dateError, genderError, typeof data?.error === "string" && data?.error]);

  const scrollToAlertMessage = () => {
    if (
      dateError ||
      genderError ||
      (typeof data?.error === "string" && data?.error)
    ) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  const payoutStatementLevel1Row = () => {
    return payout?.payout_statement?.level_one_subscriptions.map(
      (pp, index) => ({
        ...pp,
        no: (request?.page - 1) * request?.page_count + index + 1,
        date: (
          <div data-tag="allowRowEvents" title={pp.created}>
            {pp.created}
          </div>
        ),
        details: (
          <div data-tag="allowRowEvents" title={pp.event}>
            {pp.event}
          </div>
        ),
        credit: (
          <div data-tag="allowRowEvents" title={pp.points_added}>
            {pp.points_added}
          </div>
        ),
      })
    );
  };

  const payoutStatementLevel2Row = () => {
    return payout?.payout_statement?.other_level_subscriptions.map(
      (pp, index) => ({
        ...pp,
        no: (request?.page - 1) * request?.page_count + index + 1,
        date: (
          <div data-tag="allowRowEvents" title={pp.created}>
            {pp.created}
          </div>
        ),
        details: (
          <div data-tag="allowRowEvents" title={pp.event}>
            {pp.event}
          </div>
        ),
        level: (
          <div data-tag="allowRowEvents" title={pp.level}>
            {pp.level}
          </div>
        ),
        credit: (
          <div data-tag="allowRowEvents" title={pp.points_added}>
            {pp.points_added}
          </div>
        ),
      })
    );
  };

  const payoutStatementRefundablePPRow = () => {
    return payout?.payout_statement?.refund_data.map((pp, index) => ({
      ...pp,
      no: (request?.page - 1) * request?.page_count + index + 1,
      date: (
        <div data-tag="allowRowEvents" title={pp.time_created}>
          {pp.time_created}
        </div>
      ),
      pp: (
        <div data-tag="allowRowEvents" title={pp.pp}>
          {pp.pp}
        </div>
      ),
      withdrawn: (
        <div data-tag="allowRowEvents" title={pp.withdrawn}>
          {pp.withdrawn === false ? "No" : "Yes"}
        </div>
      ),
    }));
  };

  useEffect(() => {
    payout?.payout_statement?.level_one_subscriptions?.length &&
      setppLevel1Row(payoutStatementLevel1Row());
  }, [payout?.payout_statement?.level_one_subscriptions?.length]);
  useEffect(() => {
    payout?.payout_statement?.other_level_subscriptions?.length &&
      setppLevel2Row(payoutStatementLevel2Row());
    payout?.payout_statement?.refund_data?.length &&
      setRefundablePPRow(payoutStatementRefundablePPRow());
  }, [payout?.payout_statement?.other_level_subscriptions?.length]);

  const confirmBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
  };

  const redirectMethod = () => {
    setBackToListing(true);
  };

  const handleViewProfile = () => {
    history.push({
      pathname: `/user-details/${payout.payoutRequest_details?.detailed?.user_id}`,
      // state: { from: "keepFilter", PRUpdated: updated },
    });
  };

  useEffect(() => {
    const StatResp =
      payout?.payout_status?.status?.length &&
      payout?.payout_status?.status?.map((dataValues, Datakey) => {
        if (
          dataValues.id === payout?.payoutRequest_details?.detailed?.status_id
        ) {
          delete payout?.payout_status?.status[Datakey]; //Removing Current Status
        }
        delete payout?.payout_status?.status[3]; //Removing Payment ProcessInitiated
        delete payout?.payout_status?.status[4]; //Removing Payment ProcessProcessed
      });
  }, [payout?.payout_status?.status, payout?.payoutRequest_details?.detailed]);

  useEffect(() => {
    data?.error?.date_of_birth && setDateError(data?.error?.date_of_birth[0]);
    data?.error?.gender && setGenderError(data?.error?.gender[0]);
  }, [data?.error]);

  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };

  return (
    <div
      className="page-content userDetailWrap"
      data-testid="component-editUserDetail"
      ref={pageWrapRef}
    >
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            return confirmBrowserBack();
          }
        }}
        when={formChanged}
      />
      <BackButton
        label={
          history?.location?.state?.from === "payoutRequests"
            ? "Payout Requests"
            : "Payout Request Details"
        }
        handleClick={() => (formChanged ? confirmBack() : redirectMethod())}
      />
      <Breadcrumbs title="Tables" breadcrumbItem="Payout Statement" />
      <Col xl="12">
        {(payout?.error || payout?.success) && (
          <UncontrolledAlert
            color={payout?.error ? "danger" : "success"}
            className="alert-dismissible fade show"
            role="alert"
          >
            {payout?.error || payout?.success}
          </UncontrolledAlert>
        )}

        <section style={{ backgroundColor: "#f5f6f8" }}>
          <div className=" py-3">
            <div className="row">
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body text-center">
                    <img
                      src={payout?.payout_statement?.profile_image || Avatar}
                      alt="avatar"
                      className="rounded-circle img-fluid"
                      style={{ width: "150px" }}
                    />
                    <h5 className="my-3">{payout?.payout_statement?.name}</h5>
                    <p className="text-muted mb-1">
                      Username: <b>{payout?.payout_statement?.username}</b>
                    </p>
                    <p className="text-muted mb-4">
                      {payout?.payout_statement?.country}
                    </p>
                    <div className="d-flex justify-content-center mb-2">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => {
                          handleViewProfile();
                        }}
                      >
                        View Profile
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card mb-4 mb-md-0">
                  <div className="card-body">
                    <p className="mb-4">
                      <span className="text-primary font-italic me-1">
                        Account
                      </span>{" "}
                      Summary
                      <br /> <b>Statement Period:</b> {request.start_date} to{" "}
                      {request.end_date}
                    </p>
                    <p className="mb-1" style={{ fontSize: ".77rem" }}></p>
                    <p className="mt-4 mb-1">
                      Total Gained PP:{" "}
                      {payout?.payout_statement?.total_gained_pp}
                    </p>
                    <p className="mt-4 mb-1" style={{ fontSize: ".77rem" }}></p>
                    Total Withdrawn PP:{" "}
                    {payout?.payout_statement?.total_withdrawn_pp}
                    <p className="mt-4 mb-1" style={{ fontSize: ".77rem" }}></p>
                    Total PP Balance: {payout?.payout_statement?.total_balance}
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                {/* <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Payout Request Status</p>
                      </div>
                      <div className="col-sm-9">
                        <p></p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Date of Payout Request</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">
                          {
                            payout?.payoutRequest_details?.detailed
                              ?.requested_date
                          }
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Date of Join</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">
                          {
                            payout?.payoutRequest_details?.detailed
                              ?.date_of_join
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                <Breadcrumbs
                  title="Tables"
                  breadcrumbItem="Level 1 Transactions"
                />
                <Datatable
                  defaultSortField={"total"}
                  defaultSortAsc={request?.sort_order === "asc" ? true : false}
                  tableID={"ppStatement"}
                  columns={payoutStatementColumnData}
                  rows={ppLevel1Row}
                  setRequest={setRequest}
                  request={request}
                  // totalRecords={
                  //   appInstallations?.installationData?.total_records
                  // }
                  payoutStatementFilter
                  tableCardClassName={"snoTable"}
                  countries
                />
                <Breadcrumbs
                  title="Tables"
                  breadcrumbItem="Level 2-7 Transactions"
                />
                <Datatable
                  defaultSortField={"total"}
                  defaultSortAsc={request?.sort_order === "asc" ? true : false}
                  tableID={"ppStatement"}
                  columns={payoutStatement27ColumnData}
                  rows={ppLevel2Row}
                  setRequest={setRequest}
                  request={request}
                  // totalRecords={
                  //   appInstallations?.installationData?.total_records
                  // }
                  // payoutStatementFilter
                  tableCardClassName={"snoTable"}
                  countries
                />
                <Breadcrumbs
                  title="Tables"
                  breadcrumbItem="Refundable PP Details"
                />
                <Datatable
                  defaultSortField={"total"}
                  defaultSortAsc={request?.sort_order === "asc" ? true : false}
                  tableID={"ppStatement"}
                  columns={payoutStatementRefundablePPColumnData}
                  rows={refundablePPRow}
                  setRequest={setRequest}
                  request={request}
                  // totalRecords={
                  //   appInstallations?.installationData?.total_records
                  // }
                  // payoutStatementFilter
                  tableCardClassName={"snoTable"}
                  countries
                />
              </div>
              <div className="col-lg-8"></div>
            </div>
          </div>
        </section>
      </Col>

      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default PayoutStatement;
