import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  CardSubtitle,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { checkUserPermission } from "../../../helpers/utils";

const IPRemovalSettings = ({ iptodelete, iptodeleteChange, ipSaveHandler }) => {
  const { t } = useTranslation();
  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Settings",
          "App settings"
        )
      );
    }
  }, [loginData]);

  return (
    <Card className="appSettingCard">
      <CardTitle className="cardTitle">IP Remove from Blocking</CardTitle>
      <CardBody>
        {/* <CardSubtitle className="text-muted cardSubTitle">
          {t("AppSettings.SubTitle")}
        </CardSubtitle> */}
        <AvForm
          onValidSubmit={(e, v) => {
            //handleUpdate(e, v);
          }}
        >
          <div className="generalSettings">
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>Enter the IPs to Remove from Blocking</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="ipstoRemove"
                  type="text"
                  onChange={iptodeleteChange}
                  value={iptodelete}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">
                  IPs sepedated by ,
                </span>
              </div>
            </div>
          </div>
        </AvForm>
      </CardBody>
      <CardFooter className="cardFooter">
        <button
          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
          onClick={ipSaveHandler}
          disabled={!isEditable}
        >
          {t("settings.Save")}
        </button>
      </CardFooter>
    </Card>
  );
};

export default IPRemovalSettings;
