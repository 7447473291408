import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

//importing icons
import icon_username_mgmt from "../../../assets/icons/menu-icons/username-management.svg";
import icon_user_mgmt from "../../../assets/icons/menu-icons/user-management.svg";
import icon_cntn_mgmt from "../../../assets/icons/menu-icons/content-management.svg";
import icon_dashboard from "../../../assets/icons/menu-icons/dashboard.svg";
import icon_gift_mgmt from "../../../assets/icons/menu-icons/gift-management.svg";
import icon_flax_mgmt from "../../../assets/icons/menu-icons/flax-icon.svg";
// import icon_huddles from "../../../assets/icons/menu-icons/huddles.svg";
import icon_rpt_data from "../../../assets/icons/menu-icons/reported_data.svg";
import icon_settings from "../../../assets/icons/menu-icons/settings.svg";
import icon_statistics from "../../../assets/icons/menu-icons/statistics.svg";
import { checkUserViewPermission } from "../../../helpers/utils";
import icon_admin_management from "../../../assets/icons/menu-icons/admin_management.svg";
import icon_huddles from "../../../assets/icons/menu-icons/huddles.svg";
import payout_icon from "../../../assets/icons/menu-icons/payouts-icon.svg";
import appreview from "../../../assets/icons/menu-icons/app-review.png";
import icon_podium_mgmt from "../../../assets/icons/menu-icons/podium-icon.svg";
import icon_payment from "../../../assets/icons/menu-icons/payment-management.svg";
import postat_icon from "../../../assets/icons/menu-icons/postat-icon.svg";
import flash_icon from "../../../assets/icons/menu-icons/flash.png";
const SidebarContent = (props) => {
  const ref = useRef();
  const { t } = useTranslation();
  const history = useHistory();
  const [userActive, setUserActive] = useState(false);
  const [reserveActive, setReserveActive] = useState(false);
  const [allocateActive, setAllocateActive] = useState(false);
  const [legalDocActive, setLegalDocActive] = useState(false);
  const [templateSetting, setTemplateSetting] = useState(false);
  const [installationActive, setInstallationActive] = useState(false);
  const [adminManagementActive, setAdminManagementActive] = useState(false);
  const [payoutactive, setPayoutActive] = useState(false);
  const [adminRoleManagementActive, setAdminRoleManagementActive] =
    useState(false);
  const [huddleManagementActive, setHuddleManagementActive] = useState(false);
  const [huddleCategoriesActive, setHuddleCategoriesActive] = useState(false);
  const [
    huddleReportedUserCategoriesActive,
    setHuddleReportedUserCategoriesActive,
  ] = useState(false);
  const [reportedUserActive, setReportedUserActive] = useState(false);
  const [flashActive, setFlashActive] = useState(false);
  const [podiumManagementActive, setPodiumManagementActive] = useState(false);
  const [groupManagementActive, setGroupManagementActive] = useState(false);
  const [flaxActive, setFlaxActive] = useState(false);
  const [giftActive, setGiftActive] = useState(false);
  const [appReviewActive, setAppReviewActive] = useState(false);
  const [paymentManagementActive, setPaymentManagementActive] = useState(false);
  const [postatManagementActive, setPostatManagementActive] = useState(false);

  const [rolePermisions, setRolePermisions] = useState({});

  const loginData = useSelector((state) => state.Login);

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setRolePermisions(loginData?.rolePermissions?.role);
    }
  }, [loginData]);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    new MetisMenu("#side-menu");
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  useEffect(() => {
    if (
      props.location.pathname?.includes("user-details") ||
      props.location.pathname?.includes("users")
    )
      setUserActive(true);
    else setUserActive(false);
    if (
      props.location.pathname?.includes("reserved-usernames") ||
      props.location.pathname?.includes("reserved-usernames-edit")
    )
      setReserveActive(true);
    else setReserveActive(false);
    if (
      props.location.pathname?.includes("allocate-usernames") ||
      props.location.pathname?.includes("allocate-usernames-edit")
    )
      setAllocateActive(true);
    else setAllocateActive(false);
    if (
      props.location.pathname?.includes("legal-documents") ||
      props.location.pathname?.includes("edit-legal-documents")
    )
      setLegalDocActive(true);
    else setLegalDocActive(false);
    if (
      props.location.pathname?.includes("settings-template") ||
      props.location.pathname?.includes("settings-template-edit")
    )
      setTemplateSetting(true);
    else setTemplateSetting(false);
    if (
      props.location.pathname?.includes("reported-data") ||
      props.location.pathname?.includes("reported-user-categories")
    )
      setReportedUserActive(true);
    else setReportedUserActive(false);
    if (
      props.location.pathname?.includes("app-installations") ||
      props.location.pathname?.includes("subscriptions-summary") ||
      props.location.pathname?.includes("app-subscriptions")
    )
      setInstallationActive(true);
    else setInstallationActive(false);
    if (
      props.location.pathname?.includes("admin-management") ||
      props.location.pathname?.includes("edit-admin-details") ||
      props.location.pathname?.includes("/admin-addNew") ||
      props.location.pathname?.includes("/admin-details")
    )
      setAdminManagementActive(true);
    else setAdminManagementActive(false);
    if (
      props.location.pathname?.includes("/admin-permissions") ||
      props.location.pathname?.includes("/edit-admin-permissions") ||
      props.location.pathname?.includes("/admin-role-addNew")
    )
      setAdminRoleManagementActive(true);
    else setAdminRoleManagementActive(false);
    if (
      props.location.pathname?.includes("/huddles") ||
      props.location.pathname?.includes("/huddle-details")
    )
      setHuddleManagementActive(true);
    else setHuddleManagementActive(false);
    if (
      props.location.pathname?.includes("/huddle-categories") ||
      props.location.pathname?.includes("/huddle-category-addNew") ||
      props.location.pathname?.includes("/huddle-category-edit")
    )
      setHuddleCategoriesActive(true);
    else setHuddleCategoriesActive(false);
    if (
      props.location.pathname?.includes("/huddle-categories") ||
      props.location.pathname?.includes("/huddle-category-addNew") ||
      props.location.pathname?.includes("/huddle-category-edit")
    )
      setHuddleCategoriesActive(true);
    else setHuddleCategoriesActive(false);
    if (
      props.location.pathname?.includes("/huddle-reported-user-categories") ||
      props.location.pathname?.includes("/huddle-reported-user-category-add") ||
      props.location.pathname?.includes("/huddle-reported-user-category-edit")
    )
      setHuddleReportedUserCategoriesActive(true);
    else setHuddleReportedUserCategoriesActive(false);
    if (
      props.location.pathname?.includes("payout-requests") ||
      props.location.pathname?.includes("payout-status")
    )
      setPayoutActive(true);
    else setPayoutActive(false);

    if (
      props.location.pathname?.includes("/flash-categories") ||
      props.location.pathname?.includes("/flash-categories-addNew") ||
      props.location.pathname?.includes("/flash-category-edit") ||
      props.location.pathname?.includes("/all-flash") ||
      props.location.pathname?.includes("/reported-flash") ||
      // props.location.pathname?.includes("/flash-reports") ||
      props.location.pathname?.includes("/flash-reported-comments") ||
      props?.location?.pathname?.includes("/flash-blocked-users")
    ) {
      setFlashActive(true);
    } else {
      setFlashActive(false);
    }
    if (
      props.location.pathname?.includes("/podium-categories") ||
      props.location.pathname?.includes("/podium-categories-addNew") ||
      props.location.pathname?.includes("/podium-category-edit") ||
      props.location.pathname?.includes("/podiums")
    ) {
      setPodiumManagementActive(true);
    } else {
      setPodiumManagementActive(false);
    }

    if (props.location.pathname?.includes("/groups")) {
      setGroupManagementActive(true);
    } else {
      setGroupManagementActive(false);
    }
    if (
      props.location.pathname?.includes("FLiX-transfers") ||
      props.location.pathname?.includes("FLiX-transfer-purposes") ||
      props.location.pathname?.includes("FLiX-packages") ||
      props.location.pathname?.includes("FLiX-purchase-history")
    ) {
      setFlaxActive(true);
    } else setFlaxActive(false);
    if (props.location.pathname.includes("app-review-requests")) {
      setAppReviewActive(true);
    } else setAppReviewActive(false);
    if (
      props?.location?.pathname?.includes("gift-packages") ||
      props?.location?.pathname?.includes("gift-packages-addNew") ||
      props?.location?.pathname?.includes("gift-packages-edit") ||
      props?.location?.pathname?.includes("gift-transfers") ||
      props?.location?.pathname?.includes("coins-purchase-history")
    ) {
      setGiftActive(true);
    } else {
      setGiftActive(false);
    }
    if (
      props?.location?.pathname?.includes("payment-method") ||
      props?.location?.pathname?.includes("payment-method-addNew") ||
      props?.location?.pathname?.includes("payment-method-edit") ||
      props?.location?.pathname?.includes("payment-forms") ||
      props?.location?.pathname?.includes("payment-forms-addNew") ||
      props?.location?.pathname?.includes("payment-forms-edit")
    ) {
      setPaymentManagementActive(true);
    } else {
      setPaymentManagementActive(false);
    }
    if (
      props?.location?.pathname?.includes("postat-reported-posts") ||
      props?.location?.pathname?.includes("postat-reported-comments") ||
      props?.location?.pathname?.includes("postat-music-library") ||
      props?.location?.pathname?.includes("postat-music-library-addNew") ||
      props?.location?.pathname?.includes("postat-music-library-edit") ||
      props?.location?.pathname?.includes("all-postats")
    ) {
      setPostatManagementActive(true);
    } else {
      setPostatManagementActive(false);
    }
  }, [props.location.pathname]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar
        style={{ maxHeight: "100%" }}
        ref={ref}
        className="sidebar-menu-scroll"
      >
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {
              <li
                style={{
                  display: checkUserViewPermission(rolePermisions, "Dashboard")
                    ? "block"
                    : "none",
                }}
              >
                <Link to="/dashboard" className="waves-effect">
                  <img src={icon_dashboard} />
                  <span>{t("Sidebar.Item1")}</span>
                </Link>
              </li>
            }
            {
              <li
                className={userActive ? "mm-active" : ""}
                style={{
                  display: checkUserViewPermission(
                    rolePermisions,
                    "User Management"
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    userActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img src={icon_user_mgmt} />
                  <span>{t("Sidebar.Item2")}</span>
                </Link>

                <ul className={userActive ? "sub-menu mm-show" : "sub-menu"}>
                  <li
                    className={userActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "User Management",
                        "Users"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/users"
                      onClick={() =>
                        history.location.pathname === "/users"
                          ? window.location.reload()
                          : history.push("/users")
                      }
                    >
                      <span>{t("Sidebar.Item3")}</span>
                    </Link>
                  </li>

                  <li
                    className={userActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "User Management",
                        "Account verification"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/account-verification"
                      onClick={() =>
                        history.location.pathname === "/account-verification"
                          ? window.location.reload()
                          : history.push("/account-verification")
                      }
                    >
                      <span>{t("Sidebar.Item21")}</span>
                    </Link>
                  </li>
                  {
                    <li
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Reported users",
                          "Reported users"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link to="/reported-data">
                        <span>{t("Sidebar.Item10")}</span>
                      </Link>
                    </li>
                  }
                  {
                    <li
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Reported users",
                          "Reports Category"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/reported-user-categories"
                        onClick={() =>
                          history.location.pathname ===
                          "/reported-user-categories"
                            ? window.location.reload()
                            : history.push("/reported-user-categories")
                        }
                      >
                        <span>{t("Sidebar.Item25")}</span>
                      </Link>
                    </li>
                  }
                  {
                    <li
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "User Management",
                          "Users"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/blocked-users"
                        onClick={() =>
                          history.location.pathname === "/blocked-users"
                            ? window.location.reload()
                            : history.push("/blocked-users")
                        }
                      >
                        <span>{t("Sidebar.Item37")}</span>
                      </Link>
                    </li>
                  }
                  {
                    <li
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Settings",
                          "Broadcast notification"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/announcements"
                        onClick={() =>
                          history.location.pathname === "/announcements"
                            ? window.location.reload()
                            : history.push("/announcements")
                        }
                      >
                        {t("Sidebar.Item27")}
                      </Link>
                    </li>
                  }
                </ul>
              </li>
            }
            {/* flax management */}
            {
              <li
                className={flaxActive ? "mm-active" : ""}
                style={{
                  display: checkUserViewPermission(
                    rolePermisions,
                    "FLiX Management"
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    flaxActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img width={16} src={icon_flax_mgmt} />
                  <span>{t("Sidebar.Item44")}</span>
                </Link>

                <ul className={flaxActive ? "sub-menu mm-show" : "sub-menu"}>
                  {/* New sidebar subcontent in User Management to view flax transfers */}
                  <li
                    className={flaxActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "FLiX Management",
                        "FLiX transfers"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/FLix-transfers"
                      onClick={() =>
                        history.location.pathname === "/FLix-transfers"
                          ? window.location.reload()
                          : history.push("/FLix-transfers")
                      }
                    >
                      <span>{t("Sidebar.Item35")}</span>
                    </Link>
                  </li>
                  <li
                    className={flaxActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "FLiX Management",
                        "FLiX transfer purposes"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/FLix-transfer-purposes"
                      onClick={() =>
                        history.location.pathname === "/FLix-transfer-purposes"
                          ? window.location.reload()
                          : history.push("/FLix-transfer-purposes")
                      }
                    >
                      <span>{t("Sidebar.Item36")}</span>
                    </Link>
                  </li>
                  <li
                    className={flaxActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "FLiX Management",
                        "FLiX packages"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/FLix-packages"
                      onClick={() =>
                        history.location.pathname === "/FLix-packages"
                          ? window.location.reload()
                          : history.push("/FLix-transfer-purposes")
                      }
                    >
                      <span>{t("Sidebar.Item38")}</span>
                    </Link>
                  </li>
                  <li
                    className={flaxActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "FLiX Management",
                        "FLiX purchase history"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/FLix-purchase-histroy"
                      onClick={() =>
                        history.location.pathname === "/FLix-purchase-history"
                          ? window.location.reload()
                          : history.push("/FLix-purchase-history")
                      }
                    >
                      <span>{t("Sidebar.Item39")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            }
            {
              <li
                style={{
                  display: checkUserViewPermission(
                    rolePermisions,
                    "Huddle Management"
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    huddleManagementActive || huddleCategoriesActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img src={icon_huddles} />
                  <span>{t("Sidebar.Item22")}</span>
                </Link>
                <ul
                  className={
                    huddleManagementActive || huddleCategoriesActive
                      ? "sub-menu mm-show"
                      : "sub-menu"
                  }
                >
                  {
                    <li
                      className={huddleManagementActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Huddle Management",
                          "Huddles"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/huddles"
                        onClick={() =>
                          history.location.pathname === "/huddles"
                            ? window.location.reload()
                            : history.push("/huddles")
                        }
                      >
                        {t("Sidebar.Item23")}
                      </Link>
                    </li>
                  }

                  {
                    <li
                      className={huddleCategoriesActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Huddle Management",
                          "Huddle categories"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/huddle-categories"
                        onClick={() =>
                          history.location.pathname === "/huddle-categories"
                            ? window.location.reload()
                            : history.push("/huddle-categories")
                        }
                      >
                        {t("Sidebar.Item24")}
                      </Link>
                    </li>
                  }

                  {
                    <li
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Huddle Management",
                          "Huddle reports categories"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/huddle-reported-user-categories"
                        onClick={() =>
                          history.location.pathname ===
                          "/huddle-reported-user-categories"
                            ? window.location.reload()
                            : history.push("/huddle-reported-user-categories")
                        }
                      >
                        {t("Sidebar.Item26")}
                      </Link>
                    </li>
                  }
                </ul>
              </li>
            }
            {/* //gift management */}
            {
              <li
                style={{
                  display: checkUserViewPermission(
                    rolePermisions,
                    "Gift Management"
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    giftActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img width={16} src={icon_gift_mgmt} />
                  <span>{t("Sidebar.Item40")}</span>
                </Link>
                <ul className={giftActive ? "sub-menu mm-show" : "sub-menu"}>
                  {
                    <li
                      className={giftActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Gift Management",
                          "Gift points"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/gift-packages"
                        onClick={() =>
                          history.location.pathname === "/gift-packages"
                            ? window.location.reload()
                            : history.push("/gift-packages")
                        }
                      >
                        {t("Sidebar.Item41")}
                      </Link>
                    </li>
                  }
                  {/* gift_purchase history */}

                  {
                    <li
                      className={huddleManagementActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Gift Management",
                          "Gift points"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/coins-purchase-history"
                        onClick={() =>
                          history.location.pathname ===
                          "/coins-purchase-history"
                            ? window.location.reload()
                            : history.push("/coins-purchase-history")
                        }
                      >
                        {t("Sidebar.Item42")}
                      </Link>
                    </li>
                  }
                  {
                    <li
                      className={giftActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Gift Management",
                          "Gift points"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/gift-transfers"
                        onClick={() =>
                          history.location.pathname === "/gift-transfers"
                            ? window.location.reload()
                            : history.push("/gift-transfers")
                        }
                      >
                        {t("Sidebar.Item43")}
                      </Link>
                    </li>
                  }

                  {
                    <li
                      className={giftActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Gift Management",
                          "Gift points"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/gift-categories"
                        onClick={() =>
                          history.location.pathname === "/gift-categories"
                            ? window.location.reload()
                            : history.push("/gift-categories")
                        }
                      >
                        Gift Categories
                      </Link>
                    </li>
                  }
                </ul>
              </li>
            }
            {/* podium management */}
            {
              <li
                style={{
                  display: checkUserViewPermission(
                    rolePermisions,
                    "Podium Management"
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    podiumManagementActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img width={20} height={20} src={icon_podium_mgmt} />

                  <span>{t("Sidebar.Item45")}</span>
                </Link>
                <ul
                  className={
                    podiumManagementActive ? "sub-menu mm-show" : "sub-menu"
                  }
                >
                  {
                    <li
                      className={podiumManagementActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Podium Management",
                          "Podiums"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/podiums"
                        onClick={() =>
                          history.location.pathname === "/podiums"
                            ? window.location.reload()
                            : history.push("/podiums")
                        }
                      >
                        {t("Sidebar.Item46")}
                      </Link>
                    </li>
                  }
                  {/* {
                    <li
                      className={podiumManagementActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Huddle Management",
                          "Huddle categories"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/podium-categories"
                        onClick={() =>
                          history.location.pathname === "/podium-categories"
                            ? window.location.reload()
                            : history.push("/podium-categories")
                        }
                      >
                        {t("Sidebar.Item47")}
                      </Link>
                    </li>
                  }{" "} */}

                  {
                    <li
                      className={podiumManagementActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Podium Management",
                          "Podium challenges"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/challenges-history"
                        onClick={() =>
                          history.location.pathname === "/challenges-history"
                            ? window.location.reload()
                            : history.push("/challenges-history")
                        }
                      >
                        Challenges
                      </Link>
                    </li>
                  }
                </ul>
              </li>
            }

            {/* flash management */}
            {
              <li
                style={{
                  display: checkUserViewPermission(
                    rolePermisions,
                    "Flash Management"
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    flashActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img width={20} height={20} src={flash_icon} />
                  {/* change in icon needed */}
                  <span>{t("Sidebar.Item48")}</span>
                </Link>
                <ul className={flashActive ? "sub-menu mm-show" : "sub-menu"}>
                  {/* {
                    <li
                      className={flashActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Flash Management",
                          "Flash categories"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/flash-categories"
                        onClick={() =>
                          history.location.pathname === "/flash-categories"
                            ? window.location.reload()
                            : history.push("/flash-categories")
                        }
                      >
                        {t("Sidebar.Item49")}
                      </Link>
                    </li>
                  } */}
                  <li
                    className={flashActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Flash Management",
                        "Flash"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/all-flash"
                      onClick={() =>
                        history.location.pathname === "/all-flash"
                          ? window.location.reload()
                          : history.push("/all-flash")
                      }
                    >
                      All Flash
                    </Link>
                  </li>
                  <li
                    className={flashActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Flash Management",
                        "Flash"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/flash-blocked-users"
                      onClick={() =>
                        history.location.pathname === "/flash-blocked-users"
                          ? window.location.reload()
                          : history.push("/flash-blocked-users")
                      }
                    >
                      Blocked Users
                    </Link>
                  </li>
                  <li
                    className={flashActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Flash Management",
                        "Reported flash"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/reported-flash"
                      onClick={() =>
                        history.location.pathname === "/reported-flash"
                          ? window.location.reload()
                          : history.push("/reported-flash")
                      }
                    >
                      Reported Flash
                    </Link>
                  </li>
                  <li
                    className={flashActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Flash Management",
                        "Reported flash comments"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/flash-reported-comments"
                      onClick={() =>
                        history.location.pathname === "/flash-reported-comments"
                          ? window.location.reload()
                          : history.push("/flash-reported-comments")
                      }
                    >
                      Reported Comments
                    </Link>
                  </li>
                </ul>
              </li>
            }

            {
              <li
                style={{
                  display: checkUserViewPermission(
                    rolePermisions,
                    "Group Management"
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    groupManagementActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img src={icon_huddles} />
                  <span>{t("Sidebar.Item31")}</span>
                </Link>
                <ul
                  className={
                    groupManagementActive ? "sub-menu mm-show" : "sub-menu"
                  }
                >
                  {
                    <li
                      className={groupManagementActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Group Management",
                          "Groups"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/groups"
                        onClick={() =>
                          history.location.pathname === "/groups"
                            ? window.location.reload()
                            : history.push("/groups")
                        }
                      >
                        {t("Sidebar.Item32")}
                      </Link>
                    </li>
                  }
                </ul>
              </li>
            }
            {
              <li
                className={payoutactive ? "mm-active" : ""}
                style={{
                  display: checkUserViewPermission(
                    rolePermisions,
                    "Payout Management"
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    payoutactive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img src={payout_icon} />
                  <span>{t("Sidebar.Item28")}</span>
                </Link>

                <ul className={payoutactive ? "sub-menu mm-show" : "sub-menu"}>
                  <li
                    className={payoutactive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Payout Management",
                        "Payouts"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/payout-requests"
                      onClick={() =>
                        history.location.pathname === "/payout-requests"
                          ? window.location.reload()
                          : history.push("/payout-requests")
                      }
                    >
                      {t("Sidebar.Item29")}
                    </Link>
                  </li>
                  <li
                    className={payoutactive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Payout Management",
                        "Payout status"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/payout-status"
                      onClick={() =>
                        history.location.pathname === "/payout-status"
                          ? window.location.reload()
                          : history.push("/payout-status")
                      }
                    >
                      {t("Sidebar.Item30")}
                    </Link>
                  </li>
                  <li
                    className={payoutactive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Payout Management",
                        "Payout status"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/payout-new-requests"
                      onClick={() =>
                        history.location.pathname === "/payout-new-requests"
                          ? window.location.reload()
                          : history.push("/payout-new-requests")
                      }
                    >
                      Create Payout Requests
                    </Link>
                  </li>
                </ul>
              </li>
            }

            {/* payment method management  */}

            {
              <li
                className={paymentManagementActive ? "mm-active" : ""}
                style={{
                  display: checkUserViewPermission(rolePermisions, "Sell Flax")
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    paymentManagementActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img src={icon_payment} />
                  <span>{t("Sidebar.Item50")}</span>
                </Link>

                <ul
                  className={
                    paymentManagementActive ? "sub-menu mm-show" : "sub-menu"
                  }
                >
                  <li
                    className={paymentManagementActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Sell Flax",
                        "Payment methods"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/payment-method"
                      onClick={() =>
                        history.location.pathname === "/payment-method"
                          ? window.location.reload()
                          : history.push("/payment-method")
                      }
                    >
                      {t("Sidebar.Item51")}
                    </Link>
                  </li>
                  <li
                    className={paymentManagementActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Sell Flax",
                        "Payment forms"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/payment-forms"
                      onClick={() =>
                        history.location.pathname === "/payment-forms"
                          ? window.location.reload()
                          : history.push("payment-forms")
                      }
                    >
                      {t("Sidebar.Item52")}
                    </Link>
                  </li>
                  {/* <li
                    className={payoutactive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Payout Management",
                        "Payout status"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/payout-new-requests"
                      onClick={() =>
                        history.location.pathname === "/payout-new-requests"
                          ? window.location.reload()
                          : history.push("/payout-new-requests")
                      }
                    >
                      Create Payout Requests
                    </Link>
                  </li> */}
                </ul>
              </li>
            }

            {/* Postat Management */}

            {
              <li
                className={postatManagementActive ? "mm-active" : ""}
                style={{
                  display: checkUserViewPermission(
                    rolePermisions,
                    "Postat Management"
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    postatManagementActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img width={20} height={20} src={postat_icon} />
                  <span>{t("Sidebar.Item54")}</span>
                </Link>

                <ul
                  className={
                    postatManagementActive ? "sub-menu mm-show" : "sub-menu"
                  }
                >
                  <li
                    className={postatManagementActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Postat Management",
                        "Postat"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/all-postats"
                      onClick={() =>
                        history.location.pathname === "/all-postats"
                          ? window.location.reload()
                          : history.push("/all-postats")
                      }
                    >
                      All Postats
                    </Link>
                  </li>
                  <li
                    className={postatManagementActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Postat Management",
                        "Reported posts"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/postat-reported-posts"
                      onClick={() =>
                        history.location.pathname === "/postat-reported-posts"
                          ? window.location.reload()
                          : history.push("/postat-reported-posts")
                      }
                    >
                      {t("Sidebar.Item53")}
                    </Link>
                  </li>
                  <li
                    className={postatManagementActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Postat Management",
                        "Reported comments"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/postat-reported-comments"
                      onClick={() =>
                        history.location.pathname ===
                        "/postat-reported-comments"
                          ? window.location.reload()
                          : history.push("postat-reported-comments")
                      }
                    >
                      {t("Sidebar.Item55")}
                    </Link>
                  </li>
                  <li
                    className={postatManagementActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Postat Management",
                        "Music library"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/postat-music-library"
                      onClick={() =>
                        history.location.pathname === "/postat-music-library"
                          ? window.location.reload()
                          : history.push("postat-music-library")
                      }
                    >
                      {t("Sidebar.Item56")}
                    </Link>
                  </li>
                </ul>
              </li>
            }

            {
              <li
                className={reserveActive || allocateActive ? "mm-active" : ""}
                style={{
                  display: checkUserViewPermission(
                    rolePermisions,
                    "Username Management"
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    reserveActive || allocateActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img src={icon_username_mgmt} />
                  <span>{t("Sidebar.Item4")}</span>
                </Link>
                <ul
                  className={
                    reserveActive || allocateActive
                      ? "sub-menu mm-show"
                      : "sub-menu"
                  }
                >
                  {" "}
                  {
                    <li
                      className={reserveActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Username Management",
                          "Reserve Username"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/reserved-usernames"
                        className={reserveActive ? "active" : ""}
                        onClick={() =>
                          history.location.pathname === "/reserved-usernames"
                            ? window.location.reload()
                            : history.push("/reserved-usernames")
                        }
                      >
                        {t("Sidebar.Item5")}
                      </Link>
                    </li>
                  }
                  {
                    <li
                      className={allocateActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Username Management",
                          "Allocate Username"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/allocate-usernames"
                        className={allocateActive ? "active" : ""}
                        onClick={() =>
                          history.location.pathname === "/allocate-usernames"
                            ? window.location.reload()
                            : history.push("/allocate-usernames")
                        }
                      >
                        {t("Sidebar.Item6")}
                      </Link>
                    </li>
                  }
                </ul>
              </li>
            }
            {
              <li
                className={legalDocActive ? "mm-active" : ""}
                style={{
                  display: checkUserViewPermission(
                    rolePermisions,
                    "Content management"
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    legalDocActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img src={icon_cntn_mgmt} />
                  <span>{t("Sidebar.Item7")}</span>
                </Link>
                <ul
                  className={legalDocActive ? "sub-menu mm-show" : "sub-menu"}
                >
                  <li className={legalDocActive ? "mm-active" : ""}>
                    <Link
                      to="/legal-documents"
                      onClick={() =>
                        history.location.pathname === "/legal-documents"
                          ? window.location.reload()
                          : history.push("/legal-documents")
                      }
                    >
                      {t("Sidebar.Item8")}
                    </Link>
                  </li>
                </ul>
              </li>
            }
            {
              <li
                className={templateSetting ? "mm-active" : ""}
                style={{
                  display: checkUserViewPermission(rolePermisions, "Settings")
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    templateSetting
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img src={icon_settings} />
                  <span>{t("Sidebar.Item11")}</span>
                </Link>

                <ul
                  className={templateSetting ? "sub-menu mm-show" : "sub-menu"}
                >
                  {
                    <li
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Settings",
                          "App settings"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/settings-app"
                        onClick={() =>
                          history.location.pathname === "/settings-app"
                            ? window.location.reload()
                            : history.push("/settings-app")
                        }
                      >
                        {t("Sidebar.Item12")}
                      </Link>
                    </li>
                  }
                  {
                    <li
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Settings",
                          "Template"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/settings-template"
                        onClick={() =>
                          history.location.pathname === "/settings-template"
                            ? window.location.reload()
                            : history.push("/settings-template")
                        }
                      >
                        {t("Sidebar.Item13")}
                      </Link>
                    </li>
                  }
                  <li
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "Settings",
                        "Superstar suggestion"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/superstars-suggestion"
                      onClick={() =>
                        history.location.pathname === "/superstars-suggestion"
                          ? window.location.reload()
                          : history.push("/superstars-suggestion")
                      }
                    >
                      {t("Sidebar.Item20")}
                    </Link>
                  </li>
                </ul>
              </li>
            }
            {/* {
              <li
                style={{
                  display: checkUserViewPermission(
                    rolePermisions,
                    "Reported users"
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    reportedUserActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img src={icon_rpt_data} />
                  <span>{t("Sidebar.Item10")}</span>
                </Link>
                <ul
                // className={
                //   reportedUserActive ? "sub-menu mm-show" : "sub-menu"
                // }
                >
                  
                </ul>
              </li>
            } */}
            {/* <li>
              <Link to="/app-installations" className="waves-effect">
                <img src={icon_statistics} />
                <span>{t("Sidebar.Item14")}</span>
              </Link>
            </li> */}
            {
              <li
                className={installationActive ? "mm-active" : ""}
                style={{
                  display: checkUserViewPermission(rolePermisions, "Statistics")
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    installationActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                  style={{
                    display: checkUserViewPermission(
                      rolePermisions,
                      "Statistics",
                      "App installations"
                    )
                      ? "block"
                      : "none",
                  }}
                >
                  <img src={icon_statistics} />
                  <span>{t("Sidebar.Item14")}</span>
                </Link>
                <ul
                  className={
                    installationActive ? "sub-menu mm-show" : "sub-menu"
                  }
                >
                  <li className={installationActive ? "mm-active" : ""}>
                    <Link
                      to="/app-installations"
                      onClick={() =>
                        history.location.pathname === "/app-installations"
                          ? window.location.reload()
                          : history.push("/app-installations")
                      }
                    >
                      {t("Sidebar.Item15")}
                    </Link>
                  </li>
                  <li className={installationActive ? "mm-active" : ""}>
                    <Link
                      to="/subscriptions-summary"
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Statistics",
                          "App installations"
                        )
                          ? "block"
                          : "none",
                      }}
                      onClick={() =>
                        history.location.pathname === "/subscriptions-summary"
                          ? window.location.reload()
                          : history.push("/subscriptions-summary")
                      }
                    >
                      Subscriptions Summary
                    </Link>
                  </li>
                  <li className={installationActive ? "mm-active" : ""}>
                    <Link
                      to="/app-subscriptions"
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Statistics",
                          "App installations"
                        )
                          ? "block"
                          : "none",
                      }}
                      onClick={() =>
                        history.location.pathname === "/app-subscriptions"
                          ? window.location.reload()
                          : history.push("/app-subscriptions")
                      }
                    >
                      App Subscriptions
                    </Link>
                  </li>
                  <li className={installationActive ? "mm-active" : ""}>
                    <Link
                      to="/COiNS-purchase-summary"
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Statistics",
                          "App installations"
                        )
                          ? "block"
                          : "none",
                      }}
                      onClick={() =>
                        history.location.pathname === "/COiNS-purchase-summary"
                          ? window.location.reload()
                          : history.push("/COiNS-purchase-summary")
                      }
                    >
                      COiNS Purchase Summary
                    </Link>
                  </li>
                  <li className={installationActive ? "mm-active" : ""}>
                    <Link
                      to="/FLiX-purchase-summary"
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Statistics",
                          "App installations"
                        )
                          ? "block"
                          : "none",
                      }}
                      onClick={() =>
                        history.location.pathname === "/FLiX-purchase-summary"
                          ? window.location.reload()
                          : history.push("/FLiX-purchase-summary")
                      }
                    >
                      FLiX Purchase Summary
                    </Link>
                  </li>
                </ul>
              </li>
            }
            {/* 
              <li className={installationActive ? "mm-active" : ""}>
              <Link
                to="/#"
                className={
                  installationActive
                    ? "has-arrow waves-effect mm-active"
                    : "has-arrow waves-effect"
                }
              >
                <img src={icon_statistics} />
                <span>{t("Sidebar.Item14")}</span>
              </Link>
              <ul
                className={installationActive ? "sub-menu mm-show" : "sub-menu"}
              >
                <li className={installationActive ? "mm-active" : ""}>
                  <Link
                    to="/app-installations"
                    onClick={() =>
                      history.location.pathname === "/app-installations"
                        ? window.location.reload()
                        : history.push("/app-installations")
                    }
                  >
                    {t("Sidebar.Item15")}
                  </Link>
                </li>
                <li className={installationActive ? "mm-active" : ""}>
                  <Link
                    to="/app-subscriptions"
                    onClick={() =>
                      history.location.pathname === "/app-subscriptions"
                        ? window.location.reload()
                        : history.push("/app-subscriptions")
                    }
                  >
                    {t("Sidebar.Item16")}
                  </Link>
                </li>
              </ul>
            </li> */}
            {
              <li
                className={
                  adminManagementActive || adminRoleManagementActive
                    ? "mm-active"
                    : ""
                }
                style={{
                  display: checkUserViewPermission(
                    rolePermisions,
                    "Admin Management"
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    adminManagementActive || adminRoleManagementActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <img src={icon_admin_management} />
                  <span>{t("Sidebar.Item17")}</span>
                </Link>
                <ul
                  className={
                    adminManagementActive || adminRoleManagementActive
                      ? "sub-menu mm-show"
                      : "sub-menu"
                  }
                >
                  {
                    <li
                      className={adminManagementActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Admin Management",
                          "Admins"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/admin-management"
                        onClick={() =>
                          history.location.pathname === "/admin-management"
                            ? window.location.reload()
                            : history.push("/admin-management")
                        }
                      >
                        {t("Sidebar.Item18")}
                      </Link>
                    </li>
                  }

                  {
                    <li
                      className={adminRoleManagementActive ? "mm-active" : ""}
                      style={{
                        display: checkUserViewPermission(
                          rolePermisions,
                          "Admin Management",
                          "Roles & Permissions"
                        )
                          ? "block"
                          : "none",
                      }}
                    >
                      <Link
                        to="/admin-permissions"
                        onClick={() =>
                          history.location.pathname === "/admin-permissions"
                            ? window.location.reload()
                            : history.push("/admin-permissions")
                        }
                      >
                        {t("Sidebar.Item19")}
                      </Link>
                    </li>
                  }
                </ul>
              </li>
            }
            {/* App review requests */}
            {
              <li
                className={appReviewActive ? "mm-active" : ""}
                style={{
                  display: checkUserViewPermission(
                    rolePermisions,
                    "App Review Requests"
                  )
                    ? "block"
                    : "none",
                }}
              >
                <Link
                  to="/#"
                  className={
                    appReviewActive
                      ? "has-arrow waves-effect mm-active"
                      : "has-arrow waves-effect"
                  }
                >
                  <i
                    class="fa fa-regular fa-star"
                    style={{
                      fontSize: "15px",
                      color: "#c5cfec",
                      fontWeight: 100,
                    }}
                  ></i>
                  <span>{t("Sidebar.Item33")}</span>
                </Link>

                <ul
                  className={appReviewActive ? "sub-menu mm-show" : "sub-menu"}
                >
                  <li
                    className={appReviewActive ? "mm-active" : ""}
                    style={{
                      display: checkUserViewPermission(
                        rolePermisions,
                        "App Review Requests",
                        "App reviews"
                      )
                        ? "block"
                        : "none",
                    }}
                  >
                    <Link
                      to="/app-review-requests"
                      onClick={() =>
                        history.location.pathname === "/app-review-requests"
                          ? window.location.reload()
                          : history.push("/app-review-requests")
                      }
                    >
                      {t("Sidebar.Item34")}
                    </Link>
                  </li>
                </ul>
              </li>
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(SidebarContent);
