import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  Container,
  Nav,
  Row,
  UncontrolledAlert,
  NavItem,
  NavLink,
  Card,
  FormGroup,
  Input,
  Form,
  InputGroup,
} from "reactstrap";
import classnames from "classnames";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Datatable from "../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../components/UI/ConfirmationAlert";
import Loader from "../../components/Common/Loader";

import {
  getUserData,
  deleteUser,
  blockUser,
  storeRequest,
  exportUserEmail,
} from "../../store/users/actions";

import defaultProfile from "../../assets/images/users/user.svg";
import premiumImage from "../../assets/icons/premium.svg";
import saveAs from "file-saver";
import verifedImage from "../../assets/icons/verified.svg";

import {
  userColumnData,
  userCoulumnForNonPremium,
} from "../../helpers/columns";
import { checkUserPermission, convertToUAETime } from "../../helpers/utils";
import {
  DATE_WITH_TIME_IN_SECONDS,
  FE_DATE_FORMAT,
} from "../../helpers/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ManualPPInlineForm } from "./TabSection/ManualPP/ManualPPInlineForm";
import { resetMessage } from "../../store/reportedUser/action";

const countries = [
  { name: "Afghanistan", code: "AF" },

  { name: "Aland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the Congo", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote d'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Swaziland", code: "SZ" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic of Persian Gulf", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Laos", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of Micronesia", code: "FM" },
  { name: "Moldova", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar (Burma)", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Korea, Democratic People's Republic of Korea", code: "KP" },
  { name: "Macedonia", code: "MK" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestine", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russia", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Saint Barthelemy", code: "BL" },
  { name: "Saint Helena, Ascension and Tristan Da Cunha", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Martin", code: "MF" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "Korea, Republic of South Korea", code: "KR" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Venezuela", code: "VE" },
  { name: "Vietnam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

console.log("countries length", countries?.length);

const UserManagement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const data = useSelector((state) => state.user);
  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;
  const isDeleteable = userPermissions?.delete || false;
  const [expiryFilterPermissions, setExpityFilterPermissions] = useState({});

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const [columnData, setColumnData] = useState([]);

  const [membership, setMembership] = useState("");

  const { t } = useTranslation();

  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["Active"],
    page_count: 50,
    keyword: "",
    membership_filter: ["Premium"],
    citizenship_filter: [""],
  };

  const [request, setRequest] = useState();

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Users"
        )
      );
      setExpityFilterPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Expiry filter"
        )
      );
    }
  }, [loginData]);

  useEffect(() => {
    console.log("request8**", request);
  })

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const membership = query.get("membership");
    setMembership(membership);
    location?.state?.from === "keepFilter"
      ? setRequest({ ...data?.request })
      : setRequest({ ...basicRequest, membership_filter: [membership] });
    membership?.toLocaleLowerCase() === "premium"
      ? setColumnData(userColumnData)
      : setColumnData(userCoulumnForNonPremium);
  }, [location]);

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error]);

  const scrollToAlertMessage = () => {
    if (data?.error) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {
    if (history?.location?.state?.userUpdated) {
      dispatch(getUserData(request));
      dispatch(storeRequest(request));
    }
  }, [history?.location?.state?.userUpdated]);

  useEffect(() => {
    if (location?.state?.from === "keepFilter") {
      const membership =
        request?.membership_filter && request.membership_filter[0];
      setMembership(membership);
      membership?.toLocaleLowerCase() === "premium"
        ? setColumnData(userColumnData)
        : setColumnData(userCoulumnForNonPremium);
    }
  }, [history?.location?.state?.from, request]);

  useEffect(() => {
    if (request && history?.location?.state?.from !== "keepFilter") {
      dispatch(getUserData(request));
      dispatch(storeRequest(request));
    } else {
      request &&
        (JSON.stringify(request) !== JSON.stringify(data?.request) ||
          !data?.data?.users) &&
        dispatch(getUserData(request));
      request &&
        (JSON.stringify(request) !== JSON.stringify(data?.request) ||
          !data?.data?.users) &&
        dispatch(storeRequest(request));
    }
  }, [request]);

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: t("UserManagement.deleteConfirm"),
      type: "delete",
    });
  };

  const blockPromptHandler = (id, status) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to ${status === "Blocked" ? "unblock" : "block"
        } this user?`,
      type: status === "Blocked" ? "unblock" : "block",
      status: status,
    });
  };

  const okHandler = () => {
    promptMessage.type === "delete"
      ? dispatch(
        deleteUser({
          data: promptMessage.id,
          callback: () => dispatch(getUserData(request)),
        })
      )
      : promptMessage.type === "block"
        ? dispatch(
          blockUser({
            data: { blocked: true },
            id: promptMessage.id,
            callback: () => dispatch(getUserData(request)),
          })
        )
        : dispatch(
          blockUser({
            data: { blocked: false },
            id: promptMessage.id,
            callback: () => dispatch(getUserData(request)),
          })
        );
  };

  const formatUserData = useCallback(() => {
    return data?.data?.users?.map((user, index) => ({
      ...user,
      no: (request?.page - 1) * request?.page_count + index + 1,
      profile_image: (
        <div className="profileWrap" data-tag="allowRowEvents">
          {user?.verified ? (
            <div className="premium_icon" data-tag="allowRowEvents">
              <img src={verifedImage} data-tag="allowRowEvents" />
            </div>
          ) : user.membership === "Premium" ? (
            <div className="premium_icon" data-tag="allowRowEvents">
              <img src={premiumImage} data-tag="allowRowEvents" />
            </div>
          ) : null}
          <div data-tag="allowRowEvents">
            <img
              data-tag="allowRowEvents"
              className="table_profileImg"
              src={
                user.profile_image === null
                  ? defaultProfile
                  : user.profile_image
              }
            />
          </div>
        </div>
      ),
      name: (
        <>
          <div className="">
            <div className="" title={user.name}>
              <div data-tag="allowRowEvents">{user.name}</div>
              {user.username && (
                <div className="row">
                  <div
                    className="text-muted col-md-12 col-sm-12 col-lg-12 "
                    data-tag="allowRowEvents"
                  >
                    {`(${user.username})`}
                  </div>
                  <div className="col-md-12 col-sm-6 col-lg-12 mt-1 ">
                    <div className="badge bg-soft-success font-size-6 my-auto ">
                      {user.citizenship}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ),
      manual_pp: (
        <div>
          <ManualPPInlineForm userId={user.id} />
        </div>
      ),
      phone: (
        <div data-tag="allowRowEvents" title={user.email}>
          {user.email || user.phone}
        </div>
      ),
      created_on: (
        <div data-tag="allowRowEvents" title={user.created_on}>
          {user.created_on &&
            convertToUAETime(user.created_on, DATE_WITH_TIME_IN_SECONDS)}
        </div>
      ),
      payment_date: (
        <div data-tag="allowRowEvents" title={user.payment_date}>
          {user.payment_date &&
            convertToUAETime(user.payment_date, DATE_WITH_TIME_IN_SECONDS)}
        </div>
      ),
      subsctiption_expiry_date: (
        <div data-tag="allowRowEvents" title={user.subsctiption_expiry_date}>
          {user.subsctiption_expiry_date &&
            convertToUAETime(
              user.subsctiption_expiry_date,
              DATE_WITH_TIME_IN_SECONDS
            )}
        </div>
      ),
      rating: (
        <div data-tag="allowRowEvents" title={user.flax_rate}>
          {user.flax_rate_percentage} %
        </div>
      ),
    }));
  }, [data?.data?.users, request, isDeleteable, isEditable]);

  //memoized user data
  const userData = useMemo(() => formatUserData(), [formatUserData]);

  const tableRowClickHandler = (param) => {
    history.push(`/user-details/${param.id}`);
  };

  const addNewClickHandler = () => {
    dispatch(exportUserEmail({ saveAs: saveAs }));
  };

  const isPremium = (membership) => {
    return membership?.toLocaleLowerCase() === "premium";
  };

  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Users" />
          {data?.error && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            </div>
          )}
          <Card className="col-12">
            <Nav className="nav-tabs-custom nav-justified">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: !request?.membership_filter?.includes("Premium"),
                  })}
                  onClick={() => {
                    history.push({ pathname: "/users" });
                  }}
                >
                  <i className="fa fa-users " aria-hidden="true"></i>
                  <span className="d-none d-sm-block">All Users</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: request?.membership_filter?.includes("Premium"),
                  })}
                  onClick={() => {
                    history.push({
                      pathname: "/users",
                      search: `membership=Premium`,
                    });
                  }}
                >
                  <i className="fa fa-users " aria-hidden="true"></i>
                  <span className="d-none d-sm-block">Premium</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Card>
          <Datatable
            dateFilter={isPremium(membership)}
            isPremium={isPremium(membership)}
            defaultSortField={request?.sort || "created_on"}
            defaultSortAsc={request?.sort_order === "asc" ? true : false}
            dateType="Subscription Date Range"
            tableID={"userTable"}
            add
            addNewClickHandler={addNewClickHandler}
            addNewLabel={t("UserManagement.export")}
            columns={columnData}
            rows={userData}
            setRequest={setRequest}
            request={request}
            totalRecords={data?.data?.total}
            search
            filter
            tableRowClicked={(param) => tableRowClickHandler(param)}
            searchTerm={data?.request?.keyword}
            statusFilter={data?.request?.status_filter}
            membershipFilter={data?.request?.membership_filter}
            citizenShipFilter={data?.request?.citizenship_filter}
            loading={data?.loading}
            tableCardClassName={"snoTable"}
            userCountryFilter={countries}
            countries
            expiryFilterPermissions={expiryFilterPermissions}
          />
        </Row>
      </Container>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default UserManagement;
