import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { checkUserPermission, isLottieAnimation } from "../../../helpers/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  deleteGiftPackages,
  getGiftPackages,
  updateGiftPackages,
  resetGiftPackageErrors,
} from "../../../store/GiftPackages/actions";
import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import OpenEye from "../../../assets/icons/ebud-icons/openEye.svg";
import closeEye from "../../../assets/icons/ebud-icons/closeEye.svg";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";
import Lottie from "lottie-react";
import { giftPackageColumnData } from "../../../helpers/columns";

const GiftPackages = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const warningAlertRef = useRef(null);
  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["Active"],
    page_count: 50,
    keyword: "",
  };

  const loginData = useSelector((state) => state.Login);

  const data = useSelector((state) => state.giftPackages);

  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;
  const isDeleteable = userPermissions?.delete || false;
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const history = useHistory();
  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Huddle Management",
          "Huddle categories"
        )
      );
    }
  }, [loginData]);

  const [request, setRequest] = useState({ ...basicRequest });

  useEffect(() => {
    dispatch(getGiftPackages(request));
  }, [request]);
  useEffect(() => {
    return () => {
      dispatch(resetGiftPackageErrors());
    };
  }, []);

  const formatGiftPackagesData = useCallback(() => {
    return (
      data?.loading === false &&
      data?.giftPackages?.gift_points &&
      data?.giftPackages?.gift_points?.length &&
      data?.giftPackages?.gift_points?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        gift_name: (
          <div data-tag="allowRowEvents" title={result?.gift_name}>
            {result?.gift_name}
          </div>
        ),
        gift: (
          <div data-tag="allowRowEvents" title="gift">
            {/* {result?.gift_json && isLottieAnimation(result?.gift_json) && (
              <Lottie
                style={{
                  width: "30px",
                  height: "40px",
                }}
                animationData={result?.gift_json && result?.gift_json}
              />
            )} */}
            <img width={28} src={result?.gift_static_url} alt="gift" />
            {}
          </div>
        ),
        gift_type: (
          <div data-tag="allowRowEvents" title={result?.gift_type}>
            {result?.gift_type}
          </div>
        ),
        // gift_desc: (
        //   <div data-tag="allowRowEvents" title={result?.gift_description}>
        //     {result?.gift_description}
        //   </div>
        // ),
        points: (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              minWidth: "100px",
            }}
            data-tag="allowRowEvents"
            title={result?.points}
          >
            {result?.coins ? `${result?.coins} COiNS` : (result?.flix) && `${result?.flix} FLiX`}
          </div>
        ),

        actions: (
          <div className="cust-table-actions-wrap">
            <button
              className=" color-violet action-btn"
              title="Edit"
              onClick={() =>
                history.push({
                  pathname: `/gift-packages-edit/${result?.id}`,
                  state: { id: result.id },
                })
              }
            // disabled={!isEditable}
            >
              <img
                src={Edit_icon}
                alt="Edit"
              // style={{
              //   opacity: !isEditable ? "50%" : "",
              //   cursor: !isEditable ? "not-allowed" : "",
              // }}
              />
            </button>
            <button
              onClick={() => deletePromptHandler(result.id)}
              className=" color-red action-btn"
              title="Delete"
            // disabled={!isDeleteable}
            >
              <img
                src={Delete_icon}
                alt="Delete"
              // style={{
              //   opacity: !isDeleteable ? "50%" : "",
              //   cursor: !isDeleteable ? "not-allowed" : "",
              // }}
              />
            </button>
          </div>
        ),
        visibility: result?.status ? (
          <div className="cust-table-actions-wrap">
            <button
              className=" color-violet action-btn"
              title="visible"
              onClick={() => {
                const formData = new FormData();
                formData.append(
                  "data",
                  JSON.stringify({
                    points: result?.points,
                    gift_identifier: result?.gift_identifier,
                    gift_json: null,
                    gift_data: [],
                    gift_type: result?.gift_type,
                    status: false,
                  })
                );
                dispatch(
                  updateGiftPackages({
                    giftIdentifier: result?.gift_identifier,
                    id: result?.id,

                    packageData: formData,
                    callback: () => {
                      dispatch(getGiftPackages());
                    },
                  })
                );
              }}
            // disabled={!isEditable}
            >
              <img
                src={OpenEye}
                alt="Edit"
              // style={{
              //   opacity: !isEditable ? "50%" : "",
              //   cursor: !isEditable ? "not-allowed" : "",
              // }}
              />
            </button>
          </div>
        ) : (
          <div className="cust-table-actions-wrap">
            <button
              className=" color-violet action-btn"
              title="hidden"
              onClick={() => {
                const formData = new FormData();
                formData.append(
                  "data",
                  JSON.stringify({
                    points: result?.points,
                    gift_identifier: result?.giftIdentifier,
                    gift_json: null,
                    gift_data: [],
                    gift_type: result?.giftType,
                    status: true,
                  })
                );
                dispatch(
                  updateGiftPackages({
                    giftIdentifier: result?.giftIdentifier,
                    id: result?.id,

                    packageData: formData,
                    callback: () => {
                      dispatch(getGiftPackages());
                    },
                  })
                );
              }}
            // disabled={!isEditable}
            >
              <img
                src={closeEye}
                alt="Edit"
              // style={{
              //   opacity: !isEditable ? "50%" : "",
              //   cursor: !isEditable ? "not-allowed" : "",
              // }}
              />
            </button>
          </div>
        ),
      }))
    );
  }, [request, data?.giftPackages?.gift_points]);
  const giftPackagesData = useMemo(
    () => formatGiftPackagesData(),
    [formatGiftPackagesData]
  );
  const addNewClickHandler = () => {
    history.push("/gift-packages-addNew");
  };

  const okHandler = () => {
    dispatch(
      deleteGiftPackages({
        id: promptMessage.id,
        callback: () => dispatch(getGiftPackages(request)),
      })
    );
  };

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to delete 
      this Package ?`,
      type: "delete",
    });
  };

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
      // ref={pageWrapRef}
      >
        <Container fluid>
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Gift Packages" />
            {data?.error && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color="danger"
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error}
                </UncontrolledAlert>
              </div>
            )}
            <div className="container-fluid">
              <Datatable
                tableID={"adminTable"}
                columns={giftPackageColumnData}
                rows={giftPackagesData}
                setRequest={setRequest}
                request={request}
                add
                addNewClickHandler={addNewClickHandler}
                addNewLabel={t("Admins.addAdmin.addBtn")}
                searchTerm={data?.request?.keyword}
                loading={data?.loading}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
        </Container>
        <ConfirmationAlert
          {...promptMessage}
          modal_center={showPromptPopUp}
          setmodal_center={setShowPromptPopUp}
          onOK={okHandler}
        />
        {(data?.loading || data.deleteLoading) && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default GiftPackages;
