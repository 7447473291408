export const PHONE_NUMBER_WITH_COUNTRY_REGEX = "^[0-9]+-([0-9]+)+$";
export const EMAIL_REGEX = /^[^@]+@\w+(\.\w+)+\w$/;
export const DATE_REGEX =
  /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/gm;

export const FE_DATE_FORMAT = "DD-MM-YYYY";
export const DATE_WITH_TIME = "DD-MM-YYYY HH:mm";
export const DATE_WITH_TIME_IN_SECONDS = "DD-MM-YYYY HH:mm:ss";

export const PRIVATE_TYPE = "private";
export const PUBLIC_TYPE = "public";

export const ROUTES = [
  { path: "/dashboard", component: "Dashboard" },
  { path: "/users", component: "Users" },
  { path: "/allocate-usernames", component: "Allocate Username" },
  { path: "/reserved-usernames", component: "Reserve Username" },
  { path: "/legal-documents", component: "Legal documents" },
  { path: "/settings-template", component: "Template" },
  { path: "/settings-app", component: "App settings" },
  { path: "/reported-data", component: "Reported users" },
  { path: "/app-installations", component: "App installations" },
  { path: "/admin-management", component: "Admins" },
  { path: "/admin-permissions", component: "Roles & Permissions" },
  { path: "/huddles", component: "Huddles" },
  { path: "/huddle-categories", component: "Admins" },
  { path: "/huddle-categories", component: "Admins" },
];

export const LANGUAGES = [
  "english",
  "hindi",
  "tagalog",
  "arabic",
  "french",
  "russian",
  "spanish",
  "kurdish",
  "persian",
];
