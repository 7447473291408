import React , {useEffect} from "react"
import { Link } from "react-router-dom"
import { CardBody, Container, Row, Col ,Card} from "reactstrap"

//Import Images
// import logo from "../../assets/images/logo-dark.png"
// import logolight from "../../assets/images/logo-light.png"
import maintenance from "../../assets/images/maintenance.png"

const PagesMaintenance = () => {

  useEffect(() => {
    document.body.className = "authentication-bg cust-auth-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark"><i className="mdi mdi-home-variant h2"></i></Link>
      </div>
      <div className="my-5 pt-sm-5">
        <Container>
          <Row>
            <div className="col-12 text-center">
              <div className="home-wrapper">
                <Link to="/" className="mb-5 d-block auth-logo">
                  LOGO
                </Link>
                <Row className="justify-content-center">
                  <Col lg={4} sm={5}>
                    <div className="maintenance-img">
                      <img src={maintenance} alt="" className="img-fluid mx-auto d-block" />
                    </div>
                  </Col>
                </Row>
                <h3 className="mt-5">Page is Under Maintenance</h3>
                <p>Please check back in sometime.</p>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PagesMaintenance
