import React from "react";
import LevelUsers from "./levelUsers";
import { useDispatch } from "react-redux";
import TreePagination from "./pagination";

const TreeLevel = ({
  name,
  total_pp,
  active_pp,
  inactive_pp,
  users,
  visibility,
  id,
  levelClickHandler,
  total_children_count,
  levelName,
  paginationAction,
  page,
  free_count,
  premium_count,
  subscription_count,
  level,
  levelNo,
}) => {
  return (
    <div>
      <div className="levelAccordian">
        <div className="levelAccordian_header">
          <button
            // disabled={!free_count + premium_count > 0}
            onClick={() => levelClickHandler(levelNo, visibility)}
          >
            {name}
            <span>
              <i
                className={`fa fa-angle-${visibility ? "down" : "right"}`}
                aria-hidden="true"
              ></i>
            </span>
          </button>
          <div className="levelInformation">
            {/* <div className="levelPoints">
              <div className="level_header text-muted">Total PP</div>
              <div className="level_point">{total_pp}</div>
            </div> */}
            <div className="levelPoints">
              <div className="level_header text-muted">FLiX</div>
              <div className="level_point">{active_pp}</div>
            </div>
            {/* <div className="levelPoints">
              <div className="level_header text-muted">Inactive PP</div>
              <div className="level_point">{inactive_pp}</div>
            </div> */}
            <div className="levelPoints" style={{ width: "100px" }}>
              <div className="level_header text-muted">Subscriptions</div>
              <div className="level_point">{subscription_count}</div>
            </div>
            <div className="levelPoints">
              <div className="level_header text-muted">Dears</div>
              <div className="level_point">{premium_count}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="dottedWrapper">
        {visibility && (
          <div>
            <div className="levelAccordian_body">
              {level?.users?.map((levelUser) => {
                return <LevelUsers {...levelUser} />;
              })}
            </div>
            {level?.total_children_count >= 0 ? (
              <div className="tree_paginationWrapper">
                <TreePagination
                  totalRecords={level?.total_children_count}
                  level={levelName}
                  paginationAction={paginationAction}
                  page={page}
                />
              </div>
            ) : (
              <div className="bg-soft-orange p-2 level-no-user  col-lg-5">
                No Users
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TreeLevel;
