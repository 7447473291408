import { success } from "toastr";
import {
  ADD_FLASH_CATEGORIES_BEGIN,
  ADD_FLASH_CATEGORIES_FAIL,
  ADD_FLASH_CATEGORIES_SUCCESS,
  DELETE_FLASHES_BEGIN,
  DELETE_FLASHES_FAIL,
  DELETE_FLASHES_SUCCESS,
  DELETE_FLASH_CATEGORIES_BEGIN,
  DELETE_FLASH_CATEGORIES_FAIL,
  DELETE_FLASH_CATEGORIES_SUCCESS,
  DELETE_FLASH_REPORTED_COMMENT_BEGIN,
  DELETE_FLASH_REPORTED_COMMENT_FAIL,
  DELETE_FLASH_REPORTED_COMMENT_SUCCESS,
  EDIT_FLASH_CATEGORIES_BEGIN,
  EDIT_FLASH_CATEGORIES_FAIL,
  EDIT_FLASH_CATEGORIES_SUCCESS,
  GET_ALL_FLASH_BEGIN,
  GET_ALL_FLASH_FAIL,
  GET_ALL_FLASH_SUCCESS,
  GET_FLASHES_BEGIN,
  GET_FLASHES_FAIL,
  GET_FLASHES_SUCCESS,
  GET_FLASH_BLOCKED_USERS_BEGIN,
  GET_FLASH_BLOCKED_USERS_FAIL,
  GET_FLASH_BLOCKED_USERS_SUCCESS,
  GET_FLASH_CATEGORIES_BEGIN,
  GET_FLASH_CATEGORIES_BY_ID_BEGIN,
  GET_FLASH_CATEGORIES_BY_ID_FAIL,
  GET_FLASH_CATEGORIES_BY_ID_SUCCESS,
  GET_FLASH_CATEGORIES_FAIL,
  GET_FLASH_CATEGORIES_SUCCESS,
  GET_FLASH_COMMENT_REPORTS_BEGIN,
  GET_FLASH_COMMENT_REPORTS_FAIL,
  GET_FLASH_COMMENT_REPORTS_SUCCESS,
  GET_FLASH_REPORTED_COMMENT_BEGIN,
  GET_FLASH_REPORTED_COMMENT_FAIL,
  GET_FLASH_REPORTED_COMMENT_SUCCESS,
  GET_FLASH_REPORTS_BEGIN,
  GET_FLASH_REPORTS_FAIL,
  GET_FLASH_REPORTS_SUCCESS,
  GET_REPORTED_FLASH_BEGIN,
  GET_REPORTED_FLASH_FAIL,
  GET_REPORTED_FLASH_SUCCESS,
  RESET_FLASH_CATEGORY_ERRORS,
} from "./actionTypes";

export const INIT_STATE = {
  categories: {},
  categoryData: {},
  allFlash: {},
  error: "",
  loading: false,
  request: {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    page_count: 50,
    keyword: "",
    success: "",
  },
};

const Flashs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FLASH_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
        categories: {},
        huddleMessages: "",
      };
    case GET_FLASH_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
        error: "",
      };
    case GET_FLASH_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        categories: {},
        error: action.payload,
      };

    case GET_FLASH_CATEGORIES_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        categoryData: {},
        huddleMessages: "",
      };
    case GET_FLASH_CATEGORIES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryData: action.payload,
        error: "",
      };
    case GET_FLASH_CATEGORIES_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        categoryData: {},
        error: action.payload,
      };
    case EDIT_FLASH_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_FLASH_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case EDIT_FLASH_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_FLASH_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_FLASH_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case ADD_FLASH_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_FLASH_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FLASH_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case DELETE_FLASH_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_FLASH_CATEGORY_ERRORS:
      return {
        ...state,
        error: "",
        success: "",
      };

    //user specific flashes
    case GET_FLASHES_BEGIN:
      return {
        ...state,
        loading: true,
        flashes: {},
      };
    case GET_FLASHES_SUCCESS:
      return {
        ...state,
        loading: false,
        flashes: action.payload,
        error: "",
      };
    case GET_FLASHES_FAIL:
      return {
        ...state,
        loading: false,
        flashes: {},
        error: action.payload,
      };
    case DELETE_FLASHES_BEGIN:
      return {
        ...state,
        deleteLoading: true,
      };
    case DELETE_FLASHES_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        error: "Flash deleted",
      };
    case DELETE_FLASHES_FAIL:
      return {
        ...state,
        deleteLoading: false,
        error: action.payload,
      };
    // for whole flashes listing in the app
    case GET_ALL_FLASH_BEGIN:
      return {
        ...state,
        loading: true,
        allFlash: {},
        huddleMessages: "",
      };
    case GET_ALL_FLASH_SUCCESS:
      return {
        ...state,
        loading: false,
        allFlash: action.payload,
        error: "",
      };
    case GET_ALL_FLASH_FAIL:
      return {
        ...state,
        loading: false,
        allFlash: {},
        error: action.payload,
      };
    case GET_REPORTED_FLASH_BEGIN:
      return {
        ...state,
        loading: true,
        reportedFlash: {},
        huddleMessages: "",
      };
    case GET_REPORTED_FLASH_SUCCESS:
      return {
        ...state,
        loading: false,
        reportedFlash: action.payload,
        error: "",
      };
    case GET_REPORTED_FLASH_FAIL:
      return {
        ...state,
        loading: false,
        reportedFlash: {},
        error: action.payload,
      };
    case GET_FLASH_REPORTS_BEGIN:
      return {
        ...state,
        loading: true,
        flashReports: {},
        huddleMessages: "",
      };
    case GET_FLASH_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        flashReports: action.payload,
        error: "",
      };
    case GET_FLASH_REPORTS_FAIL:
      return {
        ...state,
        loading: false,
        flashReports: {},
        error: action.payload,
      };
    case GET_FLASH_REPORTED_COMMENT_BEGIN:
      return {
        ...state,
        loading: true,
        flashReportedComment: {},
        huddleMessages: "",
      };
    case GET_FLASH_REPORTED_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        flashReportedComment: action.payload,
        error: "",
      };
    case GET_FLASH_REPORTED_COMMENT_FAIL:
      return {
        ...state,
        loading: false,
        flashReportedComment: {},
        error: action.payload,
      };
    case DELETE_FLASH_REPORTED_COMMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FLASH_REPORTED_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: "",
      };
    case DELETE_FLASH_REPORTED_COMMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // FLASH_COMMENT_REPORTS Reducer Cases
    case GET_FLASH_COMMENT_REPORTS_BEGIN:
      return {
        ...state,
        loading: true,
        flashCommentReports: {},
        huddleMessages: "",
      };
    case GET_FLASH_COMMENT_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        flashCommentReports: action.payload,
        error: "",
      };
    case GET_FLASH_COMMENT_REPORTS_FAIL:
      return {
        ...state,
        loading: false,
        flashCommentReports: {},
        error: action.payload,
      };
    case GET_FLASH_BLOCKED_USERS_BEGIN:
      return {
        ...state,
        loading: true,
        flashBlockedUsers: {},
      };
    case GET_FLASH_BLOCKED_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        flashBlockedUsers: action.payload,
        error: "",
      };
    case GET_FLASH_BLOCKED_USERS_FAIL:
      return {
        ...state,
        loading: false,
        flashBlockedUsers: {},
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Flashs;
