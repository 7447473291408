import {
  GET_RES_USER_DATA,
  GET_RES_USER_DATA_BEGIN,
  GET_RES_USER_DATA_SUCCESS,
  GET_RES_USER_DATA_FAIL,
  ADD_RES_USERNAME,
  ADD_RES_USERNAME_BEGIN,
  ADD_RES_USERNAME_SUCCESS,
  ADD_RES_USERNAME_FAIL,
  DELETE_RES_USER,
  DELETE_RES_USER_BEGIN,
  DELETE_RES_USER_SUCCESS,
  DELETE_RES_USER_FAIL,
  GET_RES_USER_DETAILS,
  GET_RES_USER_DETAILS_BEGIN,
  GET_RES_USER_DETAILS_SUCCESS,
  GET_RES_USER_DETAILS_FAIL,
  UPDATE_RES_USER_DETAILS,
  UPDATE_RES_USER_DETAILS_BEGIN,
  UPDATE_RES_USER_DETAILS_SUCCESS,
  UPDATE_RES_USER_DETAILS_FAIL,
  STORE_USER_REQUEST,
  IMPORT_RESERVED_USERNAMES,
  IMPORT_RESERVED_USERNAMES_BEGIN,
  IMPORT_RESERVED_USERNAMES_SUCCESS,
  IMPORT_RESERVED_USERNAMES_FAIL,
  RESET_MESSAGE
} from "./actionTypes";

export const getReservedNames = (request) => ({
  type: GET_RES_USER_DATA,
  payload: request,
});

export const getReservedUserDataBegin = () => ({
  type: GET_RES_USER_DATA_BEGIN,
});

export const getReservedDataSuccess = (users) => ({
  type: GET_RES_USER_DATA_SUCCESS,
  payload: users,
});

export const storeRequest = (request) => ({
  type: STORE_USER_REQUEST,
  payload: request,
});

export const getReservedDataFail = (error) => ({
  type: GET_RES_USER_DATA_FAIL,
  payload: error,
});

export const addReservedName = ({ data, callback }) => ({
  type: ADD_RES_USERNAME,
  data: data,
  callback: callback,
});

export const addReservedNameBegin = () => ({
  type: ADD_RES_USERNAME_BEGIN,
});

export const addReservedNameSuccess = (username) => ({
  type: ADD_RES_USERNAME_SUCCESS,
  payload: username,
});

export const addReservedNameFail = (error) => ({
  type: ADD_RES_USERNAME_FAIL,
  payload: error,
});

export const deleteReserveName = ({ data, callback }) => ({
  type: DELETE_RES_USER,
  data: data,
  callback: callback,
});

export const deleteResUserBegin = () => ({
  type: DELETE_RES_USER_BEGIN,
});

export const deleteResUserSuccess = (users) => ({
  type: DELETE_RES_USER_SUCCESS,
  payload: users,
});

export const deleteResUserFail = (error) => ({
  type: DELETE_RES_USER_FAIL,
  payload: error,
});

export const getReservedUsernameDetails = (id) => ({
  type: GET_RES_USER_DETAILS,
  payload: id,
});

export const getReservedUsernameDetailsBegin = () => ({
  type: GET_RES_USER_DETAILS_BEGIN,
});

export const getReservedUsernameDetailsSuccess = (username) => ({
  type: GET_RES_USER_DETAILS_SUCCESS,
  payload: username,
});

export const getReservedUsernameDetailsFail = (error) => ({
  type: GET_RES_USER_DETAILS_FAIL,
  payload: error,
});

export const updateReservedUsername = ({ data, callback }) => ({
  type: UPDATE_RES_USER_DETAILS,
  id: data?.id,
  params: { username: data?.username },
  callback: callback,
});

export const updateReservedNameBegin = () => ({
  type: UPDATE_RES_USER_DETAILS_BEGIN,
});

export const updateReservedNameSuccess = (username) => ({
  type: UPDATE_RES_USER_DETAILS_SUCCESS,
  payload: username,
});

export const updateReservedNameFail = (error) => ({
  type: UPDATE_RES_USER_DETAILS_FAIL,
  payload: error,
});

export const importReservedUsernames = ({ data }) => ({
  type: IMPORT_RESERVED_USERNAMES,
  payload: data,
});

export const importReservedUsernamesBegin = () => ({
  type: IMPORT_RESERVED_USERNAMES_BEGIN,
});

export const importReservedUsernamesSuccess = (response) => ({
  type: IMPORT_RESERVED_USERNAMES_SUCCESS,
  payload: response,
});

export const importReservedUsernamesFail = (error) => ({
  type: IMPORT_RESERVED_USERNAMES_FAIL,
  payload: error,
});

export const resetMessage = () => ({
  type: RESET_MESSAGE
})
