import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";

export const ImagePreview = ({
  isOpen,
  setOpen,
  popupButtonHandler,
  selectedRow,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      backdrop={true}
      toggle={() => setOpen(false)}
    >
      <ModalHeader toggle={() => setOpen((state) => !state)}>
        Screenshot
      </ModalHeader>
      <ModalBody>
        <img src={selectedRow?.screenshot_url} width={470} />
      </ModalBody>
      <ModalFooter>
        {selectedRow?.status === "Rejected" && (
          <Button
            color="primary"
            onClick={() => popupButtonHandler("Accepted")}
          >
            Approve
          </Button>
        )}
        {selectedRow?.status === "Accepted" && (
          <Button
            color="secondary"
            onClick={() => popupButtonHandler("Rejected")}
          >
            Reject
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
