import React from "react";

const BackButton = ({ label, handleClick }) => {
  return (
    <div className="backButtonWrapper"  data-testid="backbutton-wrap">
      <button
        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
        onClick={() => handleClick()}
        data-testid="backbutton"
      >
        <span>
          <i className="fa fa-angle-double-left" aria-hidden="true"></i>
        </span>
        {label}
      </button>
    </div>
  );
};

export default BackButton;
