import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";

import {
  getAdminsData,
  deleteAdminData,
  storeRequest,
  blockAdmin,
  resetMessage,
} from "../../../../src/store/adminManagement/admins/action";

import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import defaultProfile from "../../../assets/images/users/user.svg";
import premiumImage from "../../../assets/icons/premium.svg";
import Block_icon from "../../../assets/icons/ebud-icons/Block.svg";
import UnBlock_icon from "../../../assets/icons/ebud-icons/Unblock.svg";

import { adminsColumnData } from "../../../helpers/columns";
import { checkUserPermission } from "../../../helpers/utils";

const AdminManagement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.admins);

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();

  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isDeleteable = userPermissions?.delete || false;
  const isEditable = userPermissions?.edit || false;

  const basicRequest = {
    page: 1,
    status_filter: ["Active"],
    page_count: 50,
    keyword: "",
  };

  const [request, setRequest] = useState();

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error]);

  const scrollToAlertMessage = () => {
    if (data?.error) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(loginData?.rolePermissions?.role, "Admin Management", "Admins")
      );
    }
  }, [loginData]);

  useEffect(() => {
    history?.location?.state?.from === "keepFilter"
      ? setRequest({ ...data?.request })
      : setRequest({ ...basicRequest });
  }, [history?.location?.state?.from]);

  useEffect(() => {
    request && dispatch(getAdminsData(request));
    request && dispatch(storeRequest(request));
    if (!history?.location?.state?.from === "keepFilter" || (
      JSON.stringify(request) && JSON.stringify(data?.request) &&
      JSON.stringify(request) !== JSON.stringify(data?.request)))
      dispatch(resetMessage(request));
  }, [request]);

  useEffect(() => {
    return () => {
      dispatch(resetMessage());
    }
  }, []);

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: t("Admins.deleteConfirm"),
      type: "delete",
    });
  };

  const blockPromptHandler = (id, status) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to ${status === "Blocked" ? "unblock" : "block"
        } this user?`,
      type: "block",
      status: status,
    });
  };

  const okHandler = () => {
    promptMessage.type === "delete"
      ? dispatch(
        deleteAdminData({
          data: promptMessage.id,
          callback: () => dispatch(getAdminsData(request)),
        })
      )
      : promptMessage.status === "Blocked"
        ? dispatch(
          blockAdmin({
            data: { id: promptMessage.id, value: { Blocked: false } },
            callback: () => dispatch(getAdminsData(request)),
          })
        )
        : dispatch(
          blockAdmin({
            data: { id: promptMessage.id, value: { Blocked: true } },
            callback: () => dispatch(getAdminsData(request)),
          })
        );
  };

  const addNewClickHandler = () => {
    history.push({ pathname: "/admin-addNew", state: { from: "admins" } });
  };

  const tableRowClickHandler = (param) => {
    history.push({
      pathname: `/admin-details/${param.id}`,
      state: { from: "admins" },
    });
  };

  const formatAdminData = useCallback(() => {
    return data?.adminData?.users?.map((user, index) => ({
      ...user,
      no: (request?.page - 1) * request?.page_count + index + 1,
      profile_image: (
        <div className="profileWrap" data-tag="allowRowEvents">
          {user.membership === "Premium" ? (
            <div className="premium_icon" data-tag="allowRowEvents">
              <img
                src={premiumImage}
                data-tag="allowRowEvents"
                alt="premium_icon"
              />
            </div>
          ) : null}
          <div data-tag="allowRowEvents">
            <img
              data-tag="allowRowEvents"
              className="table_profileImg"
              src={
                user.profile_photo === null
                  ? defaultProfile
                  : user.profile_photo
              }
              alt="profile_image"
            />
          </div>
        </div>
      ),
      name: (
        <div title={user.name}>
          <div data-tag="allowRowEvents">{user.name}</div>
          {user.username && (
            <div
              className="text-muted"
              data-tag="allowRowEvents"
            >{`(${user.username})`}</div>
          )}
        </div>
      ),
      role: user.role,
      email: (
        <div data-tag="allowRowEvents" title={user.email}>
          {user.email}
        </div>
      ),
      status: (
        <div
          className={`badge ${user.status === "Deleted"
            ? "bg-soft-orange"
            : user.status === "Active"
              ? "bg-soft-green"
              : user.status === "Blocked"
                ? "bg-soft-red"
                : ""
            } font-size-13`}
          data-tag="allowRowEvents"
        >
          {user.status}
        </div>
      ),
      actions: (
        <div className="cust-table-actions-wrap">
          <button
            className=" color-violet action-btn"
            title="Edit"
            onClick={() =>
              history.push({
                pathname: `/edit-admin-details/${user.id}`,
                state: { from: "admins", isEdit: true },
              })
            }
            disabled={!isEditable}
          >
            <img src={Edit_icon} alt="Edit" style={{ opacity: !isEditable ? "50%" : "", cursor: !isEditable ? "not-allowed" : "" }} />
          </button>
          <button
            onClick={() => blockPromptHandler(user.id, user.status)}
            className=" color-orange action-btn"
            title={user.status === "Blocked" ? "Unblock" : "Block"}
            disabled={!isEditable}
          >
            <img
              src={user.status === "Blocked" ? UnBlock_icon : Block_icon}
              alt={user.status === "Blocked" ? "Unblock" : "Block"}
              style={{ opacity: !isEditable ? "50%" : "", cursor: !isEditable ? "not-allowed" : "" }}
            />
          </button>
          <button
            onClick={() => deletePromptHandler(user.id)}
            className=" color-red action-btn"
            title="Delete"
            disabled={user.status === "Deleted" || !isDeleteable}
          >
            <img src={Delete_icon} alt="Delete" style={{ opacity: user.status === "Deleted" || !isDeleteable ? "50%" : "",
             cursor: user.status === "Deleted" || !isDeleteable ? "not-allowed" : "" }} />
          </button>
        </div>
      ),
    }));
  }, [data?.adminData?.users, request, isEditable, isDeleteable]);

  //memoized admin data
  const adminRowData = useMemo(() => formatAdminData(), [formatAdminData]);

  return (
    <div
      className="page-content"
      data-testid="component-admins"
      id="adminsList"
      ref={pageWrapRef}
    >
      <Container fluid id="userManagementWrapper" data-testid="adminWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Admins" />
          {(data?.error || data?.success) && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color={data?.error ? "danger" : "success"}
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error || data?.success}
              </UncontrolledAlert>
            </div>
          )}
          <Datatable
            tableID={"adminTable"}
            columns={adminsColumnData}
            rows={adminRowData}
            setRequest={setRequest}
            request={request}
            totalRecords={data.adminData?.total}
            search
            adminManagementFilter
            add
            addNewClickHandler={addNewClickHandler}
            tableRowClicked={(param) => tableRowClickHandler(param)}
            addNewLabel={t("Admins.addAdmin.addBtn")}
            searchTerm={data?.request?.keyword}
            adminStatusFilter={data?.request?.status_filter}
            adminRoleFilter={data.adminData?.roles}
            loading={data?.loading}
            tableCardClassName={"snoTable"}
          />
        </Row>
      </Container>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default AdminManagement;
