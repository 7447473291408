import {
  UPDATE_FLIX_RATE,
  UPDATE_FLIX_RATE_BEGIN,
  UPDATE_FLIX_RATE_SUCCESS,
  UPDATE_FLIX_RATE_FAIL,
  GET_FLIX_RATE,
  GET_FLIX_RATE_BEGIN,
  GET_FLIX_RATE_SUCCESS,
  GET_FLIX_RATE_FAIL,
} from "./actionTypes";

export const updateFlixRate = ({ payload, callback }) => ({
  // id: id,
  payload: payload,
  callback: callback,
  type: UPDATE_FLIX_RATE,
});

export const updateFlixRateBegin = () => ({
  type: UPDATE_FLIX_RATE_BEGIN,
});

export const updateFlixRateSuccess = (packageData) => ({
  type: UPDATE_FLIX_RATE_SUCCESS,
  payload: packageData,
});

export const updateFlixRateFail = (error) => ({
  type: UPDATE_FLIX_RATE_FAIL,
  payload: error,
});

export const getFlixRate = (request) => ({
  type: GET_FLIX_RATE,
  payload: request,
});

export const getFlixRateBegin = () => ({
  type: GET_FLIX_RATE_BEGIN,
});

export const getFlixRateSuccess = (flix_rate_status) => ({
  type: GET_FLIX_RATE_SUCCESS,
  payload: flix_rate_status,
});

export const getFlixRateFail = (error) => ({
  type: GET_FLIX_RATE_FAIL,
  payload: error,
});
