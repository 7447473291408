import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  ADD_EMPOWERMENT_DETAILS,
  ADD_EMPOWERMENT_DETAILS_BEGIN,
  GET_EMPOWERMENT_DETAILS,
  UPDATE_EMPOWERMENT_DETAILS,
} from "./actionTypes";
import {
  getEmpowermentDetailsBegin,
  getEmpowermentDetailsSuccess,
  getEmpowermentDetailsFail,
  addEmpowermentDetailsBegin,
  addEmpowermentDetailsSuccess,
  addEmpowermentDetailsFail,
  updateEmpowermentDetailsBegin,
  updateEmpowermentDetailsSuccess,
  updateEmpowermentDetailsFail,
} from "./action";
import { get, post, update } from "../../../helpers/api_helper";
import { API_GET_USER_EMPOWERMENT } from "../../../helpers/url_helper";

function* fetchEmpowermentDetails({ id }) {
  try {
    yield put(getEmpowermentDetailsBegin());

    const response = yield call(
      get,
      API_GET_USER_EMPOWERMENT.replace("id", id)
    );

    console.log("2");
    yield put(getEmpowermentDetailsSuccess(response.result));
  } catch (error) {
    yield put(getEmpowermentDetailsFail(error));
  }
}
function* addEmpowermentDetailsSaga({ id, payload }) {
  try {
    yield put(addEmpowermentDetailsBegin());

    const response = yield call(
      post,
      API_GET_USER_EMPOWERMENT.replace("id", id),
      payload
    );

    yield put(addEmpowermentDetailsSuccess(response.message));
  } catch (error) {
    yield put(addEmpowermentDetailsFail(error));
  }
}

function* empowermentDetailsSaga() {
  yield takeLatest(GET_EMPOWERMENT_DETAILS, fetchEmpowermentDetails);
  yield takeLatest(ADD_EMPOWERMENT_DETAILS, addEmpowermentDetailsSaga);
}
export default empowermentDetailsSaga;
