import React from "react";
import { DatePicker } from "./DatePicker";

const DateFilterPopup = ({ setRequest, request, dateFilter, dateType }) => {
  const onDateSelection = (date) => {
    setRequest({
      ...request,
      from_date: date.from_date,
      to_date: date.to_date,
    });
  };
  return (
    <div className="user-filterBtn-wrap" data-testid="component-filterPopup">
      {dateFilter && (
        <DatePicker dateType={dateType} OnDateSelection={onDateSelection} />
      )}
    </div>
  );
};

export default DateFilterPopup;
