import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import Loader from "../../../components/Common/Loader";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Datatable from "../../../components/UI/Tables/Datatable";
import { flaxPurchaseColumnData } from "../../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../helpers/constants";
import { convertToUAETime } from "../../../helpers/utils";
import { getFlaxPurchaseHistory } from "../../../store/flaxPackages/actions";

const FlaxPurchaseHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const basicRequest = {
    // sort: "transfered_date",
    // sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
  };

  const [request, setRequest] = useState({ ...basicRequest });
  useEffect(() => {
    dispatch(getFlaxPurchaseHistory(request));
  }, [request]);
  const data = useSelector((state) => state.flaxPackages);

  const formatFlaxPurchaseHistory = useCallback(() => {
    return (
      data?.flaxPurchaseHistoryData?.purchases?.length &&
      data?.flaxPurchaseHistoryData?.purchases?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        username: (
          <div data-tag="allowRowEvents" title={result?.username}>
            {result?.username}
          </div>
        ),
        flax: (
          <div data-tag="allowRowEvents" title={result?.flax}>
            {result?.flax}
          </div>
        ),
        purchased_on: (
          <div data-tag="allowRowEvents" title={result?.time_created}>
            {convertToUAETime(result?.time_created, DATE_WITH_TIME_IN_SECONDS)}
          </div>
        ),
      }))
    );
  }, [request, data.flaxPurchaseHistoryData?.purchases]);
  const flaxPurchaseHistory = useMemo(
    () => formatFlaxPurchaseHistory(),
    [formatFlaxPurchaseHistory]
  );

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        // id="userMgmtList"
        // ref={pageWrapRef}
      >
        <Container>
          <Row>
            <Breadcrumbs
              title="Tables"
              breadcrumbItem="FLix Purchase History"
            />
            <div className="container-fluid">
              <Datatable
                tableID={"adminTable"}
                columns={flaxPurchaseColumnData}
                rows={flaxPurchaseHistory}
                setRequest={setRequest}
                request={request}
                dateFilter
                totalRecords={data?.flaxPurchaseHistoryData?.purchases?.length}
                search
                dateType="Purchased Date Range"
                // add
                // dropDown
                // languages={languages}
                // addNewClickHandler={addNewClickHandler}
                // selectedLanguage={selectedLanguage}
                // setSelectedLanguage={setSelectedLanguage}
                // addNewLabel={t("Admins.addAdmin.addBtn")}
                searchTerm={data?.request?.keyword}
                loading={data?.loading}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
        </Container>
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default FlaxPurchaseHistory;
