export const GET_GLOBAL_PAYMENT_SYSTEMS = "GET_GLOBAL_PAYMENT_SYSTEMS";
export const GET_GLOBAL_PAYMENT_SYSTEMS_BEGIN =
  "GET_GLOBAL_PAYMENT_SYSTEMS_BEGIN";
export const GET_GLOBAL_PAYMENT_SYSTEMS_SUCCESS =
  "GET_GLOBAL_PAYMENT_SYSTEMS_SUCCESS";
export const GET_GLOBAL_PAYMENT_SYSTEMS_FAIL =
  "GET_GLOBAL_PAYMENT_SYSTEMS_FAIL";

export const GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID =
  "GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID";
export const GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_BEGIN =
  "GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_BEGIN";
export const GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_SUCCESS =
  "GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_SUCCESS";
export const GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_FAIL =
  "GET_GLOBAL_PAYMENT_SYSTEMS_BY_ID_FAIL";

export const ADD_GLOBAL_PAYMENT_SYSTEM = "ADD_GLOBAL_PAYMENT_SYSTEM";
export const ADD_GLOBAL_PAYMENT_SYSTEM_BEGIN =
  "ADD_GLOBAL_PAYMENT_SYSTEM_BEGIN";
export const ADD_GLOBAL_PAYMENT_SYSTEM_SUCCESS =
  "ADD_GLOBAL_PAYMENT_SYSTEM_SUCCESS";
export const ADD_GLOBAL_PAYMENT_SYSTEM_FAIL = "ADD_GLOBAL_PAYMENT_SYSTEM_FAIL";

export const DELETE_GLOBAL_PAYMENT_SYSTEM = "DELETE_GLOBAL_PAYMENT_SYSTEM";
export const DELETE_GLOBAL_PAYMENT_SYSTEM_BEGIN =
  "DELETE_GLOBAL_PAYMENT_SYSTEM_BEGIN";
export const DELETE_GLOBAL_PAYMENT_SYSTEM_SUCCESS =
  "DELETE_GLOBAL_PAYMENT_SYSTEM_SUCCESS";
export const DELETE_GLOBAL_PAYMENT_SYSTEM_FAIL =
  "DELETE_GLOBAL_PAYMENT_SYSTEM_FAIL";

export const RESET_GLOBAL_PAYMENT_SYSTEM_ERRORS =
  "RESET_GLOBAL_PAYMENT_SYSTEM_ERRORS";
