import { call, put, takeLatest } from "redux-saga/effects";
import {
  del,
  get,
  post,
  update,
  updateFormData,
} from "../../helpers/api_helper";
import {
  addHuddleCategoriesBegin,
  addHuddleCategoriesFail,
  addHuddleCategoriesSuccess,
  addHuddleReportedUsersCategoriesBegin,
  addHuddleReportedUsersCategoriesFail,
  addHuddleReportedUsersCategoriesSuccess,
  blockHuddleBegin,
  blockHuddleFail,
  blockHuddleSuccess,
  deleteHuddleBegin,
  deleteHuddleCategoriesBegin,
  deleteHuddleCategoriesFail,
  deleteHuddleCategoriesSuccess,
  deleteHuddleFail,
  deleteHuddleReportedUserCategoriesBegin,
  deleteHuddleReportedUserCategoriesFail,
  deleteHuddleReportedUserCategoriesSuccess,
  deleteHuddleSuccess,
  editHuddleBegin,
  editHuddleCategoriesBegin,
  editHuddleCategoriesFail,
  editHuddleCategoriesSuccess,
  editHuddleFail,
  editHuddleReportedUsersCategoriesBegin,
  editHuddleReportedUsersCategoriesFail,
  editHuddleReportedUsersCategoriesSuccess,
  editHuddleSuccess,
  getHuddleParticipantsBegin,
  getHuddleParticipantsFail,
  getHuddleParticipantsSuccess,
  getHuddleReportedMessagesBegin,
  getHuddleReportedMessagesFail,
  getHuddleReportedMessagesSuccess,
  getHuddleReportedUsersCategoriesBegin,
  getHuddleReportedUsersCategoriesByIdBegin,
  getHuddleReportedUsersCategoriesByIdFail,
  getHuddleReportedUsersCategoriesByIdSuccess,
  getHuddleReportedUsersCategoriesFail,
  getHuddleReportedUsersCategoriesSuccess,
  getHuddlesBegin,
  getHuddlesByIdBegin,
  getHuddlesByIdFail,
  getHuddlesByIdSuccess,
  getHuddlesCategoriesBegin,
  getHuddlesCategoriesByIdBegin,
  getHuddlesCategoriesByIdFail,
  getHuddlesCategoriesByIdSuccess,
  getHuddlesCategoriesFail,
  getHuddlesCategoriesSuccess,
  getHuddlesFail,
  getHuddlesSuccess,
} from "./actions";
import {
  ADD_HUDDLES_CATEGORIES,
  ADD_HUDDLES_REPORTED_USER_CATEGORIES,
  BLOCK_HUDDLES,
  DELETE_HUDDLES,
  DELETE_HUDDLES_CATEGORIES,
  EDIT_HUDDLES,
  EDIT_HUDDLES_CATEGORIES,
  EDIT_HUDDLES_REPORTED_USER_CATEGORIES,
  GET_HUDDLES,
  GET_HUDDLES_BY_ID,
  GET_HUDDLES_CATEGORIES,
  GET_HUDDLES_CATEGORIES_BY_ID,
  GET_HUDDLES_PARTICIPANTS,
  GET_HUDDLES_REPORTED_MESSAGES,
  GET_HUDDLES_REPORTED_USER_CATEGORIES,
  GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID,
  DELETE_HUDDLES_LIST,
  GET_HUDDLES_STAT,
  DELETE_HUDDLES_REPORTED_USER_CATEGORIES,
} from "./actionTypes";
import { DELETE_HUDDLE_LIST } from "../../helpers/url_helper";
import { responsiveFontSizes } from "@material-ui/core";

function* getHuddlesCategories(res) {
  try {
    yield put(getHuddlesCategoriesBegin());
    const response = yield call(get, "/admin/huddles/categories", res.request);

    yield put(getHuddlesCategoriesSuccess(response.result));
  } catch (error) {
    yield put(getHuddlesCategoriesFail(error));
  }
}

function* getHuddlesCategoriesById({ id }) {
  try {
    yield put(getHuddlesCategoriesByIdBegin());
    const response = yield call(get, `/admin/huddles/categories/${id}`);

    yield put(getHuddlesCategoriesByIdSuccess(response.result));
  } catch (error) {
    yield put(getHuddlesCategoriesByIdFail(error));
  }
}

function* addHuddleCategories({ category, callback }) {
  try {
    yield put(addHuddleCategoriesBegin());
    const response = yield call(post, "/admin/huddles/categories", category);
    yield put(addHuddleCategoriesSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(addHuddleCategoriesFail(error));
  }
}

function* editHuddleCategories({ id, category, callback }) {
  try {
    yield put(editHuddleCategoriesBegin());
    const response = yield call(
      update,
      `/admin/huddles/categories/${id}`,
      category
    );
    callback && callback();
    yield put(editHuddleCategoriesSuccess(response.message));
  } catch (error) {
    yield put(editHuddleCategoriesFail(error));
  }
}

function* deleteHuddleCategories({ id, callback }) {
  try {
    yield put(deleteHuddleCategoriesBegin());
    const response = yield call(del, `/admin/huddles/categories/${id}`);
    callback && callback();
    yield put(deleteHuddleCategoriesSuccess(response.result));
  } catch (error) {
    yield put(deleteHuddleCategoriesFail(error));
  }
}

// Huddles

function* getHuddles(res) {
  try {
    yield put(getHuddlesBegin());
    const response = yield call(get, "/admin/huddles", res.request);

    yield put(getHuddlesSuccess(response.result));
  } catch (error) {
    yield put(getHuddlesFail(error));
  }
}
function* getHuddlesStat(res) {
  try {
    yield put(getHuddlesBegin());
    const response = yield call(get, "/admin/huddles/user", res.request);

    yield put(getHuddlesSuccess(response.result));
  } catch (error) {
    yield put(getHuddlesFail(error));
  }
}

function* getHuddlesById({ id }) {
  try {
    yield put(getHuddlesByIdBegin());
    const response = yield call(get, `/admin/huddles/${id}`);

    yield put(getHuddlesByIdSuccess(response.result));
  } catch (error) {
    yield put(getHuddlesByIdFail(error));
  }
}

function* editHuddle(payload) {
  try {
    yield put(editHuddleBegin());
    let formData = new FormData();
    formData.append("data", JSON.stringify(payload.params));
    formData.append("file", payload.file);
    const response = yield call(
      updateFormData,
      `/admin/huddles/${payload.params.id}`,
      formData,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    yield put(editHuddleSuccess(response.message));
    payload.callback && payload.callback();
  } catch (error) {
    yield put(editHuddleFail(error));
  }
}

function* deleteHuddle({ id, callback }) {
  try {
    yield put(deleteHuddleBegin());
    const response = yield call(del, `/admin/huddles/${id}`);
    callback && callback();
    yield put(deleteHuddleSuccess(response.message));
  } catch (error) {
    yield put(deleteHuddleFail(error));
  }
}

function* blockHuddle({ id, callback }) {
  try {
    yield put(blockHuddleBegin());
    let formData = new FormData();
    formData.append("data", JSON.stringify({ block_huddle: true }));
    const response = yield call(
      updateFormData,
      `/admin/huddles/${id}`,
      formData,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    callback && callback();
    yield put(blockHuddleSuccess(response.message));
  } catch (error) {
    yield put(blockHuddleFail(error));
  }
}

// Huddle User Reported  Categories

function* getHuddleReportedUsersCategories(res) {
  try {
    yield put(getHuddleReportedUsersCategoriesBegin());
    const response = yield call(
      get,
      "/admin/report/huddles/categories",
      res.request
    );

    yield put(getHuddleReportedUsersCategoriesSuccess(response.result));
  } catch (error) {
    yield put(getHuddleReportedUsersCategoriesFail(error));
  }
}

function* getHuddleReportedUsersCategoriesById({ id }) {
  try {
    yield put(getHuddleReportedUsersCategoriesByIdBegin());
    const response = yield call(get, `/admin/report/huddles/categories/${id}`);

    yield put(getHuddleReportedUsersCategoriesByIdSuccess(response.result));
  } catch (error) {
    yield put(getHuddleReportedUsersCategoriesByIdFail(error));
  }
}

function* addHuddleReportedUsersCategories({ category, callback }) {
  try {
    yield put(addHuddleReportedUsersCategoriesBegin());
    const response = yield call(
      post,
      "/admin/report/huddles/categories",
      category
    );
    yield put(addHuddleReportedUsersCategoriesSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(addHuddleReportedUsersCategoriesFail(error));
  }
}

function* deleteHuddleReportedUserCategories({ id, callback }) {
  try {
    yield put(deleteHuddleReportedUserCategoriesBegin());
    const response = yield call(del, `admin/report/huddles/categories/${id}`);
    yield put(deleteHuddleReportedUserCategoriesSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(deleteHuddleReportedUserCategoriesFail(error));
  }
}

function* editHuddleReportedUsersCategories({ id, category, callback }) {
  try {
    yield put(editHuddleReportedUsersCategoriesBegin());
    const response = yield call(
      update,
      `/admin/report/huddles/categories/${id}`,
      category
    );
    callback && callback();
    yield put(editHuddleReportedUsersCategoriesSuccess(response.message));
  } catch (error) {
    yield put(editHuddleReportedUsersCategoriesFail(error));
  }
}

function* getHuddleReportedMessages({ request, id }) {
  try {
    yield put(getHuddleReportedMessagesBegin());
    const response = yield call(get, `/admin/huddles/${id}/reports`);

    yield put(getHuddleReportedMessagesSuccess(response.result));
  } catch (error) {
    yield put(getHuddleReportedMessagesFail(error));
  }
}

function* getHuddleParticipants({ request, id }) {
  try {
    yield put(getHuddleParticipantsBegin());
    const response = yield call(
      get,
      `/admin/huddles/reports/${id}/participants`
    );

    yield put(getHuddleParticipantsSuccess(response.result));
  } catch (error) {
    yield put(getHuddleParticipantsFail(error));
  }
}

function* deleteHuddleList({ ids, callback }) {
  try {
    yield put(deleteHuddleBegin());
    const response = yield call(del, DELETE_HUDDLE_LIST, {
      data: { huddle_ids: ids },
    });
    callback && callback();
    yield put(deleteHuddleSuccess(response.message));
  } catch (error) {
    yield put(deleteHuddleFail(error));
  }
}

function* HuddlesSaga() {
  yield takeLatest(GET_HUDDLES_CATEGORIES, getHuddlesCategories);
  yield takeLatest(EDIT_HUDDLES_CATEGORIES, editHuddleCategories);
  yield takeLatest(ADD_HUDDLES_CATEGORIES, addHuddleCategories);
  yield takeLatest(GET_HUDDLES_CATEGORIES_BY_ID, getHuddlesCategoriesById);
  yield takeLatest(DELETE_HUDDLES_CATEGORIES, deleteHuddleCategories);

  yield takeLatest(GET_HUDDLES, getHuddles);
  yield takeLatest(GET_HUDDLES_BY_ID, getHuddlesById);
  yield takeLatest(EDIT_HUDDLES, editHuddle);
  yield takeLatest(DELETE_HUDDLES, deleteHuddle);
  yield takeLatest(BLOCK_HUDDLES, blockHuddle);
  yield takeLatest(DELETE_HUDDLES_LIST, deleteHuddleList);

  yield takeLatest(GET_HUDDLES_STAT, getHuddlesStat);

  yield takeLatest(
    GET_HUDDLES_REPORTED_USER_CATEGORIES,
    getHuddleReportedUsersCategories
  );
  yield takeLatest(
    EDIT_HUDDLES_REPORTED_USER_CATEGORIES,
    editHuddleReportedUsersCategories
  );
  yield takeLatest(
    ADD_HUDDLES_REPORTED_USER_CATEGORIES,
    addHuddleReportedUsersCategories
  );
  yield takeLatest(
    GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID,
    getHuddleReportedUsersCategoriesById
  );

  yield takeLatest(GET_HUDDLES_REPORTED_MESSAGES, getHuddleReportedMessages);
  yield takeLatest(GET_HUDDLES_PARTICIPANTS, getHuddleParticipants);

  yield takeLatest(
    DELETE_HUDDLES_REPORTED_USER_CATEGORIES,
    deleteHuddleReportedUserCategories
  );
}

export default HuddlesSaga;
