import { call, put, takeLatest } from "@redux-saga/core/effects";
import { del, get, post, updateFormData } from "../../helpers/api_helper";
import { API_GET_CHALLENGES_HISTORY } from "../../helpers/url_helper";
import {
  addPodiumsCategoriesBegin,
  addPodiumsCategoriesFail,
  addPodiumsCategoriesSuccess,
  deletePodiumsCategoriesSuccess,
  editPodiumsCategoriesBegin,
  editPodiumsCategoriesFail,
  editPodiumsCategoriesSuccess,
  getPodiumsBegin,
  getPodiumsCategoriesBegin,
  getPodiumsCategoriesByIdBegin,
  getPodiumsCategoriesByIdFail,
  getPodiumsCategoriesByIdSuccess,
  getPodiumsCategoriesFail,
  getPodiumsCategoriesSuccess,
  getPodiumsFail,
  getPodiumsSuccess,
  deletePodiumsCategoriesBegin,
  deletePodiumsCategoriesFail,
  getChallengesHistoryBegin,
  getChallengesHistorySuccess,
  getChallengesHistoryFail,
  getChallengesHistoryByUserIdBegin,
  getChallengesHistoryByUserIdSuccess,
  getChallengesHistoryByUserIdFail,
  getSpecificChallengeDetailsBegin,
  getSpecificChallengeDetailsSuccess,
  getSpecificChallengeDetailsFail,
  editPodiumBegin,
  editPodiumSuccess,
  editPodiumFail,
  deletePodiumBegin,
  deletePodiumSuccess,
  deletePodiumFail,
  getPodiumsByIdBegin,
  getPodiumsByIdSuccess,
  getPodiumsByIdFail,
} from "./actions";
import {
  ADD_PODIUMS_CATEGORIES,
  ADD_PODIUMS_CATEGORIES_FAIL,
  DELETE_PODIUMS,
  DELETE_PODIUMS_CATEGORIES,
  EDIT_PODIUMS,
  EDIT_PODIUMS_CATEGORIES,
  GET_CHALLENGES_HISTORY,
  GET_CHALLENGES_HISTORY_BY_USER_ID,
  GET_PODIUMS,
  GET_PODIUMS_BY_ID,
  GET_PODIUMS_CATEGORIES,
  GET_PODIUMS_CATEGORIES_BY_ID,
  GET_PODIUMS_CATEGORIES_BY_ID_FAIL,
  GET_SPECIFIC_CHALLENGE_DETAILS,
} from "./actionTypes";

function* getPodiums(res) {
  try {
    yield put(getPodiumsBegin());
    const response = yield call(get, "/admin/podium", res.request);

    yield put(getPodiumsSuccess(response.result));
  } catch (error) {
    yield put(getPodiumsFail(error));
  }
}
function* getPodiumsById({ id }) {
  try {
    yield put(getPodiumsByIdBegin());
    const response = yield call(get, `/admin/podium/${id}`);

    yield put(getPodiumsByIdSuccess(response.result));
  } catch (error) {
    yield put(getPodiumsByIdFail(error));
  }
}

function* editPodium(payload) {
  try {
    yield put(editPodiumBegin());

    // formData.append("file", payload.file);
    const response = yield call(
      updateFormData,
      `/admin/podium/${payload?.id}`,
      payload?.data
    );
    yield put(editPodiumSuccess(response.message));
    payload.callback && payload.callback();
  } catch (error) {
    yield put(editPodiumFail(error));
  }
}

function* deletePodium({ id, callback }) {
  try {
    yield put(deletePodiumBegin());
    const response = yield call(del, `/admin/podium/${id}`);
    callback && callback();
    yield put(deletePodiumSuccess(response.message));
  } catch (error) {
    yield put(deletePodiumFail(error));
  }
}
function* getPodiumsCategories(res) {
  try {
    yield put(getPodiumsCategoriesBegin());
    const response = yield call(get, "/admin/podium/categories", res.request);

    yield put(getPodiumsCategoriesSuccess(response.result));
  } catch (error) {
    yield put(getPodiumsCategoriesFail(error));
  }
}
function* addPodiumsCategories({ category, callback }) {
  try {
    yield put(addPodiumsCategoriesBegin());
    const response = yield call(post, "/admin/podium/categories", category);

    yield put(addPodiumsCategoriesSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(addPodiumsCategoriesFail(error));
  }
}
function* getPodiumsCategoriesByIdSaga({ id }) {
  try {
    yield put(getPodiumsCategoriesByIdBegin());
    const response = yield call(get, `/admin/podium/categories/${id}`);
    yield put(getPodiumsCategoriesByIdSuccess(response.result));
  } catch (error) {
    yield put(getPodiumsCategoriesByIdFail(error));
  }
}
function* editPodiumsCategories({ params, id, categoryList, callback }) {
  try {
    yield put(editPodiumsCategoriesBegin());
    const response = yield call(
      updateFormData,
      `/admin/podium/categories?category=${params}`,
      categoryList
    );
    // callback && callback();
    yield put(editPodiumsCategoriesSuccess(response.message));
  } catch (error) {
    yield put(editPodiumsCategoriesFail(error));
  }
}
function* deletePodiumsCategories({ id, callback }) {
  try {
    yield put(deletePodiumsCategoriesBegin());
    const response = yield call(del, `/admin/podium/categories/${id}`);
    callback && callback();
    yield put(deletePodiumsCategoriesSuccess(response.result));
  } catch (error) {
    yield put(deletePodiumsCategoriesFail(error));
  }
}

function* getChallengesHistory(res) {
  try {
    yield put(getChallengesHistoryBegin());
    const response = yield call(get, "/podium/challenges/history", res.request);

    yield put(getChallengesHistorySuccess(response.result));
  } catch (error) {
    yield put(getChallengesHistoryFail(error));
  }
}
function* getChallengesHistoryByUserId({ request, userId }) {
  console.log("saga-id", userId);
  try {
    yield put(getChallengesHistoryByUserIdBegin());
    const response = yield call(
      get,
      `/podium/users/${userId}/challenges/history`,
      request
    );

    yield put(getChallengesHistoryByUserIdSuccess(response.result));
  } catch (error) {
    yield put(getChallengesHistoryByUserIdFail(error));
  }
}
function* getSpecificChallengeDetails({ request, podiumId, challengeId }) {
  try {
    yield put(getSpecificChallengeDetailsBegin());
    const response = yield call(
      get,
      `/podium/${podiumId}/challenges/${challengeId}/history`,
      request
    );

    yield put(getSpecificChallengeDetailsSuccess(response.result));
  } catch (error) {
    yield put(getSpecificChallengeDetailsFail(error));
  }
}

function* PodiumsSaga() {
  yield takeLatest(GET_PODIUMS, getPodiums);
  yield takeLatest(GET_PODIUMS_CATEGORIES, getPodiumsCategories);
  yield takeLatest(ADD_PODIUMS_CATEGORIES, addPodiumsCategories);
  yield takeLatest(GET_PODIUMS_CATEGORIES_BY_ID, getPodiumsCategoriesByIdSaga);
  yield takeLatest(EDIT_PODIUMS_CATEGORIES, editPodiumsCategories);
  yield takeLatest(DELETE_PODIUMS_CATEGORIES, deletePodiumsCategories);
  yield takeLatest(GET_CHALLENGES_HISTORY, getChallengesHistory);
  yield takeLatest(
    GET_CHALLENGES_HISTORY_BY_USER_ID,
    getChallengesHistoryByUserId
  );
  yield takeLatest(GET_SPECIFIC_CHALLENGE_DETAILS, getSpecificChallengeDetails);
  yield takeLatest(GET_PODIUMS_BY_ID, getPodiumsById);
  yield takeLatest(EDIT_PODIUMS, editPodium);
  yield takeLatest(DELETE_PODIUMS, deletePodium);
}

export default PodiumsSaga;
