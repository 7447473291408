import {
  GET_SUPERSTAR_SUGGESTION_BEGIN,
  GET_SUPERSTAR_SUGGESTION_SUCCESS,
  GET_SUPERSTAR_SUGGESTION_FAIL,
  ADD_SUPERSTAR_SUGGESTION_BEGIN,
  ADD_SUPERSTAR_SUGGESTION_SUCCESS,
  ADD_SUPERSTAR_SUGGESTION_FAIL,
  DELETE_SUPERSTAR_SUGGESTION_BEGIN,
  DELETE_SUPERSTAR_SUGGESTION_SUCCESS,
  DELETE_SUPERSTAR_SUGGESTION_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  loading: false,
  success: "",
  superStarList: [],
  error: "",
};

const superstarSuggestionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUPERSTAR_SUGGESTION_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_SUPERSTAR_SUGGESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        superStarList: action.payload,
      };

    case GET_SUPERSTAR_SUGGESTION_FAIL:
      return {
        ...state,
        loading: false,
        superStarList: "",
        error: action.payload,
      };
    case ADD_SUPERSTAR_SUGGESTION_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case ADD_SUPERSTAR_SUGGESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };

    case ADD_SUPERSTAR_SUGGESTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        superStarList: {},
        success: "",
      };

    case DELETE_SUPERSTAR_SUGGESTION_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case DELETE_SUPERSTAR_SUGGESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: "",
      };

    case DELETE_SUPERSTAR_SUGGESTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        superStarList: {},
        success: "",
      };

    default:
      return state;
  }
};

export default superstarSuggestionReducer;
