import React from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Button from "../../../../components/UI/Button";

const AllocateForm = ({ newUsername, cancelClick, setNewUsername, handleAllocate, warning, data, checkAvailabiltyHandler, resetError }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <AvForm
      onValidSubmit={(e, v) => {
        e.preventDefault();
      }}
    >
      <hr />
      <>
        <Row className="col-md-12">
          <div className="col-md-12">
            {data?.user && (
              <div className="enterUserNameWrapper">
                <div className="enterUserNameWrap">
                  <label>
                    {t("AllocateUserName.addNew.NewUserName")}
                    <span className="mandatory">*</span>
                    {data?.loading && <span className="spinnerClass"><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>}
                  </label>
                  <AvField
                    name="enterNewUsername"
                    placeholder={t("AllocateUserName.addNew.UserNamePlaceholder")}
                    type="text"
                    onChange={(e) => { dispatch(resetError()); setNewUsername(e?.target?.value?.trim()) }}
                    value={newUsername}
                    maxlength="50"
                  />
                </div>
                <button
                  className="btn waves-effect waves-light cust_no_shadow check_availability_button"
                  disabled={!newUsername}
                  onClick={checkAvailabiltyHandler}
                >CHECK AVAILABILITY</button>
              </div>
            )}
            {warning && <p className="bg-soft-orange warningSection"><i className="fa fa-exclamation-triangle warningIcon"></i>{warning}</p>}
            {data?.allocateError && <p className="color-red mb-0">{data?.allocateError}</p>}
            {data?.availableError && <div className="color-red">{data?.availableError}</div>}
            {data?.available && <div className="color-green">{data?.available}</div>}
          </div>
        </Row>

        <div className="allocateNewButtonWrap">
          <Button
            buttonClass={
              "btn waves-effect waves-light cust_no_shadow btn-light"
            }
            label={t("AllocateUserName.addNew.Cancel")}
            handleClick={cancelClick}
          />
          <Button
            buttonClass={
              "btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
            }
            label={t("AllocateUserName.addNew.ForceFullyChange")}
            disableBtn={(!data?.available && data?.availableUsername !== newUsername) || !newUsername}
            handleClick={() => handleAllocate('forcefullyChange')}
          />
          <Button
            buttonClass={
              "btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
            }
            label={t("AllocateUserName.addNew.Suggest")}
            disableBtn={(!data?.available && data?.availableUsername !== newUsername) || !newUsername}
            handleClick={() => handleAllocate('suggest')}
          />
        </div>
      </>
    </AvForm>
  );
};

export default AllocateForm;
