import { success } from "toastr";
import {
  ADD_POSTAT_MUSIC_BEGIN,
  ADD_POSTAT_MUSIC_FAIL,
  ADD_POSTAT_MUSIC_SUCCESS,
  DELETE_POSTAT_BEGIN,
  DELETE_POSTAT_FAIL,
  DELETE_POSTAT_MUSIC_BEGIN,
  DELETE_POSTAT_MUSIC_FAIL,
  DELETE_POSTAT_MUSIC_SUCCESS,
  DELETE_POSTAT_REPORTED_COMMENT_BEGIN,
  DELETE_POSTAT_REPORTED_COMMENT_FAIL,
  DELETE_POSTAT_REPORTED_COMMENT_SUCCESS,
  DELETE_POSTAT_SUCCESS,
  EDIT_POSTAT_MUSIC_BEGIN,
  EDIT_POSTAT_MUSIC_FAIL,
  EDIT_POSTAT_MUSIC_SUCCESS,
  GET_ALL_POSTAT_POSTS_BEGIN,
  GET_ALL_POSTAT_POSTS_FAIL,
  GET_ALL_POSTAT_POSTS_SUCCESS,
  GET_COMMENT_REPORTS_BY_ID_BEGIN,
  GET_COMMENT_REPORTS_BY_ID_FAIL,
  GET_COMMENT_REPORTS_BY_ID_SUCCESS,
  GET_POSTAT_MUSIC_BEGIN,
  GET_POSTAT_MUSIC_BY_ID_BEGIN,
  GET_POSTAT_MUSIC_BY_ID_FAIL,
  GET_POSTAT_MUSIC_BY_ID_SUCCESS,
  GET_POSTAT_MUSIC_FAIL,
  GET_POSTAT_MUSIC_SUCCESS,
  GET_POSTAT_REPORTED_COMMENTS_BEGIN,
  GET_POSTAT_REPORTED_COMMENTS_FAIL,
  GET_POSTAT_REPORTED_COMMENTS_SUCCESS,
  GET_POSTAT_REPORTED_POSTS_BEGIN,
  GET_POSTAT_REPORTED_POSTS_FAIL,
  GET_POSTAT_REPORTED_POSTS_SUCCESS,
  GET_POST_REPORTS_BY_ID_BEGIN,
  GET_POST_REPORTS_BY_ID_FAIL,
  GET_POST_REPORTS_BY_ID_SUCCESS,
  IGNORE_POSTAT_REPORTED_COMMENTS_BEGIN,
  IGNORE_POSTAT_REPORTED_COMMENTS_FAIL,
  IGNORE_POSTAT_REPORTED_COMMENTS_SUCCESS,
  IGNORE_POSTAT_REPORTED_POSTAT_BEGIN,
  IGNORE_POSTAT_REPORTED_POSTAT_FAIL,
  IGNORE_POSTAT_REPORTED_POSTAT_SUCCESS,
  RESET_POSTAT_REPORTED_POSTS_ERRORS,
} from "./actionTypes";

const INIT_STATE = {
  loading: false,
  reportedPosts: {},
  postReports: {},
  reportedComments: {},
  commentReports: {},
  postatMusic: {},
  error: "",
  success: "",
  request: {
    // sort: "transfered_date",
    // sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
    tab: "all",
  },
};

const ReportedPostatPosts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_POSTAT_REPORTED_POSTS_BEGIN:
      return {
        ...state,
        loading: true,
        reportedPosts: {},
      };
    case GET_POSTAT_REPORTED_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        reportedPosts: action.payload,
        error: "",
      };
    case GET_POSTAT_REPORTED_POSTS_FAIL:
      return {
        ...state,
        loading: false,
        reportedPosts: {},
        error: action.payload,
      };
    case RESET_POSTAT_REPORTED_POSTS_ERRORS: {
      return {
        ...state,
        error: "",
        success: "",
      };
    }
    case GET_POST_REPORTS_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        postReports: {},
      };
    case GET_POST_REPORTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        postReports: action.payload,
        error: "",
      };
    case GET_POST_REPORTS_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        postReports: {},
        error: action.payload,
      };
    case DELETE_POSTAT_BEGIN:
      return {
        ...state,
        loading: true,
        postReports: {},
      };
    case DELETE_POSTAT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case DELETE_POSTAT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_POSTAT_REPORTED_COMMENTS_BEGIN:
      return {
        ...state,
        loading: true,
        reportedComments: {},
      };
    case GET_POSTAT_REPORTED_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        reportedComments: action.payload,
        error: "",
      };
    case GET_POSTAT_REPORTED_COMMENTS_FAIL:
      return {
        ...state,
        loading: false,
        reportedComments: {},
        error: action.payload,
      };
    case DELETE_POSTAT_REPORTED_COMMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_POSTAT_REPORTED_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case DELETE_POSTAT_REPORTED_COMMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_COMMENT_REPORTS_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        commentReports: {},
      };
    case GET_COMMENT_REPORTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        commentReports: action.payload,
        error: "",
      };
    case GET_COMMENT_REPORTS_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        commentReports: {},
        error: action.payload,
      };
    case GET_POSTAT_MUSIC_BEGIN:
      return {
        ...state,
        loading: true,
        postatMusic: {},
      };
    case GET_POSTAT_MUSIC_SUCCESS:
      return {
        ...state,
        loading: false,
        postatMusic: action.payload,
        error: "",
      };
    case GET_POSTAT_MUSIC_FAIL:
      return {
        ...state,
        loading: false,
        postatMusic: {},
        error: action.payload,
      };
    case ADD_POSTAT_MUSIC_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_POSTAT_MUSIC_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "success",
        error: "",
      };
    case ADD_POSTAT_MUSIC_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_POSTAT_MUSIC_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_POSTAT_MUSIC_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case DELETE_POSTAT_MUSIC_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_POSTAT_MUSIC_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        postatMusicData: {},
      };
    case GET_POSTAT_MUSIC_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        postatMusicData: action.payload,
      };
    case GET_POSTAT_MUSIC_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        postatMusicData: {},
        error: action.payload,
      };
    case EDIT_POSTAT_MUSIC_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_POSTAT_MUSIC_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case EDIT_POSTAT_MUSIC_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ALL_POSTAT_POSTS_BEGIN:
      return {
        ...state,
        loading: true,
        allPosts: {},
      };
    case GET_ALL_POSTAT_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allPosts: action.payload,
        error: "",
      };
    case GET_ALL_POSTAT_POSTS_FAIL:
      return {
        ...state,
        loading: false,
        allPosts: {},
        error: action.payload,
      };
    case IGNORE_POSTAT_REPORTED_POSTAT_BEGIN:
      return {
        ...state,
        ignoreLoading: true,
      };
    case IGNORE_POSTAT_REPORTED_POSTAT_SUCCESS:
      return {
        ...state,
        ignoreLoading: false,
        success: action.payload?.action_message,
        error: "",
      };
    case IGNORE_POSTAT_REPORTED_POSTAT_FAIL:
      return {
        ...state,
        ignoreLoading: false,

        error: action.payload,
      };
    case IGNORE_POSTAT_REPORTED_COMMENTS_BEGIN:
      return {
        ...state,
        ignoreLoading: true,
      };
    case IGNORE_POSTAT_REPORTED_COMMENTS_SUCCESS:
      return {
        ...state,
        ignoreLoading: false,
        success: action.payload?.action_message,
        error: "",
      };
    case IGNORE_POSTAT_REPORTED_COMMENTS_FAIL:
      return {
        ...state,
        ignoreLoading: false,
        error: action.payload,
      };

    case RESET_POSTAT_REPORTED_POSTS_ERRORS:
      return {
        ...state,
        error: "",
        success: "",
      };

    default:
      return state;
  }
};

export default ReportedPostatPosts;
