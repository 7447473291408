import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";

import {
  getRolesData, deleteRoleData, storeRequest, resetMessage, setDeleteErrorMessage, getAdminsRole
} from "../../../../src/store/adminManagement/roleManagement/action";

import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";

import { rolePermissionsColumnData } from "../../../helpers/columns";
import { checkUserPermission } from "../../../helpers/utils";

const RoleManagement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.rolePermisions);
 
  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isDeleteable = userPermissions?.delete || false;
  const isEditable = userPermissions?.edit || false;

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();

  const basicRequest = {
    page: 1,
    page_count: 50,
    keyword: "",
  };

  const [request, setRequest] = useState();
  const [roleId, setRoleId] = useState();

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(loginData?.rolePermissions?.role, "Admin Management", "Roles & Permissions")
      );
    }
  }, [loginData?.rolePermissions?.role]);

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error]);

  const scrollToAlertMessage = () => {
    if (data?.error) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {
    history?.location?.state?.from === "keepFilter"
      ? setRequest({ ...data?.request })
      : setRequest({ ...basicRequest });
  }, [history?.location?.state?.from]);

  useEffect(() => {
    request && dispatch(getRolesData(request));
    request && dispatch(storeRequest(request));
    if (!history?.location?.state?.from === "keepFilter" || (
      JSON.stringify(request) && JSON.stringify(data?.request) &&
      JSON.stringify(request) !== JSON.stringify(data?.request)))
      dispatch(resetMessage(request));
  }, [request]);

  useEffect(() => {
    return () => {
      dispatch(resetMessage());
    }
  }, []);

  useEffect(() => {
    if (data?.adminsData?.admins && roleId) {
      if (data?.adminsData?.admins?.length) {
        dispatch(setDeleteErrorMessage());
      }
      else {
        setShowPromptPopUp(!showPromptPopUp);
        setPromptMessage({
          id: roleId,
          content: t("RolePermisions.deleteConfirm"),
          type: "delete",
        });
        setRoleId("");
      }
    }
  }, [data?.adminsData?.admins]);

  const deletePromptHandler = (id) => {
    setRoleId(id);
    dispatch(getAdminsRole({ role_id: id }));
  };

  const okHandler = () => {
    if (promptMessage.type === "delete") {
      dispatch(
        deleteRoleData({
          data: promptMessage.id,
          callback: () => dispatch(getRolesData(request)),
        })
      )
    }
  };

  const addNewClickHandler = () => {
    history.push("/admin-role-addNew");
  };

  const formatRoleData = useCallback(() => {
    return data?.roleData?.roles?.map((role, index) => ({
      ...role,
      no: (request?.page - 1) * request?.page_count + index + 1,
      role: (role.name),
      actions: (
        <div className="cust-table-actions-wrap">
          <button
            className=" color-violet action-btn"
            title="Edit"
            onClick={() =>
              history.push({
                pathname: `/edit-admin-permissions/${role.id}`,
                state: { from: "roles_permissions", isEdit: true, },
              })
            }
            disabled={!isEditable}
          >
            <img src={Edit_icon} alt="Edit" style={{ opacity: !isEditable ? "50%" : "" }} />
          </button>
          <button
            onClick={() => deletePromptHandler(role.id)}
            className=" color-red action-btn"
            title="Delete"
            disabled={!isDeleteable}
          >
            <img src={Delete_icon} alt="Delete" style={{ opacity: !isDeleteable ? "50%" : "" }} />
          </button>
        </div>
      ),
    }));
  }, [data?.roleData?.roles, request, isEditable, isDeleteable]);

  //memoized permissions data
  const permissionsRowData = useMemo(() => formatRoleData(), [formatRoleData]);
  return (
    <div
      className="page-content"
      data-testid="component-permissions"
      id="rolesList"
      ref={pageWrapRef}
    >
      <Container fluid id="userManagementWrapper" data-testid="rolePermissionsWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem={t("RolePermisions.Title")} />
          {(data?.error || data?.success) && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color={data.error ? "danger" : "success"}
                className="alert-dismissible fade show"
                role="alert"
                isOpen={data?.error || data?.success}
                toggle={() => dispatch(resetMessage())}
              >
                {data?.error || data?.success}
              </UncontrolledAlert>
            </div>
          )}
          <Datatable
            tableID={"roleTable"}
            columns={rolePermissionsColumnData}
            rows={permissionsRowData}
            setRequest={setRequest}
            request={request}
            totalRecords={data?.roleData?.total}
            search
            add
            addNewClickHandler={addNewClickHandler}
            addNewLabel={t("RolePermisions.addRole.addBtn")}
            searchTerm={data?.request?.keyword}
            loading={data?.loading}
            tableCardClassName={"snoTable"}
          />
        </Row>
      </Container>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default RoleManagement;
