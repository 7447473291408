import { call, put, takeLatest } from "@redux-saga/core/effects";
import { get } from "../../helpers/api_helper";
import {
  getCountriesBegin,
  getCountriesFail,
  getCountriesSuccess,
} from "./actions";
import { GET_COUNTRIES } from "./actionTypes";

function* getCountriesSaga(res) {
  try {
    yield put(getCountriesBegin());
    const response = yield call(get, "/admin/settings/countries", res.request);

    yield put(getCountriesSuccess(response.result));
  } catch (error) {
    yield put(getCountriesFail(error));
  }
}

function* countrySaga() {
  yield takeLatest(GET_COUNTRIES, getCountriesSaga);
}
export default countrySaga;
