import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { Col, Card, UncontrolledAlert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";

import ViewPublishedPopup from "../EditLegalDocs/viewPublishedPopup";
import EditDocumentForm from "./EditDocumentForm";
import TopSection from "./TopSection";

import {
  editLegalDocument,
  updateLegalDocument,
  deleteLegalDocument,
  enableDisableLegalDocument,
} from "../../../store/contentManagement/action";

import { convertDate } from "../../../helpers/utils";

const EditLegalDocuments = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.contentManagement);
  console.log("contentManagement", data);

  const [formChanged, setFormChanged] = useState(false);
  const [date, setDate] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState();
  const [keyChanges, setKeyChanges] = useState();
  const [enabled, setEnabled] = useState(false);
  const [isDraftDisabled, setIsDraftDisabled] = useState(true);
  const [isPublishDisabled, setIsPublishDisabled] = useState(true);
  const [viewPublishedVersion, setViewPublishedVersion] = useState(false);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [notify, setNotify] = useState(false);
  const [request, setRequest] = useState({});
  const [backToListing, setBackToListing] = useState(false);
  const [emptyError, setEmptyError] = useState("");

  const [lang, setLang] = useState("english");

  useEffect(() => {
    // dispatch(editLegalDocument(match.id));
    dispatch(editLegalDocument({ id: match.id, language: "english" }));
  }, [match.id]);

  useEffect(() => {
    setDate(convertDate(data?.document?.privacy_policies?.effective_date, "-"));
    setEnabled(data?.document?.privacy_policies?.enabled);
    data?.document?.privacy_policies?.title &&
      setTitle(data?.document?.privacy_policies?.title);
    setNotify(
      data?.document?.privacy_policies?.status === "Published"
        ? data?.document?.privacy_policies?.notify_user
        : false
    );
    // data?.document?.privacy_policies?.description &&
    //   setDescription(
    //     EditorState.createWithContent(
    //       ContentState.createFromBlockArray(
    //         convertFromHTML(data?.document?.privacy_policies?.description)
    //       )
    //     )
    //   );
    data?.document?.privacy_policies?.description_raw &&
      setDescription(
        EditorState.createWithContent(
          convertFromRaw(
            JSON.parse(data?.document?.privacy_policies?.description_raw)
          )
        )
      );
    // data?.document?.privacy_policies?.key_changes &&
    // setKeyChanges(
    //   EditorState.createWithContent(
    //     ContentState.createFromBlockArray(
    //       convertFromHTML(data?.document?.privacy_policies?.key_changes)
    //     )
    //   )
    // );
    data?.document?.privacy_policies?.key_changes_raw &&
      setKeyChanges(
        EditorState.createWithContent(
          convertFromRaw(
            JSON.parse(data?.document?.privacy_policies?.key_changes_raw)
          )
        )
      );
    data?.document?.privacy_policies?.status === "Drafted" &&
      setIsPublishDisabled(false);
    setRequest({
      enabled: data?.document?.privacy_policies?.enabled,
      description: data?.document?.privacy_policies?.description,
      effective_date: convertDate(
        data?.document?.privacy_policies?.effective_date,
        "-"
      ),
      status: data?.document?.privacy_policies?.status,
      title: data?.document?.privacy_policies?.title,
      key_changes: data?.document?.privacy_policies?.key_changes,
      notify_user: data?.document?.privacy_policies?.notify_user,
    });
  }, [data]);
  useEffect(() => {
    if (description && data?.document?.privacy_policies?.description) {
      const beDescriptionToState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(data?.document?.privacy_policies?.description)
        )
      );
      if (
        draftToHtml(convertToRaw(description.getCurrentContent()))?.trim() !==
        draftToHtml(
          convertToRaw(beDescriptionToState.getCurrentContent())
        )?.trim()
      ) {
        setFormChanged(true);
        setIsDraftDisabled(false);
        setIsPublishDisabled(false);
      }
    }
    if (data?.document?.privacy_policies?.key_changes && keyChanges) {
      const keyChangesToState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(data?.document?.privacy_policies?.key_changes)
        )
      );
      if (
        draftToHtml(convertToRaw(keyChanges.getCurrentContent()))?.trim() !==
        draftToHtml(convertToRaw(keyChangesToState.getCurrentContent()))?.trim()
      ) {
        setFormChanged(true);
        setIsDraftDisabled(false);
        setIsPublishDisabled(false);
      }
    }
    // if (!data?.document?.privacy_policies?.key_changes && keyChanges) {
    //   setFormChanged(true);
    //   setIsDraftDisabled(false);
    //   setIsPublishDisabled(false);
    // }
    if (notify) {
      if (date) {
        let currentTime = new Date().getTime();
        let dateTime = new Date(date).getTime();
        let beDateTime = new Date(
          convertDate(data?.document?.privacy_policies?.effective_date, "-")
        ).getTime();
        if (dateTime !== beDateTime) {
          if (dateTime > currentTime) {
            setIsDraftDisabled(false);
            setIsPublishDisabled(false);
          } else {
            setIsDraftDisabled(true);
            setIsPublishDisabled(true);
          }
        }
      } else {
        setIsDraftDisabled(true);
        setIsPublishDisabled(true);
      }
    } else {
      if (formChanged) {
        setIsDraftDisabled(false);
        setIsPublishDisabled(false);
      }
    }
  }, [
    description,
    date,
    keyChanges,
    data?.document?.privacy_policies?.key_changes,
    data?.document?.privacy_policies?.description,
    notify,
  ]);

  useEffect(() => {
    backToListing && history.push({ pathname: "/legal-documents" });
  }, [backToListing]);

  const redirectMethod = () => {
    setBackToListing(true);
  };

  const deleteDocument = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: t("legalDocuments.DeleteConfirmation"),
      type: "delete",
    });
  };

  const okHandler = () => {
    promptMessage?.type === "delete" &&
      dispatch(
        deleteLegalDocument({
          data: match.id,
          callback: () => history.push("/legal-documents"),
        })
      );
    promptMessage?.type === "publish" && submitDoument("publish");
    promptMessage?.type === "back" && redirectMethod();
  };

  const updateDocument = (data, method, callback) => {
    //method to invoke api call to update document
    let finalData = {
      ...data,
      description: data?.description?.includes("\n<p></p>")
        ? data?.description?.replaceAll("\n<p></p>", "")
        : data?.description,
      key_changes: data?.key_changes?.includes("\n<p></p>")
        ? data?.key_changes?.replaceAll("\n<p></p>", "")
        : data?.key_changes,
    };
    if (
      finalData?.description === "<p></p>" ||
      (finalData?.notify_user &&
        (finalData?.key_changes === "<p></p>" || finalData?.key_changes === ""))
    ) {
      setEmptyError(
        finalData?.description === "<p></p>"
          ? "Description cannot be empty"
          : "Key changes cannot be empty"
      );
    } else {
      setEmptyError("");
      dispatch(
        updateLegalDocument({
          id: match.id,
          method: method,
          data: {
            ...data,
            key_changes: data?.key_changes?.includes("\n<p></p>")
              ? data?.key_changes?.replaceAll("\n<p></p>", "")
              : data?.key_changes,
          },
          callback: callback,
        })
      );
    }
  };
  const enableDisableDocument = (isEnabled, id) => {
    dispatch(
      enableDisableLegalDocument({
        data: {
          ...request,
          notify_user: false,
          enabled: isEnabled,
          enable_or_disable: true,
        },
        id: id ? id : match.id,
        callback: () => {
          setEnabled(isEnabled);
          setRequest({ ...request, enabled: isEnabled });
        },
      })
    );
  };

  const handleLanguageClick = (id, name) => {
    // Despatch the Language Call
    dispatch(editLegalDocument({ id: match.id, language: name }));
  };

  const submitDoument = (button) => {
    const documentRequest = {
      //common request object
      description: description
        ? '<html dir="auto">' +
          draftToHtml(convertToRaw(description.getCurrentContent()))?.trim() +
          "</html>"
        : "",
      description_raw: description
        ? JSON.stringify(convertToRaw(description.getCurrentContent()))
        : "",
      key_changes_raw: keyChanges
        ? JSON.stringify(convertToRaw(keyChanges.getCurrentContent()))
        : "",
      key_changes:
        '<html dir="auto">' + keyChanges
          ? draftToHtml(convertToRaw(keyChanges?.getCurrentContent()))?.trim() +
            "</html>"
          : "",
      effective_date: date,
      title: title,
      language: lang,
      notify_user: false,
      enabled: data?.document?.privacy_policies?.enabled,
      status: data?.document?.privacy_policies?.status,
    };
    if (data?.document?.privacy_policies?.status === "Drafted") {
      //if current status is drafted and user needs to again save as draft or publish
      button === "draft"
        ? updateDocument(documentRequest, "put", () => redirectMethod())
        : updateDocument(
            {
              ...documentRequest,
              enabled: true,
              notify_user: notify,
              status: "Published",
            },
            "put",
            () => redirectMethod()
          );
    } else {
      // if current status is published and user needs to publish again or add as draft
      if (button === "publish" && !notify) {
        updateDocument(
          {
            ...documentRequest,
            enabled: true,
            notify_user: false,
          },
          "put",
          () => redirectMethod()
        );
      } else {
        updateDocument(
          {
            ...documentRequest,
            enabled:
              button === "publish"
                ? true
                : data?.document?.privacy_policies?.enabled,
            notify_user: button === "publish" ? true : false,
            status: button === "publish" ? "Published" : "Drafted",
          },
          "post",
          () => redirectMethod()
        );
      }
    }
  };

  const confirmBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
  };

  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };
  return (
    <div className="page-content" data-testid="component-legalDocumentEdit">
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            return confirmBrowserBack();
          }
        }}
        when={formChanged}
      />
      <BackButton
        label={t("legalDocuments.backButtonTitle")}
        handleClick={() => (formChanged ? confirmBack() : redirectMethod())}
      />
      <Breadcrumbs
        title="Tables"
        breadcrumbItem={t("legalDocuments.EditDocumentTitle")}
      />
      <TopSection
        data={data}
        setViewPublishedVersion={setViewPublishedVersion}
        ViewPublishedPopup={viewPublishedVersion}
        id={match.id}
        deleteDocument={deleteDocument}
      />
      {(emptyError || data?.error) && (
        <UncontrolledAlert
          color="danger"
          className="alert-dismissible fade show"
          role="alert"
        >
          {emptyError || data?.error}
        </UncontrolledAlert>
      )}
      <Col xl="12">
        <Card className="p-4">
          <EditDocumentForm
            data={data}
            date={date}
            setEnabled={setEnabled}
            setDate={setDate}
            enabled={enabled}
            description={description}
            setDescription={setDescription}
            title={title}
            setTitle={setTitle}
            keyChanges={keyChanges}
            setKeyChanges={setKeyChanges}
            setNotify={setNotify}
            notify={notify}
            isDraftDisabled={isDraftDisabled}
            isPublishDisabled={isPublishDisabled}
            enableDisableDocument={enableDisableDocument}
            setIsDraftDisabled={setIsDraftDisabled}
            setIsPublishDisabled={setIsPublishDisabled}
            submitDoument={submitDoument}
            handleLanguageClick={handleLanguageClick}
            setPromptMessage={setPromptMessage}
            setShowPromptPopUp={setShowPromptPopUp}
            setFormChanged={setFormChanged}
            lang={lang}
            setLang={setLang}
          />
        </Card>
      </Col>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {viewPublishedVersion && (
        <ViewPublishedPopup
          modal_center
          tog_center={() => setViewPublishedVersion(false)}
          enabled={enabled}
          data={data}
          enableDisableDocument={enableDisableDocument}
        />
      )}
      {data?.editLoading && <Loader darkBg={true} />}
    </div>
  );
};

export default EditLegalDocuments;
