import {
  ADD_HUDDLES_CATEGORIES_BEGIN,
  ADD_HUDDLES_CATEGORIES_FAIL,
  ADD_HUDDLES_CATEGORIES_SUCCESS,
  ADD_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN,
  ADD_HUDDLES_REPORTED_USER_CATEGORIES_FAIL,
  ADD_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS,
  BLOCK_HUDDLES_BEGIN,
  BLOCK_HUDDLES_FAIL,
  BLOCK_HUDDLES_SUCCESS,
  DELETE_HUDDLES_BEGIN,
  DELETE_HUDDLES_CATEGORIES_BEGIN,
  DELETE_HUDDLES_CATEGORIES_FAIL,
  DELETE_HUDDLES_CATEGORIES_SUCCESS,
  DELETE_HUDDLES_FAIL,
  DELETE_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN,
  DELETE_HUDDLES_REPORTED_USER_CATEGORIES_FAIL,
  DELETE_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS,
  DELETE_HUDDLES_SUCCESS,
  EDIT_HUDDLES_BEGIN,
  EDIT_HUDDLES_CATEGORIES_BEGIN,
  EDIT_HUDDLES_CATEGORIES_FAIL,
  EDIT_HUDDLES_CATEGORIES_SUCCESS,
  EDIT_HUDDLES_FAIL,
  EDIT_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN,
  EDIT_HUDDLES_REPORTED_USER_CATEGORIES_FAIL,
  EDIT_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS,
  EDIT_HUDDLES_SUCCESS,
  GET_HUDDLES_BEGIN,
  GET_HUDDLES_BY_ID_BEGIN,
  GET_HUDDLES_BY_ID_FAIL,
  GET_HUDDLES_BY_ID_SUCCESS,
  GET_HUDDLES_CATEGORIES_BEGIN,
  GET_HUDDLES_CATEGORIES_BY_ID_BEGIN,
  GET_HUDDLES_CATEGORIES_BY_ID_FAIL,
  GET_HUDDLES_CATEGORIES_BY_ID_SUCCESS,
  GET_HUDDLES_CATEGORIES_FAIL,
  GET_HUDDLES_CATEGORIES_SUCCESS,
  GET_HUDDLES_FAIL,
  GET_HUDDLES_PARTICIPANTS_BEGIN,
  GET_HUDDLES_PARTICIPANTS_FAIL,
  GET_HUDDLES_PARTICIPANTS_SUCCESS,
  GET_HUDDLES_REPORTED_MESSAGES_BEGIN,
  GET_HUDDLES_REPORTED_MESSAGES_FAIL,
  GET_HUDDLES_REPORTED_MESSAGES_SUCCESS,
  GET_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN,
  GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_BEGIN,
  GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_FAIL,
  GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_SUCCESS,
  GET_HUDDLES_REPORTED_USER_CATEGORIES_FAIL,
  GET_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS,
  GET_HUDDLES_SUCCESS,
  RESET_HUDDLES_ERRORS,
} from "./actionTypes";

export const INIT_STATE = {
  categories: {},
  categoryData: {},
  loading: false,
  error: "",
  huddles: {},
  huddleData: {},
  huddlereportedUserCategoryData: {},
  huddlereportedUserCategories: {},
  huddleMessages: "",
  participants: {},
  request: {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    page_count: 50,
    keyword: "",
    success: "",
  },
};

const Huddles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HUDDLES_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
        categories: {},
        huddleMessages: "",
      };
    case GET_HUDDLES_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
        error: "",
      };
    case GET_HUDDLES_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        categories: {},
        error: action.payload,
      };

    case GET_HUDDLES_CATEGORIES_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        categoryData: {},
        huddleMessages: "",
      };
    case GET_HUDDLES_CATEGORIES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryData: action.payload,
        error: "",
      };
    case GET_HUDDLES_CATEGORIES_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        categoryData: {},
        error: action.payload,
      };
    case EDIT_HUDDLES_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_HUDDLES_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case EDIT_HUDDLES_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_HUDDLES_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_HUDDLES_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case ADD_HUDDLES_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_HUDDLES_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_HUDDLES_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case DELETE_HUDDLES_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESET_HUDDLES_ERRORS: {
      return {
        ...state,
        error: "",
        success: "",
      };
    }

    case GET_HUDDLES_BEGIN:
      return {
        ...state,
        loading: true,
        huddles: {},
      };
    case GET_HUDDLES_SUCCESS:
      return {
        ...state,
        loading: false,
        huddles: action.payload,
        error: "",
      };
    case GET_HUDDLES_FAIL:
      return {
        ...state,
        loading: false,
        huddles: {},
        error: action.payload,
      };

    case GET_HUDDLES_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        huddleData: {},
        huddleMessages: "",
      };
    case GET_HUDDLES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        huddleData: action.payload,
        error: "",
      };
    case GET_HUDDLES_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        huddleData: {},
        error: action.payload,
      };

    case EDIT_HUDDLES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_HUDDLES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case EDIT_HUDDLES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_HUDDLES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_HUDDLES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: "Huddles have been deleted successfully!",
      };
    case DELETE_HUDDLES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BLOCK_HUDDLES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case BLOCK_HUDDLES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case BLOCK_HUDDLES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // huddle reported User categories

    case GET_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
        huddlereportedUserCategories: {},
      };
    case GET_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        huddlereportedUserCategories: action.payload,
        error: "",
      };
    case GET_HUDDLES_REPORTED_USER_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        huddlereportedUserCategories: {},
        error: action.payload,
      };

    case GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        huddlereportedUserCategoryData: {},
        huddleMessages: "",
      };
    case GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        huddlereportedUserCategoryData: action.payload,
        error: "",
      };
    case GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        huddlereportedUserCategoryData: {},
        error: action.payload,
      };
    case EDIT_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case EDIT_HUDDLES_REPORTED_USER_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case ADD_HUDDLES_REPORTED_USER_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
        huddlereportedUserCategories: {},
      };
    case GET_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        huddlereportedUserCategories: action.payload,
        error: "",
      };
    case GET_HUDDLES_REPORTED_USER_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        huddlereportedUserCategories: {},
        error: action.payload,
      };
    case DELETE_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: "Report Category Deleted Successfully",
      };
    case DELETE_HUDDLES_REPORTED_USER_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_HUDDLES_REPORTED_MESSAGES_BEGIN:
      return {
        ...state,
        loading: true,
        huddleMessages: "",
      };

    case GET_HUDDLES_REPORTED_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        huddleMessages: action.payload,
        error: "",
      };

    case GET_HUDDLES_REPORTED_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
        huddleMessages: "",
        error: action.payload,
      };

    case GET_HUDDLES_PARTICIPANTS_BEGIN:
      return {
        ...state,
        loading: true,
        participants: {},
      };

    case GET_HUDDLES_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        participants: action.payload,
        error: "",
      };

    case GET_HUDDLES_PARTICIPANTS_FAIL:
      return {
        ...state,
        loading: false,
        participants: {},
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Huddles;
