import DateRangeSelector from "../../../components/UI/DateRangeSelector";
import moment from "moment";
import React, { useState } from "react";
import { Modal } from "reactstrap";
import { Dropdown } from "semantic-ui-react";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useTranslation } from "react-i18next";

const PayoutStatementFilter = ({
  countryFilter,
  setRequest,
  request,
  dashboardFilter,
  no_date_range_label,
}) => {
  const { t } = useTranslation();
  const initDateRange = [
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ];
  const [modal_center, setmodal_center] = useState(false);
  const [dateRangestate, setDateRangestate] = useState(initDateRange);

  const getDateRangeString = () => {
    return (
      moment(dateRangestate[0].startDate).format("DD-MM-YYYY") +
      " - " +
      moment(dateRangestate[0].endDate).format("DD-MM-YYYY")
    );
  };

  const tog_center = () => {
    setmodal_center(!modal_center);
  };

  const handleApply = (filterData) => {
    if (dashboardFilter && "year_filter" in request) {
      delete request.year_filter;
    }
    setRequest({
      ...request,
      ...filterData,
    });
  };

  const onClickOk = () => {
    handleApply({
      start_date: moment(dateRangestate[0].startDate).format("DD-MM-YYYY"),
      end_date: moment(dateRangestate[0].endDate).format("DD-MM-YYYY"),
    });
    tog_center();
  };

  return (
    <>
      <div className="date-range-filter">
        {no_date_range_label ? null : (
          <span className="date-range-label">
            {t("AppInstallations.date_range")}
          </span>
        )}
        <input
          type="text"
          className="inputField_CSS installations-date-range"
          readOnly
          value={getDateRangeString()}
          onClick={tog_center}
        />
        <Modal
          isOpen={modal_center}
          toggle={tog_center}
          centered
          className="date-range-modal"
        >
          <DateRangeSelector
            handleDateRangeChange={(event) => {
              setDateRangestate(event);
            }}
            dateRange={dateRangestate}
            minDate={new Date("2021,Jan,1")}
          />
          <div className="modal-footer">
            <button
              className="btn btn-light"
              onClick={() => {
                setDateRangestate(initDateRange);
                tog_center();
              }}
              data-testid="component-filter-cancel"
            >
              {t("AppInstallations.cancel")}
            </button>
            <button
              className="btn bgcolor-btn-green"
              onClick={onClickOk}
              data-testid="component-filter-apply"
            >
              {t("AppInstallations.ok")}
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default PayoutStatementFilter;
