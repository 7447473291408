import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Datatable from "../../../components/UI/Tables/Datatable";
import { flaxPackageColumnData } from "../../../helpers/columns";
import {
  deleteFlaxPackages,
  getFlaxPackages,
} from "../../../store/flaxPackages/actions";
import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";
import { checkUserPermission } from "../../../helpers/utils";

const FlaxPackages = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["Active"],
    page_count: 50,
    keyword: "",
  };

  const [request, setRequest] = useState({ ...basicRequest });
  const data = useSelector((state) => state.flaxPackages);
  const loginData = useSelector((state) => state.Login);

  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;
  const isDeleteable = userPermissions?.delete || false;
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const history = useHistory();
  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Huddle Management",
          "Huddle categories"
        )
      );
    }
  }, [loginData]);

  const formatFlaxPackagesData = useCallback(() => {
    return (
      data?.flaxPackages?.length &&
      data?.flaxPackages?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        Flax: (
          <div data-tag="allowRowEvents" title={result?.flax}>
            {result?.flax}
          </div>
        ),
        USD: (
          <div data-tag="allowRowEvents" title={result?.usd}>
            {result?.points}
          </div>
        ),
        best_value: (
          <div data-tag="allowRowEvents" title={result?.best_value}>
            {result?.best_value && "Best Value"}
          </div>
        ),
        most_popular: (
          <div data-tag="allowRowEvents" title={result?.most_popular}>
            {result?.most_popular && "Most popular"}
          </div>
        ),
        android_id: (
          <div data-tag="allowRowEvents" title={result?.android_product_id}>
            {result?.android_product_id}
          </div>
        ),
        ios_id: (
          <div data-tag="allowRowEvents" title={result?.ios_product_id}>
            {result?.ios_product_id}
          </div>
        ),

        actions: (
          <div className="cust-table-actions-wrap">
            <button
              className=" color-violet action-btn"
              title="Edit"
              onClick={() =>
                history.push({
                  pathname: `/FLix-packages-edit/${result?.id}`,
                  state: { id: result.id },
                })
              }
              // disabled={!isEditable}
            >
              <img
                src={Edit_icon}
                alt="Edit"
                // style={{
                //   opacity: !isEditable ? "50%" : "",
                //   cursor: !isEditable ? "not-allowed" : "",
                // }}
              />
            </button>
            <button
              onClick={() => deletePromptHandler(result.id)}
              className=" color-red action-btn"
              title="Delete"
              // disabled={!isDeleteable}
            >
              <img
                src={Delete_icon}
                alt="Delete"
                // style={{
                //   opacity: !isDeleteable ? "50%" : "",
                //   cursor: !isDeleteable ? "not-allowed" : "",
                // }}
              />
            </button>
          </div>
        ),
      }))
    );
  }, [request, data.flaxPackages]);
  const flaxPackagesData = useMemo(
    () => formatFlaxPackagesData(),
    [formatFlaxPackagesData]
  );
  useEffect(() => {
    request && dispatch(getFlaxPackages(request));
  }, [request]);

  const okHandler = () => {
    dispatch(
      deleteFlaxPackages({
        id: promptMessage.id,
        callback: () => dispatch(getFlaxPackages(request)),
      })
    );
  };

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to delete 
      this Package ?`,
      type: "delete",
    });
  };
  const addNewClickHandler = () => {
    history.push("/FLix-packages-addNew");
  };
  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        // ref={pageWrapRef}
      >
        <Container fluid>
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="FLix Packages" />

            <div className="container-fluid">
              <Datatable
                tableID={"adminTable"}
                columns={flaxPackageColumnData}
                rows={flaxPackagesData}
                setRequest={setRequest}
                request={request}
                // totalRecords={data.flaxPackages?.length}
                search
                add
                // dropDown
                // languages={languages}
                addNewClickHandler={addNewClickHandler}
                // selectedLanguage={selectedLanguage}
                // setSelectedLanguage={setSelectedLanguage}
                addNewLabel={t("Admins.addAdmin.addBtn")}
                searchTerm={data?.request?.keyword}
                loading={data?.loading}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
        </Container>
        <ConfirmationAlert
          {...promptMessage}
          modal_center={showPromptPopUp}
          setmodal_center={setShowPromptPopUp}
          onOK={okHandler}
        />
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default FlaxPackages;
