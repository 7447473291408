import React from "react";
import { DatePicker } from "./DatePicker";

const ExpiryFilterPopup = ({ setRequest, request, dateFilter, dateType }) => {
  const onDateSelection = (date) => {
    setRequest({
      ...request,
      subscription_expiry_from_date: date.from_date,
      subscription_expiry_to_date: date.to_date,
    });
  };
  return (
    <div
      className="user-filterBtn-wrap mx-2"
      data-testid="component-filterPopup"
    >
      {dateFilter && (
        <DatePicker
          dateType={"Expiry Date Range"}
          OnDateSelection={onDateSelection}
        />
      )}
    </div>
  );
};

export default ExpiryFilterPopup;
