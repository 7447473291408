import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, UncontrolledAlert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";

import BackButton from "../../../../components/UI/BackButton";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../../components/UI/ConfirmationAlert";
import Loader from "../../../../components/Common/Loader";
import History from "../../../../components/UI/History";

import {
  getRolePermission,
  getPermissionsMasterData,
  addRole,
  updateRolePermissions,
  resetRolePermissions,
  setViewErrorMessage,
  resetMessage
} from "../../../../store/adminManagement/roleManagement/action";

import {resetUserPermissions} from "../../../../store/auth/login/actions"

const EditPermissions = () => {
  const match = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [role, setRole] = useState("");

  const [formChanged, setFormChanged] = useState(false);
  const [backToListing, setBackToListing] = useState(false);
  const [allUpdated, toggleFlag] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();

  let data = useSelector((state) => state.rolePermisions);

  const [featurePermissions, setFeaturePermissions] = useState({});
  const isEditMode = history?.location?.state?.isEdit;
  const masterData = data?.permissionsData?.permissions;

  useEffect(() => {
    dispatch(getPermissionsMasterData());
    dispatch(resetMessage());
  }, [dispatch]);

  useEffect(() => {
    if (match.id && isEditMode) {
      dispatch(getRolePermission(match.id));
    }
  }, [match.id, dispatch, isEditMode]);

  useEffect(() => {
    setRole(data?.rolePermissions?.role?.name);
  }, [data?.rolePermissions?.role]);

  useEffect(() => {
    backToListing &&
      history.push({
        pathname: `/admin-permissions`,
        state: { from: "keepFilter" },
      });
  }, [backToListing, match.id, history]);

  useEffect(() => {
    toggleFlag(false);
    setFeaturePermissions(data?.rolePermissions?.role);
  }, [data?.rolePermissions]);

  useEffect(() => {
    if (masterData && featurePermissions?.permissions && !allUpdated) {
      updateAll();
    }
  }, [masterData, featurePermissions, allUpdated]);

  useEffect(() => {
    //Building permissions object if the object doesnot exist
    let masterDataString;
    if (masterData && data?.rolePermissions && Object.keys(data?.rolePermissions).length === 0) {
      masterDataString = JSON.parse(
        JSON.stringify(masterData)
      );
      masterDataString &&
        Object.keys(masterDataString).forEach((module) => {
          masterDataString[module].forEach((featureMasterData) => {
            Object.keys(featureMasterData).forEach((field) => {
              if (featureMasterData[field] === true) {
                featureMasterData[field] = false;
              }
            });
          });
        });
      setFeaturePermissions({ "permissions": masterDataString });

    }
  }, [data?.permissionsData]);

  useEffect(() => {
    if (role) {
      setIsButtonDisabled(false);
    } else setIsButtonDisabled(true);
  }, [role]);

  const confirmBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };


  const redirectMethod = () => {
    dispatch(resetRolePermissions());
    setBackToListing(true);
  };

  const handleUpdate = () => {
    let permissionArray = [];
    let permissions = featurePermissions.permissions;
    Object.keys(permissions).forEach((feature) => {
      permissionArray.push(...permissions[feature]);
    });
    let addData = {
      data: {
        name: role,
        id: match.id,
        permissions: permissionArray,
      },
    };

    permissionArray = permissionArray.map((data) => ({
      "permissions_id": data.permissions_id,
      "create": data.create,
      "view": data.view,
      "delete": data.delete,
      "edit": data.edit
    }));

    let editData = {
      data: {
        name: role,
        id: match.id,
        permissions: permissionArray,
      },
    };

    let permissionsData = isEditMode ? editData : addData;

    if (role) {
      isEditMode
        ? dispatch(
          updateRolePermissions({
            ...permissionsData,
            callback: () => {
              dispatch(resetUserPermissions());
              redirectMethod();
            }
          }
          )
        )
        : dispatch(
          addRole({ ...permissionsData, callback: () => redirectMethod() })
        );
    }
  };

  const getPermissionsData = (id, access) => {
    let isChecked = false;
    let permissions = featurePermissions?.permissions;
    permissions &&
      Object.keys(permissions).forEach((Mainmodule) => {
        const obj =
          permissions &&
          permissions[Mainmodule] &&
          permissions[Mainmodule].find((permission) => permission.permissions_id === id);
        if (obj) {
          isChecked = obj[access];
        }
      });
    return isChecked;
  };

  const setPermissionsData = (id, access, value) => {
    dispatch(resetMessage());
    let permissions = featurePermissions?.permissions;
    permissions &&
      Object.keys(permissions).forEach((Mainmodule) => {
        const obj =
          permissions &&
          permissions[Mainmodule].find((permission) => permission.permissions_id === id);
        if (obj) {
          if (access === "all")
            obj.view = obj.edit = obj.delete = obj.create = value;
          else obj[access] = value;

          if (!obj.view && (obj.create || obj.edit || obj.delete)) {
            if (access === "view") {
              obj.create = obj.delete = obj.edit = false;
              dispatch(setViewErrorMessage());
            }
            else {
              obj.view = true;
              dispatch(setViewErrorMessage());
            }
          }
          const temp = { ...featurePermissions, ...obj }
          setFeaturePermissions(temp);
        }
      });
    updateAll();
  };


  /**Updating 'All' field according to the permissions*/
  const updateAll = () => {
    let permissions = featurePermissions?.permissions;
    if (Object.keys(masterData).length && Object.keys(permissions).length) {
      toggleFlag(true);
      Object.keys(masterData).forEach((module) => {
        masterData[module].forEach((featureMasterData) => {
          let allTrues = Object.keys(featureMasterData).filter((featureData) =>      //Finding valid fields from master data
            featureMasterData[featureData] === true);
          permissions[module].forEach((feature) => {
            if (featureMasterData.sub_module === feature.sub_module) {
              if (allTrues.every((activeCheck) => feature[activeCheck] === true)) {
                feature.all = true;
              }
              else {
                feature.all = false;
              }
            }
          });
        })
      });
      setFeaturePermissions({ ...featurePermissions, permissions });
    }
  }

  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };

  const okHandler = () => {
    if (promptMessage.type === "validation") {
      promptMessage.id.view = true;
    }
    promptMessage?.type === "back" && redirectMethod();
  }  
  return (
    <div
      className="page-content"
      data-testid="component-editRolePermissions"
      ref={pageWrapRef}
    >
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            return confirmBrowserBack();
          }
        }}
        when={formChanged}
      />
      <BackButton
        label={t("RolePermisions.Title")}
        handleClick={() => (formChanged ? confirmBack() : redirectMethod())}
      />
      <Breadcrumbs
        title="Tables"
        breadcrumbItem={
          isEditMode ? "Edit Role" : "Add New Role"
        }
      />
      <Col xl="12">
        {typeof data?.error === "string" && data?.error && (
          <div ref={warningAlertRef}>
            <UncontrolledAlert
              color="danger"
              className="alert-dismissible fade show"
              role="alert"
              isOpen= {data?.error}
              toggle={()=>dispatch(resetMessage())}
            >
              {data?.error}
            </UncontrolledAlert>
          </div>
        )}
        <Card className="mb-0 p-4">
          <div className="text-muted">
            <AvForm
              onValidSubmit={(e, v) => {
                handleUpdate(e, v);
              }}
            >
              <div className="col-md-5">
                <label htmlFor="role-input" className="col-md-8 col-form-label">
                  {t("RolePermisions.edit.role")}
                </label>
                <div className="col-md-8 dobWrapper">
                  <AvField
                    name="Role"
                    type="text"
                    className="form-control cursor-pointer"
                    value={role}
                    id="role-input"
                    onChange={(e) => {
                      setRole(e.target.value);
                      setFormChanged(true);
                    }}
                    placeholder={t("RolePermisions.edit.enterRole")}
                  />
                </div>
              </div>
              <table className="edit-permissions-table">
                {masterData &&
                  Object.keys(masterData).map(
                    (Mainmodule) => {
                      return (
                        <div className="edit-permission-item">
                          <div className="module-name">{Mainmodule}</div>
                          {masterData[Mainmodule].map(
                            (feature) => {
                              return (
                                <div className="module-permissions" id="module">
                                  <td className="feature-name-container">
                                    {feature.sub_module}
                                  </td>
                                  <td className="feature-permissions-container">
                                    <AvField
                                      name="featureAll"
                                      type="checkbox"
                                      className="form-control cursor-pointer permission-checkbox"
                                      value={getPermissionsData(
                                        feature.permissions_id,
                                        "all"
                                      )}
                                      checked={getPermissionsData(
                                        feature.permissions_id,
                                        "all"
                                      )}
                                      id={`all-input-${feature.permissions_id}`}
                                      onChange={(e) => {
                                        setPermissionsData(
                                          feature.permissions_id,
                                          "all",
                                          e.target.checked
                                        );
                                        setFormChanged(true);
                                      }}
                                    />
                                    <label
                                      htmlFor="all-input"
                                      className="col-form-label permission-label"
                                    >
                                      {t("RolePermisions.edit.all")}
                                    </label>
                                  </td>
                                  {feature.view ? (
                                    <td className="feature-permissions-container">
                                      <AvField
                                        name="featureView"
                                        type="checkbox"
                                        className="form-control cursor-pointer permission-checkbox"
                                        value={getPermissionsData(
                                          feature.permissions_id,
                                          "view"
                                        )}
                                        checked={getPermissionsData(
                                          feature.permissions_id,
                                          "view"
                                        )}
                                        id={`view-input-${feature.permissions_id}`}
                                        onChange={(e) => {
                                          setPermissionsData(
                                            feature.permissions_id,
                                            "view",
                                            e.target.checked
                                          );
                                          setFormChanged(true);
                                        }}
                                      />
                                      <label
                                        htmlFor="view-input"
                                        className="col-form-label permission-label"
                                      >
                                        {t("RolePermisions.edit.view")}
                                      </label>
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                  {feature.create ? (
                                    <td className="feature-permissions-container">
                                      <AvField
                                        name="featureCreate"
                                        type="checkbox"
                                        className="form-control cursor-pointer permission-checkbox"
                                        value={getPermissionsData(
                                          feature.permissions_id,
                                          "create"
                                        )}
                                        checked={getPermissionsData(
                                          feature.permissions_id,
                                          "create"
                                        )}
                                        id={`create-input-${feature.permissions_id}`}
                                        onChange={(e) => {
                                          setPermissionsData(
                                            feature.permissions_id,
                                            "create",
                                            e.target.checked
                                          );
                                          setFormChanged(true);
                                        }}
                                      />
                                      <label
                                        htmlFor="create-input"
                                        className=" col-form-label permission-label"
                                      >
                                        {t("RolePermisions.edit.create")}
                                      </label>
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                  {feature.edit ? (
                                    <td className="feature-permissions-container">
                                      <AvField
                                        name="featureEdit"
                                        type="checkbox"
                                        className="form-control cursor-pointer permission-checkbox"
                                        value={getPermissionsData(
                                          feature.permissions_id,
                                          "edit"
                                        )}
                                        checked={getPermissionsData(
                                          feature.permissions_id,
                                          "edit"
                                        )}
                                        id={`edit-input-${feature.permissions_id}`}
                                        onChange={(e) => {
                                          setPermissionsData(
                                            feature.permissions_id,
                                            "edit",
                                            e.target.checked
                                          );
                                          setFormChanged(true);
                                        }}
                                      />
                                      <label
                                        htmlFor="edit-input"
                                        className=" col-form-label permission-label"
                                      >
                                        {t("RolePermisions.edit.edit")}
                                      </label>
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                  {feature.delete ? (
                                    <td className="feature-permissions-container">
                                      <AvField
                                        name="featureDelete"
                                        type="checkbox"
                                        className="form-control cursor-pointer permission-checkbox"
                                        value={getPermissionsData(
                                          feature.permissions_id,
                                          "delete"
                                        )}
                                        checked={getPermissionsData(
                                          feature.permissions_id,
                                          "delete"
                                        )}
                                        id={`delete-input-${feature.permissions_id}`}
                                        onChange={(e) => {
                                          setPermissionsData(
                                            feature.permissions_id,
                                            "delete",
                                            e.target.checked
                                          );
                                          setFormChanged(true);
                                        }}
                                      />
                                      <label
                                        htmlFor="delete-input"
                                        className=" col-form-label permission-label"
                                      >
                                        {t("RolePermisions.edit.delete")}
                                      </label>
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    }
                  )}
                <tr></tr>
              </table>
              <div className="edit-admin-history-container">
                {isEditMode && (
                  <History
                    createdBy={featurePermissions?.created_by}
                    createdDate={featurePermissions?.time_created}
                    lastModifiedBy={featurePermissions?.modified_by}
                    lastModifiedDate={featurePermissions?.time_updated}
                  />
                )}
              </div>
              <div className="p-4 text-center">
                <button
                  type="submit"
                  className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                  disabled={isButtonDisabled}
                >
                  {isEditMode
                    ? t("Common.update")
                    : t("Common.save")}
                </button>
              </div>
            </AvForm>
          </div>
        </Card>
      </Col>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
        okText={"OK"}
        cancelText={"CANCEL"}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default EditPermissions;
