import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  getCoinsPurchaseHistoryBegin,
  getCoinsPurchaseHistoryByUserIdBegin,
  getCoinsPurchaseHistoryByUserIdFail,
  getCoinsPurchaseHistoryByUserIdSuccess,
  getCoinsPurchaseHistoryFail,
  getCoinsPurchaseHistorySuccess,
  exportCoinsPurchaseSummaryBegin,
  exportCoinsPurchaseSummarySuccess,
  exportCoinsPurchaseSummaryFail,
} from "./actions";
import { API_GET_COINS_PURCHASE_HISTORY, COINS_PURCHASE_SUMMARY } from "../../helpers/url_helper";
import {
  GET_COINS_PURCHASE_HISTORY,
  GET_COINS_PURCHASE_HISTORY_BY_USER_ID,
  GET_COINS_PURCHASE_HISTORY_BY_USER_ID_FAIL,
  GET_COINS_PURCHASE_HISTORY_FAIL,
  EXPORT_COINS_PURCHASE_SUMMARY
} from "./actionTypes";
import { get, getFile } from "../../helpers/api_helper";

function* getCoinsPurchaseHistory(res) {
  try {
    yield put(getCoinsPurchaseHistoryBegin());
    const response = yield call(
      get,
      `/admin/coins/userpurchasedhistory`,
      res.request
    );

    yield put(getCoinsPurchaseHistorySuccess(response.result));
  } catch (error) {
    yield put(getCoinsPurchaseHistoryFail(error));
  }
}
function* getCoinsPurchaseHistoryByUserId({ request, userId }) {
  console.log("saga-id", userId);
  try {
    yield put(getCoinsPurchaseHistoryByUserIdBegin());
    const response = yield call(
      get,
      `/admin/coins/userpurchasedhistory?user_id=${userId}`,
      request
    );

    yield put(getCoinsPurchaseHistoryByUserIdSuccess(response.result));
  } catch (error) {
    yield put(getCoinsPurchaseHistoryByUserIdFail(error));
  }
}

function* exportCoinsPurchaseSummary({ saveAs, request }) {
  try {
    yield put(exportCoinsPurchaseSummaryBegin());
    const response = yield call(getFile, "/admin/coins/userpurchasedhistory", request, {
      headers: {
        "Content-Type": "text/csv",
      },
      responseType: "blob",
    })
    const blob = new Blob([response], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `coins_purchase_summary_export_${new Date().toLocaleString()}.csv`);
    yield put(exportCoinsPurchaseSummarySuccess())
  } catch (error) {
    yield put(exportCoinsPurchaseSummaryFail(error));
  }
}

function* coinsPurchaseSaga() {
  yield takeLatest(GET_COINS_PURCHASE_HISTORY, getCoinsPurchaseHistory);
  yield takeLatest(
    GET_COINS_PURCHASE_HISTORY_BY_USER_ID,
    getCoinsPurchaseHistoryByUserId
  );
  yield takeLatest(EXPORT_COINS_PURCHASE_SUMMARY, exportCoinsPurchaseSummary);
}

export default coinsPurchaseSaga;
