import React, { useState, useEffect } from "react";
import { Col, Card, Row, UncontrolledAlert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AvForm, AvField } from "availity-reactstrap-validation";

import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";
import Button from "../../../components/UI/Button";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";

import { EMAIL_REGEX } from "../../../helpers/constants";

import {
  getReservedUsernameDetails,
  updateReservedUsername,
  deleteReserveName,
} from "../../../store/usernames/reserveUserNames/action";
import {
  checkUsernameAvailability,
  resetError,
  resetErrorWithUsername,
} from "../../../store/usernames/allocateUserNames/action";
import { checkUserPermission } from "../../../helpers/utils";

const ReservedEdit = () => {
  const [warning, setWarning] = useState("");
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [formChanged, setFormChanged] = useState(false);
  const [backToListing, setBackToListing] = useState(false);
  const [userName, setUserName] = useState("");
  const loginData = useSelector((state) => state.Login);
  const [userPermissions,setUserPermissions] = useState({});
  const isDeleteable = userPermissions?.delete || false;
 
  const match = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const data = useSelector((state) => state.reserveName);
  const usernameData = useSelector((state) => state.allocatedUsername);

  useEffect(() => {   
    if(loginData?.rolePermissions?.role){
      setUserPermissions(checkUserPermission(loginData?.rolePermissions?.role,"Username Management","Reserve Username"))
    }
  }, [loginData]);

  useEffect(() => {
    dispatch(getReservedUsernameDetails(match.id));
  }, [match.id]);

  useEffect(() => {
    data?.resUserDetails?.username &&
      setUserName(data?.resUserDetails?.username);
  }, [data?.resUserDetails?.username]);

  useEffect(() => {
    dispatch(resetError());
    if (userName && new RegExp(EMAIL_REGEX).test(userName)) {
      setWarning(t("AllocateUserName.formatWarn"));
    } else setWarning("");
  }, [userName]);

  useEffect(() => {
    backToListing &&
      history.push({
        pathname: "/reserved-usernames",
        state: { from: "keepFilter" },
      });
  }, [backToListing]);

  const deletePromptHandler = (e) => {
    e.preventDefault();
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      title: "",
      content: t("ReserveUserName.deleteConfirm"),
      type: "delete",
    });
  };

  const okHandler = () => {
    promptMessage?.type === "delete"
      ? dispatch(
          deleteReserveName({
            data: match.id,
            callback: () => history.push("/reserved-usernames"),
          })
        )
      : redirectMethod();
  };

  const handleValidSubmit = (event, values) => {
    dispatch(
      updateReservedUsername({
        data: { ...values, id: match.id },
        callback: () =>
          history.push({
            pathname: "/reserved-usernames",
            state: { showMessage: true },
          }),
      })
    );
  };

  const confirmBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
  };

  const redirectMethod = () => {
    dispatch(resetErrorWithUsername());
    setBackToListing(true);
  };

  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };

  const checkAvailabiltyHandler = (e) => {
    e.preventDefault();
    dispatch(checkUsernameAvailability({ username: userName }));
  };

  return (
    <div className="page-content" data-testid="component-reservedUNEdit">
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            return confirmBrowserBack();
          }
        }}
        when={formChanged}
      />
      <BackButton
        label={t("ReserveUserName.Title")}
        handleClick={() => (formChanged ? confirmBack() : redirectMethod())}
      />
      <Breadcrumbs title="Tables" breadcrumbItem={t("ReserveUserName.edit")} />
      {data?.error && typeof data?.error === "string" ? (
        <UncontrolledAlert
          color="danger"
          className="alert-dismissible fade show"
          role="alert"
        >
          {data?.error}
        </UncontrolledAlert>
      ) : (
        data?.error?.username && (
          <UncontrolledAlert
            color="danger"
            className="alert-dismissible fade show"
            role="alert"
          >
            {data?.error?.username?.[0]}
          </UncontrolledAlert>
        )
      )}
      <AvForm
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <Col xl="12">
          <Card className="p-4 pos-relative">
            <button
              className="action-buttons reserved_UN_delete"
              onClick={(e) => deletePromptHandler(e)}
              title="Delete"
              disabled={!isDeleteable}
            >
              <img src={Delete_icon} style={{'opacity':!isDeleteable ? "50%":""}}/>             
              <span className="color-red">DELETE</span>
            </button>
            <Row className="col-md-12 p-4">
              <div className="col-md-4">
                <p>
                  {t("ReserveUserName.linkedTo")}:{" "}
                  {data?.resUserDetails?.email ||
                    data?.resUserDetails?.phone ||
                    "NA"}
                </p>
              </div>

              <div className="col-md-8">
                <div className="checkAvailabilityWrap">
                  <AvField
                    value={data?.resUserDetails?.username || userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                      setFormChanged(true);
                    }}
                    name="username"
                    label={
                      <>
                        {t("ReserveUserName.username")}
                        <span className="mandatory">*</span>
                      </>
                    }
                    placeholder={t("ReserveUserName.usernamePlaceholder")}
                    validate={{
                      maxLength: {
                        value: 50,
                        errorMessage: t("ReserveUserName.maxlen"),
                      },
                    }}
                  />
                  <div>
                    <Button
                      buttonClass={
                        "btn waves-effect waves-light cust_no_shadow check_availability_button"
                      }
                      disableBtn={
                        !userName || data?.resUserDetails?.username === userName
                      }
                      handleClick={(e) => checkAvailabiltyHandler(e)}
                      label={t("Common.checkAvailable")}
                    />
                  </div>
                </div>
                {warning && (
                  <p className="bg-soft-orange warningSection errorAlerts">
                    <i className="fa fa-exclamation-triangle warningIcon"></i>
                    {warning}
                  </p>
                )}
                {usernameData?.availableError && (
                  <div className="color-red errorAlerts">
                    {usernameData?.availableError}
                  </div>
                )}
                {usernameData?.available && (
                  <div className="color-green errorAlerts">
                    {usernameData?.available}
                  </div>
                )}
              </div>
            </Row>
            <div className="mt-4 text-center">
              <Button
                disableBtn={
                  data?.resUserDetails?.username !== userName &&
                  (!usernameData?.available ||
                    usernameData?.availableUsername !== userName)
                }
                buttonClass={
                  "btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                }
                label={t("Common.update")}
              />
            </div>
          </Card>
        </Col>
      </AvForm>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true}/>}
    </div>
  );
};

export default ReservedEdit;
