import { call, put, takeLatest } from "@redux-saga/core/effects";
import { del, get, post, updateFormData } from "../../helpers/api_helper";
import { API_GET_PAYMENT_METHODS } from "../../helpers/url_helper"; // Assuming the URL helper for payment methods is used
import {
  addPaymentMethodBegin,
  addPaymentMethodFail,
  addPaymentMethodSuccess,
  deletePaymentMethodBegin,
  deletePaymentMethodFail,
  deletePaymentMethodSuccess,
  editPaymentMethodBegin,
  editPaymentMethodFail,
  editPaymentMethodSuccess,
  getPaymentMethodsBegin,
  getPaymentMethodsByIdBegin,
  getPaymentMethodsByIdFail,
  getPaymentMethodsByIdSuccess,
  getPaymentMethodsFail,
  getPaymentMethodsSuccess,
} from "./actions";
import {
  ADD_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  EDIT_PAYMENT_METHOD,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_BY_ID,
} from "./actionTypes";

function* getPaymentMethodsSaga(res) {
  try {
    yield put(getPaymentMethodsBegin());
    const response = yield call(
      get,
      `/admin/flax/sellflax/paymentmethods?language=${res.language}`
    );

    yield put(getPaymentMethodsSuccess(response.result));
  } catch (error) {
    yield put(getPaymentMethodsFail(error));
  }
}

function* addPaymentMethodSaga({ method, callback }) {
  try {
    yield put(addPaymentMethodBegin());
    const response = yield call(
      post,
      "/admin/flax/sellflax/paymentmethods",
      method
    );

    yield put(addPaymentMethodSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(addPaymentMethodFail(error));
  }
}

function* getPaymentMethodsByIdSaga({ id }) {
  try {
    yield put(getPaymentMethodsByIdBegin());
    const response = yield call(
      get,
      `/admin/flax/sellflax/paymentmethods/languages?payment_method_identifier=${id}`
    );
    yield put(getPaymentMethodsByIdSuccess(response.result));
  } catch (error) {
    yield put(getPaymentMethodsByIdFail(error));
  }
}

function* editPaymentMethodSaga({ params, id, methodList, callback }) {
  console.log("11", params);
  try {
    yield put(editPaymentMethodBegin());
    const response = yield call(
      updateFormData,
      `/admin/flax/sellflax/paymentmethods`,
      methodList
    );
    callback && callback();
    yield put(editPaymentMethodSuccess(response.message));
  } catch (error) {
    yield put(editPaymentMethodFail(error));
  }
}

function* deletePaymentMethodSaga({ id, callback }) {
  try {
    yield put(deletePaymentMethodBegin());
    const response = yield call(
      del,
      `/admin/flax/sellflax/paymentmethods?payment_method_identifier=${id}`
    );
    callback && callback();
    yield put(deletePaymentMethodSuccess(response.result));
  } catch (error) {
    yield put(deletePaymentMethodFail(error));
  }
}

function* paymentMethodSaga() {
  yield takeLatest(GET_PAYMENT_METHODS, getPaymentMethodsSaga);
  yield takeLatest(ADD_PAYMENT_METHOD, addPaymentMethodSaga);
  yield takeLatest(GET_PAYMENT_METHODS_BY_ID, getPaymentMethodsByIdSaga);
  yield takeLatest(EDIT_PAYMENT_METHOD, editPaymentMethodSaga);
  yield takeLatest(DELETE_PAYMENT_METHOD, deletePaymentMethodSaga);
}
export default paymentMethodSaga;
