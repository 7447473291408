import React from "react";
import { useTranslation } from "react-i18next";

import { convertToUAETime } from "../../helpers/utils";
import { DATE_WITH_TIME } from "../../helpers/constants";

const History = ({
  createdBy = "",
  createdDate = "",
  lastModifiedBy = "",
  lastModifiedDate = "",
}) => {
  const { t } = useTranslation();
  return (
    <div className="app-history col-md-6 " data-testid="appHistoryWrapper">
      <label
        htmlFor="common-history-component-label"
        className="col-md-4 col-form-label"
      >
        {t("History.Title")}
      </label>
      <div className="history-content-container" >
        <div className="history-content">
          {t("History.createdBy")}:&nbsp;
          {createdBy}
        </div>
        <div className="history-content">
          {t("History.createdDate")}:&nbsp;
          {createdDate && convertToUAETime(createdDate, DATE_WITH_TIME)}
        </div>
        <div className="history-content">
          {t("History.lastModifiedBy")}:&nbsp;
          {lastModifiedBy}
        </div>
        <div className="history-content">
          {t("History.lastModifiedDate")}:&nbsp;
          {lastModifiedDate && convertToUAETime(lastModifiedDate, DATE_WITH_TIME)}
        </div>
      </div>
    </div>
  );
};

export default History;
