import React,{useState,useEffect} from "react";
import {
  Col,
  Row,
  CardBody,
  CardTitle,
  CardImg,
  CardText,
} from "reactstrap";
import { useTranslation } from "react-i18next";

import defaultProfile from "../../../../assets/images/users/user.svg";

const UserDetail = ({ user , mode}) => {
  const [data,setData]=useState();

  const { t } = useTranslation();
  useEffect(() => {
    mode === 'edit' ? setData(user?.user) : setData(user) 
  }, [mode,user])
  return (
    <Row>
      <Col md={12}>
        <Row className="g-0 align-items-center">
          <Col md={2} className="allocated_username_imageWrap">
            <CardImg
              className="img-fluid newAllocate_userPic"
              src={data?.profile_photo || defaultProfile }
              alt="Card image cap"
            />
          </Col>
          <Col md={10}>
            <CardBody>
              <Row>
                <Col md={mode === 'edit' ? 4 : 6}>
                  <CardTitle className="h5">{t('AllocateUserName.addNew.name')}</CardTitle>
                  <CardText>
                    <div className="text-muted">{data?.name}</div>
                  </CardText>
                </Col>
                <Col md={mode === 'edit' ? 4 : 6}>
                  <CardTitle className="h5">{t('AllocateUserName.UserName')}</CardTitle>
                  <CardText>
                    <div className="text-muted">{data?.username}</div>
                  </CardText>
                </Col>
                {mode === 'edit' && <Col md={mode === 'edit' ? 4 : 6}>
                    <CardTitle className="h5">Status</CardTitle>
                    <CardText>
                        <div className={user?.status === 'Rejected' ? 'badge bg-soft-red font-size-13' : user?.status === 'Pending' ? 'badge bg-soft-orange font-size-13': user?.status === 'Deleted' ? 'badge bg-soft-gray font-size-13': user?.status === 'Forcefully Changed' ? 'badge bg-soft-blue font-size-13':null}>
                            {user?.status}
                        </div>
                    </CardText>
                </Col>}
              </Row>
              <Row className="mt-4">
                <Col md={mode === 'edit' ? 4 : 6}>
                  <CardTitle className="h5">{t('AllocateUserName.EmailAddress')}</CardTitle>
                  <CardText>
                    <div className="text-muted">{data?.email}</div>
                  </CardText>
                </Col>
                <Col md={mode === 'edit' ? 4 : 6}>
                  <CardTitle className="h5">{t('AllocateUserName.column.phone')}</CardTitle>
                  <CardText>
                    <div className="text-muted">{data?.country_code ? `${data?.country_code} ${data?.phone}` : data?.phone}</div>
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UserDetail;
