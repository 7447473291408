// huddles categories

export const GET_HUDDLES_CATEGORIES = "GET_HUDDLES_CATEGORIES";
export const GET_HUDDLES_CATEGORIES_BEGIN = "GET_HUDDLES_CATEGORIES_BEGIN";
export const GET_HUDDLES_CATEGORIES_SUCCESS = "GET_HUDDLES_CATEGORIES_SUCCESS";
export const GET_HUDDLES_CATEGORIES_FAIL = "GET_HUDDLES_CATEGORIES_FAIL";

export const GET_HUDDLES_CATEGORIES_BY_ID = "GET_HUDDLES_CATEGORIES_BY_ID";
export const GET_HUDDLES_CATEGORIES_BY_ID_BEGIN =
  "GET_HUDDLES_CATEGORIES_BY_ID_BEGIN";
export const GET_HUDDLES_CATEGORIES_BY_ID_SUCCESS =
  "GET_HUDDLES_CATEGORIES_BY_ID_SUCCESS";
export const GET_HUDDLES_CATEGORIES_BY_ID_FAIL =
  "GET_HUDDLES_CATEGORIES_BY_ID_FAIL";

export const ADD_HUDDLES_CATEGORIES = "ADD_HUDDLES_CATEGORIES";
export const ADD_HUDDLES_CATEGORIES_BEGIN = "ADD_HUDDLES_CATEGORIES_BEGIN";
export const ADD_HUDDLES_CATEGORIES_SUCCESS = "ADD_HUDDLES_CATEGORIES_SUCCESS";
export const ADD_HUDDLES_CATEGORIES_FAIL = "ADD_HUDDLES_CATEGORIES_FAIL";

export const EDIT_HUDDLES_CATEGORIES = "EDIT_HUDDLES_CATEGORIES";
export const EDIT_HUDDLES_CATEGORIES_BEGIN = "EDIT_HUDDLES_CATEGORIES_BEGIN";
export const EDIT_HUDDLES_CATEGORIES_SUCCESS =
  "EDIT_HUDDLES_CATEGORIES_SUCCESS";
export const EDIT_HUDDLES_CATEGORIES_FAIL = "EDIT_HUDDLES_CATEGORIES_FAIL";

export const DELETE_HUDDLES_CATEGORIES = "DELETE_HUDDLES_CATEGORIES";
export const DELETE_HUDDLES_CATEGORIES_BEGIN =
  "DELETE_HUDDLES_CATEGORIES_BEGIN";
export const DELETE_HUDDLES_CATEGORIES_SUCCESS =
  "DELETE_HUDDLES_CATEGORIES_SUCCESS";
export const DELETE_HUDDLES_CATEGORIES_FAIL = "DELETE_HUDDLES_CATEGORIES_FAIL";

export const RESET_HUDDLES_ERRORS = "RESET_HUDDLES_ERRORS";

// huddles

export const GET_HUDDLES = "GET_HUDDLES";
export const GET_HUDDLES_STAT = "GET_HUDDLES_STAT";
export const GET_HUDDLES_BEGIN = "GET_HUDDLES_BEGIN";
export const GET_HUDDLES_SUCCESS = "GET_HUDDLES_SUCCESS";
export const GET_HUDDLES_FAIL = "GET_HUDDLES_FAIL";

export const GET_HUDDLES_BY_ID = "GET_HUDDLES_BY_ID";
export const GET_HUDDLES_BY_ID_BEGIN = "GET_HUDDLES_BY_ID_BEGIN";
export const GET_HUDDLES_BY_ID_SUCCESS = "GET_HUDDLES_BY_ID_SUCCESS";
export const GET_HUDDLES_BY_ID_FAIL = "GET_HUDDLES_BY_ID_FAIL";

export const EDIT_HUDDLES = "EDIT_HUDDLES";
export const EDIT_HUDDLES_BEGIN = "EDIT_HUDDLES_BEGIN";
export const EDIT_HUDDLES_SUCCESS = "EDIT_HUDDLES_SUCCESS";
export const EDIT_HUDDLES_FAIL = "EDIT_HUDDLES_FAIL";

export const DELETE_HUDDLES = "DELETE_HUDDLES";
export const DELETE_HUDDLES_BEGIN = "DELETE_HUDDLES_BEGIN";
export const DELETE_HUDDLES_SUCCESS = "DELETE_HUDDLES_SUCCESS";
export const DELETE_HUDDLES_FAIL = "DELETE_HUDDLES_FAIL";

export const BLOCK_HUDDLES = "BLOCK_HUDDLES";
export const BLOCK_HUDDLES_BEGIN = "BLOCK_HUDDLES_BEGIN";
export const BLOCK_HUDDLES_SUCCESS = "BLOCK_HUDDLES_SUCCESS";
export const BLOCK_HUDDLES_FAIL = "BLOCK_HUDDLES_FAIL";

// huddles reported User categories

export const GET_HUDDLES_REPORTED_USER_CATEGORIES =
  "GET_HUDDLES_REPORTED_USER_CATEGORIES";
export const GET_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN =
  "GET_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN";
export const GET_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS =
  "GET_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS";
export const GET_HUDDLES_REPORTED_USER_CATEGORIES_FAIL =
  "GET_HUDDLES_REPORTED_USER_CATEGORIES_FAIL";

export const GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID =
  "GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID";
export const GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_BEGIN =
  "GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_BEGIN";
export const GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_SUCCESS =
  "GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_SUCCESS";
export const GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_FAIL =
  "GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_FAIL";

export const ADD_HUDDLES_REPORTED_USER_CATEGORIES =
  "ADD_HUDDLES_REPORTED_USER_CATEGORIES";
export const ADD_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN =
  "ADD_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN";
export const ADD_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS =
  "ADD_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS";
export const ADD_HUDDLES_REPORTED_USER_CATEGORIES_FAIL =
  "ADD_HUDDLES_REPORTED_USER_CATEGORIES_FAIL";

export const EDIT_HUDDLES_REPORTED_USER_CATEGORIES =
  "EDIT_HUDDLES_REPORTED_USER_CATEGORIES";
export const EDIT_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN =
  "EDIT_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN";
export const EDIT_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS =
  "EDIT_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS";
export const EDIT_HUDDLES_REPORTED_USER_CATEGORIES_FAIL =
  "EDIT_HUDDLES_REPORTED_USER_CATEGORIES_FAIL";

export const DELETE_HUDDLES_REPORTED_USER_CATEGORIES =
  "DELETE_HUDDLES_REPORTED_USER_CATEGORIES";
export const DELETE_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN =
  "DELETE_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN";
export const DELETE_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS =
  "DELETE_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS";
export const DELETE_HUDDLES_REPORTED_USER_CATEGORIES_FAIL =
  "DELETE_HUDDLES_REPORTED_USER_CATEGORIES_FAIL";

export const GET_HUDDLES_REPORTED_MESSAGES = "GET_HUDDLES_REPORTED_MESSAGES";
export const GET_HUDDLES_REPORTED_MESSAGES_BEGIN =
  "GET_HUDDLES_REPORTED_MESSAGES_BEGIN";
export const GET_HUDDLES_REPORTED_MESSAGES_SUCCESS =
  "GET_HUDDLES_REPORTED_MESSAGES_SUCCESS";
export const GET_HUDDLES_REPORTED_MESSAGES_FAIL =
  "GET_HUDDLES_REPORTED_MESSAGES_FAIL";

export const GET_HUDDLES_PARTICIPANTS = "GET_HUDDLES_PARTICIPANTS";
export const GET_HUDDLES_PARTICIPANTS_BEGIN = "GET_HUDDLES_PARTICIPANTS_BEGIN";
export const GET_HUDDLES_PARTICIPANTS_SUCCESS =
  "GET_HUDDLES_PARTICIPANTS_SUCCESS";
export const GET_HUDDLES_PARTICIPANTS_FAIL = "GET_HUDDLES_PARTICIPANTS_FAIL";

export const DELETE_HUDDLES_LIST = "DELETE_HUDDLES_LIST";
