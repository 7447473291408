import {
  GET_ADMINS,
  GET_ADMINS_BEGIN,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAIL,
  GET_ADMIN_ROLES,
  GET_ADMIN_ROLES_BEGIN,
  GET_ADMIN_ROLES_SUCCESS,
  GET_ADMIN_ROLES_FAIL,
  STORE_ADMINS_REQUEST,
  DELETE_ADMIN,
  DELETE_ADMIN_BEGIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAIL,
  GET_ADMIN_DETAILS,
  GET_ADMIN_DETAILS_BEGIN,
  GET_ADMIN_DETAILS_SUCCESS,
  GET_ADMIN_DETAILS_FAIL,
  GET_SAMPLE_PASSWORD,
  GET_SAMPLE_PASSWORD_SUCCESS,
  GET_SAMPLE_PASSWORD_BEGIN,
  GET_SAMPLE_PASSWORD_FAIL,
  UPDATE_ADMIN_DETAILS,
  UPDATE_ADMIN_DETAILS_BEGIN,
  UPDATE_ADMIN_DETAILS_SUCCESS,
  UPDATE_ADMIN_DETAILS_FAIL,
  ADD_ADMIN,
  ADD_ADMIN_BEGIN,
  ADD_ADMIN_FAIL,
  ADD_ADMIN_SUCCESS,
  BLOCK_ADMIN,
  BLOCK_ADMIN_BEGIN,
  BLOCK_ADMIN_SUCCESS,
  BLOCK_ADMIN_FAIL,
  RESET_MESSAGES,
  CLEAR_PASSWORD,
} from "./actionTypes";

export const getAdminsData = (request) => ({
  type: GET_ADMINS,
  payload: { request },
});

export const getAdminsDataBegin = () => ({
  type: GET_ADMINS_BEGIN,
});

export const getAdminsDataSuccess = (adminData) => ({
  type: GET_ADMINS_SUCCESS,
  payload: adminData,
});

export const getAdminsDataFail = (error) => ({
  type: GET_ADMINS_FAIL,
  payload: error,
});
/* ------------------------------------------- */

export const storeRequest = (request) => ({
  type: STORE_ADMINS_REQUEST,
  payload: request,
});

/* ------------------------------------------- */
export const deleteAdminData = ({ data, callback }) => ({
  type: DELETE_ADMIN,
  data: data,
  callback: callback,
});

export const deleteAdminDataBegin = () => ({
  type: DELETE_ADMIN_BEGIN,
});

export const deleteAdminDataSuccess = (admins) => ({
  type: DELETE_ADMIN_SUCCESS,
  payload: admins,
});

export const deleteAdminDataFail = (error) => ({
  type: DELETE_ADMIN_FAIL,
  payload: error,
});
/* ------------------------------------------- */

export const getAdminDetails = (id) => ({
  type: GET_ADMIN_DETAILS,
  payload: id,
});

export const getAdminDetailBegin = () => ({
  type: GET_ADMIN_DETAILS_BEGIN,
});

export const getAdminDetailSuccess = (admin) => ({
  type: GET_ADMIN_DETAILS_SUCCESS,
  payload: admin,
});

export const getAdminDetailFail = (error) => ({
  type: GET_ADMIN_DETAILS_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const getSamplePassword = (id) => ({
  type: GET_SAMPLE_PASSWORD,
  payload: id,
});

export const getSamplePasswordBegin = () => ({
  type: GET_SAMPLE_PASSWORD_BEGIN,
});

export const getSamplePasswordSuccess = (password) => ({
  type: GET_SAMPLE_PASSWORD_SUCCESS,
  payload: password,
});

export const getSamplePasswordFail = (error) => ({
  type: GET_SAMPLE_PASSWORD_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const updateAdminDetails = (data, file, callback) => ({
  type: UPDATE_ADMIN_DETAILS,
  file: file,
  params: data,
  callback: callback,
});

export const updateAdminDetailsBegin = () => ({
  type: UPDATE_ADMIN_DETAILS_BEGIN,
});

export const updateAdminDetailsSuccess = (admin) => ({
  type: UPDATE_ADMIN_DETAILS_SUCCESS,
  payload: admin,
});

export const updateAdminDetailsFail = (error) => ({
  type: UPDATE_ADMIN_DETAILS_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const addAdmin = (data, file, callback) => ({
  type: ADD_ADMIN,
  file: file,
  params: data,
  callback: callback,
});

export const addAdminBegin = () => ({
  type: ADD_ADMIN_BEGIN,
});

export const addAdminSuccess = (admin) => ({
  type: ADD_ADMIN_SUCCESS,
  payload: admin,
});

export const addAdminFail = (error) => ({
  type: ADD_ADMIN_FAIL,
  payload: error,
});
/* ------------------------------------------- */
export const blockAdmin = ({ data, callback }) => ({
  type: BLOCK_ADMIN,
  data: data,
  callback: callback,
});

export const blockAdminBegin = () => ({
  type: BLOCK_ADMIN_BEGIN,
});

export const blockAdminSuccess = (admins) => ({
  type: BLOCK_ADMIN_SUCCESS,
  payload: admins,
});

export const blockAdminFail = (error) => ({
  type: BLOCK_ADMIN_FAIL,
  payload: error,
});
/* ------------------------------------------- */
export const resetMessage = () => ({
  type: RESET_MESSAGES,
});

export const clearPassword = () => ({
  type: CLEAR_PASSWORD,
});
