import { call, put, takeLatest } from "redux-saga/effects";

import {
  EDIT_TEMPLATE,
  UPDATE_TEMPLATE,
  GET_TEMPLATE_LISTING,
  ADD_TEMPLATE,
} from "./actionTypes";

import {
  editTemplateBegin,
  editTemplateSuccess,
  editTemplateFail,
  updateTemplateBegin,
  updateTemplateSuccess,
  updateTemplateFail,
  getTemplateListingBegin,
  getTemplateListingSuccess,
  getTemplateListingFail,
  addTemplateBegin,
  addTemplateSuccess,
  addTemplateFail,
} from "./actions";

import { get, post, update } from "../../../helpers/api_helper";
import { GET_TEMPLATE_ID, GET_TEMPLATES } from "../../../helpers/url_helper";

function* getTemplateListing({ payload }) {
  try {
    yield put(getTemplateListingBegin());
    const response = yield call(get, GET_TEMPLATES, payload);
    yield put(getTemplateListingSuccess(response.result));
  } catch (error) {
    yield put(getTemplateListingFail(error));
  }
}

function* editTemplate({ payload }) {
  try {
    yield put(editTemplateBegin());
    const response = yield call(get, GET_TEMPLATE_ID.replace("{id}", payload));
    yield put(editTemplateSuccess(response.result));
  } catch (error) {
    yield put(editTemplateFail(error));
  }
}
function* updateTemplate({ id, payload, callback }) {
  try {
    yield put(updateTemplateBegin());
    const response = yield call(update, GET_TEMPLATE_ID.replace("{id}", id), {
      ...payload,
    });
    callback && callback();

    yield put(updateTemplateSuccess(response?.message));
  } catch (error) {
    yield put(updateTemplateFail(error));
  }
}
function* addTemplate({ payload, callback }) {
  try {
    yield put(addTemplateBegin());
    const response = yield call(post, GET_TEMPLATES, payload);
    callback && callback();
    yield put(addTemplateSuccess(response?.message));
  } catch (error) {
    yield put(addTemplateFail(error));
  }
}

function* templateSaga() {
  yield takeLatest(GET_TEMPLATE_LISTING, getTemplateListing);
  yield takeLatest(EDIT_TEMPLATE, editTemplate);
  yield takeLatest(UPDATE_TEMPLATE, updateTemplate);
  yield takeLatest(ADD_TEMPLATE, addTemplate);
}

export default templateSaga;
