import {
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_BEGIN,
  ADD_PAYMENT_METHOD_FAIL,
  ADD_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_BEGIN,
  DELETE_PAYMENT_METHOD_FAIL,
  DELETE_PAYMENT_METHOD_SUCCESS,
  EDIT_PAYMENT_METHOD,
  EDIT_PAYMENT_METHOD_BEGIN,
  EDIT_PAYMENT_METHOD_FAIL,
  EDIT_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_BEGIN,
  GET_PAYMENT_METHODS_BY_ID,
  GET_PAYMENT_METHODS_BY_ID_BEGIN,
  GET_PAYMENT_METHODS_BY_ID_FAIL,
  GET_PAYMENT_METHODS_BY_ID_SUCCESS,
  GET_PAYMENT_METHODS_FAIL,
  GET_PAYMENT_METHODS_SUCCESS,
  RESET_PAYMENT_METHOD_ERRORS,
} from "./actionTypes";

export const getPaymentMethods = (language) => ({
  type: GET_PAYMENT_METHODS,
  language: language,
});

export const getPaymentMethodsBegin = () => ({
  type: GET_PAYMENT_METHODS_BEGIN,
});

export const getPaymentMethodsSuccess = (methods) => ({
  type: GET_PAYMENT_METHODS_SUCCESS,
  payload: methods,
});

export const getPaymentMethodsFail = (error) => ({
  type: GET_PAYMENT_METHODS_FAIL,
  payload: error,
});

export const addPaymentMethod = ({ method, callback }) => ({
  type: ADD_PAYMENT_METHOD,
  method: method,
  callback: callback,
});

export const addPaymentMethodBegin = () => ({
  type: ADD_PAYMENT_METHOD_BEGIN,
});

export const addPaymentMethodSuccess = (method) => ({
  type: ADD_PAYMENT_METHOD_SUCCESS,
  payload: method,
});

export const addPaymentMethodFail = (error) => ({
  type: ADD_PAYMENT_METHOD_FAIL,
  payload: error,
});

export const getPaymentMethodsById = (id) => ({
  type: GET_PAYMENT_METHODS_BY_ID,
  id: id,
});

export const getPaymentMethodsByIdBegin = () => ({
  type: GET_PAYMENT_METHODS_BY_ID_BEGIN,
});

export const getPaymentMethodsByIdSuccess = (method) => ({
  type: GET_PAYMENT_METHODS_BY_ID_SUCCESS,
  payload: method,
});

export const getPaymentMethodsByIdFail = (error) => ({
  type: GET_PAYMENT_METHODS_BY_ID_FAIL,
  payload: error,
});

export const editPaymentMethod = ({ params, method, callback }) => ({
  type: EDIT_PAYMENT_METHOD,
  params: params,
  // id: id,
  methodList: method,
  callback: callback,
});

export const editPaymentMethodBegin = () => ({
  type: EDIT_PAYMENT_METHOD_BEGIN,
});

export const editPaymentMethodSuccess = (method) => ({
  type: EDIT_PAYMENT_METHOD_SUCCESS,
  payload: method,
});

export const editPaymentMethodFail = (error) => ({
  type: EDIT_PAYMENT_METHOD_FAIL,
  payload: error,
});

export const deletePaymentMethod = ({ id, callback }) => ({
  type: DELETE_PAYMENT_METHOD,
  id: id,
  callback: callback,
});

export const deletePaymentMethodBegin = () => ({
  type: DELETE_PAYMENT_METHOD_BEGIN,
});

export const deletePaymentMethodSuccess = (method) => ({
  type: DELETE_PAYMENT_METHOD_SUCCESS,
  payload: method,
});

export const deletePaymentMethodFail = (error) => ({
  type: DELETE_PAYMENT_METHOD_FAIL,
  payload: error,
});

export const resetPaymentMethodErrors = () => ({
  type: RESET_PAYMENT_METHOD_ERRORS,
});
