/*RESERVED USERNAME */
export const GET_RES_USER_DATA = "GET_RES_USER_DATA";
export const GET_RES_USER_DATA_BEGIN = "GET_RES_USER_DATA_BEGIN";
export const GET_RES_USER_DATA_SUCCESS = "GET_RES_USER_DATA_SUCCESS";
export const GET_RES_USER_DATA_FAIL = "GET_RES_USER_DATA_FAIL";

export const ADD_RES_USERNAME = "ADD_RES_USERNAME";
export const ADD_RES_USERNAME_BEGIN = "ADD_RES_USERNAME_BEGIN";
export const ADD_RES_USERNAME_SUCCESS = "ADD_RES_USERNAME_SUCCESS";
export const ADD_RES_USERNAME_FAIL = "ADD_RES_USERNAME_FAIL";

export const DELETE_RES_USER = "DELETE_RES_USER";
export const DELETE_RES_USER_BEGIN = "DELETE_RES_USER_BEGIN";
export const DELETE_RES_USER_SUCCESS = "DELETE_RES_USER_SUCCESS";
export const DELETE_RES_USER_FAIL = "DELETE_RES_USER_FAIL";

export const GET_RES_USER_DETAILS = "GET_RES_USER_DETAILS";
export const GET_RES_USER_DETAILS_BEGIN = "GET_RES_USER_DETAILS_BEGIN";
export const GET_RES_USER_DETAILS_SUCCESS = "GET_RES_USER_DETAILS_SUCCESS";
export const GET_RES_USER_DETAILS_FAIL = "GET_RES_USER_DETAILS_FAIL";

export const UPDATE_RES_USER_DETAILS = "UPDATE_RES_USER_DETAILS";
export const UPDATE_RES_USER_DETAILS_BEGIN = "UPDATE_RES_USER_DETAILS_BEGIN";
export const UPDATE_RES_USER_DETAILS_SUCCESS = "UPDATE_RES_USER_DETAILS_SUCCESS";
export const UPDATE_RES_USER_DETAILS_FAIL = "UPDATE_RES_USER_DETAILS_FAIL";

export const STORE_USER_REQUEST = "STORE_USER_REQUEST";

export const IMPORT_RESERVED_USERNAMES = "IMPORT_RESERVED_USERNAMES";
export const IMPORT_RESERVED_USERNAMES_BEGIN = "IMPORT_RESERVED_USERNAMES_BEGIN";
export const IMPORT_RESERVED_USERNAMES_SUCCESS = "IMPORT_RESERVED_USERNAMES_SUCCESS";
export const IMPORT_RESERVED_USERNAMES_FAIL = "IMPORT_RESERVED_USERNAMES_FAIL";

export const RESET_MESSAGE = "RESET_MESSAGE";
