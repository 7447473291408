import { call, put, takeLatest, takeLeading } from "redux-saga/effects";

import { GET_APP_INSTALLATIONS, GET_INSTALLATION_COUNTRY, GET_INSTALLATION_REGION, SAVE_INSTALLATION_REGION, UPDATE_INSTALLATION_REGION } from "./actionTypes";

import {
  getInstallationDataBegin,
  getInstallationDataFail,
  getInstallationDataSuccess,
  getCountryDataBegin,
  getCountryDataFail,
  getCountryDataSuccess,
  getRegionDataBegin,
  getRegionDataFail,
  getRegionDataSuccess,
  updateRegionDataBegin,
  getRegionData,
  updateRegionDataFail,
  saveRegionDataBegin
} from "./action";

import { get,post,update } from "../../helpers/api_helper";
import {
  APP_INSTALLATIONS,
  INSTALLATION_COUNTRY,
  INSTALLATION_REGION,
} from "../../helpers/url_helper";

function* getAppInstallationData({ payload }) {
  try {
    yield put(getInstallationDataBegin());
    const response = yield call(get, APP_INSTALLATIONS, payload.request);
    yield put(getInstallationDataSuccess(response.result));
  } catch (error) {
    yield put(getInstallationDataFail(error));
  }
}

function* getInstallationCountryData() {
  try {
    yield put(getCountryDataBegin());
    const response = yield call(get, INSTALLATION_COUNTRY);
    yield put(getCountryDataSuccess(response.result));
  } catch (error) {
    yield put(getCountryDataFail(error));
  }
}

function* getInstallationRegionData() {
  try {
    yield put(getRegionDataBegin());
    const response = yield call(get, INSTALLATION_REGION);
    yield put(getRegionDataSuccess(response.result));
  } catch (error) {
    yield put(getRegionDataFail(error));
  }
}

function *updateRegionData({payload}) {
  try {
    yield put(updateRegionDataBegin())
    yield call(update, `${INSTALLATION_REGION}/${payload.id}`, {countries: payload.countries});
    yield put(getRegionData())
  } catch (error) {
    yield put(updateRegionDataFail(error))
  }
}
function *saveRegionData({payload}) {
  try {
    yield put(saveRegionDataBegin())
    yield call(post, `${INSTALLATION_REGION}`, payload);
    yield put(getRegionData())
  } catch (error) {
    yield put(updateRegionDataFail(error))
  }
}

function* appInstallationsSaga() {
  yield takeLatest(GET_APP_INSTALLATIONS, getAppInstallationData);
  yield takeLatest(GET_INSTALLATION_COUNTRY, getInstallationCountryData);
  yield takeLatest(GET_INSTALLATION_REGION, getInstallationRegionData);
  yield takeLatest(UPDATE_INSTALLATION_REGION, updateRegionData);
  yield takeLatest(SAVE_INSTALLATION_REGION, saveRegionData);
}

export default appInstallationsSaga;
