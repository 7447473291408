import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_PAYOUT_REQUESTS,
  ADD_PAYOUT_STATUS,
  EDIT_PAYOUT_REQUEST,
  UPDATE_PAYOUT_REQUEST,
  GET_PAYOUT_STATUS,
  EDIT_PAYOUT_STATUS,
  UPDATE_PAYOUT_STATUS,
  DELETE_PAYOUT_STATUS,
  PROCESS_PAYMENT,
  GET_PAYOUT_STATEMENT,
  SEND_IN_REVIEW_EMAIL,
  SEND_IN_REVIEW_EMAIL_BEGIN,
  SEND_IN_REVIEW_EMAIL_SUCCESS,
  SEND_IN_REVIEW_EMAIL_FAIL,
  UPDATE_PAYOUT_PAYMENT_DETAILS,
} from "./actionTypes";
import {
  getPayoutRequestsBegin,
  getPayoutRequestsSuccess,
  getPayoutRequestsFail,
  editPayoutRequestBegin,
  editPayoutRequestSuccess,
  editPayoutRequestFail,
  updatePayoutRequestBegin,
  updatePayoutRequestSuccess,
  updatePayoutRequestFail,
  getPayoutStatusBegin,
  getPayoutStatusSuccess,
  getPayoutStatusFail,
  addPayoutStatusBegin,
  addPayoutStatusSuccess,
  addPayoutStatusFail,
  editPayoutStatusBegin,
  editPayoutStatusSuccess,
  editPayoutStatusFail,
  updatePayoutStatusBegin,
  updatePayoutStatusSuccess,
  updatePayoutStatusFail,
  deletePayoutstatusBegin,
  deletePayoutstatusSuccess,
  deletePayoutstatusFail,
  processPaymentBegin,
  processPaymentSuccess,
  processPaymentFail,
  getPayoutStatementBegin,
  getPayoutStatementSuccess,
  getPayoutStatementFail,
  sendInReviewEmailBegin,
  sendInReviewEmailSuccess,
  sendInReviewEmailFail,
  updatePayoutPaymentDetailsBegin,
  updatePayoutPaymentDetailsSuccess,
  updatePayoutPaymentDetailsFail,
} from "./action";
import {
  API_GET_PAYOUT_REQUESTS,
  API_EDIT_PAYOUT_REQUEST,
  API_UPDATE_PAYOUT_REQUEST,
  API_PROCESS_PAYMENT,
  API_GET_PAYOUT_STATUSES,
  API_ADD_PAYOUT_STATUS,
  API_EDIT_PAYOUT_STATUS,
  API_UPDATE_PAYOUT_STATUS,
  API_DELETE_PAYOUT_STATUS,
  API_GET_PAYOUT_STATEMENT,
} from "../../helpers/url_helper";

import {
  get,
  update,
  post,
  del,
  updateFormData,
} from "../../helpers/api_helper";
import { ta } from "date-fns/locale";
import { endOfDay } from "date-fns";

function* getPayoutStatus({ payload }) {
  try {
    yield put(getPayoutStatusBegin());
    const response = yield call(get, API_GET_PAYOUT_STATUSES, payload);
    yield put(getPayoutStatusSuccess(response.result));
  } catch (error) {
    yield put(getPayoutStatusFail(error));
  }
}

function* addPayoutStatus({ payload, callback }) {
  try {
    yield put(addPayoutStatusBegin());
    const response = yield call(post, API_ADD_PAYOUT_STATUS, payload);
    if (response) {
      callback && callback();
      yield put(addPayoutStatusSuccess(response.message));
    }
  } catch (error) {
    yield put(addPayoutStatusFail(error));
  }
}

function* editPayoutStatus({ payload }) {
  try {
    yield put(editPayoutStatusBegin());
    const response = yield call(
      get,
      API_EDIT_PAYOUT_STATUS.replace("{id}", payload)
    );
    yield put(editPayoutStatusSuccess(response.result));
  } catch (error) {
    yield put(editPayoutStatusFail(error));
  }
}

function* updatePayoutStatus({ payload, callback }) {
  try {
    yield put(updatePayoutStatusBegin());
    const response = yield call(
      update,
      API_UPDATE_PAYOUT_STATUS.replace("{id}", payload.id),
      payload
    );
    if (response) {
      callback && callback();
      yield put(updatePayoutStatusSuccess(response.message));
    }
  } catch (error) {
    yield put(updatePayoutStatusFail(error));
  }
}

function* deletePayoutStatus({ data, callback }) {
  try {
    yield put(deletePayoutstatusBegin());
    const response = yield call(
      del,
      API_DELETE_PAYOUT_STATUS.replace("{id}", data)
    );
    if (response) {
      callback && callback();
      yield put(deletePayoutstatusSuccess(response.message));
    }
  } catch (error) {
    yield put(deletePayoutstatusFail(error));
  }
}

function* getPayoutStatement({ payload }) {
  try {
    yield put(getPayoutStatementBegin());
    const response = yield call(
      get,
      API_GET_PAYOUT_STATEMENT.replace("{id}", payload.id),
      payload
    );
    yield put(getPayoutStatementSuccess(response.result));
  } catch (error) {
    yield put(getPayoutStatementFail(error));
  }
}

function* getPayoutRequests({ payload }) {
  try {
    yield put(getPayoutRequestsBegin());
    const response = yield call(get, API_GET_PAYOUT_REQUESTS, payload);
    yield put(getPayoutRequestsSuccess(response.result));
  } catch (error) {
    yield put(getPayoutRequestsFail(error));
  }
}

function* editPayoutRequest({ payload }) {
  try {
    yield put(editPayoutRequestBegin());
    const response = yield call(
      get,
      API_EDIT_PAYOUT_REQUEST.replace("{id}", payload)
    );
    yield put(editPayoutRequestSuccess(response.result));
  } catch (error) {
    yield put(editPayoutRequestFail(error));
  }
}

function* updatePayoutRequest({ payload, callback }) {
  try {
    yield put(updatePayoutRequestBegin());
    const response = yield call(
      update,
      API_UPDATE_PAYOUT_REQUEST.replace("{id}", payload.id),
      payload
    );
    if (response) {
      callback && callback();
      yield put(updatePayoutRequestSuccess(response.message));
    }
  } catch (error) {
    yield put(updatePayoutRequestFail(error));
  }
}

function* processPayment({ payload, callback }) {
  try {
    yield put(processPaymentBegin());
    const response = yield call(post, API_PROCESS_PAYMENT, payload);
    if (response) {
      callback && callback();
      yield put(processPaymentSuccess(response));
    }
  } catch (err) {
    yield put(processPaymentFail(err));
  }
}
function* sendInReviewEmail({ payload, callback }) {
  try {
    yield put(sendInReviewEmailBegin());
    const response = yield call(post, "admin/payout/requests/emails", payload);
    if (response) {
      callback && callback();
      yield put(sendInReviewEmailSuccess(response));
    }
  } catch (err) {
    yield put(sendInReviewEmailFail(err));
  }
}

function* updatePayoutPaymentDetails({ payload, id, callback }) {
  try {
    yield put(updatePayoutPaymentDetailsBegin())
    const response = yield call(updateFormData, `/admin/payout/requests/${id}/edit-user-details`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    if (response) {
      callback && callback();
      yield put(updatePayoutPaymentDetailsSuccess());
    }
  } catch (err) {
    yield put(updatePayoutPaymentDetailsFail(err))
  }
}

function* payoutManagementSaga() {
  yield takeLatest(GET_PAYOUT_REQUESTS, getPayoutRequests);
  yield takeLatest(EDIT_PAYOUT_REQUEST, editPayoutRequest);
  yield takeLatest(UPDATE_PAYOUT_REQUEST, updatePayoutRequest);
  yield takeLatest(PROCESS_PAYMENT, processPayment);
  yield takeLatest(GET_PAYOUT_STATUS, getPayoutStatus);
  yield takeLatest(ADD_PAYOUT_STATUS, addPayoutStatus);
  yield takeLatest(EDIT_PAYOUT_STATUS, editPayoutStatus);
  yield takeLatest(UPDATE_PAYOUT_STATUS, updatePayoutStatus);
  yield takeLatest(DELETE_PAYOUT_STATUS, deletePayoutStatus);
  yield takeLatest(GET_PAYOUT_STATEMENT, getPayoutStatement);
  yield takeLatest(SEND_IN_REVIEW_EMAIL, sendInReviewEmail);
  yield takeLatest(UPDATE_PAYOUT_PAYMENT_DETAILS, updatePayoutPaymentDetails)
}

export default payoutManagementSaga;
