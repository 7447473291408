import {
  STORE_PAYOUT_REQUEST,
  GET_PAYOUT_REQUESTS,
  GET_PAYOUT_REQUESTS_BEGIN,
  GET_PAYOUT_REQUESTS_SUCCESS,
  GET_PAYOUT_REQUESTS_FAIL,
  EDIT_PAYOUT_REQUEST,
  EDIT_PAYOUT_REQUEST_BEGIN,
  EDIT_PAYOUT_REQUEST_SUCCESS,
  EDIT_PAYOUT_REQUEST_FAIL,
  UPDATE_PAYOUT_REQUEST,
  UPDATE_PAYOUT_REQUEST_BEGIN,
  UPDATE_PAYOUT_REQUEST_SUCCESS,
  UPDATE_PAYOUT_REQUEST_FAIL,
  PROCESS_PAYMENT,
  PROCESS_PAYMENT_BEGIN,
  PROCESS_PAYMENT_SUCCESS,
  PROCESS_PAYMENT_FAIL,
  GET_PAYOUT_STATUS,
  GET_PAYOUT_STATUS_BEGIN,
  GET_PAYOUT_STATUS_SUCCESS,
  GET_PAYOUT_STATUS_FAIL,
  ADD_PAYOUT_STATUS,
  ADD_PAYOUT_STATUS_BEGIN,
  ADD_PAYOUT_STATUS_SUCCESS,
  ADD_PAYOUT_STATUS_FAIL,
  EDIT_PAYOUT_STATUS,
  EDIT_PAYOUT_STATUS_BEGIN,
  EDIT_PAYOUT_STATUS_SUCCESS,
  EDIT_PAYOUT_STATUS_FAIL,
  UPDATE_PAYOUT_STATUS,
  UPDATE_PAYOUT_STATUS_BEGIN,
  UPDATE_PAYOUT_STATUS_SUCCESS,
  UPDATE_PAYOUT_STATUS_FAIL,
  DELETE_PAYOUT_STATUS,
  DELETE_PAYOUT_STATUS_BEGIN,
  DELETE_PAYOUT_STATUS_SUCCESS,
  DELETE_PAYOUT_STATUS_FAIL,
  GET_PAYOUT_STATEMENT,
  GET_PAYOUT_STATEMENT_BEGIN,
  GET_PAYOUT_STATEMENT_FAIL,
  GET_PAYOUT_STATEMENT_SUCCESS,
  SEND_IN_REVIEW_EMAIL,
  SEND_IN_REVIEW_EMAIL_BEGIN,
  SEND_IN_REVIEW_EMAIL_SUCCESS,
  SEND_IN_REVIEW_EMAIL_FAIL,
  UPDATE_PAYOUT_PAYMENT_DETAILS_BEGIN,
  UPDATE_PAYOUT_PAYMENT_DETAILS_FAIL,
  UPDATE_PAYOUT_PAYMENT_DETAILS,
  UPDATE_PAYOUT_PAYMENT_DETAILS_SUCCESS,
} from "./actionTypes";

/*---------------------------------------------*/

export const storeRequest = (request) => ({
  type: STORE_PAYOUT_REQUEST,
  payload: request,
});

/*---------------------------------------------*/

export const getPayoutRequests = (request) => ({
  type: GET_PAYOUT_REQUESTS,
  payload: request,
});

export const getPayoutRequestsBegin = () => ({
  type: GET_PAYOUT_REQUESTS_BEGIN,
});

export const getPayoutRequestsSuccess = (payout_status) => ({
  type: GET_PAYOUT_REQUESTS_SUCCESS,
  payload: payout_status,
});

export const getPayoutRequestsFail = (error) => ({
  type: GET_PAYOUT_REQUESTS_FAIL,
  payload: error,
});

/*---------------------------------------------*/

export const editPayoutRequest = (id) => ({
  type: EDIT_PAYOUT_REQUEST,
  payload: id,
});

export const editPayoutRequestBegin = () => ({
  type: EDIT_PAYOUT_REQUEST_BEGIN,
});

export const editPayoutRequestSuccess = (payout_request) => ({
  type: EDIT_PAYOUT_REQUEST_SUCCESS,
  payload: payout_request,
});

export const editPayoutRequestFail = (error) => ({
  type: EDIT_PAYOUT_REQUEST_FAIL,
  payload: error,
});

/*---------------------------------------------*/

export const updatePayoutRequest = ({ data, callback }) => ({
  type: UPDATE_PAYOUT_REQUEST,
  // file: file,
  payload: data,
  callback: callback,
});

export const updatePayoutRequestBegin = () => ({
  type: UPDATE_PAYOUT_REQUEST_BEGIN,
});

export const updatePayoutRequestSuccess = (response) => ({
  type: UPDATE_PAYOUT_REQUEST_SUCCESS,
  payload: response,
});

export const updatePayoutRequestFail = (error) => ({
  type: UPDATE_PAYOUT_REQUEST_FAIL,
  payload: error,
});

/*---------------------------------------------*/

export const processPayment = ({ formData, callback }) => ({
  type: PROCESS_PAYMENT,
  payload: formData,
  callback: callback,
});

export const processPaymentBegin = () => ({
  type: PROCESS_PAYMENT_BEGIN,
});

export const processPaymentSuccess = (response) => ({
  type: PROCESS_PAYMENT_SUCCESS,
  payload: response,
});

export const processPaymentFail = (error) => ({
  type: PROCESS_PAYMENT_FAIL,
  payload: error,
});

/*---------------------------------------------*/

export const getPayoutStatus = (request) => ({
  type: GET_PAYOUT_STATUS,
  payload: request,
});

export const getPayoutStatusBegin = () => ({
  type: GET_PAYOUT_STATUS_BEGIN,
});

export const getPayoutStatusSuccess = (payout_requests) => ({
  type: GET_PAYOUT_STATUS_SUCCESS,
  payload: payout_requests,
});

export const getPayoutStatusFail = (error) => ({
  type: GET_PAYOUT_STATUS_FAIL,
  payload: error,
});

/*---------------------------------------------*/

export const addPayoutStatus = ({ data, callback }) => ({
  type: ADD_PAYOUT_STATUS,
  payload: data,
  callback: callback,
});

export const addPayoutStatusBegin = () => ({
  type: ADD_PAYOUT_STATUS_BEGIN,
});

export const addPayoutStatusSuccess = (response) => ({
  type: ADD_PAYOUT_STATUS_SUCCESS,
  payload: response,
});

export const addPayoutStatusFail = (error) => ({
  type: ADD_PAYOUT_STATUS_FAIL,
  payload: error,
});

/*---------------------------------------------*/

export const editPayoutStatus = (id) => ({
  type: EDIT_PAYOUT_STATUS,
  payload: id,
});

export const editPayoutStatusBegin = () => ({
  type: EDIT_PAYOUT_STATUS_BEGIN,
});

export const editPayoutStatusSuccess = (payout_status) => ({
  type: EDIT_PAYOUT_STATUS_SUCCESS,
  payload: payout_status,
});

export const editPayoutStatusFail = (error) => ({
  type: EDIT_PAYOUT_STATUS_FAIL,
  payload: error,
});

/*---------------------------------------------*/

export const updatePayoutStatus = ({ data, callback }) => ({
  type: UPDATE_PAYOUT_STATUS,
  // file: file,
  payload: data,
  callback: callback,
});

export const updatePayoutStatusBegin = () => ({
  type: UPDATE_PAYOUT_STATUS_BEGIN,
});

export const updatePayoutStatusSuccess = (response) => ({
  type: UPDATE_PAYOUT_STATUS_SUCCESS,
  payload: response,
});

export const updatePayoutStatusFail = (error) => ({
  type: UPDATE_PAYOUT_STATUS_FAIL,
  payload: error,
});

/*---------------------------------------------*/

export const deletePayoutstatus = ({ data, callback }) => ({
  type: DELETE_PAYOUT_STATUS,
  data: data,
  callback: callback,
});

export const deletePayoutstatusBegin = () => ({
  type: DELETE_PAYOUT_STATUS_BEGIN,
});

export const deletePayoutstatusSuccess = (response) => ({
  type: DELETE_PAYOUT_STATUS_SUCCESS,
  payload: response,
});

export const deletePayoutstatusFail = (error) => ({
  type: DELETE_PAYOUT_STATUS_FAIL,
  payload: error,
});

/*---------------------------------------------*/

export const getPayoutStatement = (id) => ({
  type: GET_PAYOUT_STATEMENT,
  payload: id,
});

export const getPayoutStatementBegin = () => ({
  type: GET_PAYOUT_STATEMENT_BEGIN,
});

export const getPayoutStatementSuccess = (statement) => ({
  type: GET_PAYOUT_STATEMENT_SUCCESS,
  payload: statement,
});

export const getPayoutStatementFail = (error) => ({
  type: GET_PAYOUT_STATEMENT_FAIL,
  payload: error,
});

export const sendInReviewEmail = ({ data, callback }) => ({
  type: SEND_IN_REVIEW_EMAIL,
  payload: data,
  callback: callback,
});

export const sendInReviewEmailBegin = () => ({
  type: SEND_IN_REVIEW_EMAIL_BEGIN,
});

export const sendInReviewEmailSuccess = (response) => ({
  type: SEND_IN_REVIEW_EMAIL_SUCCESS,
  payload: response,
});

export const sendInReviewEmailFail = (error) => ({
  type: SEND_IN_REVIEW_EMAIL_FAIL,
  payload: error,
});

/*---------------------------------------------*/

export const updatePayoutPaymentDetails = ({ data, id, callback }) => ({
  type: UPDATE_PAYOUT_PAYMENT_DETAILS,
  payload: data,
  id: id,
  callback: callback,
})

export const updatePayoutPaymentDetailsBegin = () => ({
  type: UPDATE_PAYOUT_PAYMENT_DETAILS_BEGIN,
})

export const updatePayoutPaymentDetailsSuccess = () => ({
  type: UPDATE_PAYOUT_PAYMENT_DETAILS_SUCCESS,
})

export const updatePayoutPaymentDetailsFail = (error) => ({
  type: UPDATE_PAYOUT_PAYMENT_DETAILS_FAIL,
  payload: error,
})