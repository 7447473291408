import {
  GET_PODIUM_CAMERA_PURCHASE_HISTORY_BEGIN,
  GET_PODIUM_CAMERA_PURCHASE_HISTORY_FAIL,
  GET_PODIUM_CAMERA_PURCHASE_HISTORY_SUCCESS,
} from "./actionTypes";

export const INIT_STATE = {
  loading: true,
  error: "",
  podiumCameraPurchaseHistoryData: {},
  request: {
    sort: "purchase_date",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    page_count: 50,
    keyword: "",
    success: "",
  },
};

const podiumCameraPurchaseHistory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PODIUM_CAMERA_PURCHASE_HISTORY_BEGIN:
      return {
        ...state,
        loading: true,
        podiumCameraPurchaseHistoryData: {},
      };
    case GET_PODIUM_CAMERA_PURCHASE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        podiumCameraPurchaseHistoryData: action.payload,
        error: "",
      };
    case GET_PODIUM_CAMERA_PURCHASE_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        podiumCameraPurchaseHistoryData: {},
        error: action.payload,
      };

    default:
      return state;
  }
};

export default podiumCameraPurchaseHistory;
