import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, UncontrolledAlert } from "reactstrap";
import { useTranslation } from "react-i18next";

import BackButton from "../../../../components/UI/BackButton";
import ConfirmationAlert from "../../../../components/UI/ConfirmationAlert";
import Loader from "../../../../components/Common/Loader";

import Edit_icon from "../../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../../assets/icons/ebud-icons/Delete.svg";
import Block_icon from "../../../../assets/icons/ebud-icons/Block.svg";
import UnBlock_icon from "../../../../assets/icons/ebud-icons/Unblock.svg";
import Avatar from "../../../../assets/images/users/user.svg";

import {
  deleteAdminData,
  getAdminDetails,
  resetMessage,
  blockAdmin,
} from "../../../../../src/store/adminManagement/admins/action";

import { DATE_WITH_TIME } from "../../../../helpers/constants";
import { checkUserPermission, convertToUAETime } from "../../../../helpers/utils";

const AdminDetail = () => {
  const match = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [backToListing, setBackToListing] = useState(false);

  const pageWrapRef = useRef(null);
  const warningAlertRef = useRef(null);

  const data = useSelector((state) => state.admins);

  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isDeleteable = userPermissions?.delete || false;
  const isEditable = userPermissions?.edit || false;

   useEffect(() => {
    dispatch(getAdminDetails(match.id));
  }, [match.id, dispatch]);

  useEffect(() => {
    return () => {
     dispatch(resetMessage());
    }
  }, [])

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(loginData?.rolePermissions?.role, "Admin Management", "Admins")
      );
    }
  }, [loginData]);

  useEffect(() => {
    backToListing &&
      history.push({
        pathname:
          history?.location?.state?.from === "admin-details"
            ? `/admin-details/${match.id}`
            : `/admin-management`,
        state: { from: "keepFilter" },
      });
  }, [backToListing, match.id, history]);

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: t("Admins.deleteConfirm"),
      type: "delete",
    });
  };

  const blockPromptHandler = (id, status) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to ${status === "Blocked" ? "unblock" : "block"
        } this user?`,
      type: "block",
      status: status,
    });
  };

  const redirectMethod = () => {
   // dispatch(resetMessage());
    setBackToListing(true);
  };

  const okHandler = () => {
    promptMessage.type === "delete"
      ? dispatch(
        deleteAdminData({
          data: promptMessage.id,
          callback: () => history.push("/admin-management"),
        })
      )
      : promptMessage.status === "Blocked"
        ? dispatch(
          blockAdmin({
            data: { id: promptMessage.id, value: { Blocked: false } },
            callback: () => () => history.push("/admin-management"),
          })
        )
        : dispatch(
          blockAdmin({
            data: { id: promptMessage.id, value: { Blocked: true } },
            callback: () => history.push("/admin-management"),
          })
        );
  };

  return (
    <div
      className="page-content userDetailWrap"
      data-testid="component-AdminDetail"
      ref={pageWrapRef}
    ><BackButton
        label={
          history?.location?.state?.from === "admin-details"
            ? "Admin Details"
            : t("Admins.Title")
        }
        handleClick={() => redirectMethod()}
      />
      {(data?.error || data?.success) && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color={data?.error ? "danger" : "success"}
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error || data?.success}
              </UncontrolledAlert>
            </div>
          )}
      <Col xl="12">
        <Card className="mb-0 p-4 admin-detail table-responsive mt-4 text-muted">
          <div className="admin-detail-wrapper">
            <div className="admin-profilePic-wrapper">
              <label>
                <img
                  src={data?.adminDetails?.profile_photo || Avatar}
                  alt=""
                  className="avatar-xl rounded-circle img-thumbnail Admin-profile-pic"
                />
              </label>
            </div>
            <div className="admin-detail-column">
              <p className="admin-detail-name">  {data?.adminDetails?.name}  </p>
              <div className="mt-4">
                <h5 className="font-size-13">Created On</h5>
                <p className="mb-1">
                  {data?.adminDetails?.created_on && convertToUAETime(
                    data?.userDetails?.created_on,
                    DATE_WITH_TIME
                  )}
                </p>
              </div>
              <div className="mt-4">
                <h5 className="font-size-13">Email Address </h5>
                <p className="mb-1">{data?.adminDetails?.email}</p>
              </div>
            </div>
            <div className="admin-detail-secondary-column">
              <div className="mt-0">
                <p className="mb-1">
                  {data?.adminDetails?.status === "Active" ? (
                    <div className="badge bg-soft-success font-size-15">
                      Active
                    </div>
                  ) : data?.adminDetails?.status === "Deleted" ? (
                    <div className="badge bg-soft-warning font-size-15">Deleted</div>
                  ) : data?.adminDetails?.status === "Blocked" ? (
                    <div className="badge bg-soft-danger font-size-15">Blocked</div>
                  ) : null}
                </p>
              </div>
              <div className="mt-4">
                <h5 className="font-size-13">Mobile Number</h5>
                <p className="mb-1">
                  {data?.adminDetails?.country_code} {data?.adminDetails?.phone}
                </p>
              </div>
            </div>
          </div>
          <div className="cust-table-actions-wrap admin-details-actions">
            <button
              className=" color-violet action-btn"
              title="Edit"
              onClick={() =>
                history.push({
                  pathname: `/edit-admin-details/${data?.adminDetails?.id}`,
                  state: { from: "admin-details", isEdit: true },
                })
              }
              disabled={!isEditable}
            >
              <img src={Edit_icon} alt="Edit"  style={{ opacity: !isEditable ? "50%" : "" }}/>
            </button>
            <button
              onClick={() => blockPromptHandler(data?.adminDetails?.id, data?.adminDetails?.status)}
              className=" color-orange action-btn"
              title={data?.adminDetails?.status === "Blocked" ? "Unblock" : "Block"}
              disabled={!isEditable}
            >
              <img
                src={data?.adminDetails?.status === "Blocked" ? UnBlock_icon : Block_icon}
                alt={data?.adminDetails?.status === "Blocked" ? "Unblock" : "Block"}
                style={{ opacity: !isEditable ? "50%" : "" }}
              />
            </button>
            <button
              onClick={() => deletePromptHandler(data?.adminDetails?.id)}
              className=" color-red action-btn"
              title="Delete"
              disabled={data?.adminDetails?.status === "Deleted" || !isDeleteable}
            >
              <img src={Delete_icon} alt="Delete"
                style={{ opacity: data?.adminDetails?.status === "Deleted" || !isDeleteable ? "50%" : "" }}
              />
            </button>
          </div>
        </Card>
      </Col>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default AdminDetail;
