import {
  GET_POSTAT_SIGNED_COOKIES,
  GET_POSTAT_SIGNED_COOKIES_BEGIN,
  GET_POSTAT_SIGNED_COOKIES_FAILED,
  GET_POSTAT_SIGNED_COOKIES_SUCCESS,
} from "./actionTypes";

export const getPostatSignedCookies = () => ({
  type: GET_POSTAT_SIGNED_COOKIES,
});

export const getPostatSignedCookiesBegin = () => ({
  type: GET_POSTAT_SIGNED_COOKIES_BEGIN,
});

export const getPostatSignedCookiesSuccess = (documents) => ({
  type: GET_POSTAT_SIGNED_COOKIES_SUCCESS,
  payload: documents,
});

export const getPostatSignedCookiesFail = (error) => ({
  type: GET_POSTAT_SIGNED_COOKIES_FAILED,
  payload: error,
});
