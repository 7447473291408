import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import Loader from "../../../components/Common/Loader";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Datatable from "../../../components/UI/Tables/Datatable";
import {
  flaxPurchaseColumnData,
  postAtReportedPostColumnData,
} from "../../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../helpers/constants";
import { checkUserPermission, convertToUAETime } from "../../../helpers/utils";
import { getFlaxPurchaseHistory } from "../../../store/flaxPackages/actions";
import {
  deletePostat,
  getPostatReportedPosts,
  ignorePostatReportedPostat,
} from "../../../store/postatManagement/actions";
import Block_icon from "../../../assets/icons/ebud-icons/Block.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";

import { blockUser } from "../../../store/users/actions";
import { ImagePreview } from "../../../components/Common/imagePreview";

const ReportedPosts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const warningAlertRef = useRef();
  const [imgUrl, setImgUrl] = useState("");
  const [isOpen, setOpen] = useState(false);
  const basicRequest = {
    // sort: "transfered_date",
    // sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
  };
  const history = useHistory();
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const [request, setRequest] = useState({ ...basicRequest });

  const loginData = useSelector((state) => state.Login);

  const [userPermissions, setUserPermissions] = useState({});
  const isDeleteable = userPermissions?.delete || false;

  useEffect(() => {
    dispatch(getPostatReportedPosts(request));
  }, [request]);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Postat Management",
          "Reported posts"
        )
      );
    }
  }, [loginData]);

  const data = useSelector((state) => state?.ReportedPostatPosts);

  const previewImage = (url) => {
    setOpen(true);
    setImgUrl(url);
  };

  const formatPostatReportedPosts = useCallback(() => {
    return (
      data?.reportedPosts &&
      data?.reportedPosts?.postat_reports?.length > 0 &&
      data?.reportedPosts?.postat_reports?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        post_thumbnail: (
          <div
            onClick={(e) => {
              e.preventDefault();
              previewImage(
                result?.media_thumbnails?.length > 0 &&
                  result?.media_thumbnails?.[0]
              );
            }}
            title={"click to preview"}
          >
            <img
              width={28}
              src={
                result?.media_thumbnails?.length > 0 &&
                result?.media_thumbnails?.[0]
              }
              alt="user"
            />
          </div>
        ),
        post_owner_name: (
          // <div
          //   className="profileWrap"
          //   title={result?.post_owner_info?.name}
          //   data-tag="allowRowEvents"
          // >
          //   <div className="d-flex">
          //     <div data-tag="allowRowEvents">
          //       <img
          //         className="table_profileImg"
          //         src={result?.post_owner_info?.profile_url}
          //       />
          //     </div>

          //     <div className="mx-2 mt-3" title={result?.name}>
          //       {result?.post_owner_info?.name}{" "}
          //     </div>
          //   </div>
          // </div>
          <>
            <div
              data-tag="allowRowEvents"
              title={result?.post_owner_info?.name}
            >
              {result?.post_owner_info?.name}
            </div>
            {result?.post_owner_block_status && (
              <div className="badge bg-soft-secondary font-size-6 my-auto ">
                Blocked user
              </div>
            )}
          </>
        ),
        reported_count: (
          <div data-tag="allowRowEvents" title={result?.reported_count}>
            {result?.reported_count}
          </div>
        ),
        actions: (
          <div>
            <div className="cust-table-actions-wrap">
              {!result?.post_owner_block_status && (
                <button
                  onClick={() =>
                    blockPromptHandler(result?.post_owner, result.post_id)
                  }
                  className=" action-btn"
                  style={{
                    fontSize: "11px",
                    padding: "5px",
                    backgroundColor: "#dedfe0",
                    color: "#969696",
                    fontWeight: "bold",
                    opacity: !isDeleteable ? "50%" : "",
                    cursor: !isDeleteable ? "not-allowed" : "",
                  }}
                  title="Block Owner"
                  disabled={!isDeleteable}
                >
                  BLOCK AND DELETE
                </button>
              )}

              <button
                onClick={() => deletePromptHandler(result?.post_id)}
                className=" action-btn"
                title="Delete Post"
                disabled={!isDeleteable}
                style={{
                  fontSize: "11px",
                  padding: "5px",
                  backgroundColor: "#fadede",
                  fontWeight: "bold",
                  color: "#fa5f5f",
                  opacity: !isDeleteable ? "50%" : "",
                  cursor: !isDeleteable ? "not-allowed" : "",
                }}
              >
                DELETE
              </button>
              <button
                onClick={() => ignorePromptHandler(result?.post_id)}
                className=" action-btn"
                style={{
                  fontSize: "11px",
                  padding: "5px",
                  backgroundColor: "lightblue",
                  color: "#5384f5",
                  fontWeight: "bold",
                  // opacity: !isDeleteable ? "50%" : "",
                  // cursor: !isDeleteable ? "not-allowed" : "",
                }}
                title="Ignore Reports"
                // disabled={!isDeleteable}
              >
                IGNORE
              </button>
            </div>
          </div>
        ),
      }))
    );
  }, [request, data?.reportedPosts?.postat_reports, userPermissions]);
  const reportedPosts = useMemo(
    () => formatPostatReportedPosts(),
    [formatPostatReportedPosts]
  );

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to delete this Post from the App?`,
      type: "delete",
    });
  };

  const blockPromptHandler = (userId, postId) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      userId: userId,
      postId: postId,
      content: `Are you sure you want to block this user and delete the Post from the App? `,
      type: "block-del",
    });
  };
  const ignorePromptHandler = (postId) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      postId: postId,
      content: `Are you sure you want to Ignore this report without action? `,
      type: "ignore",
    });
  };

  const okHandler = () => {
    promptMessage.type === "delete"
      ? dispatch(
          deletePostat({
            id: promptMessage.id,
            callback: () => dispatch(getPostatReportedPosts()),
          })
        )
      : promptMessage.type === "block-del"
      ? dispatch(
          blockUser({
            data: { blocked: true },
            id: promptMessage.userId,
          })
        ) &&
        dispatch(
          deletePostat({
            id: promptMessage.postId,
            callback: () => dispatch(getPostatReportedPosts()),
          })
        )
      : dispatch(
          ignorePostatReportedPostat({
            ...request,
            postatId: promptMessage?.postId,
            callback: () => dispatch(getPostatReportedPosts()),
          })
        );
  };

  const tableRowClickHandler = (param) => {
    // history.push(`/payout-requests/${param.id}`);
    history.push({
      pathname: `/postat-reported-posts/${param.post_id}/reports`,
      state: { postId: param.post_id },
    });
  };

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        // id="userMgmtList"
        // ref={pageWrapRef}
      >
        <Container id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Reported Posts" />
            {data?.error && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color="danger"
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error}
                </UncontrolledAlert>
              </div>
            )}
            <div className="container-fluid">
              <Datatable
                tableID={"flaxTable"}
                columns={postAtReportedPostColumnData}
                rows={reportedPosts}
                setRequest={setRequest}
                request={request}
                // dateFilter
                totalRecords={data?.reportedPosts?.total}
                sp_search
                sp_search_param="postOwnerId"
                // dateType="Purchased Date Range"
                // add
                // dropDown
                // languages={languages}
                // addNewClickHandler={addNewClickHandler}
                // selectedLanguage={selectedLanguage}
                // setSelectedLanguage={setSelectedLanguage}
                // addNewLabel={t("Admins.addAdmin.addBtn")}
                // searchTerm={data?.request?.keyword}
                loading={data?.loading}
                tableRowClicked={(param) => tableRowClickHandler(param)}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
          <ImagePreview
            imgUrl={imgUrl}
            isOpen={isOpen}
            setOpen={setOpen}
            title="Reported Post"
          />
        </Container>
        <ConfirmationAlert
          {...promptMessage}
          modal_center={showPromptPopUp}
          setmodal_center={setShowPromptPopUp}
          onOK={okHandler}
        />

        {(data?.loading || data?.ignoreLoading) && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default ReportedPosts;
