import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Modal, ModalBody, Row } from "reactstrap";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import Datatable from "../../../../components/UI/Tables/Datatable";
import { flaxToCoinsConversionColummnData } from "../../../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../../helpers/constants";
import { convertTime, convertToUAETime } from "../../../../helpers/utils";

import Loader from "../../../../components/Common/Loader";
import { getFlaxCoinsConversionHistory } from "../../../../store/coinsFlaxConversion/actions";

const FlaxToCoinHistory = ({ toggle, modal, userId }) => {
  const dispatch = useDispatch();
  const basicRequest = {
    actions: "f2p",
    sort: "conversion_date",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 10,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const data = useSelector((state) => state.flaxCoinsConversion);
  console?.log("data", data);
  useEffect(() => {
    request &&
      userId &&
      dispatch(getFlaxCoinsConversionHistory({ request, userId }));
  }, [request, request?.action, modal]);

  const conversionData =
    data?.flaxCoinsConversionData?.flax_points_history?.length >= 0 &&
    data?.flaxCoinsConversionData?.flax_points_history?.map((result, index) => {
      // Check if sender_details and receiver_details are not null

      return {
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        conversion_date: (
          <div data-tag="allowRowEvents" title={result?.time_created}>
            {convertTime(result?.time_created)}
          </div>
        ),
      };
    });

  return (
    <div>
      <Modal
        centered
        isOpen={modal}
        toggle={toggle}
        style={{ minWidth: "1000px" }}
      >
        {/* <ModalHeader toggle={toggle}>Flax summary</ModalHeader> */}
        <ModalBody>
          <>
            <Container
              fluid
              id="userManagementWrapper"
              data-testid="userMgmtWrap"
            >
              <Row>
                <Breadcrumbs
                  title="Tables"
                  breadcrumbItem="FLiX To COiNS Conversion History"
                />

                <Datatable
                  // defaultSortField={request?.sort || "transfered_date"}
                  // defaultSortAsc={
                  //   request?.sort_order === "asc" ? true : false
                  // }
                  // dateFilter
                  // dateType="Transfered Date Range"
                  tableID={"flaxTable"}
                  columns={flaxToCoinsConversionColummnData}
                  rows={conversionData}
                  setRequest={setRequest}
                  request={request}
                  totalRecords={data?.flaxCoinsConversionData?.total}
                  // search
                  // searchTerm={data?.request?.keyword}
                  // reqstatusFilter={request?.status_filter}
                  loading={data?.loading}
                  tableCardClassName={"snoTable"}
                />
              </Row>
            </Container>
            {data?.loading && <Loader darkBg={true} />}
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default FlaxToCoinHistory;
