import React from "react";
import { Redirect } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import Users from "../pages/UserMangement";
import UserDetails from "../pages/UserMangement/TabSection";
import EditUserDetails from "../pages/UserMangement/EditUser/EditUserDetail";
import AllocateUsernames from "../pages/UsernameManagement/AllocateUsernames";
import ReservedUsernames from "../pages/UsernameManagement/ReservedUsernames";
import ReservedUsernamesAddNew from "../pages/UsernameManagement/ReservedUsernames/ReservedAddNew";
import ReservedEdit from "../pages/UsernameManagement/ReservedUsernames/ReservedEdit";
import AllocateAddNew from "../pages/UsernameManagement/AllocateUsernames/AddAllocation";
import EditAllocate from "../pages/UsernameManagement/AllocateUsernames/EditAllocation";
import LegalDocuments from "../pages/LegalDocuments";
import EditLegalDocuments from "../pages/LegalDocuments/EditLegalDocs";
import TemplateSetting from "../pages/Settings/Templates";
import EditTemplateSettings from "../pages/Settings/Templates/EditTemplate";
import AddTemplateSettings from "../pages/Settings/Templates/AddTemplate";

import AppSetting from "../pages/Settings/AppSetting";
import ReportedData from "../pages/ReportedData";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import Pages404 from "../pages/Utility/pages-404";
import NotificationsViewAll from "../pages/Notifications_ViewAll";
import AppInstallations from "../pages/Statistics";
import AdminManagement from "../pages/AdminManagement/Admins";
import EditAdminDetails from "../pages/AdminManagement/Admins/EditAdmin/EditAdminDetail";
import RoleManagement from "../pages/AdminManagement/RolesAndPermissions";
import EditRolePermissionDetails from "../pages/AdminManagement/RolesAndPermissions/EditPermissions/EditPermissions";
import AdminDetails from "../pages/AdminManagement/Admins/AdminDetail";
import SubscriptionSummary from "../pages/Statistics/Subscription";
import SuperStarsSelection from "../pages/Settings/SuperStarsSelection";

import Login from "../pages/Login";
import Pages403 from "../pages/Utility/pages-403";
import LoginNavigator from "../pages/Utility/login-navigator";
import AccountVerification from "../pages/UserMangement/AccountVerification";
import HuddleManagement, {
  PublicHuddles,
  Groups,
} from "../pages/HuddleManagement";
import HuddleCategories from "../pages/HuddleManagement/HuddleCategories";
import HuddleDetails from "../pages/HuddleManagement/HuddleDetails";
import AddCategory from "../pages/HuddleManagement/HuddleCategories/addCategory";
import EditCategory from "../pages/HuddleManagement/HuddleCategories/editCategory";
import ReportedUsersCategory from "../pages/ReportedData/ReportedUsersCategory";
import EditReportedUsersCategory from "../pages/ReportedData/ReportedUsersCategory/EditReportedUsersCategory";
import AddReportedUsersCategory from "../pages/ReportedData/ReportedUsersCategory/AddReportedUsersCategory";
import HuddlesReportedUsersCategories from "../pages/HuddleManagement/HuddlesReportedUsersCategories";
import AddHuddleReportedUserCategory from "../pages/HuddleManagement/HuddlesReportedUsersCategories/AddHuddleReportedUserCategories";
import EditHuddleReportedUserCategories from "../pages/HuddleManagement/HuddlesReportedUsersCategories/EditHuddleReportedUserCategories";
import HuddlesReportedMessages from "../pages/HuddleManagement/HuddlesReportedMessages";
import PushNotification from "../pages/Settings/PushNotification";
import Payouts from "../pages/Payouts/PayoutRequestManagement";
import EditPayoutRequestDetail from "../pages/Payouts/PayoutRequestManagement/EditPayoutRequestDetail";
import EditUserPaymentDetails from "../pages/Payouts/PayoutRequestManagement/EditUserPaymentDetails";

import PayoutStatusManagement from "../pages/Payouts/PayoutStatusManagement";
import EditPayoutStatusDetail from "../pages/Payouts/PayoutStatusManagement/EditPayoutStatusDetail";
import AddPayoutStatusDetail from "../pages/Payouts/PayoutStatusManagement/AddPayoutStatusDetail";
import PayoutStatement from "../pages/Payouts/PayoutRequestManagement/PayoutStatement";
import AppReviews from "../pages/AppReviews";
import PayoutRequest from "../pages/Payouts/PayoutRequest/index";
import FlaxTransferPurposes from "../pages/UserMangement/FlaxTransferPurposes";
import AddPurpose from "../pages/UserMangement/FlaxTransferPurposes/AddPurpose";
import FlaxTransfers from "../pages/UserMangement/FlaxTransfers";
import EditPurpose from "../pages/UserMangement/FlaxTransferPurposes/EditPurpose";
import BlockedUsers from "../pages/UserMangement/BlockedUsers";
import FlaxPackages from "../pages/UserMangement/FlaxPackages";
import FLaxPurchaseHistory from "../pages/UserMangement/FlaxPurchaseHistory";
import AddPackage from "../pages/UserMangement/FlaxPackages/AddPackage";
import EditPackage from "../pages/UserMangement/FlaxPackages/EditPackage";
import FlaxPurchaseHistory from "../pages/UserMangement/FlaxPurchaseHistory";
import GiftPackages from "../pages/GiftManagement/GiftPackages";
import AddGiftPackage from "../pages/GiftManagement/GiftPackages/AddGiftPackage";
import EditGiftPackages from "../pages/GiftManagement/GiftPackages/EditGiftPackages";
import GiftPurchaseHistory from "../pages/GiftManagement/GiftPurchaseHistory/GiftPurchaseHistory";
import GiftTransfers from "../pages/GiftManagement/GiftTransfers";
import PodiumManagement from "../pages/PodiumManagement";
import PodiumCategories from "../pages/PodiumManagement/PodiumCategories";
import AddPodiumCategory from "../pages/PodiumManagement/PodiumCategories/AddPodiumCategory";
import EditPodiumCategory from "../pages/PodiumManagement/PodiumCategories/EditPodiumCategory";
import FlashCategories from "../pages/FlashManagement/FlashCategories";
import AddFlashCategories from "../pages/FlashManagement/FlashCategories/AddCategories";
import EditFlashCategories from "../pages/FlashManagement/FlashCategories/EditCategories";
import AddAnnouncement from "../pages/Settings/PushNotification/AddAnnouncement";
import EditAnnouncement from "../pages/Settings/PushNotification/EditAnnouncement";
import PaymentMethod from "../pages/PaymentManagement/PaymentMethods";
import AddPaymentMethod from "../pages/PaymentManagement/PaymentMethods/AddPaymentMethod";
import EditPaymentMethod from "../pages/PaymentManagement/PaymentMethods/EditPaymentMethod";
import GlobalPaymentSystem from "../pages/PaymentManagement/GlobalPaymentManagement";
import AddPaymentSystem from "../pages/PaymentManagement/GlobalPaymentManagement/AddPaymentSystem";
import EditPaymentSystem from "../pages/PaymentManagement/GlobalPaymentManagement/EditPaymentSystem";
import ReportedPosts from "../pages/PostatManagement/PostatPostReports";
import GiftCategories from "../pages/GiftManagement/GiftCategories";
import AddGiftCategory from "../pages/GiftManagement/GiftCategories/AddGiftCategory";
import EditGiftCategory from "../pages/GiftManagement/GiftCategories/EditGiftCategory";
import PostReports from "../pages/PostatManagement/PostatPostReports/PostReports";
import PostatReportedComments from "../pages/PostatManagement/postatReportedComments";
import CommentReports from "../pages/PostatManagement/postatReportedComments/CommentReports";
import PostAtMusic from "../pages/PostatManagement/PostatMusic";
import AddMusic from "../pages/PostatManagement/PostatMusic/AddMusic";
import EditMusic from "../pages/PostatManagement/PostatMusic/EditMusic";
import ChallengeHistory from "../pages/PodiumManagement/Challenges";
import PodiumDetails from "../pages/PodiumManagement/PodiumDetails";
import AllFlashes from "../pages/FlashManagement/AllFlashes";
import AllPostats from "../pages/PostatManagement";
import ReportedFlash from "../pages/FlashManagement/ReportedFlash";
import FlashReportedComments from "../pages/FlashManagement/FlashReportedComments";
import FlashReports from "../pages/FlashManagement/ReportedFlash/FlashReports";
import FlashCommentReports from "../pages/FlashManagement/FlashReportedComments/FlashCommentReports";
import FlashBlockedUsers from "../pages/FlashManagement/FlashBlockedUsers";
import AppSubscriptions from "../pages/Statistics/AppSubscriptions";
import AnnouncementDetails from "../pages/Settings/PushNotification/AnnouncementDetails";
import FlixPurchaseSummary from "../pages/Statistics/FlixPurchaseSummary";
import CoinPurchaseSummary from "../pages/Statistics/CoinPurchaseSummary";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/users", component: Users },
  { path: "/FLix-transfers", component: FlaxTransfers },
  { path: "/user-details/:id", component: UserDetails },
  { path: "/edit-user-details/:id", component: EditUserDetails },
  { path: "/allocate-usernames", component: AllocateUsernames },
  { path: "/allocate-usernames-new", component: AllocateAddNew },
  { path: "/allocate-usernames-edit/:id", component: EditAllocate },
  { path: "/reserved-usernames", component: ReservedUsernames },
  { path: "/reserved-usernames-addNew", component: ReservedUsernamesAddNew },
  { path: "/reserved-usernames-edit/:id", component: ReservedEdit },
  { path: "/legal-documents", component: LegalDocuments },
  { path: "/edit-legal-documents/:id", component: EditLegalDocuments },
  { path: "/settings-template", component: TemplateSetting },
  { path: "/settings-template-edit/:id", component: EditTemplateSettings },
  { path: "/settings-template-add", component: AddTemplateSettings },
  { path: "/settings-app", component: AppSetting },
  { path: "/reported-data", component: ReportedData },
  { path: "/app-installations", component: AppInstallations },
  { path: "/admin-management", component: AdminManagement },
  { path: "/edit-admin-details/:id", component: EditAdminDetails },
  { path: "/admin-addNew", component: EditAdminDetails },
  { path: "/admin-permissions", component: RoleManagement },
  { path: "/edit-admin-permissions/:id", component: EditRolePermissionDetails },
  { path: "/admin-role-addNew", component: EditRolePermissionDetails },
  { path: "/admin-details/:id", component: AdminDetails },
  { path: "/subscriptions-summary", component: SubscriptionSummary },
  { path: "/app-subscriptions", component: AppSubscriptions },
  { path: "/FLiX-purchase-summary", component: FlixPurchaseSummary },
  { path: "/COiNS-purchase-summary", component: CoinPurchaseSummary },
  { path: "/account-verification", component: AccountVerification },
  { path: "/notifications", component: NotificationsViewAll },

  { path: "/superstars-suggestion", component: SuperStarsSelection },

  { path: "/huddles", component: HuddleManagement },

  { path: "/huddle-details/:id", component: HuddleDetails },

  { path: "/huddle-categories", component: HuddleCategories },
  { path: "/huddle-category-addNew", component: AddCategory },
  { path: "/huddle-category-edit/:id", component: EditCategory },

  { path: "/groups", component: HuddleManagement },
  { path: "/group-details/:id", component: HuddleDetails },

  { path: "/payout-requests", component: Payouts },
  { path: "/payout-requests/:id", component: EditPayoutRequestDetail },
  {
    path: "/payout-requests/:id/edit-payment",
    component: EditUserPaymentDetails,
  },
  { path: "/payout-status", component: PayoutStatusManagement },
  { path: "/payout-status/:id", component: EditPayoutStatusDetail },
  { path: "/payout-add-status", component: AddPayoutStatusDetail },
  { path: "/user-pp-statement/:id", component: PayoutStatement },
  { path: "/payout-new-requests", component: PayoutRequest },

  { path: "/app-review-requests", component: AppReviews },

  {
    path: "/reported-user-categories",
    component: ReportedUsersCategory,
  },
  {
    path: "/reported-user-category-edit/:id",
    component: EditReportedUsersCategory,
  },

  {
    path: "/reported-user-category-add",
    component: AddReportedUsersCategory,
  },

  {
    path: "/huddle-reported-user-categories",
    component: HuddlesReportedUsersCategories,
  },
  {
    path: "/huddle-reported-user-category-edit/:id",
    component: EditHuddleReportedUserCategories,
  },

  {
    path: "/huddle-reported-user-category-add",
    component: AddHuddleReportedUserCategory,
  },

  {
    path: "/huddle-reported-messages/:id",
    component: HuddlesReportedMessages,
  },

  {
    path: "/announcements",
    component: PushNotification,
  },
  {
    path: "/announcements-addNew",
    component: AddAnnouncement,
  },
  {
    path: "/announcements-edit/:id",
    component: EditAnnouncement,
  },
  {
    path: "/announcements-details/:id",
    component: AnnouncementDetails,
  },
  {
    path: "/FLix-transfer-purposes",
    component: FlaxTransferPurposes,
  },
  {
    path: "/FLix-transfer-purposes-addNew",
    component: AddPurpose,
  },
  {
    path: "/FLix-transfer-purpose-edit/:id",
    component: EditPurpose,
  },
  {
    path: "/blocked-users",
    component: BlockedUsers,
  },

  {
    path: "/FLix-packages",
    component: FlaxPackages,
  },
  {
    path: "/FLix-packages-addNew",
    component: AddPackage,
  },
  {
    path: "/FLix-packages-edit/:id",
    component: EditPackage,
  },
  {
    path: "/FLix-purchase-histroy",
    component: FlaxPurchaseHistory,
  },
  {
    path: "/gift-packages",
    component: GiftPackages,
  },
  {
    path: "/gift-packages-addNew",
    component: AddGiftPackage,
  },
  {
    path: "/gift-packages-edit/:id",
    component: EditGiftPackages,
  },
  {
    path: "/coins-purchase-history",
    component: GiftPurchaseHistory,
  },
  {
    path: "/gift-transfers",
    component: GiftTransfers,
  },
  {
    path: "/gift-categories",
    component: GiftCategories,
  },
  {
    path: "/gift-categories-addNew",
    component: AddGiftCategory,
  },
  {
    path: "/gift-categories-edit/:id",
    component: EditGiftCategory,
  },
  {
    path: "/podiums",
    component: PodiumManagement,
  },
  { path: "/podium-details/:id", component: PodiumDetails },
  {
    path: "/podium-categories",
    component: PodiumCategories,
  },
  {
    path: "/podium-categories-addNew",
    component: AddPodiumCategory,
  },

  { path: "/podium-category-edit/:id", component: EditPodiumCategory },
  {
    path: "/flash-categories",
    component: FlashCategories,
  },
  {
    path: "/flash-categories-addNew",
    component: AddFlashCategories,
  },
  { path: "/flash-category-edit/:id", component: EditFlashCategories },
  {
    path: "/all-flash",
    component: AllFlashes,
  },
  {
    path: "/flash-blocked-users",
    component: FlashBlockedUsers,
  },
  {
    path: "/reported-flash",
    component: ReportedFlash,
  },
  {
    path: "/reported-flash/:id",
    component: FlashReports,
  },
  // {
  //   path: "/flash-reports",
  //   component: FlashReports,
  // },
  {
    path: "/flash-reported-comments",
    component: FlashReportedComments,
  },
  {
    path: "/flash-reported-comments/:id",
    component: FlashCommentReports,
  },

  {
    path: "/payment-method",
    component: PaymentMethod,
  },
  {
    path: "/payment-method-addNew",
    component: AddPaymentMethod,
  },
  {
    path: "/payment-method-edit/:id",
    component: EditPaymentMethod,
  },
  {
    path: "/payment-forms",
    component: GlobalPaymentSystem,
  },
  {
    path: "/payment-forms-addNew",
    component: AddPaymentSystem,
  },
  {
    path: "/payment-forms-edit/:id/:country_id",
    component: EditPaymentSystem,
  },
  {
    path: "/all-postats",
    component: AllPostats,
  },
  {
    path: "/postat-reported-posts",
    component: ReportedPosts,
  },
  {
    path: "/postat-reported-posts/:id/reports",
    component: PostReports,
  },
  {
    path: "/postat-reported-comments",
    component: PostatReportedComments,
  },
  {
    path: "/postat-reported-comments/:id/reports",
    component: CommentReports,
  },
  {
    path: "/postat-music-library",
    component: PostAtMusic,
  },
  {
    path: "/postat-music-library-addNew",
    component: AddMusic,
  },
  {
    path: "/postat-music-library-edit",
    component: EditMusic,
  },
  {
    path: "/challenges-history",
    component: ChallengeHistory,
  },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: LoginNavigator },
  { path: "/pages-403", component: Pages403 },
  { path: "*", component: Pages404 },
  HuddleDetails,
];

const authRoutes = [
  { path: "/login", component: Login },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-404", component: Pages404 },
];

export { userRoutes, authRoutes };
