import { call, put, takeLatest, delay } from "redux-saga/effects";

import {
  DELETE_USER,
  GET_USER_DATA,
  BLOCK_USER,
  GET_USER_DETAILS,
  UPDATE_USER_DETAILS,
  LOGOUT_USER,
  EXPORT_USER_EMAIL,
  SEND_NOTIFICATION,
  UPDATE_NOTIFICATION,
  GET_BLOCKED_USERS,
} from "./actionTypes";
import {
  getUserDataBegin,
  getUserDataSuccess,
  getUserDataFail,
  deleteUserBegin,
  deleteUserSuccess,
  deleteUserFail,
  blockUserBegin,
  blockUserFail,
  getUserDetailBegin,
  getUserDetailSuccess,
  getUserDetailFail,
  updateUserDataBegin,
  updateUserDataSuccess,
  updateUserDataFail,
  blockUserSuccess,
  exportUserEmailSuccess,
  exportUserEmailBegin,
  exportUserEmailFail,
  sendNotificationBegin,
  sendNotificationSuccess,
  sendNotificationFail,
  getBlockedUsersBegin,
  getBlockedUsersSuccess,
  getBlockedUsersFail,
} from "./actions";

import {
  GET_USERS,
  USER_BY_ID,
  LOGOUT,
  GET_EMAIL_DATA,
} from "../../helpers/url_helper";

import {
  get,
  del,
  update,
  post,
  getFile,
  updateFormData,
} from "../../helpers/api_helper";
import { clearTree } from "./userMatrix/actions";

function* getUserData({ payload }) {
  try {
    yield put(getUserDataBegin());
    const response = yield call(get, GET_USERS, payload);
    console.log("responseUserdata", response);
    yield put(getUserDataSuccess(response.result));
  } catch (error) {
    yield put(getUserDataFail(error));
  }
}

function* deleteUser({ data, callback }) {
  try {
    yield put(deleteUserBegin());
    const response = yield call(del, USER_BY_ID.replace("{id}", data));
    callback && callback();
    yield put(deleteUserSuccess(response.result));
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

function* blockUser({ data, callback, id }) {
  try {
    yield put(blockUserBegin());
    let formData = new FormData();
    formData.append("data", JSON.stringify(data));
    const response = yield call(
      updateFormData,
      USER_BY_ID.replace("{id}", id),
      formData,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    callback && callback();
    yield put(getUserDetailSuccess(response.result));
    yield put(
      blockUserSuccess(
        data.blocked
          ? "User blocked successfully"
          : "user unblocked successfully"
      )
    );
  } catch (error) {
    yield put(blockUserFail(error));
  }
}

function* getUserDetails({ payload }) {
  try {
    yield put(getUserDetailBegin());
    yield put(clearTree());
    const response = yield call(get, USER_BY_ID.replace("{id}", payload));
    yield put(getUserDetailSuccess(response.result));
  } catch (error) {
    yield put(getUserDetailFail(error));
  }
}

function* updateUserData(payload) {
  try {
    yield put(updateUserDataBegin());
    let formData = new FormData();
    formData.append("data", JSON.stringify(payload.params));
    payload?.file && formData.append("file", payload.file);
    const response = yield call(
      updateFormData,
      USER_BY_ID.replace("{id}", payload.params.id),
      formData,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    yield put(updateUserDataSuccess(response.result));
    payload.callback && payload.callback();
  } catch (error) {
    yield put(updateUserDataFail(error));
  }
}
// Export User Details

function* exportUserEmail({ saveAs }) {
  try {
    yield put(exportUserEmailBegin());
    const response = yield call(getFile, GET_EMAIL_DATA, {
      headers: {
        "Content-Type": "text/csv",
      },
      responseType: "blob",
    });
    saveAs(response, `email_export_${new Date().toLocaleString()}.csv`);
    yield put(exportUserEmailSuccess());
  } catch (error) {
    yield put(exportUserEmailFail(error));
  }
}

function* logoutUser({ data, callback }) {
  try {
    yield call(post, LOGOUT, data);
    callback && callback();
  } catch (error) {
    callback && callback();
  }
}

function* sendNotification(res) {
  try {
    yield put(sendNotificationBegin());
    // const response = yield call(post, "/admin/users/notify", res?.data);
    const response = yield call(post, "/admin/users/notification", res?.data);
    yield put(sendNotificationSuccess(response.message));
    res?.callback && res?.callback();
  } catch (error) {
    yield put(sendNotificationFail(error));
  }
}

function* updateNotification(res) {
  try {
    yield put(sendNotificationBegin());
    // const response = yield call(post, "/admin/users/notify", res?.data);
    const response = yield call(
      update,
      `/admin/users/notification/${res?.data?.id}`,
      res?.data
    );
    yield put(sendNotificationSuccess(response.message));
    res?.callback && res?.callback();
  } catch (error) {
    yield put(sendNotificationFail(error));
  }
}
function* getBlockedUsers({ payload }) {
  try {
    yield put(getBlockedUsersBegin());
    if (payload?.request?.id) {
      yield call(blockUser, {
        id: payload?.request?.id,
        data: { blocked: false },
      });
    }

    const response = yield call(
      get,
      "/admin/leaderblockedusers",
      payload?.request
    );

    yield put(getBlockedUsersSuccess(response.result));
  } catch (error) {
    yield put(getBlockedUsersFail(error));
  }
}

function* userSaga() {
  yield takeLatest(GET_USER_DATA, getUserData);
  yield takeLatest(DELETE_USER, deleteUser);
  yield takeLatest(BLOCK_USER, blockUser);
  yield takeLatest(GET_USER_DETAILS, getUserDetails);
  yield takeLatest(SEND_NOTIFICATION, sendNotification);
  yield takeLatest(UPDATE_NOTIFICATION, updateNotification);
  yield takeLatest(UPDATE_USER_DETAILS, updateUserData);
  yield takeLatest(EXPORT_USER_EMAIL, exportUserEmail);
  yield takeLatest(LOGOUT_USER, logoutUser);
  yield takeLatest(GET_BLOCKED_USERS, getBlockedUsers);
}

export default userSaga;
