export const GET_COINS_PURCHASE_HISTORY = "GET_COINS_PURCHASE_HISTORY";
export const GET_COINS_PURCHASE_HISTORY_BEGIN =
  "GET_COINS_PURCHASE_HISTORY_BEGIN";
export const GET_COINS_PURCHASE_HISTORY_SUCCESS =
  "GET_COINS_PURCHASE_HISTORY_SUCCESS";
export const GET_COINS_PURCHASE_HISTORY_FAIL =
  "GET_COINS_PURCHASE_HISTORY_FAIL";

export const GET_COINS_PURCHASE_HISTORY_BY_USER_ID =
  "GET_COINS_PURCHASE_HISTORY_BY_USER_ID";
export const GET_COINS_PURCHASE_HISTORY_BY_USER_ID_BEGIN =
  "GET_COINS_PURCHASE_HISTORY_BY_USER_ID_BEGIN";

export const GET_COINS_PURCHASE_HISTORY_BY_USER_ID_SUCCESS =
  "GET_COINS_PURCHASE_HISTORY_BY_USER_ID_SUCCESS";
export const GET_COINS_PURCHASE_HISTORY_BY_USER_ID_FAIL =
  "GET_COINS_PURCHASE_HISTORY_BY_USER_ID_FAIL";

export const EXPORT_COINS_PURCHASE_SUMMARY = "EXPORT_COINS_PURCHASE_SUMMARY"
export const EXPORT_COINS_PURCHASE_SUMMARY_BEGIN = "EXPORT_COINS_PURCHASE_SUMMARY_BEGIN"
export const EXPORT_COINS_PURCHASE_SUMMARY_SUCCESS = "EXPORT_COINS_PURCHASE_SUMMARY_SUCCESS"
export const EXPORT_COINS_PURCHASE_SUMMARY_FAIL = "EXPORT_COINS_PURCHASE_SUMMARY_FAIL"
