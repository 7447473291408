import React, { useEffect } from "react";
import { Card, CardBody, Container, Media, Row } from "reactstrap";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Prompt, useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import Participants from "./Participants";
import { getHuddleReportedMessages } from "../../../store/huddles/actions";
import { checkUserPermission } from "../../../helpers/utils";
import Loader from "../../../components/Common/Loader";
import BackButton from "../../../components/UI/BackButton";

const ReportedMessages = () => {
  const params = useParams();

  const [message, setMessage] = useState("");
  const loginData = useSelector((state) => state.Login);
  const Data = useSelector((state) => state.Huddles);
  const match = useParams();
  //Local states
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [formChanged, setFormChanged] = useState(false);
  const [backToListing, setBackToListing] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [userPermissions, setUserPermissions] = useState({});
  const [messageId, setMessageId] = useState("");
  const [showComment, setShowComment] = useState(false);

  const participantsToggle = () => {
    setShowParticipants(!showParticipants);
    setMessageId("");
    setShowComment(false);
  };

  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    page_count: 50,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (!message) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [message]);

  // push page to contact-request listing

  useEffect(() => {
    request &&
      dispatch(getHuddleReportedMessages({ request: request, id: match.id }));
  }, [request]);

  useEffect(() => {
    backToListing &&
      history.push({
        pathname: `/contact-request`,
      });
  }, [backToListing, match.id, history]);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Huddle Management",
          "Huddle categories"
        )
      );
    }
  }, [loginData]);

  const redirectMethod = () => {
    setBackToListing(true);
  };

  // alert popup for browser back arrow click when input contain data
  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };

  return (
    <div className="page-content">
      <BackButton
        label="Huddles Details"
        handleClick={() =>
          history.push({
            pathname: `/huddle-details/${params?.id}`,
          })
        }
        data-tesid="component-userListBack"
      />
      <Container fluid id="userManagementWrapper">
        <Row>
          <Prompt
            message={(location, action) => {
              if (action === "POP") {
                return confirmBrowserBack();
              }
            }}
            when={formChanged}
          />

          <Breadcrumbs title="Lists" breadcrumbItem="Report Messages" />
          {Data?.huddleMessages?.messsage_reports?.length &&
            Data?.huddleMessages?.messsage_reports?.map((user) => (
              <Card className="mt-3">
                <CardBody className="mb-3 my-4 ">
                  <div>
                    <div className="d-flex justify-content-between">
                      <Media className="d-flex mb-1">
                        <div className="avatar-sm me-3">
                          <span
                            className={
                              "avatar-title rounded-circle bg-purple font-size-17"
                            }
                          >
                            {user?.user_details?.name
                              ?.slice(0, 1)
                              .toUpperCase() || "EP"}
                          </span>
                        </div>
                        <Media>
                          <h5 className="font-size-15 my-3">
                            {user?.user_details?.name}
                          </h5>
                        </Media>
                      </Media>
                    </div>
                    <h5 className="contact-sender-reply mt-3">
                      {user?.message}
                    </h5>
                    <div className="d-flex justify-content-between mt-2">
                      <p>
                        Reported by:
                        <a
                          className="participant-count cursor-pointer"
                          onClick={() => {
                            participantsToggle();
                            setMessageId(user?.message_id);
                          }}
                        >
                          {" "}
                          {user?.report_count} Participants
                        </a>
                      </p>
                      <p className=" mx-3">{user?.message_sent}</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))}
        </Row>
      </Container>

      <Participants
        modal_center={showParticipants}
        setmodal_center={setShowParticipants}
        toggle={participantsToggle}
        messageId={messageId}
        showComment={showComment}
        setShowComment={setShowComment}
      />

      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={redirectMethod}
      />
      {Data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default ReportedMessages;
