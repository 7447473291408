import {
  ADD_POSTAT_MUSIC,
  ADD_POSTAT_MUSIC_BEGIN,
  ADD_POSTAT_MUSIC_FAIL,
  ADD_POSTAT_MUSIC_SUCCESS,
  DELETE_POSTAT,
  DELETE_POSTAT_BEGIN,
  DELETE_POSTAT_FAIL,
  DELETE_POSTAT_MUSIC,
  DELETE_POSTAT_MUSIC_BEGIN,
  DELETE_POSTAT_MUSIC_FAIL,
  DELETE_POSTAT_MUSIC_SUCCESS,
  DELETE_POSTAT_REPORTED_COMMENT,
  DELETE_POSTAT_REPORTED_COMMENT_BEGIN,
  DELETE_POSTAT_REPORTED_COMMENT_FAIL,
  DELETE_POSTAT_REPORTED_COMMENT_SUCCESS,
  DELETE_POSTAT_SUCCESS,
  EDIT_POSTAT_MUSIC,
  EDIT_POSTAT_MUSIC_BEGIN,
  EDIT_POSTAT_MUSIC_FAIL,
  EDIT_POSTAT_MUSIC_SUCCESS,
  GET_ALL_POSTAT_POSTS,
  GET_ALL_POSTAT_POSTS_BEGIN,
  GET_ALL_POSTAT_POSTS_FAIL,
  GET_ALL_POSTAT_POSTS_SUCCESS,
  GET_COMMENT_REPORTS_BY_ID,
  GET_COMMENT_REPORTS_BY_ID_BEGIN,
  GET_COMMENT_REPORTS_BY_ID_FAIL,
  GET_COMMENT_REPORTS_BY_ID_SUCCESS,
  GET_POSTAT_MUSIC,
  GET_POSTAT_MUSIC_BEGIN,
  GET_POSTAT_MUSIC_BY_ID,
  GET_POSTAT_MUSIC_BY_ID_BEGIN,
  GET_POSTAT_MUSIC_BY_ID_FAIL,
  GET_POSTAT_MUSIC_BY_ID_SUCCESS,
  GET_POSTAT_MUSIC_FAIL,
  GET_POSTAT_MUSIC_SUCCESS,
  GET_POSTAT_REPORTED_COMMENTS,
  GET_POSTAT_REPORTED_COMMENTS_BEGIN,
  GET_POSTAT_REPORTED_COMMENTS_FAIL,
  GET_POSTAT_REPORTED_COMMENTS_SUCCESS,
  GET_POSTAT_REPORTED_POSTS,
  GET_POSTAT_REPORTED_POSTS_BEGIN,
  GET_POSTAT_REPORTED_POSTS_FAIL,
  GET_POSTAT_REPORTED_POSTS_SUCCESS,
  GET_POST_REPORTS_BY_ID,
  GET_POST_REPORTS_BY_ID_BEGIN,
  GET_POST_REPORTS_BY_ID_FAIL,
  GET_POST_REPORTS_BY_ID_SUCCESS,
  IGNORE_POSTAT_REPORTED_COMMENTS,
  IGNORE_POSTAT_REPORTED_COMMENTS_BEGIN,
  IGNORE_POSTAT_REPORTED_COMMENTS_FAIL,
  IGNORE_POSTAT_REPORTED_COMMENTS_SUCCESS,
  IGNORE_POSTAT_REPORTED_POSTAT,
  IGNORE_POSTAT_REPORTED_POSTAT_BEGIN,
  IGNORE_POSTAT_REPORTED_POSTAT_FAIL,
  IGNORE_POSTAT_REPORTED_POSTAT_SUCCESS,
  RESET_MUSIC_ERRORS,
  RESET_POSTAT_REPORTED_POSTS_ERRORS,
} from "./actionTypes";

export const getPostatReportedPosts = (request) => ({
  type: GET_POSTAT_REPORTED_POSTS,
  request: request,
});

export const getPostatReportedPostsBegin = () => ({
  type: GET_POSTAT_REPORTED_POSTS_BEGIN,
});

export const getPostatReportedPostsSuccess = (reportedPosts) => ({
  type: GET_POSTAT_REPORTED_POSTS_SUCCESS,
  payload: reportedPosts,
});

export const getPostatReportedPostsFail = (error) => ({
  type: GET_POSTAT_REPORTED_POSTS_FAIL,
  payload: error,
});

export const resetPostatErrors = () => ({
  type: RESET_POSTAT_REPORTED_POSTS_ERRORS,
});

export const getPostReportsById = (request) => ({
  type: GET_POST_REPORTS_BY_ID,
  request: request,
});

export const getPostReportsByIdBegin = () => ({
  type: GET_POST_REPORTS_BY_ID_BEGIN,
});

export const getPostReportsByIdSuccess = (postReports) => ({
  type: GET_POST_REPORTS_BY_ID_SUCCESS,
  payload: postReports,
});

export const getPostReportsByIdFail = (error) => ({
  type: GET_POST_REPORTS_BY_ID_FAIL,
  payload: error,
});

export const deletePostat = ({ id, callback }) => ({
  type: DELETE_POSTAT,
  id: id,
  callback: callback,
});

export const deletePostatBegin = () => ({
  type: DELETE_POSTAT_BEGIN,
});

export const deletePostatSuccess = (postReports) => ({
  type: DELETE_POSTAT_SUCCESS,
  payload: postReports,
});

export const deletePostatFail = (error) => ({
  type: DELETE_POSTAT_FAIL,
  payload: error,
});

export const getPostatReportedComments = (request) => ({
  type: GET_POSTAT_REPORTED_COMMENTS,
  request: request,
});

export const getPostatReportedCommentsBegin = () => ({
  type: GET_POSTAT_REPORTED_COMMENTS_BEGIN,
});

export const getPostatReportedCommentsSuccess = (reportedComments) => ({
  type: GET_POSTAT_REPORTED_COMMENTS_SUCCESS,
  payload: reportedComments,
});

export const getPostatReportedCommentsFail = (error) => ({
  type: GET_POSTAT_REPORTED_COMMENTS_FAIL,
  payload: error,
});

export const deletePostatReportedComment = ({
  commentId,
  postatId,
  callback,
}) => ({
  type: DELETE_POSTAT_REPORTED_COMMENT,
  commentId: commentId,
  postatId: postatId,
  callback: callback,
});

export const deletePostatReportedCommentBegin = () => ({
  type: DELETE_POSTAT_REPORTED_COMMENT_BEGIN,
});

export const deletePostatReportedCommentSuccess = (postReports) => ({
  type: DELETE_POSTAT_REPORTED_COMMENT_SUCCESS,
  payload: postReports,
});

export const deletePostatReportedCommentFail = (error) => ({
  type: DELETE_POSTAT_REPORTED_COMMENT_FAIL,
  payload: error,
});

export const getCommentReportsById = (request) => ({
  type: GET_COMMENT_REPORTS_BY_ID,
  request: request,
});

export const getCommentReportsByIdBegin = () => ({
  type: GET_COMMENT_REPORTS_BY_ID_BEGIN,
});

export const getCommentReportsByIdSuccess = (commentReports) => ({
  type: GET_COMMENT_REPORTS_BY_ID_SUCCESS,
  payload: commentReports,
});

export const getCommentReportsByIdFail = (error) => ({
  type: GET_COMMENT_REPORTS_BY_ID_FAIL,
  payload: error,
});

export const getPostatMusic = (request) => ({
  type: GET_POSTAT_MUSIC,
  request: request,
});

export const getPostatMusicBegin = () => ({
  type: GET_POSTAT_MUSIC_BEGIN,
});

export const getPostatMusicSuccess = (music) => ({
  type: GET_POSTAT_MUSIC_SUCCESS,
  payload: music,
});

export const getPostatMusicFail = (error) => ({
  type: GET_POSTAT_MUSIC_FAIL,
  payload: error,
});
export const addPostatMusic = ({ music, callback }) => ({
  type: ADD_POSTAT_MUSIC,
  music: music,
  callback: callback,
});

export const addPostatMusicBegin = () => ({
  type: ADD_POSTAT_MUSIC_BEGIN,
});

export const addPostatMusicSuccess = (music) => ({
  type: ADD_POSTAT_MUSIC_SUCCESS,
  payload: music,
});

export const addPostatMusicFail = (error) => ({
  type: ADD_POSTAT_MUSIC_FAIL,
  payload: error,
});
export const deletePostatMusic = ({ id, callback }) => ({
  type: DELETE_POSTAT_MUSIC,
  id: id,
  callback: callback,
});

export const deletePostatMusicBegin = () => ({
  type: DELETE_POSTAT_MUSIC_BEGIN,
});

export const deletePostatMusicSuccess = (music) => ({
  type: DELETE_POSTAT_MUSIC_SUCCESS,
  payload: music,
});

export const deletePostatMusicFail = (error) => ({
  type: DELETE_POSTAT_MUSIC_FAIL,
  payload: error,
});

export const getPostatMusicById = (id) => ({
  type: GET_POSTAT_MUSIC_BY_ID,
  id: id,
});

export const getPostatMusicByIdBegin = () => ({
  type: GET_POSTAT_MUSIC_BY_ID_BEGIN,
});

export const getPostatMusicByIdSuccess = (music) => ({
  type: GET_POSTAT_MUSIC_BY_ID_SUCCESS,
  payload: music,
});

export const getPostatMusicByIdFail = (error) => ({
  type: GET_POSTAT_MUSIC_BY_ID_FAIL,
  payload: error,
});
export const editPostatMusic = ({
  music_identifier,
  id,
  music,
  // callback,
}) => ({
  type: EDIT_POSTAT_MUSIC,
  params: music_identifier,
  id: id,
  music: music,
  // callback: callback,
});
export const resetMusicErrors = () => ({
  type: RESET_MUSIC_ERRORS,
});

export const editPostatMusicBegin = () => ({
  type: EDIT_POSTAT_MUSIC_BEGIN,
});

export const editPostatMusicSuccess = (music) => ({
  type: EDIT_POSTAT_MUSIC_SUCCESS,
  payload: music,
});

export const editPostatMusicFail = (error) => ({
  type: EDIT_POSTAT_MUSIC_FAIL,
  payload: error,
});

export const getAllPostatPosts = (request) => ({
  type: GET_ALL_POSTAT_POSTS,
  request: request,
});

export const getAllPostatPostsBegin = () => ({
  type: GET_ALL_POSTAT_POSTS_BEGIN,
});

export const getAllPostatPostsSuccess = (allPosts) => ({
  type: GET_ALL_POSTAT_POSTS_SUCCESS,
  payload: allPosts,
});

export const getAllPostatPostsFail = (error) => ({
  type: GET_ALL_POSTAT_POSTS_FAIL,
  payload: error,
});

export const ignorePostatReportedPostat = ({
  request,
  postatId,
  callback,
}) => ({
  type: IGNORE_POSTAT_REPORTED_POSTAT,
  request: request,
  postatId: postatId,
  callback: callback,
});

export const ignorePostatReportedPostatBegin = () => ({
  type: IGNORE_POSTAT_REPORTED_POSTAT_BEGIN,
});

export const ignorePostatReportedPostatSuccess = (reportedPostat) => ({
  type: IGNORE_POSTAT_REPORTED_POSTAT_SUCCESS,
  payload: reportedPostat,
});

export const ignorePostatReportedPostatFail = (error) => ({
  type: IGNORE_POSTAT_REPORTED_POSTAT_FAIL,
  payload: error,
});

export const ignorePostatReportedComments = ({
  request,
  commentId,
  callback,
}) => ({
  type: IGNORE_POSTAT_REPORTED_COMMENTS,
  request: request,
  commentId: commentId,
  callback: callback,
});

export const ignorePostatReportedCommentsBegin = () => ({
  type: IGNORE_POSTAT_REPORTED_COMMENTS_BEGIN,
});

export const ignorePostatReportedCommentsSuccess = (reportedPostatComment) => ({
  type: IGNORE_POSTAT_REPORTED_COMMENTS_SUCCESS,
  payload: reportedPostatComment,
});

export const ignorePostatReportedCommentsFail = (error) => ({
  type: IGNORE_POSTAT_REPORTED_COMMENTS_FAIL,
  payload: error,
});
