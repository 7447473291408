import { Card } from "@material-ui/core";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, UncontrolledAlert } from "reactstrap";
import BackButton from "../../../components/UI/BackButton";
import {
  addFlaxTransferPurpose,
  getFlaxTransferPurposes,
} from "../../../store/flaxTransfer/actions";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { useTranslation } from "react-i18next";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../../components/Common/Loader";
import PodiumFilter from "../../PodiumManagement/PodiumFilter";

const AddPurpose = () => {
  const [purpose, setPurpose] = useState("");
  const history = useHistory();
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const { t } = useTranslation();
  const [backToListing, setBackToListing] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);
  const params = useParams();
  const data = useSelector((state) => state.FlaxTransfer);

  const dispatch = useDispatch();

  const [purposeText, setPurposeText] = useState("");

  const [newPurposeList, setNewPurposeList] = useState([
    {
      purpose: "",
      status: true,
      language: "",
    },
  ]);

  // Enabling the button when all fields are filled
  useEffect(() => {
    for (let i = 0; i < newPurposeList.length; i++) {
      if (
        newPurposeList[i]?.purpose === "" ||
        newPurposeList[i]?.purpose.trim() === "" ||
        newPurposeList?.length < 9 ||
        purposeText === "" ||
        purposeText.trim() === "" ||
        message !== ""
      ) {
        setDisabled(true);
        break;
      } else {
        setDisabled(false);
      }
      if (newPurposeList[i] == undefined) {
        setDisabled(true);
        break;
      } else {
        setDisabled(false);
      }
    }
  }, [
    newPurposeList,
    purposeText,
    data?.flaxTransferPurposes?.languages?.length,
    message,
  ]);
  useEffect(() => {
    for (
      let i = 0;
      i < data?.flaxTransferPurposes?.result?.result?.length;
      i++
    ) {
      if (
        purposeText.toLowerCase().replace(/\s+/g, "-") ===
          data?.flaxTransferPurposes?.result?.result[i].purpose_identifier ||
        purposeText === data?.flaxTransferPurposes?.result?.result[i].purpose ||
        newPurposeList[0].purpose.toLowerCase().replace(/\s+/g, "-") ===
          data?.flaxTransferPurposes?.result?.result[i].purpose
            .toLowerCase()
            .replace(/\s+/g, "-")
      ) {
        setMessage("This purpose already exists");
        break;
      } else {
        setMessage("");
      }
    }
  }, [purposeText, newPurposeList]);

  useEffect(() => {
    handleReportUsersChangeOrAdd();
  }, []);

  const handleReportUsersChangeOrAdd = (index, key, value, language) => {
    const list = [...newPurposeList];

    if (list[index]) {
      list[index][key] = value && value.trim();

      list[index]["language"] = language;
      setNewPurposeList(list);
    } else {
      list[index] = {
        purpose: value && value.trim(),

        status: true,
        language: language,
      };
      setNewPurposeList(list);
    }
  };
  const purposePaylaod = {
    purpose_identifier: purposeText.toLowerCase().replace(/\s+/g, "-"),
    purposes_list: newPurposeList,
  };
  // };
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      addFlaxTransferPurpose({
        purpose: purposePaylaod,
        callback: () => history.push("/FLix-transfer-purposes"),
      })
    );
  };
  useEffect(() => {
    dispatch(getFlaxTransferPurposes());
    // dispatch(getFlaxTransferPurposesby({ id: params?.id }));
    // dispatch(resetHuddlesErrors());
  }, []);
  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      //   ref={pageWrapRef}
    >
      {/* <Prompt
          message={(location, action) => {
            if (action === "POP") {
              return confirmBrowserBack();
            }
          }}
          when={formChanged}
        /> */}
      <BackButton
        label="FLiX  Transfer Purposes"
        handleClick={() => history.push("/FLix-transfer-purposes")}
      />
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Add Purpose" />
          {message !== "" && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color={"danger"}
                className="alert-dismissible fade show"
                role="alert"
              >
                {message}
              </UncontrolledAlert>
            </div>
          )}
          <Col xl="8">
            <Card className="mb-0 p-4 my-4">
              <div className="text-muted">
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleSubmit(e, v);
                  }}
                >
                  <div
                    className="table-responsive mt-4"
                    style={{ overflowX: "hidden" }}
                  >
                    <div className="d-flex mb-4">
                      <div className="col-md-6">
                        <label className="col-md-8 col-form-label">
                          Enter Transfer Purpose (in English)
                          <span className="mandatory">*</span>
                        </label>
                        <div className="col-md-8 dobWrapper">
                          <AvInput
                            name="adminName"
                            type="text"
                            className="form-control mb-4 "
                            // value={purpose?.purpose}
                            id="name-input"
                            onChange={(e) => {
                              setPurposeText(e.target.value);
                            }}
                            placeholder="Enter Transfer Purpose"
                          />
                        </div>
                      </div>
                    </div>
                    {data?.flaxTransferPurposes?.languages?.length &&
                      data?.flaxTransferPurposes?.languages?.map(
                        (language, i) =>
                          language == "english" ? (
                            <div key={i} className="col-md-12">
                              <div className="d-flex mx-1">
                                <label className="col-md-2 lang-font col-form-label">
                                  {`${language
                                    ?.slice(0, 1)
                                    .toUpperCase()}${language?.slice(1)}`}
                                </label>
                                <div className="col-md-6">
                                  <div className="col-md-8 dobWrapper">
                                    <AvInput
                                      name="adminName"
                                      type="text"
                                      className="form-control mb-4 "
                                      // value={purpose?.purpose}
                                      id="name-input"
                                      onChange={(e) => {
                                        handleReportUsersChangeOrAdd(
                                          i,
                                          "purpose",
                                          e.target.value,
                                          language
                                        );
                                      }}
                                      placeholder="Enter Transfer Purpose"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div key={i} className=" d-flex mx-1">
                              <label className="col-md-2 lang-font col-form-label">
                                {`${language
                                  ?.slice(0, 1)
                                  .toUpperCase()}${language?.slice(1)}`}
                              </label>{" "}
                              <div className="col-md-6">
                                {/* <label className="col-md-8 col-form-label">
                              Enter Category Text
                              <span className="mandatory">*</span>
                            </label> */}
                                <div className="col-md-8 dobWrapper">
                                  <AvInput
                                    name="adminName"
                                    type="text"
                                    className="form-control mb-4 "
                                    // value={purpose?.purpose}
                                    id="name-input"
                                    onChange={(e) => {
                                      handleReportUsersChangeOrAdd(
                                        i,
                                        "purpose",
                                        e.target.value,
                                        language
                                      );
                                    }}
                                    placeholder="Enter Transfer Purpose"
                                  />
                                </div>
                              </div>
                            </div>
                          )
                      )}

                    <div className="mt-4">
                      <button
                        type="submit"
                        // disabled={isButtonDisabled}
                        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                        // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                        disabled={disabled}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </AvForm>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <ConfirmationAlert
          {...promptMessage}
          modal_center={showPromptPopUp}
          setmodal_center={setShowPromptPopUp}
          onOK={okHandler}
        /> */}
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default AddPurpose;
