import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";

import {
  getPayoutRequests,
  //   deleteUser,
  //   blockUser,
  storeRequest,
  //   exportUserEmail,
} from "../../../store/payouts/action";

import Camera_icon from "../../../assets/icons/ebud-icons/Camera.svg";
import Block_icon from "../../../assets/icons/ebud-icons/Block.svg";
import UnBlock_icon from "../../../assets/icons/ebud-icons/Unblock.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import defaultProfile from "../../../assets/images/users/user.svg";
import premiumImage from "../../../assets/icons/premium.svg";
import saveAs from "file-saver";
import verifedImage from "../../../assets/icons/verified.svg";

import { payoutrequestColumnData } from "../../../helpers/columns";
import { checkUserPermission, convertToUAETime } from "../../../helpers/utils";
import {
  DATE_WITH_TIME_IN_SECONDS,
  FE_DATE_FORMAT,
} from "../../../helpers/constants";

const PayoutsManagement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Payouts);
  console.log("data_payouts", data);
  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;
  const isDeleteable = userPermissions?.delete || false;

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();

  const basicRequest = {
    sort: "requested_on",
    sort_order: "desc",
    page: 1,

    page_count: 50,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Users"
        )
      );
    }
  }, [loginData]);

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error]);

  const scrollToAlertMessage = () => {
    if (data?.error) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {
    if (history?.location?.state?.PRUpdated) {
      dispatch(getPayoutRequests(request));
      dispatch(storeRequest(request));
    }
  }, [history?.location?.state?.PRUpdated]);

  useEffect(() => {
    history?.location?.state?.from === "keepFilter"
      ? setRequest({ ...data?.request })
      : setRequest({ ...basicRequest });
  }, [history?.location?.state?.from]);

  useEffect(() => {
    if (request && history?.location?.state?.from !== "keepFilter") {
      dispatch(getPayoutRequests(request));
      dispatch(storeRequest(request));
    } else {
      request &&
        (JSON.stringify(request) !== JSON.stringify(data?.request) ||
          !data?.data?.users) &&
        dispatch(getPayoutRequests(request));
      request &&
        (JSON.stringify(request) !== JSON.stringify(data?.request) ||
          !data?.data?.users) &&
        dispatch(storeRequest(request));
    }
  }, [request]);

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: t("UserManagement.deleteConfirm"),
      type: "delete",
    });
  };

  const blockPromptHandler = (id, status) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to ${status === "Blocked" ? "unblock" : "block"
        } this user?`,
      type: status === "Blocked" ? "unblock" : "block",
      status: status,
    });
  };

  const okHandler = () => {
    // promptMessage.type === "delete"
    //   ? dispatch(
    //       deleteUser({
    //         data: promptMessage.id,
    //         callback: () => dispatch(getPayoutRequests(request)),
    //       })
    //     )
    //   : promptMessage.type === "block"
    //   ? dispatch(
    //       blockUser({
    //         data: { blocked: true },
    //         id: promptMessage.id,
    //         callback: () => dispatch(getPayoutRequests(request)),
    //       })
    //     )
    //   : dispatch(
    //       blockUser({
    //         data: { blocked: false },
    //         id: promptMessage.id,
    //         callback: () => dispatch(getPayoutRequests(request)),
    //       })
    //     );
  };

  const formatUserData = useCallback(() => {
    return data?.data?.payouts?.map((user, index) => ({
      ...user,
      no: (request?.page - 1) * request?.page_count + index + 1,
      profile_image: (
        <div className="profileWrap" data-tag="allowRowEvents">
          {user?.verified ? (
            <div className="premium_icon" data-tag="allowRowEvents">
              <img src={verifedImage} data-tag="allowRowEvents" />
            </div>
          ) : user?.membership === "Premium" ? (
            <div className="premium_icon" data-tag="allowRowEvents">
              <img src={premiumImage} data-tag="allowRowEvents" />
            </div>
          ) : null}
          <div data-tag="allowRowEvents">
            <img
              data-tag="allowRowEvents"
              className="table_profileImg"
              src={
                user?.profile_image === null
                  ? defaultProfile
                  : user?.profile_image
              }
            />
          </div>
        </div>
      ),
      name: (
        <div title={user?.name}>
          <div data-tag="allowRowEvents">{user?.name}</div>
          {user?.username && (
            <div
              className="text-muted"
              data-tag="allowRowEvents"
            >{`(${user.username})`}</div>
          )}
        </div>
      ),
      payment_method: (
        <div data-tag="allowRowEvents" title={user?.payment_method}>
          {user?.payment_method}
        </div>
      ),
      requested_on: (
        <div data-tag="allowRowEvents" title={user?.requested_date}>
          {user?.requested_date &&
            convertToUAETime(user?.requested_date, DATE_WITH_TIME_IN_SECONDS)}
        </div>
      ),
      pp: (
        <div data-tag="allowRowEvents" title={user?.requested_amount}>
          {user?.requested_amount}
        </div>
      ),
      status: (
        <>
          <div data-tag="allowRowEvents" title={user?.status}>
            <p
              // className="text-muted"
              className={`badge ${user?.status === "New Request"
                  ? "bg-soft-blue"
                  : user?.status === "In Review"
                    ? "bg-soft-orange"
                    : user?.status === "Forwarded"
                      ? "bg-soft-gray"
                      : user?.status === "Payment Processed"
                        ? "bg-soft-green"
                        : user?.status === "Payment Process Initiated"
                          ? "bg-soft-green"
                          : user?.status === "Request Declined" || user?.status === "Cancelled"
                            ? "bg-soft-red"
                            : user?.status === "Violation/Suspicious Activity"
                              ? "bg-soft-pink"
                              : "bg-soft-orange"
                } font-size-13`}
            >
              {user?.status}{" "}
            </p>
          </div>
          {user?.created_by_admin && (
            <p className="badge bg-soft-success">Admin created</p>
          )}
        </>
      ),
    }));
  }, [data?.data?.payouts, request, isDeleteable, isEditable]);

  //memoized user data
  const userData = useMemo(() => formatUserData(), [formatUserData]);

  const tableRowClickHandler = (param) => {
    // history.push(`/payout-requests/${param.id}`);
    history.push({
      pathname: `/payout-requests/${param.id}`,
      state: { from: "payoutRequests" },
    });
  };

  const addNewClickHandler = () => {
    // dispatch(exportUserEmail({ saveAs: saveAs }));
  };

  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Payout Requests" />
          {data?.error && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            </div>
          )}
          <Datatable
            defaultSortField={request?.sort || "requested_on"}
            defaultSortAsc={request?.sort_order === "asc" ? true : false}
            tableID={"userTable"}
            // add
            addNewClickHandler={addNewClickHandler}
            // addNewLabel={t("UserManagement.export")}
            columns={payoutrequestColumnData}
            rows={userData}
            setRequest={setRequest}
            request={request}
            totalRecords={data?.data?.total}
            search
            payoutfilter
            tableRowClicked={(param) => tableRowClickHandler(param)}
            searchTerm={data?.request?.keyword}
            statusFilter={data?.request?.status_filter}
            membershipFilter={data?.request?.membership_filter}
            loading={data?.loading}
            tableCardClassName={"snoTable"}
          />
        </Row>
      </Container>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default PayoutsManagement;
