import {
  ADD_EMPOWERMENT_DETAILS_BEGIN,
  ADD_EMPOWERMENT_DETAILS_FAIL,
  ADD_EMPOWERMENT_DETAILS_SUCCESS,
  GET_EMPOWERMENT_DETAILS_BEGIN,
  GET_EMPOWERMENT_DETAILS_FAIL,
  GET_EMPOWERMENT_DETAILS_SUCCESS,
  RESET_MESSAGES,
  UPDATE_EMPOWERMENT_DETAILS_BEGIN,
  UPDATE_EMPOWERMENT_DETAILS_FAIL,
  UPDATE_EMPOWERMENT_DETAILS_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  loading: false,
  empowermentDetails: [],
  error: "",
  success: "",
  update: false,
};

const empowermentDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMPOWERMENT_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        empowermentDetails: [],
        error: "",
      };
    case GET_EMPOWERMENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        empowermentDetails: action.payload,
        error: "",
      };
    case GET_EMPOWERMENT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        empowermentDetails: [],
        error: action.payload,
      };
    case ADD_EMPOWERMENT_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        success: "",
        error: "",
      };
    case ADD_EMPOWERMENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: "",
      };
    case ADD_EMPOWERMENT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        success: "",
        error: action.payload,
      };

    case RESET_MESSAGES:
      return {
        ...state,
        error: "",
        success: "",
      };
    default:
      return state;
  }
};
export default empowermentDetails;
