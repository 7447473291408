import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, UncontrolledAlert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Datatable from "../../../../components/UI/Tables/Datatable";

import { getRegistrationAndLogin } from "../../../../store/users/registrationLogin/action";

import { registrationLoginHistoryColumnData } from "../../../../helpers/columns";
import { convertToUAETime } from "../../../../helpers/utils";
import { DATE_WITH_TIME } from "../../../../helpers/constants";
import Loader from "../../../../components/Common/Loader";

const Registration_loginHistory = ({ activeTab }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useParams();

  let historyDetails = useSelector((state) => state.registrationLoginHistory);
  console.log("history details", historyDetails);

  const basicRequest = {
    filter: "1M",
    page: 1,
    page_count: 50,
  };

  const [request, setRequest] = useState({ ...basicRequest });
  console.log("activeTab", activeTab);

  useEffect(() => {
    if (activeTab === "3") {
      dispatch(getRegistrationAndLogin(match.id, { ...request }));
    }
  }, [request, activeTab]);

  const formatUserData = useCallback(() => {
    return historyDetails?.historyDetails?.history?.map((record, index) => ({
      ...record,
      device_and_location: (
        <div>
          <div>{`${record.device_name}, ${record?.device_model} `}</div>
          <div className="text-muted locationStyle">{`${record.location}`}</div>
        </div>
      ),
      login_time: (
        <div>
          {record.login_time &&
            convertToUAETime(record.login_time, DATE_WITH_TIME)}
        </div>
      ),
      logout_time: (
        <div>
          {record.logout_time &&
            convertToUAETime(record.logout_time, DATE_WITH_TIME)}
        </div>
      ),
    }));
  });

  //memoized user data
  const userData = useMemo(() => formatUserData(), [formatUserData]);

  return (
    <>
      {historyDetails?.error ? (
        <div>
          <UncontrolledAlert
            color="danger"
            className="alert-dismissible fade show"
            role="alert"
          >
            {historyDetails?.error}
          </UncontrolledAlert>
        </div>
      ) : (
        <Col xl="12" id="registration_loginHistory_wrapper">
          <Row className="col-md-12 m-0">
            <div className="reg_login_headerWrapper mb-4">
              <div className="col-md-6 reg_login_detailsRegDet">
                <div
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                  className=""
                >
                  Login Details
                </div>
              </div>
              <div className="col-md-6 reg_login_detailsFilter">
                <label>View results of : </label>
                <div>
                  <select
                    className="form-control w-200 m-0"
                    style={{
                      backgroundColor: "#eeeeee",
                    }}
                    onChange={(e) =>
                      setRequest({ ...request, filter: e.target.value })
                    }
                  >
                    <option value="1M">{t("UserManagement.thisMonth")}</option>
                    <option value="3M">
                      {t("UserManagement.last3Months")}
                    </option>
                    <option value="6M">
                      {t("UserManagement.last6Months")}
                    </option>
                    <option value="">ALL</option>
                  </select>
                </div>
              </div>
            </div>
          </Row>
          <div className="d-flex justify-content-start reg_login_detailsRegDet">
            <div className="mr-3">
              <label className="">Registered On : </label>
            </div>
            <div className="text-muted ml-3">
              {historyDetails?.historyDetails?.registered_on &&
                convertToUAETime(
                  historyDetails?.historyDetails?.registered_on,
                  DATE_WITH_TIME
                )}
            </div>
          </div>
          <Datatable
            tableID={"userTable"}
            columns={registrationLoginHistoryColumnData}
            rows={userData}
            setRequest={setRequest}
            request={request}
            totalRecords={historyDetails?.historyDetails?.total}
            loading={historyDetails?.loading}
          />
        </Col>
      )}
      {historyDetails?.loading && <Loader darkBg={true} />}
    </>
  );
};

export default Registration_loginHistory;
