import {
  ADD_MANUAL_PP,
  GET_MANUAL_PP_BEGIN,
  GET_MANUAL_PP_SUCCESS,
  GET_MANUAL_PP_ERROR,
  ADD_MANUAL_PP_BEGIN,
  ADD_MANUAL_PP_SUCCESS,
  ADD_MANUAL_PP_ERROR,
  GET_ALL_MANUAL_PP,
  DELETE_MANUAL_PP,
  DELETE_MANUAL_PP_BEGIN,
  DELETE_MANUAL_PP_SUCCESS,
  DELETE_MANUAL_PP_ERROR,
  RESET_MANUAL_PP_STATES,
} from "./actionTypes";

export const getAllManualPP = (payload) => {
  return { type: GET_ALL_MANUAL_PP, payload };
};

export const getAllManualPPBegin = (payload) => {
  return { type: GET_MANUAL_PP_BEGIN, payload };
};

export const getAllManualPPSuccess = (payload) => {
  return { type: GET_MANUAL_PP_SUCCESS, payload };
};

export const getAllManualPPError = (payload) => {
  return { type: GET_MANUAL_PP_ERROR, payload };
};

export const addManualPP = (payload) => ({ type: ADD_MANUAL_PP, payload });

export const addManualPPBegin = () => ({ type: ADD_MANUAL_PP_BEGIN });

export const addManualPPSuccess = (payload) => {
  return { type: ADD_MANUAL_PP_SUCCESS, payload };
};

export const addManualPPError = (payload) => {
  return { type: ADD_MANUAL_PP_ERROR, payload };
};

export function deletepp(payload) {
  return { type: DELETE_MANUAL_PP, payload };
}

export const deletePPBegin = () => ({ type: DELETE_MANUAL_PP_BEGIN });
export const deletePPSuccess = (payload) => ({
  type: DELETE_MANUAL_PP_SUCCESS,
  payload,
});
export const deletePPError = (payload) => ({
  type: DELETE_MANUAL_PP_ERROR,
  payload,
});

export const resetManualPPStates = () => ({ type: RESET_MANUAL_PP_STATES });