import React, { useEffect, useRef } from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { useState } from "react";
import {
  flaxTransferColumnData,
  userRequestColumnData,
} from "../../../helpers/columns";
import premiumImage from "../../../assets/icons/premium.svg";
import defaultProfile from "../../../assets/images/users/user.svg";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFlaxTransferHistory } from "../../../store/flaxTransfer/actions";
import { convertTime, convertToUAETime } from "../../../helpers/utils";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../helpers/constants";
import Loader from "../../../components/Common/Loader";

const FlaxTransfers = () => {
  // const history = useHistory();
  // const location = useLocation();
  const dispatch = useDispatch();

  // const delimiter = ":";
  // function splitString(inputString, delimiter) {
  //   if (inputString) {
  //     const [label, percentage] = inputString?.split(delimiter);
  //     return { label, percentage };
  //   }
  // }

  const data = useSelector((state) => state.FlaxTransfer);

  const loginData = useSelector((state) => state.Login);
  const basicRequest = {
    sort: "transfered_date",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });

  useEffect(() => {
    request && dispatch(getFlaxTransferHistory(request));
  }, [request]);

  // useEffect(() => {
  //   history?.location?.state?.from === "keepFilter"
  //     ? setRequest({ ...data?.request })
  //     : setRequest({ ...basicRequest });
  // }, [history?.location?.state?.from]);

  const flaxTransferData =
    data?.flaxTransferData?.transfers?.length >= 0 &&
    data?.flaxTransferData?.transfers?.map((transfers, index) => {
      // Check if sender_details and receiver_details are not null
      const senderDetails = transfers?.sender_details || {};
      const receiverDetails = transfers?.receiver_details || {};

      return {
        ...transfers,
        no: (request?.page - 1) * request?.page_count + index + 1,
        sender_image: (
          <div className="profileWrap" data-tag="allowRowEvents">
            {senderDetails.premium ? (
              <div className="huddle_premium_icon" data-tag="allowRowEvents">
                <img
                  src={premiumImage}
                  data-tag="allowRowEvents"
                  alt="Premium"
                />
              </div>
            ) : null}

            <div className="d-flex">
              <div data-tag="allowRowEvents">
                <img
                  data-tag="allowRowEvents"
                  className="table_profileImg"
                  src={
                    senderDetails.profile_photo
                      ? senderDetails.profile_photo
                      : defaultProfile
                  }
                  alt="Profile"
                />
              </div>

              <div
                className="mx-2 mt-3"
                title={senderDetails.name}
                data-tag="allowRowEvents"
              >
                {senderDetails.name}{" "}
              </div>
            </div>
          </div>
        ),
        receiver_image: (
          <div className="profileWrap" data-tag="allowRowEvents">
            {receiverDetails.premium ? (
              <div className="huddle_premium_icon" data-tag="allowRowEvents">
                <img
                  src={premiumImage}
                  data-tag="allowRowEvents"
                  alt="Premium"
                />
              </div>
            ) : null}

            <div className="d-flex">
              <div data-tag="allowRowEvents">
                <img
                  data-tag="allowRowEvents"
                  className="table_profileImg"
                  src={
                    receiverDetails.profile_photo
                      ? receiverDetails.profile_photo
                      : defaultProfile
                  }
                  alt="Profile"
                />
              </div>

              <div
                className="mx-2 mt-3"
                title={receiverDetails.name}
                data-tag="allowRowEvents"
              >
                {receiverDetails.name}{" "}
              </div>
            </div>
          </div>
        ),
        flax_amount_send: (
          <div data-tag="allowRowEvents" title={transfers?.sent_flax}>
            {transfers?.sent_flax}
          </div>
        ),
        flax_rating: (
          <div data-tag="allowRowEvents" title={transfers?.flax_rate}>
            {transfers?.flax_rate}
          </div>
        ),
        // flax_fee: (
        //   <div data-tag="allowRowEvents" title={transfers?.processing_fee}>
        //     <span>
        //       {splitString(transfers?.processing_fee, delimiter)?.label}
        //     </span>
        //     <br></br>
        //     <span
        //       style={{
        //         color: "red",
        //         fontWeight: "lighter",
        //       }}
        //     >
        //       {splitString(transfers?.processing_fee, delimiter)?.percentage}
        //     </span>
        //   </div>
        // ),
        flax_amount_received: (
          <div data-tag="allowRowEvents" title={transfers?.received_flax}>
            {transfers?.received_flax}
          </div>
        ),
        transfer_purpose: (
          <div data-tag="allowRowEvents" title={transfers?.purpose}>
            {transfers?.purpose}
          </div>
        ),
        transfered_date: (
          <div data-tag="allowRowEvents" title={transfers?.time_created}>
            {convertTime(transfers?.time_created)}
          </div>
        ),
      };
    });

  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
    >
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="FLiX Transfers" />

          {data?.flaxTransferData && (
            <Datatable
              defaultSortField={request?.sort || "transfered_date"}
              defaultSortAsc={request?.sort_order === "asc" ? true : false}
              dateFilter
              dateType="Transfered Date Range"
              tableID={"adminTable"}
              columns={flaxTransferColumnData}
              rows={flaxTransferData}
              setRequest={setRequest}
              request={request}
              totalRecords={data?.flaxTransferData?.total}
              search
              searchTerm={data?.request?.keyword}
              // reqstatusFilter={request?.status_filter}
              loading={data?.loading}
              tableCardClassName={"snoTable"}
            />
          )}
        </Row>
      </Container>
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default FlaxTransfers;
