import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_AUTH,
  AUTH_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  GET_USER_PERMISSIONS_BEGIN,
  GET_USER_PERMISSIONS_SUCCESS,
  GET_USER_PERMISSIONS_FAIL,
  RESET_USER_PERMISSIONS
} from "./actionTypes";

export const initialState = {
  error: "",
  loading: false,
  rolePermissions: {},
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_AUTH:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AUTH_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
      };
      break;
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        otpToken : action.payload,
        error: "",
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state };
      break;
/**-------------------------------------- */
    case GET_USER_PERMISSIONS_BEGIN:
      return {
        ...state,
        rolePermissions: {},
      }

    case GET_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        rolePermissions: action.payload,
      }

    case GET_USER_PERMISSIONS_FAIL:
      return {
        ...state,
        rolePermissions: {},
        error: action.payload,
      }
    /**-------------------------------------- */
    case RESET_USER_PERMISSIONS:
      return {
        ...state,
        rolePermissions: {},
      };
    /**-------------------------------------- */
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
