import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { giftTransferColumnData } from "../../../helpers/columns";
import premiumImage from "../../../assets/icons/premium.svg";
import defaultProfile from "../../../assets/images/users/user.svg";
import { convertTime, convertToUAETime } from "../../../helpers/utils";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../helpers/constants";
import Loader from "../../../components/Common/Loader";
import Lottie from "lottie-react";
import { getGiftTransferHistory } from "../../../store/GiftTransfers/actions";

const GiftTransfers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "transfered_date",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const data = useSelector((state) => state.giftTransfer);

  useEffect(() => {
    request && dispatch(getGiftTransferHistory(request));
  }, [request]);

  const giftTransferData =
    data?.giftTransferData?.gift_transfers?.length >= 0 &&
    data?.giftTransferData?.gift_transfers?.map((transfers, index) => {
      // Check if sender_details and receiver_details are not null
      const senderDetails = transfers?.sender_details || {};
      const receiverDetails = transfers?.receiver_details || {};

      return {
        ...transfers,
        no: (request?.page - 1) * request?.page_count + index + 1,
        sender_image: (
          <div className="profileWrap" data-tag="allowRowEvents">
            {senderDetails.premium ? (
              <div className="huddle_premium_icon" data-tag="allowRowEvents">
                <img
                  src={premiumImage}
                  data-tag="allowRowEvents"
                  alt="Premium"
                />
              </div>
            ) : null}

            <div className="d-flex">
              <div data-tag="allowRowEvents">
                <img
                  data-tag="allowRowEvents"
                  className="table_profileImg"
                  src={
                    senderDetails.profile_photo
                      ? senderDetails.profile_photo
                      : defaultProfile
                  }
                  alt="Profile"
                />
              </div>

              <div
                className="mx-2 mt-3"
                title={senderDetails.name}
                data-tag="allowRowEvents"
              >
                {senderDetails.name}{" "}
              </div>
            </div>
          </div>
        ),
        receiver_image: (
          <div className="profileWrap" data-tag="allowRowEvents">
            {receiverDetails.premium ? (
              <div className="huddle_premium_icon" data-tag="allowRowEvents">
                <img
                  src={premiumImage}
                  data-tag="allowRowEvents"
                  alt="Premium"
                />
              </div>
            ) : null}

            <div className="d-flex">
              <div data-tag="allowRowEvents">
                <img
                  data-tag="allowRowEvents"
                  className="table_profileImg"
                  src={
                    receiverDetails.profile_photo
                      ? receiverDetails.profile_photo
                      : defaultProfile
                  }
                  alt="Profile"
                />
              </div>

              <div
                className="mx-2 mt-3"
                title={receiverDetails.name}
                data-tag="allowRowEvents"
              >
                {receiverDetails.name}{" "}
              </div>
            </div>
          </div>
        ),
        gift_identifier: (
          <>
            <div data-tag="allowRowEvents" title={transfers?.gift_name}>
              {transfers?.gift_name}
            </div>
            <div data-tag="allowRowEvents" title={transfers?.gift_name}>
              <img width={28} src={transfers?.gift_static_url} alt="gift" />
            </div>
          </>
        ),

        send_coins: (
          <div
            data-tag="allowRowEvents"
            title={`${senderDetails?.name} --> ${receiverDetails?.name}`}
          >
            {transfers?.sender_details?.sent_coins}
          </div>
        ),
        received_coins: (
          <div
            data-tag="allowRowEvents"
            title={`${senderDetails?.name} --> ${receiverDetails?.name}`}
          >
            {receiverDetails?.received_coins}
          </div>
        ),
        sending_source: (
          <div
            data-tag="allowRowEvents"
            title={`${senderDetails?.name} --> ${receiverDetails?.name}`}
          >
            {transfers?.sending_source}
          </div>
        ),
        manager_received_coins: (
          <div
            data-tag="allowRowEvents"
            title={`${senderDetails?.name} --> ${receiverDetails?.name}`}
          >
            {transfers?.manager_received_coins}
          </div>
        ),
        flashat_points: (
          <div
            data-tag="allowRowEvents"
            title={`${senderDetails?.name} --> ${receiverDetails?.name}`}
          >
            {transfers?.flashat_points}
          </div>
        ),
        transfered_date: (
          <div
            data-tag="allowRowEvents"
            title={`${senderDetails?.name} --> ${receiverDetails?.name}`}
          >
            {convertTime(transfers?.gift_sent_time)}
          </div>
        ),
      };
    });

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        // id="userMgmtList"
        // ref={pageWrapRef}
      >
        <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Gift Revenue Share" />
            {/* <div className="container-fluid"> */}
            <Datatable
              tableID={"flaxTable"}
              columns={giftTransferColumnData}
              rows={giftTransferData}
              setRequest={setRequest}
              request={request}
              dateFilter
              dateType="Transfered Date Range"
              totalRecords={data?.giftTransferData?.total}
              search
              // add
              // dropDown
              // languages={languages}
              // addNewClickHandler={addNewClickHandler}
              // selectedLanguage={selectedLanguage}
              // setSelectedLanguage={setSelectedLanguage}
              // addNewLabel={t("Admins.addAdmin.addBtn")}
              searchTerm={data?.request?.keyword}
              loading={data?.loading}
              tableCardClassName={"snoTable"}
            />
            {/* </div> */}
          </Row>
        </Container>
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default GiftTransfers;
