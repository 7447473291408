import { combineReducers } from "redux";

import Layout from "./layout/reducer";
import Login from "./auth/login/reducer";
import dashboard from "./dashboard/reducer";
import user from "./users/reducer";
import reserveName from "./usernames/reserveUserNames/reducer";
import allocatedUsername from "./usernames/allocateUserNames/reducer";
import contentManagement from "./contentManagement/reducer";
import registrationLoginHistory from "./users/registrationLogin/reducer";
import mlmTree from "./users/userMatrix/reducer";
import appSettings from "./settings/appSettings/reducer";
import templateSettings from "./settings/templates/reducer";
import subscriptionDetails from "./users/userSubscriptions/reducer";
import notification from "./notification/reducer";
import reportedUser from "./reportedUser/reducer";
import appInstallations from "./appInstallations/reducer";
import admins from "./adminManagement/admins/reducer";
import rolePermisions from "./adminManagement/roleManagement/reducer";
import appSubscriptions from "./appSubscription/reducer";
import accountVerification from "./accountVerification/reducer";
import superStarSuggestion from "./settings/superstarSuggestion/reducer";
import Huddles from "./huddles/reducer";
import Payouts from "./payouts/reducer";
import AppReviews from "./appReview/reducer";
import manualPP from "./manualPP/reducer";
import manualCoin from './manualCoin/reducer'
import FlaxTransfer from "./flaxTransfer/reducer";
import empowermentDetails from "./users/empowerment/reducer";
import flaxPackages from "./flaxPackages/reducer";
import giftPackages from "./GiftPackages/reducer";
import giftTransfer from "./GiftTransfers/reducer";
import Podiums from "./podiums/reducer";
import FlashCategories from "./flashManagement/reducer";
import Announcement from "./announcements/reducer";
import flaxCoinsConversion from "./coinsFlaxConversion/reducer";
import PaymentMethods from "./paymentManagement/reducer";
import GlobalPaymentSystems from "./GlobalPaymentManagement/reducer";
import Countries from "./commonCountries/reducer";
import GiftCategories from "./GiftCategories/reducer";
import userFlaxRates from "./users/flaxRates/reducer";
import coinsPurchase from "./CoinsPurchaseHistory/reducer";
import ReportedPostatPosts from "./postatManagement/reducer.js";
import Statements from "./flaxStatements/reducer";
import Cookies from "./cookies/reducer";
import PodiumCameraPurchaseHistory from "./users/cameraPurchases/reducer";
import EditRating from "./users/editRating/reducer.js";

const rootReducer = combineReducers({
  Layout,
  Login,
  dashboard,
  user,
  reserveName,
  allocatedUsername,
  contentManagement,
  registrationLoginHistory,
  mlmTree,
  appSettings,
  templateSettings,
  subscriptionDetails,
  notification,
  reportedUser,
  appInstallations,
  admins,
  rolePermisions,
  appSubscriptions,
  accountVerification,
  superStarSuggestion,
  Huddles,
  Payouts,
  AppReviews,
  manualPP,
  manualCoin,
  FlaxTransfer,
  empowermentDetails,
  flaxPackages,
  giftPackages,
  giftTransfer,
  Podiums,
  FlashCategories,
  Announcement,
  flaxCoinsConversion,
  PaymentMethods,
  GlobalPaymentSystems,
  Countries,
  GiftCategories,
  userFlaxRates,
  coinsPurchase,
  ReportedPostatPosts,
  Statements,
  Cookies,
  PodiumCameraPurchaseHistory,
  EditRating,
});

export default rootReducer;
