import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Row, Container, UncontrolledAlert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Datatable from "../../../components/UI/Tables/Datatable";

import Loader from "../../../components/Common/Loader";

import { templateColumnData } from "../../../helpers/columns";

import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";

import { getTemplateListing } from "../../../store/settings/templates/actions";
import { checkUserPermission } from "../../../helpers/utils";

const Settings = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const languages = ["english", "arabic"];
  const [language, setLanguage] = useState("english");
  const templateResponse = useSelector((state) => state.templateSettings);

  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;

  const basicRequest = {
    page: 1,
    page_count: 50,
  };
  const [request, setRequest] = useState({ ...basicRequest });

  useEffect(() => {
    dispatch(getTemplateListing({ ...request, language }));
  }, [language]);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Settings",
          "Template"
        )
      );
    }
  }, [loginData]);

  const formatUserData = useCallback(() => {
    return templateResponse?.templateList?.templates?.map((record, index) => ({
      ...record,
      no: index + 1,
      templateTitle: <div>{record.title}</div>,
      subject: <div>{record.subject}</div>,
      created_on: <div>{record.created}</div>,
      action: (
        <div className="cust-table-actions-wrap">
          <button
            className="action-btn"
            title="Edit"
            onClick={() =>
              history.push({
                pathname: `/settings-template-edit/${record.id}`,
              })
            }
            disabled={!isEditable}
          >
            <img
              src={Edit_icon}
              alt="Edit"
              style={{ opacity: !isEditable ? "50%" : "" }}
            />
          </button>
        </div>
      ),
    }));
  });

  const templateData = useMemo(() => formatUserData(), [formatUserData]);
  const addNewClickHandler = () => {
    history.push("/settings-template-add");
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem={t("Sidebar.Item13")} />

          {(templateResponse?.error || templateResponse?.success) && (
            <div>
              <UncontrolledAlert
                color={templateResponse?.error ? "danger" : "success"}
                className="alert-dismissible fade show"
                role="alert"
              >
                {templateResponse?.error || templateResponse?.success}
              </UncontrolledAlert>
            </div>
          )}

          <Datatable
            title=""
            tableID={"settings_template"}
            rows={templateData}
            columns={templateColumnData}
            setRequest={setRequest}
            request={request}
            dropDown
            add
            addNewClickHandler={addNewClickHandler}
            addNewLabel={t("Admins.addAdmin.addBtn")}
            languages={languages}
            selectedLanguage={language}
            setSelectedLanguage={setLanguage}
            totalRecords={templateResponse?.templateList?.total}
            loading={templateResponse?.loading}
          />
        </Row>
      </Container>
      {templateResponse?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default Settings;
