import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, Col, Container, Row, UncontrolledAlert } from "reactstrap";
import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Loader from "../../../components/Common/Loader";
import DefaultField from "../DefaultField";
import LanguageDropDown from "../LanguageDropDown";
import {
  addGlobalPaymentSystem,
  resetGlobalPaymentSystemErrors,
} from "../../../store/GlobalPaymentManagement/actions";
import { getPaymentMethods } from "../../../store/paymentManagement/actions";
import { getCountries } from "../../../store/commonCountries/actions";

const AddPaymentSystem = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [language, setLanguage] = useState("english");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [country, setCountry] = useState("");

  const data = useSelector((state) => state?.PaymentMethods);
  console.log("paymentmethid", data?.paymentMethods);
  const countries = useSelector((state) => state.Countries);

  useEffect(() => {
    dispatch(getCountries());
    return () => {
      dispatch(resetGlobalPaymentSystemErrors());
    };
  }, []);

  useEffect(() => {
    if (language != "") dispatch(getPaymentMethods(language));
  }, [language]);
  const initialInput = {
    input_type: "",
    input_label: "",
    input_infotext: "",
    input_placeholder: "",
    listing_order: "",
    options: [
      { input_type_value: "", listing_order: "" },
      { input_type_value: "", listing_order: "" },
    ],
  };

  const [inputs, setInputs] = useState([initialInput]);

  useEffect(() => {
    console.log("inpouts", inputs);
  }, [language]);

  const handleAddInput = () => {
    setInputs([...inputs, { ...initialInput }]);
  };
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  };
  const handleRemoveInput = (index) => {
    if (index > 0) {
      // Check if index is greater than 1 (to keep first two inputs)
      const updatedInputs = [...inputs];
      updatedInputs.splice(index, 1);
      setInputs(updatedInputs);
    }
  };
  useEffect(() => {
    const isInvalid = inputs?.some((input) => {
      if (
        !input?.input_type ||
        !input?.input_label ||
        !input?.listing_order ||
        !paymentMethod ||
        !country ||
        !language
      ) {
        return true;
      }
      if (!["text", "file", "phone"].includes(input?.input_type)) {
        return input?.options?.some(
          (option) => !option?.input_type_value || !option?.listing_order
        );
      }
      return false;
    });
    setDisabled(isInvalid);
  }, [inputs, paymentMethod, country, language]);

  const handleAddOption = (index) => {
    const newInputs = [...inputs];
    newInputs[index]?.options.push({ input_type_value: "", listing_order: "" });
    setInputs(newInputs);
  };

  const handleRemoveOption = (inputIndex, optionIndex) => {
    const newInputs = [...inputs];
    newInputs[inputIndex]?.options.splice(optionIndex, 1);
    setInputs(newInputs);
  };

  const handleSubmit = (event, values) => {
    event.preventDefault();
    const finalInputs = inputs.map((input) => ({
      ...input,
      options:
        input?.input_type === "text" ||
        input?.input_type === "file" ||
        input?.input_type === "phone"
          ? []
          : input.options,
    }));

    const finalPayload = {
      payment_method_id: paymentMethod,
      language: language,
      country_id: country,
      inputs: finalInputs,
    };

    dispatch(
      addGlobalPaymentSystem({
        system: finalPayload,
        callback: () => {
          history.push("/payment-forms");
        },
      })
    );
  };
  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        ref={pageWrapRef}
      >
        <BackButton
          label="Payment Forms"
          handleClick={() => history.push("/payment-forms")}
        />
        <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Add Payment Form" />
            {/* {message && (
                <div ref={warningAlertRef}>
                  <UncontrolledAlert
                    color="danger"
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {message}
                  </UncontrolledAlert>
                </div>
              )} */}
            <Col xl="12">
              <Card className="mb-0 p-4 my-4">
                <div className="text-muted">
                  <AvForm
                    onValidSubmit={(e, v) => {
                      if (!disabled) {
                        handleSubmit(e, v);
                      } else {
                        e.preventDefault();
                      }
                    }}
                  >
                    <div
                      className="table-responsive mt-4"
                      style={{ overflowX: "hidden" }}
                    >
                      <div
                        style={{
                          backgroundColor: "lightgray",
                        }}
                        className="row mb-4  justify-content-center align-items-center"
                      >
                        <div className="col-md-4 row justify-content-center align-items-center ">
                          <div
                            style={{
                              height: "31px",
                            }}
                            className="col-md-10 row justify-content-center align-items-center my-2 "
                          >
                            <select
                              className="form-control mb-4"
                              id="huddleType"
                              // ref={ref}
                              onChange={(e) => {
                                setCountry(e?.target?.value);
                              }}
                            >
                              <option value="">Select countries</option>
                              {countries?.countries?.length &&
                                countries?.countries?.map((country, index) => {
                                  return (
                                    <option
                                      key={country?.id}
                                      value={country?.id}
                                    >
                                      {country?.english}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <LanguageDropDown
                          setLanguage={setLanguage}
                          initialInput={[initialInput]}
                          setInputs={setInputs}
                        />
                        <div className="col-md-4 row justify-content-center align-items-center">
                          <div
                            style={{
                              height: "31px",
                            }}
                            className="col-md-10 row justify-content-center align-items-center my-2   "
                          >
                            <select
                              className="form-control mb-4"
                              id="huddleType"
                              // ref={ref}
                              onChange={(e) => {
                                setPaymentMethod(e.target.value);
                              }}
                            >
                              <option value="">Select Payment Method</option>
                              {data?.paymentMethods?.length &&
                                data?.paymentMethods?.map((method, index) => {
                                  return (
                                    <option key={index} value={method?.id}>
                                      {method?.payment_method}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>

                      {inputs?.map((key, index) => {
                        return (
                          <DefaultField
                            inputs={inputs}
                            key={key}
                            index={index}
                            addNewField={handleAddInput}
                            removeField={handleRemoveInput}
                            handleAddOption={handleAddOption}
                            handleRemoveOption={handleRemoveOption}
                            handleInputChange={handleInputChange}
                            setInputs={setInputs}
                          />
                        );
                      })}

                      <div className="mt-4">
                        <button
                          type="submit"
                          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                          // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                          disabled={disabled}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default AddPaymentSystem;
