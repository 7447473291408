import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { countryOptions } from '../../components/UI/Countries';

const PhoneField = ({ labelName, handleCountryChange, handlePhoneNumberChange, mandatory, countryPlaceholder, phonePlaceholder, countryFieldValue, phoneFieldValue, countryMinWidth, phoneMinWidth }) => {
    return (
        <div className="phoneFieldWrapper" data-testid="phoneFieldWrapper">
            <label>{labelName} {mandatory && <span className="mandatory">*</span>}</label>
            <div className="phoneFieldValueWrap">
                <div className="phoneFieldCountySelect">
                    <Dropdown
                        placeholder={countryPlaceholder}
                        fluid
                        search
                        selection
                        options={countryOptions}
                        onChange={handleCountryChange}
                        defaultValue={"+91"}
                        value={countryFieldValue}
                        style={{ minWidth: countryMinWidth }}
                    />
                </div>
                <div className="phoneFieldText" data-testid="phoneTextField">
                    <input type="number" className="phoneField" placeholder={phonePlaceholder} onChange={handlePhoneNumberChange}
                        style={{ minWidth: phoneMinWidth }}
                        value={phoneFieldValue}
                    />
                </div>
            </div>
        </div>
    )
}

export default PhoneField
