import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Row, UncontrolledAlert } from "reactstrap";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Datatable from "../../../components/UI/Tables/Datatable";
import { blockedUserData } from "../../../helpers/columns";

import Avatar from "../../../assets/images/users/user.svg";
import PremiumImage from "../../../assets/icons/premium.svg";
import verifedImage from "../../../assets/icons/verified.svg";
import { convertToUAETime } from "../../../helpers/utils";
import { DATE_WITH_TIME } from "../../../helpers/constants";
import Loader from "../../../components/Common/Loader";
import Block_icon from "../../../assets/icons/ebud-icons/Block.svg";
import { blockUser, getBlockedUsers } from "../../../store/users/actions";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import UnBlock_icon from "../../../assets/icons/ebud-icons/Unblock.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BlockedUsers = () => {
  const history = useHistory();
  const basicRequest = {
    sort: "count",
    sort_order: "desc",
    page: 1,
    page_count: 50,
    keyword: "",
    status_filter: [],
    report_categories: [],
  };
  const warningAlertRef = useRef(null);
  const [promptMessage, setPromptMessage] = useState("");
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const wholeUserData = useSelector((state) => state?.user);

  const data = useSelector((state) => state?.user?.blockedUsers);

  const dispatch = useDispatch();
  const [request, setRequest] = useState({ ...basicRequest });

  const blockPromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      title: "",
      content: "Are you sure you want to unblock",
    });
  };
  const okHandler = () => {
    dispatch(getBlockedUsers({ ...request, id: promptMessage?.id }));
  };

  useEffect(() => {
    request && dispatch(getBlockedUsers({ ...request }));
  }, [request]);
  const formatUserData = useCallback(() => {
    return (
      data?.leader_blocked_users?.length &&
      data?.leader_blocked_users?.map((user, index) => ({
        ...user,
        no: index + 1,
        profile_img: (
          <div className="profileWrap">
            {user?.verified ? (
              <div className="premium_icon reportedUser">
                <img src={verifedImage} />
              </div>
            ) : user.membership === "Premium" ? (
              <div className="premium_icon reportedUser">
                <img src={PremiumImage} />
              </div>
            ) : null}

            {/* {report.membership === "Premium" ? (
              <div className="premium_icon reportedUser">
                <img src={PremiumImage} />
              </div>
            ) : null} */}
            <div>
              <img
                className="table_profileImg"
                src={user.profile_image === null ? Avatar : user.profile_photo}
              />
            </div>
          </div>
        ),
        blocked_user: (
          <div
            className="cursor-pointer"
            onClick={() => {
              history.push(`/user-details/${user?.blocked_user_id}`);
            }}
          >
            <div>{user?.blocked_account_username}</div>
            <div className="text-muted">{`(${user?.blocked_account_name})`}</div>
          </div>
        ),
        blocked_by: (
          <div
            className="cursor-pointer"
            onClick={() => {
              history.push(`/user-details/${user?.blocked_by_user_id}`);
            }}
          >
            <div>{user?.blocked_by_account_name}</div>
            <div className="text-muted">
              {user?.blocked_by_account_username}
            </div>
          </div>
        ),
        status: (
          <div className="reportStatus bg-soft-green">{user?.blocker_role}</div>
        ),

        blocked_date: (
          <div>
            {user?.time_created &&
              convertToUAETime(user?.time_created, DATE_WITH_TIME)}
          </div>
        ),
        actions: (
          <div className="cust-table-actions-wrap">
            <Button
              className="action-buttons container"
              style={{
                color: "#747d87",
                fontSize: "15px",
                fontWeight: "bold",
              }}
              color=""
              onClick={() => {
                // toggle();
                blockPromptHandler(user?.blocked_user_id);
              }}
            >
              <img width={25} height={25} src={UnBlock_icon}></img>

              {/* {data?.userDetails?.blocked ? "UNBLOCK" : "BLOCK"} */}
            </Button>
          </div>
        ),
      }))
    );
  }, [data?.leader_blocked_users]);
  const userData = useMemo(() => formatUserData(), [formatUserData]);
  return (
    <div>
      <div
        className="page-content"
        data-testid="component-reportedData"
        id="userMgmtList"
        // ref={pageWrapRef}
      >
        <Container fluid>
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Blocked Users" />
            {(wholeUserData?.success || wholeUserData?.error) && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color={wholeUserData?.error ? "danger" : "success"}
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {wholeUserData?.success || wholeUserData?.error}
                </UncontrolledAlert>
              </div>
            )}
            <div className="">
              <Datatable
                defaultSortField={request?.sort || "count"}
                defaultSortAsc={request?.sort_order === "asc" ? true : false}
                setRequest={setRequest}
                request={request}
                columns={blockedUserData}
                rows={userData}
                search
                searchTerm={data?.request?.keyword}
                totalRecords={data?.total}
                loading={wholeUserData?.loading}
              />
            </div>
          </Row>
        </Container>
      </div>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {wholeUserData?.loading && <Loader darkBg={true} />}
      {wholeUserData?.block_loading && <Loader darkBg={true} />}
    </div>
  );
};

export default BlockedUsers;
