import React, { useState } from "react";
import { DateRange } from "react-date-range";
import moment from "moment";
import { Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
export const DatePicker = ({ OnDateSelection, dateType }) => {
  const initDateRange = [
    {
      startDate: new Date("1970,Jan,1"),
      endDate: new Date(),
      key: "selection",
    },
  ];
  const { t } = useTranslation();
  const [modal_center, setmodal_center] = useState(false);
  const [dateRangestate, setDateRangestate] = useState(initDateRange);

  const getDateRangeString = () => {
    if (
      dateRangestate[0].startDate.getTime() ===
      initDateRange[0].startDate.getTime()
    )
      return dateType;
    return (
      moment(dateRangestate[0].startDate).format("DD-MM-YYYY") +
      " - " +
      moment(dateRangestate[0].endDate).format("DD-MM-YYYY")
    );
  };

  const onClickOk = () => {
    if (
      dateRangestate[0].startDate.getTime() ===
      initDateRange[0].startDate.getTime()
    )
      return;
    OnDateSelection({
      from_date: moment(dateRangestate[0].startDate).format("DD-MM-YYYY"),
      to_date: moment(dateRangestate[0].endDate).format("DD-MM-YYYY"),
    });
    tog_center();
  };

  const tog_center = () => {
    setmodal_center(!modal_center);
  };

  return (
    <div>
      <input
        type="text"
        className="inputField_CSS installations-date-range"
        readOnly
        value={getDateRangeString()}
        onClick={tog_center}
      />
      <Modal
        isOpen={modal_center}
        toggle={tog_center}
        centered
        className="date-range-modal"
      >
        <DateRange
          editableDateInputs={true}
          onChange={(item) => setDateRangestate([item.selection])}
          ranges={dateRangestate}
          rangeColors={["#42a5f5"]}
          minDate={new Date("1970,Jan,1")}
          maxDate={new Date()}
          retainEndDateOnFirstSelection={true}
        />
        <div className="modal-footer">
          <button
            className="btn btn-light"
            onClick={() => {
              setDateRangestate(initDateRange);
              tog_center();
              OnDateSelection({ from_date: undefined, to_date: undefined });
            }}
            data-testid="component-filter-cancel"
          >
            {"CLEAR"}
          </button>
          <button
            className="btn bgcolor-btn-green"
            onClick={onClickOk}
            data-testid="component-filter-apply"
          >
            {t("AppInstallations.ok")}
          </button>
        </div>
      </Modal>
    </div>
  );
};
