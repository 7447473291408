import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";

import ParentUser from "./parentUser";
import TreeLevel from "./treeLevel";

import {
  getParentsAndLevels,
  levelClickAction,
  getPaginatedLevel,
  getMlmLevelUsers,
} from "../../../../store/users/userMatrix/actions";
import Loader from "../../../../components/Common/Loader";
import TransferHistory from "./transferHistory";
import FlaxToCoinHistory from "./flaxToCoinHistory";
import CoinsToFlaxHistory from "./coinsToFlaxHistory";
import CoinsPurchaseHistory from "./coinsPurchaseHistory";
import GiftRevenueShare from "./giftRevenueShare";
import CameraPurchaseHistory from "../UserDetail/PodiumCameraPurchaseHistory";
import ChallengeHistoryByUser from "./challengesHistoryByUser";
import RatingHistory from "./ratingHistory";
import GrsSummary from "./grsSummary";
import { checkUserViewPermission } from "../../../../helpers/utils";
import FlixPurchaseHistory from "./flixPurchaseHistory";
import FlixGiftRevenueShare from "./FlixGiftRevenueShare";
import FlixGrsSummary from "./FlixGrsSummary";

const MlmTree = ({ activeTab, data }) => {
  const dispatch = useDispatch();
  const match = useParams();

  const [modal, setModal] = useState(false);

  const [historyModel, setHistoryModel] = useState(false);
  const [coinsToFlaxModal, setCoinsToFlaxModal] = useState(false);
  const [flaxToCoinsModal, setFlaxToCoinsModal] = useState(false);
  const [coinPurchaseModal, setCoinPurchaseModal] = useState(false);
  const [giftRevenueModal, setGiftRevenueModal] = useState(false);
  const [flixGiftRevenueModal, setFlixGiftRevenueModal] = useState(false);
  const [purchaseHistoryModal, setPurchaseHistoryModal] = useState(false);
  const [challengeHisModal, setchallengeHisModal] = useState(false);
  const [restoreRatingmodal, setRestoreRatingModal] = useState(false);
  const [GRSModal, setGRSModal] = useState(false);
  const [FlixGRSModal, setFlixGRSModal] = useState(false);
  const [flixPurchaseHistoryModal, setFlixPurchaseHistoryModal] =
    useState(false);

  const [rolePermisions, setRolePermisions] = useState({});

  const toggle = () => setModal(!modal);
  const toggleHistory = () => {
    setHistoryModel(!historyModel);
  };
  const coinsToFlaxHistory = () => {
    setCoinsToFlaxModal(!coinsToFlaxModal);
  };
  const flaxToCoinsHistory = () => {
    setFlaxToCoinsModal(!flaxToCoinsModal);
  };
  const coinPurchase = () => {
    setCoinPurchaseModal(!coinPurchaseModal);
  };
  const giftRevenue = () => {
    setGiftRevenueModal(!giftRevenueModal);
  };
  const flixGiftRevenue = () => {
    setFlixGiftRevenueModal(!flixGiftRevenueModal);
  };
  const cameraPurchasesShow = () => {
    setPurchaseHistoryModal(!purchaseHistoryModal);
  };
  const challengeHistory = () => {
    setchallengeHisModal(!challengeHisModal);
  };
  const restoreRatingHistory = () => {
    setRestoreRatingModal(!restoreRatingmodal);
  };
  const grsSummary = () => {
    setGRSModal(!GRSModal);
  };
  const flixGrsSummary = () => {
    setFlixGRSModal(!FlixGRSModal);
  };
  const flixPurchaseHistory = () => {
    setFlixPurchaseHistoryModal(!flixPurchaseHistoryModal);
  };
  let treeResponse = useSelector((state) => state.mlmTree);
  const loginData = useSelector((state) => state.Login);

  const [levelClicked, setLevelClicked] = useState([]);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setRolePermisions(loginData?.rolePermissions?.role);
    }
  }, [loginData]);

  const isGRSEnabled = checkUserViewPermission(
    rolePermisions,
    "Other Permissions",
    "Gift revenue summary"
  );
  useEffect(() => {
    if (activeTab === "2") {
      // dispatch(getMlmLevelUsers({ level: 1, userid: match?.id }));
      dispatch(getParentsAndLevels(match.id));
    }
  }, [activeTab]);

  const paginationAction = (request, level) => {
    dispatch(getPaginatedLevel(match.id, { ...request }, level));
  };

  const levelClick = (level, id, visibility) => {
    dispatch(levelClickAction(id));
    if (!visibility && !levelClicked.includes(id)) {
      dispatch(
        getMlmLevelUsers({
          level: level,
          userid: match?.id,
          request: { filter_value: "premium" },
        })
      );
    }
    if (!levelClicked.includes(id)) {
      setLevelClicked([...levelClicked, id]);
    }
  };

  const profilePicClick = (id) => {
    const win = window.open(`/user-details/${id}?tab=2`, "_blank");
    win.focus();
  };

  return (
    <>
      {treeResponse?.error ? (
        <div>
          <UncontrolledAlert
            color="danger"
            className="alert-dismissible fade show"
            role="alert"
          >
            {treeResponse?.error}
          </UncontrolledAlert>
        </div>
      ) : (
        <div className="treeWrapper">
          <div className="row d-flex justify-content-between mr-5 ">
            <div className="superStarAndUserWrap col-12 col-sm-12 col-md-4">
              <h5>User Matrix</h5>
              {treeResponse?.tree?.super_user && (
                <ParentUser
                  profilePicClick={(id) =>
                    profilePicClick(treeResponse?.tree?.super_user.id)
                  }
                  {...treeResponse?.tree?.super_user}
                />
              )}
              {treeResponse?.tree?.selected_user && (
                <ParentUser
                  profilePicClick={(id) =>
                    profilePicClick(treeResponse?.tree?.selected_user.id)
                  }
                  {...treeResponse?.tree?.selected_user}
                />
              )}
            </div>
            {treeResponse?.tree?.cummulativePP && (
              <>
                <div
                  style={{
                    border: "1px solid #e6e6e8",
                    borderRadius: "5px",
                    marginLeft: "10px",
                  }}
                  className="row ml-5 mb-5 col-12 col-sm-12 col-md-6 col-lg-4   pt-3 "
                >
                  <div>
                    <>
                      <div className="row">
                        <div className="col-sm-12">
                          <h5 className="mb-4">FLiX Summary</h5>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className=" col-6 col-md-6 ">
                          <p className="mb-0 text-success fw-bold">
                            Total Credited
                          </p>
                        </div>
                        <div className=" col-6 col-md-4 ">
                          <p className=" mb-0 text-end text-success">
                            <i>FLiX </i>
                            <b>
                              {treeResponse?.tree?.userPP?.total_credited_flax}
                            </b>
                          </p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className=" col-6 col-md-6 ">
                          <p className="mb-0 text-danger fw-bold">
                            Total Debited
                          </p>
                        </div>
                        <div className=" col-6 col-md-4 ">
                          <p className=" mb-0 text-end text-danger">
                            <i>FLiX </i>
                            <b>
                              {treeResponse?.tree?.userPP?.total_debited_flax}
                            </b>
                          </p>
                        </div>
                      </div>
                      <div className="row mt-2 border-bottom">
                        <div className="col-6 col-md-6 ">
                          <b className="mb-0 text-danger fw-bold label-without-wrap">
                            Total Withdrawn
                          </b>
                        </div>
                        <div className="col-6 col-md-4 ">
                          <p className=" mb-0 text-end text-danger">
                            <i>FLiX </i>
                            <b>{treeResponse?.tree?.userPP?.withdrawn_pp}</b>
                          </p>
                        </div>
                      </div>
                      <div className="row mt-2 ">
                        <div className="col-6 col-md-6 ">
                          <p className="mb-0">
                            <b>Current Total Balance</b>
                          </p>
                        </div>
                        <div className="col-6 col-md-4 ">
                          <p className="text-muted mb-0 text-end">
                            <i>FLiX </i>
                            <b>
                              {treeResponse?.tree?.userPP?.total_available_flax}
                            </b>
                          </p>
                        </div>
                      </div>

                      <div className="row mt-4  ">
                        <div className="col-10">
                          <p
                            onClick={toggle}
                            className="text-danger cursor-pointer text-decoration-underline"
                          >
                            View Detailed Summary
                          </p>
                        </div>
                      </div>

                      <div className="row mt-1 mb-2 ">
                        <div className="col-10">
                          <p
                            onClick={toggleHistory}
                            className="text-success cursor-pointer text-decoration-underline"
                          >
                            View FLiX Transaction History
                          </p>
                        </div>
                      </div>

                      <div className="row mt-1 mb-2 ">
                        <div className="col-10">
                          <p
                            style={{
                              color: "#b227c1",
                            }}
                            onClick={flaxToCoinsHistory}
                            className=" cursor-pointer text-decoration-underline"
                          >
                            View FLiX to COiNS Conversion History
                          </p>
                        </div>
                      </div>

                      <div className="row mt-1 mb-2 ">
                        <div className="col-10">
                          <p
                            style={{
                              color: "#b227c1",
                            }}
                            onClick={coinsToFlaxHistory}
                            className=" cursor-pointer text-decoration-underline"
                          >
                            View COiNS To FLiX Conversion History
                          </p>
                        </div>
                      </div>
                      <div className="row mt-1 mb-2 ">
                        <div className="col-10">
                          <p
                            style={{
                              color: "#b227c1",
                            }}
                            onClick={giftRevenue}
                            className=" cursor-pointer text-decoration-underline"
                          >
                            View Gift Revenue Share
                          </p>
                        </div>
                      </div>
                      <div className="row mt-1 mb-2 ">
                        <div className="col-10">
                          <p
                            style={{
                              color: "#b227c1",
                            }}
                            onClick={flixGiftRevenue}
                            className=" cursor-pointer text-decoration-underline"
                          >
                            View FLiX Gifts Revenue share
                          </p>
                        </div>
                      </div>
                      <div className="row mt-1 mb-2 ">
                        <div className="col-10">
                          <p
                            style={{
                              color: "#b227c1",
                            }}
                            onClick={coinPurchase}
                            className=" cursor-pointer text-decoration-underline"
                          >
                            View COiNS Purchase History
                          </p>
                        </div>
                      </div>
                      <div className="row mt-1 mb-2 ">
                        <div className="col-10">
                          <p
                            style={{
                              color: "#b227c1",
                            }}
                            onClick={cameraPurchasesShow}
                            className=" cursor-pointer text-decoration-underline"
                          >
                            Podium Camera Purchase History
                          </p>
                        </div>
                      </div>
                      <div className="row mt-1 mb-2 ">
                        <div className="col-10">
                          <p
                            style={{
                              color: "#b227c1",
                            }}
                            onClick={challengeHistory}
                            className=" cursor-pointer text-decoration-underline"
                          >
                            Challenges Detailed Summary
                          </p>
                        </div>
                      </div>
                      <div className="row mt-1 mb-2 ">
                        <div className="col-10">
                          <p
                            style={{
                              color: "#b227c1",
                            }}
                            onClick={restoreRatingHistory}
                            className=" cursor-pointer text-decoration-underline"
                          >
                            Restore Rating History
                          </p>
                        </div>
                      </div>
                      {isGRSEnabled && (
                        <>
                          <div className="row mt-1 mb-2 ">
                            <div className="col-10">
                              <p
                                style={{
                                  color: "#b227c1",
                                }}
                                onClick={grsSummary}
                                className=" cursor-pointer text-decoration-underline"
                              >
                                View Gift Revenue Summary
                              </p>
                            </div>
                          </div>
                          <div className="row mt-1 mb-2 ">
                            <div className="col-10">
                              <p
                                style={{
                                  color: "#b227c1",
                                }}
                                onClick={flixGrsSummary}
                                className=" cursor-pointer text-decoration-underline"
                              >
                                View FLiX Gift Revenue share summary
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="row mt-1 mb-2 ">
                        <div className="col-10">
                          <p
                            style={{
                              color: "#b227c1",
                            }}
                            onClick={flixPurchaseHistory}
                            className=" cursor-pointer text-decoration-underline"
                          >
                            View FLiX Purchase History
                          </p>
                        </div>
                      </div>
                      {flixPurchaseHistoryModal && (
                        <FlixPurchaseHistory
                          modal={flixPurchaseHistoryModal}
                          toggle={flixPurchaseHistory}
                          userId={match?.id}
                        />
                      )}

                      {GRSModal && (
                        <GrsSummary
                          modal={GRSModal}
                          toggle={grsSummary}
                          userId={match?.id}
                          user={data?.userDetails?.name}
                          profilePhoto={data?.userDetails?.profile_photo}
                          premiumstatus={data?.userDetails?.is_premium}
                        />
                      )}
                      {FlixGRSModal && (
                        <FlixGrsSummary
                          modal={FlixGRSModal}
                          toggle={flixGrsSummary}
                          userId={match?.id}
                          user={data?.userDetails?.name}
                          profilePhoto={data?.userDetails?.profile_photo}
                          premiumstatus={data?.userDetails?.is_premium}
                        />
                      )}
                      {restoreRatingmodal && (
                        <RatingHistory
                          modal={restoreRatingmodal}
                          toggle={restoreRatingHistory}
                          userId={match?.id}
                        />
                      )}
                      {challengeHisModal && (
                        <ChallengeHistoryByUser
                          modal={challengeHisModal}
                          toggle={challengeHistory}
                          userId={match?.id}
                        />
                      )}
                      {purchaseHistoryModal && (
                        <CameraPurchaseHistory
                          modal={purchaseHistoryModal}
                          toggle={cameraPurchasesShow}
                          userId={match?.id}
                        />
                      )}
                      {historyModel && (
                        <TransferHistory
                          modal={historyModel}
                          toggle={toggleHistory}
                          userId={match?.id}
                        />
                      )}
                      {flaxToCoinsModal && (
                        <FlaxToCoinHistory
                          modal={flaxToCoinsModal}
                          toggle={flaxToCoinsHistory}
                          userId={match?.id}
                        />
                      )}
                      {coinsToFlaxModal && (
                        <CoinsToFlaxHistory
                          modal={coinsToFlaxModal}
                          toggle={coinsToFlaxHistory}
                          userId={match?.id}
                        />
                      )}
                      {giftRevenueModal && (
                        <GiftRevenueShare
                          modal={giftRevenueModal}
                          toggle={giftRevenue}
                          userId={match?.id}
                        />
                      )}
                      {flixGiftRevenueModal && (
                        <FlixGiftRevenueShare
                          modal={flixGiftRevenueModal}
                          toggle={flixGiftRevenue}
                          userId={match?.id}
                        />
                      )}

                      {coinPurchaseModal && (
                        <CoinsPurchaseHistory
                          modal={coinPurchaseModal}
                          toggle={coinPurchase}
                          userId={match?.id}
                        />
                      )}
                    </>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="levelsWrap">
            {treeResponse?.tree?.levels &&
              Object.entries(treeResponse?.tree?.levels)?.map(
                (level, index) => {
                  return (
                    <>
                      <TreeLevel
                        key={index}
                        levelName={level[0]}
                        {...level[1]}
                        level={treeResponse[`level${index + 1}`]}
                        id={`level${index + 1}`}
                        levelClickHandler={(id, visibility) =>
                          levelClick(index + 1, id, visibility)
                        }
                        paginationAction={paginationAction}
                        levelNo={level[0]}
                      />
                    </>
                  );
                }
              )}
          </div>
        </div>
      )}

      {/* flax detailed summary modal */}

      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>FLiX summary</ModalHeader>
        <ModalBody>
          {treeResponse?.tree?.cummulativePP && (
            <>
              <div className=" row px-4">
                <div className="card-body col-12 col-md-6">
                  <h5 className="text-success">Credited</h5>
                  <div className="row mb-2 border-bottom">
                    <div className="col-6">
                      <p className="mb-0">Generated </p>
                    </div>
                    <div className="col-4">
                      <p className="text-muted text-end">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.total_generated_pp}
                      </p>
                    </div>
                  </div>

                  <div className="row mb-2 border-bottom">
                    <div className="col-6">
                      <p className="mb-0">Mining </p>
                    </div>
                    <div className="col-4 ">
                      <p className="text-muted text-end">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.total_awarded_pp}
                      </p>
                    </div>
                  </div>

                  <div className="row mb-2 border-bottom">
                    <div className="col-6">
                      <p className="mb-0">Refunded </p>
                    </div>
                    <div className="col-4">
                      <p className="text-muted mb-0 text-end">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.total_refunded_pp}
                      </p>
                    </div>
                  </div>

                  <div className="row mb-2 border-bottom">
                    <div className="col-6">
                      <p className="mb-0">Manual FLiX </p>
                    </div>
                    <div className="col-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.manual_pp}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-6">
                      <p className="mb-0">Converted from COiNS</p>
                    </div>
                    <div className="col-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.sold_points_flax}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-6">
                      <p className="mb-0">Purchased </p>
                    </div>
                    <div className="col-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.purchased_flax}
                      </p>
                    </div>
                  </div>

                  <div className="row  border-bottom">
                    <div className="col-6">
                      <p className="mb-0">Received </p>
                    </div>
                    <div className="col-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.received_flax}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card-body col-12 mt-4 ">
                  <h5 className="text-danger">Debited</h5>
                  <div className="row mb-2 border-bottom">
                    <div className="col-6">
                      <p className="mb-0">Converted to COiNS</p>
                    </div>
                    <div className="col-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.purchased_points_flax}
                      </p>
                    </div>
                  </div>

                  <div className="row mb-2 border-bottom">
                    <div className="col-6">
                      <p className="mb-0">Sent </p>
                    </div>
                    <div className="col-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.sent_flax}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-6">
                      <p className="mb-0">Pending Review </p>
                    </div>
                    <div className="col-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.pending_pp}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-6">
                      <p className="mb-0">FLiX Utilized</p>
                    </div>
                    <div className="col-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>

                        {treeResponse?.tree?.userPP?.podium_camera_purchase}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-6">
                      <p className="mb-0">Cancellation Deductions </p>
                    </div>
                    <div className="col-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.cancelled_deductions}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-6">
                      <p className="mb-0">FLiX on Hold </p>
                    </div>
                    <div className="col-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.violation_flix}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-6">
                      <p className="mb-0">Total Withdrawn </p>
                    </div>
                    <div className="col-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.withdrawn_pp}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "-50px",
                  }}
                  className="card-body col-12 "
                ></div>
                <div className="card-body">
                  <div className="row   ">
                    <div className="col-4">
                      <p className="mb-0 text-success fw-bold">
                        Total Credited
                      </p>
                    </div>
                    <div className="col-6">
                      <p className=" mb-0 text-end text-success">
                        <i>FLiX </i>
                        <b>{treeResponse?.tree?.userPP?.total_credited_flax}</b>
                      </p>
                    </div>
                  </div>
                  <div className="row mt-2  ">
                    <div className="col-4">
                      <p className="mb-0 text-danger fw-bold">Total Debited</p>
                    </div>
                    <div className="col-6">
                      <p className=" mb-0 text-end text-danger">
                        <i>FLiX </i>
                        <b>{treeResponse?.tree?.userPP?.total_debited_flax}</b>
                      </p>
                    </div>
                  </div>
                  {/* <div className="row mt-2 border-bottom">
                                      <div className="col-6">
                                        <p className="mb-0 text-danger fw-bold">
                                          Total Withdrawn
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p className=" mb-0 text-end text-danger">
                                          <i>flax </i>
                                          <b>
                                            {
                                              treeResponse?.tree?.userPP
                                                ?.withdrawn_pp
                                            }
                                          </b>
                                        </p>
                                      </div>
                                    </div> */}
                  <div className="row mt-2 ">
                    <div className="col-6">
                      <p className="mb-0">
                        <b>Current Total Balance</b>
                      </p>
                    </div>
                    <div className="col-4">
                      <p className="text-muted mb-0 text-end">
                        <i>FLiX </i>
                        <b>
                          {treeResponse?.tree?.userPP?.total_available_flax}
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
      {treeResponse?.loading && <Loader darkBg={true} />}
    </>
  );
};

export default MlmTree;
