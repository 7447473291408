import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Datatable from "../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../components/UI/Breadcrumb";
import Loader from "../../components/Common/Loader";
import Block_icon from "../../assets/icons/ebud-icons/Block.svg";

import {
  getReportedData,
  getCategoryData,
  storeRequest,
  deleteReportedData,
  resetMessage,
} from "../../store/reportedUser/action";

import { reportedData } from "../../helpers/columns";
import Avatar from "../../assets/images/users/user.svg";
import PremiumImage from "../../assets/icons/premium.svg";
import verifedImage from "../../assets/icons/verified.svg";
import Delete_icon from "../../assets/icons/ebud-icons/Delete.svg";
import Eye_icon from "../../assets/icons/ebud-icons/Eye.svg";
import { convertToUAETime, checkUserPermission } from "../../helpers/utils";
import { DATE_WITH_TIME } from "../../helpers/constants";
import ConfirmationAlert from "../../components/UI/ConfirmationAlert";
import Checkbox from "../../components/UI/Checkbox";
import { blockUser, resetErrors } from "../../store/users/actions";
import ReportSummary from "./ReportSummary";

const UserManagement = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const NOTIFICATION_MODEL = {
    title: "",
    message: "",
    push: false,
  };
  const [modal, setModal] = useState(false);

  const [showNotificationForm, setShowNotificationForm] = useState(null);

  const [notification, setNotification] = useState(NOTIFICATION_MODEL);
  const [userId, setUserId] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const reportedUser = useSelector((state) => state.reportedUser);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const loginData = useSelector((state) => state.Login);
  const data = useSelector((state) => state.user);
  const [userPermissions, setUserPermissions] = useState({});
  const isDeleteable = userPermissions?.delete || false;
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [active, setActive] = useState(null);
  const [notificationKey, setNotificationKey] = useState(null);

  const basicRequest = {
    sort: "count",
    sort_order: "desc",
    page: 1,
    page_count: 50,
    keyword: "",
    status_filter: [],
    report_categories: [],
  };
  const toggle = () => {
    setModal(!modal);
    setShowNotificationForm(null);
    setActive(null);
  };

  const blockPromptHandler = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      title: "",
      content: `Are you sure you want to ${
        data?.userDetails?.blocked ? "unblock" : "block"
      } this User?`,
      type: `${data?.userDetails?.blocked ? "unblock" : "block"}`,
    });
  };
  useEffect(() => {
    if (notification.message !== "" && notification.title !== "")
      setDisabled(false);
    else setDisabled(true);
  }, [notification]);

  const [request, setRequest] = useState({ ...basicRequest });

  // useEffect(() => {
  //   if (loginData?.rolePermissions?.role) {
  //     setUserPermissions(
  //       checkUserPermission(loginData?.rolePermissions?.role, "Reported users")
  //     );
  //   }
  // }, [loginData]);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(loginData?.rolePermissions?.role, "Reported users")
      );
    }
  }, [loginData]);

  useEffect(() => {
    request && dispatch(getReportedData({ ...request }));
    request && dispatch(storeRequest({ ...request }));
    dispatch(resetMessage());
    dispatch(resetErrors());
  }, [request]);

  useEffect(() => {
    dispatch(getCategoryData());
  }, []);

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      title: "",
      content: t("reportedData.deleteConfirm"),
      type: "delete",
    });
  };

  useEffect(() => {
    scrollToAlertMessage();
  }, [reportedUser?.error]);

  useEffect(() => {
    !history?.location?.state?.showMessage && dispatch(resetMessage());
  }, [history?.location?.state?.showMessage]);

  const scrollToAlertMessage = () => {
    if (reportedUser?.error) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  const okHandler = () => {
    promptMessage?.type === "delete" &&
      dispatch(
        deleteReportedData({
          data: promptMessage.id,
          callback: () => dispatch(getReportedData(request)),
        })
      );
    if (promptMessage?.type === "block") {
      dispatch(
        blockUser({
          data: { blocked: true },
          id: reportedUser?.reportedData?.reported_users[selectedIndex]
            ?.reported_id,
        })
      );
    }

    if (promptMessage?.type === "unblock") {
      dispatch(
        blockUser({
          data: { blocked: false },
          id: reportedUser?.reportedData?.reported_users[selectedIndex]
            ?.reported_id,
        })
      );
    }
  };

  const formatUserData = useCallback(() => {
    return reportedUser?.reportedData?.reported_users?.map((report, index) => ({
      ...report,
      no: index + 1,
      profile_img: (
        <div className="profileWrap">
          {report?.verified ? (
            <div className="premium_icon reportedUser">
              <img src={verifedImage} />
            </div>
          ) : report.membership === "Premium" ? (
            <div className="premium_icon reportedUser">
              <img src={PremiumImage} />
            </div>
          ) : null}

          {/* {report.membership === "Premium" ? (
            <div className="premium_icon reportedUser">
              <img src={PremiumImage} />
            </div>
          ) : null} */}
          <div>
            <img
              className="table_profileImg"
              src={
                report.profile_photo === null ? Avatar : report.profile_photo
              }
            />
          </div>
        </div>
      ),
      reported_name: (
        <div
          className="cursor-pointer"
          onClick={() => {
            history.push(`/user-details/${report?.reported_id}`);
          }}
        >
          <div>{report.reported_name}</div>
          <div className="text-muted">{`(${report.reported_username})`}</div>
        </div>
      ),
      reported_by: (
        <div
          className="cursor-pointer"
          onClick={() => {
            history.push(`/user-details/${report?.reported_by_id}`);
          }}
        >
          <div>{report.reported_by ? report.reported_by : "Deleted User"}</div>
          <div className="text-muted">
            {report.reported_by_username
              ? `(${report.reported_by_username})`
              : ""}
          </div>
        </div>
      ),

      status: (
        <div
          className={
            report.status === "active"
              ? "reportStatus bg-soft-red"
              : "reportStatus bg-soft-green"
          }
        >
          {report.status}
        </div>
      ),
      category: (
        <div title={report.category} className="rdtCategory">
          {report.category}
        </div>
      ),
      reported_date: (
        <div>
          {report?.reported_date &&
            convertToUAETime(report?.reported_date, DATE_WITH_TIME)}
        </div>
      ),
      actions: (
        <div className="cust-table-actions-wrap">
          <button
            onClick={() => deletePromptHandler(report.id)}
            className=" color-red action-btn"
            title="Delete"
            disabled={!isDeleteable}
          >
            <img
              src={Delete_icon}
              alt="Delete"
              style={{ opacity: !isDeleteable ? "50%" : "" }}
            />
          </button>
        </div>
      ),
      view: (
        <div className="cust-table-actions-wrap ">
          <button
            onClick={() => {
              toggle();
              setSelectedIndex(index);
            }}
            className=" color-red action-btn"
            title="Delete"
            // disabled={!isDeleteable}
          >
            <img
              src={Eye_icon}
              alt="Delete"
              style={{ opacity: !isDeleteable ? "50%" : "" }}
            />
          </button>
        </div>
      ),
    }));
  }, [reportedUser?.reportedData?.reported_users, isDeleteable]);

  //memoized user data
  const rptData = useMemo(() => formatUserData(), [formatUserData]);

  return (
    <div
      className="page-content"
      data-testid="component-reportedData"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Container fluid>
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Reported Users" />
          {(reportedUser?.listing_error ||
            reportedUser?.error ||
            reportedUser?.success ||
            data?.success ||
            data?.error) && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                key={notificationKey}
                color={
                  reportedUser?.error ||
                  reportedUser?.listing_error ||
                  data?.error
                    ? "danger"
                    : "success"
                }
                className="alert-dismissible fade show"
                role="alert"
              >
                {reportedUser?.listing_error ||
                  reportedUser?.error ||
                  reportedUser?.success ||
                  data?.success ||
                  data?.error}
              </UncontrolledAlert>
            </div>
          )}
          {selectedIndex !== null &&
            reportedUser?.reportedData?.reported_users?.length >= 0 && (
              <ReportSummary
                specificUser={
                  reportedUser?.reportedData?.reported_users[selectedIndex]
                }
                data={data}
                toggle={toggle}
                modal={modal}
                setModal={setModal}
                active={active}
                setActive={setActive}
                notificationKey={notificationKey}
                setNotificationKey={setNotificationKey}
                userId={userId}
                setUserId={setUserId}
                setNotification={setNotification}
                notification={notification}
                NOTIFICATION_MODEL={NOTIFICATION_MODEL}
                disabled={disabled}
                warningForm
                notificationForm
                setShowNotificationForm={setShowNotificationForm}
                blockPromptHandler={blockPromptHandler}
                showNotificationForm={showNotificationForm}
              />
            )}
          <Datatable
            defaultSortField={request?.sort || "count"}
            defaultSortAsc={request?.sort_order === "asc" ? true : false}
            tableID={"reportedData"}
            columns={reportedData}
            rows={rptData}
            setRequest={setRequest}
            request={request}
            totalRecords={reportedUser?.reportedData?.total}
            search
            reportedFilter
            searchTerm={reportedUser?.request?.keyword}
            //loading={data?.loading}
            tableCardClassName={"snoTable"}
            categoryFilter={reportedUser?.reportCategory}
          />
        </Row>
      </Container>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {reportedUser?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default UserManagement;
