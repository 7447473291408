import React from "react";

import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";

const TopSection = ({
  data,
  id,
  deleteDocument,
  viewPublishedVersion,
  setViewPublishedVersion,
}) => {
  return (
    <div className="legalEdit_policyInformation">
      <div>
        <div
          className={`badge ${
            data?.document?.privacy_policies?.status === "Published"
              ? "bg-soft-green"
              : "bg-soft-gray"
          } font-size-14`}
        >
          {data?.document?.privacy_policies?.status}
        </div>
      </div>
      {data?.document?.privacy_policies?.status === "Drafted" && (
        <>
          <div className="text-center">
            <a onClick={() => setViewPublishedVersion(!viewPublishedVersion)}>
              View Published Version
            </a>
          </div>

          <div className="editLegalDelete">
              <button className="action-buttons " onClick={() => deleteDocument(id)}>
                <img src={Delete_icon} />
                <span className="color-red">DELETE</span>
              </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TopSection;
