export const GET_GENERAL_SETTINGS = "GET_GENERAL_SETTINGS";
export const GET_GENERAL_SETTINGS_BEGIN = "GET_GENERAL_SETTINGS_BEGIN";
export const GET_GENERAL_SETTINGS_SUCCESS = "GET_GENERAL_SETTINGS_SUCCESS";
export const GET_GENERAL_SETTINGS_FAIL = "GET_GENERAL_SETTINGS_FAIL";

export const SAVE_GENERAL_SETTINGS = "SAVE_GENERAL_SETTINGS";
export const SAVE_GENERAL_SETTINGS_BEGIN = "SAVE_GENERAL_SETTINGS_BEGIN";
export const SAVE_GENERAL_SETTINGS_SUCCESS = "SAVE_GENERAL_SETTINGS_SUCCESS";
export const SAVE_GENERAL_SETTINGS_FAIL = "SAVE_GENERAL_SETTINGS_FAIL";

export const SAVE_IP_FOR_REMOVE = "SAVE_IP_FOR_REMOVE";
export const SAVE_IP_FOR_REMOVE_BEGIN = "SAVE_IP_FOR_REMOVE_BEGIN";
export const SAVE_IP_FOR_REMOVE_SUCCESS = "SAVE_IP_FOR_REMOVE_SUCCESS";
export const SAVE_IP_FOR_REMOVE_FAIL = "SAVE_IP_FOR_REMOVE_FAIL";

export const GET_PP_SETTINGS = "GET_PP_SETTINGS";
export const GET_PP_SETTINGS_BEGIN = "GET_PP_SETTINGS_BEGIN";
export const GET_PP_SETTINGS_SUCCESS = "GET_PP_SETTINGS_SUCCESS";
export const GET_PP_SETTINGS_FAIL = "GET_PP_SETTINGS_FAIL";

export const SAVE_PP_SETTINGS = "SAVE_PP_SETTINGS";
export const SAVE_PP_SETTINGS_BEGIN = "SAVE_PP_SETTINGS_BEGIN";
export const SAVE_PP_SETTINGS_SUCCESS = "SAVE_PP_SETTINGS_SUCCESS";
export const SAVE_PP_SETTINGS_FAIL = "SAVE_PP_SETTINGS_FAIL";

export const GET_PAYOUT_ELIGIBILITY_SETTINGS =
  "GET_PAYOUT_ELIGIBILITY_SETTINGS";
export const GET_PAYOUT_ELIGIBILITY_SETTINGS_BEGIN =
  "GET_PAYOUT_ELIGIBILITY_SETTINGS_BEGIN";
export const GET_PAYOUT_ELIGIBILITY_SETTINGS_SUCCESS =
  "GET_PAYOUT_ELIGIBILITY_SETTINGS_SUCCESS";
export const GET_PAYOUT_ELIGIBILITY_SETTINGS_FAIL =
  "GET_PAYOUT_ELIGIBILITY_SETTINGS_FAIL";
export const UPDATE_PAYOUT_ELIGIBILITY_SETTINGS =
  "UPDATE_PAYOUT_ELIGIBILITY_SETTINGS";
export const UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_BEGIN =
  "UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_BEGIN";
export const UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_SUCCESS =
  "UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_SUCCESS";
export const UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_FAIL =
  "UPDATE_PAYOUT_ELIGIBILITY_SETTINGS_FAIL";
