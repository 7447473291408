import { GET_APPREVIEW_REQUEST_BEGIN,GET_APPREVIEW_UPDATE_BEGIN, GET_APPREVIEW_REQUEST_SUCCESS, GET_APPREVIEW_REQUEST_FAIL,GET_APP_REVIEWS_DATA, UPDATE_APP_REVIEW } from "./actionTypes";

export const getAppReviewRequests = (payload) => ({
    type: GET_APP_REVIEWS_DATA,
    request : payload
  });

export const getAppReviewRequestsBegin = () => ({
    type: GET_APPREVIEW_REQUEST_BEGIN
  });

export const getAppReviewRequestsSuccess = (payload) => ({
   type: GET_APPREVIEW_REQUEST_SUCCESS,
   payload
})

export const getAppReviewRequestsFail = (payload) => ({
    type: GET_APPREVIEW_REQUEST_FAIL,
    payload
});

export const updateAppReview = (payload) => ({
    type: UPDATE_APP_REVIEW,
    payload
})

export const getAppReviewUpdateBegin = () => ({
    type: GET_APPREVIEW_UPDATE_BEGIN
  });
  