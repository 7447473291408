import React, { useEffect, useState } from "react";
import { Col, Card, Row, CardTitle, UncontrolledAlert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parsePhoneNumber from "libphonenumber-js/mobile";

import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import PhoneField from "../../../components/UI/PhoneField";
import Button from "../../../components/UI/Button";

import Loader from "../../../components/Common/Loader";

import { EMAIL_REGEX } from "../../../helpers/constants";

import {
  addReservedName,
  resetMessage,
} from "../../../store/usernames/reserveUserNames/action";
import {
  checkUsernameAvailability,
  resetError,
  resetErrorWithUsername,
} from "../../../store/usernames/allocateUserNames/action";

const ReservedAddNew = () => {
  const [warning, setWarning] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [formChanged, setFormChanged] = useState(false);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [mobile, setMobile] = useState();
  const [countryCode, setCountryCode] = useState("+91");
  const [selected, setSelected] = useState("1");
  const [backToListing, setBackToListing] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const data = useSelector((state) => state.reserveName);
  const usernameData = useSelector((state) => state.allocatedUsername);

  useEffect(() => {
    dispatch(resetMessage());
  }, []);

  useEffect(() => {
    dispatch(resetError());
    if (userName && new RegExp(EMAIL_REGEX).test(userName)) {
      setWarning(t("AllocateUserName.formatWarn"));
    } else setWarning("");
  }, [userName]);

  useEffect(() => {
    if (selected === "3")
      userName &&
      email &&
      new RegExp(EMAIL_REGEX).test(email) &&
      (usernameData?.available || usernameData?.availableUsername === userName)
        ? setIsButtonDisabled(false)
        : setIsButtonDisabled(true);

    if (selected === "2")
      userName &&
      mobile &&
      parsePhoneNumber(`${countryCode}${mobile}`)?.isValid() &&
      (usernameData?.available || usernameData?.availableUsername === userName)
        ? setIsButtonDisabled(false)
        : setIsButtonDisabled(true);
    if (selected === "1")
      userName &&
      (usernameData?.available || usernameData?.availableUsername === userName)
        ? setIsButtonDisabled(false)
        : setIsButtonDisabled(true);
  }, [selected, userName, mobile, email, countryCode, usernameData]);

  useEffect(() => {
    backToListing && history.push("/reserved-usernames");
  }, [backToListing]);

  const handleValidSubmit = (event, values) => {
    let finalData = { ...values };
    if (mobile) {
      finalData = {
        username: values.username,
        country_code: countryCode,
        phone: mobile,
      };
    }
    dispatch(
      addReservedName({
        data: finalData,
        callback: () =>
          history.push({
            pathname: "/reserved-usernames",
            state: { showMessage: true },
          }),
      })
    );
  };

  const confirmBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
  };

  const redirectMethod = () => {
    dispatch(resetErrorWithUsername());
    setBackToListing(true);
  };
  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };

  const handleCountryChangeHandler = (e, data) => {
    setCountryCode(data?.value);
  };

  const handlePhoneNumberChangeHandler = (e) => {
    setMobile(e.target.value);
  };

  const checkAvailabiltyHandler = (e) => {
    e.preventDefault();
    dispatch(checkUsernameAvailability({ username: userName }));
  };

  return (
    <div className="page-content" data-testid="component-reservedAddNew">
      <Prompt
        when={formChanged}
        message={(location, action) => {
          if (action === "POP") {
            return confirmBrowserBack();
          }
        }}
      />

      <BackButton
        label={t("ReserveUserName.Title")}
        handleClick={() => (formChanged ? confirmBack() : redirectMethod())}
      />
      <Breadcrumbs title="Tables" breadcrumbItem={t("ReserveUserName.add")} />
      {data?.error && typeof data?.error === "string" ? (
        <UncontrolledAlert
          color="danger"
          className="alert-dismissible fade show"
          role="alert"
        >
          {data?.error}
        </UncontrolledAlert>
      ) : (
        data?.error?.username && (
          <UncontrolledAlert
            color="danger"
            className="alert-dismissible fade show"
            role="alert"
          >
            {data?.error?.username?.[0]}
          </UncontrolledAlert>
        )
      )}
      <AvForm
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <Col xl="12">
          <Card className="p-4">
            <CardTitle className="h3 mt-0 mb-4">
              {t("ReserveUserName.linkedTo")} :
            </CardTitle>
            <Row className="col-md-12 mb-4">
              <div className="col-md-4">
                <div className="form-check">
                  <input
                    className="form-check-input cursor-pointer"
                    type="radio"
                    name="reservedNameLinkedTo"
                    id="notlinkedID"
                    value="1"
                    defaultChecked
                    onChange={(e) => {
                      setMobile();
                      setSelected(e?.target?.value);
                    }}
                  />
                  <label className="form-check-label" htmlFor="notlinkedID">
                    {t("ReserveUserName.notLinked")}
                  </label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-check">
                  <input
                    className="form-check-input cursor-pointer"
                    type="radio"
                    name="reservedNameLinkedTo"
                    id="mobileNumberID"
                    value="2"
                    onChange={(e) => setSelected(e?.target?.value)}
                  />
                  <label className="form-check-label" htmlFor="mobileNumberID">
                    {t("ReserveUserName.mobile")}
                  </label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-check">
                  <input
                    className="form-check-input cursor-pointer"
                    type="radio"
                    name="reservedNameLinkedTo"
                    id="emailAddrID"
                    value="3"
                    onChange={(e) => {
                      setMobile();
                      setSelected(e?.target?.value);
                    }}
                  />
                  <label className="form-check-label" htmlFor="emailAddrID">
                    {t("ReserveUserName.email")}
                  </label>
                </div>
              </div>
            </Row>
            <Row className="col-md-12 addUsernameFieldWrap">
              <div className="col-md-5">
                <div className="checkAvailabilityWrap">
                  <AvField
                    name="username"
                    label={
                      <>
                        {t("ReserveUserName.username")}
                        <span className="mandatory">*</span>
                      </>
                    }
                    placeholder={t("ReserveUserName.usernamePlaceholder")}
                    validate={{
                      maxLength: {
                        value: 50,
                        errorMessage: t("ReserveUserName.maxlen"),
                      },
                    }}
                    onChange={(e) => {
                      setFormChanged(true);
                      setUserName(e.target.value);
                    }}
                  />
                  <Button
                    buttonClass={
                      "btn waves-effect waves-light cust_no_shadow check_availability_button"
                    }
                    disableBtn={!userName}
                    handleClick={(e) => checkAvailabiltyHandler(e)}
                    label={t("Common.checkAvailable")}
                  />
                </div>
                {warning && (
                  <p className="bg-soft-orange warningSection">
                    <i className="fa fa-exclamation-triangle warningIcon"></i>
                    {warning}
                  </p>
                )}
                {usernameData?.availableError && (
                  <div className="color-red">
                    {usernameData?.availableError}
                  </div>
                )}
                {usernameData?.available && (
                  <div className="color-green">{usernameData?.available}</div>
                )}
              </div>
              <div className="col-md-1"></div>
              {selected === "2" && (
                <div className="col-md-6">
                  <PhoneField
                    labelName={t("ReserveUserName.enterMobile")}
                    countryPlaceholder="Select Country Code"
                    phonePlaceholder={t("ReserveUserName.enterMobile")}
                    handleCountryChange={handleCountryChangeHandler}
                    handlePhoneNumberChange={handlePhoneNumberChangeHandler}
                    mandatory={true}
                  />
                </div>
              )}

              {selected === "3" && (
                <div className="col-md-4">
                  <AvField
                    name="email"
                    type="text"
                    errorMessage={t("Common.error.email")}
                    label={
                      <>
                        {t("ReserveUserName.enterEmail")}
                        <span className="mandatory">*</span>
                      </>
                    }
                    placeholder={t("ReserveUserName.enterEmail")}
                    required
                    onChange={(e) => {
                      setFormChanged(true);
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              )}
            </Row>
            <div className="mt-4 text-center">
              <button
                type="submit"
                className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                disabled={isButtonDisabled}
              >
                {t("Common.save")}
              </button>
            </div>
          </Card>
        </Col>
      </AvForm>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={redirectMethod}
      />
      {data?.loading && <Loader darkBg={true}/>}
    </div>
  );
};

export default ReservedAddNew;
