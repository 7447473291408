import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Card, Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import premiumImage from "../../assets/icons/premium.svg";

import Datatable from "../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../components/UI/ConfirmationAlert";
import { DATE_WITH_TIME_IN_SECONDS } from "../../helpers/constants";
import Loader from "../../components/Common/Loader";
import { convertToUAETime } from "../../helpers/utils";

import defaultProfile from "../../assets/images/users/user.svg";
// import checkBox from "../../assets/icons/checkbox.svg";

import { huddleColumnData, groupColumnData } from "../../helpers/columns";
import { checkUserPermission } from "../../helpers/utils";
import { getHuddles, deleteHuddleList } from "../../store/huddles/actions";
import { Checkbox } from "semantic-ui-react";

const PRIVATE_TYPE = "private";
const PUBLIC_TYPE = "public";

const HuddleManagement = ({ userId }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Huddles);
  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;
  const isDeleteable = userPermissions?.delete || false;
  const [selectedHuddles, setSelectedHuddles] = useState([]);
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] =
    useState("");

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();

  const type =
    location.pathname === "/groups"
      ? PRIVATE_TYPE
      : location.pathname === "/huddles"
      ? PUBLIC_TYPE
      : null;
  const title = type === PRIVATE_TYPE ? "Groups" : "Huddles";

  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    membership_filter: [""],
    page_count: 50,
    keyword: "",
    user_id: userId,
    type,
  };
  const [request, setRequest] = useState({ ...basicRequest });

  useEffect(() => {
    request && dispatch(getHuddles(request));
  }, [request]);

  useEffect(() => {
    if (location?.pathname === "/groups") {
      setDeleteConfirmationMessage(
        "Do you really want to delete the selected Groups?"
      );
    } else {
      setDeleteConfirmationMessage(
        "Do you really want to delete the selected Huddles?"
      );
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Users"
        )
      );
    }
  }, [loginData]);

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error]);

  const scrollToAlertMessage = () => {
    if (data?.error) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {
    history?.location?.state?.from === "keepFilter"
      ? setRequest({ ...data?.request })
      : setRequest({ ...basicRequest });
  }, [history?.location?.state?.from]);

  const formatUserData = useCallback(() => {
    return;
  }, [data?.data?.huddles, request, isDeleteable, isEditable]);

  const onselectionchange = (isChecked, huddle) => {
    debugger;
    if (isChecked) {
      setSelectedHuddles([...selectedHuddles, huddle.id]);
    } else {
      const huddles = selectedHuddles.filter((h) => h.id === huddle.id);
      setSelectedHuddles(huddles);
    }
  };

  //memoized user data
  // const userData = useMemo(() => formatUserData(), [formatUserData]);

  const deleteAll = async () => {
    await dispatch(
      deleteHuddleList({
        ids: selectedHuddles,
        callback: () => {
          dispatch(getHuddles(request));
        },
      })
    );
    setSelectedHuddles([]);
  };

  const huddlesData = data?.huddles?.huddles?.map((huddles, index) => ({
    ...huddles,
    checkbox: userId ? (
      ""
    ) : (
      <Checkbox
        onChange={(event, data) => onselectionchange(data.checked, huddles)}
      />
    ),
    no: (request?.page - 1) * request?.page_count + index + 1,
    createdOn: convertToUAETime(
      huddles.time_created,
      DATE_WITH_TIME_IN_SECONDS
    ),
    profile_image: (
      <div className="profileWrap" data-tag="allowRowEvents">
        {huddles.is_premium ? (
          <div className="huddle_premium_icon" data-tag="allowRowEvents">
            <img src={premiumImage} data-tag="allowRowEvents" />
          </div>
        ) : null}

        <div className="d-flex">
          <div data-tag="allowRowEvents">
            <img
              data-tag="allowRowEvents"
              className="table_profileImg"
              src={
                huddles?.group_photo === null
                  ? defaultProfile
                  : huddles?.group_photo
              }
            />
          </div>

          <div
            className="mx-2 mt-3"
            title={huddles.name}
            data-tag="allowRowEvents"
          >
            {huddles.name}{" "}
            {huddles.tribe === true && (
              <>
                <div className="badge bg-warning font-size-12">Tribe</div>
              </>
            )}
          </div>
        </div>
      </div>
    ),
    category: (
      <div data-tag="allowRowEvents" title={huddles.category}>
        {huddles.category}
      </div>
    ),

    manager: (
      <div data-tag="allowRowEvents" title={huddles.created_by}>
        {huddles.created_by}
      </div>
    ),
    created_on: (
      <div data-tag="allowRowEvents" title={huddles.time_created}>
        {huddles?.time_created}
      </div>
    ),
    participant_count: (
      <div data-tag="allowRowEvents" title={huddles.total_members}>
        {huddles.total_members}
      </div>
    ),
    report_count: (
      <div data-tag="allowRowEvents" title={huddles.report_count}>
        {huddles?.report_count}
      </div>
    ),
    status: (
      <div
        className={`badge ${
          huddles.status === "blocked" || huddles.status === "admin_blocked"
            ? "bg-soft-orange"
            : huddles.status === "active"
            ? "bg-soft-green"
            : huddles.status === "admin_deleted" || huddles.status === "deleted"
            ? "bg-soft-red"
            : ""
        } font-size-12`}
        data-tag="allowRowEvents"
      >
        {huddles.status}
      </div>
    ),
    type: (
      <div
        data-tag="allowRowEvents"
        className="badge bg-soft-gray font-size-12"
      >
        {!huddles.private
          ? `Public/${
              huddles.request_to_join ? "Request to Join" : "Open For All"
            } `
          : "Private"}
      </div>
    ),
  }));

  const tableRowClickHandler = (param) => {
    const baseUrl = type === "private" ? "/group-details" : "/huddle-details";
    history.push(
      `${baseUrl}/${param.id}?return=${location.pathname}${location.search}`
    );
  };

  return (
    <div
      className={userId || "page-content"}
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem={userId ? "" : title} />
          {data?.error && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            </div>
          )}
          {data?.success && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="success"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.success}
              </UncontrolledAlert>
            </div>
          )}

          <Datatable
            tableID={"adminTable"}
            columns={
              location?.pathname === "/huddles"
                ? huddleColumnData
                : groupColumnData
            }
            rows={huddlesData}
            setRequest={setRequest}
            request={request}
            totalRecords={data?.huddles?.total}
            search
            huddleFilter
            tableRowClicked={(param) => tableRowClickHandler(param)}
            addNewLabel={t("Admins.addAdmin.addBtn")}
            searchTerm={data?.request?.keyword}
            reqstatusFilter={request?.status_filter}
            reqmembershipFilter={request?.membership_filter}
            loading={data?.loading}
            tableCardClassName={"snoTable"}
            deleteLabel="DELETE"
            deleteClickHandler={deleteAll}
            deleteDisabled={!selectedHuddles.length}
            showDeleteButton={true}
            deleteConfirmationMsg={deleteConfirmationMessage}
          />
        </Row>
      </Container>

      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default HuddleManagement;
