import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS,
  GET_UNSEEN_COUNT,
  MARK_ALL_AS_READ,
} from "./actionTypes";
import {
  getNotificationsBegin,
  getNotificationsSuccess,
  getNotificationsFail,
  getAllNotificationsBegin,
  getAllNotificationsSuccess,
  getAllNotificationsFail,
  getUnseenCountBegin,
  getUnseenCountSuccess,
  getUnseenCountFail,
  markAllAsReadBegin,
  markAllAsReadSuccess,
  markAllAsReadFail,
} from "./actions";

import {
  NOTIFICATIONS_LIST,
  NOTIFICATION_LIST_ALL,
  UNSEEN_COUNT,
  READ_NOTIFICATION,
} from "../../helpers/url_helper";

import { get, post, update } from "../../helpers/api_helper";
import moment from "moment";

function* getNotifications() {
  try {
    yield put(getNotificationsBegin());
    const response = yield call(get, NOTIFICATIONS_LIST);
    yield put(getNotificationsSuccess(response.result));
  } catch (error) {
    yield put(getNotificationsFail(error));
  }
}

function* getAllNotifications({ payload }) {
  try {
    yield put(getAllNotificationsBegin());
    const response = yield call(get, NOTIFICATION_LIST_ALL, payload);
    yield put(getAllNotificationsSuccess(response.result));
  } catch (error) {
    yield put(getAllNotificationsFail(error));
  }
}

function* getUnseenCount() {
  try {
    yield put(getUnseenCountBegin());
    const response = yield call(get, UNSEEN_COUNT);
    yield put(getUnseenCountSuccess(response.result));
  } catch (error) {
    yield put(getUnseenCountFail(error));
  }
}

function* markAllAsRead({ payload }) {
  try {
    yield put(markAllAsReadBegin());
    //const response = yield call(update, NOTIFICATION_LIST_ALL, { ...payload });
    if (!payload.notification_id) {
      yield call(post, NOTIFICATION_LIST_ALL, {});
      yield put(markAllAsReadSuccess(moment().format("YYYY-MM-DD HH:mm:ss")));
    } else {
      yield call(
        update,
        READ_NOTIFICATION.replace("{id}", payload.notification_id)
      );
    }
  } catch (error) {
    yield put(markAllAsReadFail(error));
  }
}

function* notificationSaga() {
  yield takeEvery(GET_NOTIFICATIONS, getNotifications);
  yield takeEvery(GET_ALL_NOTIFICATIONS, getAllNotifications);
  yield takeEvery(GET_UNSEEN_COUNT, getUnseenCount);
  yield takeEvery(MARK_ALL_AS_READ, markAllAsRead);
}

export default notificationSaga;
