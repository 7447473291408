import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumbs from "../../components/UI/Breadcrumb";
import Datatable from "../../components/UI/Tables/Datatable";
import Loader from "../../components/Common/Loader";

import Edit_icon from "../../assets/icons/ebud-icons/Edit.svg";
import Tick_icon from "../../assets/icons/tick.svg";

import { documentsColumnData } from "../../helpers/columns";

import { getLegalDocuments } from "../../store/contentManagement/action";
import { checkUserPermission } from "../../helpers/utils";

const LegalDocumentListing = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.contentManagement);
  console.log("Ddd", data);

  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;
  const basicRequest = {
    sort: "conversion_date",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 10,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });

  useEffect(() => {
    dispatch(getLegalDocuments(request));
  }, [request]);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Content management",
          "Legal documents"
        )
      );
    }
  }, [loginData]);

  const formatLegalDocumentsData = useCallback(() => {
    return data?.data?.privacy_policies?.map((record, index) => ({
      ...record,
      no: index + 1,
      title: <div>{record.title}</div>,
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html:
              record.description?.length > 150
                ? `${record.description?.substring(0, 150)}...`
                : record.description,
          }}
        />
      ),
      time_created: <div>{record.time_created}</div>,
      time_updated: <div>{record.time_updated}</div>,
      status: (
        <div
          className={`badge ${
            record.status === "Published" ? "bg-soft-green" : "bg-soft-gray"
          } font-size-13`}
        >
          {record.status}
        </div>
      ),
      userNotified: (
        <div>
          {record.notify_user ? (
            <img src={Tick_icon} alt="User Notified" />
          ) : null}
        </div>
      ),
      action: (
        <div className="cust-table-actions-wrap">
          <button
            className="action-btn"
            title="Edit"
            onClick={() =>
              history.push({
                pathname: `/edit-legal-documents/${record.id}`,
              })
            }
            disabled={!isEditable}
          >
            <img
              src={Edit_icon}
              alt="Edit"
              style={{ opacity: !isEditable ? "50%" : "" }}
            />
          </button>
        </div>
      ),
    }));
  }, [data?.data?.privacy_policies, isEditable]);

  //memoized user data
  const legalDocuments = useMemo(
    () => formatLegalDocumentsData(),
    [formatLegalDocumentsData]
  );

  return (
    <div className="page-content" data-testid="component-legalDocument">
      <Container fluid>
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem={t("Sidebar.Item8")} />
          {data?.error ||
            (data?.success && (
              <UncontrolledAlert
                color={data?.error ? "danger" : "success"}
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error || data?.success}
              </UncontrolledAlert>
            ))}
          <Datatable
            request={request}
            setRequest={setRequest}
            tableID={"legalDocuments"}
            rows={legalDocuments}
            columns={documentsColumnData}
            loading={data?.loading}
            // noPagination
            tableCardClassName={"snoTable"}
            totalRecords={data?.data?.total}
          />
        </Row>
      </Container>
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default LegalDocumentListing;
