import {
  GET_APP_SUBSCRIPTIONS,
  GET_APP_SUBSCRIPTIONS_BEGIN,
  GET_APP_SUBSCRIPTIONS_SUCCESS,
  GET_APP_SUBSCRIPTIONS_FAIL,
  STORE_APP_SUBSCRIPTIONS_REQUEST,
  GET_NEW_SUBSCRIPTIONS,
  GET_NEW_SUBSCRIPTIONS_BEGIN,
  GET_NEW_SUBSCRIPTIONS_SUCCESS,
  GET_NEW_SUBSCRIPTIONS_FAIL,
  EXPORT_APP_SUBSCRIPTION_DETAILS,
  EXPORT_APP_SUBSCRIPTION_DETAILS_BEGIN,
  EXPORT_APP_SUBSCRIPTION_DETAILS_SUCCESS,
  EXPORT_APP_SUBSCRIPTION_DETAILS_FAIL
} from "./actionTypes";

export const getSubscriptionData = (request) => ({
  type: GET_APP_SUBSCRIPTIONS,
  payload: { request },
});

export const getSubscriptionDataBegin = () => ({
  type: GET_APP_SUBSCRIPTIONS_BEGIN,
});

export const getSubscriptionDataSuccess = (subscriptionData) => ({
  type: GET_APP_SUBSCRIPTIONS_SUCCESS,
  payload: subscriptionData,
});

export const getSubscriptionDataFail = (error) => ({
  type: GET_APP_SUBSCRIPTIONS_FAIL,
  payload: error,
});
export const getNewSubscriptions = (request) => ({
  type: GET_NEW_SUBSCRIPTIONS,
  payload: { request },
});

export const getNewSubscriptionsBegin = () => ({
  type: GET_NEW_SUBSCRIPTIONS_BEGIN,
});

export const getNewSubscriptionsSuccess = (subscriptionData) => ({
  type: GET_NEW_SUBSCRIPTIONS_SUCCESS,
  payload: subscriptionData,
});

export const getNewSubscriptionsFail = (error) => ({
  type: GET_NEW_SUBSCRIPTIONS_FAIL,
  payload: error,
});
export const storeRequest = (request) => ({
  type: STORE_APP_SUBSCRIPTIONS_REQUEST,
  payload: request,
});

/* ------------------------------------------- */

// export app subscriptions details

export const exportAppSubscriptionDetails = ({ saveAs, request }) => ({
  type: EXPORT_APP_SUBSCRIPTION_DETAILS,
  saveAs: saveAs,
  request: request,
});

export const exportAppSubscriptionDetailsBegin = () => ({
  type: EXPORT_APP_SUBSCRIPTION_DETAILS_BEGIN,
});

export const exportAppSubscriptionDetailsSuccess = () => ({
  type: EXPORT_APP_SUBSCRIPTION_DETAILS_SUCCESS,
})

export const exportAppSubscriptionDetailsFail = ({ error }) => ({
  type: EXPORT_APP_SUBSCRIPTION_DETAILS_FAIL,
  payload: error
})

/* ------------------------------------------- */
