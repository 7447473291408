import { Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { CardBody, Media, Modal } from "reactstrap";
import { getHuddleParticipants } from "../../../store/huddles/actions";

const Participants = ({
  id,
  content,
  modal_center,
  setmodal_center,
  title,
  toggle,
  messageId,
  showComment,
  setShowComment,
}) => {
  const [commentId, setCommentId] = useState("");
  const match = useParams();
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.Login);
  const data = useSelector((state) => state.Huddles);

  useEffect(() => {
    setCommentId("");
    setShowComment(false);
  }, [match?.id]);

  useEffect(() => {
    if (messageId) {
      dispatch(getHuddleParticipants({ id: messageId }));
    }
  }, [messageId]);

  const handleShowCommentClick = (id) => {
    setShowComment(true);
    setCommentId(id);
  };

  const handleHideCommentClick = (id) => {
    setShowComment(false);
    setCommentId("");
  };

  return (
    <>
      {data?.participants?.paticipants?.length && (
        <Modal isOpen={modal_center} centered={true} toggle={toggle}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Reported by</h5>
            <button
              type="button"
              onClick={() => setmodal_center(false)}
              className="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {data?.participants?.paticipants?.length &&
              data?.participants?.paticipants?.map((user) => (
                <>
                  <Card className="mb-2 mt-2" style={{ boxShadow: "none" }}>
                    <CardBody className="py-2">
                      {" "}
                      <div className="d-flex justify-content-between">
                        <Media className="d-flex mb-1">
                          <div className="avatar-sm me-3">
                            <span
                              className={
                                "avatar-title rounded-circle bg-purple font-size-17"
                              }
                            >
                              {user?.user_details?.name
                                ?.slice(0, 1)
                                .toUpperCase()}
                            </span>
                          </div>
                          <Media>
                            <h5 className="font-size-14 mt-1 mb-1">
                              {user?.user_details?.name}
                            </h5>
                            <p className="contact-sender-reply mb-0">
                              {user?.report_category?.category}{" "}
                              <span
                                title="Click to see the comment"
                                className="cursor-pointer"
                                onClick={() => {
                                  handleShowCommentClick(
                                    user?.user_details?.username
                                  );
                                }}
                              >
                                <i class="fas fa-comment-alt participant-count mx-1"></i>{" "}
                              </span>
                            </p>
                          </Media>
                        </Media>
                      </div>
                      {showComment &&
                      commentId == user?.user_details?.username ? (
                        <div className="custom-comment mt-3">
                          <h3
                            className="text-end my-0"
                            type="button"
                            title="Close"
                            onClick={() => handleHideCommentClick(id)}
                          >
                            <span aria-hidden="true">&times;</span>
                          </h3>
                          <p className=" contact-sender-reply ml-3 mt-0">
                            {user?.comment}
                          </p>
                        </div>
                      ) : null}
                    </CardBody>
                  </Card>
                  <hr className="horizontal-line" />
                </>
              ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default Participants;
