import jstz from "jstz";
import "moment-timezone";
import moment from "moment";
import { ROUTES } from "./constants";

export function setLocalStorage(name, data) {
  localStorage.setItem(name, JSON.stringify(data));
}

export function getLocalStorage(name) {
  return JSON.parse(localStorage.getItem(name));
}

export function removeItem(name) {
  localStorage.removeItem(name);
}

export function convertDate(dateString, separator) {
  var p = dateString?.split(/\D/g);
  return p && [p[2], p[1], p[0]].join(separator);
}

export function convertToUAETime(time, format) {
  return moment(time).tz("Asia/Dubai").format(format);
}

export function convertToUTCTime(time, format) {
  return moment(time).tz("Asia/Dubai").format(format);
}
export function formatDateTime(isoString) {
  const date = new Date(isoString);

  const padZero = (num) => num?.toString().padStart(2, "0");

  const day = padZero(date?.getDate());
  const month = padZero(date?.getMonth() + 1);
  const year = date?.getFullYear().toString();

  const hours = padZero(date?.getHours());
  const minutes = padZero(date?.getMinutes());
  const seconds = padZero(date?.getSeconds());

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}
export function convertTime(timestamp) {
  const date = new Date(timestamp);

  // Convert to UAE time zone (UTC+4)
  date.setHours(date.getHours() + 4);

  // Format the date and time in the desired format
  const formattedDate = `${("0" + date.getDate()).slice(-2)}-${(
    "0" +
    (date.getMonth() + 1)
  ).slice(-2)}-${date.getFullYear()}`;
  const formattedTime = `${("0" + date.getHours()).slice(-2)}:${(
    "0" + date.getMinutes()
  ).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}`;

  // Concatenate date and time in the desired format
  const formattedTimestamp = `${formattedDate} ${formattedTime}`;

  return formattedTimestamp;
}
export function checkUserPermission(rolePermissions, feature, module) {
  let permissions = rolePermissions?.permissions;
  if (permissions && feature in permissions) {
    if (!module) {
      return permissions[feature][0];
    } else {
      return permissions[feature].find(
        (subFeature) => module === subFeature.sub_module
      );
    }
  } else return false;
}

export function checkUserViewPermission(rolePermissions, feature, module) {
  if (rolePermissions && rolePermissions.permissions) {
    let permissions = rolePermissions.permissions;
    if (feature in permissions) {
      if (!module) {
        return permissions[feature].some((obj) => obj.view);
      } else {
        let access = permissions[feature].find(
          (subFeature) => module === subFeature.sub_module
        );
        return access && access.view;
      }
    } else return false;
  }
  return false;
}

export function checkNextValidScreen(rolePermissions) {
  let nextValidScreen;
  let permissions = rolePermissions?.permissions;
  permissions &&
    Object.keys(permissions).some((feature) => {
      permissions[feature].some((module) => {
        ROUTES.some((route) => {
          if (module.view && module.sub_module === route.component) {
            nextValidScreen = route.path;
            return true;
          } else return false;
        });
        if (nextValidScreen) return true;
        else return false;
      });
      if (nextValidScreen) return true;
      else return false;
    });
  return !nextValidScreen ? "/pages-403" : nextValidScreen;
}

export function isLottieAnimation(jsonData) {
  try {
    const parsedData = jsonData;
    // console.log("kkkk", jsonData.assets);

    return (
      parsedData &&
      parsedData.assets &&
      Array.isArray(parsedData.assets) &&
      parsedData.layers &&
      Array.isArray(parsedData.layers)
    );
  } catch (error) {
    console.log("32432");
    // JSON parsing failed, return false
    return false;
  }
}
export function isGif(file) {
  if (file) {
    const fileName = file?.name;
    const fileType = file?.type;

    if (fileType === "image/gif") {
      return true;
    } else {
      return false;
    }
  }
}
