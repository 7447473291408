import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Card, Col, Container, Row, UncontrolledAlert } from "reactstrap";
import Loader from "../../../components/Common/Loader";
import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import {
  editPodiumsCategories,
  getPodiumsCategoriesById,
  resetPodiumsErrors,
} from "../../../store/podiums/actions";

const EditPodiumCategory = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const [formChanged, setFormChanged] = useState(false);
  const dispatch = useDispatch();
  const [updatedCategoryList, setUpdatedCategoryList] = useState([]);
  const [name, setName] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [categoryType, setCategoryType] = useState();
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const data = useSelector((state) => state.Podiums);

  useEffect(() => {
    dispatch(getPodiumsCategoriesById(location.state.id));
    return () => {
      dispatch(resetPodiumsErrors());
    };
  }, [location.state.id]);

  useEffect(() => {
    setName(data?.categoryData?.result?.podium_categories[0].name);
    setCategoryType(
      data?.categoryData?.result?.podium_categories[0].category_type
    );
  }, [data?.categoryData?.result?.podium_categories[0]]);

  useEffect(() => {
    for (let i = 0; i < updatedCategoryList?.length; i++) {
      if (updatedCategoryList[i]?.name == "" || !formChanged) {
        setDisabled(true);
        break;
      } else {
        setDisabled(false);
      }
    }
  }, [updatedCategoryList, formChanged]);

  const handleChange = (id, language, value) => {
    setFormChanged(true);
    const updatedList = [...updatedCategoryList];
    const existingIndex = updatedList.findIndex(
      (item) => item.language === language
    );

    if (existingIndex !== -1) {
      updatedList[existingIndex] = {
        ...updatedList[existingIndex],
        name: value && value.trim(),
        id: id,
        category_type: value && value.trim(),
        category: params?.id,
        selected: true,
      };
    } else {
      updatedList.push({
        id: id,
        language: language,
        name: value && value.trim(),
        category_type: value && value.trim(),
        category: params?.id,
        selected: true,
      });
    }

    setUpdatedCategoryList(updatedList);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedList = Object.values(updatedCategoryList);

    dispatch(
      editPodiumsCategories({
        category: params.id,
        id: location?.state?.id,
        categoryList: updatedList,
      })
    );
    setFormChanged(false);
  };

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error, data?.success]);

  const scrollToAlertMessage = () => {
    if (data?.error || data?.success) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };
  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        ref={pageWrapRef}
      >
        <BackButton
          label="Podium Categories"
          handleClick={() => history.push("/podium-categories")}
        />
        <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Edit Podium Category" />
            {(data?.error || data?.success) && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color={data?.error ? "danger" : "success"}
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error || data?.success}
                </UncontrolledAlert>
              </div>
            )}

            <Col xl="8">
              <Card className="mb-0 p-4 my-4">
                <div className="text-muted">
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <div
                      className="table-responsive mt-4"
                      style={{ overflowX: "hidden" }}
                    >
                      <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Category Name ( In English)
                            {/* <span className="mandatory">*</span> */}
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              readOnly
                              name="adminName"
                              type="text"
                              className="form-control mb-4 "
                              value={name}
                              id="name-input"
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Category Type
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="text"
                              className="form-control mb-4 "
                              value={categoryType}
                              id="name-input"
                              onChange={(e) => {
                                setCategoryType(e.target.value);
                              }}
                              placeholder="Enter Category Type"
                            />
                          </div>
                        </div> */}
                      </div>
                      {data?.categoryData?.result?.podium_categories?.map(
                        (result, i) => (
                          <div key={result?.language} className="col-md-12">
                            {result?.language === "english" ? (
                              <div className="d-flex mx-1">
                                <label className="col-md-2 lang-font col-form-label">
                                  {`${result?.language
                                    ?.slice(0, 1)
                                    .toUpperCase()}${result?.language?.slice(
                                    1
                                  )}`}
                                </label>
                                <div className="col-md-6">
                                  <div className="col-md-8 dobWrapper">
                                    <AvInput
                                      name="adminName"
                                      type="text"
                                      className="form-control mb-4 "
                                      value={result?.name}
                                      id="name-input"
                                      onChange={(e) => {
                                        handleChange(
                                          result?.id,
                                          result?.language,
                                          e.target.value
                                        );
                                      }}
                                      placeholder="Enter Transfer Purpose"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="d-flex mx-1">
                                <label className="col-md-2 lang-font col-form-label">
                                  {`${result?.language
                                    ?.slice(0, 1)
                                    .toUpperCase()}${result?.language?.slice(
                                    1
                                  )}`}
                                </label>{" "}
                                <div className="col-md-6">
                                  <div className="col-md-8 dobWrapper">
                                    <AvInput
                                      name="adminName"
                                      type="text"
                                      className="form-control mb-4 "
                                      value={result?.name}
                                      id="name-input"
                                      onChange={(e) => {
                                        handleChange(
                                          result?.id,
                                          result?.language,
                                          e.target.value
                                        );
                                      }}
                                      placeholder="Enter Transfer Purpose"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      )}

                      <div className="mt-4">
                        <button
                          type="submit"
                          // disabled={isButtonDisabled}
                          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                          // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                          disabled={disabled}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default EditPodiumCategory;
