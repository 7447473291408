import { get,update} from "../../helpers/api_helper";
import {API_GET_APP_REVIEW_REQUESTS,API_PUT_APP_REVIEW_REQUESTS} from "../../helpers/url_helper";
import { GET_APP_REVIEWS_DATA, UPDATE_APP_REVIEW, } from "./actionTypes";
import { call, put, takeEvery } from "redux-saga/effects";
import {getAppReviewRequestsBegin, getAppReviewRequestsSuccess,getAppReviewRequestsFail } from "./action"

export function* getAppReviewRequests(payload) {
    try {
      yield put(getAppReviewRequestsBegin());
      const response = yield call(
        get,
        API_GET_APP_REVIEW_REQUESTS,
        payload.request
      );
      yield put(getAppReviewRequestsSuccess({...response.result, request: payload.request}));
    } catch (error) {
      yield put(getAppReviewRequestsFail(error));
    }
  }

  export function* updateAppReviewRequests({payload}) {
    try {
      yield put(getAppReviewRequestsBegin());
      const response = yield call(
        update,
        `${API_PUT_APP_REVIEW_REQUESTS}/${payload.id}`,
        payload.request
      );
      payload.callback && payload.callback();
     // yield put(getAppReviewRequestsSuccess(response.result));
    } catch (error) {
      yield put(getAppReviewRequestsFail(error));
    }
  }



  function* appReviewSaga() {
    yield takeEvery(GET_APP_REVIEWS_DATA, getAppReviewRequests);
    yield takeEvery(UPDATE_APP_REVIEW, updateAppReviewRequests);
  }
  
  export default appReviewSaga;


