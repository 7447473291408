import { all, fork } from "redux-saga/effects";

import AuthSaga from "./auth/login/saga";
import LayoutSaga from "./layout/saga";
import dashboardSaga from "./dashboard/saga";
import userSaga from "./users/saga";
import reserveNameSaga from "./usernames/reserveUserNames/saga";
import allocatedUsernameSaga from "./usernames/allocateUserNames/saga";
import contentManagementSaga from "./contentManagement/saga";
import registrationAndLoginSaga from "./users/registrationLogin/saga";
import appSettingsSaga from "./settings/appSettings/saga";
import templateSaga from "./settings/templates/saga";
import mlmTreeSaga from "./users/userMatrix/saga";
import subscriptionDetailsSaga from "./users/userSubscriptions/saga";
import notificationSaga from "./notification/saga";
import reportedUserSaga from "./reportedUser/saga";
import appInstallationsSaga from "./appInstallations/saga";
import adminsSaga from "./adminManagement/admins/saga";
import rolePermissionsSaga from "./adminManagement/roleManagement/saga";
import appSubscriptionsSaga from "./appSubscription/saga";
import AccountVerificationStatusSaga from "./accountVerification/saga";
import superstarSuggestionSaga from "./settings/superstarSuggestion/saga";
import HuddlesSaga from "./huddles/saga";
import payoutManagementSaga from "./payouts/saga";
import appReviewSaga from "./appReview/saga";
import manualPPSaga from "./manualPP/saga";
import manualCoinSaga from './manualCoin/saga'
import flaxTransfersaga from "./flaxTransfer/saga";
import empowermentDetailsSaga from "./users/empowerment/saga";
import flaxPackagessaga from "./flaxPackages/saga";
import giftPackagessaga from "./GiftPackages/saga";
import giftTransfersaga from "./GiftTransfers/saga";
import podiumsSaga from "./podiums/saga";
import flashSaga from "./flashManagement/saga";
import announcementSaga from "./announcements/saga";
import flaxCoinsConversionSaga from "./coinsFlaxConversion/saga";
import paymentMethodSaga from "./paymentManagement/saga";
import globalPaymentSystemSaga from "./GlobalPaymentManagement/saga";
import countrySaga from "./commonCountries/saga";
import userFlaxRatesSaga from "./users/flaxRates/saga";
import coinsPurchaseSaga from "./CoinsPurchaseHistory/saga";
import ReportedPostatPostsSaga from "./postatManagement/saga";

import giftCategoriesSaga from "./GiftCategories/saga";
import flaxorCoinsStatementsSaga from "./flaxStatements/saga";
import cookiesSaga from "./cookies/saga";
import podiumCameraPurchaseHistorySaga from "./users/cameraPurchases/saga";
import updateFlixRateSaga from "./users/editRating/saga";

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(userSaga),
    fork(reserveNameSaga),
    fork(allocatedUsernameSaga),
    fork(contentManagementSaga),
    fork(registrationAndLoginSaga),
    fork(mlmTreeSaga),
    fork(appSettingsSaga),
    fork(templateSaga),
    fork(subscriptionDetailsSaga),
    fork(notificationSaga),
    fork(reportedUserSaga),
    fork(appInstallationsSaga),
    fork(adminsSaga),
    fork(rolePermissionsSaga),
    fork(appSubscriptionsSaga),
    fork(superstarSuggestionSaga),
    fork(AccountVerificationStatusSaga),
    fork(HuddlesSaga),
    fork(payoutManagementSaga),
    fork(appReviewSaga),
    fork(manualPPSaga),
    fork(manualCoinSaga),
    fork(flaxTransfersaga),
    fork(empowermentDetailsSaga),
    fork(flaxPackagessaga),
    fork(giftPackagessaga),
    fork(giftTransfersaga),
    fork(podiumsSaga),
    fork(flashSaga),
    fork(announcementSaga),
    fork(flaxCoinsConversionSaga),
    fork(paymentMethodSaga),
    fork(globalPaymentSystemSaga),
    fork(countrySaga),
    fork(giftCategoriesSaga),
    fork(userFlaxRatesSaga),
    fork(coinsPurchaseSaga),
    fork(ReportedPostatPostsSaga),
    fork(flaxorCoinsStatementsSaga),
    fork(cookiesSaga),
    fork(podiumCameraPurchaseHistorySaga),
    fork(updateFlixRateSaga),
  ]);
}
