import { loader } from "@monaco-editor/react";
import {
  GET_APP_SUBSCRIPTIONS_BEGIN,
  GET_APP_SUBSCRIPTIONS_SUCCESS,
  GET_APP_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTION_COUNTRY_BEGIN,
  GET_SUBSCRIPTION_COUNTRY_SUCCESS,
  GET_SUBSCRIPTION_COUNTRY_FAIL,
  STORE_APP_SUBSCRIPTIONS_REQUEST,
  GET_NEW_SUBSCRIPTIONS_BEGIN,
  GET_NEW_SUBSCRIPTIONS_SUCCESS,
  GET_NEW_SUBSCRIPTIONS_FAIL,
  EXPORT_APP_SUBSCRIPTION_DETAILS_BEGIN,
  EXPORT_APP_SUBSCRIPTION_DETAILS_SUCCESS,
  EXPORT_APP_SUBSCRIPTION_DETAILS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  listing_error: "",
  country_filter_error: "",
  loading: false,
  request: {
    sort: "total",
    sort_order: "desc",
    page: 1,
    page_count: 50,
  },
  subscriptionData: {},
  newSubscriptions: {}
};

const appSubscriptions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_APP_SUBSCRIPTIONS_BEGIN:
      return {
        ...state,
        loading: true,
        listing_error: "",
        subscriptionData: {},
      };
    case GET_APP_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        listing_error: "",
        subscriptionData: action.payload,
      };
    case GET_APP_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        subscriptionData: {},
        loading: false,
        listing_error: action.payload,
      };
    case GET_NEW_SUBSCRIPTIONS_BEGIN:
      return {
        ...state,
        loading: true,
        listing_error: "",
        newSubscriptions: {},
      };
    case GET_NEW_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        listing_error: "",
        newSubscriptions: action.payload,
      };
    case GET_NEW_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        newSubscriptions: {},
        loading: false,
        listing_error: action.payload,
      };

    case STORE_APP_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        request: action.payload,
      };

    case EXPORT_APP_SUBSCRIPTION_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        listing_error: "",
      }

    case EXPORT_APP_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        listing_error: "",
      }

    case EXPORT_APP_SUBSCRIPTION_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        listing_error: action.payload,
      }

    default:
      return state;
  }
};

export default appSubscriptions;
