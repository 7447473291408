import { call, put, takeLatest, select } from "redux-saga/effects";

import {
  GET_MLM_PARENTS_AND_LEVELS,
  GET_MLM_PAGINATED_LEVEL,
  GET_MLM_LEVEL_USERS,
} from "./actionTypes";

import {
  getParentsAndLevelsBegin,
  getParentsAndLevelsSuccess,
  getParentsAndLevelsFailure,
  getPaginatedLevelBegin,
  getPaginatedLevelSuccess,
  getPaginatedLevelFailure,
  getMlmLevelUsersBegin,
  getMlmLevelUsersSuccess,
  getMlmLevelUsersFail,
} from "./actions";

import { get } from "../../../helpers/api_helper";

import {
  GET_MLM_TREE,
  GET_MLM_TREE_PAGINATION,
} from "../../../helpers/url_helper";

function* getMlmDetails({ payload }) {
  try {
    yield put(getParentsAndLevelsBegin());
    const response = yield call(get, GET_MLM_TREE.replace("{id}", payload.id));

    /* Adding Visibility Key value pairs to the  response */
    for (const property in response.result.levels) {
      response.result.levels[property] = {
        ...response.result.levels[property],
        visibility: false,
      };
    }

    yield put(getParentsAndLevelsSuccess(response.result));
  } catch (error) {
    yield put(getParentsAndLevelsFailure(error));
  }
}

function* getPaginatedLevel({ id, payload, level }) { 
  const mlmTree = (state) => state.mlmTree;
  try {
    yield put(getPaginatedLevelBegin());
    const response = yield call(
      get,
      `/admin/userdetails/${id}/tree/level/${level?.slice(5)}`,
      // GET_MLM_TREE_PAGINATION.replace("{id}", id),
      {
        ...payload,
      }
    );
    let treeData = yield select(mlmTree); // tree from state

    // let newLevelObj = {
    //   // new level object
    //   ...response?.result[`level${payload.level}`],
    //   visibility: true,
    //   page: payload?.page,
    // };
    // let newTreeObj = {
    //   //constructing new tree object
    //   ...treeData.tree,
    //   levels: {
    //     ...treeData.tree.levels,
    //     [`level${payload.level}`]: newLevelObj,
    //   },
    // };
    yield put(getPaginatedLevelSuccess(response?.result, level));
  } catch (error) {
    yield put(getPaginatedLevelFailure(error));
  }
}

function* getMlmLevelUsers({ userid, level, request }) {
  try {
    yield put(getMlmLevelUsersBegin());

    const response = yield call(
      get,
      `/admin/userdetails/${userid}/tree/level/${level}`,
      request
    );
    yield put(getMlmLevelUsersSuccess(response.result, level));
  } catch (error) {
    yield put(getMlmLevelUsersFail(error));
  }
}

function* mlmTreeSaga() {
  yield takeLatest(GET_MLM_PARENTS_AND_LEVELS, getMlmDetails);
  yield takeLatest(GET_MLM_PAGINATED_LEVEL, getPaginatedLevel);
  yield takeLatest(GET_MLM_LEVEL_USERS, getMlmLevelUsers);
}

export default mlmTreeSaga;
