import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, Col, Container, Row, UncontrolledAlert } from "reactstrap";
import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import {
  addFlashCategories,
  getFlashCategories,
} from "../../../store/flashManagement/actions";
import { getPodiumsCategories } from "../../../store/podiums/actions";
import Loader from "../../../components/Common/Loader";

const AddFlashCategories = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [category, setCategory] = useState("");
  const [categoryType, setCategoryType] = useState("public");
  const [categoryName, setCategoryName] = useState("");
  const [categoryList, setCategoryList] = useState([
    {
      name: "",
      language: "",
    },
  ]);
  const [message, setMessage] = useState("");

  const data = useSelector((state) => state.FlashCategories);
  useEffect(() => {
    for (let i = 0; i < categoryList.length; i++) {
      if (
        category.trim() === "" ||
        categoryList[i]?.name.trim() === "" ||
        categoryList?.length < 9 ||
        // categoryName.trim() === "" ||
        message !== ""
      ) {
        setDisabled(true);
        break;
      } else {
        setDisabled(false);
      }
      if (categoryList[i] == undefined) {
        setDisabled(true);
        break;
      } else {
        setDisabled(false);
      }
    }
  }, [
    categoryList,
    category,
    // categoryName,
    data?.categories?.languages?.length,
    message,
  ]);
  useEffect(() => {
    for (let i = 0; i < data?.categories?.flash_categories?.length; i++) {
      if (
        category.trim().toLowerCase().replace(/\s+/g, "-") ===
          data?.categories?.flash_categories[i].name
            .trim()
            .toLowerCase()
            .replace(/\s+/g, "-") ||
        categoryList[0]?.name?.trim().toLowerCase().replace(/\s+/g, "-") ===
          data?.categories?.flash_categories[i].name
            .toLowerCase()
            .replace(/\s+/g, "-")
      ) {
        setMessage("This Category Already Exists");
        break;
      } else {
        setMessage("");
      }
    }
  }, [category, categoryList]);
  useEffect(() => {
    scrollToAlertMessage();
  }, [message]);

  const scrollToAlertMessage = () => {
    if (message) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {
    dispatch(getFlashCategories());
  }, []);

  const handleReportUsersChangeOrAdd = (index, key, value, language) => {
    const list = [...categoryList];
    if (list[index]) {
      list[index][key] = value;

      list[index]["language"] = language;
      setCategoryList(list);
    } else {
      list[index] = {
        name: value,
        language: language,
      };
      setCategoryList(list);
    }
  };

  // const formData = new FormData();

  // formData.append(
  //   "data",
  //   JSON.stringify({
  //     category: category.toLowerCase().replace(/\s/g, ""),
  //     category_type: categoryType,
  //     flash_data: categoryList,
  //   })
  // );

  const categoryPostData = {
    category: category.toLowerCase().replace(/\s/g, ""),
    category_type: categoryType,
    flash_data: categoryList,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      addFlashCategories({
        category: categoryPostData,
        callback: () => history.push("/flash-categories"),
      })
    );
  };

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        ref={pageWrapRef}
      >
        <BackButton
          label="Flash Categories"
          handleClick={() => history.push("/flash-categories")}
        />
        <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Add Flash Category" />
            {message && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color="danger"
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {message}
                </UncontrolledAlert>
              </div>
            )}
            <Col xl="8">
              <Card className="mb-0 p-4 my-4">
                <div className="text-muted">
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <div
                      className="table-responsive mt-4"
                      style={{ overflowX: "hidden" }}
                    >
                      <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Category (In English)
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="text"
                              className="form-control mb-4 "
                              // value={purpose?.purpose}
                              id="name-input"
                              onChange={(e) => {
                                setCategory(e.target.value);
                              }}
                              placeholder="Enter Category"
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Category Type
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="text"
                              className="form-control mb-4 "
                              // value={purpose?.purpose}
                              id="name-input"
                              onChange={(e) => {
                                setCategoryType(e.target.value);
                              }}
                              placeholder="Enter Category Type"
                            />
                          </div>
                        </div> */}
                      </div>
                      {/* <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Category Name
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="text"
                              className="form-control mb-4 "
                              // value={purpose?.purpose}
                              id="name-input"
                              onChange={(e) => {
                                setCategoryName(e.target.value);
                              }}
                              placeholder="Enter Category Name"
                            />
                          </div>
                        </div>
                      </div> */}
                      {data?.categories?.languages?.length &&
                        data?.categories?.languages?.map((language, i) =>
                          language == "english" ? (
                            <div key={i} className="col-md-12">
                              <div className="d-flex mx-1">
                                <label className="col-md-2 lang-font col-form-label">
                                  {`${language
                                    ?.slice(0, 1)
                                    .toUpperCase()}${language?.slice(1)}`}
                                </label>
                                <div className="col-md-6">
                                  <div className="col-md-8 dobWrapper">
                                    <AvInput
                                      name="adminName"
                                      type="text"
                                      className="form-control mb-4 "
                                      // value={purpose?.purpose}
                                      id="name-input"
                                      onChange={(e) => {
                                        handleReportUsersChangeOrAdd(
                                          i,
                                          "name",
                                          e.target.value,
                                          language
                                        );
                                      }}
                                      placeholder="Enter Category Name"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div key={i} className=" d-flex mx-1">
                              <label className="col-md-2 lang-font col-form-label">
                                {`${language
                                  ?.slice(0, 1)
                                  .toUpperCase()}${language?.slice(1)}`}
                              </label>{" "}
                              <div className="col-md-6">
                                {/* <label className="col-md-8 col-form-label">
                                  Enter Category Text
                                  <span className="mandatory">*</span>
                                </label> */}
                                <div className="col-md-8 dobWrapper">
                                  <AvInput
                                    name="adminName"
                                    type="text"
                                    className="form-control mb-4 "
                                    // value={purpose?.purpose}
                                    id="name-input"
                                    onChange={(e) => {
                                      handleReportUsersChangeOrAdd(
                                        i,
                                        "name",
                                        e.target.value,
                                        language
                                      );
                                    }}
                                    placeholder="Enter Category Name"
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        )}

                      <div className="mt-4">
                        <button
                          type="submit"
                          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                          // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                          disabled={disabled}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default AddFlashCategories;
