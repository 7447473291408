import {
  GET_ALLOCATED_NAMES,
  GET_ALLOCATED_NAMES_BEGIN,
  GET_ALLOCATED_NAMES_SUCCESS,
  GET_ALLOCATED_NAMES_FAIL,
  GET_ALLOCATED_NAME_ID,
  GET_ALLOCATED_NAME_ID_BEGIN,
  GET_ALLOCATED_NAME_ID_SUCCESS,
  GET_ALLOCATED_NAME_ID_FAIL,
  GET_ALLOCATED_NAME_HISTORY,
  GET_ALLOCATED_NAME_HISTORY_BEGIN,
  GET_ALLOCATED_NAME_HISTORY_SUCCESS,
  GET_ALLOCATED_NAME_HISTORY_FAIL,
  USER_SEARCH,
  USER_SEARCH_BEGIN,
  USER_SEARCH_SUCCESS,
  USER_SEARCH_FAIL,
  STORE_USER_REQUEST,
  DELETE_SUGGESTION,
  DELETE_SUGGESTION_BEGIN,
  DELETE_SUGGESTION_SUCCESS,
  DELETE_SUGGESTION_FAIL,
  ALLOCATE_USERNAME,
  ALLOCATE_USERNAME_BEGIN,
  ALLOCATE_USERNAME_SUCCESS,
  ALLOCATE_USERNAME_FAIL,
  UPDATE_ALLOCATED_USERNAME,
  UPDATE_ALLOCATED_USERNAME_BEGIN,
  UPDATE_ALLOCATED_USERNAME_SUCCESS,
  UPDATE_ALLOCATED_USERNAME_FAIL,
  CHECK_USERNAME_AVAILABILITY,
  CHECK_USERNAME_AVAILABILITY_BEGIN,
  CHECK_USERNAME_AVAILABILITY_SUCCESS,
  CHECK_USERNAME_AVAILABILITY_FAIL,
  RESET_MESSAGES,
  RESET_MESSAGES_AND_USERNAME,
  RESET_STATE
} from "./actionTypes";

export const resetState = ()=>({type: RESET_STATE});

export const getAllocatedNames = (request) => ({
  type: GET_ALLOCATED_NAMES,
  payload: request,
});

export const getAllocatedNamesBegin = () => ({
  type: GET_ALLOCATED_NAMES_BEGIN,
});

export const getAllocatedNamesSuccess = (usernames) => ({
  type: GET_ALLOCATED_NAMES_SUCCESS,
  payload: usernames,
});

export const getAllocatedNamesFail = (error) => ({
  type: GET_ALLOCATED_NAMES_FAIL,
  payload: error,
});

/* ----------------------------------------------------*/

export const getUser = ({ data, history }) => ({
  type: USER_SEARCH,
  payload: data,
  history: history
});

export const getUserBegin = () => ({
  type: USER_SEARCH_BEGIN,
});

export const getUserSuccess = (user) => ({
  type: USER_SEARCH_SUCCESS,
  payload: user,
});

export const getUserFail = (error) => ({
  type: USER_SEARCH_FAIL,
  payload: error,
});

/* ----------------------------------------------------*/

export const allocateUsername = ({ data, callback, history }) => ({
  type: ALLOCATE_USERNAME,
  data: data,
  history: history,
  callback: callback
});

export const allocateUsernameBegin = () => ({
  type: ALLOCATE_USERNAME_BEGIN,
});

export const allocateUsernameSuccess = (message) => ({
  type: ALLOCATE_USERNAME_SUCCESS,
  payload: message,
});

export const allocateUsernameFail = (error) => ({
  type: ALLOCATE_USERNAME_FAIL,
  payload: error,
});

/* ----------------------------------------------------*/

export const getAllocateUserNameDetails = (id) => ({
  type: GET_ALLOCATED_NAME_ID,
  id,
});

export const getAllocateUserNameBegin = () => ({
  type: GET_ALLOCATED_NAME_ID_BEGIN,
});

export const getAllocateUserNameSuccess = (user) => ({
  type: GET_ALLOCATED_NAME_ID_SUCCESS,
  payload: user,
});

export const getAllocateUserNameFail = (error) => ({
  type: GET_ALLOCATED_NAME_ID_FAIL,
  payload: error,
});

/* ------------------------------------------- */

export const getAllocateUserHistory = (id) => ({
  type: GET_ALLOCATED_NAME_HISTORY,
  id
})

export const getAllocateUserHistoryBegin = () => ({
  type: GET_ALLOCATED_NAME_HISTORY_BEGIN,
});

export const getAllocateUserHistorySuccess = (history) => ({
  type: GET_ALLOCATED_NAME_HISTORY_SUCCESS,
  payload: history,
});

export const getAllocateUserHistoryFail = (error) => ({
  type: GET_ALLOCATED_NAME_HISTORY_FAIL,
  payload: error,
});

/* ----------------------------------------------------*/

export const deleteSuggestion = ({ data, callback }) => ({
  type: DELETE_SUGGESTION,
  id: data,
  callback,
})

export const deleteSuggestionBegin = () => ({
  type: DELETE_SUGGESTION_BEGIN,
});

export const deleteSuggestionSuccess = (users) => ({
  type: DELETE_SUGGESTION_SUCCESS,
  payload: users,
});

export const deleteSuggestionFail = (error) => ({
  type: DELETE_SUGGESTION_FAIL,
  payload: error,
});

/* ----------------------------------------------------*/

export const updateAllocatedUserName = ({ data, id, callback, history }) => ({
  type: UPDATE_ALLOCATED_USERNAME,
  id: id,
  payload: { ...data },
  history: history,
  callback: callback,
})

export const updateAllocatedUserNameBegin = () => ({
  type: UPDATE_ALLOCATED_USERNAME_BEGIN,
});

export const updateAllocatedUserNameSuccess = (username) => ({
  type: UPDATE_ALLOCATED_USERNAME_SUCCESS,
  payload: username,
});

export const updateAllocatedUserNameFail = (error) => ({
  type: UPDATE_ALLOCATED_USERNAME_FAIL,
  payload: error,
});

/* ------------------------------------------------------- */

export const checkUsernameAvailability = (data) => ({
  type: CHECK_USERNAME_AVAILABILITY,
  payload: data
})

export const checkUsernameAvailabilityBegin = () => ({
  type: CHECK_USERNAME_AVAILABILITY_BEGIN,
});

export const checkUsernameAvailabilitySuccess = (response) => ({
  type: CHECK_USERNAME_AVAILABILITY_SUCCESS,
  payload: response,
});

export const checkUsernameAvailabilityFail = (error) => ({
  type: CHECK_USERNAME_AVAILABILITY_FAIL,
  payload: error,
});

/* ------------------------------------------------------- */

export const storeRequest = (request) => ({
  type: STORE_USER_REQUEST,
  payload: request,
});

/* ------------------------------------------------------- */

export const resetError = () => ({
  type: RESET_MESSAGES,
})

export const resetErrorWithUsername = () => ({
  type: RESET_MESSAGES_AND_USERNAME,
})
