import { all, call, fork, takeEvery, put, take } from "redux-saga/effects";
import {
  addManualPPBegin,
  addManualPPError,
  addManualPPSuccess,
  getAllManualPPBegin,
  getAllManualPPError,
  getAllManualPPSuccess,
  getAllManualPP,
  deletePPBegin,
  deletePPSuccess,
} from "./actions";
import { post, get, del } from "../../helpers/api_helper";
import {
  ADD_MANUAL_PP,
  DELETE_MANUAL_PP,
  GET_ALL_MANUAL_PP,
} from "./actionTypes";
import { API_MANUALPP } from "../../helpers/url_helper";
import { alert } from "../../util/toast";

function* addManualPP({ payload }) {
  try {
    yield put(addManualPPBegin());
    const response = yield call(post, API_MANUALPP, payload);
    yield put(addManualPPSuccess(response));
    payload?.action === "credited" &&
      alert.success("Manual FLiX has been Credited succesfully for the user!");
    payload?.action === "debited" &&
      alert.success("Manual FLiX has been Debited succesfully from the user!");
    if (payload.reload && payload?.user_id) {
      yield put(getAllManualPP({ user_id: payload.user_id }));
    }
  } catch (error) {
    yield put(addManualPPError(error));
  }
}

function* getAllManualPPs({ payload }) {
  try {
    yield put(getAllManualPPBegin(payload));
    const response = yield call(get, `${API_MANUALPP}`, payload);
    yield put(getAllManualPPSuccess(response?.result));
  } catch (error) {
    yield put(getAllManualPPError(error));
  }
}

function* deleteManualPP({ payload }) {
  try {
    yield put(deletePPBegin());
    const response = yield call(
      del,
      `${API_MANUALPP}/${payload.id}?user_id=${payload.user_id}`
    );
    yield put(deletePPSuccess(response));
    yield put(getAllManualPP({ user_id: payload.user_id }));
  } catch (error) {
    yield put(getAllManualPPError(error));
  }
}

function* manualPPSaga() {
  yield takeEvery(ADD_MANUAL_PP, addManualPP);
  yield takeEvery(GET_ALL_MANUAL_PP, getAllManualPPs);
  yield takeEvery(DELETE_MANUAL_PP, deleteManualPP);
}

export default manualPPSaga;
