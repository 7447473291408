import {
    Container,
    Row,
    Col,
    Form,
    Label,
    Input,
    FormGroup,
} from "reactstrap";
import { Button } from "semantic-ui-react";
import { getLocalStorage } from "../../../../helpers/utils";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addManualCoin } from "../../../../store/manualCoin/actions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const initialData = {
    coins: "",
    comments: "",
    user_id: "",
    user: { name: "", id: "" },
    validation: {
        coins: ''
    },
    reload: true
};
export const ManualCoinForm = (props) => {
    const match = useParams();
    const auth = getLocalStorage("AUTH_DETAILS");
    const [formData, setData] = useState(() => ({ ...initialData, action: props?.transactionType, user_id: match?.id, user: { name: auth.user.username, id: auth.user.id }, reload: true }));
    const dispatch = useDispatch();

    useEffect(() => {
        setData({ ...formData, action: props.transactionType })
    }, [props.transactionType])

    const onFormFieldChange = (event) => {
        let message = '';
        const { name, value } = event.target;
        if (name === 'coins') {
            const intValue = parseFloat(value);
            if (intValue < 1 || intValue > 100000) {
                message = 'Please ensure that the value is greater than 0 and not more than 1 lakh.';
            }
        }
        setData({ ...formData, [name]: value, validation: { [name]: message } });
    };

    const onCoinsSubmit = (event) => {
        event.preventDefault();
        if (!formData.coins) {
            setData({ ...formData, validation: { coins: 'Please fill the COiNS value' } })
            return;
        }
        const { user, ...payload } = formData;
        dispatch(addManualCoin({ ...payload, "coins": Number(payload.coins) }));
        setData({ ...initialData, action: props?.transactionType, user_id: match?.id, user: { name: auth.user.username, id: auth.user.id }, reload: true });
    };

    return (
        <Form>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Label for="amount">The amount to be added</Label>
                        <Input
                            id="Amount"
                            name="coins"
                            placeholder="Amount"
                            type="number"
                            onChange={onFormFieldChange}
                            value={formData.coins}
                            invalid={!!formData.validation.coins}
                        />
                        <span className="text-danger">{formData.validation.coins}</span>
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="addedBy">Added by</Label>
                        <Input
                            id="addedBy"
                            name="addedBy"
                            placeholder="Added By"
                            type="string"
                            disabled
                            value={formData.user.name}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col sm="12">
                    <FormGroup>
                        <Label for="comment">Comments</Label>
                        <Input
                            id="comment"
                            name="comments"
                            type="textarea"
                            value={formData.comments}
                            onChange={onFormFieldChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col sm="12" style={{ textAlign: "center" }}>
                    <Button style={{ background: "#F3D802" }} onClick={onCoinsSubmit} disabled={props.loading || !formData.coins || !!formData.validation.coins}>
                        {" "}
                        {props.addLoading ? "Saving..." : "Save"}{" "}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}