import React from "react";
import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import Editor from "@monaco-editor/react";

const EmailTemplate = ({
  subject,
  setSubject,
  setFormChanged,
  addTemp,
  body,
  setBody,
  setButtonDisabled,
  previewButtonHandler,
  title,
  setTitle,
  templateType,
  setTemplateType,
  language,
  setLanguage,
  identifier,
  setIdentifier,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="col-md-12 template-subject-wrap">
        <div className="col-md-12">
          <label className="col-form-label">
            {t("settings.editEmailSubject")}
            <span className="mandatory">*</span>
          </label>
          <div className="">
            <input
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
                setFormChanged(true);
                e.target.value
                  ? setButtonDisabled(false)
                  : setButtonDisabled(true);
              }}
              name="templateTitle"
              placeholder={t("settings.editEmailSubjectPlaceholder")}
            />
          </div>
        </div>
      </Row>
      {addTemp && (
        <>
          <Row className="col-md-12 template-subject-wrap">
            <div className="col-md-12">
              <label className="col-form-label">
                {t("settings.editTemplateLabel")}
                <span className="mandatory">*</span>
              </label>

              <div className="col-md-12">
                <input
                  value={title}
                  onChange={(e) => {
                    e.target.value
                      ? setButtonDisabled(false)
                      : setButtonDisabled(true);
                    setTitle(e.target.value);
                  }}
                  name="templateTitle"
                  placeholder={t("settings.addTemplateTitlePlaceholder")}
                />
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-6 d-flex justify-content-between">
              {/* First Select */}
              <div className="w-100">
                <label className="col-form-label">
                  {t("settings.templateTypeLabel")}
                  <span className="mandatory">*</span>
                </label>
                <select
                  style={{
                    width: "80%",
                    height: "30px",
                  }}
                  className="form-select  ml-4"
                  value={templateType}
                  onChange={(e) => {
                    setTemplateType(e.target.value);
                  }}
                >
                  <option value="email">E-Mail</option>
                  <option value="text">SMS</option>
                </select>
              </div>

              {/* Second Select */}
              <div className="w-100">
                <label className="col-form-label">
                  {t("settings.templateLanguageLabel")}
                  <span className="mandatory">*</span>
                </label>
                <select
                  style={{
                    width: "50%",
                    height: "30px",
                  }}
                  className="form-select  ml-4"
                  value={language}
                  onChange={(e) => {
                    setLanguage(e.target.value);
                  }}
                >
                  <option value="english">English</option>
                  <option value="arabic">Arabic</option>
                </select>
              </div>
            </div>
          </Row>
          <Row className="col-md-12 template-subject-wrap">
            <div className="col-md-12">
              <label className="col-form-label">
                {t("settings.templateIdentifierLabel")}
                <span className="mandatory">*</span>
              </label>

              <div className="col-md-12">
                <input
                  value={identifier}
                  onChange={(e) => {
                    e.target.value
                      ? setButtonDisabled(false)
                      : setButtonDisabled(true);
                    setIdentifier(e.target.value);
                  }}
                  name="templateTitle"
                  placeholder={t("settings.addTemplateTitlePlaceholder")}
                />
              </div>
            </div>
          </Row>
        </>
      )}
      <hr />
      <div className="emailTemplateHeader">
        <label className="col-form-label">
          {t("settings.emailTemplate")}
          <span className="mandatory">*</span>
        </label>
        <button
          className="emailTemplatePreview btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
          onClick={previewButtonHandler}
        >
          Preview
        </button>
      </div>
      <div className="editorWrapper">
        <Editor
          height="500px"
          language="javascript"
          value={body}
          loading="Loading..."
          theme="vs-dark"
          onChange={(e) => {
            e ? setButtonDisabled(false) : setButtonDisabled(true);
            setFormChanged(true);
            setBody(e);
          }}
          options={{ autoIndent: "keep", autoClosingBrackets: true }}
        />
      </div>
    </>
  );
};

export default EmailTemplate;
