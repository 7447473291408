/*ALLOCATED USERNAME */
export const GET_ALLOCATED_NAMES = "GET_ALLOCATED_NAMES";
export const GET_ALLOCATED_NAMES_BEGIN = "GET_ALLOCATED_NAMES_BEGIN";
export const GET_ALLOCATED_NAMES_SUCCESS = "GET_ALLOCATED_NAMES_SUCCESS";
export const GET_ALLOCATED_NAMES_FAIL = "GET_ALLOCATED_NAMES_FAIL";

export const USER_SEARCH = "USER_SEARCH";
export const USER_SEARCH_BEGIN = "USER_SEARCH_BEGIN";
export const USER_SEARCH_SUCCESS = "USER_SEARCH_SUCCESS";
export const USER_SEARCH_FAIL = "USER_SEARCH_FAIL";

export const ALLOCATE_USERNAME = "ALLOCATE_USERNAME";
export const ALLOCATE_USERNAME_BEGIN = "ALLOCATE_USERNAME_BEGIN";
export const ALLOCATE_USERNAME_SUCCESS = "ALLOCATE_USERNAME_SUCCESS";
export const ALLOCATE_USERNAME_FAIL = "ALLOCATE_USERNAME_FAIL";

export const GET_ALLOCATED_NAME_ID = 'GET_ALLOCATED_NAME_ID';
export const GET_ALLOCATED_NAME_ID_BEGIN = 'GET_ALLOCATED_NAME_ID_BEGIN';
export const GET_ALLOCATED_NAME_ID_SUCCESS = 'GET_ALLOCATED_NAME_ID_SUCCESS';
export const GET_ALLOCATED_NAME_ID_FAIL = 'GET_ALLOCATED_NAME_ID_FAIL';

export const GET_ALLOCATED_NAME_HISTORY = 'GET_ALLOCATED_NAME_HISTORY';
export const GET_ALLOCATED_NAME_HISTORY_BEGIN = 'GET_ALLOCATED_NAME_HISTORY_BEGIN';
export const GET_ALLOCATED_NAME_HISTORY_SUCCESS = 'GET_ALLOCATED_NAME_HISTORY_SUCCESS';
export const GET_ALLOCATED_NAME_HISTORY_FAIL = 'GET_ALLOCATED_NAME_HISTORY_FAIL';

export const STORE_USER_REQUEST = "STORE_USER_REQUEST";

export const RESET_MESSAGES = "RESET_MESSAGES";
export const RESET_MESSAGES_AND_USERNAME = "RESET_MESSAGES_AND_USERNAME";

export const DELETE_SUGGESTION = 'DELETE_SUGGESTION';
export const DELETE_SUGGESTION_BEGIN = 'DELETE_SUGGESTION_BEGIN';
export const DELETE_SUGGESTION_SUCCESS = 'DELETE_SUGGESTION_SUCCESS';
export const DELETE_SUGGESTION_FAIL = 'DELETE_SUGGESTION_FAIL';

export const UPDATE_ALLOCATED_USERNAME = 'UPDATE_ALLOCATED_USERNAME';
export const UPDATE_ALLOCATED_USERNAME_BEGIN = 'UPDATE_ALLOCATED_USERNAME_BEGIN';
export const UPDATE_ALLOCATED_USERNAME_SUCCESS = 'UPDATE_ALLOCATED_USERNAME_SUCCESS';
export const UPDATE_ALLOCATED_USERNAME_FAIL = 'UPDATE_ALLOCATED_USERNAME_FAIL';

export const CHECK_USERNAME_AVAILABILITY = 'CHECK_USERNAME_AVAILABILITY';
export const CHECK_USERNAME_AVAILABILITY_BEGIN = 'CHECK_USERNAME_AVAILABILITY_BEGIN';
export const CHECK_USERNAME_AVAILABILITY_SUCCESS = 'CHECK_USERNAME_AVAILABILITY_SUCCESS';
export const CHECK_USERNAME_AVAILABILITY_FAIL = 'CHECK_USERNAME_AVAILABILITY_FAIL';

export const RESET_STATE = "RESET_STATE";
