import {
  ADD_FLAX_PACKAGES_BEGIN,
  ADD_FLAX_PACKAGES_FAIL,
  ADD_FLAX_PACKAGES_SUCCESS,
  DELETE_FLAX_PACKAGES_BEGIN,
  DELETE_FLAX_PACKAGES_FAIL,
  DELETE_FLAX_PACKAGES_SUCCESS,
  GET_FLAX_PACKAGES_BEGIN,
  GET_FLAX_PACKAGES_BY_ID_BEGIN,
  GET_FLAX_PACKAGES_BY_ID_FAIL,
  GET_FLAX_PACKAGES_BY_ID_SUCCESS,
  GET_FLAX_PACKAGES_FAIL,
  GET_FLAX_PACKAGES_SUCCESS,
  GET_FLAX_PURCHASE_HISTORY_BEGIN,
  GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_BEGIN,
  GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_FAIL,
  GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_SUCCESS,
  GET_FLAX_PURCHASE_HISTORY_FAIL,
  GET_FLAX_PURCHASE_HISTORY_SUCCESS,
  RESET_FLAX_PACKAGE_ERRORS,
  UPDATE_FLAX_PACKAGES_BEGIN,
  UPDATE_FLAX_PACKAGES_FAIL,
  UPDATE_FLAX_PACKAGES_SUCCESS,
} from "./actionTypes";

export const INIT_STATE = {
  loading: true,
  error: "",
  success: "",
  flaxPackages: {},
  flaxPackageData: {},
  flaxPurchaseHistoryData: {},
  flaxPurchaseHistoryDataByUser: {},
  request: {
    sort: "transfered_date",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    page_count: 50,
    keyword: "",
    success: "",
  },
};

const flaxPackages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FLAX_PACKAGES_BEGIN:
      return {
        ...state,
        loading: true,
        flaxPackages: {},
      };
    case GET_FLAX_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        flaxPackages: action.payload,
        error: "",
      };
    case GET_FLAX_PACKAGES_FAIL:
      return {
        ...state,
        loading: false,
        flaxPackages: {},
        error: action.payload,
      };

    case DELETE_FLAX_PACKAGES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FLAX_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case DELETE_FLAX_PACKAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_FLAX_PACKAGES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_FLAX_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "success",
        error: "",
      };
    case ADD_FLAX_PACKAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_FLAX_PACKAGES_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        flaxPackageData: {},
      };
    case GET_FLAX_PACKAGES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        flaxPackageData: action.payload,
      };
    case GET_FLAX_PACKAGES_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        flaxPackageData: {},
        error: action.payload,
      };
    case UPDATE_FLAX_PACKAGES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_FLAX_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: "",
      };
    case UPDATE_FLAX_PACKAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_FLAX_PURCHASE_HISTORY_BEGIN:
      return {
        ...state,
        loading: true,
        flaxPurchaseHistoryData: {},
      };
    case GET_FLAX_PURCHASE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        flaxPurchaseHistoryData: action.payload,
        error: "",
      };
    case GET_FLAX_PURCHASE_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        flaxPurchaseHistoryData: {},
        error: action.payload,
      };
    case GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_BEGIN:
      return {
        ...state,
        loading: true,
        flaxPurchaseHistoryDataByUser: {},
      };
    case GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        flaxPurchaseHistoryDataByUser: action.payload,
        error: "",
      };
    case GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_FAIL:
      return {
        ...state,
        loading: false,
        flaxPurchaseHistoryDataByUser: {},
        error: action.payload,
      };
    case RESET_FLAX_PACKAGE_ERRORS:
      return {
        ...state,
        error: "",
        success: "",
      };

    default:
      return state;
  }
};

export default flaxPackages;
