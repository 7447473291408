import {
  ADD_ANNOUNCEMENTS,
  ADD_ANNOUNCEMENTS_BEGIN,
  ADD_ANNOUNCEMENTS_FAIL,
  ADD_ANNOUNCEMENTS_SUCCESS,
  DELETE_ANNOUNCEMENTS,
  DELETE_ANNOUNCEMENTS_BEGIN,
  DELETE_ANNOUNCEMENTS_FAIL,
  DELETE_ANNOUNCEMENTS_SUCCESS,
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_BEGIN,
  GET_ANNOUNCEMENTS_BY_ID,
  GET_ANNOUNCEMENTS_BY_ID_BEGIN,
  GET_ANNOUNCEMENTS_BY_ID_FAIL,
  GET_ANNOUNCEMENTS_BY_ID_SUCCESS,
  GET_ANNOUNCEMENTS_FAIL,
  GET_ANNOUNCEMENTS_SUCCESS,
  RESET_ANNOUNCEMENT_ERRORS,
} from "./actionTypes";

export const getAnnouncements = () => ({
  type: GET_ANNOUNCEMENTS,
});

export const getAnnouncementsBegin = () => ({
  type: GET_ANNOUNCEMENTS_BEGIN,
});

export const getAnnouncementsSuccess = (data) => ({
  type: GET_ANNOUNCEMENTS_SUCCESS,
  payload: data,
});

export const getAnnouncementsFail = (error) => ({
  type: GET_ANNOUNCEMENTS_FAIL,
  payload: error,
});

export const getAnnouncementsById = (id) => ({
  type: GET_ANNOUNCEMENTS_BY_ID,
  id: id,
});

export const getAnnouncementsByIdBegin = () => ({
  type: GET_ANNOUNCEMENTS_BY_ID_BEGIN,
});

export const getAnnouncementsByIdSuccess = (announcements) => ({
  type: GET_ANNOUNCEMENTS_BY_ID_SUCCESS,
  payload: announcements,
});

export const getAnnouncementsByIdFail = (error) => ({
  type: GET_ANNOUNCEMENTS_BY_ID_FAIL,
  payload: error,
});

export const deleteAnnouncements = ({ id, callback }) => ({
  type: DELETE_ANNOUNCEMENTS,
  id: id,
  callback: callback,
});

export const deleteAnnouncementsBegin = () => ({
  type: DELETE_ANNOUNCEMENTS_BEGIN,
});

export const deleteAnnouncementsSuccess = (purpose) => ({
  type: DELETE_ANNOUNCEMENTS_SUCCESS,
  payload: purpose,
});

export const deleteAnnouncementsFail = (error) => ({
  type: DELETE_ANNOUNCEMENTS_FAIL,
  payload: error,
});

export const addAnnouncements = ({ packageData, callback, status }) => ({
  type: ADD_ANNOUNCEMENTS,
  packageData: packageData,
  callback: callback,
  status: status,
});

export const addAnnouncementsBegin = () => ({
  type: ADD_ANNOUNCEMENTS_BEGIN,
});

export const addAnnouncementsSuccess = (packageData) => ({
  type: ADD_ANNOUNCEMENTS_SUCCESS,
  payload: packageData,
});

export const addAnnouncementsFail = (error) => ({
  type: ADD_ANNOUNCEMENTS_FAIL,
  payload: error,
});
export const resetAnnouncementErrors = () => ({
  type: RESET_ANNOUNCEMENT_ERRORS,
});
