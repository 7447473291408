import { call, put, takeLatest } from "redux-saga/effects";

import {
  ADD_SUPERSTAR_SUGGESTION,
  DELETE_SUPERSTAR_SUGGESTION,
  GET_SUPERSTAR_SUGGESTION,
  GET_TEMPLATE_SUPERSTAR_SUGGESTION,
} from "./actionTypes";

import {
  addSuperstarSuggestionBegin,
  addSuperstarSuggestionSuccess,
  addSuperstarSuggestionFail,
  getSuperstarSuggestionBegin,
  getSuperstarSuggestionSuccess,
  getSuperstarSuggestionFail,
  deleteSuperstarSuggestionBegin,
  deleteSuperstarSuggestionSuccess,
  deleteSuperstarSuggestionFail,
} from "./actions";

import { get, post, del, delMultiple } from "../../../helpers/api_helper";
import { SUGGEST_SUPERSTAR } from "../../../helpers/url_helper";

function* getSuperstarSuggestion() {
  try {
    yield put(getSuperstarSuggestionBegin());
    const response = yield call(get, SUGGEST_SUPERSTAR);
    yield put(getSuperstarSuggestionSuccess(response.result));
  } catch (error) {
    yield put(getSuperstarSuggestionFail(error));
  }
}

function* addSuperstarSuggestion({ data, callback }) {
  try {
    yield put(addSuperstarSuggestionBegin());
    const response = yield call(post, SUGGEST_SUPERSTAR, data);
    yield put(addSuperstarSuggestionSuccess(response.message));
    callback && callback();
  } catch (error) {
    yield put(addSuperstarSuggestionFail(error));
  }
}

function* deleteSuperstarSuggestion({ data,callback }) {
  try {
    const val = JSON.stringify(data);
    yield put(deleteSuperstarSuggestionBegin());
    const response = yield call(delMultiple, SUGGEST_SUPERSTAR, data);
    yield put(deleteSuperstarSuggestionSuccess(response.message));
    callback && callback()
  } catch (error) {
    yield put(deleteSuperstarSuggestionFail(error));
  }
}

function* superstarSuggestionSaga() {
  yield takeLatest(GET_SUPERSTAR_SUGGESTION, getSuperstarSuggestion);
  yield takeLatest(ADD_SUPERSTAR_SUGGESTION, addSuperstarSuggestion);
  yield takeLatest(DELETE_SUPERSTAR_SUGGESTION, deleteSuperstarSuggestion);
}

export default superstarSuggestionSaga;
