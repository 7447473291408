import {
  GET_DASHBOARD_DATA_BEGIN,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  GET_GRAPH_DATA_BEGIN,
  GET_GRAPH_DATA_SUCCESS,
  GET_GRAPH_DATA_FAIL,
} from "./actionTypes";

export const INIT_STATE = {
  data: [],
  loading: false,
  error: {},
  graph: [],
};

const dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA_BEGIN:
      return {
        ...state,
        data: [],
        loading: true,
      };
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_GRAPH_DATA_BEGIN:
      return {
        ...state,
        graph: [],
        loading: true,
      };
    case GET_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        graph: action.payload,
        loading: false,
        error: "",
      };

    case GET_GRAPH_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default dashboard;
