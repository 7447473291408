export const GET_EMPOWERMENT_DETAILS = "GET_EMPOWERMENT_DETAILS";
export const GET_EMPOWERMENT_DETAILS_BEGIN = "GET_EMPOWERMENT_DETAILS_BEGIN";
export const GET_EMPOWERMENT_DETAILS_SUCCESS =
  "GET_EMPOWERMENT_DETAILS_SUCCESS";
export const GET_EMPOWERMENT_DETAILS_FAIL = "GET_EMPOWERMENT_DETAILS_FAIL";
export const ADD_EMPOWERMENT_DETAILS = "ADD_EMPOWERMENT_DETAILS";
export const ADD_EMPOWERMENT_DETAILS_BEGIN = "ADD_EMPOWERMENT_DETAILS_BEGIN";
export const ADD_EMPOWERMENT_DETAILS_SUCCESS =
  "ADD_EMPOWERMENT_DETAILS_SUCCESS";
export const ADD_EMPOWERMENT_DETAILS_FAIL = "ADD_EMPOWERMENT_DETAILS_FAIL";
export const RESET_MESSAGES = "RESET_MESSAGES";
