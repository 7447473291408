import React from 'react';
import { Modal } from "reactstrap";

const PreviewTemplate = ({openPreviewModal,modalToggle,closeButtonClick,body}) => {
    
    return (
        <div className="user-filterBtn-wrap" >
          <Modal
            isOpen={openPreviewModal}
            toggle={modalToggle}
            centered={true}
            id="previewTemplate"
          >
            <div className="modal-header userList-filter-header">
                <h5>Email Template Preview</h5>
              <button
                type="button"
                onClick={closeButtonClick}
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            
            <div className="modal-body" id="emailTemplatePreview_content">
                <div dangerouslySetInnerHTML={{ __html: body}} />
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={closeButtonClick}
              >
                Close
              </button>
            </div>

         </Modal>
      </div> 
    )
}

export default PreviewTemplate
