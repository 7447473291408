import { put, call, takeLatest } from "redux-saga/effects";
import {
  del,
  get,
  post,
  update,
  updateFormData,
} from "../../helpers/api_helper";
import {
  addPostatMusicBegin,
  addPostatMusicFail,
  addPostatMusicSuccess,
  deletePostatBegin,
  deletePostatFail,
  deletePostatMusicBegin,
  deletePostatMusicFail,
  deletePostatMusicSuccess,
  deletePostatReportedCommentBegin,
  deletePostatReportedCommentFail,
  deletePostatReportedCommentSuccess,
  deletePostatSuccess,
  editPostatMusicBegin,
  editPostatMusicFail,
  editPostatMusicSuccess,
  getAllPostatPostsBegin,
  getAllPostatPostsFail,
  getAllPostatPostsSuccess,
  getCommentReportsByIdBegin,
  getCommentReportsByIdFail,
  getCommentReportsByIdSuccess,
  getPostatMusicBegin,
  getPostatMusicByIdBegin,
  getPostatMusicByIdFail,
  getPostatMusicByIdSuccess,
  getPostatMusicFail,
  getPostatMusicSuccess,
  getPostatReportedCommentsBegin,
  getPostatReportedCommentsFail,
  getPostatReportedCommentsSuccess,
  getPostatReportedPostsBegin,
  getPostatReportedPostsFail,
  getPostatReportedPostsSuccess,
  getPostReportsByIdBegin,
  getPostReportsByIdFail,
  getPostReportsByIdSuccess,
  ignorePostatReportedCommentsBegin,
  ignorePostatReportedCommentsFail,
  ignorePostatReportedCommentsSuccess,
  ignorePostatReportedPostatBegin,
  ignorePostatReportedPostatFail,
  ignorePostatReportedPostatSuccess,
} from "./actions";
import {
  ADD_POSTAT_MUSIC,
  DELETE_POSTAT,
  DELETE_POSTAT_MUSIC,
  DELETE_POSTAT_REPORTED_COMMENT,
  GET_ALL_POSTAT_POSTS,
  GET_COMMENT_REPORTS_BY_ID,
  GET_COMMENT_REPORTS_BY_ID_FAIL,
  GET_POSTAT_MUSIC,
  GET_POSTAT_MUSIC_BY_ID,
  GET_POSTAT_REPORTED_COMMENTS,
  GET_POSTAT_REPORTED_COMMENTS_FAIL,
  GET_POSTAT_REPORTED_POSTS,
  GET_POST_REPORTS_BY_ID,
  GET_POST_REPORTS_BY_ID_FAIL,
  IGNORE_POSTAT_REPORTED_COMMENTS,
  IGNORE_POSTAT_REPORTED_POSTAT,
} from "./actionTypes";

function* getReportedPosts(action) {
  try {
    yield put(getPostatReportedPostsBegin());
    const response = yield call(get, "/admin/postat/reports", action.request);
    yield put(getPostatReportedPostsSuccess(response.result));
  } catch (error) {
    yield put(getPostatReportedPostsFail(error));
  }
}
function* getPostReports(action) {
  try {
    yield put(getPostReportsByIdBegin());
    const response = yield call(get, "/admin/postat/reports", action.request);
    yield put(getPostReportsByIdSuccess(response.result));
  } catch (error) {
    yield put(getPostReportsByIdFail(error));
  }
}
function* deletePostat({ id, callback }) {
  try {
    yield put(deletePostatBegin());
    const response = yield call(del, `/admin/postat/${id}?isAdmin=1`);
    yield put(deletePostatSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(deletePostatFail(error));
  }
}
function* getCommentReports(action) {
  try {
    yield put(getCommentReportsByIdBegin());
    const response = yield call(get, "/admin/postat/reports", action.request);
    yield put(getCommentReportsByIdSuccess(response.result));
  } catch (error) {
    yield put(getCommentReportsByIdFail(error));
  }
}
function* deletePostatReportedComment({ commentId, postatId, callback }) {
  try {
    yield put(deletePostatReportedCommentBegin());
    const response = yield call(
      del,
      `/admin/postat/${postatId}/${commentId}?isAdmin=1`
    );
    yield put(deletePostatReportedCommentSuccess(response.result));
    if (callback) {
      yield call(callback); // Call the callback function
    }
  } catch (error) {
    yield put(deletePostatReportedCommentFail(error));
  }
}

function* getReportedComments(action) {
  try {
    yield put(getPostatReportedCommentsBegin());
    const response = yield call(
      get,
      `/admin/postat/reports?comment=1`,
      action.request
    );
    yield put(getPostatReportedCommentsSuccess(response.result));
  } catch (error) {
    yield put(getPostatReportedCommentsFail(error));
  }
}

function* getPostatMusic({ request, language }) {
  try {
    yield put(getPostatMusicBegin());

    const response = yield call(get, `postat/library/audio`, request);
    yield put(getPostatMusicSuccess(response.result));
  } catch (error) {
    yield put(getPostatMusicFail(error));
  }
}

function* addPostatMusic({ music, callback }) {
  try {
    console.log("msuic".music);
    yield put(addPostatMusicBegin());
    const response = yield call(post, "/admin/postat/musiclibrary", music);

    yield put(addPostatMusicSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(addPostatMusicFail(error));
  }
}

function* deletePostatMusic({ id, callback }) {
  try {
    yield put(deletePostatMusicBegin());
    const response = yield call(
      get,
      `postat/library/audio?media_id=${id}&delete=1`
    );
    callback && callback();
    yield put(deletePostatMusicSuccess(response.result));
  } catch (error) {
    yield put(deletePostatMusicFail(error));
  }
}

function* getPostatMusicByIdSaga({ id }) {
  try {
    yield put(getPostatMusicByIdBegin());
    const response = yield call(get, `/admin/deals/postatmusic/${id}`);
    yield put(getPostatMusicByIdSuccess(response.result));
  } catch (error) {
    yield put(getPostatMusicByIdFail(error));
  }
}

function* editPostatMusic({ params, id, music, callback }) {
  try {
    yield put(editPostatMusicBegin());
    const response = yield call(
      updateFormData,
      `/admin/deals/postatmusic?music_identifier=${params}`,
      music
    );
    // callback && callback();
    yield put(editPostatMusicSuccess(response.message));
  } catch (error) {
    yield put(editPostatMusicFail(error));
  }
}

function* getAllPostatPosts(action) {
  try {
    yield put(getAllPostatPostsBegin());
    const response = yield call(get, "/admin/postat?isAdmin=1", action.request);
    console.log("reponsesss", response?.result);
    yield put(getAllPostatPostsSuccess(response.result));
  } catch (error) {
    yield put(getAllPostatPostsFail(error));
  }
}
function* ignoreReportedPostat(action) {
  console.log("action", action);
  try {
    yield put(ignorePostatReportedPostatBegin());
    const response = yield call(
      get,
      `/admin/postat/reports?postatId=${action.postatId}&isIgnore=true`,
      action.request
    );
    action?.callback && action?.callback();
    yield put(ignorePostatReportedPostatSuccess(response.result));
  } catch (error) {
    yield put(ignorePostatReportedPostatFail(error));
  }
}
function* ignoreReportedComments(action) {
  console.log("action", action);
  try {
    yield put(ignorePostatReportedCommentsBegin());
    const response = yield call(
      get,
      `/admin/postat/reports?comment=1&commentId=${action.commentId}&isIgnore=true`,
      action.request
    );
    action?.callback && action?.callback();
    yield put(ignorePostatReportedCommentsSuccess(response.result));
  } catch (error) {
    yield put(ignorePostatReportedCommentsFail(error));
  }
}

function* ReportedPostatPostsSaga() {
  yield takeLatest(GET_POSTAT_REPORTED_POSTS, getReportedPosts);
  yield takeLatest(GET_POST_REPORTS_BY_ID, getPostReports);
  yield takeLatest(DELETE_POSTAT, deletePostat);
  yield takeLatest(GET_POSTAT_REPORTED_COMMENTS, getReportedComments);
  yield takeLatest(DELETE_POSTAT_REPORTED_COMMENT, deletePostatReportedComment);
  yield takeLatest(GET_COMMENT_REPORTS_BY_ID, getCommentReports);
  yield takeLatest(GET_POSTAT_MUSIC, getPostatMusic);
  yield takeLatest(ADD_POSTAT_MUSIC, addPostatMusic);
  yield takeLatest(DELETE_POSTAT_MUSIC, deletePostatMusic);
  yield takeLatest(GET_POSTAT_MUSIC_BY_ID, getPostatMusicByIdSaga);
  yield takeLatest(GET_ALL_POSTAT_POSTS, getAllPostatPosts);
  yield takeLatest(IGNORE_POSTAT_REPORTED_POSTAT, ignoreReportedPostat);
  yield takeLatest(IGNORE_POSTAT_REPORTED_COMMENTS, ignoreReportedComments);
}

export default ReportedPostatPostsSaga;
