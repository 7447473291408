import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Col,
  Container,
  Input,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import { Dropdown } from "semantic-ui-react";
import Loader from "../../../components/Common/Loader";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { countries } from "../../../components/UI/newCountries";
import { resetErrors, sendNotification } from "../../../store/users/actions";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import BackButton from "../../../components/UI/BackButton";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  addAnnouncements,
  getAnnouncementsById,
  resetAnnouncementErrors,
} from "../../../store/announcements/actions";
import DateTimePicker from "../../../components/Common/DateTimePicker";
import moment from "moment";
import { convertTime } from "../../../helpers/utils";

const AnnouncementDetails = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const data = useSelector((state) => state.Announcement);

  const warningAlertRef = useRef(null);
  const history = useHistory();
  const pageWrapRef = useRef(null);
  const params = useParams();

  const [preSelectedCountries, setPreSelectedCountries] = useState([]);
  const [preSelectedGenders, setPreSelectedGenders] = useState([]);
  const [preSelectedCitizens, setPreSelectedCitizens] = useState([]);
  const [preSelectedLanguages, setPreselectedLanguages] = useState([]);
  const [statusValue, setStatusvalue] = useState("");

  const [selectedDate, setSelectedDate] = useState(null);

  const [fromAge, setFromAge] = useState(0);
  const [toAge, setToAge] = useState(0);

  useEffect(() => {
    return () => dispatch(resetErrors());
  }, []);

  useEffect(() => {
    dispatch(getAnnouncementsById(params?.id));
    return () => {
      dispatch(resetAnnouncementErrors());
    };
  }, [params?.id]);

  useEffect(() => {
    setMessage(data?.announcementData?.message);
    setTitle(data?.announcementData?.title);
    setFromAge(data?.announcementData?.from_date);
    setToAge(data?.announcementData?.to_date);
    setStatusvalue(data?.announcementData?.status);
    if (data?.announcementData?.scheduled_time) {
      const scheduledMoment = moment.utc(data.announcementData.scheduled_time); // Convert to moment object
      setSelectedDate(scheduledMoment); // Set moment object in the state
    }

    let filters;
    try {
      const fixedFilters = data?.announcementData?.filters
        ?.replace(/'/g, '"')
        .replace(/None/g, "null");
      filters = fixedFilters && JSON.parse(fixedFilters);
    } catch (error) {
      console.error("Error parsing filters:", error);
    }

    // Check if filters exist before destructure
    if (filters) {
      const { countries, gender, from_date, to_date, citizenship, language } =
        filters;
      setPreSelectedCountries(countries);
      setPreSelectedGenders(gender ? gender : []);
      setPreSelectedCitizens(citizenship ? citizenship : []);
      setPreselectedLanguages(language ? language : []);
      setFromAge(from_date);
      setToAge(to_date);
    }
  }, [data?.announcementData]);

  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <BackButton
        label="Announcements"
        handleClick={() => history.push("/announcements")}
      />
      {data?.success && (
        <div>
          <UncontrolledAlert
            color="success"
            className="alert-dismissible fade show"
            role="alert"
          >
            {data?.success}
          </UncontrolledAlert>
        </div>
      )}{" "}
      <Container
        fluid
        id="userManagementWrapper"
        data-testid="rolePermissionsWrap"
      >
        <Row>
          <Breadcrumbs breadcrumbItem=" Announcement Details" />
          <Col xl="8">
            <Card className="mb-0 p-4 my-4">
              <form
                className="col-md-10 mx-4"
                // onSubmit={(e, v) => {
                //   handlePushNotificationSubmit(e, v);
                // }}
              >
                <div>
                  <div className="col-md-12">
                    <span className="country-label mb-2">
                      Selected countries :
                    </span>
                    {preSelectedCountries?.length > 0
                      ? preSelectedCountries?.map((citizen, index) => (
                          <div
                            key={index}
                            className="badge ms-2 bg-secondary text-white px-3 py-1 rounded-pill "
                          >
                            {citizen}
                          </div>
                        ))
                      : "NIL"}
                  </div>
                  <div className="col-md-12 mt-4">
                    <span className="country-label  mt-4">
                      Selected Languages :
                    </span>

                    {preSelectedLanguages?.length > 0
                      ? preSelectedLanguages?.map((citizen, index) => (
                          <div
                            key={index}
                            className="badge ms-2 bg-secondary text-white px-3 py-1 rounded-pill text-uppercase"
                          >
                            {citizen}
                          </div>
                        ))
                      : "NIL"}
                  </div>
                  <div className="col-md-12 mt-4">
                    <span className="country-label  mt-4">
                      Selected Genders :{" "}
                    </span>

                    {preSelectedGenders?.length > 0
                      ? preSelectedGenders?.map((citizen, index) => (
                          <div
                            key={index}
                            className="badge ms-2 bg-secondary text-white px-3 py-1 rounded-pill text-uppercase"
                          >
                            {citizen}
                          </div>
                        ))
                      : "NIL"}
                  </div>
                  <div className="col-md-12 mt-4">
                    <span className="country-label  mt-4">
                      Selected Citizenships :{" "}
                    </span>

                    {preSelectedCitizens?.length > 0
                      ? preSelectedCitizens?.map((citizen, index) => (
                          <div
                            key={index}
                            className="badge ms-2 bg-secondary text-white px-3 py-1 rounded-pill text-uppercase"
                          >
                            {citizen}
                          </div>
                        ))
                      : "NIL"}
                  </div>
                  <div className="col-md-12 mt-4">
                    <span className="country-label  mt-4">
                      Selected Age Range :{" "}
                    </span>
                    {fromAge ? fromAge + "-" + toAge : "NIL"}
                  </div>
                  {data?.announcementData?.scheduled_time !==
                    "0000-00-00 00:00:00" && (
                    <div className="col-md-12 mt-4">
                      <span className="country-label  mt-4">
                        {" "}
                        Scheduled Date(GMT) :{" "}
                      </span>{" "}
                      {data?.announcementData?.scheduled_time}
                    </div>
                  )}
                  <div className="col-md-12 mt-4">
                    <span className="country-label  mt-4">
                      {" "}
                      Created Date(GMT) :{" "}
                    </span>{" "}
                    {convertTime(data?.announcementData?.time_created)}
                  </div>
                  <div className="col-md-12 mt-4">
                    <p
                      htmlFor="example-date-input"
                      className="col-md-10 col-form-label  "
                    >
                      Title
                      <span className="mandatory">*</span>
                    </p>
                    <div className="col-md-10 dobWrapper">
                      <Input
                        name="name"
                        type="text"
                        id="example-date-input"
                        value={title}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-10 mt-4">
                    <p
                      htmlFor="example-date-input"
                      className="col-md-10 col-form-label"
                    >
                      Message
                      <span className="mandatory">*</span>
                    </p>
                    <div className="col-md-12 dobWrapper">
                      <Input
                        type="textarea"
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="10"
                        value={message}
                        readOnly
                      ></Input>
                    </div>
                    {/* <div className="form-check form-switch form-switch-lg col-md-4 enableDocumentSwitchWrap px-0 pt-2 pb-4">
                      <div className="">
                        <label
                          className="font-weight-normal"
                          htmlFor="enableDocument"
                        >
                          Push Notification
                        </label>
                      </div>
                      <div className="px-5">
                        <input
                          id={"enableDocument"}
                          className="form-check-input cursor-pointer"
                          type="checkbox"
                          checked={pushNotification}
                          onChange={(e) =>
                            setPushNotification(e.target.checked)
                          }
                          data-testid="checkbox"
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </form>
            </Card>
          </Col>
        </Row>
      </Container>
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default AnnouncementDetails;
