import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { API_URL } from "../../../../helpers/api_helper";

export const VideoPreview = ({
  isOpen,
  setOpen,
  //   popupButtonHandler,
  selectedRow,
  pairId,
  policy,
  signature,
}) => {
  const [cookies, setCookies] = useState("");
  const getCookies = async () => {
    try {
      const cookiesResponse = await fetch(`${API_URL}/flash/signed-cookies`);
      if (!cookiesResponse.ok) {
        throw new Error("Failed to fetch cookies");
      }
      const cookiesData = await cookiesResponse.json();
      console.log("2", cookiesData);
      setCookies(cookiesData.result);
    } catch (error) {
      console.error("Error fetching cookies:", error);
    }
  };
  console.log("ccoki", cookies);
  useEffect(() => {
    getCookies();
  }, [selectedRow]);
  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      backdrop={true}
      toggle={() => setOpen(false)}
    >
      <ModalHeader toggle={() => setOpen((state) => !state)}>Flash</ModalHeader>
      <ModalBody>
        {cookies && (
          <video width={470} height={670} controls autoplay>
            <source
              src={`${selectedRow?.video_url}?Policy=${encodeURIComponent(
                cookies?.["CloudFront-Policy"]
              )}&Key-Pair-Id=${encodeURIComponent(
                cookies?.["CloudFront-Key-Pair-Id"]
              )}&Signature=${encodeURIComponent(
                cookies?.["CloudFront-Signature"]
              )}`}
              type="video/mp4"
            />
          </video>
        )}
      </ModalBody>

      {/* <ModalFooter>
        {selectedRow?.status === "Rejected" && (
          <Button
            color="primary"
            onClick={() => popupButtonHandler("Accepted")}
          >
            Approve
          </Button>
        )}
        {selectedRow?.status === "Accepted" && (
          <Button
            color="secondary"
            onClick={() => popupButtonHandler("Rejected")}
          >
            Reject
          </Button>
        )}
      </ModalFooter> */}
    </Modal>
  );
};
