export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";
export const GET_ANNOUNCEMENTS_BEGIN = "GET_ANNOUNCEMENTS_BEGIN";
export const GET_ANNOUNCEMENTS_SUCCESS = "GET_ANNOUNCEMENTS_SUCCESS";
export const GET_ANNOUNCEMENTS_FAIL = "GET_ANNOUNCEMENTS_FAIL";

export const GET_ANNOUNCEMENTS_BY_ID = "GET_ANNOUNCEMENTS_BY_ID";
export const GET_ANNOUNCEMENTS_BY_ID_BEGIN = "GET_ANNOUNCEMENTS_BY_ID_BEGIN";
export const GET_ANNOUNCEMENTS_BY_ID_SUCCESS =
  "GET_ANNOUNCEMENTS_BY_ID_SUCCESS";
export const GET_ANNOUNCEMENTS_BY_ID_FAIL = "GET_ANNOUNCEMENTS_BY_ID_FAIL";

export const DELETE_ANNOUNCEMENTS = "DELETE_ANNOUNCEMENTS";
export const DELETE_ANNOUNCEMENTS_BEGIN = "DELETE_ANNOUNCEMENTS_BEGIN";
export const DELETE_ANNOUNCEMENTS_SUCCESS = "DELETE_ANNOUNCEMENTS_SUCCESS";
export const DELETE_ANNOUNCEMENTS_FAIL = "DELETE_ANNOUNCEMENTS_FAIL";

export const ADD_ANNOUNCEMENTS = "ADD_ANNOUNCEMENTS";
export const ADD_ANNOUNCEMENTS_BEGIN = "ADD_ANNOUNCEMENTS_BEGIN";
export const ADD_ANNOUNCEMENTS_SUCCESS = "ADD_ANNOUNCEMENTS_SUCCESS";
export const ADD_ANNOUNCEMENTS_FAIL = "ADD_ANNOUNCEMENTS_FAIL";

export const RESET_ANNOUNCEMENT_ERRORS = "RESET_ANNOUNCEMENT_ERRORS";
