
export const GET_APP_INSTALLATIONS = "GET_APP_INSTALLATIONS";
export const GET_APP_INSTALLATIONS_BEGIN = "GET_APP_INSTALLATIONS_BEGIN";
export const GET_APP_INSTALLATIONS_SUCCESS = "GET_APP_INSTALLATIONS_SUCCESS";
export const GET_APP_INSTALLATIONS_FAIL = "GET_APP_INSTALLATIONS_FAIL";

export const GET_INSTALLATION_COUNTRY = 'GET_INSTALLATION_COUNTRY';
export const GET_INSTALLATION_COUNTRY_BEGIN = 'GET_INSTALLATION_COUNTRY_BEGIN';
export const GET_INSTALLATION_COUNTRY_SUCCESS = 'GET_INSTALLATION_COUNTRY_SUCCESS';
export const GET_INSTALLATION_COUNTRY_FAIL = 'GET_INSTALLATION_COUNTRY_FAIL';

export const GET_INSTALLATION_REGION = 'GET_INSTALLATION_REGION';
export const GET_INSTALLATION_REGION_BEGIN = 'GET_INSTALLATION_REGION_BEGIN';
export const GET_INSTALLATION_REGION_SUCCESS = 'GET_INSTALLATION_REGION_SUCCESS';
export const GET_INSTALLATION_REGION_FAIL = 'GET_INSTALLATION_REGION_FAIL';

export const UPDATE_INSTALLATION_REGION = 'UPDATE_INSTALLATION_REGION';
export const UPDATE_INSTALLATION_REGION_BEGIN = 'UPDATE_INSTALLATION_REGION_BEGIN';
export const UPDATE_INSTALLATION_REGION_SUCCESS = 'UPDATE_INSTALLATION_REGION_SUCCESS';
export const UPDATE_INSTALLATION_REGION_FAIL = 'UPDATE_INSTALLATION_REGION_FAIL';

export const SAVE_INSTALLATION_REGION = 'SAVE_INSTALLATION_REGION';
export const SAVE_INSTALLATION_REGION_BEGIN = 'SAVE_INSTALLATION_REGION_BEGIN';
export const SAVEE_INSTALLATION_REGION_SUCCESS = 'SAVE_INSTALLATION_REGION_SUCCESS';
export const SAVE_INSTALLATION_REGION_FAIL = 'SAVE_INSTALLATION_REGION_FAIL';

export const STORE_APP_INSTALLATIONS_REQUEST = "STORE_APP_INSTALLATIONS_REQUEST";