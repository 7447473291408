import React from "react";
import DataTable from "react-data-table-component";
import { Card, CardTitle } from "reactstrap";
import TableSearch from "../../Common/Table_search";
import TableHeaderButton from "../../Common/Table_headerButton";
import TablePagination from "../../Common/Pagination";
import "./datatables.scss";
import { CSVLink } from "react-csv";
import Specific_search from "../../Common/Specific_search";

const Datatable = ({
  title,
  columns,
  rows,
  search,
  dropDown,
  languages,
  setSelectedLanguage,
  selectedLanguage,
  defaultSortField,
  defaultSortAsc,
  request,
  setRequest,
  totalRecords,
  filter,
  isPremium,
  payoutfilter,
  userRequestfilter,
  setImportFile,
  addNewClickHandler,
  upload,
  add,
  tableRowClicked,
  searchTerm,
  statusFilter,
  membershipFilter,
  loading,
  usernameFilter,
  reportedFilter,
  adminRoleFilter,
  statisticsFilter,
  payoutStatementFilter,
  addNewLabel,
  noPagination,
  tableCardClassName,
  reportStatusFilter,
  categoryFilter,
  countryFilter,
  adminManagementFilter,
  adminStatusFilter,
  countries,
  reqstatusFilter,
  reqplatformFilter,
  reqmembershipFilter,
  huddleFilter,
  appReviewFilter,
  deleteLabel,
  deleteDisabled,
  deleteClickHandler,
  showDeleteButton,
  deleteConfirmationMsg,
  regions,
  dateFilter,
  dateType,
  podiumFilter,
  reqTypeFilter,
  subscriptionSorting,
  userCountryFilter,
  expiryFilterPermissions,
  exportcsv,
  csvData,
  revenueFilter,
  sp_search,
  sp_search_param,
  allPostatSearchIdentifier,
  citizenShipFilter,
  grsFilter,
  filterAppSubscriptions,
  filterFlixPurchaseSummary,
  filterCoinsPurchaseSummary,
  ...rest
}) => {
  console.log("allPostatSearchIdentifier", allPostatSearchIdentifier);
  return (
    <Card className={`p-4 ${tableCardClassName}`}>
      {title && <CardTitle>{title}</CardTitle>}
      <div className="table_header">
        {search && (
          <TableSearch
            setRequest={setRequest}
            request={request}
            searchTerm={searchTerm}
          />
        )}
        {sp_search && (
          <Specific_search
            setRequest={setRequest}
            request={request}
            sp_search_param={sp_search_param}
            allPostatSearchIdentifier={allPostatSearchIdentifier}
          // searchTerm={searchTerm}
          />
        )}

        <TableHeaderButton
          dateFilter={dateFilter}
          dateType={dateType}
          filter={filter}
          isPremium={isPremium}
          payoutfilter={payoutfilter}
          reqstatusFilter={reqstatusFilter}
          reqplatformFilter={reqplatformFilter}
          reqmembershipFilter={reqmembershipFilter}
          usernameFilter={usernameFilter}
          reportedFilter={reportedFilter}
          statisticsFilter={statisticsFilter}
          payoutStatementFilter={payoutStatementFilter}
          setRequest={setRequest}
          request={request}
          setImportFile={setImportFile}
          addNewClickHandler={addNewClickHandler}
          upload={upload}
          add={add}
          dropDown={dropDown}
          languages={languages}
          setSelectedLanguage={setSelectedLanguage}
          selectedLanguage={selectedLanguage}
          statusFilter={statusFilter}
          membershipFilter={membershipFilter}
          addNewLabel={addNewLabel}
          reportStatusFilter={reportStatusFilter}
          categoryFilter={categoryFilter}
          countryFilter={countryFilter}
          adminRoleFilter={adminRoleFilter}
          adminManagementFilter={adminManagementFilter}
          adminStatusFilter={adminStatusFilter}
          countries={countries}
          userRequestfilter={userRequestfilter}
          huddleFilter={huddleFilter}
          appReviewFilter={appReviewFilter}
          showDeleteButton={showDeleteButton}
          deleteLabel={deleteLabel}
          deleteDisabled={deleteDisabled}
          deleteClickHandler={deleteClickHandler}
          deleteConfirmationMsg={deleteConfirmationMsg}
          regions={regions}
          podiumFilter={podiumFilter}
          reqTypeFilter={reqTypeFilter}
          userCountryFilter={userCountryFilter}
          expiryFilterPermissions={expiryFilterPermissions}
          exportcsv={exportcsv}
          csvData={csvData}
          columns={columns}
          revenueFilter={revenueFilter}
          citizenShipFilter={citizenShipFilter}
          grsFilter={grsFilter}
          filterAppSubscriptions={filterAppSubscriptions}
          filterFlixPurchaseSummary={filterFlixPurchaseSummary}
          filterCoinsPurchaseSummary={filterCoinsPurchaseSummary}
          {...rest}
        />
      </div>
      <DataTable
        columns={columns}
        data={rows}
        defaultSortField={defaultSortField}
        defaultSortAsc={defaultSortAsc}
        onSort={(column, sortDirection, event) =>
          setRequest({
            ...request,
            sort: column.selector,
            sort_order: sortDirection,
          })
        }
        sortServer={true}
        pagination={false}
        striped={true}
        onRowClicked={tableRowClicked}
      />
      <div>
        {!noPagination && totalRecords > 0 && (
          <TablePagination
            setRequest={setRequest}
            request={request}
            totalRecords={totalRecords}
            loading={loading}
          />
        )}
      </div>
    </Card>
  );
};

export default Datatable;
