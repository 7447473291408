import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Col,
  Container,
  Input,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import { Dropdown } from "semantic-ui-react";
import Loader from "../../../components/Common/Loader";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { countries } from "../../../components/UI/newCountries";
import { resetErrors, sendNotification } from "../../../store/users/actions";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BackButton from "../../../components/UI/BackButton";
import {
  addAnnouncements,
  getAnnouncements,
  resetAnnouncementErrors,
} from "../../../store/announcements/actions";
import DateTime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import DateTimePicker from "../../../components/Common/DateTimePicker";

const AddAnnouncement = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [pushNotification, setPushNotification] = useState(false);
  const data = useSelector((state) => state.Announcement);
  const [disabled, setDisabled] = useState(false);
  const warningAlertRef = useRef(null);
  const history = useHistory();
  const pageWrapRef = useRef(null);
  const [preSelectedCountries, setPreSelectedCountries] = useState([]);
  const [preSelectedGenders, setPreSelectedGenders] = useState([]);
  const [preSelectedCitizens, setPreSelectedCitizens] = useState([]);
  const [preSelectedLanguages, setPreselectedLanguages] = useState([]);
  const [statusValue, setStatusvalue] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [fromAge, setFromAge] = useState(0);
  const [toAge, setToAge] = useState(0);
  const [language, setLanguage] = useState("");
  const initialGenderOptions = [
    { key: "male", text: "MALE", value: "male" },
    { key: "all", text: "ALL", value: "ALL" },
    { key: "female", text: "FEMALE", value: "female" },
    { key: "pns", text: "PREFER NOT TO SAY", value: "Prefer Not to Say" },
  ];

  const initialStatusOptions = [
    { key: "draft", text: "Draft", value: "draft" },
    // { key: "all", text: "ALL", value: "all" },
    { key: "send", text: "Sent Immediately", value: "send" },
    { key: "scheduled", text: "Scheduled", value: "scheduled" },
  ];
  const initialCitizenOptions = [
    { key: "visitor", text: "Visitor", value: "VISITOR" },
    { key: "resident", text: "Resident", value: "RESIDENT" },
    { key: "citizen", text: "Citizen", value: "CITIZEN" },
    { key: "ambassador", text: "Ambassador", value: "AMBASSADOR" },
    { key: "leader", text: "Leader", value: "LEADER" },
    { key: "officer", text: "Officer", value: "OFFICER" },
    { key: "minister", text: "Minister", value: "MINISTER" },
    { key: "all", text: "All", value: "ALL" },
  ];
  const initialLanguageOptions = [
    { key: "english", text: "English", value: "english" },

    { key: "arabic", text: "Arabic", value: "arabic" },
    { key: "all", text: "ALL", value: "ALL" },
  ];

  const [genderOptions, setGenderOptions] = useState(initialGenderOptions);
  const [citizenOptions, setCitizenOptions] = useState(initialCitizenOptions);
  const [languageOptions, setLanguageOptions] = useState(
    initialLanguageOptions
  );

  // const [statusOptions, setStatusOptions] = useState(initialStatusOptions);

  // const genderOptions = [
  //   { key: "male", text: "MALE", value: "male" },
  //   { key: "all", text: "ALL", value: "all" },
  //   { key: "female", text: "FEMALE", value: "female" },
  // ];

  const getCountryOptions = () =>
    countries?.sort().map((country) => ({
      key: country,
      value: country,
      text: country,
    }));

  const onCountrySelection = (data) => {
    setPreSelectedCountries([...data]);
  };
  const onGenderSelection = (data) => {
    if (data.includes("ALL")) {
      setPreSelectedGenders(["ALL"]);
      setGenderOptions([{ key: "all", text: "ALL", value: "ALL" }]);
    } else {
      setPreSelectedGenders(data);
      setGenderOptions(initialGenderOptions);
    }
  };
  const onCitizenSelection = (data) => {
    if (data.includes("ALL")) {
      setPreSelectedCitizens(["ALL"]);
      setCitizenOptions([{ key: "all", text: "ALL", value: "ALL" }]);
    } else {
      setPreSelectedCitizens(data);
      setCitizenOptions(initialCitizenOptions);
    }
  };
  const onStatusSelection = (data) => {
    setStatusvalue(data);
  };
  const onLanguageSelection = (data) => {
    if (data.includes("ALL")) {
      setPreselectedLanguages(["ALL"]);
      setLanguageOptions([{ key: "all", text: "ALL", value: "ALL" }]);
    } else {
      setPreselectedLanguages(data);
      setLanguageOptions(initialLanguageOptions);
    }
  };

  const fromDate = startDate ? new Date(startDate) : "";
  const toDate = endDate ? new Date(endDate) : "";

  fromDate && fromDate.setDate(fromDate.getDate() + 1);
  toDate && toDate.setDate(toDate.getDate() + 1);

  useEffect(() => {
    if (
      !title?.trim() ||
      !message?.trim() ||
      statusValue === "" ||
      (statusValue === "scheduled" && !selectedDate) ||
      errorMessage !== ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [title, message, statusValue, selectedDate, errorMessage]);

  // const saveAsDraft = (e) => {
  //   e.preventDefault();

  //   const payload = {
  //     message: message?.trim(),
  //     content: title?.trim(),

  //     module_slug: "broadcast_notification",
  //     gender: preSelectedGenders,
  //     countries: preSelectedCountries,
  //     status: "draft",
  //     from_date: fromDate ? fromDate.toISOString().split("T")[0] : "",
  //     to_date: toDate ? toDate.toISOString().split("T")[0] : "",
  //   };
  //   dispatch(
  //     addAnnouncements({
  //       status: "draft",
  //       packageData: payload,
  //       callback: () => {
  //         history.push("/announcements");
  //       },
  //     })
  //   );
  // };
  useEffect(() => {
    return () => {
      dispatch(resetAnnouncementErrors());
    };
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const now = moment().utc();

      const selectedMoment = moment.utc(selectedDate);

      if (selectedMoment.isBefore(now)) {
        setErrorMessage(
          "The selected time must be later than the current UTC time."
        );
      } else {
        setErrorMessage("");
      }
    }
  }, [selectedDate]);

  const handleDateSelection = (date) => {
    if (date && date.isValid()) {
      // Format the date as per your requirements
      const formattedDate = date.format("YYYY-MM-DD HH:mm"); // Example format (change as needed)
      formattedDate && setSelectedDate(formattedDate);
    } else {
      console.log("Invalid date selected");
    }
  };

  // Function to restrict past dates
  const restrictPastDates = (currentDate) => {
    return currentDate.isAfter(moment().subtract(1, "day")); // Restrict to future dates
  };
  const sendAnnouncement = (e) => {
    e.preventDefault();
    const payload = {
      message: message?.trim(),
      content: title?.trim(),
      module_slug: "broadcast_notification",
      gender: preSelectedGenders,
      countries: preSelectedCountries,
      citizenship: preSelectedCitizens,
      status: statusValue,
      from_date: fromAge === 0 ? null : fromAge,
      to_date: toAge === 0 ? null : toAge,
      scheduled_time: statusValue === "scheduled" ? selectedDate : "",
      language: preSelectedLanguages,
    };
    dispatch(
      addAnnouncements({
        status: statusValue,
        packageData: payload,
        // callback: () => {
        //   history.push("/announcements");
        // },
      })
    );
    setTimeout(() => {
      history.push("/announcements");
    }, 2000);
  };

  const handleFromAgeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setFromAge(value);

    // Ensure "To" age is always greater than "From" age
    if (value >= toAge) {
      setToAge(value + 1);
    }
  };
  const handleToAgeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value > fromAge) {
      setToAge(value);
    }
  };

  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <BackButton
        label="Announcements"
        handleClick={() => history.push("/announcements")}
      />
      {data?.error && (
        <div>
          <UncontrolledAlert
            color="danger"
            className="alert-dismissible fade show"
            role="alert"
          >
            {data?.error}
          </UncontrolledAlert>
        </div>
      )}{" "}
      <Container
        fluid
        id="userManagementWrapper"
        data-testid="rolePermissionsWrap"
      >
        <Row>
          <Breadcrumbs breadcrumbItem="Add Announcement" />
          <Col xl="8">
            <Card className="mb-0 p-4 my-4">
              <form
                className="col-md-10 mx-4"
                // onSubmit={(e, v) => {
                //   handlePushNotificationSubmit(e, v);
                // }}
              >
                <div>
                  <div className="col-md-12">
                    <span className="country-label mb-2">Select country</span>
                    {countries && (
                      <Dropdown
                        className="mt-2"
                        style={{
                          width: "40%",
                        }}
                        placeholder="Countries"
                        fluid
                        multiple
                        selection
                        value={preSelectedCountries}
                        options={getCountryOptions()}
                        onChange={(e, data) => {
                          onCountrySelection(data.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-12 mt-4">
                    <span className="country-label  mt-4">Select Language</span>

                    <Dropdown
                      className="mt-2"
                      style={{
                        width: "40%",
                      }}
                      placeholder="Select Language"
                      fluid
                      selection
                      multiple
                      value={preSelectedLanguages}
                      options={languageOptions}
                      onChange={(e, data) => {
                        onLanguageSelection(data.value);
                      }}
                    />
                  </div>
                  <div className="col-md-12 mt-4">
                    <span className="country-label  mt-4">Select Gender </span>

                    <Dropdown
                      className="mt-2"
                      style={{
                        width: "40%",
                      }}
                      placeholder="Select gender"
                      fluid
                      multiple
                      selection
                      value={preSelectedGenders}
                      options={genderOptions}
                      onChange={(e, data) => {
                        onGenderSelection(data.value);
                      }}
                    />
                  </div>
                  <div className="col-md-12 mt-4">
                    <span className="country-label  mt-4">
                      Select Citizenship{" "}
                    </span>

                    <Dropdown
                      className="mt-2"
                      style={{
                        width: "40%",
                      }}
                      placeholder="Select Citizenship"
                      fluid
                      multiple
                      selection
                      value={preSelectedCitizens}
                      options={citizenOptions}
                      onChange={(e, data) => {
                        onCitizenSelection(data.value);
                      }}
                    />
                  </div>

                  <div className="col-md-12 mt-4">
                    <span className="country-label  mt-4">
                      Select Age Range{" "}
                    </span>
                    <Row className="mt-3 ms-1">
                      <div className="col-md-4">
                        <span>From </span>
                        <input
                          type="number"
                          className="form-control"
                          value={fromAge}
                          onChange={handleFromAgeChange}
                          min="13"
                          max="120"
                        />
                      </div>
                      <div className="col-md-4">
                        <span>To </span>
                        <input
                          type="number"
                          className="form-control"
                          value={toAge}
                          onChange={handleToAgeChange}
                          min={fromAge + 1} // Set minimum to be "From" age + 1
                          max="120"
                        />
                      </div>
                    </Row>
                  </div>

                  <div className="col-md-12 mt-4">
                    <span className="country-label  mt-4">Select Status </span>
                    <span className="mandatory">*</span>

                    <Dropdown
                      className="mt-2"
                      style={{
                        width: "40%",
                      }}
                      placeholder="Select status"
                      fluid
                      selection
                      value={statusValue}
                      options={initialStatusOptions}
                      onChange={(e, data) => {
                        onStatusSelection(data.value);
                      }}
                    />
                  </div>
                  {statusValue === "scheduled" && (
                    <div className="col-md-12 mt-4">
                      <span className="country-label  mt-4">
                        Select Date(GMT){" "}
                      </span>
                      <span className="mandatory">*</span>

                      <DateTimePicker
                        onDateChange={handleDateSelection}
                        isValidDate={restrictPastDates}
                        dateFormat="YYYY-MM-DD"
                        timeFormat="HH:mm"
                        placeholder="Select a  date and time"
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                      />
                      <span className="text-danger mt-2">{errorMessage}</span>
                    </div>
                  )}

                  <div className="col-md-12 mt-4">
                    <p
                      htmlFor="example-date-input"
                      className="col-md-10 col-form-label  "
                    >
                      Title
                      <span className="mandatory">*</span>
                    </p>
                    <div className="col-md-10 dobWrapper">
                      <Input
                        name="name"
                        type="text"
                        id="example-date-input"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-10 mt-4">
                    <p
                      htmlFor="example-date-input"
                      className="col-md-10 col-form-label"
                    >
                      Message
                      <span className="mandatory">*</span>
                    </p>
                    <div className="col-md-12 dobWrapper">
                      <Input
                        type="textarea"
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="10"
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                      ></Input>
                    </div>
                    {/* <div className="form-check form-switch form-switch-lg col-md-4 enableDocumentSwitchWrap px-0 pt-2 pb-4">
                      <div className="">
                        <label
                          className="font-weight-normal"
                          htmlFor="enableDocument"
                        >
                          Push Notification
                        </label>
                      </div>
                      <div className="px-5">
                        <input
                          id={"enableDocument"}
                          className="form-check-input cursor-pointer"
                          type="checkbox"
                          checked={pushNotification}
                          onChange={(e) =>
                            setPushNotification(e.target.checked)
                          }
                          data-testid="checkbox"
                        />
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="modal-footer col-md-10 mt-4">
                  <button
                    // type="submit"
                    className="btn bgcolor-btn-green ms-4"
                    disabled={disabled}
                    onClick={(e) => sendAnnouncement(e)}
                  >
                    {statusValue === "draft" && "Save as Draft"}
                    {(statusValue === "send" || statusValue === "") &&
                      "Send Announcement"}
                    {statusValue === "scheduled" && "Schedule Announcement"}
                  </button>
                </div>
              </form>
            </Card>
          </Col>
        </Row>
      </Container>
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default AddAnnouncement;
