import React from "react";

const LanguageDropDown = ({ setLanguage, setInputs, initialInput }) => {
  console.log("initial", initialInput);

  return (
    <div className="col-md-4 row justify-content-center align-items-center">
      <div
        style={{
          height: "31px",
        }}
        className="col-md-10  justify-content-center align-items-center my-2   "
      >
        <select
          className="form-control mb-4"
          id="huddleType"
          // ref={ref}
          onChange={(e) => {
            setLanguage(e?.target?.value);
            setInputs([...initialInput]);
          }}
          defaultValue="english"
        >
          <option value="">select language</option>
          <option value="english">English</option>
          <option value="hindi">Hindi</option>
          <option value="arabic">Arabic</option>
          <option value="tagalog">Tagalog</option>
          <option value="kurdish">kurdish</option>
          <option value="french">French</option>
          <option value="russian">Russian</option>
          <option value="persian">Persian </option>
          <option value="spanish">Spanish</option>
        </select>
      </div>
    </div>
  );
};

export default LanguageDropDown;
