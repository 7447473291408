import React from "react";
import defaultProfile from "../../../../assets/images/users/user.svg";
import premiumImage from "../../../../assets/icons/premium.svg";
import verifedImage from "../../../../assets/icons/verified.svg";

const LevelUsers = ({
  image_url,
  name,
  username,
  is_premium,
  is_deleted,
  id,
  is_verified,
  subscription_count,
  ...levelUser
}) => {
  return (
    <div>
      {is_deleted === "false" ? (
        <div className="level_users">
          <div className="level_user_pictureWrap">
            <a
              onClick={() => {
                const win = window.open(`/user-details/${id}?tab=2`, "_blank");
                win.focus();
              }}
              style={{ cursor: "pointer" }}
            >
              {is_verified === "true" ? (
                <img className="user_isPremiumImage" src={verifedImage} />
              ) : is_premium === "true" ? (
                <img className="user_isPremiumImage" src={premiumImage} />
              ) : null}
              <img src={image_url === null ? defaultProfile : image_url} />
            </a>
          </div>
          <div className="level_user_details">
            <a
              onClick={() => {
                const win = window.open(`/user-details/${id}?tab=2`, "_blank");
                win.focus();
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="level_profile_name" title={name}>
                {name} &nbsp;
              </div>
            </a>
            <div className="level_username text-muted" title={username}>
              {username}
            </div>
            {subscription_count >= 1 ? (
              <div className="level_username text-muted">
                Subscriptions :{" "}
                {subscription_count >= 1 ? (
                  <span className="badge bg-warning">{subscription_count}</span>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div className="level_users deletedUser bg-soft-red p-1">
          <div>User ID - {id} is deleted</div>
          {is_premium === "true" ? (
            <img className="deletedUser_crown" src={premiumImage} />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default LevelUsers;
