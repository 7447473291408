import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  del,
  get,
  getFile,
  post,
  update,
  updateFormData,
} from "../../helpers/api_helper";
import {
  API_GET_FLAX_PACKAGES,
  API_GET_FLAX_PACKAGES_BY_ID,
  API_GET_FLAX_PURCHASE_HISTORY,
  FLIX_PURCHASE_SUMMARY,
} from "../../helpers/url_helper";
import {
  addFlaxPackagesBegin,
  addFlaxPackagesFail,
  addFlaxPackagesSuccess,
  deleteFlaxPackagesBegin,
  deleteFlaxPackagesFail,
  deleteFlaxPackagesSuccess,
  getFlaxPackagesBegin,
  getFlaxPackagesById,
  getFlaxPackagesByIdBegin,
  getFlaxPackagesByIdFail,
  getFlaxPackagesByIdSuccess,
  getFlaxPackagesFail,
  getFlaxPackagesSuccess,
  getFlaxPurchaseHistoryBegin,
  getFlaxPurchaseHistoryByUserIdBegin,
  getFlaxPurchaseHistoryByUserIdFail,
  getFlaxPurchaseHistoryByUserIdSuccess,
  getFlaxPurchaseHistoryFail,
  getFlaxPurchaseHistorySuccess,
  updateFlaxPackagesBegin,
  updateFlaxPackagesFail,
  updateFlaxPackagesSuccess,
  exportFlixPurchaseSummaryBegin,
  exportFlixPurchaseSummarySuccess,
  exportFlixPurchaseSummaryFail,
} from "./actions";
import {
  ADD_FLAX_PACKAGES,
  DELETE_FLAX_PACKAGES,
  GET_FLAX_PACKAGES,
  GET_FLAX_PACKAGES_BY_ID,
  GET_FLAX_PACKAGES_BY_ID_BEGIN,
  GET_FLAX_PURCHASE_HISTORY,
  GET_FLAX_PURCHASE_HISTORY_BEGIN,
  GET_FLAX_PURCHASE_HISTORY_BY_USER_ID,
  GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_BEGIN,
  GET_FLAX_PURCHASE_HISTORY_FAIL,
  UPDATE_FLAX_PACKAGES,
  EXPORT_FLIX_PURCHASE_SUMMARY,
} from "./actionTypes";

function* getFlaxPackages({ request }) {
  try {
    yield put(getFlaxPackagesBegin());

    const response = yield call(get, API_GET_FLAX_PACKAGES, request);

    yield put(getFlaxPackagesSuccess(response.result));
  } catch (error) {
    yield put(getFlaxPackagesFail(error));
  }
}
function* deleteFlaxPackages({ id, callback }) {
  try {
    yield put(deleteFlaxPackagesBegin());
    const response = yield call(
      del,
      API_GET_FLAX_PACKAGES_BY_ID.replace("{id}", id)
    );
    console.log("22", response);
    callback && callback();
    yield put(deleteFlaxPackagesSuccess(response.result));
  } catch (error) {
    yield put(deleteFlaxPackagesFail(error));
  }
}
function* addFlaxPackagesSaga({ packageData, callback }) {
  try {
    yield put(addFlaxPackagesBegin());
    const response = yield call(post, API_GET_FLAX_PACKAGES, packageData);

    yield put(addFlaxPackagesSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(addFlaxPackagesFail(error));
  }
}
function* getFlaxPackagesByIdSaga({ id }) {
  try {
    yield put(getFlaxPackagesByIdBegin());
    const response = yield call(
      get,
      API_GET_FLAX_PACKAGES_BY_ID.replace("{id}", id)
    );
    yield put(getFlaxPackagesByIdSuccess(response.result));
  } catch (error) {
    yield put(getFlaxPackagesByIdFail(error));
  }
}
function* updateFlaxPackagesSaga({ id, packageData, callback }) {
  try {
    yield put(updateFlaxPackagesBegin());

    const response = yield call(
      updateFormData,
      API_GET_FLAX_PACKAGES_BY_ID.replace("{id}", id), // Update the API endpoint accordingly
      packageData
    );
    callback && callback();
    yield put(updateFlaxPackagesSuccess(response.message));
  } catch (error) {
    yield put(updateFlaxPackagesFail(error));
  }
}
function* getFlaxPurchaseHistorySaga(action) {
  try {
    yield put(getFlaxPurchaseHistoryBegin());
    const response = yield call(
      get,
      API_GET_FLAX_PURCHASE_HISTORY,
      action.request
    );

    yield put(getFlaxPurchaseHistorySuccess(response.result));
  } catch (error) {
    yield put(getFlaxPurchaseHistoryFail(error));
  }
}
function* getFlaxPurchaseHistoryByUserIdSaga(action) {
  console.log("Actions", action);
  try {
    yield put(getFlaxPurchaseHistoryByUserIdBegin());
    const response = yield call(
      get,
      `/admin/flax/flaxpurchasehistory?user_id=${action?.user_id}`,
      action.request
    );

    yield put(getFlaxPurchaseHistoryByUserIdSuccess(response.result));
  } catch (error) {
    yield put(getFlaxPurchaseHistoryByUserIdFail(error));
  }
}

function* exportFlixPurchaseSummary({ saveAs, request }) {
  try {
    yield put(exportFlixPurchaseSummaryBegin());
    const response = yield call(getFile, "/admin/flax/flaxpurchasehistory", request, {
      headers: {
        "Content-Type": "text/csv",
      },
      responseType: "blob",
    })
    const blob = new Blob([response], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `flix_purchase_summary_export_${new Date().toLocaleString()}.csv`);
    yield put(exportFlixPurchaseSummarySuccess())
  } catch (error) {
    yield put(exportFlixPurchaseSummaryFail(error));
  }
}

function* flaxPackagessaga() {
  yield takeLatest(GET_FLAX_PACKAGES, getFlaxPackages);
  yield takeLatest(DELETE_FLAX_PACKAGES, deleteFlaxPackages);
  yield takeLatest(ADD_FLAX_PACKAGES, addFlaxPackagesSaga);
  yield takeLatest(GET_FLAX_PACKAGES_BY_ID, getFlaxPackagesByIdSaga);

  yield takeLatest(UPDATE_FLAX_PACKAGES, updateFlaxPackagesSaga);
  yield takeLatest(GET_FLAX_PURCHASE_HISTORY, getFlaxPurchaseHistorySaga);
  yield takeLatest(
    GET_FLAX_PURCHASE_HISTORY_BY_USER_ID,
    getFlaxPurchaseHistoryByUserIdSaga
  );
  yield takeLatest(EXPORT_FLIX_PURCHASE_SUMMARY, exportFlixPurchaseSummary);
}

export default flaxPackagessaga;
