import { all, call, fork, takeEvery, put, take } from "redux-saga/effects";
import {
  addManualCoinBegin,
  addManualCoinError,
  addManualCoinSuccess,
  getAllManualCoinBegin,
  getAllManualCoinError,
  getAllManualCoinSuccess,
  getAllManualCoin,
  deleteCoinBegin,
  deleteCoinSuccess,
} from "./actions";
import { post, get, del } from "../../helpers/api_helper";
import {
  ADD_MANUAL_COIN,
  DELETE_MANUAL_COIN,
  GET_ALL_MANUAL_COIN,
} from "./actionTypes";
import { API_MANUALCOIN } from "../../helpers/url_helper";
import { alert } from "../../util/toast";

function* addManualCoin({ payload }) {
  try {
    yield put(addManualCoinBegin());
    const response = yield call(post, API_MANUALCOIN, payload);
    console.log("aaapayload", payload);
    yield put(addManualCoinSuccess(response));
    payload?.action === "credited" &&
      alert.success("Manual COiNS has been Credited succesfully for the user!");
    payload?.action === "debited" &&
      alert.success("Manual COiNS has been Debited succesfully from the user!");
    if (payload.reload && payload.user_id) {
      yield put(getAllManualCoin({ user_id: payload.user_id }));
    }
  } catch (error) {
    yield put(addManualCoinError(error));
  }
}

function* getAllManualCoins({ payload }) {
  console.log("payload--=", payload);
  try {
    yield put(getAllManualCoinBegin(payload));
    const response = yield call(get, API_MANUALCOIN, payload);
    yield put(getAllManualCoinSuccess(response?.result));
  } catch (error) {
    yield put(getAllManualCoinError(error));
  }
}

function* deleteManualCoin({ payload }) {
  try {
    yield put(deleteCoinBegin());
    const response = yield call(del, `${API_MANUALCOIN}?id=${payload.id}`);
    yield put(deleteCoinSuccess(response));
    yield put(getAllManualCoin({ user_id: payload.user_id }));
  } catch (error) {
    yield put(getAllManualCoinError(error));
  }
}

function* manualCoinSaga() {
  yield takeEvery(ADD_MANUAL_COIN, addManualCoin);
  yield takeEvery(GET_ALL_MANUAL_COIN, getAllManualCoins);
  yield takeEvery(DELETE_MANUAL_COIN, deleteManualCoin);
}

export default manualCoinSaga;
