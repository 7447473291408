import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, Col, Container, Row, UncontrolledAlert } from "reactstrap";
import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Loader from "../../../components/Common/Loader";
import {
  addPaymentMethod,
  getPaymentMethods,
  resetPaymentMethodErrors,
} from "../../../store/paymentManagement/actions";

const AddPaymentMethod = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [imageFile, setImageFile] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [serviceFee, setServiceFee] = useState("");
  const [message, setMessage] = useState("");
  const [minWithdrawalAmt, setMinWithdrawalAmt] = useState("");
  const [processFee, setprocessFee] = useState("");
  const [processTime, setProcessTime] = useState("");
  const [methodList, setMethodList] = useState([
    {
      payment_method: "",
      language: "",
    },
  ]);
  const data = useSelector((state) => state?.PaymentMethods);
  const languages = [
    "english",
    "hindi",
    "arabic",
    "tagalog",
    "french",
    "russian",
    "kurdish",
    "persian",
    "spanish",
  ];

  useEffect(() => {
    dispatch(getPaymentMethods());
    return () => {
      dispatch(resetPaymentMethodErrors());
    };
  }, []);
  useEffect(() => {
    for (let i = 0; i < methodList.length; i++) {
      if (
        paymentMethod.trim() === "" ||
        methodList[i]?.payment_method.trim() === "" ||
        // serviceFee === "" ||
        // minWithdrawalAmt === "" ||
        processTime === "" ||
        // processFee === "" ||
        methodList?.length < 9 ||
        imageFile === null ||
        message !== ""
      ) {
        setDisabled(true);
        break;
      } else {
        setDisabled(false);
      }
      if (methodList[i] == undefined) {
        setDisabled(true);
        break;
      } else {
        setDisabled(false);
      }
    }
  }, [
    methodList,
    paymentMethod,
    // serviceFee,
    // minWithdrawalAmt,
    // processFee,
    imageFile,
    data?.paymentMethods?.length,
    message,
    processTime,
  ]);

  const handleReportUsersChangeOrAdd = (index, key, value, language) => {
    const list = [...methodList];
    if (list[index]) {
      list[index][key] = value && value?.trim();

      list[index]["language"] = language;
      setMethodList(list);
    } else {
      list[index] = {
        payment_method: value && value?.trim(),
        language: language,
      };
      setMethodList(list);
    }
  };
  // useEffect(() => {
  //   for (let i = 0; i < data?.paymentMethods?.length; i++) {
  //     if (
  //       paymentMethod.trim().toLowerCase().replace(/\s+/g, "-") ===
  //         data?.paymentMethods[i]?.payment_method
  //           .trim()
  //           .toLowerCase()
  //           .replace(/\s+/g, "-") ||
  //       methodList[0]?.payment_method
  //         ?.trim()
  //         .toLowerCase()
  //         .replace(/\s+/g, "-") ===
  //         data?.paymentMethods[i].payment_method
  //           .toLowerCase()
  //           .replace(/\s+/g, "-")
  //     ) {
  //       setMessage("This Payment Method Already Exists");
  //       break;
  //     } else {
  //       setMessage("");
  //     }
  //   }
  // }, [paymentMethod, methodList]);
  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error, data?.success, message]);

  const scrollToAlertMessage = () => {
    if (data?.error || data?.success || message) {
      let element = warningAlertRef?.current;
      let top = element?.offsetTop;
      let height = element?.getBoundingClientRect()?.height;
      pageWrapRef?.current?.scrollTo(0, top - height - 30);
    }
  };

  const handleImageChange = (e) => {
    const selectedFile = e?.target?.files[0];
    setImageFile(selectedFile);
  };
  const handleSubmit = () => {
    const formData = new FormData();
    imageFile && formData?.append("file", imageFile);
    formData?.append(
      "data",
      JSON.stringify({
        payment_method_identifier: `${paymentMethod
          ?.toLowerCase()
          ?.replace(/\s/g, "-")}`,
        service_fee: "",
        status: true,
        method_list: methodList,
        minimum_withdrawal_amount: "",
        process_fee: "",
        process_time: processTime,
      })
    );
    dispatch(
      addPaymentMethod({
        method: formData,
        callback: () => {
          history.push("/payment-method");
        },
      })
    );
  };
  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        ref={pageWrapRef}
      >
        <BackButton
          label="Payment methods"
          handleClick={() => history.push("/payment-method")}
        />
        <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Add Payment Method" />
            {message && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color="danger"
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {message}
                </UncontrolledAlert>
              </div>
            )}
            {data?.error && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color="danger"
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error}
                </UncontrolledAlert>
              </div>
            )}
            <Col xl="8">
              <Card className="mb-0 p-4 my-4">
                <div className="text-muted">
                  <AvForm
                    onValidSubmit={(e, v) => {
                      if (!disabled) {
                        handleSubmit(e, v);
                      } else {
                        e.preventDefault();
                      }
                    }}
                  >
                    <div
                      className="table-responsive mt-4"
                      style={{ overflowX: "hidden" }}
                    >
                      <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Payment Method
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="text"
                              className="form-control mb-4 "
                              // value={purpose?.purpose}
                              id="name-input"
                              onChange={(e) => {
                                setPaymentMethod(e.target.value);
                              }}
                              placeholder="Enter payment method"
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Process Fee
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="number"
                              className="form-control mb-4 "
                              // value={purpose?.purpose}
                              id="name-input"
                              onChange={(e) => {
                                setprocessFee(e.target.value);
                              }}
                              placeholder="Enter process fee"
                            />
                          </div>
                        </div> */}
                      </div>
                      <div className="d-flex mb-4">
                        {/* <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Service Fee
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="number"
                              className="form-control mb-4 "
                              // value={purpose?.purpose}
                              id="name-input"
                              onChange={(e) => {
                                setServiceFee(e.target.value);
                              }}
                              placeholder="Enter service fee"
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter minimum withdrawal amount
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="number"
                              className="form-control mb-4 "
                              // value={purpose?.purpose}
                              id="name-input"
                              onChange={(e) => {
                                setMinWithdrawalAmt(e.target.value);
                              }}
                              placeholder="Enter minimum withdrawal amount"
                            />
                          </div>
                        </div> */}
                      </div>
                      <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Process Time
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="number"
                              className="form-control mb-4 "
                              // value={purpose?.purpose}
                              id="name-input"
                              onChange={(e) => {
                                setProcessTime(e.target.value);
                              }}
                              placeholder="Enter process Time"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Upload Icon
                            {/* <span className="mandatory">*</span> */}
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName6"
                              type="file"
                              className="form-control mb-4 "
                              accept="image/*"
                              id="name-input"
                              onChange={handleImageChange}
                            />
                          </div>
                        </div>
                      </div>
                      {languages?.length &&
                        languages?.map((language, i) =>
                          language == "english" ? (
                            <div key={i} className="col-md-12">
                              <div className="d-flex mx-1">
                                <label className="col-md-2 lang-font col-form-label">
                                  {`${language
                                    ?.slice(0, 1)
                                    .toUpperCase()}${language?.slice(1)}`}
                                </label>
                                <div className="col-md-6">
                                  <div className="col-md-8 dobWrapper">
                                    <AvInput
                                      name="adminName"
                                      type="text"
                                      className="form-control mb-4 "
                                      // value={purpose?.purpose}
                                      id="name-input"
                                      onChange={(e) => {
                                        handleReportUsersChangeOrAdd(
                                          i,
                                          "payment_method",
                                          e.target.value,
                                          language
                                        );
                                      }}
                                      placeholder="Enter Payment Method"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div key={i} className=" d-flex mx-1">
                              <label className="col-md-2 lang-font col-form-label">
                                {`${language
                                  ?.slice(0, 1)
                                  .toUpperCase()}${language?.slice(1)}`}
                              </label>{" "}
                              <div className="col-md-6">
                                <div className="col-md-8 dobWrapper">
                                  <AvInput
                                    name="adminName"
                                    type="text"
                                    className="form-control mb-4 "
                                    // value={purpose?.purpose}
                                    id="name-input"
                                    onChange={(e) => {
                                      handleReportUsersChangeOrAdd(
                                        i,
                                        "payment_method",
                                        e.target.value,
                                        language
                                      );
                                    }}
                                    placeholder="Enter Payment Method"
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        )}

                      <div className="mt-4">
                        <button
                          type="submit"
                          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                          // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                          disabled={disabled}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default AddPaymentMethod;
