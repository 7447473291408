import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  challengeHistoryColumnData,
  giftTransferColumnData,
  wholeChallengeHistoryColumnData,
} from "../../../helpers/columns";
import premiumImage from "../../../assets/icons/premium.svg";
import defaultProfile from "../../../assets/images/users/user.svg";
import { convertTime, convertToUAETime } from "../../../helpers/utils";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../helpers/constants";
import Loader from "../../../components/Common/Loader";
import Lottie from "lottie-react";
import { getGiftTransferHistory } from "../../../store/GiftTransfers/actions";
import {
  getChallengesHistory,
  getChallengesHistoryByUserId,
} from "../../../store/podiums/actions";
import ContributorDetails from "../../UserMangement/TabSection/UserMatrix/contributorDetails";
import ParticipantDetails from "../../UserMangement/TabSection/UserMatrix/participantDetails";

const ChallengeHistory = () => {
  const [contributorModal, setContributorModal] = useState(false);
  const [participantModal, setParticipantModal] = useState(false);
  const [currentChallenge, setCurrentChallenge] = useState("");
  const [currentPodium, setCurrentPodium] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "transfered_date",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    per_page: 25,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const data = useSelector((state) => state.Podiums);
  console.log("challnge", data);

  useEffect(() => {
    request && dispatch(getChallengesHistory(request));
  }, [request]);
  console.log("2222", data);

  const challengeHistory =
    data?.challengesData?.challenges_history?.length >= 0 &&
    data?.challengesData?.challenges_history?.map((challenge, index) => {
      return {
        ...challenge,
        no: (request?.page - 1) * request?.per_page + index + 1,
        time_created: (
          <>
            <div
              className=""
              data-tag="allowRowEvents"
              title={challenge?.time_created}
            >
              {convertTime(challenge?.time_created)}
            </div>
          </>
        ),
        podium_manager_name: (
          <>
            <div
              className=""
              data-tag="allowRowEvents"
              title={challenge?.podium_manager?.name}
            >
              {challenge?.podium_manager?.name}
            </div>
          </>
        ),
        podium_manager_coins: (
          <>
            <div
              className=""
              data-tag="allowRowEvents"
              title={challenge?.podium_manager?.coins}
            >
              {challenge?.podium_manager?.coins}
            </div>
          </>
        ),
      };
    });

  const showContributorDetails = () => {
    setContributorModal(!contributorModal);
  };
  const showParticipantDetails = () => {
    setParticipantModal(!participantModal);
  };

  const tableRowClickHandler = (param) => {
    setCurrentChallenge(param?.challenge_id);
    setCurrentPodium(param?.podium_id);
    param?.challenge_name !== "GIFTS"
      ? showContributorDetails()
      : showParticipantDetails();
  };

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        // id="userMgmtList"
        // ref={pageWrapRef}
      >
        <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Challenges" />
            <div
              className="w-auto alert alert-warning text-start "
              role="alert"
            >
              <strong>Note:</strong> Values displayed before <em>08/10/2024</em>{" "}
              are not valid, as the 10% revenue share for both Host and Flashat
              was introduced after this date.
            </div>
            {/* <div className="container-fluid"> */}
            <Datatable
              tableID={"flaxTable"}
              columns={wholeChallengeHistoryColumnData}
              rows={challengeHistory}
              setRequest={setRequest}
              request={request}
              //   dateFilter
              //   dateType="Transfered Date Range"
              totalRecords={data?.challengesData?.total_items}
              //   search
              // add
              // dropDown
              // languages={languages}
              // addNewClickHandler={addNewClickHandler}
              // selectedLanguage={selectedLanguage}
              // setSelectedLanguage={setSelectedLanguage}
              // addNewLabel={t("Admins.addAdmin.addBtn")}
              //   searchTerm={data?.request?.keyword}
              tableRowClicked={(param) => tableRowClickHandler(param)}
              loading={data?.loading}
              tableCardClassName={"snoTable"}
            />
            {/* </div> */}
          </Row>

          {contributorModal && (
            <ContributorDetails
              toggle={showContributorDetails}
              modal={contributorModal}
              challengeId={currentChallenge}
              podiumId={currentPodium}
            />
          )}
          {participantModal && (
            <ParticipantDetails
              toggle={showParticipantDetails}
              modal={participantModal}
              challengeId={currentChallenge}
              podiumId={currentPodium}
            />
          )}
        </Container>
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default ChallengeHistory;
