import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Col,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  Row,
  UncontrolledAlert,
  CardSubtitle,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { checkUserPermission } from "../../../helpers/utils";

const PayoutGeneralSettings = ({
  payoutProcessingFee,
  payoutPPFirsttime,
  payoutPPSecondtime,
  payoutPPThirdtime,
  payoutPPFourthtime,
  payoutRefundablePPAmount,
  payoutRefundablePPtype,
  payoutTransferFee,
  payoutProcessingFeeChange,
  payoutPPFirsttimeChange,
  payoutPPSecondtimeChange,
  payoutPPThirdtimeChange,
  payoutPPFourthtimeChange,
  payoutRefundablePPAmountChange,
  payoutRefundablePPtypeChange,
  payout_save,
  payoutTransferFeeChange,
}) => {
  const { t } = useTranslation();
  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Settings",
          "App settings"
        )
      );
    }
  }, [loginData]);
  return (
    <Card className="appSettingCard">
      <CardTitle className="cardTitle">{t("AppSettings.Payouts")}</CardTitle>
      <CardBody>
        <AvForm
          onValidSubmit={(e, v) => {
            //handleUpdate(e, v);
          }}
        >
          <div className="generalSettings">
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>Transfer Fee in %</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="freeUsersMinutes"
                  type="number"
                  onChange={payoutTransferFeeChange}
                  value={payoutTransferFee}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in Percentage</span>
              </div>
            </div>
            {/* <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>Processing Fee in %</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="freeUsersMinutes"
                  type="number"
                  onChange={payoutProcessingFeeChange}
                  value={payoutProcessingFee}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in Percentage</span>
              </div>
            </div> */}

            {/* <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>Minimum PP for First Payout</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText "
                  name="freeUsersMinutes"
                  type="number"
                  onChange={payoutPPFirsttimeChange}
                  value={payoutPPFirsttime}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in Numbers</span>
              </div>
            </div> */}
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>Refundable PP on Subscription</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="freeUsersMinutes"
                  type="number"
                  onChange={payoutRefundablePPAmountChange}
                  value={payoutRefundablePPAmount}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in Numbers</span>
              </div>
            </div>
          </div>
          {/* <div className="generalSettings">
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>Minimum PP for Second Payout</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="freeUsersMinutes"
                  type="number"
                  onChange={payoutPPSecondtimeChange}
                  value={payoutPPSecondtime}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in Numbers</span>
              </div>
            </div>

            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div> Minimum PP for Third Payout</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText "
                  name="freeUsersMinutes"
                  type="number"
                  onChange={payoutPPThirdtimeChange}
                  value={payoutPPThirdtime}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in Numbers</span>
              </div>
            </div>
          </div> */}
          <div className="generalSettings">
            {/* <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>Minimum PP for Fourth Payout Onwards</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="freeUsersMinutes"
                  type="number"
                  onChange={payoutPPFourthtimeChange}
                  value={payoutPPFourthtime}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in Numbers</span>
              </div>
            </div> */}
          </div>
          <div className="generalSettings">
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>Refundable PP withrdrawal on each renewals</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="freeUsersMinutes"
                  type="select"
                  onChange={payoutRefundablePPtypeChange}
                  value={payoutRefundablePPtype}
                  disabled={!isEditable}
                >
                  <option value="non-recurring">Non Recurring</option>
                  <option value="recurring">Recurring</option>
                </AvField>
              </div>
            </div>
          </div>
        </AvForm>
      </CardBody>
      <CardFooter className="cardFooter">
        <button
          onClick={payout_save}
          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
          // disabled={
          //   !payoutProcessingFee ||
          //   !payoutPPFirsttime ||
          //   !payoutPPSecondtime ||
          //   !payoutPPThirdtime ||
          //   !payoutPPFourthtime ||
          //   !payoutRefundablePPAmount ||
          //   !payoutTransferfee ||
          //   !isEditable
          // }
        >
          {t("settings.Save")}
        </button>
      </CardFooter>
    </Card>
  );
};

export default PayoutGeneralSettings;
