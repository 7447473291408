import { call, put, takeLatest } from "@redux-saga/core/effects";
import { del, get, post, updateFormData } from "../../helpers/api_helper";
import { API_GET_FLASH_CATEGORIES } from "../../helpers/url_helper";
import {
  addFlashCategoriesBegin,
  addFlashCategoriesFail,
  addFlashCategoriesSuccess,
  blockOrUnblockUserFromFlashBegin,
  blockOrUnblockUserFromFlashFail,
  blockOrUnblockUserFromFlashSuccess,
  deleteFlashCategoriesBegin,
  deleteFlashCategoriesFail,
  deleteFlashCategoriesSuccess,
  deleteFlashesBegin,
  deleteFlashesFail,
  deleteFlashesSuccess,
  deleteFlashReportedCommentBegin,
  deleteFlashReportedCommentFail,
  deleteFlashReportedCommentSuccess,
  editFlashCategoriesBegin,
  editFlashCategoriesFail,
  editFlashCategoriesSuccess,
  getAllFlashBegin,
  getAllFlashFail,
  getAllFlashSuccess,
  getFlashBlockedUsersBegin,
  getFlashBlockedUsersFail,
  getFlashBlockedUsersSuccess,
  getFlashCategoriesBegin,
  getFlashCategoriesByIdBegin,
  getFlashCategoriesByIdFail,
  getFlashCategoriesByIdSuccess,
  getFlashCategoriesFail,
  getFlashCategoriesSuccess,
  getFlashCommentReportsBegin,
  getFlashCommentReportsFail,
  getFlashCommentReportsSuccess,
  getFlashesBegin,
  getFlashesFail,
  getFlashesSuccess,
  getFlashReportedCommentBegin,
  getFlashReportedCommentFail,
  getFlashReportedCommentSuccess,
  getFlashReportsBegin,
  getFlashReportsFail,
  getFlashReportsSuccess,
  getReportedFlashBegin,
  getReportedFlashFail,
  getReportedFlashSuccess,
} from "./actions";
import {
  ADD_FLASH_CATEGORIES,
  BLOCK_OR_UNBLOCK_USER_FROM_FLASH,
  DELETE_FLASHES,
  DELETE_FLASH_CATEGORIES,
  DELETE_FLASH_REPORTED_COMMENT,
  EDIT_FLASH_CATEGORIES,
  GET_ALL_FLASH,
  GET_FLASHES,
  GET_FLASH_BLOCKED_USERS,
  GET_FLASH_CATEGORIES,
  GET_FLASH_CATEGORIES_BY_ID,
  GET_FLASH_COMMENT_REPORTS,
  GET_FLASH_REPORTED_COMMENT,
  GET_FLASH_REPORTS,
  GET_REPORTED_FLASH,
} from "./actionTypes";

function* getFlashCategories(res) {
  try {
    yield put(getFlashCategoriesBegin());
    const response = yield call(get, API_GET_FLASH_CATEGORIES, res.request);

    yield put(getFlashCategoriesSuccess(response.result));
  } catch (error) {
    yield put(getFlashCategoriesFail(error));
  }
}

function* addFlashCategories({ category, callback }) {
  try {
    yield put(addFlashCategoriesBegin());
    const response = yield call(post, API_GET_FLASH_CATEGORIES, category);

    yield put(addFlashCategoriesSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(addFlashCategoriesFail(error));
  }
}

function* getFlashCategoriesByIdSaga({ id }) {
  try {
    yield put(getFlashCategoriesByIdBegin());
    const response = yield call(get, `/admin/flash/categories/${id}`);
    yield put(getFlashCategoriesByIdSuccess(response.result));
  } catch (error) {
    yield put(getFlashCategoriesByIdFail(error));
  }
}

function* editFlashCategories({ params, id, categoryList, callback }) {
  try {
    console.log("7373", categoryList);
    yield put(editFlashCategoriesBegin());
    const response = yield call(
      updateFormData,
      `/admin/flash/categories/${id}?category=${params}`,
      categoryList
    );
    // callback && callback();
    yield put(editFlashCategoriesSuccess(response.message));
  } catch (error) {
    yield put(editFlashCategoriesFail(error));
  }
}

function* deleteFlashCategories({ id, callback }) {
  try {
    yield put(deleteFlashCategoriesBegin());
    const response = yield call(del, `/admin/flash/categories/${id}`);
    callback && callback();
    yield put(deleteFlashCategoriesSuccess(response.result));
  } catch (error) {
    yield put(deleteFlashCategoriesFail(error));
  }
}
function* getFlashes({ userId, request }) {
  try {
    yield put(getFlashesBegin());
    const response = yield call(get, `/flash/user/${userId}/mylist`, request);

    yield put(getFlashesSuccess(response.result));
  } catch (error) {
    yield put(getFlashesFail(error));
  }
}
function* deleteFlashes({ id, callback }) {
  try {
    yield put(deleteFlashesBegin());
    const response = yield call(del, `/flash/${id}`);

    callback && callback();
    yield put(deleteFlashesSuccess(response.result));
  } catch (error) {
    yield put(deleteFlashesFail(error));
  }
}
function* getAllFlash(res) {
  try {
    yield put(getAllFlashBegin());
    const response = yield call(get, "/admin/users/flash-list", res.request);

    yield put(getAllFlashSuccess(response.result));
    res?.callback && res?.callback();
  } catch (error) {
    yield put(getAllFlashFail(error));
  }
}
function* getReportedFlash(res) {
  try {
    yield put(getReportedFlashBegin());
    const response = yield call(get, "/admin/flash/reported-list", res.request);

    yield put(getReportedFlashSuccess(response.result));
  } catch (error) {
    yield put(getReportedFlashFail(error));
  }
}

function* getFlashReports(res) {
  try {
    yield put(getFlashReportsBegin());
    const response = yield call(
      get,
      `/admin/flash/reported-list/${res?.id}`,
      res.request
    );

    yield put(getFlashReportsSuccess(response.result));
  } catch (error) {
    yield put(getFlashReportsFail(error));
  }
}

function* getFlashReportedComment(res) {
  try {
    yield put(getFlashReportedCommentBegin());
    const response = yield call(
      get,
      "/admin/flash/reported-comments",
      res.request
    );

    yield put(getFlashReportedCommentSuccess(response.result));
  } catch (error) {
    yield put(getFlashReportedCommentFail(error));
  }
}

function* deleteFlashReportedComment({ id, callback }) {
  try {
    yield put(deleteFlashReportedCommentBegin());
    const response = yield call(del, `/admin/flash/reported-comments/${id}`);

    yield put(deleteFlashReportedCommentSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(deleteFlashReportedCommentFail(error));
  }
}

// FLASH_COMMENT_REPORTS Saga
function* getFlashCommentReports(res) {
  try {
    yield put(getFlashCommentReportsBegin());
    const response = yield call(
      get,
      `/admin/flash/reported-comments/${res?.id}`,
      res?.request
    );

    yield put(getFlashCommentReportsSuccess(response.result));
  } catch (error) {
    yield put(getFlashCommentReportsFail(error));
  }
}
function* getFlashBlockedUsers(res) {
  try {
    yield put(getFlashBlockedUsersBegin());
    const response = yield call(
      get,
      "/admin/users/flash-block-list",
      res.request
    );

    yield put(getFlashBlockedUsersSuccess(response.result));
  } catch (error) {
    yield put(getFlashBlockedUsersFail(error));
  }
}
function* blockOrUnblockSaga(res) {
  console.log("res", res);
  try {
    yield put(blockOrUnblockUserFromFlashBegin());
    const response = yield call(post, `/user/func-block/${res?.request?.id}`, {
      action: res?.request?.action,
      functionality: "flash",
    });

    yield put(blockOrUnblockUserFromFlashSuccess(response.result));
    res?.request?.callback && res?.request?.callback();
  } catch (error) {
    yield put(blockOrUnblockUserFromFlashFail(error));
  }
}

function* flashSaga() {
  yield takeLatest(GET_FLASH_CATEGORIES, getFlashCategories);
  yield takeLatest(ADD_FLASH_CATEGORIES, addFlashCategories);
  yield takeLatest(GET_FLASH_CATEGORIES_BY_ID, getFlashCategoriesByIdSaga);
  yield takeLatest(EDIT_FLASH_CATEGORIES, editFlashCategories);
  yield takeLatest(DELETE_FLASH_CATEGORIES, deleteFlashCategories);
  yield takeLatest(GET_FLASHES, getFlashes);
  yield takeLatest(DELETE_FLASHES, deleteFlashes);
  yield takeLatest(GET_ALL_FLASH, getAllFlash);
  yield takeLatest(GET_REPORTED_FLASH, getReportedFlash);
  yield takeLatest(GET_FLASH_REPORTS, getFlashReports);
  yield takeLatest(GET_FLASH_REPORTED_COMMENT, getFlashReportedComment);
  yield takeLatest(GET_FLASH_COMMENT_REPORTS, getFlashCommentReports);
  yield takeLatest(DELETE_FLASH_REPORTED_COMMENT, deleteFlashReportedComment);
  yield takeLatest(GET_FLASH_BLOCKED_USERS, getFlashBlockedUsers);
  yield takeLatest(BLOCK_OR_UNBLOCK_USER_FROM_FLASH, blockOrUnblockSaga);
}
export default flashSaga;
