import { call, takeEvery, put, takeLatest } from "redux-saga/effects";

import { LOGIN_USER, LOGIN_AUTH, GET_USER_PERMISSIONS } from "./actionTypes";
import {
  apiError,
  loginSuccess,
  AuthenticationSuccess,
  getRolePermissionBegin,
  getRolePermissionSuccess,
  getRolePermissionFail,
} from "./actions";

import {
  POST_AUTH,
  POST_LOGIN,
  USER_PERMISSIONS,
} from "../../../helpers/url_helper";
import { setLocalStorage } from "../../../helpers/utils";

import { post, get } from "../../../helpers/api_helper";

function* loginUser({ payload: { user }, callback }) {
  try {
    const response = yield call(post, POST_LOGIN, user);
    callback && callback();
    yield put(loginSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* loginAuthentication({ payload, callback }) {
  try {
    const response = yield call(post, POST_AUTH, payload.otp, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    });
   if (response && response.result.user.role) {
    setLocalStorage("AUTH_DETAILS", response?.result);
    callback && callback();
    yield put(AuthenticationSuccess(response));   
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getRolePermissions({ payload, callback }) {
  try {
    yield put(getRolePermissionBegin());
    const response = yield call(get, USER_PERMISSIONS.replace("{id}", payload));
    yield put(getRolePermissionSuccess(response.result));
    callback && callback(response?.result?.role);
  } catch (error) {
    yield put(getRolePermissionFail(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGIN_AUTH, loginAuthentication);
  yield takeLatest(GET_USER_PERMISSIONS, getRolePermissions);
}

export default authSaga;
