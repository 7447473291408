import React, { useEffect, useState } from "react";
import { convertTime } from "../../helpers/utils";
import { getFlaxPurchaseHistory } from "../../store/flaxPackages/actions";
import { wholeFlaxPurchaseColumnData } from "../../helpers/columns";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/UI/Breadcrumb";
import Datatable from "../../components/UI/Tables/Datatable";
import Loader from "../../components/Common/Loader";
import { useTranslation } from "react-i18next";

import saveAs from "file-saver";

import { exportFlixPurchaseSummary } from "../../store/flaxPackages/actions"

const FlixPurchaseSummary = () => {
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "purchased_on",
    sort_order: "desc",
    page: 1,
    page_count: 50,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });

  const { t } = useTranslation();

  useEffect(() => {
    request && dispatch(getFlaxPurchaseHistory(request));
  }, [request]);

  const data = useSelector((state) => state.flaxPackages);
  const profilePicClick = (e, id) => {
    e.preventDefault();
    const win = window.open(`/user-details/${id}?tab=1`, "_blank");
    win.focus();
  };

  const addNewClickHandler = () => {
    dispatch(exportFlixPurchaseSummary({ saveAs: saveAs, request: { ...request, export: "True" } }));
  };

  const historyData =
    data?.flaxPurchaseHistoryData?.purchases?.length >= 0 &&
    data?.flaxPurchaseHistoryData?.purchases?.map((result, index) => {
      return {
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        username: (
          <div
            onClick={(e) => {
              profilePicClick(e, result?.user_id);
            }}
            data-tag="allowRowEvents"
            title={result?.username}
          >
            {result?.username}
          </div>
        ),
        flax: (
          <div data-tag="allowRowEvents" title={result?.flax}>
            {result?.flax}
          </div>
        ),
        price: (
          <div data-tag="allowRowEvents" title={result?.flax}>
            {result?.price ? result?.price + " " + result?.currency : ""}
          </div>
        ),
        purchased_on: (
          <div data-tag="allowRowEvents" title={result?.time_created}>
            {convertTime(result?.time_created)}
          </div>
        ),
      };
    });
  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
    //   ref={pageWrapRef}
    >
      <Container id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="FLiX Purchase Summary" />

          <Datatable
            defaultSortField={request?.sort || "purchased_on"}
            defaultSortAsc={
              request?.sort_order === "asc" ? true : false
            }
            // dateFilter
            // dateType="Transfered Date Range"
            tableID={"flaxTable"}
            columns={wholeFlaxPurchaseColumnData}
            rows={historyData}
            setRequest={setRequest}
            request={request}
            reqplatformFilter={request?.device_types}
            totalRecords={data?.flaxPurchaseHistoryData?.total}
            search
            searchTerm={data?.request?.keyword}
            // reqstatusFilter={request?.status_filter}
            loading={data?.loading}
            tableCardClassName={"snoTable"}
            filterFlixPurchaseSummary
            addNewClickHandler={addNewClickHandler}
            addNewLabel={t("UserManagement.export")}
            add
            dateFilter
            dateType="Transfered Date Range"
          />
        </Row>
      </Container>

      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default FlixPurchaseSummary;
