import { useEffect } from "react";
import {
  Col,
  Card,
  Input,
  Button,
  Row,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  getPaginatedLevel,
  getParentsAndLevels,
} from "../../../store/users/userMatrix/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Common/Loader";
import { CardContent } from "@material-ui/core";
import { useState } from "react";
import { post } from "../../../helpers/api_helper";
import { API_FLAX_REQUEST_POST } from "../../../helpers/url_helper";
import { useAsync } from "../../../util/useAsync";
import { alert } from "../../../util/toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const FlaxRequestForm = ({ user }) => {
  const { data, error, status, run, isLoading, isSuccess, isError } =
    useAsync();
  const [modal, setModal] = useState(false);
  const [flaxRequest, setFlaxRequest] = useState({ validation: {} });
  const dispatch = useDispatch();
  let treeResponse = useSelector((state) => state.mlmTree);
  const history = useHistory();

  const toggleFlaxSummary = () => setModal(!modal);

  useEffect(() => {
    if (data?.message && isSuccess) {
      alert.success("Request has been created successfully.");
      history.push(`payout-requests/${data?.result?.payout_id}`);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (user) {
      dispatch(getParentsAndLevels(user.id));
    }
  }, [user]);

  const onChangeHandler = (event) => {
    const { name, value } = event.nativeEvent.target;
    const valueToInt = parseFloat(value) || value;
    if (valueToInt <= 0 || isNaN(valueToInt)) {
      setFlaxRequest({
        ...flaxRequest,
        [name]: valueToInt,
        validation: { [name]: "Value should be greater than 0." },
      });
      return;
    }
    setFlaxRequest({
      ...flaxRequest,
      [name]: valueToInt,
      validation: { [name]: "" },
    });
  };
  const onSubmit = (event) => {
    event.preventDefault();
    if (
      !flaxRequest.validation.requested_points_for_review &&
      !flaxRequest.validation.flax_rate
    ) {
      const { validation, ...payload } = flaxRequest;
      run(post(API_FLAX_REQUEST_POST, { ...payload, user_id: user.id }));
    }
  };
  return (
    <Card className="p-4">
      {treeResponse.loading && (
        <CardContent>
          <Row className="p-8">
            <Loader />
          </Row>
        </CardContent>
      )}
      {!treeResponse.loading && (
        <Row>
          <Col>
            {treeResponse?.tree?.cummulativePP && (
              <div className="row">
                <div className="col-md-12">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <h5 className="mb-4">FLiX Summary</h5>
                      </div>
                    </div>
                    {/* <div className="row mb-2 border-bottom">
                      <div className="col-sm-12">
                        <p className="mb-0">Generated </p>
                      </div>
                      <div className="col-sm-12">
                        <p className="text-muted text-end">
                          <i>FLiX </i>
                          {treeResponse?.tree?.userPP?.total_generated_pp}
                        </p>
                      </div>
                    </div>

                    <div className="row mb-2 border-bottom">
                      <div className="col-sm-12">
                        <p className="mb-0">Mining </p>
                      </div>
                      <div className="col-sm-12">
                        <p className="text-muted text-end">
                          <i>FLiX </i>
                          {treeResponse?.tree?.userPP?.total_awarded_pp}
                        </p>
                      </div>
                    </div>

                    <div className="row mb-2 border-bottom">
                      <div className="col-sm-12">
                        <p className="mb-0">Refunded </p>
                      </div>
                      <div className="col-sm-12">
                        <p className="text-muted mb-0 text-end">
                          <i>FLiX </i>
                          {treeResponse?.tree?.userPP?.total_refunded_pp}
                        </p>
                      </div>
                    </div>

                    <div className="row mb-2 border-bottom">
                      <div className="col-sm-12">
                        <p className="mb-0">Manually added </p>
                      </div>
                      <div className="col-sm-12">
                        <p className="text-muted mb-0 text-end ">
                          <i>FLiX </i>
                          {treeResponse?.tree?.userPP?.manual_pp}
                        </p>
                      </div>
                    </div> */}

                    <div className="row mt-4 mb-2">
                      <div className="col-sm-12">
                        <p className="mb-0 text-success fw-bold">
                          Total Gained
                        </p>
                      </div>
                      <div className="col-sm-12">
                        <p className=" mb-0 text-end text-success">
                          <i>FLiX </i>
                          <b>
                            {treeResponse?.tree?.userPP?.total_credited_flax}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="row mt-2 border-bottom">
                      <div className="col-sm-12">
                        <p className="mb-0 text-danger fw-bold">
                          Total Debited
                        </p>
                      </div>
                      <div className="col-sm-12">
                        <p className=" mb-0 text-end text-danger">
                          <i>FLiX </i>
                          <b>
                            {treeResponse?.tree?.userPP?.total_debited_flax}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="row mt-2 border-bottom">
                      <div className="col-sm-12">
                        <p className="mb-0 text-danger fw-bold">
                          Total Withdrawn
                        </p>
                      </div>
                      <div className="col-sm-12">
                        <p className=" mb-0 text-end text-danger">
                          <i>FLiX </i>
                          <b>{treeResponse?.tree?.userPP?.withdrawn_pp}</b>
                        </p>
                      </div>
                    </div>
                    <div className="row mt-4 ">
                      <div className="col-sm-12">
                        <p className="mb-0">
                          <b>Current Total Balance</b>
                        </p>
                      </div>
                      <div className="col-sm-12">
                        <p className="text-muted mb-0 text-end">
                          <i>FLiX </i>
                          <b>
                            {treeResponse?.tree?.userPP?.total_available_flax}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="row mt-2 ">
                      <div className="col-sm-8">
                        <p
                          onClick={toggleFlaxSummary}
                          className="mb-0 cursor-pointer text-decoration-underline text-primary"
                        >
                          View Detailed Summary
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Modal centered isOpen={modal} toggle={toggleFlaxSummary}>
              <ModalHeader toggle={toggleFlaxSummary}>FLiX summary</ModalHeader>
              <ModalBody>
                <div className="card-body">
                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <h5 className="mb-0 text-success">Credited</h5>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0">Generated </p>
                    </div>
                    <div className="col-sm-4">
                      <p className="text-muted text-end">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.total_generated_pp}
                      </p>
                    </div>
                  </div>

                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0">Mining </p>
                    </div>
                    <div className="col-sm-4 ">
                      <p className="text-muted text-end">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.total_awarded_pp}
                      </p>
                    </div>
                  </div>

                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0">Refunded</p>
                    </div>
                    <div className="col-sm-4">
                      <p className="text-muted mb-0 text-end">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.total_refunded_pp}
                      </p>
                    </div>
                  </div>

                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0">Manual FLiX </p>
                    </div>
                    <div className="col-sm-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.manual_pp}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0">Converted from COiNS</p>
                    </div>
                    <div className="col-sm-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.sold_points_flax}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0">Purchased</p>
                    </div>
                    <div className="col-sm-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.purchased_flax}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0">Received</p>
                    </div>
                    <div className="col-sm-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.received_flax}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <h5 className="mb-0 mt-2 text-danger">Debited</h5>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0">Converted to COiNS</p>
                    </div>
                    <div className="col-sm-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.purchased_points_flax}
                      </p>
                    </div>
                  </div>

                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0">Sent</p>
                    </div>
                    <div className="col-sm-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.sent_flax}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0">Pending Review</p>
                    </div>
                    <div className="col-sm-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.pending_pp}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0">Podium Camera Purchased </p>
                    </div>
                    <div className="col-sm-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>

                        {treeResponse?.tree?.userPP?.podium_camera_purchase}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0">Cancellation Deductions</p>
                    </div>
                    <div className="col-sm-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.cancelled_deductions}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0">FLiX on Hold</p>
                    </div>
                    <div className="col-sm-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.violation_flix}
                      </p>
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0">Total Withdrawn</p>
                    </div>
                    <div className="col-sm-4">
                      <p className="text-muted mb-0 text-end ">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.withdrawn_pp}
                      </p>
                    </div>
                  </div>

                  <div className="row mt-4 mb-2">
                    <div className="col-sm-8">
                      <p className="mb-0 text-success fw-bold">
                        Total Credited
                      </p>
                    </div>
                    <div className="col-sm-4">
                      <p className=" mb-0 text-end text-success fw-bold">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.total_credited_flax}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-2 mb-2">
                    <div className="col-sm-8">
                      <p className="mb-0 text-danger fw-bold">Total Debited</p>
                    </div>
                    <div className="col-sm-4">
                      <p className=" mb-0 text-end text-danger fw-bold">
                        <i>FLiX </i>
                        {treeResponse?.tree?.userPP?.total_debited_flax}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-2 border-bottom">
                    <div className="col-sm-8">
                      <p className="mb-0 fw-bold">Current Total Balance</p>
                    </div>
                    <div className="col-sm-4">
                      <p className=" mb-0 text-end text-danger fw-bold">
                        <i>FLiX </i>
                        <b>
                          {treeResponse?.tree?.userPP?.total_available_flax}
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </Col>
          <Col style={{ background: "#f5f6f8" }} className="p-4">
            <Card>
              <CardContent>
                <Row>
                  <Col className="mt-4">
                    <h5 className="mb-4">Payout Request</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form>
                      <FormGroup className="mb-4">
                        <Label>FLiX For Review</Label>
                        <Input
                          type="number"
                          min={0}
                          max={100000}
                          name="requested_points_for_review"
                          onChange={onChangeHandler}
                          value={flaxRequest.requested_points_for_review}
                          invalid={
                            !!flaxRequest.validation.requested_points_for_review
                          }
                        />
                        <span className="text-danger">
                          {flaxRequest.validation.requested_points_for_review}
                        </span>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Label>FLiX Rate</Label>
                        <Input
                          name="flax_rate"
                          onChange={onChangeHandler}
                          value={flaxRequest.flax_rate}
                          invalid={!!flaxRequest.validation.flax_rate}
                        />
                        <span className="text-danger">
                          {flaxRequest.validation.flax_rate}
                        </span>
                      </FormGroup>
                      <Row>
                        <Col className="text-center">
                          <Button
                            color="primary"
                            disabled={
                              flaxRequest.validation.flax_rate ||
                              flaxRequest.validation
                                .requested_points_for_review ||
                              isLoading ||
                              !flaxRequest.requested_points_for_review ||
                              !flaxRequest.flax_rate
                            }
                            onClick={(event) => onSubmit(event.nativeEvent)}
                          >
                            {isLoading ? "Saving" : "Save"}
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pt-4">
                          {!isLoading && (
                            <span className="text-danger">{error}</span>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </CardContent>
            </Card>
          </Col>
        </Row>
      )}
    </Card>
  );
};
