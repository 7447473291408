import React from "react";
import { Modal } from "reactstrap";

const FileChooseAlert = ({
  modal_center,
  setmodal_center,
  title,
  readFile,
  setFormChanged,
  toggle,
  setIsButtonDisabled,
}) => {
  return (
    <Modal isOpen={modal_center} toggle={toggle} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{title}</h5>
        <button
          type="button"
          onClick={() => setmodal_center(false)}
          className="close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body  text-center">
        <button
          type="button"
          className="btn btn-danger my-3"
          onClick={(e) => {
            readFile("removed");
            setmodal_center(false);
            setFormChanged(true);
            setIsButtonDisabled(false);
          }}
        >
          Remove Image
        </button>
        <p> OR </p>
        <div className="d-flex">
          <div className="col-xl-3 col-md-3 col-sm-3"></div>
          <input
            type="file"
            accept="image/*"
            className="px-5"
            onChange={(e) => {
              readFile(e);
              setFormChanged(true);
              setmodal_center(false);
              setIsButtonDisabled(false);
            }}
          />
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => setmodal_center(false)}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default FileChooseAlert;
