
export const GET_APPREVIEW_REQUEST = "GET_APPREVIEW_REQUEST";
export const GET_APPREVIEW_REQUEST_BEGIN = "GET_APPREVIEW_REQUEST_BEGIN";
export const GET_APPREVIEW_REQUEST_SUCCESS= "GET_APPREVIEW_REQUEST_SUCCESS";
export const GET_APPREVIEW_REQUEST_FAIL = "GET_APPREVIEW_REQUEST_FAIL";
export const GET_APP_REVIEWS_DATA = "GET_APP_REVIEWS_DATA";
export const UPDATE_APP_REVIEW = "UPDATE_APP_REVIEW";
export const UPDATE_APP_REVIEW_BEGIN ="UPDATE_APP_REVIEW_BEGIN";
export const UPDATE_APP_REVIEW_SUCCESS = "UPDATE_APP_REVIEW_SUCCESS";
export const UPDATE_APP_REVIEW_FAIL = "UPDATE_APP_REVIEW_FAIL";
export const GET_APPREVIEW_UPDATE_BEGIN="GET_APPREVIEW_UPDATE_BEGIN";