import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Col,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  Row,
  UncontrolledAlert,
  CardSubtitle,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { checkUserPermission } from "../../../helpers/utils";

const PPSettings = ({
  level1,
  level2,
  level3,
  level4,
  level5,
  level6,
  level7,
  level1Change,
  level2Change,
  level3Change,
  level4Change,
  level5Change,
  level6Change,
  level7Change,
  pp_save,
}) => {
  const { t } = useTranslation();
  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Settings",
          "App settings"
        )
      );
    }
  }, [loginData]);
  return (
    <Card className="appSettingCard">
      <CardTitle className="cardTitle">
        {t("AppSettings.PerformancePoints")}
      </CardTitle>
      <CardBody>
        <AvForm
          onValidSubmit={(e, v) => {
            //handleUpdate(e, v);
          }}
        >
          <table>
            <thead>
              <tr>
                <th className="mx-1">{t("AppSettings.tableHead1")}</th>
                <th className="px-4 ">{t("AppSettings.tableHead2")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {t("AppSettings.Level1")} <span className="mandatory">*</span>
                </td>
                <td>
                  <AvField
                    className="performancePointsText"
                    name="level 1"
                    type="number"
                    onChange={level1Change}
                    value={level1}
                    disabled={!isEditable}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t("AppSettings.Level2")} <span className="mandatory">*</span>
                </td>
                <td>
                  <AvField
                    className="performancePointsText"
                    name="level 2"
                    type="number"
                    onChange={level2Change}
                    value={level2}
                    disabled={!isEditable}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t("AppSettings.Level3")} <span className="mandatory">*</span>
                </td>
                <td>
                  <AvField
                    className="performancePointsText"
                    name="level 3"
                    type="number"
                    onChange={level3Change}
                    value={level3}
                    disabled={!isEditable}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t("AppSettings.Level4")} <span className="mandatory">*</span>
                </td>
                <td>
                  <AvField
                    className="performancePointsText"
                    name="level 4"
                    type="number"
                    onChange={level4Change}
                    value={level4}
                    disabled={!isEditable}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t("AppSettings.Level5")}
                  <span className="mandatory">*</span>
                </td>
                <td>
                  <AvField
                    className="performancePointsText"
                    name="level 5"
                    type="number"
                    onChange={level5Change}
                    value={level5}
                    disabled={!isEditable}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t("AppSettings.Level6")} <span className="mandatory">*</span>
                </td>
                <td>
                  <AvField
                    className="performancePointsText"
                    name="level 6"
                    type="number"
                    onChange={level6Change}
                    value={level6}
                    disabled={!isEditable}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t("AppSettings.Level7")} <span className="mandatory">*</span>
                </td>
                <td>
                  <AvField
                    className="performancePointsText"
                    name="level 7"
                    type="number"
                    onChange={level7Change}
                    value={level7}
                    disabled={!isEditable}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </AvForm>
      </CardBody>
      <CardFooter className="cardFooter">
        <button
          onClick={pp_save}
          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
          disabled={
            !level1 ||
            !level2 ||
            !level3 ||
            !level4 ||
            !level5 ||
            !level6 ||
            !level7 ||
            !isEditable
          }
        >
          {t("settings.Save")}
        </button>
      </CardFooter>
    </Card>
  );
};

export default PPSettings;
