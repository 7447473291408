import {
  LOGIN_AUTH,
  AUTH_SUCCESS,
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  GET_USER_PERMISSIONS,
  GET_USER_PERMISSIONS_BEGIN,
  GET_USER_PERMISSIONS_SUCCESS,
  GET_USER_PERMISSIONS_FAIL,
  RESET_USER_PERMISSIONS
} from "./actionTypes"


export const loginAuthentication = (otp, token, history, callback) => {
  return {
    type: LOGIN_AUTH,
    payload: { otp, token, history },
    callback: callback,
  }
}

export const AuthenticationSuccess = user => {
  return {
    type: AUTH_SUCCESS,
    payload: user,
  }
}

export const loginUser = (user, callback) => {
  return {
    type: LOGIN_USER,
    payload: { user },
    callback: callback
  }
}
export const loginSuccess = otpToken => {
  return {
    type: LOGIN_SUCCESS,
    payload: otpToken,
  }
}

/* ------------------------------------------- */
export const getRolePermission = (id, callback) => ({
  type: GET_USER_PERMISSIONS,
  payload: id,
  callback: callback
})

export const getRolePermissionBegin = () => ({
  type: GET_USER_PERMISSIONS_BEGIN
})

export const getRolePermissionSuccess = (rolePermissions) => ({
  type: GET_USER_PERMISSIONS_SUCCESS,
  payload: rolePermissions
})

export const getRolePermissionFail = (error) => ({
  type: GET_USER_PERMISSIONS_FAIL,
  payload: error
})

/* ------------------------------------------- */

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const resetUserPermissions = () => ({
  type: RESET_USER_PERMISSIONS,
});

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}
