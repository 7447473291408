import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Modal, ModalBody, Row } from "reactstrap";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import Datatable from "../../../../components/UI/Tables/Datatable";
import {
  coinsPurchaseColumnData,
  flaxToCoinsConversionColummnData,
} from "../../../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../../helpers/constants";
import { convertTime, convertToUAETime } from "../../../../helpers/utils";

import Loader from "../../../../components/Common/Loader";
import { getFlaxCoinsConversionHistory } from "../../../../store/coinsFlaxConversion/actions";
import { getCoinsPurchaseHistoryByUserId } from "../../../../store/CoinsPurchaseHistory/actions";

const CoinsPurchaseHistory = ({ toggle, modal, userId }) => {
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "purchased_on",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 10,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  useEffect(() => {
    dispatch(getCoinsPurchaseHistoryByUserId({ request, userId }));
  }, [request]);

  const data = useSelector((state) => state.coinsPurchase);

  const formatCoinPurchaseData = useCallback(() => {
    return (
      data?.coinsPurchaseDataByUser?.coin_purchase &&
      data?.coinsPurchaseDataByUser?.coin_purchase?.length &&
      data?.coinsPurchaseDataByUser?.coin_purchase?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,
        username: (
          <div data-tag="allowRowEvents" title={result?.name}>
            {result?.name}
          </div>
        ),
        coins: (
          <div data-tag="allowRowEvents" title={result?.coins}>
            {result?.coins}
          </div>
        ),
        device_type: (
          <div data-tag="allowRowEvents" title={result?.device_type}>
            {result?.device_type}
          </div>
        ),
        purchased_on: (
          <div data-tag="allowRowEvents" title={result?.time_created}>
            {convertTime(result?.time_created)}
          </div>
        ),
      }))
    );
  });
  const coinsPurchaseHistory = useMemo(
    () => formatCoinPurchaseData(),
    [formatCoinPurchaseData]
  );

  return (
    <div>
      <Modal
        centered
        isOpen={modal}
        toggle={toggle}
        style={{ minWidth: "1000px" }}
      >
        {/* <ModalHeader toggle={toggle}>Flax summary</ModalHeader> */}
        <ModalBody>
          <>
            <Container
              fluid
              id="userManagementWrapper"
              data-testid="userMgmtWrap"
            >
              <Row>
                <Breadcrumbs
                  title="Tables"
                  breadcrumbItem="COiNS Purchase History"
                />

                <Datatable
                  // defaultSortField={request?.sort || "transfered_date"}
                  // defaultSortAsc={
                  //   request?.sort_order === "asc" ? true : false
                  // }
                  // dateFilter
                  // dateType="Transfered Date Range"
                  tableID={"flaxTable"}
                  columns={coinsPurchaseColumnData}
                  rows={coinsPurchaseHistory}
                  setRequest={setRequest}
                  request={request}
                  totalRecords={data?.coinsPurchaseDataByUser?.total}
                  // search
                  // searchTerm={data?.request?.keyword}
                  // reqstatusFilter={request?.status_filter}
                  loading={data?.loading}
                  tableCardClassName={"snoTable"}
                />
              </Row>
            </Container>
            {data?.loading && <Loader darkBg={true} />}
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CoinsPurchaseHistory;
