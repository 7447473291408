import {
  ADD_FLAX_TRANSFER_PURPOSE,
  ADD_FLAX_TRANSFER_PURPOSE_BEGIN,
  ADD_FLAX_TRANSFER_PURPOSE_FAIL,
  ADD_FLAX_TRANSFER_PURPOSE_SUCCESS,
  DELETE_FLAX_TRANSFER_PURPOSE,
  DELETE_FLAX_TRANSFER_PURPOSE_BEGIN,
  DELETE_FLAX_TRANSFER_PURPOSE_FAIL,
  DELETE_FLAX_TRANSFER_PURPOSE_SUCCESS,
  GET_FLAX_TRANSFER_HISTORY,
  GET_FLAX_TRANSFER_HISTORY_BEGIN,
  GET_FLAX_TRANSFER_HISTORY_FAIL,
  GET_FLAX_TRANSFER_HISTORY_SUCCESS,
  GET_FLAX_TRANSFER_PURPOSES,
  GET_FLAX_TRANSFER_PURPOSES_BEGIN,
  GET_FLAX_TRANSFER_PURPOSES_FAIL,
  GET_FLAX_TRANSFER_PURPOSES_SUCCESS,
  EDIT_FLAX_TRANSFER_PURPOSE,
  EDIT_FLAX_TRANSFER_PURPOSE_BEGIN,
  EDIT_FLAX_TRANSFER_PURPOSE_SUCCESS,
  EDIT_FLAX_TRANSFER_PURPOSE_FAIL,
  GET_FLAX_TRANSFER_PURPOSE_BY_ID,
  GET_FLAX_TRANSFER_PURPOSE_BY_ID_BEGIN,
  GET_FLAX_TRANSFER_PURPOSE_BY_ID_SUCCESS,
  GET_FLAX_TRANSFER_PURPOSE_BY_ID_FAIL,
  RESET_PURPOSE_ERRORS,
  GET_FLAX_TRANSFER_HISTORY_BY_USER_ID,
  GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_BEGIN,
  GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_SUCCESS,
  GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_FAIL,
} from "./actionTypes";

export const getFlaxTransferHistory = (request) => ({
  type: GET_FLAX_TRANSFER_HISTORY,
  request: request,
});

export const getFlaxTransferHistoryBegin = () => ({
  type: GET_FLAX_TRANSFER_HISTORY_BEGIN,
});

export const getFlaxTransferHistorySuccess = (flaxTransfers) => ({
  type: GET_FLAX_TRANSFER_HISTORY_SUCCESS,
  payload: flaxTransfers,
});

export const getFlaxTransferHistoryFail = (error) => ({
  type: GET_FLAX_TRANSFER_HISTORY_FAIL,
  payload: error,
});
export const getFlaxTransferPurposes = (request, selectedLanguage) => ({
  type: GET_FLAX_TRANSFER_PURPOSES,
  request: request,
  language: selectedLanguage,
});

export const getFlaxTransferPurposesBegin = () => ({
  type: GET_FLAX_TRANSFER_PURPOSES_BEGIN,
});

export const getFlaxTransferPurposesSuccess = (purposes) => ({
  type: GET_FLAX_TRANSFER_PURPOSES_SUCCESS,
  payload: purposes,
});

export const getFlaxTransferPurposesFail = (error) => ({
  type: GET_FLAX_TRANSFER_PURPOSES_FAIL,
  payload: error,
});
export const addFlaxTransferPurpose = ({ purpose, callback }) => ({
  type: ADD_FLAX_TRANSFER_PURPOSE,
  purpose: purpose,
  callback: callback,
});

export const addFlaxTransferPurposeBegin = () => ({
  type: ADD_FLAX_TRANSFER_PURPOSE_BEGIN,
});

export const addFlaxTransferPurposeSuccess = (purpose) => ({
  type: ADD_FLAX_TRANSFER_PURPOSE_SUCCESS,
  payload: purpose,
});

export const addFlaxTransferPurposeFail = (error) => ({
  type: ADD_FLAX_TRANSFER_PURPOSE_FAIL,
  payload: error,
});
export const deleteFlaxTransferPurpose = ({ id, callback }) => ({
  type: DELETE_FLAX_TRANSFER_PURPOSE,
  id: id,
  callback: callback,
});

export const deleteFlaxTransferPurposeBegin = () => ({
  type: DELETE_FLAX_TRANSFER_PURPOSE_BEGIN,
});

export const deleteFlaxTransferPurposeSuccess = (purpose) => ({
  type: DELETE_FLAX_TRANSFER_PURPOSE_SUCCESS,
  payload: purpose,
});

export const deleteFlaxTransferPurposeFail = (error) => ({
  type: DELETE_FLAX_TRANSFER_PURPOSE_FAIL,
  payload: error,
});

export const getFlaxTransferPurposeById = (id) => ({
  type: GET_FLAX_TRANSFER_PURPOSE_BY_ID,
  id: id,
});

export const getFlaxTransferPurposeByIdBegin = () => ({
  type: GET_FLAX_TRANSFER_PURPOSE_BY_ID_BEGIN,
});

export const getFlaxTransferPurposeByIdSuccess = (purpose) => ({
  type: GET_FLAX_TRANSFER_PURPOSE_BY_ID_SUCCESS,
  payload: purpose,
});

export const getFlaxTransferPurposeByIdFail = (error) => ({
  type: GET_FLAX_TRANSFER_PURPOSE_BY_ID_FAIL,
  payload: error,
});
export const editFlaxTransferPurpose = ({
  purpose_identifier,
  id,
  purpose,
  // callback,
}) => ({
  type: EDIT_FLAX_TRANSFER_PURPOSE,
  params: purpose_identifier,
  id: id,
  purpose: purpose,
  // callback: callback,
});
export const resetPurposeErrors = () => ({
  type: RESET_PURPOSE_ERRORS,
});

export const editFlaxTransferPurposeBegin = () => ({
  type: EDIT_FLAX_TRANSFER_PURPOSE_BEGIN,
});

export const editFlaxTransferPurposeSuccess = (purpose) => ({
  type: EDIT_FLAX_TRANSFER_PURPOSE_SUCCESS,
  payload: purpose,
});

export const editFlaxTransferPurposeFail = (error) => ({
  type: EDIT_FLAX_TRANSFER_PURPOSE_FAIL,
  payload: error,
});

//actions for individual user flax transfer history

export const getFlaxTransferHistoryByUserId = (request, userId) => ({
  type: GET_FLAX_TRANSFER_HISTORY_BY_USER_ID,
  request: request,
  userId: userId,
});

export const getFlaxTransferHistoryByUserIdBegin = () => ({
  type: GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_BEGIN,
});

export const getFlaxTransferHistoryByUserIdSuccess = (flaxTransfers) => ({
  type: GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_SUCCESS,
  payload: flaxTransfers,
});

export const getFlaxTransferHistoryByUserIdFail = (error) => ({
  type: GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_FAIL,
  payload: error,
});
