import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { API_URL } from "../../helpers/api_helper";
import { getLocalStorage } from "../../helpers/utils";

export const PostatVideoPreview = ({
  isOpen,
  setOpen,
  //   popupButtonHandler,
  videoUrl,
  pairId,
  policy,
  signature,
}) => {
  const [cookies, setCookies] = useState("");
  const auth = getLocalStorage("AUTH_DETAILS");
  const getCookies = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };

      // If `auth` exists, add the Authorization header with the Bearer token
      if (auth) {
        headers["Authorization"] = `Bearer ${auth.access_token}`; // Assuming `auth.token` holds the token
      }

      const cookiesResponse = await fetch(`${API_URL}/postat/signed_cookies`, {
        method: "GET",
        headers: headers,
      });

      if (!cookiesResponse.ok) {
        throw new Error("Failed to fetch cookies");
      }

      const cookiesData = await cookiesResponse.json();
      setCookies(cookiesData.result);
    } catch (error) {
      console.error("Error fetching cookies:", error);
    }
  };

  useEffect(() => {
    getCookies();
  }, [videoUrl]);
  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      backdrop={true}
      toggle={() => setOpen(false)}
    >
      <ModalHeader toggle={() => setOpen((state) => !state)}>
        Postat
      </ModalHeader>
      <ModalBody>
        {cookies && (
          <video width={470} height={670} controls autoplay>
            <source
              src={`${videoUrl}?Policy=${encodeURIComponent(
                cookies?.["CloudFront-Policy"]
              )}&Key-Pair-Id=${encodeURIComponent(
                cookies?.["CloudFront-Key-Pair-Id"]
              )}&Signature=${encodeURIComponent(
                cookies?.["CloudFront-Signature"]
              )}`}
              type="video/mp4"
            />
          </video>
        )}
      </ModalBody>

      {/* <ModalFooter>
        {selectedRow?.status === "Rejected" && (
          <Button
            color="primary"
            onClick={() => popupButtonHandler("Accepted")}
          >
            Approve
          </Button>
        )}
        {selectedRow?.status === "Accepted" && (
          <Button
            color="secondary"
            onClick={() => popupButtonHandler("Rejected")}
          >
            Reject
          </Button>
        )}
      </ModalFooter> */}
    </Modal>
  );
};
