import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  announcementColumnData,
  giftTransferColumnData,
} from "../../../helpers/columns";
import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import { getGiftTransferHistory } from "../../../store/GiftTransfers/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  deleteAnnouncements,
  getAnnouncements,
  resetAnnouncementErrors,
} from "../../../store/announcements/actions";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";

const Announcements = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const basicRequest = {
    sort: "transfered_date",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const data = useSelector((state) => state?.Announcement);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    request && dispatch(getAnnouncements(request));
    return () => {
      dispatch(resetAnnouncementErrors());
    };
  }, [request]);

  const formatAnnouncementData = useCallback(() => {
    return (
      data?.announcements &&
      data?.announcements?.length &&
      data?.announcements?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        title: (
          <div data-tag="allowRowEvents" title={result?.title}>
            {result?.title}
          </div>
        ),
        message: (
          <div data-tag="allowRowEvents" title={result?.message}>
            {result?.message}
          </div>
        ),
        status: (
          <div data-tag="allowRowEvents" title={result?.status}>
            {result?.status}
          </div>
        ),
        scheduled_time: (
          <div data-tag="allowRowEvents" title={result?.status}>
            {result?.scheduled_time === "0000-00-00 00:00:00"
              ? ""
              : result?.scheduled_time}
          </div>
        ),

        actions: (
          <div className="cust-table-actions-wrap">
            {(result?.status === "draft" || result?.status === "scheduled") && (
              <button
                className=" color-violet action-btn"
                title="Edit"
                onClick={() =>
                  history.push({
                    pathname: `/announcements-edit/${result?.id}`,
                    state: { id: result.id },
                  })
                }
                disabled={result?.status === "Processing"}
              >
                <img
                  src={Edit_icon}
                  alt="Edit"
                  style={{
                    opacity: result?.status === "Processing" ? "50%" : "",
                    cursor:
                      result?.status === "Processing" ? "not-allowed" : "",
                  }}
                />
              </button>
            )}
            <button
              onClick={() => deletePromptHandler(result.id, result?.status)}
              className=" color-red action-btn"
              title="Delete"
              disabled={result?.status === "Processing"}
            >
              <img
                src={Delete_icon}
                alt="Delete"
                style={{
                  opacity: result?.status === "Processing" ? "50%" : "",
                  cursor: result?.status === "Processing" ? "not-allowed" : "",
                }}
              />
            </button>
          </div>
        ),
      }))
    );
  }, [request, data?.announcements]);
  const announcementData = useMemo(
    () => formatAnnouncementData(),
    [formatAnnouncementData]
  );
  const okHandler = () => {
    dispatch(
      deleteAnnouncements({
        id: promptMessage.id,
        callback: () => dispatch(getAnnouncements()),
      })
    );
  };

  const deletePromptHandler = (id, status) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `${
        status === "draft" || status === "success"
          ? "Are you sure you want to delete this Announcement"
          : status === "scheduled"
          ? "Deleting will not send the announcement to corresponding users. Are you sure you want to delete this Announcement"
          : "Are you sure you want to delete this Announcement"
      } ?`,
      type: "delete",
    });
  };
  const addNewClickHandler = () => {
    history.push("/announcements-addNew");
  };
  useEffect(() => {
    setSuccessMessage(data?.success);
  }, [data?.success]);
  console.log("data?.error", data?.error);
  const tableRowClickHandler = (param) => {
    history.push({
      pathname: `/announcements-details/${param?.id}`,
      state: { id: param.id },
    });
  };

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        // id="userMgmtList"
        // ref={pageWrapRef}
      >
        {/* {successMessage && (
          <div>
            <UncontrolledAlert
              color="success"
              className="alert-dismissible fade show"
              role="alert"
            >
              {successMessage}
            </UncontrolledAlert>
          </div>
        )}{" "}
        */}
        {data?.error && (
          <div>
            <UncontrolledAlert
              color="danger"
              className="alert-dismissible fade show"
              role="alert"
            >
              {data?.error}
            </UncontrolledAlert>
          </div>
        )}{" "}
        <Container fluid>
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Announcements" />
            <div className="container-fluid">
              <Datatable
                tableID={"adminTable"}
                columns={announcementColumnData}
                rows={announcementData}
                setRequest={setRequest}
                request={request}
                // totalRecords={data?.giftTransferData?.total}
                // search
                add
                // dropDown
                // languages={languages}
                addNewClickHandler={addNewClickHandler}
                // selectedLanguage={selectedLanguage}
                // setSelectedLanguage={setSelectedLanguage}
                addNewLabel={t("Admins.addAdmin.addBtn")}
                // searchTerm={data?.request?.keyword}
                tableRowClicked={(param) => tableRowClickHandler(param)}
                loading={data?.loading}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
        </Container>
        <ConfirmationAlert
          {...promptMessage}
          modal_center={showPromptPopUp}
          setmodal_center={setShowPromptPopUp}
          onOK={okHandler}
        />
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default Announcements;
