import { RESET_ERRORS } from "../users/actionTypes";
import {
  ADD_GIFT_PACKAGES,
  ADD_GIFT_PACKAGES_BEGIN,
  ADD_GIFT_PACKAGES_FAIL,
  ADD_GIFT_PACKAGES_SUCCESS,
  DELETE_GIFT_PACKAGES,
  DELETE_GIFT_PACKAGES_BEGIN,
  DELETE_GIFT_PACKAGES_FAIL,
  DELETE_GIFT_PACKAGES_SUCCESS,
  GET_GIFT_PACKAGES,
  GET_GIFT_PACKAGES_BEGIN,
  GET_GIFT_PACKAGES_BY_ID,
  GET_GIFT_PACKAGES_BY_ID_BEGIN,
  GET_GIFT_PACKAGES_BY_ID_FAIL,
  GET_GIFT_PACKAGES_BY_ID_SUCCESS,
  GET_GIFT_PACKAGES_FAIL,
  GET_GIFT_PACKAGES_SUCCESS,
  RESET_GIFT_PACKAGE_ERRORS,
  UPDATE_GIFT_PACKAGES,
  UPDATE_GIFT_PACKAGES_BEGIN,
  UPDATE_GIFT_PACKAGES_FAIL,
  UPDATE_GIFT_PACKAGES_SUCCESS,
} from "./actionTypes";

export const getGiftPackages = (request) => ({
  type: GET_GIFT_PACKAGES,
  request: request,
});

export const getGiftPackagesBegin = () => ({
  type: GET_GIFT_PACKAGES_BEGIN,
});

export const getGiftPackagesSuccess = (giftPackages) => ({
  type: GET_GIFT_PACKAGES_SUCCESS,
  payload: giftPackages,
});

export const getGiftPackagesFail = (error) => ({
  type: GET_GIFT_PACKAGES_FAIL,
  payload: error,
});

export const deleteGiftPackages = ({ id, callback }) => ({
  type: DELETE_GIFT_PACKAGES,
  id: id,
  callback: callback,
});

export const deleteGiftPackagesBegin = () => ({
  type: DELETE_GIFT_PACKAGES_BEGIN,
});

export const deleteGiftPackagesSuccess = (purpose) => ({
  type: DELETE_GIFT_PACKAGES_SUCCESS,
  payload: purpose,
});

export const deleteGiftPackagesFail = (error) => ({
  type: DELETE_GIFT_PACKAGES_FAIL,
  payload: error,
});

export const addGiftPackages = ({ packageData, callback }) => ({
  type: ADD_GIFT_PACKAGES,
  packageData: packageData,
  callback: callback,
});

export const addGiftPackagesBegin = () => ({
  type: ADD_GIFT_PACKAGES_BEGIN,
});

export const addGiftPackagesSuccess = (packageData) => ({
  type: ADD_GIFT_PACKAGES_SUCCESS,
  payload: packageData,
});

export const addGiftPackagesFail = (error) => ({
  type: ADD_GIFT_PACKAGES_FAIL,
  payload: error,
});

export const getGiftPackagesById = (id) => ({
  type: GET_GIFT_PACKAGES_BY_ID,
  id: id,
});

export const getGiftPackagesByIdBegin = () => ({
  type: GET_GIFT_PACKAGES_BY_ID_BEGIN,
});

export const getGiftPackagesByIdSuccess = (giftPackages) => ({
  type: GET_GIFT_PACKAGES_BY_ID_SUCCESS,
  payload: giftPackages,
});

export const getGiftPackagesByIdFail = (error) => ({
  type: GET_GIFT_PACKAGES_BY_ID_FAIL,
  payload: error,
});

export const updateGiftPackages = ({
  id,
  packageData,
  giftIdentifier,
  callback,
}) => ({
  id: id,
  giftIdentifier: giftIdentifier,
  packageData: packageData,
  callback: callback,
  type: UPDATE_GIFT_PACKAGES,
});

export const updateGiftPackagesBegin = () => ({
  type: UPDATE_GIFT_PACKAGES_BEGIN,
});

export const updateGiftPackagesSuccess = (packageData) => ({
  type: UPDATE_GIFT_PACKAGES_SUCCESS,
  payload: packageData,
});

export const updateGiftPackagesFail = (error) => ({
  type: UPDATE_GIFT_PACKAGES_FAIL,
  payload: error,
});

export const resetGiftPackageErrors = () => ({
  type: RESET_GIFT_PACKAGE_ERRORS,
});
