import { AvForm } from "availity-reactstrap-validation";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import { hi } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Card, Col, Container, Row, UncontrolledAlert } from "reactstrap";
import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";

import Lottie from "lottie-react";
import {
  getGiftPackagesById,
  updateGiftPackages,
  resetGiftPackageErrors,
  getGiftPackages,
} from "../../../store/GiftPackages/actions";
import Loader from "../../../components/Common/Loader";
import { isGif, isLottieAnimation } from "../../../helpers/utils";
// import DatePicker from "react-date-picker";
// import "react-date-picker/dist/DatePicker.css";
// import "react-calendar/dist/Calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getGiftCategories } from "../../../store/GiftCategories/actions";

const EditGiftPackages = () => {
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [giftIdentifier, setGiftIdentifier] = useState("");
  const [giftName, setGiftName] = useState("");
  const [points, setPoints] = useState(0);
  const [flix, setFlix] = useState(0);
  const [animationFile, setAnimationFile] = useState(null);
  const [animationData, setAnimationData] = useState(null);
  const [message, setMessage] = useState(null);
  const [giftList, setGiftList] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [lottieOrGifUrl, setLottieOrGifUrl] = useState("");
  const [videoAnimationFile, setVideoAnimationFile] = useState(null);
  const [giftStaticUrl, setGiftStaticUrl] = useState("");
  const [videoAnimationUrl, setVideoAnimationUrl] = useState("");
  const [androidVideoAnimationUrl, setAndroidVideoAnimationUrl] = useState("");
  const [formChanged, setFormChanged] = useState(false);
  const [giftType, setGiftType] = useState("");
  const [giftDescription, setGiftDescripton] = useState("");
  // const [giftCategory, setGiftCategory] = useState("");
  const [categoryIdentifier, setCategoryIdentifier] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [date, setDate] = useState(null);
  const [androidVideoAnimationFile, setAndroidVideoAnimationFile] =
    useState(null);

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);
  const data = useSelector((state) => state.giftPackages);
  const categoryData = useSelector((state) => state.GiftCategories);

  useEffect(() => {
    dispatch(getGiftPackagesById(location?.state?.id));
    dispatch(getGiftCategories());
    return () => {
      dispatch(resetGiftPackageErrors());
    };
  }, [location?.state?.id]);
  function extractFileName(url) {
    if (url) return url?.substring(url?.lastIndexOf("/") + 1);
  }

  useEffect(() => {
    if (giftList && giftList?.length !== 0) {
      console.log("ggg");
      for (let i = 0; i < giftList?.length; i++) {
        if (
          (selectedCategory !== "FLiX" && points <= 0) ||
          (selectedCategory === "FLiX" && flix <= 0) ||
          message !== null ||
          giftType === "" ||
          giftList[i]?.gift_name === ""
          // ||
          // ((giftType === "vip" || giftType === "personal") &&
          //   giftList[i]?.gift_description === "")
        ) {
          setDisabled(true);
          break;
        } else {
          setDisabled(false);
        }
      }
    } else {
      console.log(
        "jjjjjjgg",
        points,
        message,
        giftType,
        categoryIdentifier,
        date
      );
      if (
        (selectedCategory !== "FLiX" && points <= 0) ||
        (selectedCategory === "FLiX" && flix <= 0) ||
        message !== null ||
        giftType === "" ||
        (categoryIdentifier !== "common" && date === null)
      ) {
        console.log(
          "jjjjjj",
          points,
          message,
          giftType,
          categoryIdentifier,
          date
        );
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [
    points,
    // giftIdentifier,
    // selectedCategory,
    flix,
    message,
    giftName,
    giftList,
    categoryId,
    date,
    categoryIdentifier,
  ]);
  useEffect(() => {
    if (giftType !== "fun") {
      loadAnimationData();
    } else {
      setMessage(null);
    }
  }, [animationFile, giftType]);
  useEffect(() => {
    if (giftType === "fun" && animationFile) {
      if (!isGif(animationFile)) {
        setMessage("please upload a Gif File");
      } else {
        setMessage(null);
      }
    }
  }, [animationFile, giftType]);

  const loadAnimationData = async () => {
    if (!animationFile) {
      // console.error("No animation file provided.");
      return;
    }

    if (!(animationFile instanceof Blob)) {
      // console.error("Invalid animation file type.");
      setMessage("Please Upload an Animation Json File");
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      try {
        if (isLottieAnimation(JSON.parse(e.target.result))) {
          setAnimationData(JSON.parse(e.target.result));

          setMessage(null);
        } else {
          setMessage("Please Upload an Animation Json File");
        }
      } catch (error) {
        setAnimationData(null);
        setMessage("Please Upload an Animation Json File ");
        console.error("Error parsing animation data:", error);
      }
    };

    fileReader.readAsText(animationFile);
  };
  const handleFileChange = (e) => {
    setFormChanged(true);
    setMessage(null);

    const selectedFile = e.target.files[0];
    setAnimationFile(selectedFile);
  };
  const handleImageChange = (e) => {
    setFormChanged(true);
    const selectedFile = e.target.files[0];
    setImageFile(selectedFile);
  };
  const handleAnimationVideoChange = (e) => {
    setFormChanged(true);
    const selectedFile = e.target.files[0];
    setVideoAnimationFile(selectedFile);
  };
  const handleAndroidAnimationVideoChange = (e) => {
    setFormChanged(true);
    const selectedFile = e.target.files[0];
    setAndroidVideoAnimationFile(selectedFile);
  };

  useEffect(() => {
    if (
      data?.giftPackageData?.gift_list &&
      data?.giftPackageData?.gift_list.length > 0
    ) {
      setGiftIdentifier(data?.giftPackageData?.gift_list[0]?.gift_identifier);
      setGiftName(data?.giftPackageData?.gift_list[0].gift_name);
      setPoints(data?.giftPackageData?.gift_list[0]?.coins);
      setFlix(data?.giftPackageData?.gift_list[0]?.flix);
      setAnimationData(data?.giftPackageData?.gift_list[0]?.gift_json);
      setGiftStaticUrl(data?.giftPackageData?.gift_list[0]?.gift_static_url);
      setVideoAnimationUrl(
        data?.giftPackageData?.gift_list[0]?.gift_animation_url
      );
      setAndroidVideoAnimationUrl(
        data?.giftPackageData?.gift_list[0]?.gift_animation_url_android
      );
      setGiftDescripton(data?.giftPackageData?.gift_list[0]?.gift_description);
      setGiftType(data?.giftPackageData?.gift_list[0]?.gift_type);
      setLottieOrGifUrl(data?.giftPackageData?.gift_list[0]?.gift_url);
      // setDate(
      //   new Date(data?.giftPackageData?.gift_list[0]?.special_occasion_date)
      // );
      handleDateFetch(
        data?.giftPackageData?.gift_list[0]?.special_occasion_date || null
      );
      if (categoryData?.categories?.length > 0) findAndSetGiftCategory();

      // setGiftCategory(data?.giftPackageData?.gift_list[0]?.gift_category_id && data?.giftPackageData?.gift_list[0]?.gift_category)
    }
  }, [data?.giftPackageData?.gift_list, categoryData?.categories]);
  console.log("dateeee", date);

  const handleDateFetch = (fetchedDate) => {
    if (fetchedDate) {
      const adjustedDate = new Date(fetchedDate);
      adjustedDate.setDate(adjustedDate.getDate());
      setDate(adjustedDate.toISOString().split("T")[0]);
    } else {
      setDate(null);
    }
  };

  useEffect(() => {
    categoryIdentifier !== "date" && setDate(null);
  }, [categoryIdentifier]);

  // useEffect(() => {
  //   if (categoryData?.categories.length > 0 && giftType === "personal") {
  //     const initialCategory = categoryData.categories[0];
  //     // setGiftCategory(initialCategory.english);
  //     setCategoryIdentifier(initialCategory?.category_identifier);
  //   } else {
  //     setSelectedCategory("");
  //     setCategoryId("");
  //     // setGiftCategory("");
  //     setCategoryIdentifier("");
  //   }
  // }, [categoryData,giftType]);

  //setting gift category in dropdown while fetching

  const findAndSetGiftCategory = () => {
    const giftCategoryId =
      data?.giftPackageData?.gift_list[0]?.gift_category_id;
    console?.log("ssss", categoryData);

    if (giftCategoryId !== undefined && categoryData) {
      const matchingCategory = categoryData?.categories?.find(
        (category) => category.id === giftCategoryId
      );

      if (matchingCategory) {
        setCategoryId(matchingCategory.id);
        setSelectedCategory(matchingCategory.english);
        setCategoryIdentifier(matchingCategory?.category_identifier);
      }
    }
  };

  const handleChange = (event) => {
    console.log("333");
    setFormChanged(true);
    const selectedValue = event.target.value;
    console.log("value", selectedValue);
    const selectedGiftCategory = categoryData.categories.find(
      (category) => category.english === selectedValue
    );

    if (selectedGiftCategory) {
      console.log("hhhhhhhh");
      setSelectedCategory(selectedValue);
      console.log("1", selectedCategory);
      setCategoryId(selectedGiftCategory.id);
      // setGiftCategory(selectedValue);
      setCategoryIdentifier(selectedGiftCategory?.category_identifier);
    }
  };
  console.log("category Identifier", categoryIdentifier);
  console.log("category selected", selectedCategory, categoryId);

  const handleReportUsersChangeOrAdd = (index, key, value, language) => {
    setFormChanged(true);
    const updatedList = [...giftList];
    const existingIndex = updatedList.findIndex(
      (item) => item.language === language
    );

    if (existingIndex !== -1) {
      updatedList[existingIndex] = {
        ...updatedList[existingIndex],
        gift_name: value && value.trim(),
        id: index,
      };
    } else {
      updatedList.push({
        id: index,
        language: language,
        gift_name: value && value.trim(),
      });
    }

    setGiftList(updatedList);
  };
  const handleDescriptionChangeOrAdd = (index, key, value, language) => {
    setFormChanged(true);
    const updatedList = [...giftList];
    const existingIndex = updatedList.findIndex(
      (item) => item.language === language
    );

    if (existingIndex !== -1) {
      updatedList[existingIndex] = {
        ...updatedList[existingIndex],
        gift_description: value && value.trim(),
        id: index,
      };
    } else {
      updatedList.push({
        id: index,
        language: language,
        gift_description: value && value.trim(),
      });
    }

    setGiftList(updatedList);
  };

  const handleDateChange = (selectedDate) => {
    if (
      selectedDate &&
      giftType === "personal" &&
      categoryIdentifier === "date"
    ) {
      const adjustedDate = new Date(selectedDate);
      console.log("selected date", adjustedDate);

      const localDate = adjustedDate.toLocaleDateString("en-CA"); // ISO-like format (YYYY-MM-DD)
      setDate(localDate);
    } else {
      setDate(null);
    }
  };

  const handleSubmit = (e) => {
    const formData = new FormData();
    animationFile && formData?.append("lottie_file", animationFile);
    imageFile && formData?.append("gift_image", imageFile);
    videoAnimationFile && formData?.append("animation", videoAnimationFile);
    androidVideoAnimationFile &&
      formData?.append("animation_android", androidVideoAnimationFile);
    if (selectedCategory === "FLiX") {
      formData?.append(
        "data",
        JSON.stringify({
          flix: flix,
          gift_identifier: `${(giftName + "-" + giftType + "-" + points)
            ?.toLowerCase()
            ?.replace(/\s/g, "-")}`,
          gift_json: null,
          gift_type: giftType && giftType?.trim(),
          gift_category_id: categoryId,
          special_occasion_date: date?.split("T")[0] || null,
          gift_data: giftList,
        })
      );
    } else {
      formData?.append(
        "data",
        JSON.stringify({
          points: points,
          gift_identifier: `${(giftName + "-" + giftType + "-" + points)
            ?.toLowerCase()
            ?.replace(/\s/g, "-")}`,
          gift_json: null,
          gift_type: giftType && giftType?.trim(),
          gift_category_id: categoryId,
          special_occasion_date: date?.split("T")[0] || null,
          gift_data: giftList,
        })
      );
    }

    dispatch(
      updateGiftPackages({
        giftIdentifier: giftIdentifier,
        id: location.state?.id,

        packageData: formData,
        callback: () => {
          dispatch(getGiftPackagesById(location?.state?.id));
        },
      })
    );
    setFormChanged(false);
  };
  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error, data?.success]);

  const scrollToAlertMessage = () => {
    if (data?.error || data?.success) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      {/* <Prompt
      message={(location, action) => {
        if (action === "POP") {
          return confirmBrowserBack();
        }
      }}
      when={formChanged}
    /> */}
      <BackButton
        label="Gift Packages"
        handleClick={() => history.push("/gift-packages")}
      />
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Edit Package" />
          {(data?.error || data?.success) && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color={data?.error ? "danger" : "success"}
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error || data?.success}
              </UncontrolledAlert>
            </div>
          )}

          <Col xl="8">
            <Card className="mb-0 p-4 my-4">
              <div className="text-muted">
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleSubmit(e, v);
                  }}
                >
                  <div
                    className="table-responsive mt-4"
                    style={{ overflowX: "hidden" }}
                  >
                    <div className="d-flex mb-4">
                      <div className="col-md-6">
                        <label className="col-md-8 col-form-label">
                          Gift Name
                        </label>
                        <div className="col-md-8 dobWrapper">
                          <AvInput
                            name="adminName"
                            type="text"
                            className="form-control mb-4 "
                            value={giftName}
                            id="name-input"
                            readOnly
                            onChange={(e) => {
                              setGiftName(e.target.value);
                            }}
                            placeholder="Gift Name"
                          />
                        </div>
                      </div>
                      {selectedCategory === "FLiX" ? (
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter FLiX
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName4"
                              type="number"
                              className="form-control mb-4 "
                              value={flix}
                              id="name-input"
                              onChange={(e) => {
                                setFlix(e.target.value);
                                setFormChanged(true);
                              }}
                              placeholder="Enter FLiX"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Coins
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName4"
                              type="number"
                              className="form-control mb-4 "
                              value={points}
                              id="name-input"
                              onChange={(e) => {
                                setPoints(e.target.value);
                                setFormChanged(true);
                              }}
                              placeholder="Enter Coins"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="d-flex mb-4">
                      <div className="col-md-6">
                        <label className="col-md-8 col-form-label">
                          Gift Type
                        </label>
                        <div className="col-md-8 dobWrapper">
                          <AvInput
                            name="adminName"
                            type="text"
                            className="form-control mb-4 text-uppercase "
                            value={giftType}
                            id="name-input"
                            readOnly
                            onChange={(e) => {
                              setGiftType(e.target.value);
                            }}
                            // placeholder="Gift Name"
                          />
                        </div>
                      </div>
                      {(giftType === "vip" || giftType === "personal") &&
                        giftDescription !== "" && (
                          <div className="col-md-6">
                            <label className="col-md-8 col-form-label">
                              Gift Description
                            </label>
                            <div className="col-md-9 dobWrapper">
                              <textarea
                                name="adminName"
                                type="text"
                                readOnly
                                className="form-control mb-4 "
                                value={giftDescription}
                                id="name-input"
                                onChange={(e) => {
                                  setFormChanged(true);
                                  setGiftDescripton(e.target.value);
                                }}
                                // placeholder="Enter Gift Description"
                              />
                            </div>
                          </div>
                        )}
                    </div>
                    {(giftType === "personal" || giftType === "bank") && (
                      <div className="d-flex mb-4">
                        {categoryData?.categories?.length > 0 && (
                          <div className="col-md-6">
                            <label className="col-md-8 col-form-label">
                              Gift Categories
                              {/* <span className="mandatory">*</span> */}
                            </label>
                            <div className="col-md-8 dobWrapper">
                              <select
                                className="form-control mb-4  text-uppercase "
                                id="huddleType"
                                value={selectedCategory}
                                onChange={handleChange}
                              >
                                {categoryData?.categories
                                  .filter((category) => {
                                    // Only include "COINS" and "FLIX" if giftType is "bank"
                                    if (giftType === "bank") {
                                      return (
                                        category?.english === "COiNS" ||
                                        category?.english === "FLiX"
                                      );
                                    }
                                    // Exclude "COINS" and "FLIX" for other giftType values
                                    return (
                                      category?.english !== "COiNS" &&
                                      category?.english !== "FLiX"
                                    );
                                  })
                                  .map((category) => (
                                    <option
                                      key={category?.id}
                                      className=""
                                      value={category?.english}
                                    >
                                      {category?.english}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        )}

                        {categoryIdentifier === "date" && (
                          <div className="col-md-6">
                            <label className="col-md-8 col-form-label">
                              Select Day
                              <span className="mandatory">*</span>
                            </label>
                            <div>
                              <DatePicker
                                onChange={handleDateChange}
                                selected={date ? new Date(date) : null}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="d-flex mb-4">
                      {giftType !== "fun" ? (
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Upload Gift Animation (In lottie)
                            {/* <span className="mandatory">*</span> */}
                          </label>
                          <div className="col-md-8 dobWrapper position-relative">
                            <AvInput
                              name="adminName"
                              type="file"
                              className="form-control mb-4 "
                              accept=".json"
                              // value={animationFile && animationFile}
                              id="name-input"
                              onChange={handleFileChange}
                              placeholder="Enter points"
                            />
                            <span className="text-success position-absolute top-100">
                              {" "}
                              {lottieOrGifUrl &&
                                extractFileName(lottieOrGifUrl)}
                            </span>
                            {/* {animationData && (
                            <Lottie
                              style={{
                                width: "50px",
                                height: "50px",
                              }}
                              animationData={animationData && animationData}
                            />
                          )} */}
                            {message && (
                              <p
                                style={{
                                  color: "red",
                                }}
                              >
                                {" "}
                                {message}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Upload Gift Animation (In Gif)
                            {/* <span className="mandatory">*</span> */}
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="file"
                              className="form-control mb-4 "
                              accept=".gif"
                              // value={animationFile && animationFile}
                              id="name-input"
                              onChange={handleFileChange}
                              placeholder="Enter points"
                            />
                            <span className="text-success position-absolute top-100">
                              {" "}
                              {lottieOrGifUrl &&
                                extractFileName(lottieOrGifUrl)}
                            </span>
                            {/* {animationData && (
                          <Lottie
                            style={{
                              width: "50px",
                              height: "50px",
                            }}
                            animationData={animationData && animationData}
                          />
                        )} */}
                            {message && (
                              <p
                                style={{
                                  color: "red",
                                }}
                              >
                                {" "}
                                {message}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="d-flex mb-4">
                      <div className="col-md-6">
                        <label className="col-md-8 col-form-label">
                          Upload Gift Image
                          {/* <span className="mandatory">*</span> */}
                        </label>
                        <div className="col-md-8 dobWrapper position-relative">
                          <AvInput
                            name="adminName"
                            type="file"
                            className="form-control mb-4 "
                            accept="image/*"
                            id="name-input"
                            onChange={handleImageChange}
                          />
                          <span className="text-success position-absolute top-100">
                            {" "}
                            {giftStaticUrl && extractFileName(giftStaticUrl)}
                          </span>
                        </div>
                      </div>
                    </div>
                    {(giftType === "personal" ||
                      giftType === "vip" ||
                      giftType === "bank") && (
                      <>
                        {" "}
                        <div className="d-flex mb-4">
                          <div className="col-md-6">
                            <label className="col-md-8 col-form-label">
                              Upload Gift Animation Video (For IOS)
                              {/* <span className="mandatory">*</span> */}
                            </label>
                            <div className="col-md-8 dobWrapper position-relative">
                              <AvInput
                                name="adminName"
                                type="file"
                                className="form-control mb-4 "
                                accept="video/*"
                                id="name-input"
                                onChange={handleAnimationVideoChange}
                              />
                              <span className="text-success position-absolute top-100">
                                {videoAnimationUrl &&
                                  extractFileName(videoAnimationUrl)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mb-4">
                          <div className="col-md-6">
                            <label className="col-md-8 col-form-label">
                              Upload Gift Animation Video (For Android)
                              {/* <span className="mandatory">*</span> */}
                            </label>
                            <div className="col-md-8 dobWrapper position-relative">
                              <AvInput
                                name="adminName7"
                                type="file"
                                className="form-control mb-4 "
                                accept="video/*"
                                id="name-input"
                                onChange={handleAndroidAnimationVideoChange}
                              />

                              <span className="text-success position-absolute top-100   ">
                                {androidVideoAnimationUrl &&
                                  extractFileName(androidVideoAnimationUrl)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {data?.giftPackageData?.gift_list?.length &&
                      data?.giftPackageData?.gift_list?.map((result, i) =>
                        result?.language == "english" ? (
                          <div key={i} className="col-md-12">
                            <div className="d-flex mx-1">
                              <label className="col-md-2 lang-font col-form-label">
                                {`${result?.language
                                  ?.slice(0, 1)
                                  .toUpperCase()}${result?.language?.slice(1)}`}
                              </label>
                              <div className="col-md-4">
                                <div className="col-md-8 dobWrapper">
                                  <AvInput
                                    name="adminName"
                                    type="text"
                                    className="form-control mb-4 "
                                    value={result.gift_name}
                                    id="name-input"
                                    onChange={(e) => {
                                      handleReportUsersChangeOrAdd(
                                        i,
                                        "gift_name",
                                        e.target.value,
                                        result?.language
                                      );
                                    }}
                                    placeholder="Enter Gift Name"
                                  />
                                </div>
                              </div>
                              {(giftType === "vip" ||
                                giftType === "personal") && (
                                <div className="col-md-8">
                                  <div className="col-md-8 dobWrapper">
                                    <AvInput
                                      name="adminName10"
                                      type="text"
                                      className="form-control mb-4 "
                                      value={result.gift_description}
                                      id="name-input"
                                      onChange={(e) => {
                                        handleDescriptionChangeOrAdd(
                                          i,
                                          "gift_description",
                                          e.target.value,
                                          result?.language
                                        );
                                      }}
                                      placeholder="Enter Gift Description"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div key={i} className=" d-flex mx-1">
                            <label className="col-md-2 lang-font col-form-label">
                              {`${result.language
                                ?.slice(0, 1)
                                .toUpperCase()}${result.language?.slice(1)}`}
                            </label>{" "}
                            <div className="col-md-4">
                              {/* <label className="col-md-8 col-form-label">
                              Enter Category Text
                              <span className="mandatory">*</span>
                            </label> */}
                              <div className="col-md-8 dobWrapper">
                                <AvInput
                                  name="adminName"
                                  type="text"
                                  className="form-control mb-4 "
                                  value={result.gift_name}
                                  id="name-input"
                                  onChange={(e) => {
                                    handleReportUsersChangeOrAdd(
                                      i,
                                      "gift_name",
                                      e.target.value,
                                      result.language
                                    );
                                  }}
                                  placeholder="Enter Gift Name"
                                />
                              </div>
                            </div>
                            {(giftType === "vip" ||
                              giftType === "personal") && (
                              <div className="col-md-8">
                                {/* <label className="col-md-8 col-form-label">
                              Enter Category Text
                              <span className="mandatory">*</span>
                            </label> */}
                                <div className="col-md-8 dobWrapper">
                                  <AvInput
                                    name="adminName"
                                    type="text"
                                    className="form-control mb-4 "
                                    value={result.gift_description}
                                    id="name-input"
                                    onChange={(e) => {
                                      handleDescriptionChangeOrAdd(
                                        i,
                                        "gift_description",
                                        e.target.value,
                                        result.language
                                      );
                                    }}
                                    placeholder="Enter Gift Description"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      )}

                    <div className="mt-4">
                      <button
                        type="submit"
                        // disabled={isButtonDisabled}
                        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                        // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                        disabled={disabled || !formChanged}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </AvForm>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <ConfirmationAlert
      {...promptMessage}
      modal_center={showPromptPopUp}
      setmodal_center={setShowPromptPopUp}
      onOK={okHandler}
    /> */}
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default EditGiftPackages;
