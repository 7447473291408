import {
  GET_SUBSCRIPTION_DETAILS_BEGIN,
  GET_SUBSCRIPTION_DETAILS_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  subscriptionDetails: {},
  loading: false,
  error: "",
};

const subscriptionDetailsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        subscriptionDetails: {},
        error: "",
      };

    case GET_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionDetails: action.payload,
      };

    case GET_SUBSCRIPTION_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        subscriptionDetails: {},
        error: action.payload,
      };

    default:
      return state;
  }
};

export default subscriptionDetailsReducer;
