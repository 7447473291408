import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, Row, UncontrolledAlert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";

import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";
import Camera_icon from "./../../../assets/icons/ebud-icons/Camera.svg";

import { checkUserPermission, convertDate } from "../../../helpers/utils";

import Block_icon from "../../../assets/icons/ebud-icons/Block.svg";
import UnBlock_icon from "../../../assets/icons/ebud-icons/Unblock.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import Avatar from "../../../assets/images/users/user.svg";
import ProfileAvatar from "../../../assets/images/users/Profile_avatar.png";
import Crown from "../../../assets/icons/premium.svg";
import verifedImage from "../../../assets/icons/verified.svg";

import { DATE_REGEX } from "../../../helpers/constants";

import {
  getUserDetails,
  blockUser,
  deleteUser,
  updateUserData,
  resetErrors,
} from "../../../store/users/actions";
import {
  getAdminsData,
  storeRequest,
} from "../../../store/adminManagement/admins/action";
import { addPayoutStatus } from "../../../store/payouts/action";

const AddPayoutStatusDetail = () => {
  const match = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [imageChoosePopUp, setImageChoosePopUp] = useState(false);
  const toggle = () => setShowPromptPopUp(!showPromptPopUp);
  const imageChoosetoggle = () => setImageChoosePopUp(!imageChoosePopUp);
  const [promptMessage, setPromptMessage] = useState({});

  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [payout_status, setPayoutStatus] = useState("");
  const [requesteddate, setRequesteddate] = useState("");
  const [dateofjoin, setDateofjoin] = useState("");
  const [paymentmethod, setPaymentmethod] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [forwarded, setForwarded] = useState(false);
  const [forwarded_user, setForwardedUser] = useState("");
  const [review, setReview] = useState("");
  const [date, setDate] = useState("");
  const [gender, setGender] = useState("");

  const [statusName, setStatusName] = useState("");
  const [statusActive, setStatusActive] = useState(false);
  const [dateError, setDateError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [formChanged, setFormChanged] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [backToListing, setBackToListing] = useState(false);
  const [removeImage, setRemoveImage] = useState(false);
  const [updated, setUpdated] = useState(false);

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();

  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isDeleteable = userPermissions?.delete || false;
  const isBlockable = userPermissions?.edit || false;
  const [imageFile, setImageFile] = useState("");
  const [profilePic, setProfilePic] = useState("");

  const data = useSelector((state) => state.user);
  const payout = useSelector((state) => state.Payouts);
  const admins = useSelector((state) => state.admins);

  useEffect(() => {
    dispatch(getAdminsData());
  }, [match.id, dispatch]);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Users"
        )
      );
    }
  }, [loginData]);

  useEffect(() => {
    backToListing && history.push("/payout-status");
  }, [backToListing, match.id, history]);

  const confirmBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
  };

  const redirectMethod = () => {
    // dispatch(resetErrors());
    setBackToListing(true);
  };

  const handleStatusUpdate = () => {
    if (statusName) {
      const data = {
        status_name: statusName,
        status: statusActive,
      };

      dispatch(
        addPayoutStatus({
          data,
          callback: () => {
            setFormChanged(false);
          },
        })
      );
    }
  };

  const okHandler = () => {
    promptMessage.type === "back" ? redirectMethod() : redirectMethod();
  };

  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };

  const chkForwarded = (value) => {
    setStatusActive(value);
    setFormChanged(true);
  };

  return (
    <div
      className="page-content userDetailWrap"
      data-testid="component-editUserDetail"
      ref={pageWrapRef}
    >
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            return confirmBrowserBack();
          }
        }}
        when={formChanged}
      />
      <BackButton
        label={
          history?.location?.state?.from === "payoutStatus"
            ? "Payout Status"
            : "Payout Status"
        }
        handleClick={() => (formChanged ? confirmBack() : redirectMethod())}
      />
      <Breadcrumbs title="Tables" breadcrumbItem="Add New Payout Status" />
      <Col xl="12">
        {(payout?.error || payout?.success) && (
          <UncontrolledAlert
            color={payout?.error ? "danger" : "success"}
            className="alert-dismissible fade show col-8"
            role="alert"
          >
            {payout?.error || payout?.success}
          </UncontrolledAlert>
        )}

        <section style={{ backgroundColor: "#f5f6f8" }}>
          <div className=" py-3">
            <div className="row">
              <div className="col-lg-8">
                <AvForm
                // onValidSubmit={(e, v) => {
                //   handleUpdate(e, v);
                // }}
                >
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Status</p>
                        </div>
                        <div className="col-sm-9">
                          <AvField
                            name="comment"
                            type="input"
                            className="form-control cursor-pointer"
                            value={statusName}
                            id="example-data-input"
                            onChange={(e) => {
                              setStatusName(e.target.value);
                              setFormChanged(true);
                            }}
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Enable/Disable</p>
                        </div>
                        <div className="col-sm-9">
                          <AvField
                            type="select"
                            name="user-status"
                            className="form-control cursor-pointer genderSelectField"
                            value={statusActive}
                            onChange={(e) => {
                              chkForwarded(e.target.value);
                            }}
                          >
                            <option value="">Select Status</option>
                            <option value="1">Enabled</option>
                            <option value="0">Disabled</option>
                          </AvField>
                        </div>
                      </div>
                      <hr />

                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0"></p>
                        </div>
                        <div className="col-sm-9">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              handleStatusUpdate();
                            }}
                          >
                            ADD
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </AvForm>
              </div>
              <div className="col-lg-8"></div>
            </div>
          </div>
        </section>
      </Col>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />

      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default AddPayoutStatusDetail;
