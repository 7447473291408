import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";

const Filter_userMgmt_component = ({
  setRequest,
  request,
}) => {
  const [accepted, setAccepted] = useState(false);
  const [pending, setPending] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [forcefullyChanged, setForcefullyChanged] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const { t } = useTranslation();

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const filter_clearAll = () => {
    setAccepted(false);
    setPending(false);
    setRejected(false);
    setForcefullyChanged(false);
    setDeleted(false);
  };

  const handleApply = () => {
    setRequest({
      ...request,
      status: [accepted, pending, rejected, forcefullyChanged, deleted].filter(
        (item) => item
      ),
    });
    setmodal_center(false);
  };
  return (
    <div className="user-filterBtn-wrap" data-testid="component-filterPopup">
      <button
        type="button"
        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
        onClick={() => {
          tog_center();
        }}
        data-testid="component-filterButton"
      >
        <i
          style={{ paddingRight: "10px" }}
          className="fa fa-filter"
          aria-hidden="true"
        ></i>
        {t("filter.title")}
      </button>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={true}
        data-testid="component-filterModal"
      >
        <div className="modal-header userList-filter-header">
          <h5 className="modal-title mt-0">FILTER</h5>
          <button
            onClick={() => filter_clearAll()}
            className="btn waves-effect waves-light cust_no_shadow userList-filter-clearAll"
            data-testid="component-filterClearall"
          >
            {t("filter.clear")}
          </button>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
            // data-dismiss="modal"
            // aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body userList-filter-body">
          <div className="userList-filter-SectionWrap">
            <h5 className="userList-filter-SectionTitle">
              {t("filter.status")}
            </h5>
            <div className="userList-filter-checkBoxWrap alloc_UN_filterCheckboxWrap">
              <div>
                <div>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={accepted}
                    id="allocate_accepted"
                    onChange={() =>
                      accepted ? setAccepted(!accepted) : setAccepted("Accepted")
                    }
                    checked={accepted}
                    data-testid="component-filter-activeCheck"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="allocate_accepted"
                  >
                    {t("filter.accepted")}
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={pending}
                    id="allocate_pending"
                    onChange={() =>
                      pending ? setPending(!pending) : setPending("Pending")
                    }
                    checked={pending}
                    data-testid="component-filter-InactiveCheck"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="allocate_pending"
                  >
                    {t("filter.pending")}
                  </label>
                </div>
              </div>

              <div>
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={rejected}
                      id="allocate_rejected"
                      onChange={() =>
                        rejected ? setRejected(!rejected) : setRejected("Blocked")
                      }
                      checked={rejected}
                      data-testid="component-filter-blockedCheck"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allocate_rejected"
                    >
                      {t("filter.rejected")}
                    </label>
                  </div>
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={forcefullyChanged}
                      id="allocate_forcefully"
                      onChange={() =>
                        forcefullyChanged
                          ? setForcefullyChanged(!forcefullyChanged)
                          : setForcefullyChanged("Forcefully changed")
                      }
                      checked={forcefullyChanged}
                      data-testid="component-filter-InactiveCheck"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allocate_forcefully"
                    >
                      {t("filter.forcefullyChanged")}
                    </label>
                  </div>
              </div>

              <div>
                <div>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={deleted}
                    id="allocate_deleted"
                    onChange={() =>
                      deleted ? setDeleted(!deleted) : setDeleted("Deleted")
                    }
                    checked={deleted}
                    data-testid="component-filter-InactiveCheck"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="allocate_deleted"
                  >
                    {t("filter.deleted")}
                  </label>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              tog_center();
            }}
            data-testid="component-filter-cancel"
          >
            CANCEL
          </button>
          <button
            type="button"
            className="btn bgcolor-btn-green"
            onClick={() => handleApply()}
            data-testid="component-filter-apply"
          >
            APPLY
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Filter_userMgmt_component;
