import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import Checkbox from "../../components/UI/Checkbox";
import { DATE_WITH_TIME } from "../../helpers/constants";
import { convertToUAETime } from "../../helpers/utils";
import Block_icon from "../../assets/icons/ebud-icons/Block.svg";
import { useDispatch } from "react-redux";
import { sendNotification } from "../../store/users/actions";

const ReportSummary = ({
  data,
  specificUser,
  modal,
  active,
  setActive,
  userId,
  setUserId,
  setNotification,
  notification,
  notificationKey,
  setNotificationKey,
  NOTIFICATION_MODEL,
  toggle,
  disabled,
  warningForm,
  notificationForm,
  setShowNotificationForm,
  blockPromptHandler,
  showNotificationForm,
}) => {
  const dispatch = useDispatch();

  const handleButtonClick = (id) => {
    setShowNotificationForm((prev) => (prev === id ? null : id));
    setActive(id);
  };
  const onNotificationChange = (event) => {
    const { name, value } = event.target;
    setNotification({ ...notification, [name]: value });
  };
  const handlePushNotificationSubmit = (e) => {
    e.preventDefault();
    setShowNotificationForm(null);
    toggle();
    setNotificationKey(Date.now().toString());
    const data = {
      user_id: userId,
      type: notification.push ? "push" : "app",
      ...notification,
    };

    dispatch(
      sendNotification(data, () => {
        toggle();
        setNotification(NOTIFICATION_MODEL);
      })
    );
  };

  return (
    <Modal
      style={{
        maxWidth: "700px",
      }}
      centered
      isOpen={modal}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Report Summary</ModalHeader>
      <ModalBody>
        <div className="row   p-2">
          <div className=" col-md-4">
            <b>Reported user :</b>
          </div>

          <div className=" col-md-8">{specificUser?.reported_username}</div>
        </div>
        <div className="row   p-2">
          <div className=" col-md-4">
            <b>Reported by :</b>
          </div>

          <div className=" col-md-8">{specificUser?.reported_by_username}</div>
        </div>
        <div className="row  p-2">
          <div className=" col-md-4">
            <b>Reported date :</b>
          </div>

          <div className=" col-md-8">
            {specificUser?.reported_date &&
              convertToUAETime(specificUser?.reported_date, DATE_WITH_TIME)}
          </div>
        </div>
        <div className="row  p-2">
          <div className=" col-md-4">
            <b>Reported category :</b>
          </div>

          <div className=" col-md-8">{specificUser?.category}</div>
        </div>

        <div className="row  p-2">
          <div className=" col-md-4">
            <b>Reported reason</b>
          </div>

          <div className=" col-md-8">{specificUser?.reason}</div>
        </div>
        <div className="row  p-2">
          <div className=" col-md-4">
            <b>Number of Reports</b>
          </div>

          <div className=" col-md-8">{specificUser?.count}</div>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-start">
        {warningForm && (
          <Button
            className={` ${
              active === 1 ? "border border-2 border-success" : ""
            }`}
            style={{
              color: "#747d87",
              fontSize: "15px",
              fontWeight: "bold",
              backgroundColor: "",
            }}
            color="white"
            onClick={() => {
              setUserId(specificUser?.reported_id);
              setNotification(NOTIFICATION_MODEL);
              handleButtonClick(1);
            }}
          >
            <i class="fas fa-exclamation-triangle"></i>
            <span className="mx-1 "></span> SEND WARNING
          </Button>
        )}
        {notificationForm && (
          <Button
            className={`action-buttons  active ${
              active === 2 ? "border border-2 border-success" : ""
            }`}
            style={{
              color: "#747d87",
              fontSize: "15px",
              fontWeight: "bold",
              backgroundColor: "",
            }}
            color="white"
            onClick={() => {
              handleButtonClick(2);
              setNotification(NOTIFICATION_MODEL);
              setUserId(specificUser?.reported_by_id);
            }}
          >
            <i className="fas fa-bell"> </i>
            <span className="mx-1 "></span> SEND NOTIFICATION
          </Button>
        )}
        <Button
          className="action-buttons"
          style={{
            color: "#747d87",
            fontSize: "15px",
            fontWeight: "bold",
          }}
          color=""
          onClick={() => {
            toggle();
            blockPromptHandler();
          }}
        >
          <img width={25} height={25} src={Block_icon}></img>
          <span className="mx-1"></span>
          {data?.userDetails?.blocked ? "UNBLOCK" : "BLOCK"}
        </Button>
        {showNotificationForm !== null && (
          <div className="col-md-12 ">
            <form
              className=""
              onSubmit={(e) => {
                handlePushNotificationSubmit(e);
              }}
            >
              <div className="col-md-12 p-5">
                <div className="col-md-12">
                  <p
                    htmlFor="example-date-input"
                    className="col-md-10 col-form-label  "
                  >
                    Title
                    <span className="mandatory">*</span>
                  </p>
                  <div className="col-md-12 dobWrapper">
                    <Input
                      name="title"
                      type="text"
                      id="example-date-input"
                      value={notification.title}
                      onChange={onNotificationChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <p
                    htmlFor="example-date-input"
                    className="col-md-10 col-form-label"
                  >
                    Message
                    <span className="mandatory">*</span>
                  </p>
                  <div className="col-md-12 dobWrapper">
                    <Input
                      type="textarea"
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="8"
                      name="message"
                      value={notification.message}
                      onChange={onNotificationChange}
                    ></Input>
                  </div>
                  <div className="form-check form-switch form-switch-lg col-md-4 enableDocumentSwitchWrap px-0 pt-2 pb-4 ">
                    <div className="">
                      <label
                        className="font-weight-normal"
                        htmlFor="enableDocument"
                      >
                        Push Notification
                      </label>
                    </div>
                    <div className="px-5">
                      <Checkbox
                        checked={notification.push}
                        id={"enableDocument"}
                        name="push"
                        handleChange={(e) => {
                          onNotificationChange({
                            target: { name: "push", value: e },
                          });
                        }}
                        className="form-check-input "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer ">
                <button
                  disabled={disabled}
                  type="submit"
                  className="btn bgcolor-btn-green"
                >
                  {showNotificationForm === 1
                    ? "Send Warning"
                    : "Send Notification"}
                </button>
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowNotificationForm(null);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ReportSummary;
