import {
  ADD_GIFT_CATEGORIES_BEGIN,
  ADD_GIFT_CATEGORIES_FAIL,
  ADD_GIFT_CATEGORIES_SUCCESS,
  DELETE_GIFT_CATEGORIES_BEGIN,
  DELETE_GIFT_CATEGORIES_FAIL,
  DELETE_GIFT_CATEGORIES_SUCCESS,
  EDIT_GIFT_CATEGORIES_BEGIN,
  EDIT_GIFT_CATEGORIES_FAIL,
  EDIT_GIFT_CATEGORIES_SUCCESS,
  GET_GIFT_CATEGORIES_BEGIN,
  GET_GIFT_CATEGORIES_BY_ID_BEGIN,
  GET_GIFT_CATEGORIES_BY_ID_FAIL,
  GET_GIFT_CATEGORIES_BY_ID_SUCCESS,
  GET_GIFT_CATEGORIES_FAIL,
  GET_GIFT_CATEGORIES_SUCCESS,
  RESET_GIFT_CATEGORY_ERRORS,
} from "./actionTypes";

export const INIT_STATE = {
  categories: {},
  categoryData: {},
  error: "",
  loading: false,
  request: {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    page_count: 50,
    keyword: "",
    success: "",
  },
};

const Gifts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GIFT_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
        categories: {},
        huddleMessages: "",
      };
    case GET_GIFT_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
        error: "",
      };
    case GET_GIFT_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        categories: {},
        error: action.payload,
      };

    case GET_GIFT_CATEGORIES_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        categoryData: {},
        huddleMessages: "",
      };
    case GET_GIFT_CATEGORIES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryData: action.payload,
        error: "",
      };
    case GET_GIFT_CATEGORIES_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        categoryData: {},
        error: action.payload,
      };
    case EDIT_GIFT_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_GIFT_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case EDIT_GIFT_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_GIFT_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_GIFT_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case ADD_GIFT_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_GIFT_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_GIFT_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case DELETE_GIFT_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_GIFT_CATEGORY_ERRORS:
      return {
        ...state,
        error: "",
        success: "",
      };
    default:
      return state;
  }
};

export default Gifts;
