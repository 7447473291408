import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';

import BackButton from '../../../components/UI/BackButton';

import { editPayoutRequest, updatePayoutPaymentDetails } from "../../../store/payouts/action"
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, UncontrolledAlert } from 'reactstrap';
import {
    getGlobalPaymentSystems,
    getGlobalPaymentSystemsById,
    resetGlobalPaymentSystemErrors,
} from "../../../store/GlobalPaymentManagement/actions";
import Loader from '../../../components/Common/Loader';
import ConfirmationAlert from '../../../components/UI/ConfirmationAlert';
import { AvForm, AvInput } from 'availity-reactstrap-validation';

const EditUserPaymentDetails = () => {
    const match = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const [updateFormData, setUpdateFormData] = useState()
    const [language, setLanguage] = useState("english")
    const [paymentMethod, setPaymentMethod] = useState()
    const [dropdownOpen, setDropdownOpen] = useState();
    const [paymentMethodDropDown, setPaymentMethodDropDown] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [isCancelOpen, setCancelOpen] = useState(false)
    const [buttonDisableByNormalInput, setButtonDisableByNormalInput] = useState(true)
    const [buttonDisableByFileInput, setButtonDisableByFileInput] = useState(false)
    const [formData, setFormData] = useState(() => new FormData());
    const [selectedFiles, setSelectedFiles] = useState([])


    const warningAlertRef = useRef(null);
    const pageWrapRef = useRef(null);

    const payout = useSelector((state) => state?.Payouts);
    const data = useSelector((state) => state?.GlobalPaymentSystems);

    const onFormFieldChange = (index, value) => {
        const updatedFormData = [...updateFormData];
        updatedFormData[index].input_data = value;
        setUpdateFormData(updatedFormData);
    };


    const onFormFileChange = (index, formId, e) => {
        e.preventDefault()
        const file = e.target.files[0];
        setSelectedFiles((prev) => [...prev, formId])
        file && formData?.append(formId?.toString(), file)
    };

    useEffect(() => {
        setSelectedFiles([])
    }, [paymentMethod])


    const onUpdateClick = () => {
        const extractFormData = (updateFormData) => {
            return updateFormData
                .filter((item) => item.input_type !== "file")
                .map((item) => ({
                    payment_form_id: item.payment_form_id,
                    input_data: item.input_data?.trim() || '',
                }));
        };

        const currentPaymentMethod = data?.globalPaymentSystems?.filter((method, index) => {
            if (data?.globalPaymentSystems?.length) {
                return method.payment_method === paymentMethod
            }
        })
        const extractedFormData = extractFormData(updateFormData);

        const payload = {
            inputs: extractedFormData,
            payment_method_id: currentPaymentMethod?.[0]?.payment_method_id,
        };


        payload && formData.append("data", JSON.stringify(payload));

        dispatch(updatePayoutPaymentDetails({
            data: formData, id: match?.id, callback: () => {
                history.push(`/payout-requests/${match?.id}`);
            }
        }));
    };

    const addInputData = () => {
        const updatedFormData = data?.globalPaymentSystemData?.inputs?.map((inputField) => {
            const matchingData = payout?.payoutRequest_details?.user_payment_deatils?.find(
                (inputFieldData) => {
                    return inputField.payment_form_id === inputFieldData.payment_form_id
                }
            );

            return matchingData
                ? { ...inputField, input_data: matchingData.input_data }
                : inputField;
        });
        if (!data?.loading && !payout.loading) {
            setUpdateFormData(updatedFormData);
        }
    }

    const onCancelOk = () => {
        if (payout?.payoutRequest_details?.user_payment_deatils?.length && payout?.payoutRequest_details?.user_payment_deatils[0]?.payment_method) {
            setPaymentMethod(payout?.payoutRequest_details?.user_payment_deatils[0]?.payment_method)
        }
        if (data?.globalPaymentSystemData?.inputs?.length && paymentMethod) {
            if (payout?.payoutRequest_details?.user_payment_deatils?.length && paymentMethod === payout?.payoutRequest_details?.user_payment_deatils[0]?.payment_method) {
                addInputData()
            }
        }

    }

    useEffect(() => {
        match?.id && dispatch(editPayoutRequest(match?.id))
    }, [match.id])

    useEffect(() => {
        dispatch(getGlobalPaymentSystems());
    }, []);

    useEffect(() => {
        if (payout?.payoutRequest_details?.user_payment_deatils?.length && payout?.payoutRequest_details?.user_payment_deatils[0]?.payment_method) {
            setPaymentMethod(payout?.payoutRequest_details?.user_payment_deatils[0]?.payment_method)
        }
    }, [payout])

    useEffect(() => {
        if (data?.globalPaymentSystems.length) {
            const matchedObject = data?.globalPaymentSystems?.find((system, index) => {
                return system?.payment_method === paymentMethod
            })

            matchedObject && dispatch(
                getGlobalPaymentSystemsById({
                    country_id: 251,
                    payment_method_identifier: matchedObject?.payment_method_identifier,
                    language,
                })
            );
        }

        return () => {
            dispatch(resetGlobalPaymentSystemErrors());
        };
    }, [paymentMethod, data?.globalPaymentSystems]);

    useEffect(() => {
        if (data?.globalPaymentSystemData?.inputs?.length && paymentMethod) {
            if (payout?.payoutRequest_details?.user_payment_deatils?.length && paymentMethod === payout?.payoutRequest_details?.user_payment_deatils[0]?.payment_method) {
                addInputData()
            }
            else {
                setUpdateFormData(data?.globalPaymentSystemData?.inputs)
            }
        }
    }, [data?.globalPaymentSystemData, payout, paymentMethod])

    useEffect(() => {
        if (updateFormData?.length) {
            const isAnyMissing = updateFormData
                .filter((field) => field.input_type !== "file")
                .some((field) => field.input_data === undefined || field.input_data === "");
            setButtonDisableByNormalInput(isAnyMissing);
        }

        const payoutPaymentMethod = payout?.payoutRequest_details?.user_payment_deatils?.length && payout?.payoutRequest_details?.user_payment_deatils[0]?.payment_method
        if (updateFormData?.length && payout?.payoutRequest_details?.user_payment_deatils?.length && payoutPaymentMethod !== paymentMethod) {
            const conditionMet = updateFormData.filter((field) => field.input_type === "file").length === selectedFiles?.length
            setButtonDisableByFileInput(!conditionMet);
        }
    }, [updateFormData, paymentMethod, selectedFiles])

    const togglePopup = () => setOpen((state) => !state);
    const toggleCancelPopup = () => setCancelOpen((state) => !state);

    return (
        <div>
            <div
                className="page-content userDetailWrap"
                data-testid="component-editUserDetail"
                ref={pageWrapRef}
            >

                <BackButton
                    label="Payout Request Details"
                    handleClick={() => {
                        history.push(`/payout-requests/${match.id}`);
                    }}
                />
                <Breadcrumbs title="Tables" breadcrumbItem="Manage Payment Details" />
                <Col xl="12">
                    {(payout?.error) && (
                        <div ref={warningAlertRef}>
                            <UncontrolledAlert
                                color="danger"
                                className="alert-dismissible fade show"
                                role="alert"
                            >
                                {payout?.error}
                            </UncontrolledAlert>
                        </div>
                    )}

                    <section>
                        <h6>Payment Method</h6>
                        <Dropdown
                            isOpen={paymentMethodDropDown}
                            toggle={() => setPaymentMethodDropDown(!paymentMethodDropDown)}
                            className="w-25 mb-4"
                        >
                            <DropdownToggle
                                caret
                                className="form-control d-flex justify-content-between align-items-center bg-white text-dark p-2"
                            >
                                {paymentMethod || "Select Payment Method"}
                                <i className="fas fa-solid fa-chevron-down fs-6"></i>
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {data?.globalPaymentSystems?.length > 0 ? (
                                    data.globalPaymentSystems.map((option, optIndex) => (
                                        <DropdownItem
                                            key={optIndex}
                                            onClick={() => {
                                                setPaymentMethod(option.payment_method);
                                                setDropdownOpen(false);
                                            }}
                                            className="text-dark"
                                        >
                                            {option.payment_method}
                                        </DropdownItem>
                                    ))
                                ) : (
                                    <DropdownItem disabled className="text-muted">
                                        No Payment Methods Available
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </Dropdown>

                        <AvForm>
                            <div className='d-flex flex-row flex-wrap'>
                                {updateFormData?.map((result, index) => {
                                    if (result.input_type === "drop-down") {
                                        return (
                                            <div key={index} className="me-3 mb-3 d-flex flex-column w-25">
                                                <Label for={`dropdown-${result.payment_form_id}`}>{result.input_label}</Label>
                                                <Dropdown
                                                    isOpen={dropdownOpen === index}
                                                    toggle={() => setDropdownOpen(dropdownOpen === index ? null : index)}
                                                    className="w-100"
                                                    style={{ border: '1px solid #d3d3d3', borderRadius: '4px' }}
                                                    direction='down'
                                                >
                                                    <DropdownToggle
                                                        caret
                                                        color='black'
                                                        className="form-control d-flex justify-content-between align-items-center bg-white text-dark p-2"
                                                    >
                                                        {result.input_data || result.input_placeholder}
                                                        {/* <i className="bi bi-chevron-down ms-2 text-black"></i> */}
                                                        <i className="fas fa-solid fa-chevron-down fs-6"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="w-100">
                                                        {result.options.map((option, optIndex) => (
                                                            <DropdownItem
                                                                key={optIndex}
                                                                onClick={() => {
                                                                    const updatedFormData = [...updateFormData];
                                                                    updatedFormData[index].input_data = option.input_type_value;
                                                                    setUpdateFormData(updatedFormData);
                                                                }}
                                                                className="text-dark"
                                                            >
                                                                {option.input_type_value}
                                                            </DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                        )
                                    } else if (result?.input_type === "file") {
                                        return <div className="me-3 mb-3 d-flex flex-column w-25">
                                            <div>
                                                <Label for={result.payment_form_id}>{result.input_label}</Label>
                                            </div>
                                            <div className='d-flex flex-column h-auto gap-2'>
                                                <AvInput
                                                    className='form-control w-100'
                                                    key={result?.payment_form_id}
                                                    name={result?.payment_form_id}
                                                    accept="image/*"
                                                    type={result.input_type}
                                                    placeholder="Choose file"
                                                    // value={result?.input_data}
                                                    id={result.payment_form_id}
                                                    onChange={(e) => {
                                                        onFormFileChange(index, result?.payment_form_id, e)
                                                    }
                                                    } />
                                                {result?.input_data && <img width={50} height={50} src={result?.input_data} alt="im" className='absolute left-0 -top-2' />}
                                            </div>
                                        </div>
                                    }
                                    else {
                                        return (
                                            <div className="w-25 me-3 mb-3">
                                                <Label for={result.payment_form_id}>{result.input_label}</Label>
                                                <Input
                                                    key={result?.payment_form_id}
                                                    type='text'
                                                    value={result?.input_data}
                                                    id={result.payment_form_id}
                                                    onChange={(e) => {
                                                        onFormFieldChange(index, e.target.value)
                                                    }
                                                    }
                                                    className="w-100" />
                                            </div>
                                        )
                                    }
                                })}
                            </div>

                            <div className='d-flex gap-2'>
                                <Button onClick={togglePopup} color='primary' disabled={buttonDisableByNormalInput || buttonDisableByFileInput}>UPDATE</Button>
                                <Button onClick={toggleCancelPopup} color='warning'>CANCEL</Button>
                            </div>

                        </AvForm>
                    </section>
                </Col>

            </div>
            {(data?.loading || payout.loading) && <Loader darkBg={true} />}
            <ConfirmationAlert
                content={"Are you sure you want to update the Payment Details of the user?"}
                modal_center={isOpen}
                title={"Are you sure?"}
                setmodal_center={togglePopup}
                onOK={onUpdateClick}
            />

            <ConfirmationAlert
                content={"Are you sure you want to cancel the edits made?"}
                modal_center={isCancelOpen}
                title={"Are you sure?"}
                setmodal_center={toggleCancelPopup}
                onOK={onCancelOk}
            />
        </div>
    )
}

export default EditUserPaymentDetails
