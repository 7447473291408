import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";

import {
  getReservedNames,
  deleteReserveName,
  storeRequest,
  importReservedUsernames,
  resetMessage,
} from "../../../store/usernames/reserveUserNames/action";

import { reserveUsernameColumnData } from "../../../helpers/columns";

import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import { checkUserPermission, convertToUAETime } from "../../../helpers/utils";
import { DATE_WITH_TIME } from "../../../helpers/constants";

const ReservedUsernames = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const reservedData = useSelector((state) => state.reserveName);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [importFile, setImportFile] = useState();
  const [uploadInfo, setUploadInfo] = useState();

  const loginData = useSelector((state) => state.Login);
  const [userPermissions,setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;
  const isDeleteable = userPermissions?.delete || false;
  const isAddEnabled = userPermissions?.create || false;

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();

  const basicRequest = {
    sort: "modified_on",
    sort_order: "desc",
    page: 1,
    page_count: 50,
  };

  const [request, setRequest] = useState(
    history?.location?.state?.from === "keepFilter"
      ? { ...reservedData?.request }
      : { ...basicRequest }
  );

  useEffect(() => {
    scrollToAlertMessage();
  }, [reservedData?.error, uploadInfo]);

  useEffect(() => {
    !history?.location?.state?.showMessage && dispatch(resetMessage());
  }, [history?.location?.state?.showMessage]);
  const scrollToAlertMessage = () => {
    if (reservedData?.error || uploadInfo) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {   
    if(loginData?.rolePermissions?.role){
      setUserPermissions(checkUserPermission(loginData?.rolePermissions?.role,"Username Management","Reserve Username"))
    }
  }, [loginData]);

  useEffect(() => {
    if (reservedData?.uploadInfo?.status === "pending") {
      setUploadInfo({
        message: "Upload is in progress. You will receive an email",
        color: "info",
      });
    } else if (reservedData?.uploadInfo?.status === "success") {
      setUploadInfo({
        message: "File uploaded successfully",
        color: "success",
      });
      dispatch(getReservedNames(basicRequest));
    } else if (
      reservedData?.uploadInfo?.status === "error" &&
      reservedData?.uploadInfo?.success_rows === 0
    ) {
      setUploadInfo({
        message: `Upload failed. Please try again later. <a href=${reservedData?.uploadInfo?.url} target="_blank">Click here</a> to download the failed records`,
        color: "danger",
      });
    } else if (
      reservedData?.uploadInfo?.status === "error" &&
      reservedData?.uploadInfo?.success_rows !== 0
    ) {
      setUploadInfo({
        message: `Username Import is completed. ${
          reservedData?.uploadInfo?.success_rows
        } usernames are successfully imported and ${
          reservedData?.uploadInfo?.total_rows -
          reservedData?.uploadInfo?.success_rows
        } imports failed. <a href=${reservedData?.uploadInfo?.url} target="_blank">Click here</a> to download the failed imports`,
        color: "warning",
      });
      dispatch(getReservedNames(basicRequest));
    }
  }, [reservedData?.uploadInfo]);

  useEffect(() => {
    (reservedData?.error || reservedData?.success) && setUploadInfo();
  }, [reservedData?.error, reservedData?.success]);

  useEffect(() => {
    history?.location?.state?.from === "keepFilter"
      ? setRequest({ ...reservedData?.request })
      : setRequest({ ...basicRequest });
  }, [history?.location?.state?.from]);

  useEffect(() => {
    request && dispatch(getReservedNames(request));
    request && dispatch(storeRequest(request));
  }, [request]);

  const okHandler = () => {
    promptMessage?.type === "delete" && // delete popup YES button click
      dispatch(
        deleteReserveName({
          data: promptMessage.id,
          callback: () => dispatch(getReservedNames(request)),
        })
      );
    if (promptMessage?.type === "import") {
      // import popup YES button click
      if (
        promptMessage?.fileData[0]?.trim() === "Email" &&
        promptMessage?.fileData[1]?.trim() === "Country Code" &&
        promptMessage?.fileData[2]?.trim() === "Mobile Number" &&
        promptMessage?.fileData[3]?.trim()?.substring(0,8) === "Username" &&
        /\r|\n/.exec(promptMessage?.fileData[3])
      )
        //new line after username field
        dispatch(
          importReservedUsernames({
            data: { key: importFile?.name, file: importFile },
            callback: () => setImportFile(),
          })
        );
      else {
        setUploadInfo({
          message: "Invalid file format- Header order incorrect",
          color: "danger",
        });
      }
    }
  };

  const readFile = (file, callback) => {
    const reader = new FileReader();
    reader.onload = callback;
    reader.readAsText(file);
  };

  useEffect(() => {
    if (importFile?.size > 1e8) {
      setUploadInfo({
        message:
          "The file size is too large. The  maximum file size allowed is 100 MB",
        color: "danger",
      });
    } else {
      if (importFile) {
        readFile(importFile, function (e) {
          // read file
          importPromptHandler(e.target.result?.split(","));
        });
      }
    }
  }, [importFile]);

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      title: "",
      content: t("ReserveUserName.deleteConfirm"),
      type: "delete",
    });
  };

  const importPromptHandler = (fileData) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      title: "",
      content: t("ReserveUserName.uploadConfirm"),
      type: "import",
      fileData: fileData,
    });
  };

  const addNewClickHandler = () => {
    history.push("/reserved-usernames-addNew");
  };

  const formatResUserData = useCallback(() => {
    return reservedData?.data?.users?.map((username, index) => ({
      ...username,
      no: (request?.page - 1) * request?.page_count + index + 1,
      username: <div title={username.username}>{username.username}</div>,
      reserved_for: (
        <div
          title={
            username.linked_to === "Mobile"
              ? username.phone
              : username.linked_to === "Email"
              ? username.email
              : username.linked_to
          }
        >
          {username.linked_to === "Mobile"
            ? username.phone
            : username.linked_to === "Email"
            ? username.email
            : username.linked_to}
        </div>
      ),

      modified_on: (
        <div title={username.modified_on}>{username.modified_on && convertToUAETime(username.modified_on, DATE_WITH_TIME)}</div>
      ),
      actions: (
        <div className="cust-table-actions-wrap">
          <button
            className=" color-violet action-btn"
            title="Edit"
            onClick={() =>
              history.push(`/reserved-usernames-edit/${username.id}`)
            }
            disabled={!isEditable}
          >
            <img src={Edit_icon} alt="Edit" style={{'opacity':!isEditable ? "50%":""}}/>
          </button>
          <button
            onClick={() => deletePromptHandler(username.id)}
            className=" color-red action-btn"
            title="Delete"
            disabled={!isDeleteable}
          >
            <img src={Delete_icon} alt="Delete" style={{'opacity':!isDeleteable ? "50%":""}}/>
          </button>
        </div>
      ),
    }));
  }, [reservedData?.data?.users, isEditable, isDeleteable]);

  //memoized user data
  const reservedUserData = useMemo(
    () => formatResUserData(),
    [formatResUserData]
  );
  return (
    <div
      className="page-content"
      data-testid="component-reserveUserNameList"
      id="reserveUNList"
      ref={pageWrapRef}
    >
      <Container fluid>
        <Row>
          <Breadcrumbs
            title="Tables"
            breadcrumbItem={t("ReserveUserName.Title")}
          />
          {(reservedData?.error || reservedData?.success) && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color={reservedData?.error ? "danger" : "success"}
                className="alert-dismissible fade show"
                role="alert"
              >
                {reservedData?.error || reservedData?.success}
              </UncontrolledAlert>
            </div>
          )}
          {uploadInfo && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color={uploadInfo?.color}
                className="alert-dismissible fade show"
                role="alert"
              >
                <div
                  dangerouslySetInnerHTML={{ __html: uploadInfo?.message }}
                />
              </UncontrolledAlert>
            </div>
          )}
          <Datatable
            defaultSortField={"modified_on"}
            defaultSortAsc={false}
            tableID={"reservedUserNames"}
            rows={reservedUserData}
            columns={reserveUsernameColumnData}
            upload = {isAddEnabled}
            add = {isAddEnabled}
            search
            addNewClickHandler={addNewClickHandler}
            setImportFile={setImportFile}
            request={request}
            setRequest={setRequest}
            totalRecords={reservedData?.data?.total}
            loading={reservedData?.loading}
            addNewLabel={t("ReserveUserName.addBtn")}
            searchTerm={reservedData?.request?.keyword}
            tableCardClassName={"snoTable"}
          />
        </Row>
      </Container>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {reservedData?.loading && <Loader darkBg={true}/>}
    </div>
  );
};

export default ReservedUsernames;
