import React from "react";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";

import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";

import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";
import { paymentMethodsColumnData } from "../../../helpers/columns";
import {
  deletePaymentMethod,
  editPaymentMethod,
  getPaymentMethods,
} from "../../../store/paymentManagement/actions";
import OpenEye from "../../../assets/icons/ebud-icons/openEye.svg";
import closeEye from "../../../assets/icons/ebud-icons/closeEye.svg";

const PaymentMethod = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["Active"],
    page_count: 50,
    keyword: "",
  };
  const [request, setRequest] = useState(basicRequest);
  const pageWrapRef = useRef(null);
  const warningAlertRef = useRef(null);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const data = useSelector((state) => state?.PaymentMethods);

  useEffect(() => {
    dispatch(getPaymentMethods("english"));
  }, [request]);

  const formatPaymentMethodsData = useCallback(() => {
    return (
      data?.loading === false &&
      data?.paymentMethods?.length &&
      data?.paymentMethods?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        payment_method: (
          <div data-tag="allowRowEvents" title={result?.payment_method}>
            {result?.payment_method}
          </div>
        ),
        service_fee: (
          <div data-tag="allowRowEvents" title={result?.service_fee}>
            {result?.service_fee}
          </div>
        ),
        icon: (
          <div data-tag="allowRowEvents" title={result?.payment_method}>
            <img width={28} src={result?.icon} alt="gift" />
            {}
          </div>
        ),

        actions: (
          <div className="cust-table-actions-wrap">
            <button
              className=" color-violet action-btn"
              title="Edit"
              onClick={() =>
                history.push({
                  pathname: `/payment-method-edit/${result?.id}`,
                  state: { id: result.payment_method_identifier },
                })
              }
              // disabled={!isEditable}
            >
              <img
                src={Edit_icon}
                alt="Edit"
                // style={{
                //   opacity: !isEditable ? "50%" : "",
                //   cursor: !isEditable ? "not-allowed" : "",
                // }}
              />
            </button>
            <button
              onClick={() =>
                deletePromptHandler(result?.payment_method_identifier)
              }
              className=" color-red action-btn"
              title="Delete"
              // disabled={!isDeleteable}
            >
              <img
                src={Delete_icon}
                alt="Delete"
                // style={{
                //   opacity: !isDeleteable ? "50%" : "",
                //   cursor: !isDeleteable ? "not-allowed" : "",
                // }}
              />
            </button>
            <button
              className=" color-violet action-btn"
              title={result?.status ? "Hide" : "Unhide"}
              onClick={() => {
                const formData = new FormData();
                formData?.append(
                  "data",
                  JSON.stringify({
                    payment_method_identifier:
                      result?.payment_method_identifier,
                    service_fee: "",
                    process_fee: "",
                    process_time: result?.process_time,
                    minimum_withdrawal_amount: "",
                    status: !result?.status,
                    method_list: [],
                  })
                );
                dispatch(
                  editPaymentMethod({
                    params: result?.payment_method_identifier,
                    method: formData,
                    callback: () => {
                      dispatch(getPaymentMethods("english"));
                    },
                  })
                );
              }}
              // disabled={!isEditable}
            >
              <img
                src={result?.status ? OpenEye : closeEye}
                alt="Edit"
                // style={{
                //   opacity: !isEditable ? "50%" : "",
                //   cursor: !isEditable ? "not-allowed" : "",
                // }}
              />
            </button>
          </div>
        ),
      }))
    );
  }, [request, data?.paymentMethods]);
  const paymentMethodsData = useMemo(
    () => formatPaymentMethodsData(),
    [formatPaymentMethodsData]
  );
  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to delete 
      this Payment method?`,
      type: "delete",
    });
  };
  const okHandler = () => {
    dispatch(
      deletePaymentMethod({
        id: promptMessage.id,
        callback: () => dispatch(getPaymentMethods()),
      })
    );
  };
  const addNewClickHandler = () => {
    history.push("/payment-method-addNew");
  };
  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Payment Methods" />
          {/* <div className="container"> */}
          <Datatable
            tableID={"adminTable"}
            columns={paymentMethodsColumnData}
            rows={paymentMethodsData}
            // setRequest={setRequest}
            // request={request}
            // totalRecords={data.adminData?.total}
            // search
            add
            addNewClickHandler={addNewClickHandler}
            addNewLabel={t("Admins.addAdmin.addBtn")}
            // searchTerm={data?.request?.keyword}
            // loading={data?.loading}
            tableCardClassName={"snoTable"}
          />
          {/* </div> */}
        </Row>
      </Container>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default PaymentMethod;
