export const GET_PODIUMS_CATEGORIES = "GET_PODIUMS_CATEGORIES";
export const GET_PODIUMS_CATEGORIES_BEGIN = "GET_PODIUMS_CATEGORIES_BEGIN";
export const GET_PODIUMS_CATEGORIES_SUCCESS = "GET_PODIUMS_CATEGORIES_SUCCESS";
export const GET_PODIUMS_CATEGORIES_FAIL = "GET_PODIUMS_CATEGORIES_FAIL";

export const STORE_PODIUM_REQUESTS = "STORE_PODIUM_REQUESTS";

export const GET_PODIUMS_CATEGORIES_BY_ID = "GET_PODIUMS_CATEGORIES_BY_ID";
export const GET_PODIUMS_CATEGORIES_BY_ID_BEGIN =
  "GET_PODIUMS_CATEGORIES_BY_ID_BEGIN";
export const GET_PODIUMS_CATEGORIES_BY_ID_SUCCESS =
  "GET_PODIUMS_CATEGORIES_BY_ID_SUCCESS";
export const GET_PODIUMS_CATEGORIES_BY_ID_FAIL =
  "GET_PODIUMS_CATEGORIES_BY_ID_FAIL";

export const ADD_PODIUMS_CATEGORIES = "ADD_PODIUMS_CATEGORIES";
export const ADD_PODIUMS_CATEGORIES_BEGIN = "ADD_PODIUMS_CATEGORIES_BEGIN";
export const ADD_PODIUMS_CATEGORIES_SUCCESS = "ADD_PODIUMS_CATEGORIES_SUCCESS";
export const ADD_PODIUMS_CATEGORIES_FAIL = "ADD_PODIUMS_CATEGORIES_FAIL";

export const EDIT_PODIUMS_CATEGORIES = "EDIT_PODIUMS_CATEGORIES";
export const EDIT_PODIUMS_CATEGORIES_BEGIN = "EDIT_PODIUMS_CATEGORIES_BEGIN";
export const EDIT_PODIUMS_CATEGORIES_SUCCESS =
  "EDIT_PODIUMS_CATEGORIES_SUCCESS";
export const EDIT_PODIUMS_CATEGORIES_FAIL = "EDIT_PODIUMS_CATEGORIES_FAIL";

export const DELETE_PODIUMS_CATEGORIES = "DELETE_PODIUMS_CATEGORIES";
export const DELETE_PODIUMS_CATEGORIES_BEGIN =
  "DELETE_PODIUMS_CATEGORIES_BEGIN";
export const DELETE_PODIUMS_CATEGORIES_SUCCESS =
  "DELETE_PODIUMS_CATEGORIES_SUCCESS";
export const DELETE_PODIUMS_CATEGORIES_FAIL = "DELETE_PODIUMS_CATEGORIES_FAIL";

export const RESET_PODIUMS_ERRORS = "RESET_PODIUMS_ERRORS";

// podiums

export const GET_PODIUMS = "GET_PODIUMS";
export const GET_PODIUMS_STAT = "GET_PODIUMS_STAT";
export const GET_PODIUMS_BEGIN = "GET_PODIUMS_BEGIN";
export const GET_PODIUMS_SUCCESS = "GET_PODIUMS_SUCCESS";
export const GET_PODIUMS_FAIL = "GET_PODIUMS_FAIL";

export const GET_PODIUMS_BY_ID = "GET_PODIUMS_BY_ID";
export const GET_PODIUMS_BY_ID_BEGIN = "GET_PODIUMS_BY_ID_BEGIN";
export const GET_PODIUMS_BY_ID_SUCCESS = "GET_PODIUMS_BY_ID_SUCCESS";
export const GET_PODIUMS_BY_ID_FAIL = "GET_PODIUMS_BY_ID_FAIL";

export const EDIT_PODIUMS = "EDIT_PODIUMS";
export const EDIT_PODIUMS_BEGIN = "EDIT_PODIUMS_BEGIN";
export const EDIT_PODIUMS_SUCCESS = "EDIT_PODIUMS_SUCCESS";
export const EDIT_PODIUMS_FAIL = "EDIT_PODIUMS_FAIL";

export const DELETE_PODIUMS = "DELETE_PODIUMS";
export const DELETE_PODIUMS_BEGIN = "DELETE_PODIUMS_BEGIN";
export const DELETE_PODIUMS_SUCCESS = "DELETE_PODIUMS_SUCCESS";
export const DELETE_PODIUMS_FAIL = "DELETE_PODIUMS_FAIL";

export const BLOCK_PODIUMS = "BLOCK_PODIUMS";
export const BLOCK_PODIUMS_BEGIN = "BLOCK_PODIUMS_BEGIN";
export const BLOCK_PODIUMS_SUCCESS = "BLOCK_PODIUMS_SUCCESS";
export const BLOCK_PODIUMS_FAIL = "BLOCK_PODIUMS_FAIL";

export const GET_CHALLENGES_HISTORY = "GET_CHALLENGES_HISTORY";
export const GET_CHALLENGES_HISTORY_BEGIN = "GET_CHALLENGES_HISTORY_BEGIN";
export const GET_CHALLENGES_HISTORY_SUCCESS = "GET_CHALLENGES_HISTORY_SUCCESS";
export const GET_CHALLENGES_HISTORY_FAIL = "GET_CHALLENGES_HISTORY_FAIL";

export const GET_CHALLENGES_HISTORY_BY_USER_ID =
  "GET_CHALLENGES_HISTORY_BY_USER_ID";
export const GET_CHALLENGES_HISTORY_BY_USER_ID_BEGIN =
  "GET_CHALLENGES_HISTORY_BY_USER_ID_BEGIN";

export const GET_CHALLENGES_HISTORY_BY_USER_ID_SUCCESS =
  "GET_CHALLENGES_HISTORY_BY_USER_ID_SUCCESS";
export const GET_CHALLENGES_HISTORY_BY_USER_ID_FAIL =
  "GET_CHALLENGES_HISTORY_BY_USER_ID_FAIL";

export const GET_SPECIFIC_CHALLENGE_DETAILS = "GET_SPECIFIC_CHALLENGE_DETAILS";
export const GET_SPECIFIC_CHALLENGE_DETAILS_BEGIN =
  "GET_SPECIFIC_CHALLENGE_DETAILS_BEGIN";
export const GET_SPECIFIC_CHALLENGE_DETAILS_SUCCESS =
  "GET_SPECIFIC_CHALLENGE_DETAILS_SUCCESS";
export const GET_SPECIFIC_CHALLENGE_DETAILS_FAIL =
  "GET_SPECIFIC_CHALLENGE_DETAILS_FAIL";
