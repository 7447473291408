import React, { useState, useEffect } from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Datatable from "../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../components/UI/Breadcrumb";
import Loader from "../../components/Common/Loader";
import moment from "moment";

import {
  getSubscriptionData,
  storeRequest,
} from "../../store/appSubscription/action";

import { appSubscriptionColumnData } from "../../helpers/columns";

const Subscription = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appSubscriptions = useSelector((state) => state.appSubscriptions);
  const [appSubscriptionRow, setAppSubscriptionRow] = useState({});

  const basicRequest = {
    sort: "total",
    sort_order: "desc",
    page: 1,
    page_count: 50,
    date_range_filter: [],
    from_date: moment(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ).format("DD-MM-YYYY"),
    to_date: moment(new Date()).format("DD-MM-YYYY"),
  };

  const [request, setRequest] = useState({ ...basicRequest });

  useEffect(() => {
    request && dispatch(getSubscriptionData(request));
  }, [request]);

  useEffect(() => {
    request && dispatch(getSubscriptionData({ ...request }));
    request && dispatch(storeRequest({ ...request }));
  }, [request]);

  useEffect(() => {
    setAppSubscriptionRow(getAppSubscriptionsRowData());
  }, [appSubscriptions]);

  const calculateAll = () => {
    return {
      date: t("AppSubscriptionStatistics.all"),
      ios: appSubscriptions?.subscriptionData?.ios_total,
      total: appSubscriptions?.subscriptionData?.platforms_total,
      android: appSubscriptions?.subscriptionData?.android_total,
    };
  };

  const getAppSubscriptionsRowData = () => {
    let temp = [];
    if (appSubscriptions?.subscriptionData?.subscription_statistics) {
      temp = [
        calculateAll(), //adding 'All' as the first column
        ...appSubscriptions?.subscriptionData?.subscription_statistics,
      ];
      return temp?.map((statistics, index) => ({
        ...statistics,
        no: statistics.date !== t("AppSubscriptionStatistics.all") && index,
        date: (
          <div
            title={statistics.date}
            className={
              statistics.date === t("AppSubscriptionStatistics.all")
                ? "bold islCountry"
                : "islCountry"
            }
          >
            {statistics.date}
          </div>
        ),
        platform: (
          <div className="grouped-column-body">
            <div title={statistics.ios} className="islIos">
              {statistics.ios}
            </div>
            <div title={statistics.android} className="islAndroid">
              {statistics.android}
            </div>
          </div>
        ),
        total: (
          <div title={statistics.total} className="islTotal">
            {statistics.total}
          </div>
        ),
      }));
    }
  };

  return (
    <div
      className="app-installations page-content"
      data-testid="component-subscriptionData"
      id="userMgmtList"
    >
      <Container fluid>
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="App Subscriptions" />
          {appSubscriptions?.listing_error && (
            <div>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {appSubscriptions?.listing_error}
              </UncontrolledAlert>
            </div>
          )}
          <Datatable
            defaultSortField={"total"}
            defaultSortAsc={request?.sort_order === "asc" ? true : false}
            tableID={"subscriptions"}
            columns={appSubscriptionColumnData}
            rows={appSubscriptionRow}
            setRequest={setRequest}
            request={request}
            totalRecords={appSubscriptions?.subscriptionData?.total_records}
            statisticsFilter
            tableCardClassName={"snoTable"}
          />
        </Row>
      </Container>

      {appSubscriptions?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default Subscription;
