export const GET_MLM_PARENTS_AND_LEVELS = "GET_MLM_PARENTS_AND_LEVELS";
export const GET_MLM_PARENTS_AND_LEVELS_BEGIN =
  "GET_MLM_PARENTS_AND_LEVELS_BEGIN";
export const GET_MLM_PARENTS_AND_LEVELS_SUCCESS =
  "GET_MLM_PARENTS_AND_LEVELS_SUCCESS";
export const GET_MLM_PARENTS_AND_LEVELS_FAILURE =
  "GET_MLM_PARENTS_AND_LEVELS_FAILURE";
export const GET_MLM_PAGINATED_LEVEL = "GET_MLM_PAGINATED_LEVEL";
export const GET_MLM_PAGINATED_LEVEL_BEGIN = "GET_MLM_PAGINATED_LEVEL_BEGIN";
export const GET_MLM_PAGINATED_LEVEL_SUCCESS =
  "GET_MLM_PAGINATED_LEVEL_SUCCESS";
export const GET_MLM_PAGINATED_LEVEL_FAILURE =
  "GET_MLM_PAGINATED_LEVEL_FAILURE";
export const LEVEL_CLICK = "LEVEL_CLICK";
export const CLEAR_TREE = "CLEAR_TREE";

export const GET_MLM_LEVEL_USERS = "GET_MLM_LEVEL_USERS";
export const GET_MLM_LEVEL_USERS_BEGIN = "GET_MLM_LEVEL_USERS_BEGIN";
export const GET_MLM_LEVEL_USERS_SUCCESS = "GET_MLM_LEVEL_USERS_SUCCESS";
export const GET_MLM_LEVEL_USERS_FAIL = "GET_MLM_LEVEL_USERS_FAIL";
