import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import Datatable from "../../../../components/UI/Tables/Datatable";
import {
  FlixGRSSummaryColumnData,
  GRSSummaryColumnData,
} from "../../../../helpers/columns";

import Loader from "../../../../components/Common/Loader";

import {
  exportGRSSummaryByUserId,
  getGRSSummaryByUserId,
} from "../../../../store/GiftTransfers/actions";
import premiumImage from "../../../../assets/icons/premium.svg";
import defaultProfile from "../../../../assets/images/users/user.svg";
import CountUp from "react-countup";
import Coin_bal_icon from "../../../../assets/icons/sdfl_icons/coin-bal-icon.svg";
import Flix_bal_icon from "../../../../assets/icons/sdfl_icons/flix-bal-icon.svg";
import { useTranslation } from "react-i18next";

const FlixGrsSummary = ({
  toggle,
  modal,
  userId,
  user,
  profilePhoto,
  premiumstatus,
}) => {
  const dispatch = useDispatch();
  const basicRequest = {
    // sort: "transfered_date",
    // sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 10,
    // keyword: "",
    filter: "sender",
    gift_type: "flix",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const data = useSelector((state) => state.giftTransfer);
  const { t } = useTranslation();

  useEffect(() => {
    request && userId && dispatch(getGRSSummaryByUserId({ request, userId }));
  }, [request, request?.actions, modal]);

  const addNewClickHandler = () => {
    console.log("exporting");
    dispatch(
      exportGRSSummaryByUserId({ request, userId, exportParam: "True" })
    );
  };

  const giftTransferData =
    data?.grsSummaryDataByUser?.data?.length >= 0 &&
    data?.grsSummaryDataByUser?.data?.map((transfers, index) => {
      const premium =
        request.filter === "sender" ? premiumstatus : transfers?.premium;
      return {
        no: (request?.page - 1) * request?.page_count + index + 1,
        sender: (
          <>
            <div
              onClick={() => {
                window.open(
                  `/user-details/${
                    request?.filter === "sender" ? userId : transfers?.user_id
                  }`,
                  "_blank"
                );
              }}
              className="profileWrap"
              data-tag="allowRowEvents"
            >
              {premium ? (
                <div className="huddle_premium_icon" data-tag="allowRowEvents">
                  <img
                    src={premiumImage}
                    data-tag="allowRowEvents"
                    alt="Premium"
                  />
                </div>
              ) : null}

              <div className="d-flex">
                <div data-tag="allowRowEvents">
                  <img
                    data-tag="allowRowEvents"
                    className="table_profileImg"
                    src={
                      request?.filter === "sender"
                        ? profilePhoto
                        : transfers?.profile_photo || defaultProfile
                    }
                    alt="Profile"
                  />
                </div>

                <div
                  className="mx-2 mt-3"
                  //   title={senderDetails.name}
                  data-tag="allowRowEvents"
                >
                  {request.filter === "sender" ? user : transfers?.name}{" "}
                </div>
              </div>
            </div>
          </>
        ),
        receiver: (
          <>
            <div
              onClick={() => {
                window.open(
                  `/user-details/${
                    request?.filter === "receiver" ? userId : transfers?.user_id
                  }`,
                  "_blank"
                );
              }}
              className="profileWrap"
              data-tag="allowRowEvents"
            >
              {premium ? (
                <div className="huddle_premium_icon" data-tag="allowRowEvents">
                  <img
                    src={premiumImage}
                    data-tag="allowRowEvents"
                    alt="Premium"
                  />
                </div>
              ) : null}

              <div className="d-flex">
                <div data-tag="allowRowEvents">
                  <img
                    data-tag="allowRowEvents"
                    className="table_profileImg"
                    src={
                      request?.filter === "receiver"
                        ? profilePhoto
                        : transfers?.profile_photo || defaultProfile
                    }
                    alt="Profile"
                  />
                </div>

                <div
                  className="mx-2 mt-3"
                  //   title={senderDetails.name}
                  data-tag="allowRowEvents"
                >
                  {request.filter === "receiver" ? user : transfers?.name}{" "}
                </div>
              </div>
            </div>
          </>
        ),

        gift_count: (
          <>
            <div data-tag="allowRowEvents" title={transfers?.gift_count}>
              {transfers?.gift_count}
            </div>
          </>
        ),
        gift_total: (
          <>
            <div data-tag="allowRowEvents" title={transfers?.gift_total}>
              {transfers?.gift_total}
            </div>
          </>
        ),
      };
    });

  return (
    <div>
      <Modal
        centered
        isOpen={modal}
        toggle={toggle}
        style={{ minWidth: "1000px", maxWidth: "1200px" }}
      >
        <ModalBody>
          <>
            <Container
              fluid
              id="userManagementWrapper"
              data-testid="userMgmtWrap"
            >
              <Row className="mt-4">
                <Breadcrumbs
                  title="Tables"
                  breadcrumbItem="FLiX Gift Revenue share summary"
                />
                {!data?.loading && (
                  <>
                    <Col md={3} xl={3}>
                      <Card>
                        <CardBody>
                          <div className="float-end mt-2">
                            <img src={Coin_bal_icon} alt="coin" />
                          </div>
                          <div>
                            <h4 className="mb-1 mt-1">
                              <span>
                                <CountUp
                                  end={+data?.grsSummaryDataByUser?.total_sent}
                                />
                              </span>
                            </h4>
                            <p className="text-muted mb-0">
                              Total FLiX Transfered
                            </p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </>
                )}

                <Datatable
                  // defaultSortField={request?.sort || "transfered_date"}
                  // defaultSortAsc={
                  //   request?.sort_order === "asc" ? true : false
                  // }
                  dateFilter
                  dateType="Transfered Date Range"
                  tableID={"flaxTable"}
                  columns={FlixGRSSummaryColumnData}
                  rows={giftTransferData}
                  setRequest={setRequest}
                  request={request}
                  add
                  totalRecords={data?.grsSummaryDataByUser?.total}
                  addNewClickHandler={addNewClickHandler}
                  addNewLabel={t("UserManagement.export")}
                  // search
                  // searchTerm={data?.request?.keyword}
                  // reqstatusFilter={request?.status_filter}
                  loading={data?.loading}
                  tableCardClassName={"snoTable"}
                  grsFilter
                />
              </Row>
            </Container>
            {data?.loading && <Loader darkBg={true} />}
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default FlixGrsSummary;
