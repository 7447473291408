export const GET_REPORTED_USER = "GET_REPORTED_USER";
export const GET_REPORTED_USER_BEGIN = "GET_REPORTED_USER_BEGIN";
export const GET_REPORTED_USER_SUCCESS = "GET_REPORTED_USER_SUCCESS";
export const GET_REPORTED_USER_FAIL = "GET_REPORTED_USER_FAIL";

export const DELETE_REPORTED_USER = "DELETE_REPORTED_USER";
export const DELETE_REPORTED_USER_BEGIN = "DELETE_REPORTED_USER_BEGIN";
export const DELETE_REPORTED_USER_SUCCESS = "DELETE_REPORTED_USER_SUCCESS";
export const DELETE_REPORTED_USER_FAIL = "DELETE_REPORTED_USER_FAIL";

export const RESET_MESSAGES = "RESET_MESSAGES";

export const GET_REPORTED_CATEGORY = "GET_REPORTED_CATEGORY";
export const GET_REPORTED_CATEGORY_BEGIN = "GET_REPORTED_CATEGORY_BEGIN";
export const GET_REPORTED_CATEGORY_SUCCESS = "GET_REPORTED_CATEGORY_SUCCESS";
export const GET_REPORTED_CATEGORY_FAIL = "GET_REPORTED_CATEGORY_FAIL";

export const STORE_REPORTED_USER_REQUEST = "STORE_REPORTED_USER_REQUEST";

// huddles reported message categories

export const GET_REPORTED_USERS_CATEGORIES = "GET_REPORTED_USERS_CATEGORIES";
export const GET_REPORTED_USERS_CATEGORIES_BEGIN =
  "GET_REPORTED_USERS_CATEGORIES_BEGIN";
export const GET_REPORTED_USERS_CATEGORIES_SUCCESS =
  "GET_REPORTED_USERS_CATEGORIES_SUCCESS";
export const GET_REPORTED_USERS_CATEGORIES_FAIL =
  "GET_REPORTED_USERS_CATEGORIES_FAIL";

export const GET_REPORTED_USERS_CATEGORIES_BY_ID =
  "GET_REPORTED_USERS_CATEGORIES_BY_ID";
export const GET_REPORTED_USERS_CATEGORIES_BY_ID_BEGIN =
  "GET_REPORTED_USERS_CATEGORIES_BY_ID_BEGIN";
export const GET_REPORTED_USERS_CATEGORIES_BY_ID_SUCCESS =
  "GET_REPORTED_USERS_CATEGORIES_BY_ID_SUCCESS";
export const GET_REPORTED_USERS_CATEGORIES_BY_ID_FAIL =
  "GET_REPORTED_USERS_CATEGORIES_BY_ID_FAIL";

export const ADD_REPORTED_USERS_CATEGORIES = "ADD_REPORTED_USERS_CATEGORIES";
export const ADD_REPORTED_USERS_CATEGORIES_BEGIN =
  "ADD_REPORTED_USERS_CATEGORIES_BEGIN";
export const ADD_REPORTED_USERS_CATEGORIES_SUCCESS =
  "ADD_REPORTED_USERS_CATEGORIES_SUCCESS";
export const ADD_REPORTED_USERS_CATEGORIES_FAIL =
  "ADD_REPORTED_USERS_CATEGORIES_FAIL";

export const EDIT_REPORTED_USERS_CATEGORIES = "EDIT_REPORTED_USERS_CATEGORIES";
export const EDIT_REPORTED_USERS_CATEGORIES_BEGIN =
  "EDIT_REPORTED_USERS_CATEGORIES_BEGIN";
export const EDIT_REPORTED_USERS_CATEGORIES_SUCCESS =
  "EDIT_REPORTED_USERS_CATEGORIES_SUCCESS";
export const EDIT_REPORTED_USERS_CATEGORIES_FAIL =
  "EDIT_REPORTED_USERS_CATEGORIES_FAIL";

export const DELETE_REPORTED_USERS_CATEGORIES =
  "DELETE_REPORTED_USERS_CATEGORIES";
export const DELETE_REPORTED_USERS_CATEGORIES_BEGIN =
  "DELETE_REPORTED_USERS_CATEGORIES_BEGIN";
export const DELETE_REPORTED_USERS_CATEGORIES_SUCCESS =
  "DELETE_REPORTED_USERS_CATEGORIES_SUCCESS";
export const DELETE_REPORTED_USERS_CATEGORIES_FAIL =
  "DELETE_REPORTED_USERS_CATEGORIES_FAIL";

export const GET_REPORTED_USER_BY_ID = "GET_REPORTED_USER_BY_ID";
export const GET_REPORTED_USER_BY_ID_BEGIN = "GET_REPORTED_USER_BY_ID_BEGIN";
export const GET_REPORTED_USER_BY_ID_SUCCESS =
  "GET_REPORTED_USER_BY_ID_SUCCESS";
export const GET_REPORTED_USER_BY_ID_FAIL = "GET_REPORTED_USER_BY_ID_FAIL";
