export const ADD_MANUAL_COIN = "ADD_MANUAL_COIN ";
export const ADD_MANUAL_COIN_BEGIN = "ADD_MANUAL_COIN_BEGIN";
export const ADD_MANUAL_COIN_SUCCESS = "ADD_MANUAL_COIN_SUCCESS";
export const ADD_MANUAL_COIN_ERROR = "ADD_MANUAL_COIN_ERROR"

export const DELETE_MANUAL_COIN  = "DELETE_MANUAL_COIN ";
export const DELETE_MANUAL_COIN_BEGIN = "DELETE_MANUAL_COIN_BEGIN";
export const DELETE_MANUAL_COIN_SUCCESS = "DELETE_MANUAL_COIN_SUCCESS";
export const DELETE_MANUAL_COIN_ERROR = "DELETE_MANUAL_COIN_ERROR"

export const GET_MANUAL_COIN_BEGIN = "GET_MANUAL_COIN_BEGIN";
export const GET_MANUAL_COIN_SUCCESS = "GET_MANUAL_COIN_SUCCESS";
export const GET_MANUAL_COIN_ERROR = "GET_MANUAL_COIN_ERROR"
export const GET_ALL_MANUAL_COIN = "GET_ALL_MANUAL_COIN";

export const RESET_MANUAL_COIN_STATES = "RESET_MANUAL_COIN_STATES";