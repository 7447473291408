import { Card, CardBody } from "reactstrap";
import { useAsync } from "../../../../../util/useAsync";
import { del, get } from "../../../../../helpers/api_helper";
import { USER_NOTIFICATION } from "../../../../../helpers/url_helper";
import { useParams } from "react-router-dom";
import Datatable from "../../../../../components/UI/Tables/Datatable";
import { notificationCol } from "../../../../../helpers/columns";
import { useCallback, useEffect } from "react";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../../../helpers/constants";
import { convertToUAETime } from "../../../../../helpers/utils";
import Edit_icon from "../../../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../../../assets/icons/ebud-icons/Delete.svg";
import { useState } from "react";
import ConfirmationAlert from "../../../../../components/UI/ConfirmationAlert";
import { NOTIFICATION_STATUS, NOTIFICATION_TYPE } from "../PushNotification";
import { alert } from "../../../../../util/toast";

export const NotificationList = ({
  setNotification,
  toggle,
  selectedNotification,
}) => {
  const params = useParams();
  const { status, run, error, data } = useAsync();

  const { status: deleteStatus, run: runDelete } = useAsync();
  const [deletePopupVisibility, setAdminPopupVisibility] = useState(false);

  const selectNotfication = (notification) => {
    const notificationType = Object.keys(NOTIFICATION_STATUS).find(
      (key) => NOTIFICATION_STATUS[key] === notification.status
    );
    const notificationWithType = { ...notification, notificationType };

    setNotification(notificationWithType);
    toggle();
  };

  useEffect(() => {
    if (deleteStatus === "resolved") {
      alert.success("Notification has been deleted successfully!");
    }
  }, [deleteStatus]);

  const deleteNotification = () => {
    runDelete(del(`${USER_NOTIFICATION}/${selectedNotification.id}`));
  };

  const notificationData = useCallback(() => {
    return data?.result?.notifications?.map((notification, index) => ({
      ...notification,
      no: index + 1,
      time_created: (
        <div data-tag="allowRowEvents" title={notification.time_created}>
          {notification.time_created &&
            convertToUAETime(
              notification.time_created,
              DATE_WITH_TIME_IN_SECONDS
            )}
        </div>
      ),
      push: <div>{notification.push ? "YES" : "NO"}</div>,
      action: (
        <>
          {notification.status !== "Sent" && (
            <div className="cust-table-actions-wrap">
              <button
                className=" color-violet action-btn"
                title="Edit"
                onClick={() => {
                  selectNotfication(notification);
                }}
              >
                <img src={Edit_icon} alt="Edit" />
              </button>
              <button
                onClick={() => {
                  setAdminPopupVisibility(true);
                  setNotification(notification);
                }}
                className=" color-red action-btn"
                title="Delete"
                //disabled={!isDeleteable}
              >
                <img src={Delete_icon} alt="Delete" />
              </button>
            </div>
          )}
        </>
      ),
    }));
  }, [data?.result]);

  useEffect(() => {
    if (deleteStatus === "loading") return;
    run(get(`${USER_NOTIFICATION}?user_id=${params.id}`));
  }, [deleteStatus]);
  return (
    <>
      <Datatable columns={notificationCol} rows={notificationData()} />
      <ConfirmationAlert
        content={`Do you want to delete this notification?`}
        modal_center={deletePopupVisibility}
        title={"Are you sure?"}
        setmodal_center={() => setAdminPopupVisibility(false)}
        onOK={() => {
          deleteNotification();
        }}
      />
    </>
  );
};
