import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getFlixRate,
  updateFlixRate,
} from "../../../../store/users/editRating/action";
import { getUserDetails } from "../../../../store/users/actions";
import Loader from "../../../../components/Common/Loader";

const EditRating = ({ modal, toggle, userId, currentRate }) => {
  const [selectedValue, setSelectedValue] = useState(currentRate);
  const dispatch = useDispatch();
  const rating = useSelector((state) => state.EditRating);

  useEffect(() => {
    dispatch(getFlixRate());
  }, [dispatch]);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const updateRating = (e) => {
    e.preventDefault();
    const data = {
      user_id: userId,
      rate: parseFloat(selectedValue),
    };
    dispatch(
      updateFlixRate({
        payload: data,
        callback: () => dispatch(getUserDetails(userId)),
      })
    );
  };

  return (
    <div>
      <Modal
        centered
        isOpen={modal}
        toggle={toggle}
        style={{ minWidth: "300px", maxWidth: "300px" }}
      >
        <ModalHeader toggle={toggle}>Edit FLiX Rating</ModalHeader>
        <ModalBody>
          <select
            style={{
              outline: "none",
              width: "100%", // Adjust width if necessary
            }}
            className="p-1 outline"
            value={selectedValue}
            onChange={handleChange}
          >
            <option value="">Select a value</option>
            {rating.flix_rate?.length > 0 &&
              rating.flix_rate.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
          </select>
          {rating.loading && <Loader darkBg={true} />}
        </ModalBody>
        <ModalFooter>
          <div className="d-grid gap-2">
            <button
              onClick={updateRating}
              type="button"
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditRating;
