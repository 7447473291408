import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";

import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";

import {
  huddleCategoryColumnData,
  podiumCategoryColumnData,
} from "../../../helpers/columns";
import { checkUserPermission } from "../../../helpers/utils";
import {
  deletePodiumsCategories,
  getPodiumsCategories,
} from "../../../store/podiums/actions";

const PodiumCategories = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["Active"],
    page_count: 50,
    keyword: "",
  };
  const [request, setRequest] = useState(basicRequest);
  const pageWrapRef = useRef(null);
  const warningAlertRef = useRef(null);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const data = useSelector((state) => state.Podiums);

  const addNewClickHandler = () => {
    history.push("/podium-categories-addNew");
  };

  const scrollToAlertMessage = () => {
    if (data.error) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };
  useEffect(() => {
    if (data.error) {
      scrollToAlertMessage();
    }
  }, [data?.error]);

  useEffect(() => {
    dispatch(getPodiumsCategories(request));
  }, [request]);

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to delete 
      this Category?`,
      type: "delete",
    });
  };
  const okHandler = () => {
    dispatch(
      deletePodiumsCategories({
        id: promptMessage.id,
        callback: () => dispatch(getPodiumsCategories(request)),
      })
    );
  };

  const formatCategoryData = useCallback(() => {
    return (
      data?.categories?.podium_categories?.length &&
      data?.categories?.podium_categories?.map((category, index) => ({
        ...category,
        no: (request?.page - 1) * request?.page_count + index + 1,

        category: (
          <div data-tag="allowRowEvents" title={category.category}>
            {category.name}
          </div>
        ),
        // category_type: (
        //   <div data-tag="allowRowEvents" title={category.category_type}>
        //     {category.category_type}
        //   </div>
        // ),
        actions: (
          <div className="cust-table-actions-wrap">
            <button
              className=" color-violet action-btn"
              title="Edit"
              onClick={() =>
                history.push({
                  pathname: `/podium-category-edit/${category.category}`,
                  state: { id: category?.id },
                })
              }
              // disabled={!isEditable}
            >
              <img
                src={Edit_icon}
                alt="Edit"
                // style={{
                //   opacity: !isEditable ? "50%" : "",
                //   cursor: !isEditable ? "not-allowed" : "",
                // }}
              />
            </button>
            {/* <button
              onClick={() => blockPromptHandler(category.id, category.status)}
              className=" color-orange action-btn"
              title={category.status === "Blocked" ? "Unblock" : "Block"}
              disabled={!isEditable}
            >
              <img
                src={category.status === "Blocked" ? UnBlock_icon : Block_icon}
                alt={category.status === "Blocked" ? "Unblock" : "Block"}
                style={{
                  opacity: !isEditable ? "50%" : "",
                  cursor: !isEditable ? "not-allowed" : "",
                }}
              />
            </button> */}
            <button
              onClick={() => deletePromptHandler(category.id)}
              className=" color-red action-btn"
              title="Delete"
              // disabled={!isDeleteable}
            >
              <img
                src={Delete_icon}
                alt="Delete"
                // style={{
                //   opacity: !isDeleteable ? "50%" : "",
                //   cursor: !isDeleteable ? "not-allowed" : "",
                // }}
              />
            </button>
          </div>
        ),
      }))
    );
  }, [data?.categories?.podium_categories, request]);
  const categoryData = useMemo(
    () => formatCategoryData(),
    [formatCategoryData]
  );

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        ref={pageWrapRef}
      >
        <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Podium Categories" />
            {data?.error && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color="danger"
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error}
                </UncontrolledAlert>
              </div>
            )}
            <div className="container">
              <Datatable
                tableID={"adminTable"}
                columns={podiumCategoryColumnData}
                rows={categoryData}
                setRequest={setRequest}
                request={request}
                // totalRecords={data.adminData?.total}
                // search
                add
                addNewClickHandler={addNewClickHandler}
                addNewLabel={t("Admins.addAdmin.addBtn")}
                // searchTerm={data?.request?.keyword}
                // loading={data?.loading}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
        </Container>
        <ConfirmationAlert
          {...promptMessage}
          modal_center={showPromptPopUp}
          setmodal_center={setShowPromptPopUp}
          onOK={okHandler}
        />
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default PodiumCategories;
