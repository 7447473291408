
import { GET_APPREVIEW_REQUEST_BEGIN, GET_APPREVIEW_REQUEST_SUCCESS,GET_APPREVIEW_UPDATE_BEGIN } from "./actionTypes"


const initialState = {
    error: "",
    loading: false,
    success: "",
    appReviews: {}
}

const AppReviews = (state= initialState, action) => {
    switch(action.type) {
        case GET_APPREVIEW_REQUEST_BEGIN:
            return {
                ...state,
                loading: true,
                error: "",
                appReviews: {},
              };

        case GET_APPREVIEW_REQUEST_SUCCESS: 
        return {
            ...state,
            loading: false,
            appReviews: {list: action.payload?.app_reviews, total: action.payload?.total },
            request:action.payload?.request
        }

        case GET_APPREVIEW_UPDATE_BEGIN: 
        return {
            ...state,
            loading: true,
        }

    }

    return state;

}

export default AppReviews;