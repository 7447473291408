import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  GET_RES_USER_DATA,
  ADD_RES_USERNAME,
  DELETE_RES_USER,
  GET_RES_USER_DETAILS,
  UPDATE_RES_USER_DETAILS,
  IMPORT_RESERVED_USERNAMES,
} from "./actionTypes";
import {
  getReservedUserDataBegin,
  getReservedDataSuccess,
  getReservedDataFail,
  addReservedNameBegin,
  addReservedNameSuccess,
  addReservedNameFail,
  deleteResUserBegin,
  deleteResUserSuccess,
  deleteResUserFail,
  getReservedUsernameDetailsBegin,
  getReservedUsernameDetailsSuccess,
  getReservedUsernameDetailsFail,
  updateReservedNameBegin,
  updateReservedNameSuccess,
  updateReservedNameFail,
  importReservedUsernamesBegin,
  importReservedUsernamesSuccess,
  importReservedUsernamesFail,
} from "./action";
import {
  GET_RES_USERS,
  GET_RES_USERNAME,
  API_IMPORT_RESERVED_USERNAMES
} from "../../../helpers/url_helper";
import { get, post, del, update } from "../../../helpers/api_helper";

function* getReservedNames({ payload }) {
  try {
    yield put(getReservedUserDataBegin());
    const response = yield call(get, GET_RES_USERS, payload);
    yield put(getReservedDataSuccess(response.result));
  } catch (error) {
    yield put(getReservedDataFail(error));
  }
}

function* getReservedUsernameDetails({ payload }) {
  try {
    yield put(getReservedUsernameDetailsBegin());
    const response = yield call(get, GET_RES_USERNAME.replace("{id}", payload));
    yield put(getReservedUsernameDetailsSuccess(response.result));
  } catch (error) {
    yield put(getReservedUsernameDetailsFail(error));
  }
}

function* deleteReserveName({ data, callback }) {
  try {
    yield put(deleteResUserBegin());
    const response = yield call(del, GET_RES_USERNAME.replace("{id}", data));
    callback && callback();
    yield put(deleteResUserSuccess(response.result));
  } catch (error) {
    yield put(deleteResUserFail(error));
  }
}

function* addReserveUsername({ data, callback }) {
  try {
    yield put(addReservedNameBegin());
    const response = yield call(post, GET_RES_USERS, { ...data });
    yield put(addReservedNameSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(addReservedNameFail(error));
  }
}

function* updateUsername({ id, params, callback }) {
  try {
    yield put(updateReservedNameBegin());
    const response = yield call(update, GET_RES_USERNAME.replace("{id}", id), {
      ...params,
    });
    yield put(updateReservedNameSuccess(response));
    callback && callback();
  } catch (error) {
    yield put(updateReservedNameFail(error));
  }
}

function* importReservedUsernames({ payload }) {
  try {
    yield put(importReservedUsernamesBegin());
    const postUrl = yield call(get, API_IMPORT_RESERVED_USERNAMES, { key: `admin/reserve_username/${payload?.key}` });
    try {
      const data = { ...postUrl?.result?.fields, file: payload?.file }
      let formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      yield call(post, postUrl?.result?.url, formData,
        {
          headers: {
            'Accept': '*/*',
            'Content-Type': 'multipart/form-data'
          }
        });
      try {
        const response = yield call(post, API_IMPORT_RESERVED_USERNAMES, { key: `admin/reserve_username/${payload?.key}` });
        yield put(importReservedUsernamesSuccess(response.result));
      } catch (uploadError) {
        yield put(importReservedUsernamesFail(uploadError));
      }
    } catch (s3Error) {
      yield put(importReservedUsernamesFail('Upload failed. Please try again'));
    }
  } catch (error) {
    yield put(importReservedUsernamesFail('Upload failed. Please try again'));
  }
}

function* reserveNameSaga() {
  yield takeEvery(GET_RES_USER_DATA, getReservedNames);
  yield takeLatest(ADD_RES_USERNAME, addReserveUsername);
  yield takeLatest(DELETE_RES_USER, deleteReserveName);
  yield takeLatest(GET_RES_USER_DETAILS, getReservedUsernameDetails);
  yield takeLatest(UPDATE_RES_USER_DETAILS, updateUsername);
  yield takeLatest(IMPORT_RESERVED_USERNAMES, importReservedUsernames)
}

export default reserveNameSaga;
