import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Card, Col, Container, Row, UncontrolledAlert } from "reactstrap";
import Loader from "../../../components/Common/Loader";
import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import {
  editPaymentMethod,
  getPaymentMethodsById,
  resetPaymentMethodErrors,
} from "../../../store/paymentManagement/actions";

const EditPaymentMethod = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);
  const [formChanged, setFormChanged] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [imageFile, setImageFile] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [serviceFee, setServiceFee] = useState("");
  const [minWithdrawalAmt, setMinWithdrawalAmt] = useState("");
  const [processFee, setprocessFee] = useState("");
  const [processTime, setProcessTime] = useState("");
  const [paymentIcon, setPaymentIcon] = useState("");
  const [message, setMessage] = useState(null);
  const [updatedMethodList, setUpdatedMethodList] = useState([]);
  const [identfier, setIdentifier] = useState("");
  const data = useSelector((state) => state?.PaymentMethods);

  const handleImageChange = (e) => {
    setFormChanged(true);
    const selectedFile = e?.target.files[0];
    setImageFile(selectedFile);
  };

  useEffect(() => {
    dispatch(getPaymentMethodsById(location.state.id));
    return () => {
      dispatch(resetPaymentMethodErrors());
    };
  }, [location.state.id]);

  useEffect(() => {
    if (data?.paymentMethodData && data?.paymentMethodData?.length > 0) {
      setprocessFee(data?.paymentMethodData[0]?.process_fee);
      setServiceFee(data?.paymentMethodData[0]?.service_fee);
      setMinWithdrawalAmt(
        data?.paymentMethodData[0]?.minimum_withdrawal_amount
      );
      setProcessTime(data?.paymentMethodData[0]?.process_time);
      setPaymentIcon(data?.paymentMethodData[0]?.icon);
    }
  }, [data?.paymentMethodData]);

  useEffect(() => {
    setIdentifier(data?.paymentMethodData[0]?.payment_method_identifier);
  }, [data?.paymentMethodData]);

  useEffect(() => {
    if (updatedMethodList && updatedMethodList?.length !== 0) {
      for (let i = 0; i < updatedMethodList?.length; i++) {
        if (
          // serviceFee <= 0 ||
          // processFee <= 0 ||
          // minWithdrawalAmt <= 0 ||
          message !== null ||
          processTime === "" ||
          updatedMethodList[i]?.payment_method === ""
        ) {
          setDisabled(true);
          break;
        } else {
          setDisabled(false);
        }
      }
    } else {
      if (
        // serviceFee <= 0 ||
        // processFee <= 0 ||
        // minWithdrawalAmt <= 0 ||
        message !== null
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [
    message,
    // processFee,
    // minWithdrawalAmt,
    // serviceFee,
    processTime,
    updatedMethodList,
  ]);

  const handleChange = (id, language, value) => {
    setFormChanged(true);
    const updatedList = [...updatedMethodList];
    const existingIndex = updatedList.findIndex(
      (item) => item.language === language
    );

    if (existingIndex !== -1) {
      updatedList[existingIndex] = {
        ...updatedList[existingIndex],
        payment_method: value && value?.trim(),
        language: language,
      };
    } else {
      updatedList.push({
        payment_method: value && value?.trim(),
        language: language,
      });
    }

    setUpdatedMethodList(updatedList);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    imageFile && formData?.append("file", imageFile);
    formData?.append(
      "data",
      JSON.stringify({
        payment_method_identifier: identfier,
        service_fee: "",
        process_fee: "",
        process_time: processTime,
        minimum_withdrawal_amount: "",
        status: "",
        method_list: updatedMethodList,
      })
    );
    dispatch(
      editPaymentMethod({
        params: identfier,
        method: formData,
      })
    );
    setFormChanged(false);
  };
  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error, data?.success]);

  const scrollToAlertMessage = () => {
    if (data?.error || data?.success) {
      let element = warningAlertRef?.current;
      let top = element?.offsetTop;
      let height = element?.getBoundingClientRect()?.height;
      pageWrapRef?.current?.scrollTo(0, top - height - 30);
    }
  };
  function getFileNameFromUrl(url) {
    const lastSlashIndex = url?.lastIndexOf("/");

    return url?.substring(lastSlashIndex + 1);
  }
  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        ref={pageWrapRef}
      >
        <BackButton
          label="Payment Methods"
          handleClick={() => history.push("/payment-method")}
        />
        <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Edit Payment Method" />
            {(data?.error || data?.success) && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color={data?.error ? "danger" : "success"}
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error || data?.success}
                </UncontrolledAlert>
              </div>
            )}
            <Col xl="8">
              <Card className="mb-0 p-4 my-4">
                <div className="text-muted">
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <div
                      className="table-responsive mt-4"
                      style={{ overflowX: "hidden" }}
                    >
                      <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Payment Method
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="text"
                              className="form-control mb-4 "
                              value={data?.paymentMethodData[0]?.payment_method}
                              id="name-input"
                              onChange={(e) => {
                                // setFormChanged(true)
                                setPaymentMethod(e.target.value);
                              }}
                              placeholder="Enter payment method"
                              readOnly
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Process Fee
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="number"
                              className="form-control mb-4 "
                              value={processFee}
                              id="name-input"
                              onChange={(e) => {
                                setFormChanged(true);
                                setprocessFee(e.target.value);
                              }}
                              placeholder="Enter process fee"
                            />
                          </div>
                        </div> */}
                      </div>
                      {/* <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Service Fee
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="number"
                              className="form-control mb-4 "
                              value={serviceFee}
                              id="name-input"
                              onChange={(e) => {
                                setFormChanged(true);
                                setServiceFee(e.target.value);
                              }}
                              placeholder="Enter service fee"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter minimum withdrawal amount
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="number"
                              className="form-control mb-4 "
                              value={minWithdrawalAmt}
                              id="name-input"
                              onChange={(e) => {
                                setFormChanged(true);
                                setMinWithdrawalAmt(e.target.value);
                              }}
                              placeholder="Enter minimum withdrawal amount"
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Process Time
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="number"
                              className="form-control mb-4 "
                              value={processTime}
                              id="name-input"
                              onChange={(e) => {
                                setFormChanged(true);
                                setProcessTime(e.target.value);
                              }}
                              placeholder="Enter process Time"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Upload Icon
                            {/* <span className="mandatory">*</span> */}
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName6"
                              type="file"
                              className="form-control mb-2 "
                              accept="image/*"
                              id="name-input"
                              onChange={handleImageChange}
                            />
                          </div>
                          <span className="text-success ">
                            {getFileNameFromUrl(paymentIcon)}
                          </span>
                        </div>
                      </div>
                      {data?.paymentMethodData?.length &&
                        data?.paymentMethodData?.map((result, i) => (
                          <div key={result?.language} className="col-md-12">
                            {result?.language === "english" ? (
                              <div className="d-flex mx-1">
                                <label className="col-md-2 lang-font col-form-label">
                                  {`${result?.language
                                    ?.slice(0, 1)
                                    .toUpperCase()}${result?.language?.slice(
                                    1
                                  )}`}
                                </label>
                                <div className="col-md-6">
                                  <div className="col-md-8 dobWrapper">
                                    <AvInput
                                      name="adminName"
                                      type="text"
                                      className="form-control mb-4 "
                                      value={result?.payment_method}
                                      id="name-input"
                                      onChange={(e) => {
                                        handleChange(
                                          result?.id,
                                          result?.language,

                                          e.target.value
                                        );
                                      }}
                                      placeholder="Enter Payment Method"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="d-flex mx-1">
                                <label className="col-md-2 lang-font col-form-label">
                                  {`${result?.language
                                    ?.slice(0, 1)
                                    .toUpperCase()}${result?.language?.slice(
                                    1
                                  )}`}
                                </label>{" "}
                                <div className="col-md-6">
                                  <div className="col-md-8 dobWrapper">
                                    <AvInput
                                      name="adminName"
                                      type="text"
                                      className="form-control mb-4 "
                                      value={result?.payment_method}
                                      id="name-input"
                                      onChange={(e) => {
                                        handleChange(
                                          result?.id,
                                          result?.language,
                                          e.target.value
                                        );
                                      }}
                                      placeholder="Enter Payment Method"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}

                      <div className="mt-4">
                        <button
                          type="submit"
                          // disabled={isButtonDisabled}
                          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                          // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                          disabled={disabled || !formChanged}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default EditPaymentMethod;
