export const POST_LOGIN = "/user/login";
export const POST_AUTH = "/user/login/mfa";
export const USER_PERMISSIONS = "admin/permissions";
export const GET_DASHBOARD = "/admin/dashboard";
export const GET_DASHBOARD_GRAPH = "/admin/dashboard/graph";
export const GET_USERS = "/admin/users";
export const USER_BY_ID = "/admin/users/{id}";
export const POST_RES_USERNAME = "";
export const GET_RES_USERS = "/admin/usernames/reservations";
export const GET_RES_USERNAME = "/admin/usernames/reservations/{id}";
export const ALLOCATE_USERNAME_BY_ID = "/admin/usernames/allocations/{id}";
export const GET_ALLOCATED_USERNAME_HISTORY =
  "/admin/usernames/allocations/{id}/history";
export const API_USER_SEARCH = "/admin/users/search";
export const API_ALLOCATE_USERNAME = "/admin/usernames/allocations";
export const API_CHECK_AVAILABILITY = "/admin/usernames/availability";
export const API_IMPORT_RESERVED_USERNAMES = "/admin/usernames/import";
export const API_GET_LEGAL_DOCUMENTS = "/admin/legal/documents";
export const API_GET_LEGAL_DOCUMET_BY_ID = "/admin/legal/documents/{id}";
export const GET_REGN_LOGIN_HISTORY = "/admin/users/{id}/history";
export const API_GENERAL_SETTING = "/admin/settings/general";
export const API_IP_REMOVAL = "/admin/settings/ipblockremove";
export const LOGOUT = "/user/logout";
export const GET_TEMPLATES = "/admin/templates";
export const API_PERFORMANCE_POINTS = "/admin/settings/points";
export const GET_TEMPLATE_ID = "/admin/templates/{id}";
export const GET_SUBSCRIPTION_DETAILS_API = "/admin/users/{id}/payments";
export const GET_MLM_TREE = "/admin/users/{id}/tree";
export const GET_MLM_TREE_PAGINATION = "/admin/users/{id}/tree/level/{level}";
export const NOTIFICATIONS_LIST = "/admin/notifications?page=1&page_count=3";
export const NOTIFICATION_LIST_ALL = "/admin/notifications";
export const READ_NOTIFICATION = "/admin/notifications/{id}";
export const UNSEEN_COUNT = "/admin/notifications/unseen";
export const USER_NOTIFICATION = "/admin/users/notification";
export const REPORTED_USER = "/admin/report/users";
export const REPORT_CATEGORY = "/user/report/categories";
export const APP_INSTALLATIONS = "/admin/statistics";
export const GET_EMAIL_DATA = "/admin/users/export/csv";
export const INSTALLATION_COUNTRY = "/admin/statistics/installations";
export const INSTALLATION_REGION = "/admin/regions";
export const APP_SUBSCRIPTIONS = "/admin/statistics/subscriptions";
export const REPORTED_USER_BY_ID = "/admin/report/users/{id}";
export const PERMISSIONS_MASTER_DATA = "admin/roles/permissions";
export const ROLE_BY_ID = "/admin/roles/{id}";
export const ROLE_PERMISSIONS = "admin/roles/{id}";
export const ADMIN_DATA = "/admin/administrators";
export const ADMIN_ROLES = "/admin/roles";
export const ADMIN_BY_ID = "/admin/administrators/{id}";
export const SAMPLE_PASSWORD = "/admin/password";
export const ADMINS_ROLE_BY_ID = "/admin/administrators/roles";
export const SUGGEST_SUPERSTAR = "/admin/suggestions/stars";
export const ACCOUNT_VERIFICATION_STATUS = "/admin/users/verifications";
export const ACCOUNT_VERIFICATION_STATUS_BY_ID =
  "/admin/users/verifications/{id}";
export const API_GET_PAYOUT_REQUESTS = "/admin/payout/requests";
export const API_EDIT_PAYOUT_REQUEST = "/admin/payout/requests/{id}";
export const API_EDIT_PAYOUT_METHOD = "/admin/users/payout_credentials";
export const API_UPDATE_PAYOUT_REQUEST = "/admin/payout/requests/{id}";
export const API_PROCESS_PAYMENT = "admin/payout/processpayment";
export const API_GET_PAYOUT_STATUSES = "/admin/payout/status";
export const API_ADD_PAYOUT_STATUS = "/admin/payout/status";
export const API_EDIT_PAYOUT_STATUS = "/admin/payout/status/{id}";
export const API_UPDATE_PAYOUT_STATUS = "/admin/payout/status/{id}";
export const API_DELETE_PAYOUT_STATUS = "/admin/payout/status/{id}";
export const API_GET_PAYOUT_STATEMENT = "/admin/payout/statement/{id}";
export const API_GET_APP_REVIEW_REQUESTS = "/admin/payout/appreview/list";
export const API_PUT_APP_REVIEW_REQUESTS = "/admin/payout/appreview";
export const API_MANUALPP = "/admin/users/manualpp";
export const API_MANUALCOIN = "/admin/coins/admin-added";
export const DELETE_HUDDLE_LIST = "/admin/huddles/delete_list";

export const API_FLAX_REQUEST_POST = "/admin/payout/requests";

export const API_SEND_NOTIFICATION = "/admin/payout/requests/payout_email";

export const API_GET_FLAX_TRANSFER_HISTORY = "/admin/deals/flaxtransferhistory";
export const API_GET_FLAX_TRANSFER_HISTORY_BY_USER_ID =
  "/admin/deals/flaxtransferhistory?user_id={userId}";
export const API_GET_FLAX_TRANSFER_PURPOSES =
  "/admin/deals/flaxtransferpurposes";
export const API_POST_FLAX_TRANSFER_PURPOSE =
  "/admin/deals/flaxtransferpurposes";
export const API_DELETE_FLAX_TRANSFER_PURPOSE =
  "/admin/deals/flaxtransferpurposes/{id}";
export const API_GET_USER_EMPOWERMENT = "/admin/users/id/empowerment";

export const API_GET_FLAX_PACKAGES = "/admin/flax/flaxpurchasesettings";

export const API_GET_FLAX_PACKAGES_BY_ID =
  "/admin/flax/flaxpurchasesettings/{id}";

export const API_GET_FLAX_PURCHASE_HISTORY = "/admin/flax/flaxpurchasehistory";

export const API_GET_GIFT_PACKAGES = "/admin/gift/managepoints";

export const API_GET_GIFT_PACKAGES_BY_ID = "/admin/gift/managepoints/{id}";
export const API_UPDATE_GIFT_PACKAGES =
  "/admin/gift/managepoints/{gift_identifier}";

export const API_GET_GIFT_TRANSFER_HISTORY = "/admin/gift/transferhistory";

export const API_GET_FLASH_CATEGORIES = "/admin/flash/categories";

export const API_DELETE_FLASH_CATEGORIES = "/admin/flash/categories/{id}";

export const API_GET_ANNOUNCEMENTS = "/admin/notifications/broadcast";
export const API_GET_ANNOUNCEMENTS_BY_ID =
  "/admin/notifications/broadcast/{id}";
export const API_GET_PAYMENT_METHODS = "/admin/payment/methods";
export const API_GET_GLOBAL_PAYMENT_SYSTEMS =
  "/admin/flax/sellflax/paymentforms";

export const API_GET_USER_FLAX_OR_COINS_STATEMENTS =
  "/admin/users/flaxcoinstatements?user_id=";
export const API_PAYOUT_ELIGIBILITY_SETTING = "/admin/payout/payoutconditions";
export const API_UPDATE_PAYOUT_ELIGIBILITY_SETTING =
  "/admin/payout/payoutconditions/{id}";

export const API_GET_CHALLENGES_HISTORY = "/admin/challenegs/history";


export const FLIX_PURCHASE_SUMMARY = "FLIX_PURCHASE_SUMMARY"
export const COINS_PURCHASE_SUMMARY = "COINS_PURCHASE_SUMMARY"