import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";

import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";

import { huddleCategoryColumnData } from "../../../helpers/columns";
import { checkUserPermission } from "../../../helpers/utils";
import {
  deleteHuddleCategories,
  getHuddlesCategories,
} from "../../../store/huddles/actions";
import {
  deleteReportedUserCategories,
  getReportedUsersCategories,
} from "../../../store/reportedUser/action";

const ReportedUsersCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.reportedUser);
  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;
  const isDeleteable = userPermissions?.delete || false;

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();

  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["Active"],
    page_count: 50,
    keyword: "",
  };
  const [request, setRequest] = useState(basicRequest);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Reported users",
          "Reports Category"
        )
      );
    }
  }, [loginData]);

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error]);

  const scrollToAlertMessage = () => {
    if (data?.error) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  // useEffect(() => {
  //   request && dispatch(getHuddlesCategories(request));
  // }, [request]);

  useEffect(() => {
    request && dispatch(getReportedUsersCategories(request));
  }, [request]);

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to delete 
        this huddle category?`,
      type: "delete",
    });
  };

  const blockPromptHandler = (id, status) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to ${
        status === "Blocked" ? "unblock" : "block"
      } this user?`,
      type: "block",
      status: status,
    });
  };

  const okHandler = () => {
    promptMessage.type === "delete"
      ? dispatch(
          deleteReportedUserCategories({
            id: promptMessage.id,
            callback: () => dispatch(getHuddlesCategories(request)),
          })
        )
      : promptMessage.status === "Blocked"
      ? dispatch(
          deleteHuddleCategories({
            data: { id: promptMessage.id, blocked: false },
            callback: () => dispatch(getHuddlesCategories(request)),
          })
        )
      : dispatch(
          deleteHuddleCategories({
            data: { id: promptMessage.id, blocked: true },
            callback: () => dispatch(getHuddlesCategories(request)),
          })
        );
  };

  const formatUserData = useCallback(() => {
    return (
      data?.reportedUserCategories?.report_categories?.length &&
      data?.reportedUserCategories?.report_categories?.map(
        (category, index) => ({
          ...category,
          no: (request?.page - 1) * request?.page_count + index + 1,

          category: (
            <div data-tag="allowRowEvents" title={category.category}>
              {category.category}
            </div>
          ),
          actions: (
            <div className="cust-table-actions-wrap">
              <button
                className=" color-violet action-btn"
                title="Edit"
                onClick={() =>
                  history.push({
                    pathname: `/huddle-reported-user-category-edit/${category.id}`,
                    state: { from: "categoryList" },
                  })
                }
                // disabled={!isEditable}
              >
                <img
                  src={Edit_icon}
                  alt="Edit"
                  // style={{
                  //   opacity: !isEditable ? "50%" : "",
                  //   cursor: !isEditable ? "not-allowed" : "",
                  // }}
                />
              </button>

              {/* <button
                onClick={() => deletePromptHandler(category.id)}
                className=" color-red action-btn"
                title="Delete"
                disabled={!isDeleteable}
              >
                <img
                  src={Delete_icon}
                  alt="Delete"
                  style={{
                    opacity: !isDeleteable ? "50%" : "",
                    cursor: !isDeleteable ? "not-allowed" : "",
                  }}
                />
              </button> */}
            </div>
          ),
        })
      )
    );
  }, [data?.categories?.categories, request, isDeleteable, isEditable]);

  //memoized user data
  // const userData = useMemo(() => formatUserData(), [formatUserData]);

  const userData =
    data?.reportedUserCategories?.report_categories?.length &&
    data?.reportedUserCategories?.report_categories?.map((category, index) => ({
      ...category,
      no: (request?.page - 1) * request?.page_count + index + 1,

      category: (
        <div data-tag="allowRowEvents" title={category.category_text}>
          {category.category_text}
        </div>
      ),
      actions: (
        <div className="cust-table-actions-wrap">
          <button
            className=" color-violet action-btn"
            title="Edit"
            onClick={() =>
              history.push({
                pathname: `/reported-user-category-edit/${category.id}`,
                state: { from: "categoryList" },
              })
            }
            // disabled={!isEditable}
          >
            <img
              src={Edit_icon}
              alt="Edit"
              style={{
                opacity: !isEditable ? "50%" : "",
                cursor: !isEditable ? "not-allowed" : "",
              }}
            />
          </button>

          <button
            onClick={() => deletePromptHandler(category.id)}
            className=" color-red action-btn"
            title="Delete"
            disabled={!isDeleteable}
          >
            <img
              src={Delete_icon}
              alt="Delete"
              style={{
                opacity: !isDeleteable ? "50%" : "",
                cursor: !isDeleteable ? "not-allowed" : "",
              }}
            />
          </button>
        </div>
      ),
    }));

  const addNewClickHandler = () => {
    history.push("/reported-user-category-add");
  };

  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs
            title="Tables"
            breadcrumbItem="Reported User Categories"
          />
          {data?.error && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            </div>
          )}
          <div className="container">
            <Datatable
              tableID={"adminTable"}
              columns={huddleCategoryColumnData}
              rows={userData}
              setRequest={setRequest}
              request={request}
              totalRecords={data.adminData?.total}
              search
              add
              addNewClickHandler={addNewClickHandler}
              addNewLabel={t("Admins.addAdmin.addBtn")}
              searchTerm={data?.request?.keyword}
              loading={data?.loading}
              tableCardClassName={"snoTable"}
            />
          </div>
        </Row>
      </Container>

      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default ReportedUsersCategory;
