import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Card, Col, Container, Row, UncontrolledAlert } from "reactstrap";
import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Lottie from "lottie-react";
import { useDispatch, useSelector } from "react-redux";
import {
  addGiftPackages,
  getGiftPackages,
  resetGiftPackageErrors,
} from "../../../store/GiftPackages/actions";
import Loader from "../../../components/Common/Loader";
import { isGif, isLottieAnimation } from "../../../helpers/utils";
import { getGiftCategories } from "../../../store/GiftCategories/actions";
// import DatePicker from "react-date-picker";
// import "react-date-picker/dist/DatePicker.css";
// import "react-calendar/dist/Calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddGiftPackage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [giftIdentifier, setGiftIdentifier] = useState("");
  const [giftName, setGiftName] = useState("");
  const [points, setPoints] = useState(0);
  const [flix, setFlix] = useState(0);
  const [giftType, setGiftType] = useState("personal");
  const [giftDescription, setGiftDescripton] = useState("");
  const [animationFile, setAnimationFile] = useState(null);
  const [animationData, setAnimationData] = useState(null);

  const [imageFile, setImageFile] = useState(null);
  const [videoAnimationFile, setVideoAnimationFile] = useState(null);
  const [androidVideoAnimationFile, setAndroidVideoAnimationFile] =
    useState(null);
  const [message, setMessage] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [giftCategory, setGiftCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [date, setDate] = useState(null);
  const [giftCategoryIdentifier, setGiftCategoryIdentifier] = useState("");
  const [giftList, setGiftList] = useState([
    {
      gift_name: "",
      language: "",
      gift_description: "",
    },
  ]);
  const [validationMessage, setValidationMessage] = useState(null);
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const data = useSelector((state) => state.giftPackages);

  const categoryData = useSelector((state) => state.GiftCategories);
  console.log("gift category", giftCategory);

  useEffect(() => {
    dispatch(getGiftPackages());
    dispatch(getGiftCategories());
    return () => {
      dispatch(resetGiftPackageErrors());
    };
  }, []);
  console.log("cdata", categoryData);

  useEffect(() => {
    scrollToAlertMessage();
  }, [validationMessage]);
  const scrollToAlertMessage = () => {
    if (validationMessage) {
      let element = warningAlertRef?.current;
      let top = element?.offsetTop;
      let height = element?.getBoundingClientRect().height;
      pageWrapRef?.current?.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {
    console.log("cattt", selectedCategory);
    for (let i = 0; i < giftList?.length; i++) {
      if (
        giftList[i]?.gift_name === "" ||
        giftList?.length < 9 ||
        (selectedCategory !== "FLiX" && points <= 0) ||
        (selectedCategory === "FLiX" && flix <= 0) ||
        giftName === "" ||
        validationMessage !== null ||
        message !== null ||
        (giftType === "personal" && giftCategory === "") ||
        (giftType === "bank" && giftCategory === "") ||
        (giftType === "personal" &&
          giftCategoryIdentifier !== "common" &&
          date === null)
      ) {
        setDisabled(true);
        break;
      } else {
        setDisabled(false);
      }
      if (giftList[i] == undefined) {
        setDisabled(true);
        break;
      } else {
        setDisabled(false);
      }
    }
  }, [
    points,
    selectedCategory,
    flix,
    message,
    giftName,
    giftList,
    validationMessage,
    giftType,
    giftCategory,
    date,
    giftDescription,
    giftCategoryIdentifier,
  ]);

  useEffect(() => {
    if (giftType !== "vip" || giftType !== "personal") {
      setGiftList((prevList) =>
        prevList.map((item) => ({
          ...item,
          gift_description: "",
        }))
      );
    }
  }, [giftType]);

  useEffect(() => {
    if (giftType !== "personal" || giftType !== "bank") {
      setGiftCategoryIdentifier("");
      setDate(null);
      setCategoryId("");
    }
  }, [giftType]);

  const loadAnimationData = async () => {
    if (!animationFile) {
      // console.error("No animation file provided.");
      return;
    }

    if (!(animationFile instanceof Blob)) {
      // console.error("Invalid animation file type.");
      setMessage("Please Upload an Animation Json File");
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      try {
        if (isLottieAnimation(JSON?.parse(e.target.result))) {
          setAnimationData(JSON?.parse(e.target.result));

          setMessage(null);
        } else {
          setMessage("Please Upload an Animation Json File");
        }
      } catch (error) {
        setAnimationData(null);
        setMessage("Please Upload an Animation Json File ");
        console.error("Error parsing animation data:", error);
      }
    };

    fileReader.readAsText(animationFile);
  };

  const handleReportUsersChangeOrAdd = (index, key, value, language) => {
    const list = [...giftList];

    if (list[index]) {
      list[index][key] = value && value?.trim();

      list[index]["language"] = language;
      setGiftList(list);
    } else {
      list[index] = {
        gift_name: key === "gift_name" ? value && value?.trim() : "",
        gift_description:
          key === "gift_description" ? value && value?.trim() : "",
        language: language,
      };
      setGiftList(list);
    }
  };

  const handleDateChange = (selectedDate) => {
    if (
      selectedDate &&
      giftType === "personal" &&
      giftCategoryIdentifier === "date"
    ) {
      console.log("selected date 1", selectedDate);
      const adjustedDate = new Date(selectedDate);
      const localDate = adjustedDate.toLocaleDateString("en-CA"); // ISO-like format (YYYY-MM-DD)
      setDate(localDate);
    } else {
      setDate(null);
    }
  };
  console.log("set date", date);

  useEffect(() => {
    giftCategoryIdentifier !== "date" && setDate(null);
  }, [giftCategoryIdentifier]);

  const handleSubmit = (e) => {
    const formData = new FormData();
    animationFile && formData?.append("lottie_file", animationFile);
    imageFile && formData?.append("gift_image", imageFile);
    videoAnimationFile && formData?.append("animation", videoAnimationFile);
    androidVideoAnimationFile &&
      formData?.append("animation_android", androidVideoAnimationFile);
    if (selectedCategory === "FLiX") {
      formData?.append(
        "data",
        JSON.stringify({
          flix: flix,
          gift_identifier: `${(giftName + "-" + giftType + "-" + points)
            ?.toLowerCase()
            ?.replace(/\s/g, "-")}`,
          gift_json: null,
          gift_type: giftType && giftType?.trim(),
          gift_category_id: categoryId,
          special_occasion_date: date?.split("T")[0] || null,
          gift_data: giftList,
        })
      );
    } else {
      formData?.append(
        "data",
        JSON.stringify({
          points: points,
          gift_identifier: `${(giftName + "-" + giftType + "-" + points)
            ?.toLowerCase()
            ?.replace(/\s/g, "-")}`,
          gift_json: null,
          gift_type: giftType && giftType?.trim(),
          gift_category_id: categoryId,
          special_occasion_date: date?.split("T")[0] || null,
          gift_data: giftList,
        })
      );
    }
    // formData.append("gift_data", JSON.stringify(giftList));

    dispatch(
      addGiftPackages({
        packageData: formData,
        callback: () => {
          history.push("/gift-packages");
        },
      })
    );
  };
  useEffect(() => {
    if (giftType !== "fun") {
      loadAnimationData();
    } else {
      setVideoAnimationFile(null);
      setAndroidVideoAnimationFile(null);
      setMessage(null);
    }
  }, [animationFile, giftType]);
  useEffect(() => {
    if (giftType === "fun" && animationFile) {
      if (!isGif(animationFile)) {
        setMessage("please upload a Gif File");
      } else {
        setMessage(null);
      }
    }
  }, [animationFile, giftType]);

  const handleFileChange = (e) => {
    setMessage(null);
    const selectedFile = e.target.files[0];
    setAnimationFile(selectedFile);
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    setImageFile(selectedFile);
  };
  const handleAnimationVideoChange = (e) => {
    const selectedFile = e.target.files[0];
    setVideoAnimationFile(selectedFile);
  };
  const handleAndroidAnimationVideoChange = (e) => {
    const selectedFile = e.target.files[0];
    setAndroidVideoAnimationFile(selectedFile);
  };
  console.log("catt", categoryData);
  useEffect(() => {
    if (categoryData?.categories.length > 0 && giftType === "personal") {
      const initialCategory = categoryData.categories[0];
      setSelectedCategory(initialCategory.english);
      setCategoryId(initialCategory.id);
      setGiftCategory(initialCategory.english);
      setGiftCategoryIdentifier(initialCategory?.category_identifier);
    } else if (categoryData?.categories.length > 0 && giftType === "bank") {
      const filteredCategories = categoryData?.categories?.filter(
        (item) => item.english === "COiNS"
      );
      if (filteredCategories) {
        console.log("fil", filteredCategories);
        setSelectedCategory(filteredCategories[0]?.english);
        setCategoryId(filteredCategories[0]?.id);
        setGiftCategory(filteredCategories[0]?.english);
        setGiftCategoryIdentifier(filteredCategories[0]?.category_identifier);
      }
    } else {
      setSelectedCategory("");
      setCategoryId("");
      setGiftCategory("");
      setGiftCategoryIdentifier("");
    }
  }, [
    categoryData,
    setCategoryId,
    setGiftCategory,
    setGiftCategoryIdentifier,
    giftType,
  ]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedCategory = categoryData.categories.find(
      (category) => category.english === selectedValue
    );

    if (selectedCategory) {
      setSelectedCategory(selectedValue);
      setCategoryId(selectedCategory.id);
      setGiftCategory(selectedValue);
      setGiftCategoryIdentifier(selectedCategory?.category_identifier);
    }
  };

  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <BackButton
        label="Gift Packages"
        handleClick={() => history.push("/gift-packages")}
      />
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Add Package" />
          {validationMessage && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color={"danger"}
                className="alert-dismissible fade show"
                role="alert"
              >
                {validationMessage}
              </UncontrolledAlert>
            </div>
          )}
          {data?.error && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color={"danger"}
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            </div>
          )}
          <Col xl="10">
            <Card className="mb-0 p-4 my-4">
              <div className="text-muted">
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleSubmit(e, v);
                  }}
                >
                  <div
                    className="table-responsive mt-4"
                    style={{ overflowX: "hidden" }}
                  >
                    <div className="d-flex mb-4">
                      {/* <div className="col-md-6">
                        <label className="col-md-8 col-form-label">
                          Enter Gift Identifier
                          <span className="mandatory">*</span>
                        </label>
                        <div className="col-md-8 dobWrapper">
                          <AvInput
                            name="adminName"
                            type="text"
                            className="form-control mb-4 "
                            // value={}
                            id="name-input"
                            onChange={(e) => {
                              setGiftIdentifier(e.target.value);
                            }}
                            placeholder="Enter Gift Identifier..."
                          />
                        </div>
                      </div> */}
                      <div className="col-md-6">
                        <label className="col-md-8 col-form-label">
                          Enter Gift Name(In English)
                          <span className="mandatory">*</span>
                        </label>
                        <div className="col-md-8 dobWrapper">
                          <AvInput
                            name="adminName2"
                            type="text"
                            className="form-control mb-4 "
                            // value={}
                            id="name-input"
                            onChange={(e) => {
                              setGiftName(e.target.value);
                            }}
                            placeholder="Enter Gift Name"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <label className="col-md-8 col-form-label">
                          Gift Type
                          <span className="mandatory">*</span>
                        </label>
                        <div className="col-md-8 dobWrapper">
                          <select
                            className="form-control mb-4"
                            id="huddleType"
                            // ref={ref}
                            onChange={(e) => {
                              setGiftType(e.target.value);
                            }}
                          >
                            <option value="personal">PERSONAL</option>
                            <option value="fun">FUN</option>
                            <option value="vip">VIP</option>
                            <option value="bank">BANK</option>
                            {/* <option value="birthday">BIRTHDAY</option> */}
                          </select>
                        </div>
                      </div>
                    </div>

                    {(giftType === "personal" || giftType === "bank") && (
                      <div className="d-flex mb-4">
                        {categoryData?.categories?.length > 0 && (
                          <div className="col-md-6">
                            <label className="col-md-8 col-form-label">
                              Gift Categories
                              {/* <span className="mandatory">*</span> */}
                            </label>
                            <div className="col-md-8 dobWrapper">
                              <select
                                className="form-control mb-4   "
                                id="huddleType"
                                value={selectedCategory}
                                onChange={handleChange}
                              >
                                {categoryData?.categories
                                  .filter((category) => {
                                    // Only include "COINS" and "FLIX" if giftType is "bank"
                                    if (giftType === "bank") {
                                      return (
                                        category?.english === "COiNS" ||
                                        category?.english === "FLiX"
                                      );
                                    }
                                    // Exclude "COINS" and "FLIX" for other giftType values
                                    return (
                                      category?.english !== "COiNS" &&
                                      category?.english !== "FLiX"
                                    );
                                  })
                                  .map((category) => (
                                    <option
                                      key={category?.id}
                                      className=""
                                      value={category?.english}
                                    >
                                      {category?.english}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        )}

                        {giftCategoryIdentifier === "date" && (
                          <div className="col-md-6">
                            <label className="col-md-8 col-form-label">
                              Select Day
                              <span className="mandatory">*</span>
                            </label>
                            <div className="col-md-8 dobWrapper">
                              <DatePicker
                                onChange={handleDateChange}
                                selected={date ? new Date(date) : null}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="d-flex mb-4">
                      {(giftType === "vip" || giftType === "personal") && (
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Gift Description
                            {/* <span className="mandatory">*</span> */}
                          </label>
                          <div className="col-md-9 dobWrapper">
                            <textarea
                              name="adminName3"
                              type="text"
                              className="form-control mb-4 "
                              // value={}
                              id="name-input"
                              onChange={(e) => {
                                setGiftDescripton(e.target.value);
                              }}
                              placeholder="Enter Gift Description"
                            />
                          </div>
                        </div>
                      )}

                      {selectedCategory === "FLiX" ? (
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter FLiX
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName4"
                              type="number"
                              className="form-control mb-4 "
                              value={flix}
                              id="name-input"
                              onChange={(e) => {
                                setFlix(e.target.value);
                              }}
                              placeholder="Enter FLiX"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter COiNS
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName4"
                              type="number"
                              className="form-control mb-4 "
                              value={points}
                              id="name-input"
                              onChange={(e) => {
                                setPoints(e.target.value);
                              }}
                              placeholder="Enter COiNS"
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="d-flex mb-4">
                      {giftType !== "fun" ? (
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Upload Gift Animation (Lottie)
                            {/* <span className="mandatory">*</span> */}
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName5"
                              type="file"
                              className="form-control mb-4 "
                              accept=".json"
                              // value={animationFile && animationFile}
                              id="name-input"
                              onChange={handleFileChange}
                            />
                            {/* {animationData && (
                            <Lottie
                              style={{
                                width: "50px",
                                height: "50px",
                              }}
                              animationData={animationData && animationData}
                              // href={result?.gift_url}
                            />
                          )} */}
                            {message && (
                              <p
                                style={{
                                  color: "red",
                                }}
                              >
                                {" "}
                                {message}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Upload Gift Animation (in Gif)
                            {/* <span className="mandatory">*</span> */}
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName5"
                              type="file"
                              className="form-control mb-4 "
                              accept=".gif"
                              // value={animationFile && animationFile}
                              id="name-input"
                              onChange={handleFileChange}
                            />
                            {/* {animationData && (
                            <Lottie
                              style={{
                                width: "50px",
                                height: "50px",
                              }}
                              animationData={animationData && animationData}
                              // href={result?.gift_url}
                            />
                          )} */}
                            {message && (
                              <p
                                style={{
                                  color: "red",
                                }}
                              >
                                {" "}
                                {message}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="d-flex mb-4">
                      <div className="col-md-6">
                        <label className="col-md-8 col-form-label">
                          Upload Gift Image
                          {/* <span className="mandatory">*</span> */}
                        </label>
                        <div className="col-md-8 dobWrapper">
                          <AvInput
                            name="adminName6"
                            type="file"
                            className="form-control mb-4 "
                            accept="image/*"
                            id="name-input"
                            onChange={handleImageChange}
                          />
                        </div>
                      </div>
                    </div>
                    {(giftType === "personal" ||
                      giftType === "vip" ||
                      giftType === "bank") && (
                      <>
                        <div className="d-flex mb-4">
                          <div className="col-md-6">
                            <label className="col-md-8 col-form-label">
                              Upload Gift Animation Video (For IOS)
                              {/* <span className="mandatory">*</span> */}
                            </label>
                            <div className="col-md-8 dobWrapper">
                              <AvInput
                                name="adminName7"
                                type="file"
                                className="form-control mb-4 "
                                accept="video/*"
                                id="name-input"
                                onChange={handleAnimationVideoChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mb-4">
                          <div className="col-md-6">
                            <label className="col-md-8 col-form-label">
                              Upload Gift Animation Video (For Android)
                              {/* <span className="mandatory">*</span> */}
                            </label>
                            <div className="col-md-8 dobWrapper">
                              <AvInput
                                name="adminName7"
                                type="file"
                                className="form-control mb-4 "
                                accept="video/*"
                                id="name-input"
                                onChange={handleAndroidAnimationVideoChange}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {data?.giftPackages &&
                      data?.giftPackages?.languages?.length &&
                      data?.giftPackages?.languages?.map((language, i) =>
                        language == "english" ? (
                          <div key={i} className="col-md-12">
                            <div className="d-flex mx-1">
                              <label className="col-md-2 lang-font col-form-label">
                                {`${language
                                  ?.slice(0, 1)
                                  .toUpperCase()}${language?.slice(1)}`}
                              </label>
                              <div className="col-md-4">
                                <div className="col-md-8 dobWrapper">
                                  <AvInput
                                    name="adminName8"
                                    type="text"
                                    className="form-control mb-4 "
                                    // value={}
                                    id="name-input"
                                    onChange={(e) => {
                                      handleReportUsersChangeOrAdd(
                                        i,
                                        "gift_name",
                                        e.target.value,
                                        language
                                      );
                                    }}
                                    placeholder="Enter Gift Name"
                                  />
                                </div>
                              </div>
                              {(giftType === "vip" ||
                                giftType === "personal") && (
                                <div className="col-md-8">
                                  <div className="col-md-8 dobWrapper">
                                    <AvInput
                                      name="adminName9"
                                      type="text"
                                      className="form-control mb-4 "
                                      // value={}
                                      id="name-input"
                                      onChange={(e) => {
                                        handleReportUsersChangeOrAdd(
                                          i,
                                          "gift_description",
                                          e.target.value,
                                          language
                                        );
                                      }}
                                      placeholder="Enter Gift Description"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div key={i} className=" d-flex mx-1">
                            <label className="col-md-2 lang-font col-form-label">
                              {`${language
                                ?.slice(0, 1)
                                .toUpperCase()}${language?.slice(1)}`}
                            </label>{" "}
                            <div className="col-md-4">
                              {/* <label className="col-md-8 col-form-label">
                              Enter Category Text
                              <span className="mandatory">*</span>
                            </label> */}
                              <div className="col-md-8 dobWrapper">
                                <AvInput
                                  name="adminName10"
                                  type="text"
                                  className="form-control mb-4 "
                                  // value={}
                                  id="name-input"
                                  onChange={(e) => {
                                    handleReportUsersChangeOrAdd(
                                      i,
                                      "gift_name",
                                      e.target.value,
                                      language
                                    );
                                  }}
                                  placeholder="Enter Gift Name"
                                />
                              </div>
                            </div>
                            {(giftType === "vip" ||
                              giftType === "personal") && (
                              <div className="col-md-8">
                                {/* <label className="col-md-8 col-form-label">
                              Enter Category Text
                              <span className="mandatory">*</span>
                            </label> */}
                                <div className="col-md-8 dobWrapper">
                                  <AvInput
                                    name="adminName11"
                                    type="text"
                                    className="form-control mb-4 "
                                    // value={}
                                    id="name-input"
                                    onChange={(e) => {
                                      handleReportUsersChangeOrAdd(
                                        i,
                                        "gift_description",
                                        e.target.value,
                                        language
                                      );
                                    }}
                                    placeholder="Enter Gift Description"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      )}

                    <div className="mt-4">
                      <button
                        type="submit"
                        // disabled={isButtonDisabled}
                        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                        // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                        disabled={disabled}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </AvForm>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default AddGiftPackage;
