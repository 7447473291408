import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { Col, Card, Row, UncontrolledAlert } from "reactstrap";

import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Button from "../../../components/UI/Button";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";
import PreviewTemplate from "./EditTemplate/PreviewTemplate";

import EmailTemplate from "./EditTemplate/EmailTemplate";

import {
  addTemplate,
  editTemplate,
  updateTemplate,
} from "../../../store/settings/templates/actions";

const AddTemplateSettings = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.templateSettings);

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [title, setTitle] = useState("");
  const [templateType, setTemplateType] = useState("email");
  const [language, setLanguage] = useState("english");
  const [identifier, setIdentifier] = useState("");

  const [subject, setSubject] = useState("");
  const [body, setBody] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [backToListing, setBackToListing] = useState(false);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  /*useEffect(() => {
    dispatch(editTemplate(match.id));
  }, [match.id]);*/
  useEffect(() => {
    // Check if all values are filled
    const allValuesFilled =
      title && templateType && language && identifier && body && subject;

    // Update the buttonDisabled state
    setButtonDisabled(!allValuesFilled);
  }, [title, templateType, language, identifier]);

  useEffect(() => {
    backToListing && history.push("/settings-template");
  }, [backToListing]);

  function tog_center() {
    setOpenPreviewModal(!openPreviewModal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const redirectMethod = () => {
    setBackToListing(true);
  };

  const confirmBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
  };

  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };

  const okHandler = () => {
    promptMessage.type === "back" && redirectMethod();
  };
  const templateData = {
    title,
    template_type: templateType,
    language,
    identifier: identifier.toLowerCase(),
    subject,
    body,
  };
  const handleSubmit = () => {
    dispatch(
      addTemplate({
        data: templateData,
        callback: () => {
          history.push("/settings-template");
        },
      })
    );
  };

  return (
    <div className="page-content">
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            return confirmBrowserBack();
          }
        }}
        when={formChanged}
      />
      <BackButton
        label={t("Sidebar.Item13")}
        handleClick={() => (formChanged ? confirmBack() : redirectMethod())}
      />
      <Breadcrumbs
        title="Tables"
        breadcrumbItem={t("settings.AddPolicyTitle")}
      />

      <Col xl="12">
        <Card className="p-4">
          <div>
            {templateType === "email" && (
              <EmailTemplate
                subject={subject}
                setSubject={setSubject}
                title={title}
                setTitle={setTitle}
                templateType={templateType}
                setTemplateType={setTemplateType}
                language={language}
                setLanguage={setLanguage}
                identifier={identifier}
                setIdentifier={setIdentifier}
                setFormChanged={setFormChanged}
                body={body}
                addTemp
                setBody={setBody}
                setButtonDisabled={setButtonDisabled}
                previewButtonHandler={() => tog_center()}
              />
            )}
            {templateType === "text" && (
              <>
                <Row className="col-md-12 template-subject-wrap">
                  <div className="col-md-12">
                    <label className="col-form-label">
                      {t("settings.editEmailSubject")}
                      <span className="mandatory">*</span>
                    </label>
                    <div className="">
                      <input
                        value={subject}
                        onChange={(e) => {
                          e.target.value
                            ? setButtonDisabled(false)
                            : setButtonDisabled(true);
                          setSubject(e.target.value);
                          setFormChanged(true);
                        }}
                        name="templateTitle"
                        placeholder={t("settings.editEmailSubjectPlaceholder")}
                      />
                    </div>
                  </div>
                </Row>
                <Row className="col-md-12 template-subject-wrap">
                  <div className="col-md-12">
                    <label className="col-form-label">
                      {t("settings.editTemplateLabel")}
                      <span className="mandatory">*</span>
                    </label>

                    <div className="col-md-12">
                      <input
                        value={title}
                        onChange={(e) => {
                          e.target.value
                            ? setButtonDisabled(false)
                            : setButtonDisabled(true);
                          setFormChanged(true);
                          setTitle(e.target.value);
                        }}
                        name="templateTitle"
                        placeholder={t("settings.addTemplateTitlePlaceholder")}
                      />
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-md-6 d-flex justify-content-between">
                    {/* First Select */}
                    <div className="w-100">
                      <label className="col-form-label">
                        {t("settings.templateTypeLabel")}
                        <span className="mandatory">*</span>
                      </label>
                      <select
                        style={{
                          width: "80%",
                          height: "30px",
                        }}
                        className="form-select  ml-4"
                        value={templateType}
                        onChange={(e) => {
                          setTemplateType(e.target.value);
                        }}
                      >
                        <option value="email">E-Mail</option>
                        <option value="sms">SMS</option>
                      </select>
                    </div>

                    {/* Second Select */}
                    <div className="w-100">
                      <label className="col-form-label">
                        {t("settings.templateLanguageLabel")}
                        <span className="mandatory">*</span>
                      </label>
                      <select
                        style={{
                          width: "50%",
                          height: "30px",
                        }}
                        className="form-select  ml-4"
                        value={language}
                        onChange={(e) => {
                          setLanguage(e.target.value);
                        }}
                      >
                        <option value="english">English</option>
                        <option value="arabic">Arabic</option>
                      </select>
                    </div>
                  </div>
                </Row>
                <Row className="col-md-12 template-subject-wrap">
                  <div className="col-md-12">
                    <label className="col-form-label">
                      {t("settings.templateIdentifierLabel")}
                      <span className="mandatory">*</span>
                    </label>

                    <div className="col-md-12">
                      <input
                        value={identifier}
                        onChange={(e) => {
                          e.target.value
                            ? setButtonDisabled(false)
                            : setButtonDisabled(true);
                          setFormChanged(true);
                          setIdentifier(e.target.value);
                        }}
                        name="templateIdentifier"
                        placeholder={t("settings.addTemplateTitlePlaceholder")}
                      />
                    </div>
                  </div>
                </Row>

                <label className="col-form-label">
                  {t("settings.smsTemplate")}
                  <span className="mandatory">*</span>
                </label>
                <textarea
                  id="settings_otp_textarea"
                  name=""
                  rows="10"
                  cols=""
                  onChange={(e) => {
                    e.target.value
                      ? setButtonDisabled(false)
                      : setButtonDisabled(true);
                    setFormChanged(true);
                    setBody(e.target.value);
                  }}
                  value={body}
                />
              </>
            )}
          </div>
          <div className="mt-4 editDocument">
            <Button
              buttonClass="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
              label={t("Common.add")}
              disableBtn={buttonDisabled}
              handleClick={() => handleSubmit()}
            />
          </div>
        </Card>
      </Col>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.editLoading && <Loader darkBg={true} />}

      <PreviewTemplate
        openPreviewModal={openPreviewModal}
        modalToggle={() => tog_center()}
        closeButtonClick={() => setOpenPreviewModal(false)}
        body={body}
      />
    </div>
  );
};

export default AddTemplateSettings;
