import React, { useState } from "react";
import { Input, Modal, Label, FormGroup } from "reactstrap";
import Checkbox from "../../../../components/UI/Checkbox";
import DateTime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";

export const NOTIFICATION_TYPE = {
  SEND_IMMEDIATELY: 'Send Immediately',
  SAVE_DRAFT: "Save As Draft",
  SCHEDULED_NOTIFICATION: "Scheduled Notification"
}
export const NOTIFICATION_STATUS = {
  [NOTIFICATION_TYPE.SAVE_DRAFT] : 'Draft',
  [NOTIFICATION_TYPE.SCHEDULED_NOTIFICATION]: 'Saved',
  [NOTIFICATION_TYPE.SEND_IMMEDIATELY]: undefined
}

const PushNotification = ({
  modal_center,
  setmodal_center,
  tog_center,
  handleSubmit,
  data,
  notification,
  onNotificationChange
}) => {
  const options = Object.keys(NOTIFICATION_TYPE).map(key => NOTIFICATION_TYPE[key]);
  const today = moment();
  const valid = function( current ){
    const now = moment.now();
    return current.isAfter( now );
};


  return (
    <div>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={true}
      >
        <div className="modal-header userList-filter-header">
          <h5 className="modal-title mt-0"> Notification</h5>

          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body  mx-3">
          <form
            className="col-md-12"
            onSubmit={(e, v) => {
              handleSubmit(e, v);
            }}
          >
            <FormGroup>
              <Label>Notification type</Label>
              <Input type="select" style={{textAlign: 'left'}} onChange={(event) => onNotificationChange({target:{name: 'notificationType', value:event.target.value}})} value={notification.notificationType}>
               { options.map( option => <option value={option}>{option}</option>)}
              </Input>
            </FormGroup>
            <FormGroup className="mt-2">
            {
             notification.notificationType === NOTIFICATION_TYPE.SCHEDULED_NOTIFICATION && 
             <FormGroup>
              <Label>Send On</Label>
             <DateTime isValidDate={valid} initialValue={notification.datetime_scheduled} 
             onChange={(e) => {onNotificationChange({target:{name: 'datetime_scheduled', value: e._d}})}} 
             utc={true}/>
             </FormGroup>
            }  
            </FormGroup>
        
            <div className="col-md-12">
              <div className="col-md-12">
                <p
                  htmlFor="example-date-input"
                  className="col-md-10 col-form-label  "
                >
                  Title
                  <span className="mandatory">*</span>
                </p>
                <div className="col-md-12 dobWrapper">
                  <Input
                    name="title"
                    type="text"
                    id="example-date-input"
                    value={notification.title}
                    onChange={onNotificationChange}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <p
                  htmlFor="example-date-input"
                  className="col-md-10 col-form-label"
                >
                  Message
                  <span className="mandatory">*</span>
                </p>
                <div className="col-md-12 dobWrapper">
                  <Input
                    type="textarea"
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="8"
                    name="message"
                    value={notification.message}
                    onChange={onNotificationChange}
                  ></Input>
                </div>               
                <div className="form-check form-switch form-switch-lg col-md-4 enableDocumentSwitchWrap px-0 pt-2 pb-4 ">
                  <div className="">
                    <label
                      className="font-weight-normal"
                      htmlFor="enableDocument"
                    >
                      Push Notification
                    </label>
                  </div>
                  <div className="px-5">
                    <Checkbox
                      checked={notification.push}
                      id={"enableDocument"}
                      name="push"
                      handleChange={(e) => {onNotificationChange({target:{name: 'push', value: e}})}}
                      className="form-check-input "
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer ">
              <button
                type="submit"
                className="btn bgcolor-btn-green"
                // onClick={() => {
                //   tog_center();
                // }}
              >
                Send
                {data?.notification_loading && (
                  <i
                    className="fa fa-spinner fa-spin mx-1"
                    aria-hidden="true"
                  ></i>
                )}
              </button>
              <button
                className="btn btn-light"
                type="button"
                onClick={() => tog_center()}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    
    </div>
  );
};

export default PushNotification;
