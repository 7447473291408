import {
  GET_HUDDLES_CATEGORIES_BEGIN,
  GET_HUDDLES_CATEGORIES_FAIL,
  GET_HUDDLES_CATEGORIES_SUCCESS,
} from "../huddles/actionTypes";
import {
  ADD_PODIUMS_CATEGORIES_BEGIN,
  ADD_PODIUMS_CATEGORIES_FAIL,
  ADD_PODIUMS_CATEGORIES_SUCCESS,
  BLOCK_PODIUMS_BEGIN,
  BLOCK_PODIUMS_FAIL,
  BLOCK_PODIUMS_SUCCESS,
  DELETE_PODIUMS_BEGIN,
  DELETE_PODIUMS_CATEGORIES_BEGIN,
  DELETE_PODIUMS_CATEGORIES_FAIL,
  DELETE_PODIUMS_CATEGORIES_SUCCESS,
  DELETE_PODIUMS_FAIL,
  DELETE_PODIUMS_SUCCESS,
  EDIT_PODIUMS_BEGIN,
  EDIT_PODIUMS_CATEGORIES_BEGIN,
  EDIT_PODIUMS_CATEGORIES_FAIL,
  EDIT_PODIUMS_CATEGORIES_SUCCESS,
  EDIT_PODIUMS_FAIL,
  EDIT_PODIUMS_SUCCESS,
  GET_CHALLENGES_HISTORY_BEGIN,
  GET_CHALLENGES_HISTORY_BY_USER_ID_BEGIN,
  GET_CHALLENGES_HISTORY_BY_USER_ID_FAIL,
  GET_CHALLENGES_HISTORY_BY_USER_ID_SUCCESS,
  GET_CHALLENGES_HISTORY_FAIL,
  GET_CHALLENGES_HISTORY_SUCCESS,
  GET_PODIUMS_BEGIN,
  GET_PODIUMS_BY_ID_BEGIN,
  GET_PODIUMS_BY_ID_FAIL,
  GET_PODIUMS_BY_ID_SUCCESS,
  GET_PODIUMS_CATEGORIES_BEGIN,
  GET_PODIUMS_CATEGORIES_BY_ID_BEGIN,
  GET_PODIUMS_CATEGORIES_BY_ID_FAIL,
  GET_PODIUMS_CATEGORIES_BY_ID_SUCCESS,
  GET_PODIUMS_CATEGORIES_FAIL,
  GET_PODIUMS_CATEGORIES_SUCCESS,
  GET_PODIUMS_FAIL,
  GET_PODIUMS_SUCCESS,
  GET_SPECIFIC_CHALLENGE_DETAILS_BEGIN,
  GET_SPECIFIC_CHALLENGE_DETAILS_FAIL,
  GET_SPECIFIC_CHALLENGE_DETAILS_SUCCESS,
  RESET_PODIUMS_ERRORS,
  STORE_PODIUM_REQUESTS,
} from "./actionTypes";

export const INIT_STATE = {
  categories: {},
  categoryData: {},
  loading: false,
  error: "",
  podiumData: {},
  podiums: {},
  podiumreportedUserCategoryData: {},
  podiumreportedUserCategories: {},
  challengesData: {},
  challengesDataByUser: {},
  specificChallengeDetails: {},
  // huddleMessages: "",
  // participants: {},
  request: {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    page_count: 25,
    per_page: 25,
    keyword: "",
    success: "",
  },
};

const Podiums = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PODIUMS_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
        categories: {},
        huddleMessages: "",
      };
    case GET_PODIUMS_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
        error: "",
      };
    case GET_PODIUMS_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        categories: {},
        error: action.payload,
      };

    case GET_PODIUMS_CATEGORIES_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        categoryData: {},
        huddleMessages: "",
      };
    case GET_PODIUMS_CATEGORIES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryData: action.payload,
        error: "",
      };
    case GET_PODIUMS_CATEGORIES_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        categoryData: {},
        error: action.payload,
      };
    case EDIT_PODIUMS_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_PODIUMS_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case EDIT_PODIUMS_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_PODIUMS_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_PODIUMS_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case ADD_PODIUMS_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_PODIUMS_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PODIUMS_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case DELETE_PODIUMS_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESET_PODIUMS_ERRORS: {
      return {
        ...state,
        error: "",
        success: "",
      };
    }

    case GET_PODIUMS_BEGIN:
      return {
        ...state,
        loading: true,
        podiums: {},
      };
    case GET_PODIUMS_SUCCESS:
      return {
        ...state,
        loading: false,
        podiums: action.payload,
        error: "",
      };
    case GET_PODIUMS_FAIL:
      return {
        ...state,
        loading: false,
        podiums: {},
        error: action.payload,
      };
    case STORE_PODIUM_REQUESTS:
      return {
        ...state,
        request: action?.request,
      };

    case GET_PODIUMS_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        podiumData: {},
        podiumMessages: "",
      };
    case GET_PODIUMS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        podiumData: action.payload,
        error: "",
      };
    case GET_PODIUMS_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        podiumData: {},
        error: action.payload,
      };

    case EDIT_PODIUMS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_PODIUMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case EDIT_PODIUMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_PODIUMS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PODIUMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: "Podiums have been deleted successfully!",
      };
    case DELETE_PODIUMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_CHALLENGES_HISTORY_BEGIN:
      return {
        ...state,
        loading: true,
        challengesData: {},
      };
    case GET_CHALLENGES_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        challengesData: action.payload,
        error: "",
      };
    case GET_CHALLENGES_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        challengesData: {},
        error: action.payload,
      };
    case GET_CHALLENGES_HISTORY_BY_USER_ID_BEGIN:
      return {
        ...state,
        loading: true,
        challengesDataByUser: {},
      };
    case GET_CHALLENGES_HISTORY_BY_USER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        challengesDataByUser: action.payload,
      };
    case GET_CHALLENGES_HISTORY_BY_USER_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_SPECIFIC_CHALLENGE_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        specificChallengeDetails: {},
      };
    case GET_SPECIFIC_CHALLENGE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        specificChallengeDetails: action.payload,
      };
    case GET_SPECIFIC_CHALLENGE_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default Podiums;
