import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";

const AdminsFilter = ({
  setRequest,
  request,
}) => {
  const statusFilter = request?.status_filter;
  const [status_filter_new, set_status_filter_new] = useState("New");
  const [status_filter_accepted, set_status_filter_accepted] = useState(false);
  const [status_filter_rejected, set_status_filter_rejected] = useState(false);

  const [modal_center, setmodal_center] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    statusFilter?.includes("New")
      ? set_status_filter_new("New")
      : set_status_filter_new(false);
      statusFilter?.includes("Accepted")
      ? set_status_filter_accepted("Accepted")
      : set_status_filter_accepted(false);
      statusFilter?.includes("Rejected")
      ? set_status_filter_rejected("Rejected")
      : set_status_filter_rejected(false);
  }, [statusFilter, modal_center]);

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const filter_clearAll = () => {
    set_status_filter_new(false);
    set_status_filter_accepted(false);
    set_status_filter_rejected(false);
  };

  const handleApply = () => {
    setRequest({
      ...request,
      status_filter: [
        status_filter_new,
        status_filter_accepted,
        status_filter_rejected,
      ].filter((item) => item)
    })
    setmodal_center(false);
  };

  return (
    <div className="user-filterBtn-wrap" data-testid="component-filterPopup">
      <button
        type="button"
        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
        onClick={() => {
          tog_center();
        }}
        data-testid="component-filterButton"
      >
        <i
          style={{ paddingRight: "10px" }}
          className="fa fa-filter"
          aria-hidden="true"
        ></i>
        {t("Admins.filter.title")}
      </button>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={true}
      >
        <div className="modal-header userList-filter-header">
          <h5 className="modal-title mt-0">{t("Admins.filter.title")}</h5>
          <button
            onClick={() => filter_clearAll()}
            className="btn waves-effect waves-light cust_no_shadow userList-filter-clearAll"
          >
            {t("Admins.filter.clear")}
          </button>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body userList-filter-body">
          <div className="userList-filter-SectionWrap">
            <h5 className="userList-filter-SectionTitle">
              {t("AppReview.filter.status")}
            </h5>
            <div className="adminList-filter-checkBoxWrap">
              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={status_filter_new}
                  id="admin_filter_active"
                  onChange={() =>
                    status_filter_new
                      ? set_status_filter_new(!status_filter_new)
                      : set_status_filter_new("New")
                  }
                  checked={status_filter_new}
                />
                <label
                  className="form-check-label"
                  htmlFor="admin_filter_active"
                >
                  {t("AppReview.status.new")}
                </label>
              </div>

              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={status_filter_accepted}
                  id="admin_filter_deleted"
                  onChange={() =>
                    status_filter_accepted
                      ? set_status_filter_accepted(!status_filter_accepted)
                      : set_status_filter_accepted("Accepted")
                  }
                  checked={status_filter_accepted}
                />
                <label
                  className="form-check-label"
                  htmlFor="admin_filter_deleted"
                >
                  {t("AppReview.status.accepted")}
                </label>
              </div>

              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={status_filter_rejected}
                  id="admin_filter_blocked"
                  onChange={() =>
                    status_filter_rejected
                      ? set_status_filter_rejected(!status_filter_rejected)
                      : set_status_filter_rejected("Rejected")
                  }
                  checked={status_filter_rejected}
                />
                <label
                  className="form-check-label"
                  htmlFor="admin_filter_blocked"
                >
                  {t("AppReview.status.rejected")}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              tog_center();
            }}
          >
            {t("Admins.filter.cancel")}
          </button>
          <button
            type="button"
            className="btn bgcolor-btn-green"
            onClick={() => handleApply()}
          >
            {t("Admins.filter.apply")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminsFilter;
