import { GET_FLAX_COINS_CONVERSION_HISTORY_BEGIN, GET_FLAX_COINS_CONVERSION_HISTORY_FAIL, GET_FLAX_COINS_CONVERSION_HISTORY_SUCCESS } from "./actionTypes";

export const INIT_STATE = {
    loading: true,
    error: "",
    flaxCoinsConversionData: {},
    request: {
      sort: "transfered_date",
      sort_order: "desc",
      page: 1,
      status_filter: ["active"],
      page_count: 50,
      keyword: "",
      success: "",
    },
  };
  
  const flaxCoinsConversion = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_FLAX_COINS_CONVERSION_HISTORY_BEGIN:
        return {
          ...state,
          loading: true,
          flaxCoinsConversionData: {},
        };
      case GET_FLAX_COINS_CONVERSION_HISTORY_SUCCESS:
        return {
          ...state,
          loading: false,
          flaxCoinsConversionData: action.payload,
          error: "",
        };
      case GET_FLAX_COINS_CONVERSION_HISTORY_FAIL:
        return {
          ...state,
          loading: false,
          flaxCoinsConversionData: {},
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default flaxCoinsConversion;
  