import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { checkUserPermission, isLottieAnimation } from "../../../helpers/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  deleteGiftPackages,
  getGiftPackages,
} from "../../../store/GiftPackages/actions";
import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";
import Lottie from "lottie-react";
import {
  giftCategoryColumnData,
  giftPackageColumnData,
  postatMusicColumnData,
} from "../../../helpers/columns";
import {
  deleteGiftCategories,
  getGiftCategories,
} from "../../../store/GiftCategories/actions";
import {
  deletePostatMusic,
  getPostatMusic,
} from "../../../store/postatManagement/actions";
import { getPostatSignedCookies } from "../../../store/cookies/action";
import TrackComponent from "./TrackComponent";

const PostAtMusic = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const warningAlertRef = useRef(null);
  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["Active"],
    page_count: 50,
    keyword: "",
  };

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isDeleteable = userPermissions?.delete || false;
  const isCreatable = userPermissions?.create || false;

  const history = useHistory();

  const [request, setRequest] = useState({ ...basicRequest });
  const [currentAudio, setCurrentAudio] = useState(null);

  const data = useSelector((state) => state?.ReportedPostatPosts);
  const cookies = useSelector((state) => state.Cookies);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Postat Management",
          "Music library"
        )
      );
    }
  }, [loginData]);

  useEffect(() => {
    dispatch(getPostatMusic(request));
    dispatch(getPostatSignedCookies());
  }, [request]);

  const handleAudioPlay = (audioElement) => {
    if (currentAudio !== null && currentAudio !== audioElement) {
      currentAudio?.pause();
    }
    setCurrentAudio(audioElement);
  };

  const formatSongData = useCallback(() => {
    return (
      cookies?.cookies &&
      data?.postatMusic?.music_files?.length &&
      data?.postatMusic?.music_files?.map((item, index) => ({
        ...item,
        no: (request?.page - 1) * request?.page_count + index + 1,

        track: cookies?.cookies ? (
          <TrackComponent
            key={item?.media_meta?.music_id}
            musicId={item?.media_meta?.music_id}
            cookies={cookies?.cookies}
            item={item}
            onPlay={handleAudioPlay}
          />
        ) : (
          ""
        ),
        singers: (
          <div data-tag="allowRowEvents" title="song">
            {item?.singers_name?.length > 0 && item.singers_name.join(", ")}
          </div>
        ),
        // category_type: (
        //   <div data-tag="allowRowEvents" title={category.category_type}>
        //     {category.category_type}
        //   </div>
        // ),
        actions: (
          <div className="cust-table-actions-wrap">
            {/* <button
              className=" color-violet action-btn"
              title="Edit"
              // onClick={() =>
              //   history.push({
              //     pathname: `/gift-categories-edit/${category.id}`,
              //     state: { id: category?.id },
              //   })
              // }
              // disabled={!isEditable}
            >
              <img
                src={Edit_icon}
                alt="Edit"
                // style={{
                //   opacity: !isEditable ? "50%" : "",
                //   cursor: !isEditable ? "not-allowed" : "",
                // }}
              />
            </button> */}

            <button
              onClick={() => deletePromptHandler(item?.media_meta?.music_id)}
              className=" color-red action-btn"
              title="Delete"
              disabled={!isDeleteable}
              style={{
                opacity: !isDeleteable ? "50%" : "",
                cursor: !isDeleteable ? "not-allowed" : "",
              }}
            >
              <img
                src={Delete_icon}
                alt="Delete"
                // style={{
                //   opacity: !isDeleteable ? "50%" : "",
                //   cursor: !isDeleteable ? "not-allowed" : "",
                // }}
              />
            </button>
          </div>
        ),
      }))
    );
  }, [data?.postatMusic?.music_files, request, cookies?.cookies]);
  const songData = useMemo(() => formatSongData(), [formatSongData]);

  const addNewClickHandler = () => {
    history.push("/postat-music-library-addNew");
  };

  const okHandler = () => {
    dispatch(
      deletePostatMusic({
        id: promptMessage.id,
        callback: () => dispatch(getPostatMusic()),
      })
    );
  };

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to delete 
          this Music ?`,
      type: "delete",
    });
  };

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        // ref={pageWrapRef}
      >
        <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Music Library" />
            {/* {data?.error && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color="danger"
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error}
                </UncontrolledAlert>
              </div>
            )} */}
            <div className="">
              <Datatable
                tableID={"adminTable"}
                columns={postatMusicColumnData}
                rows={songData}
                setRequest={setRequest}
                request={request}
                add={isCreatable}
                addNewClickHandler={addNewClickHandler}
                addNewLabel={t("Admins.addAdmin.addBtn")}
                // searchTerm={data?.request?.keyword}
                // loading={data?.loading}
                tableCardClassName={"snoTable"}
                totalRecords={data?.postatMusic?.total}
              />
            </div>
          </Row>
        </Container>
        <ConfirmationAlert
          {...promptMessage}
          modal_center={showPromptPopUp}
          setmodal_center={setShowPromptPopUp}
          onOK={okHandler}
        />
        {(data?.loading || cookies?.loading) && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default PostAtMusic;
