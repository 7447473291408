import {
  GET_NOTIFICATIONS_BEGIN,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  GET_ALL_NOTIFICATIONS_BEGIN,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATIONS_FAIL,
  GET_UNSEEN_COUNT_BEGIN,
  GET_UNSEEN_COUNT_SUCCESS,
  GET_UNSEEN_COUNT_FAIL,
  RESET_UNSEEN_COUNT,
  MARK_ALL_AS_READ_BEGIN,
  MARK_ALL_AS_READ_SUCCESS,
  MARK_ALL_AS_READ_FAIL,
} from "./actionTypes";

export const INIT_STATE = {
  notification: [],
  allNotifications: [],
  loading: false,
  error: {},
  unseenCount: "",
};

const notification = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_BEGIN:
      return {
        ...state,
        notification: [],
        loading: true,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notification: action.payload,
        loading: false,
      };

    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ALL_NOTIFICATIONS_BEGIN:
      return {
        ...state,
        allNotifications: [],
        allLoading: true,
      };
    case GET_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        allNotifications: action.payload,
        allLoading: false,
      };

    case GET_ALL_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        allLoading: false,
      };
    case GET_UNSEEN_COUNT_BEGIN:
      return {
        ...state,
        unseenCount: "",
      };
    case GET_UNSEEN_COUNT_SUCCESS:
      return {
        ...state,
        unseenCount: action.payload,
      };

    case GET_UNSEEN_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case RESET_UNSEEN_COUNT:
      return {
        ...state,
        unseenCount: { unseen_count: 0 },
      };

    case MARK_ALL_AS_READ_BEGIN:
      return {
        ...state,
      };
    case MARK_ALL_AS_READ_SUCCESS:
      return {
        ...state,
        notification: {
          ...state.notification,
          ...state.notification.notifications.map(
            (item) => (item.is_read = action.payload)
          ),
        },
      };

    case MARK_ALL_AS_READ_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default notification;
