import {
  GET_MLM_PARENTS_AND_LEVELS_BEGIN,
  GET_MLM_PARENTS_AND_LEVELS_SUCCESS,
  GET_MLM_PARENTS_AND_LEVELS_FAILURE,
  GET_MLM_PAGINATED_LEVEL_BEGIN,
  GET_MLM_PAGINATED_LEVEL_SUCCESS,
  GET_MLM_PAGINATED_LEVEL_FAILURE,
  LEVEL_CLICK,
  CLEAR_TREE,
  GET_MLM_LEVEL_USERS_BEGIN,
  GET_MLM_LEVEL_USERS_SUCCESS,
  GET_MLM_LEVEL_USERS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  tree: {},
  loading: false,
  error: "",
  level: "",
  level1: {},
  level2: {},
  level3: {},
  level4: {},
  level5: {},
  level6: {},
  level7: {},
};

const mlmTree = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MLM_PARENTS_AND_LEVELS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_MLM_PARENTS_AND_LEVELS_SUCCESS:
      return {
        ...state,
        loading: false,
        tree: action.payload,
      };

    case GET_MLM_PARENTS_AND_LEVELS_FAILURE:
      return {
        ...state,
        loading: false,
        tree: {},
        error: action.payload,
      };

    case GET_MLM_PAGINATED_LEVEL_BEGIN:
      return {
        ...state,
        error: "",
      };

    case GET_MLM_PAGINATED_LEVEL_SUCCESS:
      return {
        ...state,
        level: action.level,
        ...(action.level == "level1" && { level1: action.payload[1] }),
        ...(action.level == "level2" && { level2: action.payload[2] }),
        ...(action.level == "level3" && { level3: action.payload[3] }),
        ...(action.level == "level4" && { level4: action.payload[4] }),
        ...(action.level == "level5" && { level5: action.payload[5] }),
        ...(action.level == "level6" && { level6: action.payload[6] }),
        ...(action.level == "level7" && { level7: action.payload[7] }),
      };

    case GET_MLM_PAGINATED_LEVEL_FAILURE:
      return {
        ...state,
        tree: {},
        error: action.payload,
      };

    case LEVEL_CLICK:
      const changeVisibility = { ...state.tree };
      changeVisibility.levels[action.payload] = {
        ...changeVisibility?.levels[action.payload],
        visibility: !changeVisibility?.levels[action.payload]?.visibility,
      };

      return {
        ...state,
        tree: changeVisibility,
      };
    case GET_MLM_LEVEL_USERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_MLM_LEVEL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...(action.level == "1" && { level1: action.payload[1] }),
        ...(action.level == "2 " && { level2: action.payload[2] }),
        ...(action.level == "3" && { level3: action.payload[3] }),
        ...(action.level == "4" && { level4: action.payload[4] }),
        ...(action.level == "5" && { level5: action.payload[5] }),
        ...(action.level == "6" && { level6: action.payload[6] }),
        ...(action.level == "7" && { level7: action.payload[7] }),
        error: "",
      };
    case GET_MLM_LEVEL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_TREE:
      return {
        ...state,
        tree: {},
        error: "",
      };

      return {
        ...state,
        //tree:changeVisibility
      };

    default:
      return state;
  }
};

export default mlmTree;
