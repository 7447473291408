import {
  GET_PODIUM_CAMERA_PURCHASE_HISTORY,
  GET_PODIUM_CAMERA_PURCHASE_HISTORY_BEGIN,
  GET_PODIUM_CAMERA_PURCHASE_HISTORY_FAIL,
  GET_PODIUM_CAMERA_PURCHASE_HISTORY_SUCCESS,
} from "./actionTypes";

export const getPodiumCameraPurchaseHistory = ({ request, userId }) => ({
  type: GET_PODIUM_CAMERA_PURCHASE_HISTORY,
  request: request,
  userId: userId,
});

export const getPodiumCameraPurchaseHistoryBegin = () => ({
  type: GET_PODIUM_CAMERA_PURCHASE_HISTORY_BEGIN,
});

export const getPodiumCameraPurchaseHistorySuccess = (purchaseHistory) => ({
  type: GET_PODIUM_CAMERA_PURCHASE_HISTORY_SUCCESS,
  payload: purchaseHistory,
});

export const getPodiumCameraPurchaseHistoryFail = (error) => ({
  type: GET_PODIUM_CAMERA_PURCHASE_HISTORY_FAIL,
  payload: error,
});
