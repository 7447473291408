import {
  GET_COUNTRIES,
  GET_COUNTRIES_BEGIN,
  GET_COUNTRIES_FAIL,
  GET_COUNTRIES_SUCCESS,
} from "./actionTypes";

export const getCountries = (request) => ({
  type: GET_COUNTRIES,
  request: request,
});

export const getCountriesBegin = () => ({
  type: GET_COUNTRIES_BEGIN,
});

export const getCountriesSuccess = (countries) => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: countries,
});

export const getCountriesFail = (error) => ({
  type: GET_COUNTRIES_FAIL,
  payload: error,
});
