import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  del,
  get,
  post,
  update,
  updateFormData,
} from "../../helpers/api_helper";
import {
  API_GET_FLAX_TRANSFER_HISTORY,
  API_GET_FLAX_TRANSFER_PURPOSES,
  API_POST_FLAX_TRANSFER_PURPOSE,
  API_GET_FLAX_TRANSFER_HISTORY_BY_USER_ID,
} from "../../helpers/url_helper";
import {
  addFlaxTransferPurposeBegin,
  addFlaxTransferPurposeFail,
  addFlaxTransferPurposeSuccess,
  deleteFlaxTransferPurposeBegin,
  deleteFlaxTransferPurposeFail,
  deleteFlaxTransferPurposeSuccess,
  editFlaxTransferPurposeBegin,
  editFlaxTransferPurposeFail,
  editFlaxTransferPurposeSuccess,
  getFlaxTransferHistoryBegin,
  getFlaxTransferHistoryByUserIdBegin,
  getFlaxTransferHistoryByUserIdFail,
  getFlaxTransferHistoryByUserIdSuccess,
  getFlaxTransferHistoryFail,
  getFlaxTransferHistorySuccess,
  getFlaxTransferPurposeByIdBegin,
  getFlaxTransferPurposeByIdFail,
  getFlaxTransferPurposeByIdSuccess,
  getFlaxTransferPurposesBegin,
  getFlaxTransferPurposesFail,
  getFlaxTransferPurposesSuccess,
} from "./actions";
import {
  ADD_FLAX_TRANSFER_PURPOSE,
  DELETE_FLAX_TRANSFER_PURPOSE,
  EDIT_FLAX_TRANSFER_PURPOSE,
  GET_FLAX_TRANSFER_HISTORY,
  GET_FLAX_TRANSFER_HISTORY_BY_USER_ID,
  GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_FAIL,
  GET_FLAX_TRANSFER_PURPOSES,
  GET_FLAX_TRANSFER_PURPOSE_BY_ID,
} from "./actionTypes";

function* getFlaxTransferHistory(res) {
  try {
    yield put(getFlaxTransferHistoryBegin());
    const response = yield call(
      get,
      API_GET_FLAX_TRANSFER_HISTORY,
      res.request
    );

    yield put(getFlaxTransferHistorySuccess(response.result));
  } catch (error) {
    yield put(getFlaxTransferHistoryFail(error));
  }
}
function* getFlaxTransferPurposes({ request, language }) {
  try {
    yield put(getFlaxTransferPurposesBegin());
    if (language) {
      const response = yield call(
        get,
        API_GET_FLAX_TRANSFER_PURPOSES + `?language=${language}`,
        request
      );
      yield put(getFlaxTransferPurposesSuccess(response.result));
    } else {
      const response = yield call(
        get,
        `/admin/deals/flaxtransferpurposes`,
        request
      );
      yield put(getFlaxTransferPurposesSuccess(response.result));
    }
  } catch (error) {
    yield put(getFlaxTransferPurposesFail(error));
  }
}
function* addFlaxTransferPurpose({ purpose, callback }) {
  try {
    yield put(addFlaxTransferPurposeBegin());
    const response = yield call(post, API_POST_FLAX_TRANSFER_PURPOSE, purpose);

    yield put(addFlaxTransferPurposeSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(addFlaxTransferPurposeFail(error));
  }
}
function* deleteFlaxTransferPurpose({ id, callback }) {
  try {
    yield put(deleteFlaxTransferPurposeBegin());
    const response = yield call(del, `/admin/deals/flaxtransferpurposes/${id}`);
    callback && callback();
    yield put(deleteFlaxTransferPurposeSuccess(response.result));
  } catch (error) {
    yield put(deleteFlaxTransferPurposeFail(error));
  }
}
function* getFlaxTransferPurposeByIdSaga({ id }) {
  try {
    yield put(getFlaxTransferPurposeByIdBegin());
    const response = yield call(get, `/admin/deals/flaxtransferpurposes/${id}`);
    yield put(getFlaxTransferPurposeByIdSuccess(response.result));
  } catch (error) {
    yield put(getFlaxTransferPurposeByIdFail(error));
  }
}
function* editFlaxTransferPurpose({ params, id, purpose, callback }) {
  try {
    yield put(editFlaxTransferPurposeBegin());
    const response = yield call(
      updateFormData,
      `/admin/deals/flaxtransferpurposes?purpose_identifier=${params}`,
      purpose
    );
    // callback && callback();
    yield put(editFlaxTransferPurposeSuccess(response.message));
  } catch (error) {
    yield put(editFlaxTransferPurposeFail(error));
  }
}

function* getFlaxTransferHistoryByUserId({ request, userId }) {
  console.log("saga-id", userId);
  try {
    yield put(getFlaxTransferHistoryByUserIdBegin());
    const response = yield call(
      get,
      API_GET_FLAX_TRANSFER_HISTORY_BY_USER_ID.replace("{userId}", userId),
      request
    );

    yield put(getFlaxTransferHistoryByUserIdSuccess(response.result));
  } catch (error) {
    yield put(getFlaxTransferHistoryByUserIdFail(error));
  }
}

function* flaxTransfersaga() {
  yield takeLatest(GET_FLAX_TRANSFER_HISTORY, getFlaxTransferHistory);
  yield takeLatest(GET_FLAX_TRANSFER_PURPOSES, getFlaxTransferPurposes);
  yield takeLatest(ADD_FLAX_TRANSFER_PURPOSE, addFlaxTransferPurpose);
  yield takeLatest(DELETE_FLAX_TRANSFER_PURPOSE, deleteFlaxTransferPurpose);
  yield takeLatest(
    GET_FLAX_TRANSFER_PURPOSE_BY_ID,
    getFlaxTransferPurposeByIdSaga
  );
  yield takeLatest(EDIT_FLAX_TRANSFER_PURPOSE, editFlaxTransferPurpose);
  yield takeLatest(
    GET_FLAX_TRANSFER_HISTORY_BY_USER_ID,
    getFlaxTransferHistoryByUserId
  );
}

export default flaxTransfersaga;
