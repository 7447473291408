import { call, put, takeLatest } from "redux-saga/effects";

import { GET_APP_SUBSCRIPTIONS, GET_NEW_SUBSCRIPTIONS, EXPORT_APP_SUBSCRIPTION_DETAILS } from "./actionTypes";

import {
  // exportAppSubscriptionDetails,
  getNewSubscriptionsBegin,
  getNewSubscriptionsFail,
  getNewSubscriptionsSuccess,
  getSubscriptionDataBegin,
  getSubscriptionDataFail,
  getSubscriptionDataSuccess,
  exportAppSubscriptionDetailsBegin,
  exportAppSubscriptionDetailsSuccess,
  exportAppSubscriptionDetailsFail
} from "./action";

import { get, getFile } from "../../helpers/api_helper";
import { APP_SUBSCRIPTIONS } from "../../helpers/url_helper";

function* getAppSubscriptionData({ payload }) {
  try {
    yield put(getSubscriptionDataBegin());
    const response = yield call(get, APP_SUBSCRIPTIONS, payload.request);
    yield put(getSubscriptionDataSuccess(response.result));
  } catch (error) {
    yield put(getSubscriptionDataFail(error));
  }
}
function* getNewSubscriptionData({ payload }) {
  console.log("payload--", payload);

  try {
    yield put(getNewSubscriptionsBegin());
    const response = yield call(
      get,
      "/admin/users/subscriptions-list",
      payload.request
    );
    yield put(getNewSubscriptionsSuccess(response.result));
  } catch (error) {
    yield put(getNewSubscriptionsFail(error));
  }
}

function* exportAppSubscriptionDetails({ saveAs, request }) { 
  try {
    yield put(exportAppSubscriptionDetailsBegin())
    const response = yield call(getFile, "/admin/users/subscriptions-list", request, {
      headers: {
        "Content-Type": "text/csv",
      },
      responseType: "blob",
    })
    const blob = new Blob([response], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `app_subscription_details_export_${new Date().toLocaleString()}.csv`);
    yield put(exportAppSubscriptionDetailsSuccess())
  } catch (error) {
    yield put(exportAppSubscriptionDetailsFail(error))
  }
}

function* appSubscriptionsSaga() {
  yield takeLatest(GET_APP_SUBSCRIPTIONS, getAppSubscriptionData);
  yield takeLatest(GET_NEW_SUBSCRIPTIONS, getNewSubscriptionData);
  yield takeLatest(EXPORT_APP_SUBSCRIPTION_DETAILS, exportAppSubscriptionDetails)
}

export default appSubscriptionsSaga;
