import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import Datatable from "../../../../components/UI/Tables/Datatable";
import {
  flaxTransferColumnData,
  restoreRatingHistoryColumnData,
  userFlaxTransferColumnData,
} from "../../../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../../helpers/constants";
import { convertTime, convertToUAETime } from "../../../../helpers/utils";
import {
  getFlaxTransferHistory,
  getFlaxTransferHistoryByUserId,
} from "../../../../store/flaxTransfer/actions";
import premiumImage from "../../../../assets/icons/premium.svg";
import defaultProfile from "../../../../assets/images/users/user.svg";
import Loader from "../../../../components/Common/Loader";
import { getUserRatingHistory } from "../../../../store/users/flaxRates/actions";
import { Card } from "@material-ui/core";
import CountUp from "react-countup";
import Flix_bal_icon from "../../../../assets/icons/sdfl_icons/flix-bal-icon.svg";

const RatingHistory = ({ toggle, modal, userId }) => {
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "transfered_date",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 10,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const data = useSelector((state) => state.userFlaxRates);
  useEffect(() => {
    request && userId && dispatch(getUserRatingHistory({ request, userId }));
  }, [request]);
  console.log("restore", data);

  const restoreData =
    data?.userRatingHistoryData?.data?.length >= 0 &&
    data?.userRatingHistoryData?.data?.map((result, index) => {
      return {
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        time_created: (
          <div data-tag="allowRowEvents" title={result?.time_created}>
            {convertTime(result?.time_created)}
          </div>
        ),
      };
    });

  return (
    <div>
      <Modal
        centered
        isOpen={modal}
        toggle={toggle}
        style={{ minWidth: "1000px" }}
      >
        {/* <ModalHeader toggle={toggle}>Flax summary</ModalHeader> */}
        <ModalBody>
          <>
            <Container
              fluid
              id="userManagementWrapper"
              data-testid="userMgmtWrap"
            >
              <Row>
                <Breadcrumbs
                  title="Tables"
                  breadcrumbItem="Restore Rating History"
                />
                {!data?.loading && (
                  <Row className="py-3 ">
                    <Col md={3} xl={3}>
                      <Card>
                        <CardBody className="px-3 py-3">
                          <div className="float-end mt-2">
                            <img src={Flix_bal_icon} alt="coin" />
                          </div>
                          <div>
                            <h4 className="mb-1 mt-1">
                              <span>
                                <CountUp
                                  end={+data?.userRatingHistoryData?.total || 0}
                                />
                              </span>
                            </h4>
                            <p className="text-muted mb-0">Total FLiX Spent</p>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                <Datatable
                  // defaultSortField={request?.sort || "transfered_date"}
                  // defaultSortAsc={
                  //   request?.sort_order === "asc" ? true : false
                  // }
                  // dateFilter
                  // dateType="Transfered Date Range"
                  tableID={"flaxTable"}
                  columns={restoreRatingHistoryColumnData}
                  rows={restoreData}
                  setRequest={setRequest}
                  request={request}
                  totalRecords={data?.userRatingHistoryData?.length}
                  // search
                  // searchTerm={data?.request?.keyword}
                  // reqstatusFilter={request?.status_filter}
                  loading={data?.loading}
                  tableCardClassName={"snoTable"}
                />
              </Row>
            </Container>
            {data?.loading && <Loader darkBg={true} />}
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RatingHistory;
