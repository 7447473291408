import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";

const UserRequestFilter = ({
  setRequest,
  request,
  reqstatusFilter,
}) => {
  const [userList_filter_success, set_userList_filter_success] = useState(
    "Verified"
  );
  const [userList_filter_pending, set_userList_filter_pending] = useState(
    false
  );
  const [userList_filter_rejected, set_userList_filter_rejected] = useState(
    false
  );

  const [modal_center, setmodal_center] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    reqstatusFilter?.includes("Verified")
      ? set_userList_filter_success("Verified")
      : set_userList_filter_success(false);
      reqstatusFilter?.includes("Processing")
      ? set_userList_filter_pending("Processing")
      : set_userList_filter_pending(false);
      reqstatusFilter?.includes("Declined")
      ? set_userList_filter_rejected("Declined")
      : set_userList_filter_rejected(false);
  }, [reqstatusFilter, modal_center]);


  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const filter_clearAll = () => {
    set_userList_filter_success(false);
    set_userList_filter_pending(false);
    set_userList_filter_rejected(false);

  };
  const handleApply = () => {
    setRequest({
      ...request,
      status_filter: [
        userList_filter_success,
        userList_filter_pending,
        userList_filter_rejected,
      ].filter((item) => item),
 
    });
    setmodal_center(false);
  };
  return (
    <div className="user-filterBtn-wrap" data-testid="component-filterPopup">
      <button
        type="button"
        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
        onClick={() => {
          tog_center();
        }}
        data-testid="component-filterButton"
      >
        <i
          style={{ paddingRight: "10px" }}
          className="fa fa-filter"
          aria-hidden="true"
        ></i>
        FILTER
      </button>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={true}
      >
        <div className="modal-header userList-filter-header">
          <h5 className="modal-title mt-0">FILTER</h5>
          <button
            onClick={() => filter_clearAll()}
            className="btn waves-effect waves-light cust_no_shadow userList-filter-clearAll"
          >
            CLEAR ALL
          </button>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
          // data-dismiss="modal"
          // aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body userList-filter-body">
          <div className="userList-filter-SectionWrap">
            <h5 className="userList-filter-SectionTitle">Status</h5>
            <div className="userList-filter-checkBoxWrap">
              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={userList_filter_success}
                  id="userList_filter_active"
                  onChange={() =>
                    userList_filter_success
                      ? set_userList_filter_success(!userList_filter_success)
                      : set_userList_filter_success("Verified")
                  }
                  checked={userList_filter_success}
                />
                <label
                  className="form-check-label"
                  htmlFor="userList_filter_active"
                >
                  Verified
                </label>
              </div>

              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={userList_filter_pending}
                  id="userList_filter_pending"
                  onChange={() =>
                    userList_filter_pending
                      ? set_userList_filter_pending(!userList_filter_pending)
                      : set_userList_filter_pending("Processing")
                  }
                  checked={userList_filter_pending}
                />
                <label
                  className="form-check-label"
                  htmlFor="userList_filter_pending"
                >
                  Processing
                </label>
              </div>

              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={userList_filter_rejected}
                  id="userList_filter_rejected"
                  onChange={() =>
                    userList_filter_rejected
                      ? set_userList_filter_rejected(!userList_filter_rejected)
                      : set_userList_filter_rejected("Declined")
                  }
                  checked={userList_filter_rejected}
                />
                <label
                  className="form-check-label"
                  htmlFor="userList_filter_rejected"
                >
                  Declined
                </label>
              </div>
            </div>
          </div>


        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              tog_center();
            }}
          >
            CANCEL
          </button>
          <button
            type="button"
            className="btn bgcolor-btn-green"
            onClick={() => handleApply()}
          >
            APPLY
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default UserRequestFilter
