import {
  GET_APP_INSTALLATIONS_BEGIN,
  GET_APP_INSTALLATIONS_SUCCESS,
  GET_APP_INSTALLATIONS_FAIL,
  GET_INSTALLATION_COUNTRY_BEGIN,
  GET_INSTALLATION_COUNTRY_SUCCESS,
  GET_INSTALLATION_COUNTRY_FAIL,
  STORE_APP_INSTALLATIONS_REQUEST,
  GET_INSTALLATION_REGION_BEGIN,
  GET_INSTALLATION_REGION_SUCCESS,
  GET_INSTALLATION_REGION_FAIL,
  UPDATE_INSTALLATION_REGION,
  UPDATE_INSTALLATION_REGION_BEGIN,
} from './actionTypes';

const INIT_STATE = {
  listing_error: "",
  country_filter_error: "",
  loading: false,
  request: {
    sort: "total",
    sort_order: "desc",
    page: 1,
    page_count: 50,
  },
  installationData: {},
  installationCountry: [],
  regions: [],
  regionE_error: ""
};

const appInstallations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_APP_INSTALLATIONS_BEGIN:
      return {
        ...state,
        loading: true,
        listing_error: "",
        installationData: {}
      };
    case GET_APP_INSTALLATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        listing_error: '',
        installationData: action.payload,
      };
    case GET_APP_INSTALLATIONS_FAIL:
      return {
        ...state,
        installationData: {},
        loading: false,
        listing_error: action.payload,
      };

    case GET_INSTALLATION_COUNTRY_BEGIN:
      return {
        ...state,
        installationCountry: [],
        //loading: true,
        country_filter_error: ""
      }

    case GET_INSTALLATION_COUNTRY_SUCCESS:
      return {
        ...state,
        installationCountry: action.payload,
        //loading: false,
        country_filter_error: ""
      }

    case GET_INSTALLATION_COUNTRY_FAIL:
      return {
        ...state,
        installationCountry: [],
        //loading: false,
        country_filter_error: action.payload
      }

      case GET_INSTALLATION_REGION_BEGIN:
        return {
          ...state,
          regions: [],
          region_error: ""
        }

        case GET_INSTALLATION_REGION_SUCCESS:
          return {
            ...state,
            regions: action.payload?.regions,
            region_error: "",
            loading: false
          }
    
        case GET_INSTALLATION_REGION_FAIL:
          return {
            ...state,
            regions: [],
            //loading: false,
            region_error: action.payload
          }

    case STORE_APP_INSTALLATIONS_REQUEST:
      return {
        ...state,
        request: action.payload,
      };
    
    case UPDATE_INSTALLATION_REGION_BEGIN: 
    return {
      ...state,
      loading: true
    }

    default:
      return state;
  }
};

export default appInstallations;
