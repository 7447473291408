import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  GET_REPORTED_USER,
  GET_REPORTED_CATEGORY,
  DELETE_REPORTED_USER,
  GET_REPORTED_USERS_CATEGORIES,
  EDIT_REPORTED_USERS_CATEGORIES,
  ADD_REPORTED_USERS_CATEGORIES,
  GET_REPORTED_USERS_CATEGORIES_BY_ID,
  GET_REPORTED_USER_BY_ID,
  DELETE_REPORTED_USERS_CATEGORIES_FAIL,
  DELETE_REPORTED_USERS_CATEGORIES,
} from "./actionTypes";

import {
  getReportedDataBegin,
  getReportedDataSuccess,
  getReportedDataFail,
  getCategoryDataBegin,
  getCategoryDataSuccess,
  getCategoryDataFail,
  deleteReportedDataBegin,
  deleteReportedDataSuccess,
  deleteReportedDataFail,
  getReportedUsersCategoriesBegin,
  getReportedUsersCategoriesSuccess,
  getReportedUsersCategoriesFail,
  getReportedUsersCategoriesByIdBegin,
  getReportedUsersCategoriesByIdSuccess,
  getReportedUsersCategoriesByIdFail,
  addReportedUsersCategoriesBegin,
  addReportedUsersCategoriesSuccess,
  addReportedUsersCategoriesFail,
  editReportedUsersCategoriesBegin,
  editReportedUsersCategoriesSuccess,
  editReportedUsersCategoriesFail,
  getReportedUserByIdBegin,
  getReportedUserByIdSuccess,
  getReportedUserByIdFail,
  deleteReportedUserCategoriesBegin,
  deleteReportedUserCategoriesFail,
  deleteReportedUserCategoriesSuccess,
} from "./action";

import { del, get, post, update } from "../../helpers/api_helper";
import {
  REPORTED_USER,
  REPORTED_USER_BY_ID,
  REPORT_CATEGORY,
} from "../../helpers/url_helper";

function* getReportedUserData({ payload }) {
  try {
    yield put(getReportedDataBegin());
    const response = yield call(get, REPORTED_USER, payload.request);
    yield put(getReportedDataSuccess(response.result));
  } catch (error) {
    yield put(getReportedDataFail(error));
  }
}

function* getCategoryData() {
  try {
    yield put(getCategoryDataBegin());
    const response = yield call(get, REPORT_CATEGORY);
    yield put(getCategoryDataSuccess(response.result));
  } catch (error) {
    yield put(getCategoryDataFail(error));
  }
}

function* deleteReportedUser({ data, callback }) {
  try {
    yield put(deleteReportedDataBegin());
    const response = yield call(del, REPORTED_USER_BY_ID.replace("{id}", data));
    callback && callback();
    yield put(deleteReportedDataSuccess(response.result));
  } catch (error) {
    yield put(deleteReportedDataFail(error));
  }
}

// Huddle User Reported  Categories

function* getReportedUsersCategories(res) {
  try {
    yield put(getReportedUsersCategoriesBegin());
    const response = yield call(
      get,
      "/admin/report/users/categories",
      res.request
    );

    yield put(getReportedUsersCategoriesSuccess(response.result));
  } catch (error) {
    yield put(getReportedUsersCategoriesFail(error));
  }
}

function* getReportedUsersCategoriesById({ id }) {
  try {
    yield put(getReportedUsersCategoriesByIdBegin());
    const response = yield call(get, `/admin/report/users/categories/${id}`);

    yield put(getReportedUsersCategoriesByIdSuccess(response.result));
  } catch (error) {
    yield put(getReportedUsersCategoriesByIdFail(error));
  }
}

function* addReportedUsersCategories({ category, callback }) {
  try {
    yield put(addReportedUsersCategoriesBegin());
    const response = yield call(
      post,
      "/admin/report/users/categories",
      category
    );
    yield put(addReportedUsersCategoriesSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(addReportedUsersCategoriesFail(error));
  }
}

function* editReportedUsersCategories({ id, category, callback }) {
  try {
    yield put(editReportedUsersCategoriesBegin());
    const response = yield call(
      update,
      `/admin/report/users/categories/${id}`,
      category
    );
    callback && callback();
    yield put(editReportedUsersCategoriesSuccess(response.message));
  } catch (error) {
    yield put(editReportedUsersCategoriesFail(error));
  }
}
function* deleteReportedUserCategories({ id, callback }) {
  try {
    yield put(deleteReportedUserCategoriesBegin());
    const response = yield call(del, `/admin/report/users/categories/${id}`);

    callback && callback();
    yield put(deleteReportedUserCategoriesSuccess(response.message));
  } catch (error) {
    yield put(deleteReportedUserCategoriesFail(error));
  }
}
function* getReportedUserById({ id, request }) {
  try {
    yield put(getReportedUserByIdBegin());
    const response = yield call(get, `/admin/report/users/${id}`, request);
    yield put(getReportedUserByIdSuccess(response.result));
  } catch (error) {
    yield put(getReportedUserByIdFail(error));
  }
}

function* reportedUserSaga() {
  yield takeLatest(GET_REPORTED_USER, getReportedUserData);
  yield takeLatest(GET_REPORTED_CATEGORY, getCategoryData);
  yield takeLatest(DELETE_REPORTED_USER, deleteReportedUser);

  yield takeLatest(GET_REPORTED_USERS_CATEGORIES, getReportedUsersCategories);
  yield takeLatest(EDIT_REPORTED_USERS_CATEGORIES, editReportedUsersCategories);
  yield takeLatest(ADD_REPORTED_USERS_CATEGORIES, addReportedUsersCategories);
  yield takeLatest(
    GET_REPORTED_USERS_CATEGORIES_BY_ID,
    getReportedUsersCategoriesById
  );
  yield takeLatest(GET_REPORTED_USER_BY_ID, getReportedUserById);
  yield takeLatest(
    DELETE_REPORTED_USERS_CATEGORIES,
    deleteReportedUserCategories
  );
}

export default reportedUserSaga;
