import {
  GET_RES_USER_DATA_BEGIN,
  GET_RES_USER_DATA_SUCCESS,
  GET_RES_USER_DATA_FAIL,
  ADD_RES_USERNAME_BEGIN,
  ADD_RES_USERNAME_SUCCESS,
  ADD_RES_USERNAME_FAIL,
  DELETE_RES_USER_BEGIN,
  DELETE_RES_USER_SUCCESS,
  DELETE_RES_USER_FAIL,
  GET_RES_USER_DETAILS_BEGIN,
  GET_RES_USER_DETAILS_SUCCESS,
  GET_RES_USER_DETAILS_FAIL,
  UPDATE_RES_USER_DETAILS_BEGIN,
  UPDATE_RES_USER_DETAILS_SUCCESS,
  UPDATE_RES_USER_DETAILS_FAIL,
  STORE_USER_REQUEST,
  IMPORT_RESERVED_USERNAMES_BEGIN,
  IMPORT_RESERVED_USERNAMES_SUCCESS,
  IMPORT_RESERVED_USERNAMES_FAIL,
  RESET_MESSAGE
} from "./actionTypes";

const INIT_STATE = {
  data: {},
  error: "",
  resUserDetails: {},
  loading: false,
  request: {
    sort: "modified_on",
    sort_order: "desc",
    page: 1,
    page_count: 50,
  },
};

const reservedUser = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RES_USER_DATA_BEGIN:
      return {
        ...state,
        data: {},
        loading: true,
        error: "",
        uploadInfo: "",
      };

    case GET_RES_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        uploadInfo: "",
      };

    case GET_RES_USER_DATA_FAIL:
      return {
        ...state,
        data: {},
        loading: false,
        error: action.payload,
        uploadInfo: "",
      };

    case ADD_RES_USERNAME_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case ADD_RES_USERNAME_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Reserved username successfully",
      };

    case ADD_RES_USERNAME_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case DELETE_RES_USER_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case DELETE_RES_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: "Username deleted successfully",
      };

    case DELETE_RES_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case GET_RES_USER_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case GET_RES_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        resUserDetails: action.payload,
        error: "",
      };

    case GET_RES_USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_RES_USER_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case UPDATE_RES_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: "Reserved username successfully",
      };

    case UPDATE_RES_USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };
    case IMPORT_RESERVED_USERNAMES_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        uploadInfo: "",
        success: "",
      };

    case IMPORT_RESERVED_USERNAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        uploadInfo: action.payload,
      };

    case IMPORT_RESERVED_USERNAMES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        uploadInfo: "",
      };
    case STORE_USER_REQUEST:
      return {
        ...state,
        request: action.payload,
      };

    case RESET_MESSAGE:
      return {
        ...state,
        success: "",
      };

    default:
      return state;
  }
};

export default reservedUser;
