import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

const UserCountryFilterComponent = ({
  request,
  setRequest,
  userCountryFilter,
  countries,
}) => {
  const { t } = useTranslation();
  const [preSelectedCountries, setPreSelectedCountries] = useState([]);

  // Update pre-selected countries when userCountryFilter changes
  //   useEffect(() => {
  //     setPreSelectedCountries(userCountryFilter);
  //   }, [userCountryFilter]);

  const getCountryOptions = () =>
    userCountryFilter?.sort().map((country) => ({
      key: country.code,
      value: country.code,
      text: country.name,
    }));

  const onCountrySelection = (data) => {
    setPreSelectedCountries([...data]);
    handleApply({ country: data });
  };

  const handleApply = (filterData) => {
    setRequest({
      ...request,
      ...filterData,
    });
  };

  return (
    <>
      {countries && (
        <div
          className=" user-filterBtn-wrap mr-2"
          style={{
            maxWidth: "750px",
            minWidth: "200px",
            marginRight: "5px",
            height: "30px",
          }}
        >
          <span className="country-label">
            {/* {t("AppInstallations.countries")} */}
          </span>
          <Dropdown
            placeholder="Countries"
            fluid
            multiple
            selection
            value={preSelectedCountries}
            options={getCountryOptions()}
            onChange={(e, data) => {
              onCountrySelection(data.value);
            }}
          />
        </div>
      )}
    </>
  );
};

export default UserCountryFilterComponent;
