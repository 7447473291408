import { call, put, takeEvery } from "redux-saga/effects";

import { GET_DASHBOARD_DATA, GET_GRAPH_DATA } from "./actionTypes";
import {
  getDashboardDataSuccess,
  getDashboardDataFail,
  getDashboardDataBegin,
  getGraphDataBegin,
  getGraphDataSuccess,
  getGraphDataFail,
} from "./actions";

import { GET_DASHBOARD, GET_DASHBOARD_GRAPH } from "../../helpers/url_helper";

import { get } from "../../helpers/api_helper";

function* getDashboardData() {
  try {
    yield put(getDashboardDataBegin());
    const response = yield call(get, GET_DASHBOARD);
    yield put(getDashboardDataSuccess(response.result));
  } catch (error) {
    yield put(getDashboardDataFail(error));
  }
}

function* getGraphData({ payload }) {
  try {
    yield put(getGraphDataBegin());
    const response = yield call(get, GET_DASHBOARD_GRAPH, payload?.request);
    yield put(getGraphDataSuccess(response.result));
  } catch (error) {
    yield put(getGraphDataFail(error));
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_DASHBOARD_DATA, getDashboardData);
  yield takeEvery(GET_GRAPH_DATA, getGraphData);
}

export default dashboardSaga;
