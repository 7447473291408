import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Card, Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import premiumImage from "../../assets/icons/premium.svg";

import Datatable from "../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../components/UI/ConfirmationAlert";
import { DATE_WITH_TIME_IN_SECONDS } from "../../helpers/constants";
import Loader from "../../components/Common/Loader";
import { convertToUAETime } from "../../helpers/utils";

import defaultProfile from "../../assets/images/users/podium-default.svg";
// import checkBox from "../../assets/icons/checkbox.svg";

import { podiumColumnData } from "../../helpers/columns";
import { checkUserPermission } from "../../helpers/utils";
import { getHuddles, deleteHuddleList } from "../../store/huddles/actions";
import { Checkbox } from "semantic-ui-react";
import { getPodiums, storePodiumRequests } from "../../store/podiums/actions";

// const PRIVATE_TYPE = "private";
// const PUBLIC_TYPE = "public";

const PodiumManagement = ({ userId }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Podiums);
  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;
  const isDeleteable = userPermissions?.delete || false;

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();
  console.log(location.path);

  //   const type =
  //     location.pathname === "/groups"
  //       ? PRIVATE_TYPE
  //       : location.pathname === "/huddles"
  //       ? PUBLIC_TYPE
  //       : null;
  //   const title = type === PRIVATE_TYPE ? "Groups" : "Huddles";

  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    type: "",
    // membership_filter: [""],
    page_count: 50,

    // user_id: userId,
    // type,
  };
  const [request, setRequest] = useState({ ...basicRequest });
  console?.log("req", request);
  console.log("location", location);
  useEffect(() => {
    location?.state?.from === "podiumDetails"
      ? setRequest({ ...data?.request })
      : setRequest({ ...basicRequest });
  }, [location?.state]);

  useEffect(() => {
    request && dispatch(getPodiums(request));
    request && dispatch(storePodiumRequests(request));
  }, [request]);

  // console.log("podium data", data);
  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Users"
        )
      );
    }
  }, [loginData]);

  //   useEffect(() => {
  //     scrollToAlertMessage();
  //   }, [data?.error]);

  //   const scrollToAlertMessage = () => {
  //     if (data?.error) {
  //       let element = warningAlertRef.current;
  //       let top = element.offsetTop;
  //       let height = element.getBoundingClientRect().height;
  //       pageWrapRef.current.scrollTo(0, top - height - 30);
  //     }
  //   };

  //memoized user data
  // const userData = useMemo(() => formatUserData(), [formatUserData]);
  console.log("podiums", data);

  const podiumsData = data?.podiums?.podiums?.map((podium, index) => ({
    ...podium,

    no: (request?.page - 1) * request?.page_count + index + 1,
    createdOn: convertToUAETime(podium.time_created, DATE_WITH_TIME_IN_SECONDS),
    profile_image: (
      <div className="profileWrap" data-tag="allowRowEvents">
        {/* {huddles.is_premium ? (
          <div className="huddle_premium_icon" data-tag="allowRowEvents">
            <img src={premiumImage} data-tag="allowRowEvents" />
          </div>
        ) : null} */}

        <div data-tag="allowRowEvents" className="d-flex">
          <div data-tag="allowRowEvents">
            <img
              data-tag="allowRowEvents"
              className="table_profileImg"
              src={
                podium?.profile_pic === null
                  ? defaultProfile
                  : podium?.profile_pic
              }
            />
          </div>

          <div
            className="mx-2 mt-3"
            title={podium.name}
            data-tag="allowRowEvents"
          >
            {podium.name}{" "}
            {/* {huddles.tribe === true && (
              <>
                <div className="badge bg-warning font-size-12">Tribe</div>
              </>
            )} */}
          </div>
        </div>
      </div>
    ),
    category: (
      <div data-tag="allowRowEvents" title={podium.category.name}>
        {podium.category.name}
      </div>
    ),

    manager: (
      <div data-tag="allowRowEvents" title={podium.manager_name}>
        {podium.manager_name}
      </div>
    ),
    created_on: (
      <div data-tag="allowRowEvents" title={podium.created}>
        {podium?.created}
      </div>
    ),

    type: (
      <div
        data-tag="allowRowEvents"
        className="badge bg-soft-gray font-size-12"
      >
        {podium.type}
      </div>
    ),
  }));

  const tableRowClickHandler = (param) => {
    const baseUrl = "/podium-details";
    history.push(
      `${baseUrl}/${param.id}?return=${location.pathname}${location.search}`
    );
  };

  return (
    <div
      className={userId || "page-content"}
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Podiums" />
          {/* {data?.error && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            </div>
          )} */}
          {/* {data?.success && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="success"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.success}
              </UncontrolledAlert>
            </div>
          )} */}

          <Datatable
            defaultSortField={request?.sort || "created_on"}
            defaultSortAsc={request?.sort_order === "asc" ? true : false}
            tableID={"adminTable"}
            columns={podiumColumnData}
            rows={podiumsData}
            setRequest={setRequest}
            request={request}
            totalRecords={data?.podiums?.total_items}
            search
            // podiumFilter
            reqTypeFilter={request.type}
            tableRowClicked={(param) => tableRowClickHandler(param)}
            // addNewLabel={t("Admins.addAdmin.addBtn")}
            searchTerm={data?.request?.keyword}
            // reqstatusFilter={request?.status_filter}
            // reqmembershipFilter={request?.membership_filter}
            // loading={data?.loading}
            // tableCardClassName={"snoTable"}
            // deleteLabel="DELETE"
            // deleteClickHandler={deleteAll}
            // deleteDisabled={!selectedHuddles.length}
            // showDeleteButton={true}
            // deleteConfirmationMsg="Do you really want to delete the selected huddles?"
          />
        </Row>
      </Container>

      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default PodiumManagement;
