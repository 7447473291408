import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import * as Sentry from "@sentry/react";

import "react-toastify/dist/ReactToastify.css";

import FallbackComponent from "./components/Common/Fallback";

import i18n from "./i18n";

import store from "./store";

var sentry_dsn =
  "https://b3ed0d7de9784ec5917bc6bc6b4d12e7@o531357.ingest.sentry.io/5785248";
if (process.env.NODE_ENV === "production") {
  sentry_dsn =
    "https://000f3c0996b747baa3fe82073eab1573@o1102108.ingest.sentry.io/6128488";
}

Sentry.init({
  dsn: sentry_dsn,
  traces_sample_rate: 0.0
});

const app = (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Sentry.ErrorBoundary fallback={<FallbackComponent />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </Provider>
  </I18nextProvider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
