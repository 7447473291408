import {
  GET_TEMPLATE_LISTING_BEGIN,
  GET_TEMPLATE_LISTING_SUCCESS,
  GET_TEMPLATE_LISTING_FAIL,
  EDIT_TEMPLATE_BEGIN,
  EDIT_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE_FAIL,
  UPDATE_TEMPLATE_BEGIN,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAIL,
  ADD_TEMPLATE_BEGIN,
  ADD_TEMPLATE_SUCCESS,
  ADD_TEMPLATE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  loading: false,
  success: "",
  templateList: [],
  error: "",
  templateDetails: {},
  editLoading: false,
};

const templateReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TEMPLATE_LISTING_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_TEMPLATE_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        templateList: action.payload,
      };

    case GET_TEMPLATE_LISTING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EDIT_TEMPLATE_BEGIN:
      return {
        ...state,
        templateDetails: {},
        editLoading: true,
        error: "",
        success: "",
      };

    case EDIT_TEMPLATE_SUCCESS:
      return {
        ...state,
        editLoading: false,
        templateDetails: action.payload,
      };

    case EDIT_TEMPLATE_FAIL:
      return {
        ...state,
        editLoading: false,
        error: action.payload,
        templateDetails: {},
      };

    case UPDATE_TEMPLATE_BEGIN:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      };

    case UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        editLoading: false,
        success: action.payload,
      };

    case UPDATE_TEMPLATE_FAIL:
      return {
        ...state,
        editLoading: false,
        error: action.payload,
      };
    case ADD_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case ADD_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case ADD_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default templateReducer;
