/*TEMPLATES */
export const GET_SUPERSTAR_SUGGESTION = "GET_SUPERSTAR_SUGGESTION";
export const GET_SUPERSTAR_SUGGESTION_BEGIN = "GET_SUPERSTAR_SUGGESTION_BEGIN";
export const GET_SUPERSTAR_SUGGESTION_SUCCESS =
  "GET_SUPERSTAR_SUGGESTION_SUCCESS";
export const GET_SUPERSTAR_SUGGESTION_FAIL = "GET_SUPERSTAR_SUGGESTION_FAIL";

export const ADD_SUPERSTAR_SUGGESTION = "ADD_SUPERSTAR_SUGGESTION";
export const ADD_SUPERSTAR_SUGGESTION_BEGIN = "ADD_SUPERSTAR_SUGGESTION_BEGIN";
export const ADD_SUPERSTAR_SUGGESTION_SUCCESS =
  "ADD_SUPERSTAR_SUGGESTION_SUCCESS";
export const ADD_SUPERSTAR_SUGGESTION_FAIL = "ADD_SUPERSTAR_SUGGESTION_FAIL";

export const DELETE_SUPERSTAR_SUGGESTION = "DELETE_SUPERSTAR_SUGGESTION";
export const DELETE_SUPERSTAR_SUGGESTION_BEGIN =
  "DELETE_SUPERSTAR_SUGGESTION_BEGIN";
export const DELETE_SUPERSTAR_SUGGESTION_SUCCESS =
  "DELETE_SUPERSTAR_SUGGESTION_SUCCESS";
export const DELETE_SUPERSTAR_SUGGESTION_FAIL =
  "DELETE_SUPERSTAR_SUGGESTION_FAIL";
