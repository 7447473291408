export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const GET_PAYMENT_METHODS_BEGIN = "GET_PAYMENT_METHODS_BEGIN";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
export const GET_PAYMENT_METHODS_FAIL = "GET_PAYMENT_METHODS_FAIL";

export const GET_PAYMENT_METHODS_BY_ID = "GET_PAYMENT_METHODS_BY_ID";
export const GET_PAYMENT_METHODS_BY_ID_BEGIN =
  "GET_PAYMENT_METHODS_BY_ID_BEGIN";
export const GET_PAYMENT_METHODS_BY_ID_SUCCESS =
  "GET_PAYMENT_METHODS_BY_ID_SUCCESS";
export const GET_PAYMENT_METHODS_BY_ID_FAIL = "GET_PAYMENT_METHODS_BY_ID_FAIL";

export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const ADD_PAYMENT_METHOD_BEGIN = "ADD_PAYMENT_METHOD_BEGIN";
export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS";
export const ADD_PAYMENT_METHOD_FAIL = "ADD_PAYMENT_METHOD_FAIL";

export const EDIT_PAYMENT_METHOD = "EDIT_PAYMENT_METHOD";
export const EDIT_PAYMENT_METHOD_BEGIN = "EDIT_PAYMENT_METHOD_BEGIN";
export const EDIT_PAYMENT_METHOD_SUCCESS = "EDIT_PAYMENT_METHOD_SUCCESS";
export const EDIT_PAYMENT_METHOD_FAIL = "EDIT_PAYMENT_METHOD_FAIL";

export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
export const DELETE_PAYMENT_METHOD_BEGIN = "DELETE_PAYMENT_METHOD_BEGIN";
export const DELETE_PAYMENT_METHOD_SUCCESS = "DELETE_PAYMENT_METHOD_SUCCESS";
export const DELETE_PAYMENT_METHOD_FAIL = "DELETE_PAYMENT_METHOD_FAIL";

export const RESET_PAYMENT_METHOD_ERRORS = "RESET_PAYMENT_METHOD_ERRORS";
