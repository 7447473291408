import {
  ADD_MANUAL_COIN_BEGIN,
  ADD_MANUAL_COIN_SUCCESS,
  ADD_MANUAL_COIN_ERROR,
  DELETE_MANUAL_COIN_BEGIN,
  DELETE_MANUAL_COIN_SUCCESS,
  GET_MANUAL_COIN_BEGIN,
  GET_MANUAL_COIN_ERROR,
  GET_MANUAL_COIN_SUCCESS,
  RESET_MANUAL_COIN_STATES,
} from "./actionTypes";

const initialState = {
  manualCoins: [],
  loading: false,
  error: "",
  addLoading: false,
  success: "",
  userId: "",
  loader: {},
};

function manualCoinReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MANUAL_COIN_BEGIN:
      let message = state.success;
      if (state.userId !== action.payload.user_id) {
        message = "";
      }

      return {
        ...state,
        loading: true,
        error: "",
        manualCoins: [],
        userId: action.payload.user_id,
        success: message,
      };
    case GET_MANUAL_COIN_SUCCESS:
      return {
        ...state,
        manualCoins: action.payload,
        loading: false,
        error: "",
      };
    case GET_MANUAL_COIN_ERROR:
      return {
        ...state,
        loading: false,
        manualCoins: [],
        error: action.payload.message,
        success: "",
      };
    case ADD_MANUAL_COIN_BEGIN:
      return { ...state, addLoading: true, error: "", success: "" };
    case ADD_MANUAL_COIN_SUCCESS:
      return {
        ...state,
        addLoading: false,
        error: "",
        success: action.payload.message,
      };
    case ADD_MANUAL_COIN_ERROR:
      return {
        ...state,
        addLoading: false,
        error: "Something went wrong",
        success: "",
      };
    case DELETE_MANUAL_COIN_BEGIN:
      return { ...state, deleteLoading: true, error: "", success: "" };
    case DELETE_MANUAL_COIN_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        error: "",
        success: action.payload.message,
      };
    case RESET_MANUAL_COIN_STATES:
      return { ...state, error: "", success: "" };

    default:
      return state;
  }
}

export default manualCoinReducer;
