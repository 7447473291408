import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const TotalCoinsList = ({ toggle, modal, tpc, ccff, cctf, rsf }) => {
  return (
    <div>
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Total COiNS Summary</ModalHeader>
        <ModalBody>
          <div className="card-body">
            <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <p className="mb-0">Total Purchased COiNS </p>
              </div>
              <div className="col-sm-4">
                <p className="text-muted text-end ">
                  {tpc}
                  <i className="ms-1">COiNS </i>
                </p>
              </div>
            </div>

            <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <p className="mb-0">Total COiNS Converted from FLiX</p>
              </div>
              <div className="col-sm-4 ">
                <p className="text-muted text-end">
                  {ccff}
                  <i className="ms-1">COiNS </i>
                </p>
              </div>
            </div>

            <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <p className="mb-0">Total COiNS converted to FLiX</p>
              </div>
              <div className="col-sm-4">
                <p className="text-muted mb-0 text-end">
                  {cctf}
                  <i className="ms-1">COiNS </i>
                </p>
              </div>
            </div>

            <div className="row mb-2 border-bottom">
              <div className="col-sm-8">
                <p className="mb-0">Revenue Share for Flashat </p>
              </div>
              <div className="col-sm-4">
                <p className="text-muted mb-0 text-end ">
                  {rsf} <i className="ms-1">COiNS </i>
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TotalCoinsList;
