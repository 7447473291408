import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import Datatable from "../../../components/UI/Tables/Datatable";
import { huddleCategoryColumnData } from "../../../helpers/columns";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { useTranslation } from "react-i18next";
import { flaxTransferPurposeColumnData } from "../../../helpers/columns";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFlaxTransferPurpose,
  getFlaxTransferPurposeByLanguage,
  getFlaxTransferPurposes,
} from "../../../store/flaxTransfer/actions";
import { checkUserPermission } from "../../../helpers/utils";
import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import Loader from "../../../components/Common/Loader";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { axiosApi } from "../../../helpers/api_helper";
import { API_GET_FLAX_TRANSFER_PURPOSES } from "../../../helpers/url_helper";
import { deleteFlaxPackages } from "../../../store/flaxPackages/actions";

const FlaxTransferPurposes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = useSelector((state) => state.FlaxTransfer);
  const loginData = useSelector((state) => state.Login);
  const warningAlertRef = useRef(null);

  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;
  const isDeleteable = userPermissions?.delete || false;
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("english");

  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["Active"],
    page_count: 50,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });

  useEffect(() => {
    request && dispatch(getFlaxTransferPurposes(request, selectedLanguage));
  }, [request, selectedLanguage]);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Huddle Management",
          "Huddle categories"
        )
      );
    }
  }, [loginData]);
  const addNewClickHandler = () => {
    history.push("/FLix-transfer-purposes-addNew");
  };
  // const languageClickhandler = () => {
  //   setSelectedLanguage(selectedLanguage);
  // };
  const formatFlaxTransferPurposeData = useCallback(() => {
    return (
      data?.flaxTransferPurposes?.result?.result &&
      data?.flaxTransferPurposes?.result?.result.length &&
      data?.flaxTransferPurposes?.result?.result?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        Purpose: (
          <div data-tag="allowRowEvents" title={result?.purpose}>
            {result?.purpose}
          </div>
        ),
        actions: (
          <div className="cust-table-actions-wrap">
            <button
              className=" color-violet action-btn"
              title="Edit"
              onClick={() =>
                history.push({
                  pathname: `/FLix-transfer-purpose-edit/${result?.purpose_identifier}`,
                  state: { id: result.id },
                })
              }
              disabled={!isEditable}
            >
              <img
                src={Edit_icon}
                alt="Edit"
                style={{
                  opacity: !isEditable ? "50%" : "",
                  cursor: !isEditable ? "not-allowed" : "",
                }}
              />
            </button>
            <button
              onClick={() => deletePromptHandler(result.id)}
              className=" color-red action-btn"
              title="Delete"
              disabled={!isDeleteable}
            >
              <img
                src={Delete_icon}
                alt="Delete"
                style={{
                  opacity: !isDeleteable ? "50%" : "",
                  cursor: !isDeleteable ? "not-allowed" : "",
                }}
              />
            </button>
          </div>
        ),
      }))
    );
  }, [
    data?.flaxTransferPurposes?.result?.result,
    request,
    isDeleteable,
    isEditable,
  ]);
  const flaxTransferPurposeData = useMemo(
    () => formatFlaxTransferPurposeData(),
    [formatFlaxTransferPurposeData]
  );

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to delete 
      this Purpose?`,
      type: "delete",
    });
  };
  // delete operation
  const okHandler = () => {
    dispatch(
      deleteFlaxTransferPurpose({
        id: promptMessage.id,
        callback: () =>
          dispatch(getFlaxTransferPurposes(request, selectedLanguage)),
      })
    );
  };

  return (
    <div>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        // ref={pageWrapRef}
      >
        <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs
              title="Tables"
              breadcrumbItem="FLix Transfer Purposes"
            />
            {data?.error && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color="danger"
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error}
                </UncontrolledAlert>
              </div>
            )}
            <div className="container">
              <Datatable
                tableID={"adminTable"}
                columns={flaxTransferPurposeColumnData}
                rows={flaxTransferPurposeData}
                setRequest={setRequest}
                request={request}
                // totalRecords={data.adminData?.total}
                // search
                add
                // dropDown
                languages={languages}
                addNewClickHandler={addNewClickHandler}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                addNewLabel={t("Admins.addAdmin.addBtn")}
                // searchTerm={data?.request?.keyword}
                // loading={data?.loading}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
        </Container>

        <ConfirmationAlert
          {...promptMessage}
          modal_center={showPromptPopUp}
          setmodal_center={setShowPromptPopUp}
          onOK={okHandler}
        />
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </div>
  );
};

export default FlaxTransferPurposes;
