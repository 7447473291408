import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { Col, Row, Card, UncontrolledAlert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import BackButton from "../../../../components/UI/BackButton";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import Datatable from "../../../../components/UI/Tables/Datatable";
import Button from "../../../../components/UI/Button";
import ConfirmationAlert from "../../../../components/UI/ConfirmationAlert";

import Loader from "../../../../components/Common/Loader";
import UpdateAllocatedName from "./UpdateAllocatedName";
import UserDetail from "../Shared/UserDetail";

import {
  getAllocateUserNameDetails,
  getAllocateUserHistory,
  deleteSuggestion,
  updateAllocatedUserName,
  allocateUsername,
} from "../../../../store/usernames/allocateUserNames/action";
import { checkUserPermission, convertToUAETime } from "../../../../helpers/utils";
import { DATE_WITH_TIME } from "../../../../helpers/constants";

const EditAllocate = () => {
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [showUpdateAllocatePopup, setShowUpdateAllocatePopup] = useState(false);
  const [updateAllocateMessage, setUpdateAllocateMesage] = useState({});
  const [request, setRequest] = useState({});
  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;
  const isDeleteable = userPermissions?.delete || false;

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useParams();
  const data = useSelector((state) => state.allocatedUsername);
  const status = data?.userDetails?.status;

  useEffect(() => {
    dispatch(getAllocateUserNameDetails(match.id));
    dispatch(getAllocateUserHistory(match.id));
  }, [match.id]);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(loginData?.rolePermissions?.role, "Username Management", "Allocate Username")
      );
    }
  }, [loginData]);

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.allocateError, data?.success]);

  const scrollToAlertMessage = () => {
    if (data?.allocateError || data?.success) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  let redirectMethod = () => {
    history.push({
      pathname: "/allocate-usernames",
      state: { from: "keepFilter" },
    });
  };

  const formatUserData = useCallback(() => {
    return data?.historyDetails?.map((record, index) => ({
      ...record,
      username: <div>{record.current_username}</div>,
      suggestedName: <div>{record.suggested_username}</div>,
      dateAndTime: (
        <div>
          {record.created && convertToUAETime(record.created, DATE_WITH_TIME)}
        </div>
      ),
      status: (
        <div
          className={
            record.status === "Rejected"
              ? "badge bg-soft-red font-size-13"
              : record.status === "Pending"
              ? "badge bg-soft-orange font-size-13"
              : record.status === "Deleted"
              ? "badge bg-soft-gray font-size-13"
              : record.status === "Forcefully Changed"
              ? "badge bg-soft-blue font-size-13"
              : "badge bg-soft-green font-size-13"
          }
        >
          {record.status}
        </div>
      ),
    }));
  });

  //memoized user data
  const userData = useMemo(() => formatUserData(), [formatUserData]);

  const columns = [
    {
      name: t("AllocateUserName.editAllocation.Username"),
      selector: "username",
      minWidth: 250 + "px",
    },
    {
      name: t("AllocateUserName.editAllocation.Suggested/Changed"),
      selector: "suggestedName",
      minWidth: 250 + "px",
    },
    {
      name: t("AllocateUserName.editAllocation.DateAndTime"),
      selector: "dateAndTime",
      minWidth: 200 + "px",
    },
    {
      name: t("AllocateUserName.editAllocation.Status"),
      selector: "status",
      minWidth: 200 + "px",
    },
  ];

  const deleteHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: t("AllocateUserName.editAllocation.DeleteConfirm"),
      type: "delete",
    });
  };

  // Update allocation
  const updateAllocatedOkHandler = (updatedUsername) => {
    if (status === "Pending" || status === "Rejected") {
      // same entry updated if status is pending/rejected
      let payload =
        updateAllocateMessage?.type === "forcefullyChange"
          ? { username: updatedUsername, force: true }
          : { username: updatedUsername, resend: true };
      dispatch(
        updateAllocatedUserName({
          data: {
            ...payload,
          },
          id: data?.userDetails?.id,
          history: history,
          // callback: () => {
          //     dispatch(getAllocateUserNameDetails(match.id))
          //     dispatch(getAllocateUserHistory(match.id))
          // }
        })
      );
    } else {
      //new entry created in table for forcefully changed/accepted/deleted
      updateAllocateMessage?.type === "forcefullyChange"
        ? dispatch(
            allocateUsername({
              data: {
                username: updatedUsername,
                user_id: data?.userDetails?.user?.id,
                force: true,
              },
              history: history,
            })
          )
        : dispatch(
            allocateUsername({
              data: {
                username: updatedUsername,
                user_id: data?.userDetails?.user?.id,
              },
              history: history,
            })
          );
    }
  };

  const updateAllocationHandler = (type) => {
    setShowUpdateAllocatePopup(!showUpdateAllocatePopup);
    setUpdateAllocateMesage({
      userId: data?.userDetails?.user?.id,
      name: data?.userDetails?.user?.name,
      username: data?.userDetails?.user?.username,
      suggestedUserName: data?.userDetails?.suggested_username,
      status: status,
      type: type,
      confirmationMessage:
        type === "forcefullyChange"
          ? `Do you want to forcefully change the username ${
              data?.userDetails?.user?.name
                ? `of ${data?.userDetails?.user?.name}?`
                : "?"
            }`
          : status === "Pending" || status === "Rejected"
          ? "Do you want to suggest this username again ?"
          : "Do you want to suggest this username ?",
    });
  };

  const okHandler = () => {
    if (promptMessage.type === "delete") {
      dispatch(
        deleteSuggestion({
          data: promptMessage.id,
          callback: () => {
            dispatch(getAllocateUserNameDetails(match.id));
            dispatch(getAllocateUserHistory(match.id));
          },
        })
      );
    }
  };

  return (
    <div className="page-content" id="editAllocationUserName" ref={pageWrapRef}>
      <BackButton
        label={t("AllocateUserName.Title")}
        handleClick={() => redirectMethod()}
      />
      <Breadcrumbs
        title="Tables"
        breadcrumbItem={t("AllocateUserName.editAllocation.Title")}
      />
      {(data?.allocateError || data?.success || data?.error) && (
        <div ref={warningAlertRef}>
          <UncontrolledAlert
            color={data?.allocateError || data?.error ? "danger" : "success"}
            className="alert-dismissible fade show"
            role="alert"
          >
            {data?.allocateError || data?.error || data?.success}
          </UncontrolledAlert>
        </div>
      )}

      <div>
        <Col xl="12">
          <Card className="editAllocate_detailWrapper">
            <UserDetail user={data?.userDetails} mode="edit" />

            <hr />
            {status === "Pending" || status === "Rejected" ? (
              <Row className="col-md-12">
                <div className="col-md-4">
                  <label>
                    {t("AllocateUserName.addNew.SuggestedUserName")}
                  </label>
                  <div>{data?.userDetails?.suggested_username}</div>
                </div>
              </Row>
            ) : null}
            <div className="allocateEditButtonWrap">
              <Button
                buttonClass={
                  "btn waves-effect waves-light cust_no_shadow cust-delete-button"
                }
                label={t("AllocateUserName.editAllocation.DeleteSuggestion")}
                handleClick={() => deleteHandler(match.id)}
                disableBtn={data?.userDetails?.status !== "Pending" || !isDeleteable}
              />

              <Button
                buttonClass={
                  "btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                }
                label={t("AllocateUserName.editAllocation.ForcefullyChange")}
                handleClick={() => updateAllocationHandler("forcefullyChange")}
                disableBtn = {!isEditable}
              />

              <Button
                buttonClass={
                  "btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                }
                label={
                  status === "Pending" || status === "Rejected"
                    ? t("AllocateUserName.editAllocation.SendSuggestionAgain")
                    : t("AllocateUserName.editAllocation.SendSuggestion")
                }
                handleClick={() => updateAllocationHandler("suggestAgain")}
                disableBtn = {!isEditable}
              />
            </div>
          </Card>

          <Datatable
            tableID={"editAllocateHistory"}
            columns={columns}
            rows={userData}
            setRequest={setRequest}
            request={request}
            totalRecords={2}
            noPagination
          />
        </Col>
      </div>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
        //disableStatus = {disableForceYes}
      />

      <UpdateAllocatedName
        {...updateAllocateMessage}
        modal_center={showUpdateAllocatePopup}
        setmodal_center={setShowUpdateAllocatePopup}
        onOK={updateAllocatedOkHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default EditAllocate;
