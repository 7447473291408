import {
  GET_LEGAL_DOCUMENTS_BEGIN,
  GET_LEGAL_DOCUMENTS_SUCCESS,
  GET_LEGAL_DOCUMENTS_FAIL,
  EDIT_LEGAL_DOCUMENTS_BEGIN,
  EDIT_LEGAL_DOCUMENTS_SUCCESS,
  EDIT_LEGAL_DOCUMENTS_FAIL,
  UPDATE_LEGAL_DOCUMENTS_BEGIN,
  UPDATE_LEGAL_DOCUMENTS_SUCCESS,
  UPDATE_LEGAL_DOCUMENTS_FAIL,
  VIEW_PUBLISHED_BEGIN,
  VIEW_PUBLISHED_SUCCESS,
  VIEW_PUBLISHED_FAIL,
  DELETE_LEGAL_DOCUMENT_BEGIN,
  DELETE_LEGAL_DOCUMENT_SUCCESS,
  DELETE_LEGAL_DOCUMENT_FAIL,
  ENABLE_DISABLE_LEGAL_DOCUMENTS_BEGIN,
  ENABLE_DISABLE_LEGAL_DOCUMENTS_SUCCESS,
  ENABLE_DISABLE_LEGAL_DOCUMENTS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  data: {},
  document: {},
  error: "",
};

const contentManagement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LEGAL_DOCUMENTS_BEGIN:
      return {
        ...state,
        data: {},
        loading: true,
        error: "",
        success: "",
        document: {},
      };

    case GET_LEGAL_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_LEGAL_DOCUMENTS_FAIL:
      return {
        ...state,
        data: {},
        loading: false,
        error: action.payload,
      };
    case EDIT_LEGAL_DOCUMENTS_BEGIN:
      return {
        ...state,
        document: {},
        editLoading: true,
        error: "",
      };

    case EDIT_LEGAL_DOCUMENTS_SUCCESS:
      return {
        ...state,
        editLoading: false,
        document: action.payload,
      };

    case EDIT_LEGAL_DOCUMENTS_FAIL:
      return {
        ...state,
        document: {},
        editLoading: false,
        error: action.payload,
      };
    case UPDATE_LEGAL_DOCUMENTS_BEGIN:
      return {
        ...state,
        document: {
          ...state?.document,
          privacy_policies: {
            ...state?.document?.privacy_policies,
            description: action?.payload?.description,
            key_changes: action?.payload?.key_changes,
          },
        },
        editLoading: true,
        error: "",
        success: "",
      };

    case UPDATE_LEGAL_DOCUMENTS_SUCCESS:
      return {
        ...state,
        editLoading: false,
        error: "",
        success: action.payload,
      };

    case UPDATE_LEGAL_DOCUMENTS_FAIL:
      return {
        ...state,
        editLoading: false,
        error: action.payload,
        success: "",
      };

    case VIEW_PUBLISHED_BEGIN:
      return {
        ...state,
        published: {},
        isPublishedLoading: true,
        publishedError: "",
      };

    case VIEW_PUBLISHED_SUCCESS:
      return {
        ...state,
        isPublishedLoading: false,
        published: action.payload,
        publishedError: "",
      };

    case VIEW_PUBLISHED_FAIL:
      return {
        ...state,
        published: {},
        isPublishedLoading: false,
        publishedError: action.payload,
      };

    case DELETE_LEGAL_DOCUMENT_BEGIN:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      };

    case DELETE_LEGAL_DOCUMENT_SUCCESS:
      return {
        ...state,
        editLoading: false,
        error: "",
        success: action.payload,
      };

    case DELETE_LEGAL_DOCUMENT_FAIL:
      return {
        ...state,
        editLoading: false,
        error: action.payload,
        success: "",
      };

    case ENABLE_DISABLE_LEGAL_DOCUMENTS_BEGIN:
      return {
        ...state,
        error: "",
      };

    case ENABLE_DISABLE_LEGAL_DOCUMENTS_SUCCESS:
      return {
        ...state,
        error: "",
      };

    case ENABLE_DISABLE_LEGAL_DOCUMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default contentManagement;
