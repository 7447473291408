import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
  UncontrolledAlert,
} from "reactstrap";

import {
  checkUserPermission,
  checkUserViewPermission,
  convertTime,
  convertToUAETime,
} from "../../../../helpers/utils";

import Stars from "../../../../assets/icons/sdfl_icons/stars.svg";
import Dears from "../../../../assets/icons/sdfl_icons/dears.svg";
import Fans from "../../../../assets/icons/sdfl_icons/fans.svg";
import Likers from "../../../../assets/icons/sdfl_icons/likers.svg";
import Rating from "../../../../assets/icons/sdfl_icons/rating.svg";
import Avatar from "../../../../assets/images/users/user.svg";
import Edit_icon from "../../../../assets/icons/ebud-icons/Edit.svg";
import Block_icon from "../../../../assets/icons/ebud-icons/Block.svg";
import UnBlock_icon from "../../../../assets/icons/ebud-icons/Unblock.svg";
import Delete_icon from "../../../../assets/icons/ebud-icons/Delete.svg";
import PremiumImage from "../../../../assets/icons/premium.svg";
import Eye_icon from "../../../../assets/icons/ebud-icons/Eye.svg";
import Coin_bal_icon from "../../../../assets/icons/sdfl_icons/coin-bal-icon.svg";
import Flix_bal_icon from "../../../../assets/icons/sdfl_icons/flix-bal-icon.svg";
import {
  DATE_WITH_TIME,
  DATE_WITH_TIME_IN_SECONDS,
  FE_DATE_FORMAT,
} from "../../../../helpers/constants";
import Crown from "../../../../assets/icons/premium.svg";
import verifedImage from "../../../../assets/icons/verified.svg";
import ImageModal from "../../../HuddleManagement/HuddleDetails/ImageModal";
import PushNotification, { NOTIFICATION_STATUS } from "./PushNotification";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetails,
  resetErrors,
  sendNotification,
  updateNotification,
  updateUserData,
} from "../../../../store/users/actions";
import { useParams } from "react-router";
import ConfirmationAlert from "../../../../components/UI/ConfirmationAlert";
import Loader from "../../../../components/Common/Loader";
import { NotificationList } from "./Notification/NotificationList";
import { alert } from "../../../../util/toast";
import Datatable from "../../../../components/UI/Tables/Datatable";
import { getReportedUserById } from "../../../../store/reportedUser/action";
import reportedUser from "../../../../store/reportedUser/reducer";
import { reportedData } from "../../../../helpers/columns";
import Checkbox from "../../../../components/UI/Checkbox";
import FlaxRates from "./FlaxRates";
import PodiumCameraConfirmationAlert from "./PodiumCameraConfirmationAlert";
import FlaxStatements from "./FlaxStatements";
import CoinsStatements from "./CoinsStatements";
import CameraPurchaseHistory from "./PodiumCameraPurchaseHistory";
import EditRating from "./EditRating";
import moment from "moment";

const NOTIFICATION_MODEL = {
  status: undefined,
  title: "",
  message: "",
  push: false,
  notificationType: "",
};

const UserDetail = ({
  data,
  rptData,
  reportedUserData,
  selectedIndex,
  editUserDetailsHandler,
  deletePromptHandler,
  blockPromptHandler,
  userPermissions,
  request,
  setRequest,
  userId,
}) => {
  const loginData = useSelector((state) => state.Login);

  const dispatch = useDispatch();

  const params = useParams();
  const [modalShow, setmodalShow] = useState(false);
  const [pushmodalShow, setPushmodalShow] = useState(false);
  const [broadcastPermission, setBroadCastPermission] = useState({});
  const [isAdminPopupVisible, setAdminPopupVisibility] = useState(false);
  const [dataChanged, setDatachanged] = useState(false);

  const [notification, setNotification] = useState(NOTIFICATION_MODEL);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [flaxRatesModal, setFlaxRatesModal] = useState(false);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [flixStatementsModal, setFlixStatementsModal] = useState(false);
  const [coinsStatementsModal, setCoinsStatementsModal] = useState(false);
  const [purchaseHistoryModal, setPurchaseHistoryModal] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [rolePermisions, setRolePermisions] = useState({});

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setRolePermisions(loginData?.rolePermissions?.role);
    }
  }, [loginData]);

  const isEmpowermentEnabled = checkUserViewPermission(
    rolePermisions,
    "User Management",
    "User Empowerments"
  );

  const isFlixStatementsEnabled = checkUserViewPermission(
    rolePermisions,
    "Other Permissions",
    "Flix statement"
  );
  const isCoinsStatementsEnabled = checkUserViewPermission(
    rolePermisions,
    "Other Permissions",
    "Coins statement"
  );

  const [checkBoxValues, setCheckBoxValues] = useState({
    location_country_flag: true,
    allow_change_location: false,
    allow_hide_country_flag: false,
    display_country_flag: false,
    enable_camera_podium: false,
    camera_violation: false,
  });
  const [clickedToggle, setClickedToggle] = useState("");

  const handleCheckboxChange = (checkboxName) => {
    setClickedToggle(checkboxName);

    setCheckBoxValues((prevValues) => ({
      ...prevValues,
      [checkboxName]: !prevValues[checkboxName],
    }));
  };
  const updateDetails = () => {
    dispatch(getUserDetails(userId));
  };

  useEffect(() => {
    const file = "";
    if (clickedToggle !== "" && clickedToggle !== "enable_camera_podium") {
      const dataFields = {
        id: userId,
        allow_change_location: checkBoxValues.allow_change_location,
        allow_hide_country_flag: checkBoxValues.allow_hide_country_flag,
        display_country_flag: checkBoxValues.display_country_flag,
        location_country_flag: true,
        enable_camera_podium: checkBoxValues?.enable_camera_podium,
        camera_violation: false,
      };
      dispatch(updateUserData(dataFields, file, updateDetails));

      setClickedToggle("");
      setDatachanged(!dataChanged);
    }
    if (
      clickedToggle === "enable_camera_podium" &&
      checkBoxValues?.enable_camera_podium
    ) {
      const dataFields = {
        id: userId,
        allow_change_location: checkBoxValues.allow_change_location,
        allow_hide_country_flag: checkBoxValues.allow_hide_country_flag,
        display_country_flag: checkBoxValues.display_country_flag,
        location_country_flag: true,
        enable_camera_podium: checkBoxValues?.enable_camera_podium,
        camera_violation: false,
      };
      dispatch(updateUserData(dataFields, file, updateDetails));

      setClickedToggle("");
      setDatachanged(!dataChanged);
    }
  }, [clickedToggle]);

  useEffect(() => {
    if (!data?.loading) {
      setCheckBoxValues({
        allow_hide_country_flag: data?.userDetails?.allow_hide_country_flag,
        allow_change_location: data?.userDetails?.allow_change_location,
        display_country_flag: data?.userDetails?.display_country_flag,
        location_country_flag: data?.userDetails?.location_country_flag,
        enable_camera_podium: data?.userDetails?.enable_camera_podium,
        camera_violation: data?.userDetails?.camera_violation,
      });
    }
  }, [data?.userDetails, data?.loading]);

  const closePopup = () => {
    setCheckBoxValues({
      ...checkBoxValues,
      enable_camera_podium: data?.userDetails?.enable_camera_podium,
    });
  };

  const cameraAlert = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      title: "",
      content: `Are you disabling Camera for the user due to Violation of  Flashat Community Guidelines?`,
      type: "camera-alert",
    });
  };

  // this Ok handler handles the Off case for podium camera

  const okHandler = ({ cameraViolation }) => {
    if (promptMessage.type === "camera-alert") {
      const file = "";
      const dataFields = {
        id: userId,
        allow_change_location: checkBoxValues.allow_change_location,
        allow_hide_country_flag: checkBoxValues.allow_hide_country_flag,
        display_country_flag: checkBoxValues.display_country_flag,
        location_country_flag: true,
        enable_camera_podium: checkBoxValues?.enable_camera_podium,
        camera_violation: cameraViolation,
      };
      dispatch(updateUserData(dataFields, file, updateDetails));
      setClickedToggle("");
    }
  };
  const toggleNotification = () => {
    setIsOpen1(!isOpen1);
    setIsOpen2(false); // Close the other div
  };

  const toggleViolations = () => {
    setIsOpen2(!isOpen2);
    setIsOpen1(false); // Close the other div
  };
  const flaxratesShow = () => {
    setFlaxRatesModal(!flaxRatesModal);
  };
  const flixStatementsShow = () => {
    setFlixStatementsModal(!flixStatementsModal);
  };
  const coinsStatementsShow = () => {
    setCoinsStatementsModal(!coinsStatementsModal);
  };
  const cameraPurchasesShow = () => {
    setPurchaseHistoryModal(!purchaseHistoryModal);
  };

  const onNotificationChange = (event) => {
    const { name, value } = event.target;
    setNotification({ ...notification, [name]: value });
  };

  const toggleLeadership = (is_leader) => {
    dispatch(updateUserData({ is_leader: !is_leader, id: params?.id }));
  };

  const toggle = () => {
    setmodalShow(!modalShow);
  };

  const pushToggle = () => {
    setNotification(NOTIFICATION_MODEL);
    setPushmodalShow(!pushmodalShow);
  };

  const openEditModal = () => {
    setPushmodalShow(!pushmodalShow);
  };
  const editRate = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setBroadCastPermission(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Settings",
          "Broadcast notification"
        )
      );
    }
  }, [loginData]);
  useEffect(() => {
    return () => {
      dispatch(resetErrors());
    };
  }, []);

  const isEditable = userPermissions?.edit || false;
  const isDeleteable = userPermissions?.delete || false;
  const isBroadCastPermission = broadcastPermission?.view || false;

  const handlePushNotificationSubmit = (e) => {
    e.preventDefault();
    const { notificationType, ...not } = notification;
    const status = NOTIFICATION_STATUS[notificationType];
    const data = {
      user_id: params?.id,
      type: notification.push ? "push" : "app",
      ...not,
      status,
    };
    if (!notification.id) {
      dispatch(
        sendNotification(data, () => {
          pushToggle();
          setNotification(NOTIFICATION_MODEL);
        })
      );
    } else {
      dispatch(
        updateNotification(data, () => {
          pushToggle();
          setNotification(NOTIFICATION_MODEL);
          alert.success("Notification has been upadated");
        })
      );
    }
  };

  return (
    <>
      {data?.success ||
        (reportedUserData?.success && (
          <div>
            <UncontrolledAlert
              color="success"
              className="alert-dismissible fade show"
              role="alert"
            >
              {data?.success || reportedUserData?.success}
            </UncontrolledAlert>
          </div>
        ))}
      {data?.error &&
      data?.error !=
        "This user cannot be deleted. Delete the huddles created by this user and try again." ? (
        <div>
          <UncontrolledAlert
            color="danger"
            className="alert-dismissible fade show"
            role="alert"
          >
            {data?.error}
          </UncontrolledAlert>
        </div>
      ) : (
        <>
          {data?.error ==
          "This user cannot be deleted. Delete the huddles created by this user and try again." ? (
            <div>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            </div>
          ) : null}
          <Col xl="12" data-testid="component-userDetails">
            <div className="text-center">
              <div className="clearfix"></div>
              <div
                className="userDetails_profilePic cursor-pointer"
                onClick={toggle}
              >
                <img
                  src={data?.userDetails?.profile_photo || Avatar}
                  alt=""
                  className="avatar-lg img-thumbnail p-0"
                />
                {data?.userDetails?.verified ? (
                  <img src={verifedImage} className="userDetails_crown" />
                ) : data?.userDetails?.is_premium ? (
                  <img src={Crown} className="userDetails_crown" />
                ) : null}
              </div>
              <h5 className="mt-3 mb-1">{data?.userDetails?.name}</h5>
              <div className="d-flex justify-content-center align-items-center">
                <p className="text-muted">{data?.userDetails?.username} </p>

                <div className="badge bg-soft-success mx-3 mb-3 font-size-8">
                  {data?.userDetails?.citizenship}
                </div>
              </div>
            </div>
            <div className="userDetailStarStatus">
              <div className="sdfl_border">
                <div className="starBox">
                  <img src={Stars} alt="Stars" />
                  <div className="mt-1">
                    <span className="sdfl_label">
                      {data?.userDetails?.stars}
                    </span>
                    <span>Stars</span>
                  </div>
                </div>
                <div className="starBox">
                  <img src={Dears} alt="Dears" />
                  <div className="mt-1">
                    <span className="sdfl_label">
                      {data?.userDetails?.dears}
                    </span>
                    <span>Dears</span>
                  </div>
                </div>
                <div className="starBox">
                  <img src={Fans} alt="Fans" />
                  <div className="mt-1">
                    <span className="sdfl_label">
                      {data?.userDetails?.fans}
                    </span>
                    <span>Fans</span>
                  </div>
                </div>
                <div className="starBox">
                  <img src={Likers} alt="Likers" />
                  <div className="mt-1">
                    <span className="sdfl_label">
                      {data?.userDetails?.likers}
                    </span>
                    <span>Likers</span>
                  </div>
                </div>

                <div className="starBox">
                  <img onClick={flaxratesShow} src={Rating} alt="Likers" />
                  <div className="mt-1">
                    <span className="sdfl_label ">
                      {data?.userDetails?.flax_rate_percentage &&
                        data?.userDetails?.flax_rate_percentage}
                    </span>
                    <span>% Rating</span>
                    <span className="ms-2">
                      <img onClick={editRate} src={Edit_icon} alt="edit" />
                    </span>
                  </div>
                </div>

                {editMode && (
                  <EditRating
                    modal={editMode}
                    toggle={editRate}
                    userId={userId}
                    currentRate={data?.userDetails?.flax_rate_percentage}
                  />
                )}
              </div>
            </div>
            {flaxRatesModal && (
              <FlaxRates
                modal={flaxRatesModal}
                toggle={flaxratesShow}
                userId={userId}
              />
            )}
            {flixStatementsModal && (
              <FlaxStatements
                modal={flixStatementsModal}
                toggle={flixStatementsShow}
                userId={userId}
              />
            )}
            {coinsStatementsModal && (
              <CoinsStatements
                modal={coinsStatementsModal}
                toggle={coinsStatementsShow}
                userId={userId}
              />
            )}
            <div className="userDetailStarStatus">
              <div className="sdfl_border">
                <div className="starBox">
                  <img src={Flix_bal_icon} alt="Likers" />
                  <div className="mt-1">
                    <span className="sdfl_label ">
                      {data?.userDetails?.total_flax}
                    </span>
                    <span>FLiX</span>
                  </div>
                </div>

                <div className="starBox">
                  <img src={Coin_bal_icon} alt="Likers" />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                    }}
                    className="mt-1"
                  >
                    <span
                      className="sdfl_label "
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data?.userDetails?.total_coins}
                    </span>
                    <span>COiNS</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="userDetailLinks col-12 col-sm-12 col-md-12 col-lg-4">
                {isFlixStatementsEnabled && (
                  <button
                    className="action-buttons text-primary"
                    // title="Edit"
                    onClick={flixStatementsShow}
                    // disabled={!isStatementsEnabled}
                    style={{
                      // opacity: !isBroadCastPermission ? "50%" : "",
                      fontSize: "15px",
                    }}
                  >
                    <span className="text-decoration-underline">
                      FLiX Statements
                    </span>
                  </button>
                )}
                {isCoinsStatementsEnabled && (
                  <button
                    className="action-buttons text-primary  "
                    // title="Edit"
                    onClick={coinsStatementsShow}
                    // disabled={!isBroadCastPermission}
                    style={{
                      // opacity: !isBroadCastPermission ? "50%" : "",
                      fontSize: "15px",
                    }}
                  >
                    <span className="text-decoration-underline">
                      COiNS Statements
                    </span>
                  </button>
                )}
              </div>

              <div className="userDetailActions col-12 col-sm-12 col-md-12 col-lg-8">
                {/* <button
                  hidden={data?.userDetails?.blocked}
                  className="action-buttons"
                  onClick={() => setAdminPopupVisibility(true)}
                  style={{ opacity: !isBroadCastPermission ? "50%" : "" }}
                >
                  <span>
                    {" "}
                    {data?.userDetails?.is_leader
                      ? "DISMISS LEADERSHIP"
                      : "APPOINT AS LEADER"}
                  </span>
                </button> */}
                <button
                  className="action-buttons"
                  // title="Edit"
                  onClick={() => pushToggle()}
                  disabled={!isBroadCastPermission}
                  style={{ opacity: !isBroadCastPermission ? "50%" : "" }}
                >
                  {/* <img
                  src={Edit_icon}
                  alt="Edit"
                  // style={{ opacity: !isEditable ? "50%" : "" }}
                />{" "} */}
                  <i class="fas fa-bell "></i> <span>SEND NOTIFICATION</span>
                </button>
                <button
                  className="action-buttons"
                  title="Edit"
                  onClick={() => editUserDetailsHandler()}
                  disabled={!isEditable}
                >
                  <img
                    src={Edit_icon}
                    alt="Edit"
                    style={{ opacity: !isEditable ? "50%" : "" }}
                  />{" "}
                  <span>EDIT</span>
                </button>
                <button
                  className="action-buttons"
                  title={data?.userDetails?.blocked ? "Unblock" : "Block"}
                  onClick={() => blockPromptHandler()}
                  disabled={!isEditable}
                >
                  <img
                    src={data?.userDetails?.blocked ? UnBlock_icon : Block_icon}
                    alt={data?.userDetails?.blocked ? "Block" : "Unblock"}
                    style={{ opacity: !isEditable ? "50%" : "" }}
                  />
                  <span>
                    {data?.userDetails?.blocked ? "UNBLOCK" : "BLOCK"}
                  </span>
                </button>
                <button
                  className="action-buttons"
                  title="Delete"
                  onClick={() => deletePromptHandler()}
                  disabled={!isDeleteable}
                >
                  <img
                    src={Delete_icon}
                    alt="Delete"
                    style={{ opacity: !isDeleteable ? "50%" : "" }}
                  />
                  <span className="color-red">DELETE</span>
                </button>
              </div>
              <hr className="my-4" />
            </div>

            <div className="table-responsive mt-4">
              <Row className="col-md-12 userDetailsWrapper">
                <div className="col-md-6">
                  <div className="mt-0">
                    <h5 className="font-size-16">Created On</h5>
                    <p className="mb-1">
                      {data?.userDetails?.created_on &&
                        convertToUAETime(
                          data?.userDetails?.created_on,
                          DATE_WITH_TIME_IN_SECONDS
                        )}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h5 className="font-size-16">Date Of Birth</h5>
                    <p className="mb-1">{data?.userDetails?.dob}</p>
                  </div>
                  <div className="mt-4">
                    <h5 className="font-size-16">Gender</h5>
                    <p className="mb-1">{data?.userDetails?.gender}</p>
                  </div>
                  <div className="mt-4">
                    <h5 className="font-size-16">E-mail Address</h5>
                    <p className="mb-1">{data?.userDetails?.email}</p>
                  </div>
                  <div className="mt-4">
                    <h5 className="font-size-16">Location</h5>
                    <p className="mb-1">
                      <div>
                        Latitude: {data?.userDetails?.latitude}, Longitude:{" "}
                        {data?.userDetails?.longitude}
                      </div>
                      <div className="text-muted">
                        {data?.userDetails?.country}
                      </div>
                    </p>
                  </div>
                  {isEmpowermentEnabled && (
                    <div>
                      <div className="mt-4">
                        <div className="d-flex row  align-items-center">
                          <div className="col-7">
                            <h5 className="font-size-16">
                              Allow To Change Location
                            </h5>
                            <p
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              Provide provision for the user to Change Location
                              in the app
                            </p>
                          </div>
                          <div className=" col-5 form-check form-switch form-switch-lg enableDocumentSwitchWrap mt-2 ">
                            <div className="px-5 ">
                              <Checkbox
                                value={checkBoxValues?.allow_change_location}
                                id={"allow_change_location"}
                                name="push"
                                handleChange={() =>
                                  handleCheckboxChange("allow_change_location")
                                }
                                className="form-check-input"
                              />
                            </div>
                            <span
                              style={{
                                fontWeight: "bold",
                                textIndent: "30px",
                                fontSize: "15px",
                              }}
                              className="text-danger mt-1 text-left   text-bold"
                            >
                              {checkBoxValues?.allow_change_location
                                ? "ON"
                                : "OFF"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 mb-3">
                        <div className="d-flex row  align-items-center ">
                          <div className="col-7">
                            <h5 className="font-size-16 ">
                              Allow To Hide/Unhide Country flag
                            </h5>{" "}
                            <p
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              Provide provision for the user to Hide/Show
                              Country Flag in the app
                            </p>
                          </div>
                          <div className=" col-5 form-check form-switch form-switch-lg enableDocumentSwitchWrap mt-2 ">
                            <div className="px-5 ">
                              <Checkbox
                                value={checkBoxValues?.allow_hide_country_flag}
                                id={"allow_hide_country_flag"}
                                name="push"
                                handleChange={() =>
                                  handleCheckboxChange(
                                    "allow_hide_country_flag"
                                  )
                                }
                                className="form-check-input"
                              />
                            </div>
                            <span
                              style={{
                                fontWeight: "bold",
                                textIndent: "30px",
                                fontSize: "15px",
                              }}
                              className="text-danger mt-1 text-left   text-bold"
                            >
                              {checkBoxValues?.allow_hide_country_flag
                                ? "ON"
                                : "OFF"}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 mb-3">
                        <div className="d-flex row  align-items-center">
                          <div className="col-7">
                            <h5 className="font-size-16">
                              Display Country flag
                            </h5>
                            <p
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              Hide/Show Country Flag of the user
                            </p>
                          </div>
                          <div className=" col-5 form-check form-switch form-switch-lg enableDocumentSwitchWrap mt-2 ">
                            <div className="px-5">
                              <Checkbox
                                value={checkBoxValues?.display_country_flag}
                                id={"display_country_flag"}
                                name="push"
                                handleChange={() =>
                                  handleCheckboxChange("display_country_flag")
                                }
                                className="form-check-input"
                              />
                            </div>
                            <span
                              style={{
                                fontWeight: "bold",
                                textIndent: "30px",
                                fontSize: "15px",
                              }}
                              className="text-danger  mt-1 text-left  text-bold"
                            >
                              {checkBoxValues?.display_country_flag
                                ? "ON"
                                : "OFF"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 mb-3">
                        <div className="d-flex row  align-items-center">
                          <div className="col-7">
                            <h5 className="font-size-16">Enable Camera</h5>
                            <p
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              Enable Camera in Podium
                            </p>
                          </div>
                          <div className=" col-5 form-check form-switch form-switch-lg enableDocumentSwitchWrap mt-2 ">
                            <div className="px-5 ">
                              <Checkbox
                                value={checkBoxValues?.enable_camera_podium}
                                id={"enable_camera_podium"}
                                name="push"
                                handleChange={() => {
                                  // setCameraToggleClicked(true);
                                  if (checkBoxValues?.enable_camera_podium) {
                                    cameraAlert();
                                  }
                                  handleCheckboxChange("enable_camera_podium");
                                }}
                                className="form-check-input"
                              />
                            </div>
                            <span
                              style={{
                                fontWeight: "bold",
                                textIndent: "30px",
                                fontSize: "15px",
                              }}
                              className="text-danger text-left  mt-1   text-bold"
                            >
                              {checkBoxValues?.enable_camera_podium
                                ? "ON"
                                : "OFF"}
                            </span>
                            <div
                              style={{
                                fontSize: "12px",
                              }}
                              className="text-success"
                            >
                              {data?.userDetails?.camera_expiry && (
                                <>
                                  Camera Available till :{" "}
                                  {moment
                                    ?.utc(data?.userDetails?.camera_expiry)
                                    .local()
                                    .format("DD/MM/YYYY")}
                                  <br />
                                  {moment
                                    ?.utc(data?.userDetails?.camera_expiry)
                                    .local()
                                    .format("LT")}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <p
                          onClick={cameraPurchasesShow}
                          className="text-primary  text-decoration-underline cursor-pointer"
                        >
                          Podium Camera Purchase History
                        </p>
                      </div>
                    </div>
                  )}

                  {purchaseHistoryModal && (
                    <CameraPurchaseHistory
                      modal={purchaseHistoryModal}
                      toggle={cameraPurchasesShow}
                      userId={userId}
                    />
                  )}
                </div>

                <div className="col-md-6">
                  <div className="mt-0">
                    <h5 className="font-size-16">Status</h5>
                    <p className="mb-1">
                      {data?.userDetails?.account_status === "Active" ? (
                        <div className="badge bg-soft-success font-size-15">
                          Active
                        </div>
                      ) : data?.userDetails?.account_status === "Inactive" ? (
                        <div className="badge bg-soft-warning">Inactive</div>
                      ) : data?.userDetails?.account_status === "Blocked" ? (
                        <div className="badge bg-soft-danger">Blocked</div>
                      ) : null}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h5 className="font-size-16">Mobile Number</h5>
                    <p className="mb-1">
                      {data?.userDetails?.country_code}{" "}
                      {data?.userDetails?.phone}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h5 className="font-size-16">Device Type</h5>
                    <p className="mb-1">{data?.userDetails?.device}</p>
                  </div>
                  <div className="mt-4">
                    <h5 className="font-size-16">Payment Date</h5>
                    <p className="mb-1">
                      {data?.userDetails?.payment_date &&
                        convertToUAETime(
                          data?.userDetails?.payment_date,
                          FE_DATE_FORMAT
                        )}
                    </p>
                  </div>
                  <div className="mt-4">
                    <h5 className="font-size-16">About</h5>
                    <p>{data?.userDetails?.about}</p>
                  </div>
                </div>
              </Row>
            </div>
          </Col>
          <Col col="12" className="mt-4">
            <div
              className="d-flex col-md-1 cursor-pointer"
              onClick={toggleNotification}
            >
              <div>
                <h5 className="bold">Notifications</h5>
              </div>
              <div className="ms-3">
                <i
                  className={`${
                    isOpen1 ? "fas fa-chevron-down" : "fas fa-chevron-down"
                  }`}
                ></i>
              </div>
            </div>
            {!pushmodalShow && isOpen1 && (
              <NotificationList
                setNotification={setNotification}
                toggle={openEditModal}
                onNotificationChange={onNotificationChange}
                selectedNotification={notification}
              />
            )}
          </Col>
          {/* <Col col="12" className="mt-4">
            <div
              className="d-flex col-md-1 cursor-pointer mb-2"
              onClick={toggleViolations}
            >
              <div>
                <h5 className="bold">Violations</h5>
              </div>
              <div className="ms-3">
                <i
                  className={`${
                    isOpen2 ? "fas fa-chevron-down" : "fas fa-chevron-down"
                  }`}
                ></i>
              </div>
            </div>

            {isOpen2 && (
              <Datatable
                columns={reportedData}
                rows={rptData}
                request={request}
                setRequest={setRequest}
                totalRecords={reportedUserData?.reportedUserData?.total}
                loading={reportedUserData?.loading}
              />
            )}
          </Col> */}

          <ImageModal
            modal_center={modalShow}
            setmodal_center={setmodalShow}
            path={data?.userDetails?.profile_photo || Avatar}
            toggle={toggle}
          />

          <PushNotification
            modal_center={pushmodalShow}
            setmodal_center={setPushmodalShow}
            tog_center={pushToggle}
            handleSubmit={handlePushNotificationSubmit}
            notification={notification}
            onNotificationChange={onNotificationChange}
            data={data}
          />
        </>
      )}
      <ConfirmationAlert
        content={
          data?.userDetails?.is_leader
            ? `Do you want to dismiss "${data?.userDetails?.name}" from leadership?`
            : `Do you want to make "${data?.userDetails?.name}" as an leader?`
        }
        modal_center={isAdminPopupVisible}
        title={"Are you sure?"}
        setmodal_center={() => setAdminPopupVisibility(false)}
        onOK={() => toggleLeadership(data?.userDetails?.is_leader)}
      />

      <PodiumCameraConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
        citizenShip={data?.userDetails.citizenship}
        onClose={closePopup}
        cameraPurchased={data?.userDetails?.camera_expiry}
      />

      {/* {data?.loading && <Loader darkBg={true} />} */}
      {/* {reportedUserData?.loading && <Loader darkBg={true} />} */}
    </>
  );
};

export default UserDetail;
