import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router";
import {
  Card,
  Col,
  Container,
  Input,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Common/Loader";
import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import {
  editReportedUsersCategories,
  getReportedUsersCategories,
  getReportedUsersCategoriesById,
  resetMessage,
} from "../../../store/reportedUser/action";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import { AvForm } from "availity-reactstrap-validation";

const EditReportedUsersCategory = () => {
  const [category, setCategory] = useState("");
  const history = useHistory();
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const { t } = useTranslation();
  const [backToListing, setBackToListing] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const params = useParams();
  const data = useSelector((state) => state.reportedUser);
  const dispatch = useDispatch();
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);
  const [categoryName, setCategoryName] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [categoryText, setCategoryText] = useState("");
  const [categoryList, setCategoryList] = useState([
    {
      id: "",
      category_text: "",
      category_type: "",
      created_by: "",
      category: "",
      language: "",
    },
  ]);

  const [newCategoryList, setNewCategoryList] = useState([]);

  useEffect(() => {
    for (let i = 0; i < newCategoryList?.length; i++) {
      if (
        newCategoryList[i]?.category_text == "" ||
        newCategoryList?.length + categoryList?.length !=
          data?.reportedUserCategories?.languages?.length - 1
      ) {
        setDisabled(true);
        break;
      } else {
        setDisabled(false);
      }
    }
  }, [newCategoryList]);

  useEffect(() => {
    if (categoryText == "" || categoryType == "" || categoryName == "") {
      return setDisabled(true);
    } else {
      setDisabled(false);
    }
    for (let i = 0; i < categoryList?.length; i++) {
      if (
        categoryList[i]?.category_text == "" ||
        newCategoryList?.length + categoryList?.length !=
          data?.reportedUserCategories?.languages?.length - 1
      ) {
        setDisabled(true);
        break;
      } else {
        setDisabled(false);
      }
    }
  }, [categoryList, categoryName, categoryType, categoryText]);

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error, data?.success]);

  const scrollToAlertMessage = () => {
    if (data?.error || data?.success) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {
    data?.reportedUserCategoryData?.category_text &&
      setCategoryText(data?.reportedUserCategoryData?.category_text);
    data?.reportedUserCategoryData?.category_type &&
      setCategoryType(data?.reportedUserCategoryData?.category_type);
    data?.reportedUserCategoryData?.category &&
      setCategoryName(data?.reportedUserCategoryData?.category);
  }, [data?.reportedUserCategoryData?.category]);

  useEffect(() => {
    data?.reportedUserCategoryData?.category_details?.length > 0
      ? setCategoryList(data?.reportedUserCategoryData?.category_details)
      : setCategoryList([
          {
            id: "",
            category_text: "",
            category_type: "",
            created_by: "",
            category: "",
            language: "",
          },
        ]);
  }, [data?.reportedUserCategoryData?.category_details]);

  const handlesuperStarInputChange = (index, key, value) => {
    const list = [...categoryList];
    list[index][key] = value;
    setCategoryList(list);
  };

  useEffect(() => {
    dispatch(getReportedUsersCategories());
    dispatch(getReportedUsersCategoriesById({ id: params?.id }));
    dispatch(resetMessage());
  }, []);

  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

  const okHandler = () => {
    history.push("/reported-user-categories");
  };

  useEffect(() => {
    if (category) setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  }, [category]);

  useEffect(() => {
    backToListing && history.push("/reported-user-categories");
  }, [backToListing, params.id, history]);

  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };

  const confirmBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
  };

  const redirectMethod = () => {
    dispatch(resetMessage());
    setBackToListing(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const englishArr = [
      {
        category_text: categoryText,
        category_type: categoryType,
        created_by: data?.reportedUserCategoryData?.created_by,
        category: categoryName,
        language: data?.reportedUserCategoryData?.language,
      },
    ];
    const objs = convertArrayToObject(
      [...categoryList, ...newCategoryList, ...englishArr],
      "language"
    );
    dispatch(editReportedUsersCategories({ category: objs, id: params?.id }));
  };

  const handleReportUsersChangeOrAdd = (index, key, value, language) => {
    const list = [...newCategoryList];
    if (list[index]) {
      list[index][key] = value;
      list[index]["language"] = language;
      setNewCategoryList(list);
    } else {
      list[index] = {
        id: "",
        category_text: value,
        category_type: "",
        created_by: "",
        category: "",
        language: language,
      };
      setNewCategoryList(list);
    }
  };

  const handleCategory = (name, value) => {
    if (name == "category_type") {
      setCategoryType(value);
    } else if (name == "category") {
      setCategoryName(value);
    } else {
      setCategoryText(value);
    }
  };

  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            return confirmBrowserBack();
          }
        }}
        when={formChanged}
      />
      <BackButton
        label="Reported User Categories"
        handleClick={() => (formChanged ? confirmBack() : redirectMethod())}
      />
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Edit Category" />
          {(data?.error || data?.success) && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color={data?.error ? "danger" : "success"}
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error || data?.success}
              </UncontrolledAlert>
            </div>
          )}
          <Col xl="8">
            <Card className="mb-0 p-4 my-4">
              <div className="text-muted">
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleSubmit(e, v);
                  }}
                >
                  <div
                    className="table-responsive mt-4"
                    style={{ overflowX: "hidden" }}
                  >
                    <div className="col-md-12">
                      <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Category Type
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="text"
                              className="form-control mb-4 "
                              value={categoryType}
                              id="name-input"
                              onChange={(e) => {
                                handleCategory("category_type", e.target.value);
                                // handlesuperStarInputChange(
                                //   0,
                                //   "category_type",
                                //   e.target.value
                                // );
                              }}
                              placeholder="Enter Category Type"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Category
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="text"
                              className="form-control mb-4 "
                              value={categoryName}
                              id="name-input"
                              onChange={(e) => {
                                handleCategory("category", e.target.value);
                                // handlesuperStarInputChange(
                                //   0,
                                //   "category",
                                //   e.target.value
                                // );
                              }}
                              placeholder="Enter Category Text"
                            />
                          </div>
                        </div>
                      </div>
                      {data?.reportedUserCategoryData?.language && (
                        <div className="d-flex mx-1">
                          <label className="col-md-2 lang-font col-form-label">
                            {`${data?.reportedUserCategoryData?.language
                              ?.slice(0, 1)
                              .toUpperCase()}${data?.reportedUserCategoryData?.language?.slice(
                              1
                            )}`}
                          </label>

                          <div className="col-md-6">
                            <div className="col-md-8 dobWrapper">
                              <AvInput
                                name="adminName"
                                type="text"
                                className="form-control mb-4 "
                                value={categoryText}
                                id="name-input"
                                onChange={(e) => {
                                  handleCategory(
                                    "category_text",
                                    e.target.value
                                  );
                                  // handlesuperStarInputChange(
                                  //   0,
                                  //   "category_text",
                                  //   e.target.value
                                  // );
                                }}
                                placeholder="Enter Category"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {categoryList?.length &&
                      categoryList?.map((category, i) => (
                        <div className=" d-flex mx-1">
                          <label className="col-md-2 lang-font col-form-label">
                            {`${category?.language
                              ?.slice(0, 1)
                              .toUpperCase()}${category?.language?.slice(1)}`}
                          </label>
                          <div className="col-md-6">
                            <div className="col-md-8 dobWrapper">
                              <AvInput
                                name="adminName"
                                type="text"
                                className="form-control mb-4 "
                                value={category?.category_text}
                                id="name-input"
                                onChange={(e) => {
                                  handlesuperStarInputChange(
                                    i,
                                    "category_text",
                                    e.target.value
                                  );
                                }}
                                placeholder="Enter Category Text"
                              />
                            </div>
                          </div>
                        </div>
                      ))}

                    {data?.reportedUserCategoryData?.languages?.length
                      ? data?.reportedUserCategoryData?.languages?.map(
                          (language, i) => (
                            <Row className="col-md-12 editAdminRow d-flex">
                              <h3 className="font-weight-bold">{`${language
                                ?.slice(0, 1)
                                .toUpperCase()}${language?.slice(1)}`}</h3>{" "}
                              <div className="col-md-9">
                                <label className="col-md-8 col-form-label">
                                  Enter Category Text
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="col-md-8 dobWrapper">
                                  <AvInput
                                    name="adminName"
                                    type="text"
                                    className="form-control mb-4 "
                                    value={category?.category_text}
                                    id="name-input"
                                    onChange={(e) => {
                                      handleReportUsersChangeOrAdd(
                                        i + 1,
                                        "category_text",
                                        e.target.value,
                                        language
                                      );
                                    }}
                                    placeholder="Enter Category"
                                  />
                                </div>
                              </div>
                            </Row>
                          )
                        )
                      : null}

                    <div className="mt-4">
                      <button
                        type="submit"
                        disabled={disabled}
                        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                        // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </AvForm>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>

      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default EditReportedUsersCategory;
