import React, { useState, useEffect, useRef } from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";

import defaultProfile from "../../../assets/images/users/user.svg";
import premiumImage from "../../../assets/icons/verified.svg"
import { userRequestColumnData } from "../../../helpers/columns";
import { checkUserPermission, convertToUAETime } from "../../../helpers/utils";
import { FE_DATE_FORMAT } from "../../../helpers/constants";
import {
  addAccountVerificationStatus,
  getAccountVerificationStatusData,
} from "../../../store/accountVerification/action";

const AccountVerification = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.accountVerification);
  const loginData = useSelector((state) => state.Login);

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();

  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["Processing"],
    page_count: 50,
    keyword: "",
  };

  const [request, setRequest] = useState({ ...basicRequest });

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Account verification"
        )
      );
    }
  }, [loginData]);

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error]);

  const scrollToAlertMessage = () => {
    if (data?.error) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {
    request && dispatch(getAccountVerificationStatusData(request));
  }, [request]);

  const rejectPromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: t("UserRequests.rejectConfirm"),
      type: "delete",
    });
  };

  const okHandler = () => {
    dispatch(
      addAccountVerificationStatus(promptMessage.id, () =>
        dispatch(getAccountVerificationStatusData(request))
      )
    );
  };

  const userRequestData =
    data?.userRequestData?.requests &&
    data?.userRequestData?.requests?.map((user, index) => ({
      ...user,
      no: (request?.page - 1) * request?.page_count + index + 1,
      profile_image: (
        <div className="profileWrap" data-tag="allowRowEvents">
          {user.membership === "Premium" ? (
            <div className="premium_icon" data-tag="allowRowEvents">
              <img src={premiumImage} data-tag="allowRowEvents" />
            </div>
          ) : null}
          <div data-tag="allowRowEvents">
            <img
              data-tag="allowRowEvents"
              className="table_profileImg"
              src={
                user.profile_image === null
                  ? defaultProfile
                  : user.profile_image
              }
            />
          </div>
        </div>
      ),
      name: (
        <div title={user.name}>
          <div data-tag="allowRowEvents">{user.name}</div>
          {user.username && (
            <div
              className="text-muted"
              data-tag="allowRowEvents"
            >{`(${user.username})`}</div>
          )}
        </div>
      ),
      email: (
        <div data-tag="allowRowEvents" title={user.email}>
          {user.email}
        </div>
      ),
      phone: (
        <div data-tag="allowRowEvents" title={user.email}>
          {user.phone}
        </div>
      ),

      payment_date: (
        <div data-tag="allowRowEvents" title={user.payment_date}>
          {user.payment_date &&
            convertToUAETime(user.payment_date, FE_DATE_FORMAT)}
        </div>
      ),

      pp: (
        <div data-tag="allowRowEvents" title={user.pp}>
          {user.pp}
        </div>
      ),

      request_status: (
        <div
          className={`badge ${
            user.request_status === "Processing"
              ? "bg-soft-orange"
              : user.request_status === "Verified"
              ? "bg-soft-green"
              : user.request_status === "Declined"
              ? "bg-soft-red"
              : ""
          } font-size-13`}
          data-tag="allowRowEvents"
        >
          {user.request_status}
        </div>
      ),
      request_date: (
        <div data-tag="allowRowEvents" title={user.created_on}>
          {user.created_on}
        </div>
      ),

      actions: (
        <div className="cust-table-actions-wrap">
          <button
            onClick={() => rejectPromptHandler(user.request_id)}
            className=" color-red action-btn"
            title="Reject"
            disabled={
              user.request_status == "Processing" && isEditable ? false : true
            }
          >
            <i
              class="fa fa-ban "
              aria-hidden="true"
              style={{ fontSize: "20px" }}
            ></i>
          </button>
        </div>
      ),
    }));

  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs
            title="Tables"
            breadcrumbItem="User Verification Requests"
          />
          {data?.error && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            </div>
          )}
          <Datatable
            defaultSortField={request?.sort || "created_on"}
            defaultSortAsc={request?.sort_order === "asc" ? true : false}
            tableID={"userTable"}
            columns={userRequestColumnData}
            rows={userRequestData}
            setRequest={setRequest}
            request={request}
            totalRecords={data?.data?.total}
            search
            userRequestfilter
            searchTerm={data?.request?.keyword}
            reqstatusFilter={request?.status_filter}
            loading={data?.loading}
            tableCardClassName={"snoTable"}
          />
        </Row>
      </Container>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default AccountVerification;
