import {
  ADD_FLAX_TRANSFER_PURPOSE_BEGIN,
  ADD_FLAX_TRANSFER_PURPOSE_FAIL,
  ADD_FLAX_TRANSFER_PURPOSE_SUCCESS,
  DELETE_FLAX_TRANSFER_PURPOSE_BEGIN,
  DELETE_FLAX_TRANSFER_PURPOSE_FAIL,
  DELETE_FLAX_TRANSFER_PURPOSE_SUCCESS,
  EDIT_FLAX_TRANSFER_PURPOSE_BEGIN,
  EDIT_FLAX_TRANSFER_PURPOSE_FAIL,
  EDIT_FLAX_TRANSFER_PURPOSE_SUCCESS,
  GET_FLAX_TRANSFER_HISTORY_BEGIN,
  GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_BEGIN,
  GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_FAIL,
  GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_SUCCESS,
  GET_FLAX_TRANSFER_HISTORY_FAIL,
  GET_FLAX_TRANSFER_HISTORY_SUCCESS,
  GET_FLAX_TRANSFER_PURPOSES_BEGIN,
  GET_FLAX_TRANSFER_PURPOSES_FAIL,
  GET_FLAX_TRANSFER_PURPOSES_SUCCESS,
  GET_FLAX_TRANSFER_PURPOSE_BY_ID_BEGIN,
  GET_FLAX_TRANSFER_PURPOSE_BY_ID_FAIL,
  GET_FLAX_TRANSFER_PURPOSE_BY_ID_SUCCESS,
  GET_FLAX_TRANSFER_PURPOSE_BY_LANGUAGE_BEGIN,
  GET_FLAX_TRANSFER_PURPOSE_BY_LANGUAGE_FAIL,
  GET_FLAX_TRANSFER_PURPOSE_BY_LANGUAGE_SUCCESS,
  RESET_PURPOSE_ERRORS,
} from "./actionTypes";

export const INIT_STATE = {
  loading: true,
  error: "",
  flaxTransferData: {},
  flaxTransferDataByUser: {},
  flaxTransferPurposes: {},
  flaxTransferPurposeData: {},
  request: {
    sort: "transfered_date",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    page_count: 50,
    keyword: "",
    success: "",
  },
};

const flaxTransfers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FLAX_TRANSFER_PURPOSES_BEGIN:
      return {
        ...state,
        loading: true,
        flaxTransferPurposes: {},
      };
    case GET_FLAX_TRANSFER_PURPOSES_SUCCESS:
      return {
        ...state,
        loading: false,
        flaxTransferPurposes: action.payload,
        error: "",
      };
    case GET_FLAX_TRANSFER_PURPOSES_FAIL:
      return {
        ...state,
        loading: false,
        flaxTransferPurposes: {},
        error: action.payload,
      };
    case GET_FLAX_TRANSFER_HISTORY_BEGIN:
      return {
        ...state,
        loading: true,
        flaxTransferData: {},
      };
    case GET_FLAX_TRANSFER_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        flaxTransferData: action.payload,
        error: "",
      };
    case GET_FLAX_TRANSFER_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        flaxTransferData: {},
        error: action.payload,
      };
    case ADD_FLAX_TRANSFER_PURPOSE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_FLAX_TRANSFER_PURPOSE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "success",
        error: "",
      };
    case ADD_FLAX_TRANSFER_PURPOSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_FLAX_TRANSFER_PURPOSE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FLAX_TRANSFER_PURPOSE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case DELETE_FLAX_TRANSFER_PURPOSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_FLAX_TRANSFER_PURPOSE_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        flaxTransferPurposeData: {},
      };
    case GET_FLAX_TRANSFER_PURPOSE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        flaxTransferPurposeData: action.payload,
      };
    case GET_FLAX_TRANSFER_PURPOSE_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        flaxTransferPurposeData: {},
        error: action.payload,
      };
    case EDIT_FLAX_TRANSFER_PURPOSE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_FLAX_TRANSFER_PURPOSE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case EDIT_FLAX_TRANSFER_PURPOSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_PURPOSE_ERRORS:
      return {
        ...state,
        error: "",
        success: "",
      };
    case GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_BEGIN:
      return {
        ...state,
        loading: true,
        flaxTransferDataByUser: {},
      };
    case GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        flaxTransferDataByUser: action.payload,
      };
    case GET_FLAX_TRANSFER_HISTORY_BY_USER_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_FLAX_TRANSFER_PURPOSES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default flaxTransfers;
