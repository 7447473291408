import {
  GET_EMPOWERMENT_DETAILS,
  GET_EMPOWERMENT_DETAILS_BEGIN,
  GET_EMPOWERMENT_DETAILS_FAIL,
  GET_EMPOWERMENT_DETAILS_SUCCESS,
  ADD_EMPOWERMENT_DETAILS,
  ADD_EMPOWERMENT_DETAILS_BEGIN,
  ADD_EMPOWERMENT_DETAILS_FAIL,
  ADD_EMPOWERMENT_DETAILS_SUCCESS,
  RESET_MESSAGES,
  UPDATE_EMPOWERMENT_DETAILS,
  UPDATE_EMPOWERMENT_DETAILS_BEGIN,
  UPDATE_EMPOWERMENT_DETAILS_SUCCESS,
  UPDATE_EMPOWERMENT_DETAILS_FAIL,
} from "./actionTypes";

export const getEmpowermentDetails = (id) => ({
  type: GET_EMPOWERMENT_DETAILS,
  id: id,
});

export const getEmpowermentDetailsBegin = () => ({
  type: GET_EMPOWERMENT_DETAILS_BEGIN,
});

export const getEmpowermentDetailsSuccess = (empowermentDetails) => ({
  type: GET_EMPOWERMENT_DETAILS_SUCCESS,
  payload: empowermentDetails,
});

export const getEmpowermentDetailsFail = (error) => ({
  type: GET_EMPOWERMENT_DETAILS_FAIL,
  payload: error,
});

export const addEmpowermentDetails = ({ id, payload }) => ({
  type: ADD_EMPOWERMENT_DETAILS,
  id: id,
  payload: payload,
});
export const addEmpowermentDetailsBegin = () => ({
  type: ADD_EMPOWERMENT_DETAILS_BEGIN,
});

export const addEmpowermentDetailsSuccess = (payload) => ({
  type: ADD_EMPOWERMENT_DETAILS_SUCCESS,
  payload: payload,
});
export const addEmpowermentDetailsFail = (error) => ({
  type: ADD_EMPOWERMENT_DETAILS_FAIL,
  payload: error,
});

export const resetMessages = () => ({
  type: RESET_MESSAGES,
});
