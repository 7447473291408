export const GET_FLAX_PACKAGES = "GET_FLAX_PACKAGES";
export const GET_FLAX_PACKAGES_BEGIN = "GET_FLAX_PACKAGES_BEGIN";
export const GET_FLAX_PACKAGES_SUCCESS = "GET_FLAX_PACKAGES_SUCCESS";
export const GET_FLAX_PACKAGES_FAIL = "GET_FLAX_PACKAGES_FAIL";

export const DELETE_FLAX_PACKAGES = "DELETE_FLAX_PACKAGES";
export const DELETE_FLAX_PACKAGES_BEGIN = "DELETE_FLAX_PACKAGES_BEGIN";
export const DELETE_FLAX_PACKAGES_SUCCESS = "DELETE_FLAX_PACKAGES_SUCCESS";
export const DELETE_FLAX_PACKAGES_FAIL = "DELETE_FLAX_PACKAGES_FAIL";

export const ADD_FLAX_PACKAGES = "ADD_FLAX_PACKAGES";
export const ADD_FLAX_PACKAGES_BEGIN = "ADD_FLAX_PACKAGES_BEGIN";
export const ADD_FLAX_PACKAGES_SUCCESS = "ADD_FLAX_PACKAGES_SUCCESS";
export const ADD_FLAX_PACKAGES_FAIL = "ADD_FLAX_PACKAGES_FAIL";

export const UPDATE_FLAX_PACKAGES = "UPDATE_FLAX_PACKAGES";
export const UPDATE_FLAX_PACKAGES_BEGIN = "UPDATE_FLAX_PACKAGES_BEGIN";
export const UPDATE_FLAX_PACKAGES_SUCCESS = "UPDATE_FLAX_PACKAGES_SUCCESS";
export const UPDATE_FLAX_PACKAGES_FAIL = "UPDATE_FLAX_PACKAGES_FAIL";

export const GET_FLAX_PACKAGES_BY_ID = "GET_FLAX_PACKAGES_BY_ID";
export const GET_FLAX_PACKAGES_BY_ID_BEGIN = "GET_FLAX_PACKAGES_BY_ID_BEGIN";
export const GET_FLAX_PACKAGES_BY_ID_SUCCESS =
  "GET_FLAX_PACKAGES_BY_ID_SUCCESS";
export const GET_FLAX_PACKAGES_BY_ID_FAIL = "GET_FLAX_PACKAGES_BY_ID_FAIL";

export const GET_FLAX_PURCHASE_HISTORY = "GET_FLAX_PURCHASE_HISTORY";
export const GET_FLAX_PURCHASE_HISTORY_BEGIN =
  "GET_FLAX_PURCHASE_HISTORY_BEGIN";
export const GET_FLAX_PURCHASE_HISTORY_SUCCESS =
  "GET_FLAX_PURCHASE_HISTORY_SUCCESS";
export const GET_FLAX_PURCHASE_HISTORY_FAIL = "GET_FLAX_PURCHASE_HISTORY_FAIL";

export const GET_FLAX_PURCHASE_HISTORY_BY_USER_ID =
  "GET_FLAX_PURCHASE_HISTORY_BY_USER_ID";
export const GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_BEGIN =
  "GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_BEGIN";
export const GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_SUCCESS =
  "GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_SUCCESS";
export const GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_FAIL =
  "GET_FLAX_PURCHASE_HISTORY_BY_USER_ID_FAIL";
export const RESET_FLAX_PACKAGE_ERRORS = "RESET_FLAX_PACKAGE_ERRORS";

export const EXPORT_FLIX_PURCHASE_SUMMARY = "EXPORT_FLIX_PURCHASE_SUMMARY"
export const EXPORT_FLIX_PURCHASE_SUMMARY_BEGIN = "EXPORT_FLIX_PURCHASE_SUMMARY_BEGIN"
export const EXPORT_FLIX_PURCHASE_SUMMARY_SUCCESS = "EXPORT_FLIX_PURCHASE_SUMMARY_SUCCESS"
export const EXPORT_FLIX_PURCHASE_SUMMARY_FAIL = "EXPORT_FLIX_PURCHASE_SUMMARY_FAIL"
