import {
  ADD_PAYMENT_METHOD_BEGIN,
  ADD_PAYMENT_METHOD_FAIL,
  ADD_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_BEGIN,
  DELETE_PAYMENT_METHOD_FAIL,
  DELETE_PAYMENT_METHOD_SUCCESS,
  EDIT_PAYMENT_METHOD_BEGIN,
  EDIT_PAYMENT_METHOD_FAIL,
  EDIT_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHODS_BEGIN,
  GET_PAYMENT_METHODS_BY_ID_BEGIN,
  GET_PAYMENT_METHODS_BY_ID_FAIL,
  GET_PAYMENT_METHODS_BY_ID_SUCCESS,
  GET_PAYMENT_METHODS_FAIL,
  GET_PAYMENT_METHODS_SUCCESS,
  RESET_PAYMENT_METHOD_ERRORS,
} from "./actionTypes";

export const INIT_STATE = {
  paymentMethods: {},
  paymentMethodData: {},
  error: "",
  loading: false,
  request: {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    page_count: 50,
    keyword: "",
    success: "",
  },
};

const PaymentMethods = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENT_METHODS_BEGIN:
      return {
        ...state,
        loading: true,
        paymentMethods: {},
        huddleMessages: "",
      };
    case GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethods: action.payload,
        error: "",
      };
    case GET_PAYMENT_METHODS_FAIL:
      return {
        ...state,
        loading: false,
        paymentMethods: {},
        error: action.payload,
      };

    case GET_PAYMENT_METHODS_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        paymentMethodData: {},
        huddleMessages: "",
      };
    case GET_PAYMENT_METHODS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethodData: action.payload,
        error: "",
      };
    case GET_PAYMENT_METHODS_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        paymentMethodData: {},
        error: action.payload,
      };
    case EDIT_PAYMENT_METHOD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case EDIT_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_PAYMENT_METHOD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case ADD_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_PAYMENT_METHOD_BEGIN:
      return {
        ...state,
        delete_loading: true,
      };
    case DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        delete_loading: false,
        error: "",
      };
    case DELETE_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        delete_loading: false,
        error: action.payload,
      };
    case RESET_PAYMENT_METHOD_ERRORS:
      return {
        ...state,
        error: "",
        success: "",
      };
    default:
      return state;
  }
};

export default PaymentMethods;
