import React from "react";

const Button = ({ label, disableBtn, buttonClass, handleClick }) => {
  return (
    <button className={buttonClass} disabled={disableBtn} onClick={handleClick} data-testid="button">
      {label}
    </button>
  );
};

export default Button;
