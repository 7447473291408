import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Form, Input, Button, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";

import NotificationDropdown from "../../CommonForBoth/TopbarDropdown/NotificationDropdown"	
import ProfileMenu from "../../CommonForBoth/TopbarDropdown/ProfileMenu"

import { getUnseenCount } from '../../../store/notification/actions';


import logoSm from "../../../assets/icons/logo.svg"
import logoSmall from "../../../assets/images/Messej-logo.svg";	
	
//i18n
import { withTranslation } from "react-i18next"

	// Redux Store	
import {	
  showRightSidebarAction,	
  changeSidebarType,	
} from "../../../store/actions"
import { checkUserViewPermission } from '../../../helpers/utils'


const Header = props => {

    function tToggle() {
        var body = document.body;
        if (window.screen.width <= 768) {
        body.classList.toggle("sidebar-enable");
        } else {
        body.classList.toggle("vertical-collpsed");
        body.classList.toggle("sidebar-enable");
        }
    }

    //  const toggleSideBar = () => {	
    //   document.getElementById('vertical-menu-id').classList.toggle('stretch');	
    //   document.getElementById('page-topbar').classList.toggle('stretch');	
    //   document.getElementById('main-content-id').classList.toggle('stretch');	
    // }

    const dispatch = useDispatch();
    const data = useSelector((state) => state.notification);
    const [rolePermisions, setRolePermisions] = useState({});
    const loginData = useSelector((state) => state.Login);
  
    useEffect(() => {
      if (loginData?.rolePermissions?.role) {
        setRolePermisions(loginData?.rolePermissions?.role)
      }
    }, [loginData?.rolePermissions]);

    useEffect(()=> {
      dispatch(getUnseenCount())
    }, [])
    return (	
    <React.Fragment>	
      <header id="page-topbar" className="test">	
        <div className="navbar-header">	
          <div className="d-flex">	
            {/* <button id="collapser_button" onClick={() => toggleSideBar()}><i className="fa fa-fw fa-bars" /></button>	 */}
            <div className="navbar-brand-box">	
              <Link to="/" className="logo logo-dark">	
                <span className="logo-sm">	
                  <img src={logoSmall} alt="" height="30" />	
                </span>	
                <span className="logo-lg">	
                  <img src={logoSm} alt="" height="30" />	
                </span>	
              </Link>	
              <Link to="/" className="logo logo-light">	
                <span className="logo-sm">	
                  <img src={logoSmall} alt="" height="30" />	
                </span>	
                <span className="logo-lg">	
                  <img src={logoSm} alt="" height="30" />	
                </span>	
              </Link>	
            </div>

            <button	
              type="button"	
              onClick={() => {	
                tToggle()	
              }}	
              className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"	
              id="vertical-menu-btn"	
            >	
              <i className="fa fa-fw fa-bars" />	
            </button>

          </div>	

          <div className="d-flex">
          {rolePermisions && checkUserViewPermission(rolePermisions,"Notification" ) && <NotificationDropdown data={data?.notification?.Notifications} />	}
            <ProfileMenu />
          </div>	

        </div>	
      </header>	
    </React.Fragment>	
  )	
}              

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftSideBarType,
  } = state.Layout
  return { layoutType, showRightSidebar, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  changeSidebarType,
})(withTranslation()(Header))