import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import Datatable from "../../../../components/UI/Tables/Datatable";
import {
  flaxTransferColumnData,
  userFlaxTransferColumnData,
} from "../../../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../../helpers/constants";
import { convertTime, convertToUAETime } from "../../../../helpers/utils";
import {
  getFlaxTransferHistory,
  getFlaxTransferHistoryByUserId,
} from "../../../../store/flaxTransfer/actions";
import premiumImage from "../../../../assets/icons/premium.svg";
import defaultProfile from "../../../../assets/images/users/user.svg";
import Loader from "../../../../components/Common/Loader";
import { Card } from "@material-ui/core";
import CountUp from "react-countup";
import Flix_bal_icon from "../../../../assets/icons/sdfl_icons/flix-bal-icon.svg";

const TransferHistory = ({ toggle, modal, userId }) => {
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "transfered_date",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 10,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const data = useSelector((state) => state.FlaxTransfer);
  console?.log("flax transfer", data);
  useEffect(() => {
    request &&
      userId &&
      dispatch(getFlaxTransferHistoryByUserId(request, userId));
  }, [request]);

  const flaxTransferData =
    data?.flaxTransferDataByUser?.transfers?.length >= 0 &&
    data?.flaxTransferDataByUser?.transfers?.map((transfers, index) => {
      // Check if sender_details and receiver_details are not null
      const senderDetails = transfers?.sender_details || {};
      const receiverDetails = transfers?.receiver_details || {};

      return {
        ...transfers,
        no: (request?.page - 1) * request?.page_count + index + 1,

        flax_amount: (
          <div data-tag="allowRowEvents" title={transfers?.received_flax}>
            {transfers?.status === "Received" ||
            transfers?.status === "Purchased"
              ? transfers.received_flax
              : transfers?.sent_flax}
          </div>
        ),
        status: (
          <div
            className={`${
              transfers?.status === "Received" ||
              transfers?.status === "Purchased"
                ? "badge bg-soft-success font-size-10 my-auto"
                : "badge bg-soft-danger font-size-10 my-auto"
            }`}
            data-tag="allowRowEvents"
            title={transfers?.status}
          >
            {transfers?.status === "Received" ||
            transfers?.status === "Purchased"
              ? "Received"
              : "Sent"}
          </div>
        ),
        transfered_from:
          transfers?.status === "Received" ||
          transfers?.status === "Purchased" ? (
            <div title={senderDetails.name}>
              <div data-tag="allowRowEvents">{senderDetails.name}</div>

              <div className="d-flex flex-column flex-sm-row align-items-center">
                <div
                  style={{
                    marginRight: "5px",
                  }}
                  className="text-muted"
                  data-tag="allowRowEvents"
                >
                  ({senderDetails.username})
                </div>
              </div>
            </div>
          ) : (
            <div data-tag="allowRowEvents">
              {receiverDetails.name}
              <div className="d-flex flex-column flex-sm-row align-items-center">
                <div
                  style={{
                    marginRight: "5px",
                  }}
                  className="text-muted"
                  data-tag="allowRowEvents"
                >
                  ({receiverDetails.username})
                </div>
              </div>
            </div>
          ),
        transfered_date: (
          <div data-tag="allowRowEvents" title={transfers?.time_created}>
            {convertTime(transfers?.time_created)}
          </div>
        ),
      };
    });

  const csvData =
    data?.flaxTransferDataByUser?.transfers?.length >= 0 &&
    data?.flaxTransferDataByUser?.transfers?.map((transfers, index) => {
      const senderDetails = transfers?.sender_details || {};
      const receiverDetails = transfers?.receiver_details || {};

      return {
        no: (request?.page - 1) * request?.page_count + index + 1,
        flax_amount:
          transfers?.status === "Received"
            ? transfers.received_flax
            : transfers?.sent_flax,
        status: transfers?.status,
        transfered_from:
          transfers?.status === "Received"
            ? senderDetails?.username
            : receiverDetails?.username,
        transfered_date: convertTime(transfers?.time_created),
      };
    });

  return (
    <div>
      <Modal
        centered
        isOpen={modal}
        toggle={toggle}
        style={{ minWidth: "1000px" }}
      >
        {/* <ModalHeader toggle={toggle}>Flax summary</ModalHeader> */}
        <ModalBody>
          <>
            <Container
              fluid
              id="userManagementWrapper"
              data-testid="userMgmtWrap"
            >
              <Breadcrumbs title="Tables" breadcrumbItem="FLiX Transfers" />
              {!data?.loading && (
                <Row className="py-3 ">
                  <Col md={3} xl={3}>
                    <Card>
                      <CardBody className="px-3 py-3">
                        <div className="float-end mt-2">
                          <img src={Flix_bal_icon} alt="coin" />
                        </div>
                        <div>
                          <h4 className="mb-1 mt-1">
                            <span>
                              <CountUp
                                end={
                                  +data?.flaxTransferDataByUser?.total_sent || 0
                                }
                              />
                            </span>
                          </h4>
                          <p className="text-muted mb-0">Total Send FLiX</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={3} xl={3}>
                    <Card>
                      <CardBody className="px-3 py-3">
                        <div className="float-end mt-2">
                          <img src={Flix_bal_icon} alt="coins" />
                        </div>
                        <div>
                          <h4 className="mb-1 mt-1">
                            <span>
                              <CountUp
                                end={
                                  +data?.flaxTransferDataByUser
                                    ?.total_received || 0
                                }
                              />
                            </span>
                          </h4>
                          <p className="text-muted mb-0">Total Received FLiX</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
              {data?.flaxTransferDataByUser && (
                <Datatable
                  defaultSortField={request?.sort || "transfered_date"}
                  defaultSortAsc={request?.sort_order === "asc" ? true : false}
                  dateFilter
                  dateType="Transfered Date Range"
                  tableID={"flaxTable"}
                  columns={userFlaxTransferColumnData}
                  rows={flaxTransferData}
                  setRequest={setRequest}
                  request={request}
                  totalRecords={data?.flaxTransferDataByUser?.total}
                  search
                  searchTerm={data?.request?.keyword}
                  // reqstatusFilter={request?.status_filter}
                  loading={data?.loading}
                  exportcsv
                  csvData={csvData}
                  tableCardClassName={"snoTable"}
                />
              )}
            </Container>
            {data?.loading && <Loader darkBg={true} />}
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TransferHistory;
