import React, { useState, useEffect } from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Datatable from "../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../components/UI/Breadcrumb";
import Loader from "../../components/Common/Loader";

import {
  getInstallationData,
  getCountryData,
  storeRequest,
  getRegionData,
  updateRegionData,
  saveRegionData,
} from "../../store/appInstallations/action";

import { appInstallationsColumnData } from "../../helpers/columns";

const Statistics = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appInstallations = useSelector((state) => state.appInstallations);
  const [appInstallationRow, setAppInstallationRow] = useState({});

  const basicRequest = {
    sort: "total",
    sort_order: "desc",
    page: 1,
    page_count: 50,
    country_filter: [],
    date_range_filter: [],
    from_date: moment(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ).format("DD-MM-YYYY"),
    to_date: moment(new Date()).format("DD-MM-YYYY"),
  };

  const [request, setRequest] = useState({ ...basicRequest });

  useEffect(() => {
    request && dispatch(getInstallationData({ ...request }));
    request && dispatch(storeRequest({ ...request }));
  }, [request]);

  useEffect(() => {
    dispatch(getCountryData());
    dispatch(getRegionData())
  }, []);

  useEffect(() => {
    setAppInstallationRow(getAppInstallationsRowData());
  }, [appInstallations]);

  const calculateAll = () => {
    return {
      country: t("AppInstallations.all"),
      ios: appInstallations?.installationData?.ios_total,
      total: appInstallations?.installationData?.platforms_total,
      android: appInstallations?.installationData?.android_total,
    };
  };
  const updateRegion = (data) => dispatch(updateRegionData(data));
  const saveRegion = (data) => dispatch(saveRegionData(data));

  const getAppInstallationsRowData = () => {
    let temp = [];
    if (appInstallations?.installationData?.app_statistics) {
      temp = [
        calculateAll(), //adding 'All' as the first column
        ...appInstallations.installationData.app_statistics,
      ];
      return temp.map((statistics, index) => ({
        ...statistics,
        no: statistics.country !== t("AppInstallations.all") && index,
        country: (
          <div
            title={statistics.country}
            className={
              statistics.country === t("AppInstallations.all")
                ? "bold islCountry"
                : "islCountry"
            }
          >
            {statistics.country}
          </div>
        ),
        platform: (
          <div className="grouped-column-body">
            <div title={statistics.ios} className="islIos">
              {statistics.ios}
            </div>
            <div title={statistics.android} className="islAndroid">
              {statistics.android}
            </div>
          </div>
        ),
        total: (
          <div title={statistics.total} className="islTotal">
            {statistics.total}
          </div>
        ),
      }));
    }
  };

  return (
    <div
      className="app-installations page-content"
      data-testid="component-installationData"
      id="userMgmtList"
    >
      <Container fluid>
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="App Installations" />
          {appInstallations?.listing_error && (
            <div>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {appInstallations?.listing_error}
              </UncontrolledAlert>
            </div>
          )}
          <Datatable
            defaultSortField={"total"}
            defaultSortAsc={request?.sort_order === "asc" ? true : false}
            tableID={"appInstallations"}
            columns={appInstallationsColumnData}
            rows={appInstallationRow}
            setRequest={setRequest}
            request={request}
            totalRecords={appInstallations?.installationData?.total_records}
            statisticsFilter
            tableCardClassName={"snoTable"}
            countryFilter={appInstallations?.installationCountry?.countries}
            countries
            regions={appInstallations?.regions}
            updateRegion={updateRegion}
            saveRegionData={saveRegion}
          />
        </Row>
      </Container>

      {appInstallations?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default Statistics;
