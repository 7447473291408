import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  exportGRSSummaryByUserIdBegin,
  exportGRSSummaryByUserIdFail,
  exportGRSSummaryByUserIdSuccess,
  getGiftTransferHistoryBegin,
  getGiftTransferHistoryByUserIdBegin,
  getGiftTransferHistoryByUserIdFail,
  getGiftTransferHistoryByUserIdSuccess,
  getGiftTransferHistoryFail,
  getGiftTransferHistorySuccess,
  getGRSSummaryByUserIdBegin,
  getGRSSummaryByUserIdFail,
  getGRSSummaryByUserIdSuccess,
} from "./actions";
import { API_GET_GIFT_TRANSFER_HISTORY } from "../../helpers/url_helper";
import {
  EXPORT_GRS_SUMMARY_BY_USER_ID,
  GET_GIFT_TRANSFER_HISTORY,
  GET_GIFT_TRANSFER_HISTORY_BY_USER_ID,
  GET_GIFT_TRANSFER_HISTORY_FAIL,
  GET_GRS_SUMMARY_BY_USER_ID,
  GET_GRS_SUMMARY_BY_USER_ID_BEGIN,
} from "./actionTypes";
import { get, getFile } from "../../helpers/api_helper";
import { saveAs } from "file-saver";

function* getGiftTransferHistory(res) {
  try {
    yield put(getGiftTransferHistoryBegin());
    const response = yield call(
      get,
      API_GET_GIFT_TRANSFER_HISTORY,
      res.request
    );

    yield put(getGiftTransferHistorySuccess(response.result));
  } catch (error) {
    yield put(getGiftTransferHistoryFail(error));
  }
}
function* getGiftTransferHistoryByUserId({ request, userId }) {
  console.log("saga-id", userId);
  try {
    yield put(getGiftTransferHistoryByUserIdBegin());
    const response = yield call(
      get,
      `/admin/gift/transferhistory?user_id=${userId}`,
      request
    );

    yield put(getGiftTransferHistoryByUserIdSuccess(response.result));
  } catch (error) {
    yield put(getGiftTransferHistoryByUserIdFail(error));
  }
}
function* getGRSSummaryByUserId({ request, userId }) {
  console.log("saga-id", userId);
  try {
    yield put(getGRSSummaryByUserIdBegin());
    const response = yield call(
      get,
      `/admin/gift/transfer-details?user_id=${userId}`,
      request
    );

    yield put(getGRSSummaryByUserIdSuccess(response.result));
  } catch (error) {
    yield put(getGRSSummaryByUserIdFail(error));
  }
}
function* exportGRSSummaryByUserIdSaga({ request, userId, exportParam }) {
  try {
    yield put(exportGRSSummaryByUserIdBegin());
    const response = yield call(
      getFile,
      `/admin/gift/transfer-details?user_id=${userId}&export=${exportParam}`,
      request,
      {
        headers: {
          "Content-Type": "text/csv",
        },
        responseType: "blob",
      }
    );
    console.log("export res", response);
    const blob = new Blob([response], { type: "text/csv;charset=utf-8;" });

    saveAs(
      blob,
      `grs_summary_export_${new Date()
        .toLocaleString()
        .replace(/[^\w]/g, "_")}.csv`
    );
    yield put(exportGRSSummaryByUserIdSuccess());
  } catch (error) {
    yield put(exportGRSSummaryByUserIdFail(error));
  }
}

function* giftTransfersaga() {
  yield takeLatest(GET_GIFT_TRANSFER_HISTORY, getGiftTransferHistory);
  yield takeLatest(
    GET_GIFT_TRANSFER_HISTORY_BY_USER_ID,
    getGiftTransferHistoryByUserId
  );
  yield takeLatest(GET_GRS_SUMMARY_BY_USER_ID, getGRSSummaryByUserId);
  yield takeLatest(EXPORT_GRS_SUMMARY_BY_USER_ID, exportGRSSummaryByUserIdSaga);
}

export default giftTransfersaga;
