import axios from "axios";
import {
  onRequest,
  onRequestError,
  onResponse,
  onResponseError,
} from "./interceptors";

//apply base url for axios
export const API_URL = process.env.REACT_APP_BACKEND_URL;
// export const API_URL = " https://api-route-test.messej.com";
// export const API_URL = " https://admin-api.dev.eu.messej.com";

const axiosApi = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor
axiosApi.interceptors.request.use(onRequest, onRequestError);

// Add a response interceptor
axiosApi.interceptors.response.use(onResponse, onResponseError);

export async function get(url, data, config = {}) {
  return await axiosApi
    .get(url, { params: { ...data } }, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  console.log("config", { ...config });
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}
// export async function uploadFile(url, data, config = {}) {
//   return axiosApi
//     .post(url, data, {
//       ...config,
//     })
//     .then((response) => response.data);
// }

export async function update(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function updateFormData(url, data, config = {}) {
  return axiosApi
    .put(url, data, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

export async function getFile(url, data = {}, config = {}) {
  return await axiosApi
    .get(url, { params: { ...data } }, { ...config })
    .then((response) => response.data);
}

export async function delMultiple(url, data) {
  return await axiosApi
    .delete(url, (data = { data }))
    .then((response) => response.data);
}
