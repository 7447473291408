import {
  GET_FLIX_RATE_BEGIN,
  GET_FLIX_RATE_FAIL,
  GET_FLIX_RATE_SUCCESS,
  UPDATE_FLIX_RATE_BEGIN,
  UPDATE_FLIX_RATE_FAIL,
  UPDATE_FLIX_RATE_SUCCESS,
} from "./actionTypes";

export const INIT_STATE = {
  loading: false,
  error: "",
  success: "",
  flix_rate: {},
};

const editRate = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_FLIX_RATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_FLIX_RATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload, // Ensure success message is set properly
      };
    case UPDATE_FLIX_RATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_FLIX_RATE_BEGIN:
      return {
        ...state,
        data: {},
        loading: true,
        error: "",
        success: "",
        flix_rate: {}, // Changed from payout_requests to flix_rate
      };

    case GET_FLIX_RATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        flix_rate: action.payload, // Assuming you want to store the successful data in flix_rate
      };

    case GET_FLIX_RATE_FAIL:
      return {
        ...state,
        data: {},
        loading: false,
        error: action.payload,
        flix_rate: {}, // Reset flix_rate on failure
      };

    default:
      return state;
  }
};
export default editRate;
