import React from "react";

const Checkbox = ({ className, value, handleChange, id }) => {
  return (
    <input
      className={className}
      type="checkbox"
      checked={value}
      id={id}
      onChange={() => handleChange(!value)}
      value={value}
      data-testid="checkbox"
    />
  );
};

export default Checkbox;
