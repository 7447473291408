import React, { useState, useEffect, useRef } from "react";
import { Col, Card, CardTitle, UncontrolledAlert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory, Prompt } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import parsePhoneNumber from "libphonenumber-js/mobile";
import Breadcrumbs from "../../../components/UI/ConfirmationAlert";

import { EMAIL_REGEX } from "../../../helpers/constants";

import {
  getUser,
  allocateUsername,
  checkUsernameAvailability,
  resetError,
  resetErrorWithUsername,
  resetState,
} from "../../../store/usernames/allocateUserNames/action";
import GetUserForm from "../../UsernameManagement/AllocateUsernames/AddAllocation/GetUserForm";
import UserDetail from "../../UsernameManagement/AllocateUsernames/Shared/UserDetail";
import Loader from "../../../components/Common/Loader";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import LinkedTo from "../../UsernameManagement/AllocateUsernames/AddAllocation/LinkedTo";
import { FlaxRequestForm } from "./FlaxRequestForm";

const AllocateAddNew = () => {
  const [selected, setSelected] = useState("1");
  const [getUserDisabled, setGetUserDisabled] = useState(true);
  const [mobile, setMobile] = useState();
  const [countryCode, setCountryCode] = useState("+91");
  const [newUsername, setNewUsername] = useState();
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [warning, setWarning] = useState("");
  const [backToListing, setBackToListing] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [searchUsername, setSearchUsername] = useState("");

  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.allocatedUsername);
  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.success, data?.error]);

  const scrollToAlertMessage = () => {
    if (data?.success || data?.error) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {
    if (newUsername) {
      setFormChanged(true);
      if (new RegExp(EMAIL_REGEX).test(newUsername)) {
        setWarning(t("AllocateUserName.formatWarn"));
      }
    } else setWarning("");
  }, [newUsername]);

  useEffect(() => {
    mobile && parsePhoneNumber(`${countryCode}${mobile}`)?.isValid()
      ? setGetUserDisabled(false)
      : setGetUserDisabled(true);
  }, [mobile, countryCode]);

  useEffect(() => {
    dispatch(resetState());
  }, []);

  const selectOption = (e) => {
    setSelected(e?.target?.value);
    setGetUserDisabled(true);
    setMobile();
  };
  const getUserDetails = (e, values) => {
    selected === "2"
      ? dispatch(
          getUser({
            data: {
              phone: mobile,
              country_code: countryCode,
            },
            history: history,
          })
        )
      : dispatch(getUser({ data: { ...values }, history: history }));
  };

  return (
    <div className="page-content" ref={pageWrapRef}>
      <Breadcrumbs
        title="Tables"
        breadcrumbItem={t("AllocateUserName.addNew.Title")}
      />
      {data?.success && (
        <div ref={warningAlertRef}>
          <UncontrolledAlert
            color="success"
            className="alert-dismissible fade show"
            role="alert"
          >
            {data?.success}
          </UncontrolledAlert>
        </div>
      )}

      {data?.error && (
        <div ref={warningAlertRef}>
          <UncontrolledAlert
            color="danger"
            className="alert-dismissible fade show"
            role="alert"
          >
            {data?.error}
          </UncontrolledAlert>
        </div>
      )}
      <Col xl="12">
        <Card className="p-4">
          <CardTitle className="h3 mt-0 mb-4">
            {t("ReserveUserName.linkedTo")} :
          </CardTitle>
          <LinkedTo selectOption={selectOption} selected={selected} />
          <GetUserForm
            getUserDisabled={getUserDisabled}
            setGetUserDisabled={setGetUserDisabled}
            selected={selected}
            setMobile={setMobile}
            onValidSubmit={getUserDetails}
            setCountryCode={setCountryCode}
            searchUsername={searchUsername}
            setSearchUsername={setSearchUsername}
          />
        </Card>
      </Col>

      {data?.user && (
        <Col xl="12">
          <Card className="newAllocate_detailWrapper">
            <UserDetail user={data?.user} />
          </Card>
        </Col>
      )}
      {data?.user && <FlaxRequestForm user={data?.user} />}
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default AllocateAddNew;
