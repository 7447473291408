import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";

export const IdPreview = ({ isOpen, setOpen, imgurl }) => {
  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      backdrop={true}
      toggle={() => setOpen(false)}
    >
      <ModalHeader toggle={() => setOpen((state) => !state)}>
        Uploaded ID
      </ModalHeader>
      <ModalBody>
        <img src={imgurl} width={470} />
      </ModalBody>
    </Modal>
  );
};
