import {
  GET_USER_FLAX_OR_COINS_STATEMENTS,
  GET_USER_FLAX_OR_COINS_STATEMENTS_BEGIN,
  GET_USER_FLAX_OR_COINS_STATEMENTS_FAIL,
  GET_USER_FLAX_OR_COINS_STATEMENTS_SUCCESS,
} from "./actionTypes";

export const getUserFlaxOrCoinsStatements = ({ request, userId }) => ({
  type: GET_USER_FLAX_OR_COINS_STATEMENTS,
  request: request,
  userId: userId,
});

export const getUserFlaxOrCoinsStatementsBegin = () => ({
  type: GET_USER_FLAX_OR_COINS_STATEMENTS_BEGIN,
});

export const getUserFlaxOrCoinsStatementsSuccess = (statements) => ({
  type: GET_USER_FLAX_OR_COINS_STATEMENTS_SUCCESS,
  payload: statements,
});

export const getUserFlaxOrCoinsStatementsFail = (error) => ({
  type: GET_USER_FLAX_OR_COINS_STATEMENTS_FAIL,
  payload: error,
});
