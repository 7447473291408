import {
  GET_REPORTED_USER_BEGIN,
  GET_REPORTED_USER_SUCCESS,
  GET_REPORTED_USER_FAIL,
  GET_REPORTED_CATEGORY_BEGIN,
  GET_REPORTED_CATEGORY_SUCCESS,
  GET_REPORTED_CATEGORY_FAIL,
  STORE_REPORTED_USER_REQUEST,
  DELETE_REPORTED_USER_BEGIN,
  DELETE_REPORTED_USER_SUCCESS,
  DELETE_REPORTED_USER_FAIL,
  RESET_MESSAGES,
  GET_REPORTED_USERS_CATEGORIES_BEGIN,
  GET_REPORTED_USERS_CATEGORIES_SUCCESS,
  GET_REPORTED_USERS_CATEGORIES_FAIL,
  GET_REPORTED_USERS_CATEGORIES_BY_ID_BEGIN,
  GET_REPORTED_USERS_CATEGORIES_BY_ID_SUCCESS,
  GET_REPORTED_USERS_CATEGORIES_BY_ID_FAIL,
  EDIT_REPORTED_USERS_CATEGORIES_BEGIN,
  EDIT_REPORTED_USERS_CATEGORIES_SUCCESS,
  EDIT_REPORTED_USERS_CATEGORIES_FAIL,
  ADD_REPORTED_USERS_CATEGORIES_BEGIN,
  ADD_REPORTED_USERS_CATEGORIES_SUCCESS,
  ADD_REPORTED_USERS_CATEGORIES_FAIL,
  GET_REPORTED_USER_BY_ID_BEGIN,
  GET_REPORTED_USER_BY_ID_SUCCESS,
  GET_REPORTED_USER_BY_ID_FAIL,
  DELETE_REPORTED_USERS_CATEGORIES_BEGIN,
  DELETE_REPORTED_USERS_CATEGORIES_SUCCESS,
  DELETE_REPORTED_USERS_CATEGORIES_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  listing_error: "",
  category_filter_error: "",
  loading: false,
  request: {
    sort: "count",
    sort_order: "desc",
    page: 1,
    page_count: 50,
    keyword: "",
  },
  reportedData: [],
  reportedUserData: [],
  reportCategory: [],
  reportedUserCategoryData: [],
  reportedUserCategories: [],
  success: "",
};

const reportedUser = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORTED_USER_BEGIN:
      return {
        ...state,
        loading: true,
        listing_error: "",
        reportedData: [],
      };
    case GET_REPORTED_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        listing_error: "",
        reportedData: action.payload,
      };
    case GET_REPORTED_USER_FAIL:
      return {
        ...state,
        reportedData: [],
        loading: false,
        listing_error: action.payload,
      };

    case GET_REPORTED_CATEGORY_BEGIN:
      return {
        ...state,
        reportCategory: [],
        //loading: true,
        category_filter_error: "",
      };

    case GET_REPORTED_CATEGORY_SUCCESS:
      return {
        ...state,
        reportCategory: action.payload,
        //loading: false,
        category_filter_error: "",
      };

    case GET_REPORTED_CATEGORY_FAIL:
      return {
        ...state,
        reportCategory: [],
        //loading: false,
        category_filter_error: action.payload,
      };

    case STORE_REPORTED_USER_REQUEST:
      return {
        ...state,
        request: action.payload,
      };

    case DELETE_REPORTED_USER_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case DELETE_REPORTED_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: "Report deleted successfully",
      };

    case DELETE_REPORTED_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case RESET_MESSAGES:
      return {
        ...state,
        success: "",
        error: "",
      };

    // huddle reported message categories

    case GET_REPORTED_USERS_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
        reportedUserCategories: {},
      };
    case GET_REPORTED_USERS_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        reportedUserCategories: action.payload,
        error: "",
      };
    case GET_REPORTED_USERS_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        reportedUserCategories: {},
        error: action.payload,
      };

    case GET_REPORTED_USERS_CATEGORIES_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        reportedUserCategoryData: {},
      };
    case GET_REPORTED_USERS_CATEGORIES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        reportedUserCategoryData: action.payload,
        error: "",
      };
    case GET_REPORTED_USERS_CATEGORIES_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        reportedUserCategoryData: {},
        error: action.payload,
      };
    case EDIT_REPORTED_USERS_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_REPORTED_USERS_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case EDIT_REPORTED_USERS_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_REPORTED_USERS_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_REPORTED_USERS_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case ADD_REPORTED_USERS_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_REPORTED_USERS_CATEGORIES_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case DELETE_REPORTED_USERS_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: "Report deleted successfully",
      };

    case DELETE_REPORTED_USERS_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };
    case GET_REPORTED_USER_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        reportedUserData: {},
      };
    case GET_REPORTED_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        reportedUserData: action.payload,
        listing_error: "",
      };
    case GET_REPORTED_USER_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        reportedUserData: {},
        listing_error: action.payload,
      };

    default:
      return state;
  }
};

export default reportedUser;
