import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import Datatable from "../../../../components/UI/Tables/Datatable";
import {
  podiumColumnData,
  userFlashCoumnData,
} from "../../../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../../helpers/constants";
import {
  checkUserPermission,
  convertTime,
  convertToUAETime,
} from "../../../../helpers/utils";
import {
  deleteFlashes,
  getFlashes,
} from "../../../../store/flashManagement/actions";
import { VideoPreview } from "./VideoPreview";
import Delete_icon from "../../../../assets/icons/ebud-icons/Delete.svg";
import Loader from "../../../../components/Common/Loader";
import ConfirmationAlert from "../../../../components/UI/ConfirmationAlert";

const Flashes = ({ activeTab, userId }) => {
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 10,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const [isOpen, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);
  const loginData = useSelector((state) => state.Login);

  const [userPermissions, setUserPermissions] = useState({});
  const isDeleteable = userPermissions?.delete || false;

  const data = useSelector((state) => state.FlashCategories);

  useEffect(() => {
    if (activeTab === "6") dispatch(getFlashes({ userId, request }));
  }, [activeTab, request]);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Flash Management",
          "Flash"
        )
      );
    }
  }, [loginData]);

  const podiumsData = data?.flashes?.items?.map((flash, index) => ({
    ...flash,

    no: (request?.page - 1) * request?.page_count + index + 1,
    // createdOn: convertToUAETime(flash.time_created, DATE_WITH_TIME_IN_SECONDS),
    thumbnail: (
      <div className="profileWrap" data-tag="allowRowEvents">
        <div data-tag="allowRowEvents">
          {/* {result?.gift_json && isLottieAnimation(result?.gift_json) && (
          <Lottie
            style={{
              width: "30px",
              height: "40px",
            }}
            animationData={result?.gift_json && result?.gift_json}
          />
        )} */}
          <img
            data-tag="allowRowEvents"
            className="table_profileImg"
            width={28}
            src={flash?.thumbnail_url}
            alt="flash"
          />
        </div>
        {}
      </div>
    ),

    category: (
      <div data-tag="allowRowEvents" title={flash.category}>
        {flash.category}
      </div>
    ),

    created_on: (
      <div data-tag="allowRowEvents" title={flash.time_created}>
        {convertTime(flash.time_created)}
      </div>
    ),
    actions: (
      <div className="cust-table-actions-wrap">
        <button
          onClick={() => deletePromptHandler(flash.id)}
          className=" color-red action-btn"
          title="Delete"
          disabled={!isDeleteable}
        >
          <img
            src={Delete_icon}
            alt="Delete"
            style={{
              opacity: !isDeleteable ? "50%" : "",
              cursor: !isDeleteable ? "not-allowed" : "",
            }}
          />
        </button>
      </div>
    ),
  }));
  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to delete 
      this Flash ?`,
      type: "delete",
    });
  };
  const okHandler = () => {
    dispatch(
      deleteFlashes({
        id: promptMessage.id,

        callback: () => dispatch(getFlashes({ userId })),
      })
    );
  };

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error, data?.success]);

  const scrollToAlertMessage = () => {
    if (data?.error || data?.success) {
      let element = warningAlertRef?.current;
      let top = element?.offsetTop;
      let height = element?.getBoundingClientRect().height;
      pageWrapRef?.current?.scrollTo(0, top - height - 30);
    }
  };

  const onRowClicked = (review) => {
    setOpen(true);
    setSelectedRow(review);
  };
  return (
    <div
      className={userId || "page-content"}
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Flashes" />
          {data?.error && (
            <div ref={warningAlertRef}>
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            </div>
          )}

          <Datatable
            //   defaultSortField={request?.sort || "created_on"}
            //   defaultSortAsc={request?.sort_order === "asc" ? true : false}
            tableID={"reviewTable"}
            columns={userFlashCoumnData}
            rows={podiumsData}
            setRequest={setRequest}
            request={request}
            totalRecords={data?.flashes?.total}
            //   search
            //   podiumFilter
            //   reqTypeFilter={request.type}
            tableRowClicked={(param) => onRowClicked(param)}
            // addNewLabel={t("Admins.addAdmin.addBtn")}
            // searchTerm={data?.request?.keyword}
            // reqstatusFilter={request?.status_filter}
            // reqmembershipFilter={request?.membership_filter}
            // loading={data?.loading}
            tableCardClassName={"snoTable"}
            // deleteLabel="DELETE"
            // deleteClickHandler={deleteAll}
            // deleteDisabled={!selectedHuddles.length}
            // showDeleteButton={true}
            // deleteConfirmationMsg="Do you really want to delete the selected huddles?"
          />
        </Row>
        <VideoPreview
          // pairId={cookies?.["CloudFront-Key-Pair-Id"]}
          // policy={cookies?.["CloudFront-Key-Pair-Id"]}
          // signature={cookies?.["CloudFront-Signature"]}
          selectedRow={selectedRow}
          // popupButtonHandler={popupButtonHandler}
          isOpen={isOpen}
          setOpen={setOpen}
        />
      </Container>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />

      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default Flashes;
