import {
  GET_MLM_PARENTS_AND_LEVELS,
  GET_MLM_PARENTS_AND_LEVELS_BEGIN,
  GET_MLM_PARENTS_AND_LEVELS_SUCCESS,
  GET_MLM_PARENTS_AND_LEVELS_FAILURE,
  GET_MLM_PAGINATED_LEVEL,
  GET_MLM_PAGINATED_LEVEL_BEGIN,
  GET_MLM_PAGINATED_LEVEL_SUCCESS,
  GET_MLM_PAGINATED_LEVEL_FAILURE,
  LEVEL_CLICK,
  CLEAR_TREE,
  GET_MLM_LEVEL_USERS,
  GET_MLM_LEVEL_USERS_BEGIN,
  GET_MLM_LEVEL_USERS_SUCCESS,
  GET_MLM_LEVEL_USERS_FAIL,
} from "./actionTypes";

export const getParentsAndLevels = (userID) => ({
  type: GET_MLM_PARENTS_AND_LEVELS,
  payload: { id: userID },
});

export const getParentsAndLevelsBegin = () => ({
  type: GET_MLM_PARENTS_AND_LEVELS_BEGIN,
});

export const getParentsAndLevelsSuccess = (response) => ({
  type: GET_MLM_PARENTS_AND_LEVELS_SUCCESS,
  payload: response,
});

export const getParentsAndLevelsFailure = (error) => ({
  type: GET_MLM_PARENTS_AND_LEVELS_FAILURE,
  payload: error,
});

export const getPaginatedLevel = (id, request, level) => ({
  type: GET_MLM_PAGINATED_LEVEL,
  id: id,
  payload: request,
  level: level,
});

export const getPaginatedLevelBegin = () => ({
  type: GET_MLM_PAGINATED_LEVEL_BEGIN,
});

export const getPaginatedLevelSuccess = (response, level) => ({
  type: GET_MLM_PAGINATED_LEVEL_SUCCESS,
  payload: response,
  level: level,
});

export const getPaginatedLevelFailure = (error) => ({
  type: GET_MLM_PAGINATED_LEVEL_FAILURE,
  payload: error,
});

export const levelClickAction = (levelID) => ({
  type: LEVEL_CLICK,
  payload: levelID,
});

export const getMlmLevelUsers = ({ userid, level, request }) => ({
  type: GET_MLM_LEVEL_USERS,
  userid: userid,
  level: level,
  request: request,
});

export const getMlmLevelUsersBegin = () => ({
  type: GET_MLM_LEVEL_USERS_BEGIN,
});

export const getMlmLevelUsersSuccess = (users, level) => ({
  type: GET_MLM_LEVEL_USERS_SUCCESS,
  payload: users,
  level: level,
});

export const getMlmLevelUsersFail = (error) => ({
  type: GET_MLM_LEVEL_USERS_FAIL,
  payload: error,
});

export const clearTree = () => ({
  type: CLEAR_TREE,
});
