export const countries = [
  "India",
  "United States",
  "Spain",
  "Kuwait",
  "France",
  "Oman",
  "Philippines",
  "Morocco",
  "Saudi Arabia",
  "Bahrain",
  "Jordan",
  "United Kingdom",
  "Egypt",
  "Netherlands",
  "Switzerland",
  "Romania",
  "Iraq",
  "Sweden",
  "Pakistan",
  "Malaysia",
  "Russia",
  "Canada",
  "Libya",
  "Indonesia",
  "Germany",
  "Algeria",
  "Turkey",
  "Hong Kong",
  "Australia",
  "Singapore",
  "Nepal",
  "Israel",
  "Lebanon",
  "Syria",
  "Austria",
  "Sudan",
  "Ethiopia",
  "Qatar",
  "Yemen",
  "Somalia",
  "Belgium",
  "Ukraine",
  "Iran",
  "Italy",
  "Georgia",
  "Colombia",
  "South Korea",
  "Bangladesh",
  "Togo",
  "Zambia",
  "Peru",
  "Afghanistan",
  "Mali",
  "Guyana",
  "Benin",
  "Solomon Islands",
  "Liberia",
  "Sierra Leone",
  "Zimbabwe",
  "Kenya",
  "Senegal",
  "Fiji",
  "Nigeria",
  "South Sudan",
  "Tanzania",
  "Malawi",
  "Jamaica",
  "Tajikistan",
  "Ghana",
  "Burundi",
  "Barbados",
  "Belize",
  "Rwanda",
  "Brunei",
  "Cameroon",
  "Angola",
  "Uganda",
  "Brazil",
  "Mauritius",
  "Haiti",
  "Sri Lanka",
  "Chad",
  "Maldives",
  "Malta",
  "Namibia",
  "Trinidad and Tobago",
  "Papua New Guinea",
  "Mozambique",
  "Eswatini",
  "Gabon",
  "Cambodia",
  "Botswana",
  "China",
  "Thailand",
  "Bulgaria",
  "South Africa",
  "Central African Republic",
  "Bhutan",
  "Laos",
  "Saint Lucia",
  "Guinea",
  "Niger",
  "Slovakia",
  "Lesotho",
  "Kyrgyzstan",
  "Mexico",
  "North Macedonia",
  "Curaçao",
  "Mongolia",
  "Armenia",
  "Azerbaijan",
  "Greece",
  "Poland",
  "Turkmenistan",
  "Finland",
  "Ireland",
  "Norway",
  "Bolivia",
  "Luxembourg",
  "Japan",
  "Guadeloupe",
  "Bosnia and Herzegovina",
  "Paraguay",
  "Honduras",
  "The Bahamas",
  "Argentina",
  "Ecuador",
  "Kazakhstan",
  "Equatorial Guinea",
  "Venezuela",
  "Cuba",
  "Panama",
  "Vietnam",
  "Serbia",
  "Nicaragua",
  "Dominican Republic",
  "Belarus",
  "Réunion",
  "Montenegro",
  "Uruguay",
  "El Salvador",
  "Sint Maarten",
  "Saint Martin",
  "Guatemala",
  "Portugal",
  "Guinea-Bissau",
  "Moldova",
  "Turks and Caicos Islands",
  "Montserrat",
  "Cape Verde",
  "Saint Vincent and the Grenadines",
  "Costa Rica",
  "Cyprus",
  "Grenada",
  "Seychelles",
  "Eritrea",
  "Croatia",
  "Czechia",
  "Vanuatu",
  "Comoros",
  "Hungary",
  "Aruba",
  "Caribbean Netherlands",
  "Republic of the Congo",
  "Greenland",
  "Samoa",
  "Cook Islands",
  "Dominica",
  "São Tomé and Príncipe",
  "Denmark",
  "Tonga",
  "Antigua and Barbuda",
  "Svalbard and Jan Mayen",
  "Chile",
  "New Zealand",
  "U.S. Virgin Islands",
  "Lithuania",
  "New Caledonia",
  "Iceland",
  "Puerto Rico",
  "UAE",
  "Tunisia",
  "Mauritania"
];
