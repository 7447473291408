import {
  GET_COINS_PURCHASE_HISTORY,
  GET_COINS_PURCHASE_HISTORY_BEGIN,
  GET_COINS_PURCHASE_HISTORY_BY_USER_ID,
  GET_COINS_PURCHASE_HISTORY_BY_USER_ID_BEGIN,
  GET_COINS_PURCHASE_HISTORY_BY_USER_ID_FAIL,
  GET_COINS_PURCHASE_HISTORY_BY_USER_ID_SUCCESS,
  GET_COINS_PURCHASE_HISTORY_FAIL,
  GET_COINS_PURCHASE_HISTORY_SUCCESS,
  EXPORT_COINS_PURCHASE_SUMMARY,
  EXPORT_COINS_PURCHASE_SUMMARY_BEGIN,
  EXPORT_COINS_PURCHASE_SUMMARY_SUCCESS,
  EXPORT_COINS_PURCHASE_SUMMARY_FAIL,
} from "./actionTypes";

export const getCoinsPurchaseHistory = (request) => ({
  type: GET_COINS_PURCHASE_HISTORY,
  request: request,
});

export const getCoinsPurchaseHistoryBegin = () => ({
  type: GET_COINS_PURCHASE_HISTORY_BEGIN,
});

export const getCoinsPurchaseHistorySuccess = (coinsPurchaseHistory) => ({
  type: GET_COINS_PURCHASE_HISTORY_SUCCESS,
  payload: coinsPurchaseHistory,
});

export const getCoinsPurchaseHistoryFail = (error) => ({
  type: GET_COINS_PURCHASE_HISTORY_FAIL,
  payload: error,
});

export const getCoinsPurchaseHistoryByUserId = ({ request, userId }) => ({
  type: GET_COINS_PURCHASE_HISTORY_BY_USER_ID,
  request: request,
  userId: userId,
});

export const getCoinsPurchaseHistoryByUserIdBegin = () => ({
  type: GET_COINS_PURCHASE_HISTORY_BY_USER_ID_BEGIN,
});

export const getCoinsPurchaseHistoryByUserIdSuccess = (
  coinsPurchaseHistory
) => ({
  type: GET_COINS_PURCHASE_HISTORY_BY_USER_ID_SUCCESS,
  payload: coinsPurchaseHistory,
});

export const getCoinsPurchaseHistoryByUserIdFail = (error) => ({
  type: GET_COINS_PURCHASE_HISTORY_BY_USER_ID_FAIL,
  payload: error,
});

// &
export const exportCoinsPurchaseSummary = ({ saveAs, request }) => ({
  type: EXPORT_COINS_PURCHASE_SUMMARY,
  saveAs: saveAs,
  request: request,
})

export const exportCoinsPurchaseSummaryBegin = () => ({
  type: EXPORT_COINS_PURCHASE_SUMMARY_BEGIN,
})

export const exportCoinsPurchaseSummarySuccess = () => ({
  type: EXPORT_COINS_PURCHASE_SUMMARY_SUCCESS,
})

export const exportCoinsPurchaseSummaryFail = (error) => ({
  type: EXPORT_COINS_PURCHASE_SUMMARY_FAIL,
  payload: error,
})