import { call, put, takeLatest } from "@redux-saga/core/effects";
import { del, get, post, updateFormData } from "../../helpers/api_helper";
import { API_GET_GIFT_CATEGORIES } from "../../helpers/url_helper";
import {
  addGiftCategoriesBegin,
  addGiftCategoriesFail,
  addGiftCategoriesSuccess,
  deleteGiftCategoriesBegin,
  deleteGiftCategoriesFail,
  deleteGiftCategoriesSuccess,
  editGiftCategoriesBegin,
  editGiftCategoriesFail,
  editGiftCategoriesSuccess,
  getGiftCategoriesBegin,
  getGiftCategoriesByIdBegin,
  getGiftCategoriesByIdFail,
  getGiftCategoriesByIdSuccess,
  getGiftCategoriesFail,
  getGiftCategoriesSuccess,
} from "./actions";
import {
  ADD_GIFT_CATEGORIES,
  DELETE_GIFT_CATEGORIES,
  EDIT_GIFT_CATEGORIES,
  GET_GIFT_CATEGORIES,
  GET_GIFT_CATEGORIES_BY_ID,
} from "./actionTypes";

function* getGiftCategoriesSaga(res) {
  try {
    yield put(getGiftCategoriesBegin());
    const response = yield call(get, "/admin/gift/categories", res.request);

    yield put(getGiftCategoriesSuccess(response.result));
  } catch (error) {
    yield put(getGiftCategoriesFail(error));
  }
}

function* addGiftCategories({ category, callback }) {
  try {
    yield put(addGiftCategoriesBegin());
    const response = yield call(post, "/admin/gift/categories", category);

    yield put(addGiftCategoriesSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(addGiftCategoriesFail(error));
  }
}

function* getGiftCategoriesByIdSaga({ id }) {
  try {
    yield put(getGiftCategoriesByIdBegin());
    const response = yield call(get, `/admin/gift/categories/${id}`);
    yield put(getGiftCategoriesByIdSuccess(response.result));
  } catch (error) {
    yield put(getGiftCategoriesByIdFail(error));
  }
}

function* editGiftCategories({ id, categoryList }) {
  try {
    yield put(editGiftCategoriesBegin());
    const response = yield call(
      updateFormData,
      `/admin/gift/categories/${id}`,
      categoryList
    );
    // callback && callback();
    yield put(editGiftCategoriesSuccess(response.message));
  } catch (error) {
    yield put(editGiftCategoriesFail(error));
  }
}

function* deleteGiftCategories({ id, callback }) {
  try {
    yield put(deleteGiftCategoriesBegin());
    const response = yield call(del, `/admin/gift/categories/${id}`);
    callback && callback();
    yield put(deleteGiftCategoriesSuccess(response.result));
  } catch (error) {
    yield put(deleteGiftCategoriesFail(error));
  }
}

function* giftCategoriesSaga() {
  yield takeLatest(GET_GIFT_CATEGORIES, getGiftCategoriesSaga);
  yield takeLatest(ADD_GIFT_CATEGORIES, addGiftCategories);
  yield takeLatest(GET_GIFT_CATEGORIES_BY_ID, getGiftCategoriesByIdSaga);
  yield takeLatest(EDIT_GIFT_CATEGORIES, editGiftCategories);
  yield takeLatest(DELETE_GIFT_CATEGORIES, deleteGiftCategories);
}
export default giftCategoriesSaga;
