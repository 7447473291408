import {
  ADD_FLAX_PACKAGES_BEGIN,
  ADD_FLAX_PACKAGES_FAIL,
  ADD_FLAX_PACKAGES_SUCCESS,
} from "../flaxPackages/actionTypes";
import {
  ADD_GIFT_PACKAGES_BEGIN,
  ADD_GIFT_PACKAGES_FAIL,
  ADD_GIFT_PACKAGES_SUCCESS,
  DELETE_GIFT_PACKAGES_BEGIN,
  DELETE_GIFT_PACKAGES_FAIL,
  DELETE_GIFT_PACKAGES_SUCCESS,
  GET_GIFT_PACKAGES_BEGIN,
  GET_GIFT_PACKAGES_BY_ID_BEGIN,
  GET_GIFT_PACKAGES_BY_ID_FAIL,
  GET_GIFT_PACKAGES_BY_ID_SUCCESS,
  GET_GIFT_PACKAGES_FAIL,
  GET_GIFT_PACKAGES_SUCCESS,
  RESET_GIFT_PACKAGE_ERRORS,
  UPDATE_GIFT_PACKAGES_BEGIN,
  UPDATE_GIFT_PACKAGES_FAIL,
  UPDATE_GIFT_PACKAGES_SUCCESS,
} from "./actionTypes";

export const INIT_STATE = {
  loading: true,
  error: "",
  giftPackages: {},
  giftPackageData: {},
  request: {
    sort: "transfered_date",
    sort_order: "desc",
    page: 1,
    status_filter: ["active"],
    page_count: 50,
    keyword: "",
    success: "",
    deleteLoading: false,
  },
};

const giftPackages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GIFT_PACKAGES_BEGIN:
      return {
        ...state,
        loading: true,
        giftPackages: {},
      };
    case GET_GIFT_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        giftPackages: action.payload,
        error: "",
      };
    case GET_GIFT_PACKAGES_FAIL:
      return {
        ...state,
        loading: false,
        giftPackages: {},
        error: action.payload,
      };
    case DELETE_GIFT_PACKAGES_BEGIN:
      return {
        ...state,
        deleteLoading: true,
      };
    case DELETE_GIFT_PACKAGES_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        error: "",
      };
    case DELETE_GIFT_PACKAGES_FAIL:
      return {
        ...state,
        deleteLoading: false,
        error: action.payload,
      };

    case ADD_GIFT_PACKAGES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_GIFT_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "success",
        error: "",
      };
    case ADD_GIFT_PACKAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_GIFT_PACKAGES_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        giftPackageData: {},
      };
    case GET_GIFT_PACKAGES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        giftPackageData: action.payload,
      };
    case GET_GIFT_PACKAGES_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        giftPackageData: {},
        error: action.payload,
      };
    case UPDATE_GIFT_PACKAGES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_GIFT_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };
    case UPDATE_GIFT_PACKAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_GIFT_PACKAGE_ERRORS:
      return {
        ...state,
        error: "",
        success: "",
      };

    // ... other cases
    default:
      return state;
  }
};
export default giftPackages;
