import React from "react";
import { CardSubtitle } from "reactstrap";

// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const HtmlEditor = ({
  title,
  subtitle,
  editorState,
  setEditorState,
  mandatory,
}) => {
  return (
    <div className="htmlEditor" data-testid="html-editor-wrapper">
      <label>
        {title} {mandatory && <span className="mandatory">*</span>}
      </label>
      <CardSubtitle className="mb-3">{subtitle}</CardSubtitle>

      <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        editorState={editorState}
        onEditorStateChange={setEditorState}
      />
    </div>
  );
};

export default HtmlEditor;
