import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";

const Filter_userMgmt_component = ({
  setRequest,
  request,
  statusFilter,
  membershipFilter,
}) => {
  const [userList_filter_active, set_userList_filter_active] = useState(false);
  const [PR_filter_new, set_PR_filter_new] = useState(false);
  const [PR_filter_inreview, set_PR_filter_inreview] = useState(false);
  const [PR_filter_forwarded, set_PR_filter_forwarded] = useState(false);
  const [PR_filter_rejected, set_PR_filter_rejected] = useState(false);
  const [PR_filter_paymentinitiated, set_PR_filter_paymentinitiated] =
    useState(false);
  const [PR_filter_paymentprocessed, set_PR_filter_paymentprocessed] =
    useState(false);
  const [PR_filter_cancelled, set_PR_filter_cancelled] = useState(false);
  const [PR_filter_under_mena, set_PR_filter_under_mena] = useState(false);
  const [PR_filter_under_finance, set_PR_filter_under_finance] =
    useState(false);
  const [PR_filter_mena_approved, set_PR_filter_mena_approved] =
    useState(false);
  const [PR_filter_violation_suspicious, set_PR_filter_violation_suspicious] =
    useState(false);
  const [userList_filter_inactive, set_userList_filter_inactive] =
    useState(false);
  const [userList_filter_blocked, set_userList_filter_blocked] =
    useState(false);
  const [userList_filter_free, set_userList_filter_free] = useState(false);
  const [userList_filter_premium, set_userList_filter_premium] =
    useState(false);
  const [userList_filter_verified, set_userList_filter_verified] =
    useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const { t } = useTranslation();

  // useEffect(() => {
  //   statusFilter?.includes("New")
  //     ? set_PR_filter_new("New")
  //     : set_PR_filter_new(false);
  //   statusFilter?.includes("InReview")
  //     ? set_PR_filter_inreview("InReview")
  //     : set_PR_filter_inreview(false);
  //   statusFilter?.includes("Forwarded")
  //     ? set_PR_filter_forwarded("Forwarded")
  //     : set_PR_filter_forwarded(false);
  //   statusFilter?.includes("PaymentInitiated")
  //     ? set_PR_filter_paymentinitiated("PaymentInitiated")
  //     : set_PR_filter_paymentinitiated(false);
  //   statusFilter?.includes("PaymenProcessed")
  //     ? set_PR_filter_paymentprocessed("PaymenProcessed")
  //     : set_PR_filter_paymentprocessed(false);
  //   statusFilter?.includes("Rejected")
  //     ? set_PR_filter_rejected("Rejected")
  //     : set_PR_filter_rejected(false);
  //   statusFilter?.includes("Active")
  //     ? set_userList_filter_active("Active")
  //     : set_userList_filter_active(false);
  //   statusFilter?.includes("Inactive")
  //     ? set_userList_filter_inactive("Inactive")
  //     : set_userList_filter_inactive(false);
  //   statusFilter?.includes("Blocked")
  //     ? set_userList_filter_blocked("Blocked")
  //     : set_userList_filter_blocked(false);
  // }, [statusFilter, modal_center]);

  // useEffect(() => {
  //   membershipFilter?.includes("Free")
  //     ? set_userList_filter_free("Free")
  //     : set_userList_filter_free(false);
  //   membershipFilter?.includes("Premium")
  //     ? set_userList_filter_premium("Premium")
  //     : set_userList_filter_premium(false);
  //   membershipFilter?.includes("Verified")
  //     ? set_userList_filter_verified("Verified")
  //     : set_userList_filter_verified(false);
  // }, [membershipFilter, modal_center]);
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const filter_clearAll = () => {
    set_userList_filter_active(false);
    set_userList_filter_inactive(false);
    set_userList_filter_blocked(false);
    set_userList_filter_free(false);
    set_userList_filter_premium(false);
    set_userList_filter_verified(false);

    set_PR_filter_new(false);
    set_PR_filter_inreview(false);
    set_PR_filter_forwarded(false);
    set_PR_filter_paymentinitiated(false);
    set_PR_filter_paymentprocessed(false);
    set_PR_filter_rejected(false);
    set_PR_filter_mena_approved(false);
    set_PR_filter_under_finance(false);
    set_PR_filter_under_mena(false);
    set_PR_filter_violation_suspicious(false);
  };
  const handleApply = () => {
    setRequest({
      ...request,
      status_filter: [
        userList_filter_active,
        userList_filter_inactive,
        userList_filter_blocked,
        PR_filter_new,
        PR_filter_inreview,
        PR_filter_forwarded,
        PR_filter_rejected,
        PR_filter_paymentinitiated,
        PR_filter_paymentprocessed,
        PR_filter_cancelled,
        PR_filter_mena_approved,
        PR_filter_under_finance,
        PR_filter_under_mena,
        PR_filter_violation_suspicious,
      ].filter((item) => item),
      membership_filter: [
        userList_filter_free,
        userList_filter_premium,
        userList_filter_verified,
      ].filter((item) => item),
    });
    setmodal_center(false);
  };
  return (
    <div className="user-filterBtn-wrap" data-testid="component-filterPopup">
      <button
        type="button"
        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
        onClick={() => {
          tog_center();
        }}
        data-testid="component-filterButton"
      >
        <i
          style={{ paddingRight: "10px" }}
          className="fa fa-filter"
          aria-hidden="true"
        ></i>
        FILTER
      </button>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered={true}
      >
        <div className="modal-header userList-filter-header">
          <h5 className="modal-title mt-0">FILTER</h5>
          <button
            onClick={() => filter_clearAll()}
            className="btn waves-effect waves-light cust_no_shadow userList-filter-clearAll"
          >
            CLEAR ALL
          </button>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false);
            }}
            className="close"
            // data-dismiss="modal"
            // aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body userList-filter-body">
          <div className="userList-filter-SectionWrap">
            <h5 className="userList-filter-SectionTitle">
              Payout Request Status
            </h5>
            <div className="userList-filter-checkBoxWrap">
              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={PR_filter_new}
                  id="PR_filter_new"
                  onChange={() =>
                    PR_filter_new
                      ? set_PR_filter_new(!PR_filter_new)
                      : set_PR_filter_new("New")
                  }
                  checked={PR_filter_new}
                />
                <label className="form-check-label" htmlFor="PR_filter_new">
                  New Request
                </label>
              </div>

              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={PR_filter_inreview}
                  id="PR_filter_inreview"
                  onChange={() =>
                    PR_filter_inreview
                      ? set_PR_filter_inreview(!PR_filter_inreview)
                      : set_PR_filter_inreview("InReview")
                  }
                  checked={PR_filter_inreview}
                />
                <label
                  className="form-check-label"
                  htmlFor="PR_filter_inreview"
                >
                  In Review
                </label>
              </div>

              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={PR_filter_forwarded}
                  id="PR_filter_forwarded"
                  onChange={() =>
                    PR_filter_forwarded
                      ? set_PR_filter_forwarded(!PR_filter_forwarded)
                      : set_PR_filter_forwarded("Forwarded")
                  }
                  checked={PR_filter_forwarded}
                />
                <label
                  className="form-check-label"
                  htmlFor="PR_filter_forwarded"
                >
                  Forwarded
                </label>
              </div>
            </div>
            <div className="userList-filter-checkBoxWrap">
              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={PR_filter_paymentinitiated}
                  id="PR_filter_paymentinitiated"
                  onChange={() =>
                    PR_filter_paymentinitiated
                      ? set_PR_filter_paymentinitiated(
                          !PR_filter_paymentinitiated
                        )
                      : set_PR_filter_paymentinitiated("PaymentInitiated")
                  }
                  checked={PR_filter_paymentinitiated}
                />
                <label
                  className="form-check-label"
                  htmlFor="PR_filter_paymentinitiated"
                >
                  Payment Initiated
                </label>
              </div>
              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={PR_filter_paymentprocessed}
                  id="PR_filter_paymentprocessed"
                  onChange={() =>
                    PR_filter_paymentprocessed
                      ? set_PR_filter_paymentprocessed(
                          !PR_filter_paymentprocessed
                        )
                      : set_PR_filter_paymentprocessed("Completed")
                  }
                  checked={PR_filter_paymentprocessed}
                />
                <label
                  className="form-check-label"
                  htmlFor="PR_filter_paymentprocessed"
                >
                  Payment Processed
                </label>
              </div>
              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={PR_filter_rejected}
                  id="PR_filter_rejected"
                  onChange={() =>
                    PR_filter_rejected
                      ? set_PR_filter_rejected(!PR_filter_rejected)
                      : set_PR_filter_rejected("RequestDeclined")
                  }
                  checked={PR_filter_rejected}
                />
                <label
                  className="form-check-label"
                  htmlFor="PR_filter_rejected"
                >
                  Request Declined
                </label>
              </div>
            </div>
            <div className="userList-filter-checkBoxWrap">
              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={PR_filter_cancelled}
                  id="PR_filter_cancelled"
                  onChange={() =>
                    PR_filter_cancelled
                      ? set_PR_filter_cancelled(!PR_filter_cancelled)
                      : set_PR_filter_cancelled("Cancelled")
                  }
                  checked={PR_filter_cancelled}
                />
                <label
                  className="form-check-label"
                  htmlFor="PR_filter_cancelled"
                >
                  Cancelled
                </label>
              </div>
              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={PR_filter_violation_suspicious}
                  id="PR_filter_violation_suspicious"
                  onChange={() =>
                    PR_filter_violation_suspicious
                      ? set_PR_filter_violation_suspicious(
                          !PR_filter_violation_suspicious
                        )
                      : set_PR_filter_violation_suspicious("Violation")
                  }
                  checked={PR_filter_violation_suspicious}
                />
                <label
                  className="form-check-label"
                  htmlFor="PR_filter_violation_suspicious"
                >
                  Violation/Suspicious
                </label>
              </div>
              {/* <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={PR_filter_under_mena}
                  id="PR_filter_under_mena"
                  onChange={() =>
                    PR_filter_under_mena
                      ? set_PR_filter_under_mena(!PR_filter_under_mena)
                      : set_PR_filter_under_mena("UnderMENAReview")
                  }
                  checked={PR_filter_under_mena}
                />
                <label
                  className="form-check-label"
                  htmlFor="PR_filter_under_mena"
                >
                  Under MENA Review
                </label>
              </div>
              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={PR_filter_under_finance}
                  id="PR_filter_under_finance"
                  onChange={() =>
                    PR_filter_under_finance
                      ? set_PR_filter_under_finance(!PR_filter_under_finance)
                      : set_PR_filter_under_finance("UnderFinanceReview")
                  }
                  checked={PR_filter_under_finance}
                />
                <label
                  className="form-check-label"
                  htmlFor="PR_filter_under_finance"
                >
                  Under Finance Review
                </label>
              </div> */}
            </div>
            {/* <div className="userList-filter-checkBoxWrap">
              <div>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value={PR_filter_mena_approved}
                  id="PR_filter_mena_approved"
                  onChange={() =>
                    PR_filter_mena_approved
                      ? set_PR_filter_mena_approved(!PR_filter_mena_approved)
                      : set_PR_filter_mena_approved("MenaApproved")
                  }
                  checked={PR_filter_mena_approved}
                />
                <label
                  className="form-check-label"
                  htmlFor="PR_filter_mena_approved"
                >
                  Mena Approved
                </label>
              </div>
            </div> */}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              tog_center();
            }}
          >
            CANCEL
          </button>
          <button
            type="button"
            className="btn bgcolor-btn-green"
            onClick={() => handleApply()}
          >
            APPLY
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Filter_userMgmt_component;
