import {
  GET_SUPERSTAR_SUGGESTION,
  GET_SUPERSTAR_SUGGESTION_BEGIN,
  GET_SUPERSTAR_SUGGESTION_SUCCESS,
  GET_SUPERSTAR_SUGGESTION_FAIL,
  ADD_SUPERSTAR_SUGGESTION,
  ADD_SUPERSTAR_SUGGESTION_BEGIN,
  ADD_SUPERSTAR_SUGGESTION_SUCCESS,
  ADD_SUPERSTAR_SUGGESTION_FAIL,
  DELETE_SUPERSTAR_SUGGESTION,
  DELETE_SUPERSTAR_SUGGESTION_BEGIN,
  DELETE_SUPERSTAR_SUGGESTION_SUCCESS,
  DELETE_SUPERSTAR_SUGGESTION_FAIL,
} from "./actionTypes";

export const getSuperstarSuggestion = () => ({
  type: GET_SUPERSTAR_SUGGESTION,
});

export const getSuperstarSuggestionBegin = () => ({
  type: GET_SUPERSTAR_SUGGESTION_BEGIN,
});

export const getSuperstarSuggestionSuccess = (response) => ({
  type: GET_SUPERSTAR_SUGGESTION_SUCCESS,
  payload: response,
});

export const getSuperstarSuggestionFail = (error) => ({
  type: GET_SUPERSTAR_SUGGESTION_FAIL,
  payload: error,
});

/* ----------------------------------------------------- */

export const addSuperstarSuggestion = ({ data, callback }) => ({
  type: ADD_SUPERSTAR_SUGGESTION,
  data: data,
  callback: callback,
});

export const addSuperstarSuggestionBegin = () => ({
  type: ADD_SUPERSTAR_SUGGESTION_BEGIN,
});

export const addSuperstarSuggestionSuccess = (message) => ({
  type: ADD_SUPERSTAR_SUGGESTION_SUCCESS,
  payload:message
});

export const addSuperstarSuggestionFail = (error) => ({
  type: ADD_SUPERSTAR_SUGGESTION_FAIL,
  payload: error,
});

export const deleteSuperstarSuggestion = ({ data,callback }) => ({
  type: DELETE_SUPERSTAR_SUGGESTION,
  data: data,
  callback:callback
});

export const deleteSuperstarSuggestionBegin = () => ({
  type: DELETE_SUPERSTAR_SUGGESTION_BEGIN,
});

export const deleteSuperstarSuggestionSuccess = (message) => ({
  type: DELETE_SUPERSTAR_SUGGESTION_SUCCESS,
  payload:message
});

export const deleteSuperstarSuggestionFail = (error) => ({
  type: DELETE_SUPERSTAR_SUGGESTION_FAIL,
  payload: error,
});
