import { del, get, post, update } from "../../helpers/api_helper";
import moment from "moment";
import {
  addAnnouncementsBegin,
  addAnnouncementsFail,
  addAnnouncementsSuccess,
  deleteAnnouncementsBegin,
  deleteAnnouncementsFail,
  deleteAnnouncementsSuccess,
  getAnnouncementsBegin,
  getAnnouncementsByIdBegin,
  getAnnouncementsByIdFail,
  getAnnouncementsByIdSuccess,
  getAnnouncementsFail,
  getAnnouncementsSuccess,
} from "./actions";
import {
  ADD_ANNOUNCEMENTS,
  DELETE_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_BY_ID,
} from "./actionTypes";
import { call, put, takeEvery, takeLatest } from "@redux-saga/core/effects";
import {
  API_GET_ANNOUNCEMENTS,
  API_GET_ANNOUNCEMENTS_BY_ID,
} from "../../helpers/url_helper";

function* getAnnouncements() {
  try {
    yield put(getAnnouncementsBegin());
    const response = yield call(get, `/admin/notifications/broadcast`);
    yield put(getAnnouncementsSuccess(response.result));
  } catch (error) {
    yield put(getAnnouncementsFail(error));
  }
}

function* getAnnouncementsByIdSaga({ id }) {
  try {
    yield put(getAnnouncementsByIdBegin());
    const response = yield call(
      get,
      //   API_GET_ANNOUNCEMENTS_BY_ID.replace("{id}", id)
      `/admin/notifications/broadcast/${id}`
    );
    yield put(getAnnouncementsByIdSuccess(response.result));
  } catch (error) {
    yield put(getAnnouncementsByIdFail(error));
  }
}

function* deleteAnnouncementsSaga({ id, callback }) {
  try {
    yield put(deleteAnnouncementsBegin());
    const response = yield call(
      del,
      // API_GET_ANNOUNCEMENTS_BY_ID.replace("{id}", id)
      `/admin/notifications/broadcast/${id}`
    );

    callback && callback();
    yield put(deleteAnnouncementsSuccess(response.message));
  } catch (error) {
    yield put(deleteAnnouncementsFail(error));
  }
}

function* addAnnouncementsSaga({ packageData, callback, status }) {
  try {
    yield put(addAnnouncementsBegin());
    const response = yield call(
      post,
      `/admin/notifications/broadcast`,
      packageData
    );

    if (status === "send") {
      yield put(addAnnouncementsSuccess("Announcements processed"));
    } else if (status === "scheduled") {
      yield put(addAnnouncementsSuccess(response?.message));
    } else if (status === "draft") {
      yield put(addAnnouncementsSuccess(response?.message));
    }
    callback && callback();
  } catch (error) {
    yield put(addAnnouncementsFail(error));
  }
}

function* announcementSaga() {
  yield takeLatest(GET_ANNOUNCEMENTS, getAnnouncements);
  yield takeLatest(GET_ANNOUNCEMENTS_BY_ID, getAnnouncementsByIdSaga);
  yield takeLatest(DELETE_ANNOUNCEMENTS, deleteAnnouncementsSaga);
  yield takeLatest(ADD_ANNOUNCEMENTS, addAnnouncementsSaga);
}

export default announcementSaga;
