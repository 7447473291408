import {
  GET_POSTAT_SIGNED_COOKIES,
  GET_POSTAT_SIGNED_COOKIES_BEGIN,
  GET_POSTAT_SIGNED_COOKIES_FAILED,
  GET_POSTAT_SIGNED_COOKIES_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  cookies: {},
  error: "",
};

const contentManagement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_POSTAT_SIGNED_COOKIES_BEGIN:
      return {
        ...state,

        loading: true,
        error: "",
        success: "",
      };
    case GET_POSTAT_SIGNED_COOKIES_SUCCESS:
      return {
        ...state,
        cookies: action.payload,
        loading: false,
        error: "",
      };
    case GET_POSTAT_SIGNED_COOKIES_FAILED:
      return {
        ...state,
        cookies: {},
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default contentManagement;
