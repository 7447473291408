import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  CardSubtitle,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { checkUserPermission } from "../../../helpers/utils";

const GeneralSettings = ({
  freeUserBroadcastMin,
  premiumUserBroadcastMin,
  freeUserOTOMTextMin,
  premiumUserOTOMTextMin,
  policyInterval,
  minDearsforVerifiedStatus,
  huddleAdminsLimitForFree,
  huddleMessageDeleteTimeoutFree,
  huddleParticipantsLimitForFree,
  huddleAdminsLimitForPremium,
  huddleMessageDeleteTimeoutPremium,
  huddleParticipantsLimitForPremium,
  huddleVoiceNoteLimitForFree,
  huddleVoiceNoteLimitForPremium,
  otomVoiceNoteLimitForFree,
  otomVoiceNoteLimitForPremium,
  videoLimitForFree,
  videoLimitForPremium,
  flashDurationForPremium,
  flashDurationForFree,
  minimumPointConversion,
  flaxConversionRate,
  birthdayCelebEligibility,

  huddleAdminsLimitForFreeUpdate,
  huddleAdminsLimitForPremiumUpdate,
  huddleMessageDeleteTimeoutFreeUpdate,
  huddleMessageDeleteTimeoutPremiumUpdate,
  huddleParticipantsLimitForFreeUpdate,
  huddleParticipantsLimitForPremiumUpdate,
  huddleVoiceNoteLimitFreeUpdate,
  huddleVoiceNoteLimitPremiumUpdate,
  otomVoiceNoteLimitPremiumUpdate,
  otomVoiceNoteLimitFreeUpdate,
  videoLimitPremiumUpdate,
  videoLimitFreeUpdate,
  freeUserMinBroadcastUpdate,
  premiumUserMinBroadcastUpdate,
  freeUserMinOTOMTextUpdate,
  premiumUserMinOTOMTextUpdate,
  policyIntervalUpdate,
  minDearsforVerifiedStatusUpdate,
  generalSettingsSaveHandler,
  huddleSellMinParticipants,
  huddleSellMinParticpantsUpdate,
  flashDurationForPremiumUpdate,
  flashDurationForFreeUpdate,
  minimumPointConversionUpdate,
  flaxConversionRateUpdate,
  birthdayCelebEligibilityUpdate,
}) => {
  const { t } = useTranslation();
  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const isEditable = userPermissions?.edit || false;

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Settings",
          "App settings"
        )
      );
    }
  }, [loginData]);

  return (
    <Card className="appSettingCard">
      <CardTitle className="cardTitle">
        {t("AppSettings.GeneralSettings")}
      </CardTitle>
      <CardBody>
        {/* <CardSubtitle className="text-muted cardSubTitle">
          {t("AppSettings.SubTitle")}
        </CardSubtitle> */}
        <AvForm
          onValidSubmit={(e, v) => {
            //handleUpdate(e, v);
          }}
        >
          <div className="generalSettings">
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.PremiumUsersBroadcastMin")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="freeUsersMinutes"
                  type="number"
                  onChange={premiumUserMinBroadcastUpdate}
                  value={premiumUserBroadcastMin}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in minutes</span>
              </div>
            </div>

            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.FreeUsersBroadcastMin")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText "
                  name="freeUsersMinutes"
                  type="number"
                  onChange={freeUserMinBroadcastUpdate}
                  value={freeUserBroadcastMin}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in minutes</span>
              </div>
            </div>
          </div>

          <div className="generalSettings">
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.PremiumUsersOTOMTextMin")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="freeUsersMinutes"
                  type="number"
                  onChange={premiumUserMinOTOMTextUpdate}
                  value={premiumUserOTOMTextMin}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in minutes</span>
              </div>
            </div>

            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.FreeUsersOTOMTextMin")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText "
                  name="freeUsersMinutes"
                  type="number"
                  onChange={freeUserMinOTOMTextUpdate}
                  value={freeUserOTOMTextMin}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in minutes</span>
              </div>
            </div>
          </div>

          <div className="generalSettings">
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.HuddleMessageDeleteTimeoutPremium")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="freeUsersMinutes"
                  type="number"
                  onChange={huddleMessageDeleteTimeoutPremiumUpdate}
                  value={huddleMessageDeleteTimeoutPremium}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in minutes</span>
              </div>
            </div>

            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.HuddleMessageDeleteTimeoutFree")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="freeUsersMinutes"
                  type="number"
                  onChange={huddleMessageDeleteTimeoutFreeUpdate}
                  value={huddleMessageDeleteTimeoutFree}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in minutes</span>
              </div>
            </div>
          </div>

          <div className="generalSettings">
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.HuddleParticipantsLimitForPremium")}</div>
              <AvField
                className="generalSettingsText"
                name="freeUsersMinutes"
                type="number"
                onChange={huddleParticipantsLimitForPremiumUpdate}
                value={huddleParticipantsLimitForPremium}
                disabled={!isEditable}
              />
            </div>

            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.HuddleParticipantsLimitForFree")}</div>
              <AvField
                className="generalSettingsText"
                name="freeUsersMinutes"
                type="number"
                onChange={huddleParticipantsLimitForFreeUpdate}
                value={huddleParticipantsLimitForFree}
                disabled={!isEditable}
              />
            </div>
          </div>

          <div className="generalSettings">
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.HuddleAdminsLimitForPremium")}</div>
              <AvField
                className="generalSettingsText"
                name="freeUsersMinutes"
                type="number"
                onChange={huddleAdminsLimitForPremiumUpdate}
                value={huddleAdminsLimitForPremium}
                disabled={!isEditable}
              />
              {/* <span className="settingsSubTextWrapper">in seconds</span> */}
            </div>

            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.HuddleAdminsLimitForFree")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="freeUsersMinutes"
                  type="number"
                  onChange={huddleAdminsLimitForFreeUpdate}
                  value={huddleAdminsLimitForFree}
                  disabled={!isEditable}
                />
              </div>
              {/* <span className="settingsSubTextWrapper">in seconds</span> */}
            </div>
          </div>
          <div className="generalSettings">
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.HuddleVoiceNoteLimitForPremium")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="huddleVoiceNoteLimitPremium"
                  type="number"
                  onChange={huddleVoiceNoteLimitPremiumUpdate}
                  value={huddleVoiceNoteLimitForPremium}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in seconds</span>
              </div>
            </div>

            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.HuddleVoiceNoteLimitForFree")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="huddleVoiceNoteLimitFree"
                  type="number"
                  onChange={huddleVoiceNoteLimitFreeUpdate}
                  value={huddleVoiceNoteLimitForFree}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in seconds</span>
              </div>
            </div>
          </div>
          <div className="generalSettings">
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.OtomVoiceNoteLimitForPremium")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="otomVoiceNoteLimitPremium"
                  type="number"
                  onChange={otomVoiceNoteLimitPremiumUpdate}
                  value={otomVoiceNoteLimitForPremium}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in seconds</span>
              </div>
            </div>

            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.OtomVoiceNoteLimitForFree")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="otomVoiceNoteLimitFree"
                  type="number"
                  onChange={otomVoiceNoteLimitFreeUpdate}
                  value={otomVoiceNoteLimitForFree}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in seconds</span>
              </div>
            </div>
          </div>

          <div className="generalSettings">
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.VideoLimitForPremium")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="otomVoiceNoteLimitPremium"
                  type="number"
                  onChange={videoLimitPremiumUpdate}
                  value={videoLimitForPremium}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in seconds</span>
              </div>
            </div>

            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.VideoLimitForFree")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="otomVoiceNoteLimitFree"
                  type="number"
                  onChange={videoLimitFreeUpdate}
                  value={videoLimitForFree}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in seconds</span>
              </div>
            </div>
          </div>
          {/* for flash duartions */}
          <div className="generalSettings">
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.FlashDurationForPremium")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="flashDurationForpremium"
                  type="number"
                  onChange={flashDurationForPremiumUpdate}
                  value={flashDurationForPremium}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in seconds</span>
              </div>
            </div>

            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.FlashDurationForFree")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="flashDurationForFree"
                  type="number"
                  onChange={flashDurationForFreeUpdate}
                  value={flashDurationForFree}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">in seconds</span>
              </div>
            </div>
          </div>

          {/* flax conversions */}

          <div className="generalSettings">
            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.FlaxConversionRate")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="flaxConversionRate"
                  type="number"
                  onChange={flaxConversionRateUpdate}
                  value={flaxConversionRate}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">coins</span>
              </div>
            </div>

            <div className="generalSettingsFormRow col-md-8 col-lg-5">
              <div>{t("AppSettings.MinimumPointConversion")}</div>
              <div className="SettingsWrapper">
                <AvField
                  className="generalSettingsText"
                  name="minimumPointConversion"
                  type="number"
                  onChange={minimumPointConversionUpdate}
                  value={minimumPointConversion}
                  disabled={!isEditable}
                />
                <span className="settingsSubTextWrapper">coins</span>
              </div>
            </div>
          </div>

          <div className="generalSettingsFormRow">
            <div>
              {t("AppSettings.PolicyToShow")}{" "}
              <span className="mandatory">*</span>
            </div>
            <AvField
              className="generalSettingsText generalSettingsPolicyText"
              name="freeUsersMinutes"
              type="number"
              onChange={policyIntervalUpdate}
              value={policyInterval}
              disabled={!isEditable}
            />
          </div>
          <div className="generalSettingsFormRow">
            <div>
              {t("AppSettings.MinDearsforProfileVerifiedStatus")}{" "}
              <span className="mandatory">*</span>
            </div>
            <AvField
              className="generalSettingsText generalSettingsPolicyText"
              name="freeUsersMinutes"
              type="number"
              onChange={minDearsforVerifiedStatusUpdate}
              value={minDearsforVerifiedStatus}
              disabled={!isEditable}
            />
          </div>
          <div className="generalSettingsFormRow">
            <div>
              {t("AppSettings.huddleSellMinParticipants")}{" "}
              <span className="mandatory">*</span>
            </div>
            <AvField
              className="generalSettingsText generalSettingsPolicyText"
              name="freeUsersMinutes"
              type="number"
              onChange={huddleSellMinParticpantsUpdate}
              value={huddleSellMinParticipants}
              disabled={!isEditable}
            />
          </div>
          <div className="generalSettingsFormRow">
            <div>
              {t("AppSettings.birthdayCelebrationEligibility")}{" "}
              <span className="mandatory">*</span>
            </div>
            <AvField
              className="generalSettingsText generalSettingsPolicyText"
              name="freeUsersMinutes"
              type="number"
              onChange={birthdayCelebEligibilityUpdate}
              value={birthdayCelebEligibility}
              disabled={!isEditable}
            />
          </div>
        </AvForm>
      </CardBody>
      <CardFooter className="cardFooter">
        <button
          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
          onClick={generalSettingsSaveHandler}
          disabled={!policyInterval || !isEditable}
        >
          {t("settings.Save")}
        </button>
      </CardFooter>
    </Card>
  );
};

export default GeneralSettings;
