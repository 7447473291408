import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Redirect } from "react-router-dom";

//Import Images
import { checkNextValidScreen } from "../../helpers/utils"
import Loader from "../../components/Common/Loader";

const LoginNavigator = () => {
  const [nextValidScreen, setNextValidScreen] = useState(null);
  const loginData = useSelector((state) => state.Login);

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setNextValidScreen(checkNextValidScreen(loginData?.rolePermissions?.role))
    }
  }, [loginData?.rolePermissions]);

  return (
    <React.Fragment>
      {(!nextValidScreen) ? <Loader darkBg={true} /> : <Redirect to={nextValidScreen} />}
    </React.Fragment>
  )
}
export default LoginNavigator
