export const ADD_MANUAL_PP = "ADD_MANUAL_PP ";
export const ADD_MANUAL_PP_BEGIN = "ADD_MANUAL_PP_BEGIN";
export const ADD_MANUAL_PP_SUCCESS = "ADD_MANUAL_PP_SUCCESS";
export const ADD_MANUAL_PP_ERROR = "ADD_MANUAL_PP_ERROR"

export const DELETE_MANUAL_PP = "DELETE_MANUAL_PP ";
export const DELETE_MANUAL_PP_BEGIN = "DELETE_MANUAL_PP_BEGIN";
export const DELETE_MANUAL_PP_SUCCESS = "DELETE_MANUAL_PP_SUCCESS";
export const DELETE_MANUAL_PP_ERROR = "DELETE_MANUAL_PP_ERROR"

export const GET_MANUAL_PP_BEGIN = "GET_MANUAL_PP_BEGIN";
export const GET_MANUAL_PP_SUCCESS = "GET_MANUAL_PP_SUCCESS";
export const GET_MANUAL_PP_ERROR = "GET_MANUAL_PP_ERROR"
export const GET_ALL_MANUAL_PP = "GET_ALL_MANUAL_PP";

export const RESET_MANUAL_PP_STATES = "RESET_MANUAL_PP_STATES";