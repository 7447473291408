import React, { useEffect, useState } from "react";
import {
  Container,
  Modal,
  ModalBody,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import Datatable from "../../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  challengeHistoryColumnData,
  giftTransferColumnData,
} from "../../../../helpers/columns";

import { convertTime, convertToUAETime } from "../../../../helpers/utils";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../../helpers/constants";

import { getGiftTransferHistory } from "../../../../store/GiftTransfers/actions";
import {
  getChallengesHistory,
  getChallengesHistoryByUserId,
} from "../../../../store/podiums/actions";
import Loader from "../../../../components/Common/Loader";
import ContributorDetails from "./contributorDetails";
import ParticipantDetails from "./participantDetails";
import CountUp from "react-countup";
import Coin_bal_icon from "../../../../assets/icons/sdfl_icons/coin-bal-icon.svg";

const ChallengeHistoryByUser = ({ toggle, modal, userId }) => {
  const [contributorModal, setContributorModal] = useState(false);
  const [participantModal, setParticipantModal] = useState(false);
  const [currentChallenge, setCurrentChallenge] = useState("");
  const [currentPodium, setCurrentPodium] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "transfered_date",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    per_page: 25,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const data = useSelector((state) => state.Podiums);
  console.log("challnge", data);

  useEffect(() => {
    request && dispatch(getChallengesHistoryByUserId({ request, userId }));
  }, [request]);

  const challengeData =
    data?.challengesDataByUser?.challenges_history?.length >= 0 &&
    data?.challengesDataByUser?.challenges_history?.map((challenge, index) => {
      return {
        ...challenge,
        no: (request?.page - 1) * request?.per_page + index + 1,
        time_created: (
          <>
            <div
              className="text-secondary"
              data-tag="allowRowEvents"
              title={challenge?.time_created}
            >
              {convertTime(challenge?.time_created)}
            </div>
          </>
        ),
      };
    });

  const showContributorDetails = () => {
    setContributorModal(!contributorModal);
  };
  const showParticipantDetails = () => {
    setParticipantModal(!participantModal);
  };

  const tableRowClickHandler = (param) => {
    setCurrentChallenge(param?.challenge_id);
    setCurrentPodium(param?.podium_id);
    param?.challenge_name !== "GIFTS"
      ? showContributorDetails()
      : showParticipantDetails();
  };

  return (
    <>
      <div>
        <Modal
          centered
          isOpen={modal}
          toggle={toggle}
          style={{ minWidth: "1000px" }}
        >
          {/* <ModalHeader toggle={toggle}>Flax summary</ModalHeader> */}
          <ModalBody>
            <>
              <Container id="userManagementWrapper" data-testid="userMgmtWrap">
                <Row>
                  <Breadcrumbs title="Tables" breadcrumbItem="Challenges" />
                  <div
                    className="w-auto alert alert-warning text-start "
                    role="alert"
                  >
                    <strong>Note:</strong> Values displayed before{" "}
                    <em>08/10/2024</em> are not valid, as the 10% revenue share
                    for both Host and Flashat was introduced after this date.
                  </div>
                  {!data?.loading && (
                    <>
                      <Col md={6} xl={3}>
                        <Card>
                          <CardBody>
                            <div className="float-end mt-2">
                              <img src={Coin_bal_icon} alt="coin" />
                            </div>
                            <div>
                              <h4 className="mb-1 mt-1">
                                <span>
                                  <CountUp
                                    end={
                                      +data?.challengesDataByUser
                                        ?.total_coins_won
                                    }
                                  />
                                </span>
                              </h4>
                              <p className="text-muted mb-0">
                                Total COiNS earned from Challenge
                              </p>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </>
                  )}
                  {/* <div className="container-fluid"> */}
                  <Datatable
                    tableID={"flaxTable"}
                    columns={challengeHistoryColumnData}
                    rows={challengeData}
                    setRequest={setRequest}
                    request={request}
                    //   dateFilter
                    //   dateType="Transfered Date Range"
                    totalRecords={data?.challengesDataByUser?.total_items}
                    //   search
                    // add
                    // dropDown
                    // languages={languages}
                    // addNewClickHandler={addNewClickHandler}
                    // selectedLanguage={selectedLanguage}
                    // setSelectedLanguage={setSelectedLanguage}
                    // addNewLabel={t("Admins.addAdmin.addBtn")}
                    //   searchTerm={data?.request?.keyword}
                    tableRowClicked={(param) => tableRowClickHandler(param)}
                    loading={data?.loading}
                    tableCardClassName={"snoTable"}
                  />
                  {/* </div> */}
                </Row>
              </Container>
              {/* {data?.loading && <Loader darkBg={true} />} */}

              {data?.loading && <Loader darkBg={true} />}
            </>
          </ModalBody>
          {contributorModal && (
            <ContributorDetails
              toggle={showContributorDetails}
              modal={contributorModal}
              challengeId={currentChallenge}
              podiumId={currentPodium}
            />
          )}
          {participantModal && (
            <ParticipantDetails
              toggle={showParticipantDetails}
              modal={participantModal}
              challengeId={currentChallenge}
              podiumId={currentPodium}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

export default ChallengeHistoryByUser;
