import DateRangeSelector from "../../components/UI/DateRangeSelector";
import moment from "moment";
import React, { useState } from "react";
import { Dropdown, Label } from "semantic-ui-react";
import {Modal, ModalHeader, ModalFooter, Button, ModalBody, Badge, InputGroup} from "reactstrap";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useTranslation } from "react-i18next";
import { Input } from "@material-ui/core";

const AppInstallationsFilter = ({
  countryFilter,
  setRequest,
  request,
  countries,
  dashboardFilter,
  no_date_range_label,
  regions,
  updateRegion,
  saveRegionData
}) => {
  const { t } = useTranslation();
  const [preSelectedCountries, setCountries] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [enableSave, setSaveEnable] = useState(false);
  const [modal, setModal] = useState(false);
  const [regionName, setRegionName] = useState('');

  const toggle = () => setModal(!modal);

  const getRegion = (regionId) => regions.find(region => region.id === regionId) || null;
  const initDateRange = [
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ];
  const [modal_center, setmodal_center] = useState(false);
  const [dateRangestate, setDateRangestate] = useState(initDateRange);

  const getDateRangeString = () => {
    return (
      moment(dateRangestate[0].startDate).format("DD-MM-YYYY") +
      " - " +
      moment(dateRangestate[0].endDate).format("DD-MM-YYYY")
    );
  };

  const tog_center = () => {
    setmodal_center(!modal_center);
  };

  const getCountryOptions = () =>
    countryFilter?.sort().map((country) => ({
      key: country,
      value: country,
      text: country,
    }));

    const getRegionOptions = () => {
      const region =  regions?.map(region => ({
        value: region.id,
        key: region.region_name,
        text: region.region_name
      }) )

      return [ {value: 0, key: 'New', text: 'Create New Region'}, ...region]
    }

  const saveRegion = () => {
    const region = getRegion(selectedRegion);
    if(region) {
      updateRegion({...region, countries: preSelectedCountries})
    } else {
      saveRegionData({region_name: regionName, countries: preSelectedCountries});
      setRegionName("");
    }
    toggle();
  }

  const onRegionSelection = (e, data) => {
      setSelectedRegion(data.value);
      const region = regions?.find(region => region.id === data.value);
      if(region) {
        const countries = [ ...region.countries];
        setCountries(countries);
        handleApply({country: countries});
      } else {
        setCountries([]);
      }
      
      setSaveEnable(false);
     
  }

  const onCountrySelection = (data) => {
    setCountries([...data.value]);
    handleApply({ country: data.value });
    setSaveEnable(true);
  }
  const handleApply = (filterData) => {
    if (dashboardFilter && "year_filter" in request) {
      delete request.year_filter;
    }
    setRequest({
      ...request,
      ...filterData,
    });
  };

  const onClickOk = () => {
    handleApply({
      from_date: moment(dateRangestate[0].startDate).format("DD-MM-YYYY"),
      to_date: moment(dateRangestate[0].endDate).format("DD-MM-YYYY"),
    });
    tog_center();
  };

  return (
    <>
      <div className="date-range-filter">
        {no_date_range_label ? null : (
          <span className="date-range-label">
            {t("AppInstallations.date_range")}
          </span>
        )}
        <input
          type="text"
          className="inputField_CSS installations-date-range"
          readOnly
          value={getDateRangeString()}
          onClick={tog_center}
        />
        <Modal
          isOpen={modal_center}
          toggle={tog_center}
          centered
          className="date-range-modal"
        >
          <DateRangeSelector
            handleDateRangeChange={(event) => {
              setDateRangestate(event);
            }}
            dateRange={dateRangestate}
            minDate={new Date("2021,Jan,1")}
          />
          <div className="modal-footer">
            <button
              className="btn btn-light"
              onClick={() => {
                setDateRangestate(initDateRange);
                tog_center();
              }}
              data-testid="component-filter-cancel"
            >
              {t("AppInstallations.cancel")}
            </button>
            <button
              className="btn bgcolor-btn-green"
              onClick={onClickOk}
              data-testid="component-filter-apply"
            >
              {t("AppInstallations.ok")}
            </button>
          </div>
        </Modal>
      </div>
      <div style={{display: "flex",flexDirection: "column", alignItems:"flex-end"}}>
      {
        regions &&
         <div className="country-filter-container" style={{width: "250px", alignItems:"flex-end", zIndex:199}}>
         <span className="country-label" style={{paddingRight: '17px'}}>
           {t("AppInstallations.regions")}
         </span>
         <Dropdown
           placeholder= {t("AppInstallations.regions")}
           fluid
           selection
           value={selectedRegion}
           options={getRegionOptions()}
           onChange={(e, data) => {
            onRegionSelection(e, data);
             //handleApply({ country: data.value });
           }}
         />
       </div>
      }
      {countries && (
        <div className="country-filter-container" style={{ maxWidth: "750px"}}>
          <span className="country-label">
            {t("AppInstallations.countries")}
          </span>
          <Dropdown
            placeholder="Countries"
            fluid
            multiple
            selection
            value={preSelectedCountries}
            options={getCountryOptions()}
            onChange={(e, data) => {
              onCountrySelection(data)
            }}
          />
          <button style={{minWidth:"50px", height:"30px", alignSelf: "center"}} className="btn btn-primary" disabled={!enableSave} onClick={toggle}> Save</button>
        </div>
       
      )}
      
       </div>

       <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}> {(selectedRegion !== 0 && selectedRegion !== null) ? 'UPDATE REGION' : 'SAVE REGION' }</ModalHeader>
        <ModalBody>
        <p>{(selectedRegion!==0 && selectedRegion !== null)  && `Do you want to update the Region - ${ getRegion(selectedRegion)?.region_name} ?`  }</p>
        { (selectedRegion === 0 || selectedRegion === null ) && <InputGroup>
           <Label>Region Name</Label>
           <Input type="text" value={regionName} onChange={(e) => setRegionName(e.target.value)}/>
           <span className="text-danger"> {!regionName && '* Enter a valid region name'}</span>
          </InputGroup>
         }
        <br/>
        <p> Selected Countries: </p>
        <div style={{display: "flex"}}>
          {preSelectedCountries.map(country => <span className="bg-soft-green" style={{padding: "2px 10px", marginRight: "10px",borderRadius: "5px"}}>
    {country}
  </span>)}
        </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveRegion}>
           { selectedRegion === 0 ? 'Save' : 'Update'} 
          </Button>{' '}
          <Button color="secondary" onClick={() => {toggle(); setRegionName("");}}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AppInstallationsFilter;
