import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Container, Row, UncontrolledAlert } from "reactstrap";
import Datatable from "../../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { checkUserPermission, isLottieAnimation } from "../../../helpers/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  deleteGiftPackages,
  getGiftPackages,
} from "../../../store/GiftPackages/actions";
import Edit_icon from "../../../assets/icons/ebud-icons/Edit.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import Loader from "../../../components/Common/Loader";
import Lottie from "lottie-react";
import {
  giftCategoryColumnData,
  giftPackageColumnData,
} from "../../../helpers/columns";
import {
  deleteGiftCategories,
  getGiftCategories,
} from "../../../store/GiftCategories/actions";

const GiftCategories = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const warningAlertRef = useRef(null);
  const basicRequest = {
    sort: "created_on",
    sort_order: "desc",
    page: 1,
    status_filter: ["Active"],
    page_count: 50,
    keyword: "",
  };

  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  const history = useHistory();
  //   useEffect(() => {
  //     if (loginData?.rolePermissions?.role) {
  //       setUserPermissions(
  //         checkUserPermission(
  //           loginData?.rolePermissions?.role,
  //           "Huddle Management",
  //           "Huddle categories"
  //         )
  //       );
  //     }
  //   }, [loginData]);

  const [request, setRequest] = useState({ ...basicRequest });

  const data = useSelector((state) => state?.GiftCategories);

  useEffect(() => {
    dispatch(getGiftCategories());
  }, [request]);

  const formatCategoryData = useCallback(() => {
    return (
      data?.categories?.length &&
      data?.categories?.map((category, index) => ({
        ...category,
        no: (request?.page - 1) * request?.page_count + index + 1,

        gift_category: (
          <div data-tag="allowRowEvents" title={category.english}>
            {category?.english}
          </div>
        ),
        // category_type: (
        //   <div data-tag="allowRowEvents" title={category.category_type}>
        //     {category.category_type}
        //   </div>
        // ),
        actions: (
          <div className="cust-table-actions-wrap">
            <button
              className=" color-violet action-btn"
              title="Edit"
              onClick={() =>
                history.push({
                  pathname: `/gift-categories-edit/${category.id}`,
                  state: { id: category?.id },
                })
              }
              // disabled={!isEditable}
            >
              <img
                src={Edit_icon}
                alt="Edit"
                // style={{
                //   opacity: !isEditable ? "50%" : "",
                //   cursor: !isEditable ? "not-allowed" : "",
                // }}
              />
            </button>

            <button
              onClick={() => deletePromptHandler(category.id)}
              className=" color-red action-btn"
              title="Delete"
              // disabled={!isDeleteable}
            >
              <img
                src={Delete_icon}
                alt="Delete"
                // style={{
                //   opacity: !isDeleteable ? "50%" : "",
                //   cursor: !isDeleteable ? "not-allowed" : "",
                // }}
              />
            </button>
          </div>
        ),
      }))
    );
  }, [data?.categories, request]);
  const giftCategoryData = useMemo(
    () => formatCategoryData(),
    [formatCategoryData]
  );

  const addNewClickHandler = () => {
    history.push("/gift-categories-addNew");
  };

  const okHandler = () => {
    dispatch(
      deleteGiftCategories({
        id: promptMessage.id,
        callback: () => dispatch(getGiftCategories(request)),
      })
    );
  };

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to delete 
        this Package ?`,
      type: "delete",
    });
  };

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        // ref={pageWrapRef}
      >
        <Container>
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Gift Categories" />
            {data?.error && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color="danger"
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error}
                </UncontrolledAlert>
              </div>
            )}
            <div className="">
              <Datatable
                tableID={"adminTable"}
                columns={giftCategoryColumnData}
                rows={giftCategoryData}
                setRequest={setRequest}
                request={request}
                add
                addNewClickHandler={addNewClickHandler}
                addNewLabel={t("Admins.addAdmin.addBtn")}
                // searchTerm={data?.request?.keyword}
                // loading={data?.loading}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
        </Container>
        <ConfirmationAlert
          {...promptMessage}
          modal_center={showPromptPopUp}
          setmodal_center={setShowPromptPopUp}
          onOK={okHandler}
        />
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default GiftCategories;
