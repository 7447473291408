import { AvForm } from "availity-reactstrap-validation";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import { hi } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Card, Col, Container, Row } from "reactstrap";
import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { addFlaxPackages } from "../../../store/flaxPackages/actions";

const AddPackage = () => {
  const [disabled, setDisabled] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [flax, setFlax] = useState("");
  const [usd, setUsd] = useState("");
  const [androidProductId, setAndroidProductId] = useState("");
  const [iosProductId, setIosProductId] = useState("");

  useEffect(() => {
    const isFlaxValid = /^\d*\.?\d*$/.test(flax);
    const isUsdValid = /^\d*\.?\d*$/.test(usd);

    if (
      flax === "" ||
      usd === "" ||
      androidProductId === "" ||
      iosProductId === ""
    ) {
      setDisabled(true);
    } else {
      if (isFlaxValid && isUsdValid) setDisabled(false);
      else setDisabled(true);
    }
  }, [flax, usd, androidProductId, iosProductId]);
  console.log(disabled);
  const handleSubmit = (e, v) => {
    e.preventDefault();
    dispatch(
      addFlaxPackages({
        packageData: {
          flax: flax,
          usd: usd,
          android_product_id: androidProductId,
          ios_product_id: iosProductId,
        },
        callback: () => history.push("/FLix-packages"),
      })
    );
  };

  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      //   ref={pageWrapRef}
    >
      {/* <Prompt
      message={(location, action) => {
        if (action === "POP") {
          return confirmBrowserBack();
        }
      }}
      when={formChanged}
    /> */}
      <BackButton
        label="FLiX Packages"
        handleClick={() => history.push("/FLix-packages")}
      />
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Add Package" />
          {/* {(data?.error || data?.success) && (
          <div ref={warningAlertRef}>
            <UncontrolledAlert
              color={data?.error ? "danger" : "success"}
              className="alert-dismissible fade show"
              role="alert"
            >
              {data?.error || data?.success}
            </UncontrolledAlert>
          </div>
        )} */}
          <Col xl="8">
            <Card className="mb-0 p-4 my-4">
              <div className="text-muted">
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleSubmit(e, v);
                  }}
                >
                  <div
                    className="table-responsive mt-4"
                    style={{ overflowX: "hidden" }}
                  >
                    <div className="d-flex mb-4">
                      <div className="col-md-6">
                        <label className="col-md-8 col-form-label">
                          Enter FLiX
                          <span className="mandatory">*</span>
                        </label>
                        <div className="col-md-8 dobWrapper">
                          <AvInput
                            name="adminName"
                            type="text"
                            className="form-control mb-4 "
                            value={flax}
                            id="name-input"
                            onChange={(e) => {
                              setFlax(e.target.value);
                            }}
                            placeholder="Enter FLiX"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mb-4">
                      <div className="col-md-6">
                        <label className="col-md-8 col-form-label">
                          Enter USD
                          <span className="mandatory">*</span>
                        </label>
                        <div className="col-md-8 dobWrapper">
                          <AvInput
                            name="adminName"
                            type="text"
                            className="form-control mb-4 "
                            value={usd}
                            id="name-input"
                            onChange={(e) => {
                              setUsd(e.target.value);
                            }}
                            placeholder="Enter USD amount"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mb-4">
                      <div className="col-md-6">
                        <label className="col-md-8 col-form-label">
                          Enter Android Product ID
                          <span className="mandatory">*</span>
                        </label>
                        <div className="col-md-8 dobWrapper">
                          <AvInput
                            name="adminName"
                            type="text"
                            className="form-control mb-4 "
                            value={androidProductId}
                            id="name-input"
                            onChange={(e) => {
                              setAndroidProductId(e.target.value);
                            }}
                            placeholder="Enter Android Product ID"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mb-4">
                      <div className="col-md-6">
                        <label className="col-md-8 col-form-label">
                          Enter IOS Product ID
                          <span className="mandatory">*</span>
                        </label>
                        <div className="col-md-8 dobWrapper">
                          <AvInput
                            name="adminName"
                            type="text"
                            className="form-control mb-4 "
                            value={iosProductId}
                            id="name-input"
                            onChange={(e) => {
                              setIosProductId(e.target.value);
                            }}
                            placeholder="Enter IOS Product ID"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <button
                        type="submit"
                        // disabled={isButtonDisabled}
                        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                        // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                        disabled={disabled}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </AvForm>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <ConfirmationAlert
      {...promptMessage}
      modal_center={showPromptPopUp}
      setmodal_center={setShowPromptPopUp}
      onOK={okHandler}
    /> */}
      {/* {data?.loading && <Loader darkBg={true} />} */}
    </div>
  );
};

export default AddPackage;
