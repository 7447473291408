/* NOTIFICATIONS */

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_BEGIN = "GET_NOTIFICATIONS_BEGIN";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";

export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const GET_ALL_NOTIFICATIONS_BEGIN = "GET_ALL_NOTIFICATIONS_BEGIN";
export const GET_ALL_NOTIFICATIONS_SUCCESS = "GET_ALL_NOTIFICATIONS_SUCCESS";
export const GET_ALL_NOTIFICATIONS_FAIL = "GET_ALL_NOTIFICATIONS_FAIL";

export const MARK_ALL_AS_READ = "MARK_ALL_AS_READ";
export const MARK_ALL_AS_READ_BEGIN = "MARK_ALL_AS_READ_BEGIN";
export const MARK_ALL_AS_READ_SUCCESS = "MARK_ALL_AS_READ_SUCCESS";
export const MARK_ALL_AS_READ_FAIL = "MARK_ALL_AS_READ_FAIL";

export const GET_UNSEEN_COUNT = "GET_UNSEEN_COUNT";
export const GET_UNSEEN_COUNT_BEGIN = "GET_UNSEEN_COUNT_BEGIN";
export const GET_UNSEEN_COUNT_SUCCESS = "GET_UNSEEN_COUNT_SUCCESS";
export const GET_UNSEEN_COUNT_FAIL = "GET_UNSEEN_COUNT_FAIL";

export const RESET_UNSEEN_COUNT = "RESET_UNSEEN_COUNT";
