import {
    ADD_MANUAL_COIN,
    GET_MANUAL_COIN_BEGIN,
    GET_MANUAL_COIN_SUCCESS,
    GET_MANUAL_COIN_ERROR,
    ADD_MANUAL_COIN_BEGIN,
    ADD_MANUAL_COIN_SUCCESS,
    ADD_MANUAL_COIN_ERROR,
    GET_ALL_MANUAL_COIN,
    DELETE_MANUAL_COIN,
    DELETE_MANUAL_COIN_BEGIN,
    DELETE_MANUAL_COIN_SUCCESS,
    DELETE_MANUAL_COIN_ERROR,
    RESET_MANUAL_COIN_STATES,
} from "./actionTypes";

export const getAllManualCoin = (payload) => {
    return { type: GET_ALL_MANUAL_COIN, payload };
};

export const getAllManualCoinBegin = (payload) => {
    return { type: GET_MANUAL_COIN_BEGIN, payload };
};

export const getAllManualCoinSuccess = (payload) => {
    return { type: GET_MANUAL_COIN_SUCCESS, payload };
};

export const getAllManualCoinError = (payload) => {
    return { type: GET_MANUAL_COIN_ERROR, payload };
};

export const addManualCoin = (payload) => ({ type: ADD_MANUAL_COIN, payload });

export const addManualCoinBegin = () => ({ type: ADD_MANUAL_COIN_BEGIN });

export const addManualCoinSuccess = (payload) => {
    return { type: ADD_MANUAL_COIN_SUCCESS, payload };
};

export const addManualCoinError = (payload) => {
    return { type: ADD_MANUAL_COIN_ERROR, payload };
};

export function deleteCoin(payload) {
    return { type: DELETE_MANUAL_COIN, payload };
}

export const deleteCoinBegin = () => ({ type: DELETE_MANUAL_COIN_BEGIN });
export const deleteCoinSuccess = (payload) => ({
    type: DELETE_MANUAL_COIN_SUCCESS,
    payload,
});
export const deleteCoinError = (payload) => ({
    type: DELETE_MANUAL_COIN_ERROR,
    payload,
});

export const resetManualCoinsStates = () => ({ type: RESET_MANUAL_COIN_STATES });