import {
  ADD_FLASH_CATEGORIES,
  ADD_FLASH_CATEGORIES_BEGIN,
  ADD_FLASH_CATEGORIES_FAIL,
  ADD_FLASH_CATEGORIES_SUCCESS,
  BLOCK_OR_UNBLOCK_USER_FROM_FLASH,
  BLOCK_OR_UNBLOCK_USER_FROM_FLASH_BEGIN,
  BLOCK_OR_UNBLOCK_USER_FROM_FLASH_FAIL,
  BLOCK_OR_UNBLOCK_USER_FROM_FLASH_SUCCESS,
  DELETE_FLASHES,
  DELETE_FLASHES_BEGIN,
  DELETE_FLASHES_FAIL,
  DELETE_FLASHES_SUCCESS,
  DELETE_FLASH_CATEGORIES,
  DELETE_FLASH_CATEGORIES_BEGIN,
  DELETE_FLASH_CATEGORIES_FAIL,
  DELETE_FLASH_CATEGORIES_SUCCESS,
  DELETE_FLASH_REPORTED_COMMENT,
  DELETE_FLASH_REPORTED_COMMENT_BEGIN,
  DELETE_FLASH_REPORTED_COMMENT_FAIL,
  DELETE_FLASH_REPORTED_COMMENT_SUCCESS,
  EDIT_FLASH_CATEGORIES,
  EDIT_FLASH_CATEGORIES_BEGIN,
  EDIT_FLASH_CATEGORIES_FAIL,
  EDIT_FLASH_CATEGORIES_SUCCESS,
  GET_ALL_FLASH,
  GET_ALL_FLASH_BEGIN,
  GET_ALL_FLASH_FAIL,
  GET_ALL_FLASH_SUCCESS,
  GET_FLASHES,
  GET_FLASHES_BEGIN,
  GET_FLASHES_FAIL,
  GET_FLASHES_SUCCESS,
  GET_FLASH_BLOCKED_USERS,
  GET_FLASH_BLOCKED_USERS_BEGIN,
  GET_FLASH_BLOCKED_USERS_FAIL,
  GET_FLASH_BLOCKED_USERS_SUCCESS,
  GET_FLASH_CATEGORIES,
  GET_FLASH_CATEGORIES_BEGIN,
  GET_FLASH_CATEGORIES_BY_ID,
  GET_FLASH_CATEGORIES_BY_ID_BEGIN,
  GET_FLASH_CATEGORIES_BY_ID_FAIL,
  GET_FLASH_CATEGORIES_BY_ID_SUCCESS,
  GET_FLASH_CATEGORIES_FAIL,
  GET_FLASH_CATEGORIES_SUCCESS,
  GET_FLASH_COMMENT_REPORTS,
  GET_FLASH_COMMENT_REPORTS_BEGIN,
  GET_FLASH_COMMENT_REPORTS_FAIL,
  GET_FLASH_COMMENT_REPORTS_SUCCESS,
  GET_FLASH_REPORTED_COMMENT,
  GET_FLASH_REPORTED_COMMENT_BEGIN,
  GET_FLASH_REPORTED_COMMENT_FAIL,
  GET_FLASH_REPORTED_COMMENT_SUCCESS,
  GET_FLASH_REPORTS,
  GET_FLASH_REPORTS_BEGIN,
  GET_FLASH_REPORTS_FAIL,
  GET_FLASH_REPORTS_SUCCESS,
  GET_REPORTED_FLASH,
  GET_REPORTED_FLASH_BEGIN,
  GET_REPORTED_FLASH_FAIL,
  GET_REPORTED_FLASH_SUCCESS,
  RESET_FLASH_CATEGORY_ERRORS,
} from "./actionTypes";

export const getFlashCategories = (request) => ({
  type: GET_FLASH_CATEGORIES,
  request: request,
});

export const getFlashCategoriesBegin = () => ({
  type: GET_FLASH_CATEGORIES_BEGIN,
});

export const getFlashCategoriesSuccess = (categories) => ({
  type: GET_FLASH_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getFlashCategoriesFail = (error) => ({
  type: GET_FLASH_CATEGORIES_FAIL,
  payload: error,
});

export const addFlashCategories = ({ category, callback }) => ({
  type: ADD_FLASH_CATEGORIES,
  category: category,
  callback: callback,
});

export const addFlashCategoriesBegin = () => ({
  type: ADD_FLASH_CATEGORIES_BEGIN,
});

export const addFlashCategoriesSuccess = (category) => ({
  type: ADD_FLASH_CATEGORIES_SUCCESS,
  payload: category,
});

export const addFlashCategoriesFail = (error) => ({
  type: ADD_FLASH_CATEGORIES_FAIL,
  payload: error,
});

export const getFlashCategoriesById = (id) => ({
  type: GET_FLASH_CATEGORIES_BY_ID,
  id: id,
});

export const getFlashCategoriesByIdBegin = () => ({
  type: GET_FLASH_CATEGORIES_BY_ID_BEGIN,
});

export const getFlashCategoriesByIdSuccess = (category) => ({
  type: GET_FLASH_CATEGORIES_BY_ID_SUCCESS,
  payload: category,
});

export const getFlashCategoriesByIdFail = (error) => ({
  type: GET_FLASH_CATEGORIES_BY_ID_FAIL,
  payload: error,
});

export const editFlashCategories = ({
  category,
  id,
  packageData,
  // callback,
}) => ({
  type: EDIT_FLASH_CATEGORIES,
  params: category,
  id: id,
  categoryList: packageData,
  // callback: callback,
});
export const editFlashCategoriesBegin = () => ({
  type: EDIT_FLASH_CATEGORIES_BEGIN,
});

export const editFlashCategoriesSuccess = (category) => ({
  type: EDIT_FLASH_CATEGORIES_SUCCESS,
  payload: category,
});

export const editFlashCategoriesFail = (error) => ({
  type: EDIT_FLASH_CATEGORIES_FAIL,
  payload: error,
});

export const deleteFlashCategories = ({ id, callback }) => ({
  type: DELETE_FLASH_CATEGORIES,
  id: id,
  callback: callback,
});

export const deleteFlashCategoriesBegin = () => ({
  type: DELETE_FLASH_CATEGORIES_BEGIN,
});

export const deleteFlashCategoriesSuccess = (category) => ({
  type: DELETE_FLASH_CATEGORIES_SUCCESS,
  payload: category,
});

export const deleteFlashCategoriesFail = (error) => ({
  type: DELETE_FLASH_CATEGORIES_FAIL,
  payload: error,
});

export const resetFlashCategoryErrors = () => ({
  type: RESET_FLASH_CATEGORY_ERRORS,
});

//user specific flashes
export const getFlashes = ({ userId, request }) => ({
  type: GET_FLASHES,
  request: request,
  userId: userId,
});

export const getFlashesBegin = () => ({
  type: GET_FLASHES_BEGIN,
});

export const getFlashesSuccess = (flashes) => ({
  type: GET_FLASHES_SUCCESS,
  payload: flashes,
});

export const getFlashesFail = (error) => ({
  type: GET_FLASHES_FAIL,
  payload: error,
});

export const deleteFlashes = ({ id, callback }) => ({
  type: DELETE_FLASHES,
  id: id,
  callback: callback,
});

export const deleteFlashesBegin = () => ({
  type: DELETE_FLASHES_BEGIN,
});

export const deleteFlashesSuccess = (purpose) => ({
  type: DELETE_FLASHES_SUCCESS,
  payload: purpose,
});

export const deleteFlashesFail = (error) => ({
  type: DELETE_FLASHES_FAIL,
  payload: error,
});

// for whole flashes listing in the app

export const getAllFlash = (request) => ({
  type: GET_ALL_FLASH,
  request: request,
});

export const getAllFlashBegin = () => ({
  type: GET_ALL_FLASH_BEGIN,
});

export const getAllFlashSuccess = (categories) => ({
  type: GET_ALL_FLASH_SUCCESS,
  payload: categories,
});

export const getAllFlashFail = (error) => ({
  type: GET_ALL_FLASH_FAIL,
  payload: error,
});

export const getReportedFlash = (request) => ({
  type: GET_REPORTED_FLASH,
  request: request,
});

export const getReportedFlashBegin = () => ({
  type: GET_REPORTED_FLASH_BEGIN,
});

export const getReportedFlashSuccess = (flashes) => ({
  type: GET_REPORTED_FLASH_SUCCESS,
  payload: flashes,
});

export const getReportedFlashFail = (error) => ({
  type: GET_REPORTED_FLASH_FAIL,
  payload: error,
});
export const getFlashReports = (request, id) => ({
  type: GET_FLASH_REPORTS,
  request: request,
  id: id,
});

export const getFlashReportsBegin = () => ({
  type: GET_FLASH_REPORTS_BEGIN,
});

export const getFlashReportsSuccess = (flashes) => ({
  type: GET_FLASH_REPORTS_SUCCESS,
  payload: flashes,
});

export const getFlashReportsFail = (error) => ({
  type: GET_FLASH_REPORTS_FAIL,
  payload: error,
});

export const getFlashReportedComment = (request) => ({
  type: GET_FLASH_REPORTED_COMMENT,
  request: request,
});

export const getFlashReportedCommentBegin = () => ({
  type: GET_FLASH_REPORTED_COMMENT_BEGIN,
});

export const getFlashReportedCommentSuccess = (comments) => ({
  type: GET_FLASH_REPORTED_COMMENT_SUCCESS,
  payload: comments,
});
export const getFlashReportedCommentFail = (error) => ({
  type: GET_FLASH_REPORTED_COMMENT_FAIL,
  payload: error,
});
export const deleteFlashReportedComment = ({ id, callback }) => ({
  type: DELETE_FLASH_REPORTED_COMMENT,
  id: id,
  callback: callback,
});

export const deleteFlashReportedCommentBegin = () => ({
  type: DELETE_FLASH_REPORTED_COMMENT_BEGIN,
});

export const deleteFlashReportedCommentSuccess = (comments) => ({
  type: DELETE_FLASH_REPORTED_COMMENT_SUCCESS,
  payload: comments,
});

export const deleteFlashReportedCommentFail = (error) => ({
  type: DELETE_FLASH_REPORTED_COMMENT_FAIL,
  payload: error,
});
export const getFlashCommentReports = (request, id) => ({
  type: GET_FLASH_COMMENT_REPORTS,
  request: request,
  id: id,
});

export const getFlashCommentReportsBegin = () => ({
  type: GET_FLASH_COMMENT_REPORTS_BEGIN,
});

export const getFlashCommentReportsSuccess = (reports) => ({
  type: GET_FLASH_COMMENT_REPORTS_SUCCESS,
  payload: reports,
});

export const getFlashCommentReportsFail = (error) => ({
  type: GET_FLASH_COMMENT_REPORTS_FAIL,
  payload: error,
});
export const getFlashBlockedUsers = (request) => ({
  type: GET_FLASH_BLOCKED_USERS,
  request: request,
});

export const getFlashBlockedUsersBegin = () => ({
  type: GET_FLASH_BLOCKED_USERS_BEGIN,
});

export const getFlashBlockedUsersSuccess = (categories) => ({
  type: GET_FLASH_BLOCKED_USERS_SUCCESS,
  payload: categories,
});

export const getFlashBlockedUsersFail = (error) => ({
  type: GET_FLASH_BLOCKED_USERS_FAIL,
  payload: error,
});

export const blockOrUnblockUserFromFlash = (request) => ({
  type: BLOCK_OR_UNBLOCK_USER_FROM_FLASH,
  request: request,
});

export const blockOrUnblockUserFromFlashBegin = () => ({
  type: BLOCK_OR_UNBLOCK_USER_FROM_FLASH_BEGIN,
});

export const blockOrUnblockUserFromFlashSuccess = (categories) => ({
  type: BLOCK_OR_UNBLOCK_USER_FROM_FLASH_SUCCESS,
  payload: categories,
});

export const blockOrUnblockUserFromFlashFail = (error) => ({
  type: BLOCK_OR_UNBLOCK_USER_FROM_FLASH_FAIL,
  payload: error,
});
