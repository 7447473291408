
export const GET_ADMINS = "GET_ADMINS";
export const GET_ADMINS_BEGIN = "GET_ADMINS_BEGIN";
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS";
export const GET_ADMINS_FAIL = "GET_ADMINS_FAIL";

export const GET_ADMIN_ROLES = 'GET_ADMIN_ROLES';
export const GET_ADMIN_ROLES_BEGIN = 'GET_ADMIN_ROLES_BEGIN';
export const GET_ADMIN_ROLES_SUCCESS = 'GET_ADMIN_ROLES_SUCCESS';
export const GET_ADMIN_ROLES_FAIL = 'GET_ADMIN_ROLES_FAIL';

export const ADD_ADMIN = "ADD_ADMIN";
export const ADD_ADMIN_BEGIN = "ADD_ADMIN_BEGIN";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_FAIL = "ADD_ADMIN_FAIL";

export const GET_ADMIN_DETAILS = "GET_ADMIN_DETAILS";
export const GET_ADMIN_DETAILS_BEGIN = "GET_ADMIN_DETAILS_BEGIN";
export const GET_ADMIN_DETAILS_SUCCESS = "GET_ADMIN_DETAILS_SUCCESS";
export const GET_ADMIN_DETAILS_FAIL = "GET_ADMIN_DETAILS_FAIL";

export const GET_SAMPLE_PASSWORD = "GET_SAMPLE_PASSWORD";
export const GET_SAMPLE_PASSWORD_BEGIN = "GET_SAMPLE_PASSWORD_BEGIN";
export const GET_SAMPLE_PASSWORD_SUCCESS = "GET_SAMPLE_PASSWORD_SUCCESS";
export const GET_SAMPLE_PASSWORD_FAIL = "GET_SAMPLE_PASSWORD_FAIL";

export const UPDATE_ADMIN_DETAILS = "UPDATE_ADMIN_DETAILS";
export const UPDATE_ADMIN_DETAILS_BEGIN = "UPDATE_ADMIN_DETAILS_BEGIN";
export const UPDATE_ADMIN_DETAILS_SUCCESS = "UPDATE_ADMIN_DETAILS_SUCCESS";
export const UPDATE_ADMIN_DETAILS_FAIL = "UPDATE_ADMIN_DETAILS_FAIL";

export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_ADMIN_BEGIN = "DELETE_ADMIN_BEGIN";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAIL = "DELETE_ADMIN_FAIL";

export const BLOCK_ADMIN = "BLOCK_ADMIN";
export const BLOCK_ADMIN_BEGIN = "BLOCK_ADMIN_BEGIN";
export const BLOCK_ADMIN_SUCCESS = "BLOCK_ADMIN_SUCCESS";
export const BLOCK_ADMIN_FAIL = "BLOCK_ADMIN_FAIL";

export const RESET_MESSAGES = "RESET_MESSAGES";
export const CLEAR_PASSWORD = "CLEAR_PASSWORD";

export const STORE_ADMINS_REQUEST = "STORE_ADMINS_REQUEST";