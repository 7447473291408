import {
  GET_TEMPLATE_LISTING,
  GET_TEMPLATE_LISTING_BEGIN,
  GET_TEMPLATE_LISTING_SUCCESS,
  GET_TEMPLATE_LISTING_FAIL,
  EDIT_TEMPLATE,
  EDIT_TEMPLATE_BEGIN,
  EDIT_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE_FAIL,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_BEGIN,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAIL,
  ADD_TEMPLATE,
  ADD_TEMPLATE_BEGIN,
  ADD_TEMPLATE_SUCCESS,
  ADD_TEMPLATE_FAIL,
} from "./actionTypes";

export const getTemplateListing = (requestParams) => ({
  type: GET_TEMPLATE_LISTING,
  payload: requestParams,
});

export const getTemplateListingBegin = () => ({
  type: GET_TEMPLATE_LISTING_BEGIN,
});

export const getTemplateListingSuccess = (response) => ({
  type: GET_TEMPLATE_LISTING_SUCCESS,
  payload: response,
});

export const getTemplateListingFail = (error) => ({
  type: GET_TEMPLATE_LISTING_FAIL,
  payload: error,
});

/* ----------------------------------------------------- */

export const editTemplate = (id) => ({
  type: EDIT_TEMPLATE,
  payload: id,
});

export const editTemplateBegin = () => ({
  type: EDIT_TEMPLATE_BEGIN,
});

export const editTemplateSuccess = (template) => ({
  type: EDIT_TEMPLATE_SUCCESS,
  payload: template,
});

export const editTemplateFail = (error) => ({
  type: EDIT_TEMPLATE_FAIL,
  payload: error,
});

/* ----------------------------------------------------- */
export const updateTemplate = ({ id, data, callback }) => ({
  type: UPDATE_TEMPLATE,
  id: id,
  payload: data,
  callback: callback,
});

export const updateTemplateBegin = () => ({
  type: UPDATE_TEMPLATE_BEGIN,
});

export const updateTemplateSuccess = (success) => ({
  type: UPDATE_TEMPLATE_SUCCESS,
  payload: success,
});

export const updateTemplateFail = (error) => ({
  type: UPDATE_TEMPLATE_FAIL,
  payload: error,
});
export const addTemplate = ({ data, callback }) => ({
  type: ADD_TEMPLATE,
  payload: data,
  callback: callback,
});

export const addTemplateBegin = () => ({
  type: ADD_TEMPLATE_BEGIN,
});

export const addTemplateSuccess = (success) => ({
  type: ADD_TEMPLATE_SUCCESS,
  payload: success,
});

export const addTemplateFail = (error) => ({
  type: ADD_TEMPLATE_FAIL,
  payload: error,
});
