import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, Col, Container, Row, UncontrolledAlert } from "reactstrap";
import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import {
  addFlashCategories,
  getFlashCategories,
} from "../../../store/flashManagement/actions";
import { getPodiumsCategories } from "../../../store/podiums/actions";
import Loader from "../../../components/Common/Loader";
import { LANGUAGES } from "../../../helpers/constants";
import {
  addGiftCategories,
  getGiftCategories,
} from "../../../store/GiftCategories/actions";
import { success } from "toastr";

const AddGiftCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [category, setCategory] = useState("");

  const [categoryObj, setCategoryObj] = useState({});

  const data = useSelector((state) => state.GiftCategories);

  const scrollToAlertMessage = () => {
    if (data?.error || data?.success) {
      let element = warningAlertRef.current;
      let top = element.offsetTop;
      let height = element.getBoundingClientRect().height;
      pageWrapRef.current.scrollTo(0, top - height - 30);
    }
  };
  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error, data?.success]);

  useEffect(() => {
    dispatch(getGiftCategories());
  }, []);

  const handleInputChange = (key, value) => {
    setCategoryObj((prev) => ({
      ...prev,
      [key]: value && value?.trim(),
    }));
  };

  useEffect(() => {
    const allKeysHaveValues = Object.values(categoryObj).every(
      (value) => value !== ""
    );
    const hasNineValues = Object.keys(categoryObj).length === 11;
    const categoryNameIsNotEmpty = category.trim() !== "";

    setDisabled(
      !(allKeysHaveValues && hasNineValues && categoryNameIsNotEmpty)
    );
  }, [categoryObj, category]);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      addGiftCategories({
        category: categoryObj,
        callback: () => history.push("/gift-categories"),
      })
    );
  };

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        ref={pageWrapRef}
      >
        <BackButton
          label="Gift Categories"
          handleClick={() => history.push("/gift-categories")}
        />
        <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Add Gift Category" />
            {(data?.error || data?.sucess) && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color={`${data?.error ? "danger" : "success"}`}
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error || data?.success}
                </UncontrolledAlert>
              </div>
            )}
            <Col xl="8">
              <Card className="mb-0 p-4 my-4">
                <div className="text-muted">
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <div
                      className="table-responsive mt-4"
                      style={{ overflowX: "hidden" }}
                    >
                      <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Category (In English)
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="text"
                              className="form-control mb-4 "
                              // value={purpose?.purpose}
                              id="name-input"
                              onChange={(e) => {
                                setCategory(e.target.value);
                              }}
                              placeholder="Enter Category"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Select Category Identifier
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <select
                              className="form-control mb-4"
                              id="huddleType"
                              // ref={ref}
                              onChange={(e) => {
                                handleInputChange(
                                  "category_identifier",
                                  e?.target?.value
                                );
                                // setCategoryIdentifier(e.target.value);
                              }}
                            >
                              <option value="">
                                Choose Identifier for Category
                              </option>
                              <option value="common">Common</option>
                              <option value="date">Date</option>
                              {/* <option value="birthday">BIRTHDAY</option> */}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Listing Order
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="number"
                              className="form-control mb-4 "
                              // value={purpose?.purpose}
                              id="name-input"
                              onChange={(e) => {
                                handleInputChange(
                                  "listing_order",
                                  e?.target?.value
                                );
                              }}
                              placeholder="Enter listing order"
                            />
                          </div>
                        </div>
                      </div>

                      {LANGUAGES?.map((language, i) => (
                        <div key={i} className="col-md-12">
                          <div className="d-flex mx-1">
                            <label className="col-md-2 lang-font col-form-label">
                              {`${language
                                ?.slice(0, 1)
                                .toUpperCase()}${language?.slice(1)}`}
                            </label>
                            <div className="col-md-6">
                              <div className="col-md-8 dobWrapper">
                                <AvInput
                                  name="adminName"
                                  type="text"
                                  className="form-control mb-4"
                                  id={`name-input-${i}`}
                                  onChange={(e) => {
                                    handleInputChange(language, e.target.value);
                                  }}
                                  placeholder="Enter Category Name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="mt-4">
                        <button
                          type="submit"
                          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                          // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                          disabled={disabled}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* {data?.loading && <Loader darkBg={true} />} */}
      </div>
    </>
  );
};

export default AddGiftCategory;
