import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  get,
  post,
  uploadFile,
  del,
  updateFormData,
} from "../../helpers/api_helper";
import {
  API_GET_GIFT_PACKAGES,
  API_GET_GIFT_PACKAGES_BY_ID,
  API_UPDATE_GIFT_PACKAGES,
} from "../../helpers/url_helper";
import {
  addGiftPackagesBegin,
  addGiftPackagesFail,
  addGiftPackagesSuccess,
  deleteGiftPackagesBegin,
  deleteGiftPackagesFail,
  deleteGiftPackagesSuccess,
  getGiftPackagesBegin,
  getGiftPackagesByIdBegin,
  getGiftPackagesByIdFail,
  getGiftPackagesByIdSuccess,
  getGiftPackagesFail,
  getGiftPackagesSuccess,
  updateGiftPackagesBegin,
  updateGiftPackagesFail,
  updateGiftPackagesSuccess,
} from "./actions";
import {
  ADD_GIFT_PACKAGES,
  DELETE_GIFT_PACKAGES,
  GET_GIFT_PACKAGES,
  GET_GIFT_PACKAGES_BY_ID,
  GET_GIFT_PACKAGES_BY_ID_SUCCESS,
  UPDATE_GIFT_PACKAGES,
} from "./actionTypes";

function* getGiftPackages({ request }) {
  try {
    yield put(getGiftPackagesBegin());

    const response = yield call(get, API_GET_GIFT_PACKAGES, request);

    yield put(getGiftPackagesSuccess(response.result));
    console.log("response.result", response.result);
  } catch (error) {
    yield put(getGiftPackagesFail(error));
  }
}
function* addGiftPackagesSaga({ packageData, callback }) {
  try {
    yield put(addGiftPackagesBegin());
    const response = yield call(post, API_GET_GIFT_PACKAGES, packageData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    yield put(addGiftPackagesSuccess(response.result));
    callback && callback();
  } catch (error) {
    yield put(addGiftPackagesFail(error));
  }
}
function* getGiftPackagesByIdSaga({ id }) {
  try {
    yield put(getGiftPackagesByIdBegin());
    const response = yield call(
      get,
      API_GET_GIFT_PACKAGES_BY_ID.replace("{id}", id)
    );

    yield put(getGiftPackagesByIdSuccess(response.result));
  } catch (error) {
    yield put(getGiftPackagesByIdFail(error));
  }
}
function* deleteGiftPackages({ id, callback }) {
  try {
    yield put(deleteGiftPackagesBegin());
    const response = yield call(
      del,
      API_GET_GIFT_PACKAGES_BY_ID.replace("{id}", id)
    );

    yield put(deleteGiftPackagesSuccess(response.result));
  } catch (error) {
    yield put(deleteGiftPackagesFail(error));
  } finally {
    if (callback) {
      callback();
    }
  }
}
function* updateGiftPackagesSaga({
  id,
  giftIdentifier,
  packageData,
  callback,
}) {
  try {
    yield put(updateGiftPackagesBegin());

    const response = yield call(
      updateFormData,
      `/admin/gift/managepoints/${id}?gift_identifier=${giftIdentifier}`,
      packageData
    );

    yield put(updateGiftPackagesSuccess(response.message));
    callback && callback();
  } catch (error) {
    yield put(updateGiftPackagesFail(error));
  }
}

function* giftPackagessaga() {
  yield takeLatest(GET_GIFT_PACKAGES, getGiftPackages);
  yield takeLatest(ADD_GIFT_PACKAGES, addGiftPackagesSaga);
  yield takeLatest(DELETE_GIFT_PACKAGES, deleteGiftPackages);
  yield takeLatest(UPDATE_GIFT_PACKAGES, updateGiftPackagesSaga);
  yield takeLatest(GET_GIFT_PACKAGES_BY_ID, getGiftPackagesByIdSaga);
}
export default giftPackagessaga;
