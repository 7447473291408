export const GET_GIFT_TRANSFER_HISTORY = "GET_GIFT_TRANSFER_HISTORY";
export const GET_GIFT_TRANSFER_HISTORY_BEGIN =
  "GET_GIFT_TRANSFER_HISTORY_BEGIN";
export const GET_GIFT_TRANSFER_HISTORY_SUCCESS =
  "GET_GIFT_TRANSFER_HISTORY_SUCCESS";
export const GET_GIFT_TRANSFER_HISTORY_FAIL = "GET_GIFT_TRANSFER_HISTORY_FAIL";

export const GET_GIFT_TRANSFER_HISTORY_BY_USER_ID =
  "GET_GIFT_TRANSFER_HISTORY_BY_USER_ID";
export const GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_BEGIN =
  "GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_BEGIN";

export const GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_SUCCESS =
  "GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_SUCCESS";
export const GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_FAIL =
  "GET_GIFT_TRANSFER_HISTORY_BY_USER_ID_FAIL";

export const GET_GRS_SUMMARY_BY_USER_ID = "GET_GRS_SUMMARY_BY_USER_ID";
export const GET_GRS_SUMMARY_BY_USER_ID_BEGIN =
  "GET_GRS_SUMMARY_BY_USER_ID_BEGIN";

export const GET_GRS_SUMMARY_BY_USER_ID_SUCCESS =
  "GET_GRS_SUMMARY_BY_USER_ID_SUCCESS";
export const GET_GRS_SUMMARY_BY_USER_ID_FAIL =
  "GET_GRS_SUMMARY_BY_USER_ID_FAIL";

export const EXPORT_GRS_SUMMARY_BY_USER_ID = "EXPORT_GRS_SUMMARY_BY_USER_ID";
export const EXPORT_GRS_SUMMARY_BY_USER_ID_BEGIN =
  "EXPORT_GRS_SUMMARY_BY_USER_ID_BEGIN";
export const EXPORT_GRS_SUMMARY_BY_USER_ID_SUCCESS =
  "EXPORT_GRS_SUMMARY_BY_USER_ID_SUCCESS";
export const EXPORT_GRS_SUMMARY_BY_USER_ID_FAIL =
  "EXPORT_GRS_SUMMARY_BY_USER_ID_FAIL";
