import React, { useState, useEffect, lazy, useCallback, useMemo } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Card,
  Row,
  Col,
  Button,
} from "reactstrap";
import Registration_LoginHistory from "./RegistrationLoginHistory";
import MLM_Tree from "./UserMatrix";
import UserDetail from "./UserDetail";
import UserSubscription from "./UserSubscription";
import {
  getUserDetails,
  blockUser,
  deleteUser,
} from "../../../store/users/actions";

import Breadcrumbs from "../../../components/UI/Breadcrumb";

import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import BackButton from "../../../components/UI/BackButton";
import Loader from "../../../components/Common/Loader";
import Avatar from "../../../assets/images/users/user.svg";
import PremiumImage from "../../../assets/icons/premium.svg";
import verifedImage from "../../../assets/icons/verified.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import Eye_icon from "../../../assets/icons/ebud-icons/Eye.svg";
import personalInfoIcon from "../../../assets/icons/dashboard-icons/personalInfo..svg";
import statementIcon from "../../../assets/icons/dashboard-icons/statements.svg";
import huddlesIcon from "../../../assets/icons/dashboard-icons/HuddlesNGroups.svg";
import accountsIcon from "../../../assets/icons/dashboard-icons/accounts.svg";
import empowerIcon from "../../../assets/icons/dashboard-icons/empowerment.svg";
import rewardIcon from "../../../assets/icons/dashboard-icons/reward_flax.svg";
import flashIcon from "../../../assets/icons/dashboard-icons/flash-icon.svg";

import classnames from "classnames";
import {
  checkUserPermission,
  checkUserViewPermission,
  convertToUAETime,
} from "../../../helpers/utils";
import ManualPP from "./ManualPP/ManualPP";
import ManualCoin from "./ManualCoin/ManualCoin";
import StatisticsTab from "./Statistics";
import {
  deleteReportedData,
  getReportedUserById,
} from "../../../store/reportedUser/action";
import { DATE_WITH_TIME } from "../../../helpers/constants";
import ReportSummary from "../../ReportedData/ReportSummary";
import Empowerment from "./Empowerment";
import Flashes from "./Flashes";
// import ManualCoin from "./ManualCoin/ManualCoin";

const TabSection = () => {
  const basicRequest = {
    sort: "count",
    sort_order: "desc",
    page: 1,
    page_count: 50,
    keyword: "",
    status_filter: [],
    report_categories: [],
  };
  const history = useHistory();
  const match = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.Login);
  const [userPermissions, setUserPermissions] = useState({});
  const [rolePermisions, setRolePermisions] = useState({});
  const [updated, setUpdated] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [modal, setModal] = useState(false);
  const [request, setRequest] = useState({ ...basicRequest });
  const NOTIFICATION_MODEL = {
    title: "",
    message: "",
    push: false,
  };

  const [showNotificationForm, setShowNotificationForm] = useState(null);

  const [notification, setNotification] = useState(NOTIFICATION_MODEL);
  const [userId, setUserId] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [active, setActive] = useState(null);
  const [notificationKey, setNotificationKey] = useState(null);
  const [activeTabNum, setActiveTabNum] = useState("1");
  const [flixOrCoinsTab, setFlixOrCoinsTab] = useState(true);
  const isMLMTreeEnabled = checkUserViewPermission(
    rolePermisions,
    "User Management",
    "MLM Tree"
  );
  const isSubscriptionEnabled = checkUserViewPermission(
    rolePermisions,
    "User Management",
    "Subscription"
  );
  const isRegistrationEnabled = checkUserViewPermission(
    rolePermisions,
    "User Management",
    "Registration & login history"
  );

  const isManualPPEnabled = checkUserViewPermission(
    rolePermisions,
    "Manual PP",
    "Manual PP"
  );
  const isHuddleEnabled = checkUserViewPermission(
    rolePermisions,
    "Huddle Management",
    "Huddles"
  );

  const isFlashEnabled = checkUserViewPermission(
    rolePermisions,
    "Flash Management",
    "Flash"
  );
  const isEmpowermentEnabled = checkUserViewPermission(
    rolePermisions,
    "User Management",
    "User Empowerments"
  );

  const data = useSelector((state) => state.user);

  const reportedUserData = useSelector((state) => state.reportedUser);

  useEffect(() => {
    dispatch(getUserDetails(match.id));
  }, [match.id]);

  useEffect(() => {
    {
      match?.id && dispatch(getReportedUserById({ id: match?.id, request }));
    }
  }, [match?.id, request]);

  useEffect(() => {
    if (history?.location?.state?.userUpdated) {
      setUpdated(true);
    }
  }, [history?.location?.state?.userUpdated]);

  const [activeTab, setActiveTab] = useState("1");
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const activeTab = query.get("tab");

    if (
      !isNaN(activeTab) &&
      parseInt(activeTab) > 0 &&
      (data?.userDetails?.is_premium
        ? parseInt(activeTab) <= 7
        : parseInt(activeTab) <= 6)
    ) {
      setActiveTab(activeTab);
    }
  }, [location, data]);

  const toggle = () => {
    setModal(!modal);
    setShowNotificationForm(null);
    setActive(null);
  };

  const formatUserData = useCallback(() => {
    return reportedUserData?.reportedUserData?.reports?.map(
      (report, index) => ({
        ...report,
        no: index + 1,
        profile_img: (
          <div className="profileWrap">
            {report?.verified ? (
              <div className="premium_icon reportedUser">
                <img src={verifedImage} />
              </div>
            ) : report.membership === "Premium" ? (
              <div className="premium_icon reportedUser">
                <img src={PremiumImage} />
              </div>
            ) : null}

            {/* {report.membership === "Premium" ? (
            <div className="premium_icon reportedUser">
              <img src={PremiumImage} />
            </div>
          ) : null} */}
            <div>
              <img
                className="table_profileImg"
                src={
                  report.profile_photo === null ? Avatar : report.profile_photo
                }
              />
            </div>
          </div>
        ),
        reported_name: (
          <div
            className="cursor-pointer"
            onClick={() => {
              history.push(`/user-details/${report?.reported_id}`);
            }}
          >
            <div>{report.reported_name}</div>
            <div className="text-muted">{`(${report.reported_username})`}</div>
          </div>
        ),
        reported_by: (
          <div
            className="cursor-pointer"
            onClick={() => {
              history.push(`/user-details/${report?.reported_by_id}`);
            }}
          >
            <div>
              {report.reported_by ? report.reported_by : "Deleted User"}
            </div>
            <div className="text-muted">
              {report.reported_by_username
                ? `(${report.reported_by_username})`
                : ""}
            </div>
          </div>
        ),

        status: (
          <div
            className={
              report.status === "active"
                ? "reportStatus bg-soft-red"
                : "reportStatus bg-soft-green"
            }
          >
            {report.status}
          </div>
        ),
        category: (
          <div title={report.category} className="rdtCategory">
            {report.category}
          </div>
        ),
        reported_date: (
          <div>
            {report?.reported_date &&
              convertToUAETime(report?.reported_date, DATE_WITH_TIME)}
          </div>
        ),
        actions: (
          <div className="cust-table-actions-wrap">
            <button
              onClick={() => deleteReportPromptHandler(report.id)}
              className=" color-red action-btn"
              title="Delete"
              // disabled={!isDeleteable}
            >
              <img
                src={Delete_icon}
                alt="Delete"
                // style={{ opacity: !isDeleteable ? "50%" : "" }}
              />
            </button>
          </div>
        ),
        view: (
          <div className="cust-table-actions-wrap ">
            <button
              onClick={() => {
                toggle();
                setSelectedIndex(index);
              }}
              className=" color-red action-btn"
              title="Delete"
              // disabled={!isDeleteable}
            >
              <img
                src={Eye_icon}
                alt="Delete"
                // style={{ opacity: !isDeleteable ? "50%" : "" }}
              />
            </button>
          </div>
        ),
      })
    );
  }, [reportedUserData?.reportedUserData?.reports, request]);
  const rptData = useMemo(
    () =>
      reportedUserData?.reportedUserData?.reports?.length > 0 &&
      formatUserData(),
    [formatUserData]
  );

  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setRolePermisions(loginData?.rolePermissions?.role);
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Users"
        )
      );
    }
  }, [loginData]);

  const profiletoggle = (tab) => {
    setActiveTabNum(tab);
    if (activeTab !== tab) {
      history.push({
        pathname: history.url,
        search: `?tab=${tab}`,
      });
    }
  };

  const editUserDetailsHandler = () => {
    history.push({
      pathname: `/edit-user-details/${match.id}`,
      state: { from: "userDetails" },
    });
  };

  const deletePromptHandler = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      title: "",
      content: "Are you sure you want to delete this User?",
      type: "delete",
    });
  };
  const deleteReportPromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      title: "",
      content: "Are you sure you want to delete this Report?",
      type: "deleteReport",
    });
  };

  const blockPromptHandler = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      title: "",
      content: `Are you sure you want to ${
        data?.userDetails?.blocked ? "unblock" : "block"
      } this User?`,
      type: data?.userDetails?.blocked ? "unblock" : "block",
    });
  };

  const okHandler = () => {
    promptMessage?.type === "deleteReport" &&
      dispatch(
        deleteReportedData({
          data: promptMessage?.id,
          callback: () => {
            dispatch(getReportedUserById({ id: match?.id }));
          },
        })
      );
    promptMessage.type === "delete" &&
      dispatch(
        deleteUser({ data: match.id, callback: () => history.push("/users") })
      );
    promptMessage.type === "block" &&
      dispatch(
        blockUser({
          data: { blocked: true },
          id: match.id,
        })
      );
    promptMessage.type === "unblock" &&
      dispatch(
        blockUser({
          data: { blocked: false },
          id: match.id,
        })
      );
  };

  return (
    <div
      className="page-content userDetailWrap"
      data-tesid="component-userDetail"
    >
      {reportedUserData?.reportedUserData?.reports?.length > 0 &&
        selectedIndex != null && (
          <ReportSummary
            data={data}
            specificUser={
              reportedUserData?.reportedUserData?.reports?.[selectedIndex]
            }
            toggle={toggle}
            modal={modal}
            setModal={setModal}
            active={active}
            setActive={setActive}
            notificationKey={notificationKey}
            setNotificationKey={setNotificationKey}
            userId={userId}
            setUserId={setUserId}
            setNotification={setNotification}
            notification={notification}
            NOTIFICATION_MODEL={NOTIFICATION_MODEL}
            disabled={disabled}
            warningForm
            setShowNotificationForm={setShowNotificationForm}
            blockPromptHandler={blockPromptHandler}
            showNotificationForm={showNotificationForm}
          />
        )}
      <BackButton
        label="Users"
        handleClick={() =>
          history.push({
            pathname: "/users",
            state: { from: "keepFilter", userUpdated: updated },
          })
        }
        data-tesid="component-userListBack"
      />
      <Breadcrumbs title="Tables" breadcrumbItem="User Details" />
      <Col xl="12">
        <Card className="mb-0">
          <Nav tabs className="nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  profiletoggle("1");
                }}
              >
                {/* <i className="uil uil-user-circle font-size-20"></i> */}
                <img src={personalInfoIcon}></img>
                <span className="d-none d-sm-block">Personal Info</span>
              </NavLink>
            </NavItem>
            {isMLMTreeEnabled && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    profiletoggle("2");
                  }}
                >
                  {/* <i className="fa fa-sitemap" aria-hidden="true"></i> */}
                  <img src={statementIcon}></img>
                  <span className="d-none d-sm-block">Dears & Statements</span>
                </NavLink>
              </NavItem>
            )}
            {isRegistrationEnabled && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    profiletoggle("3");
                  }}
                >
                  {/* <i className="fa fa-history " aria-hidden="true"></i> */}
                  <img src={accountsIcon}></img>
                  <span className="d-none d-sm-block">Accounts and Logins</span>
                </NavLink>
              </NavItem>
            )}

            {isManualPPEnabled && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => {
                    profiletoggle("4");
                  }}
                >
                  {/* <i className="fa fa-coins " aria-hidden="true"></i> */}
                  <img src={rewardIcon}></img>
                  <span className="d-none d-sm-block">Reward FLiX & COiNS</span>
                </NavLink>
              </NavItem>
            )}

            {isHuddleEnabled && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "5" })}
                  onClick={() => {
                    profiletoggle("5");
                  }}
                >
                  {/* <i className="fa fa-signal"></i> */}
                  <img src={huddlesIcon}></img>
                  <span className="d-none d-sm-block">Huddles & Groups</span>
                </NavLink>
              </NavItem>
            )}
            {isFlashEnabled && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "6" })}
                  onClick={() => {
                    profiletoggle("6");
                  }}
                >
                  {/* <i className="fa fa-signal"></i> */}
                  <img width={18} src={flashIcon}></img>
                  <span className="d-none mt-2 d-sm-block">Flash</span>
                </NavLink>
              </NavItem>
            )}
            {isEmpowermentEnabled &&
              data?.userDetails &&
              (data?.userDetails?.is_premium ||
                data?.userDetails?.is_leader) && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "7" })}
                    onClick={() => {
                      profiletoggle("7");
                    }}
                  >
                    {/* <i className="fa fa-signal"></i> */}
                    <img src={empowerIcon}></img>
                    <span className="d-none d-sm-block">Empowerment</span>
                  </NavLink>
                </NavItem>
              )}
          </Nav>
          <TabContent activeTab={activeTab} className="p-4">
            <TabPane tabId="1">
              <UserDetail
                request={request}
                setRequest={setRequest}
                data={data}
                rptData={rptData}
                reportedUserData={reportedUserData}
                selectedIndex={selectedIndex}
                deletePromptHandler={deletePromptHandler}
                blockPromptHandler={blockPromptHandler}
                editUserDetailsHandler={editUserDetailsHandler}
                userPermissions={userPermissions}
                userId={match?.id}
              />
            </TabPane>
            {isMLMTreeEnabled && (
              <TabPane tabId="2">
                <MLM_Tree data={data} activeTab={activeTab} />
              </TabPane>
            )}
            {isRegistrationEnabled && (
              <TabPane tabId="3">
                <div className="row">
                  <div
                    style={{
                      borderRight: "solid 1px #eeeeee",
                    }}
                    className="col-md-6"
                  >
                    <UserSubscription
                      userError={data?.error}
                      activeTab={activeTab}
                    />
                  </div>
                  <div className="col-md-6">
                    <Registration_LoginHistory activeTab={activeTab} />
                  </div>
                </div>
              </TabPane>
            )}

            {isManualPPEnabled && (
              <TabPane tabId="4">
                <Row
                  className="justify-content-center mb-3"
                  style={{ border: "1px solid #B227C1", width: "25%" }}
                >
                  <Col
                    className={`d-flex align-items-center justify-content-center`}
                    style={{
                      height: "30px",
                      backgroundColor: flixOrCoinsTab ? "#B227C1" : "white",
                      color: flixOrCoinsTab ? "white" : "black",
                      border: flixOrCoinsTab
                        ? "1px solid #B227C1"
                        : "1px solid lightgray",
                      cursor: "pointer",
                      borderRadius: "0",
                    }}
                    onClick={() => setFlixOrCoinsTab(true)}
                    tag={Button}
                  >
                    FLiX
                  </Col>

                  <Col
                    className={`d-flex align-items-center justify-content-center`}
                    style={{
                      height: "30px",
                      backgroundColor: !flixOrCoinsTab ? "#B227C1" : "white",
                      color: !flixOrCoinsTab ? "white" : "black",
                      border: !flixOrCoinsTab
                        ? "1px solid #B227C1"
                        : "1px solid lightgray",
                      cursor: "pointer",
                      borderRadius: "0",
                    }}
                    onClick={() => setFlixOrCoinsTab(false)}
                    tag={Button}
                  >
                    COiNS
                  </Col>
                </Row>

                {flixOrCoinsTab ? (
                  <ManualPP tabNum={activeTabNum} />
                ) : (
                  <ManualCoin tabNum={activeTabNum} />
                )}
              </TabPane>
            )}

            {isHuddleEnabled && (
              <TabPane tabId="5">
                <StatisticsTab />
              </TabPane>
            )}
            {isFlashEnabled && (
              <TabPane tabId="6">
                <Flashes activeTab={activeTab} userId={match?.id} />
              </TabPane>
            )}
            {isEmpowermentEnabled &&
              data?.userDetails &&
              (data?.userDetails?.is_premium ||
                data?.userDetails?.is_leader) && (
                <TabPane tabId="7">
                  <Empowerment userId={match?.id} activeTab={activeTab} />
                </TabPane>
              )}
          </TabContent>
        </Card>
      </Col>
      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
      {reportedUserData?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default TabSection;
