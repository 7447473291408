export const GET_GIFT_CATEGORIES = "GET_GIFT_CATEGORIES";
export const GET_GIFT_CATEGORIES_BEGIN = "GET_GIFT_CATEGORIES_BEGIN";
export const GET_GIFT_CATEGORIES_SUCCESS = "GET_GIFT_CATEGORIES_SUCCESS";
export const GET_GIFT_CATEGORIES_FAIL = "GET_GIFT_CATEGORIES_FAIL";

export const GET_GIFT_CATEGORIES_BY_ID = "GET_GIFT_CATEGORIES_BY_ID";
export const GET_GIFT_CATEGORIES_BY_ID_BEGIN =
  "GET_GIFT_CATEGORIES_BY_ID_BEGIN";
export const GET_GIFT_CATEGORIES_BY_ID_SUCCESS =
  "GET_GIFT_CATEGORIES_BY_ID_SUCCESS";
export const GET_GIFT_CATEGORIES_BY_ID_FAIL = "GET_GIFT_CATEGORIES_BY_ID_FAIL";

export const ADD_GIFT_CATEGORIES = "ADD_GIFT_CATEGORIES";
export const ADD_GIFT_CATEGORIES_BEGIN = "ADD_GIFT_CATEGORIES_BEGIN";
export const ADD_GIFT_CATEGORIES_SUCCESS = "ADD_GIFT_CATEGORIES_SUCCESS";
export const ADD_GIFT_CATEGORIES_FAIL = "ADD_GIFT_CATEGORIES_FAIL";

export const EDIT_GIFT_CATEGORIES = "EDIT_GIFT_CATEGORIES";
export const EDIT_GIFT_CATEGORIES_BEGIN = "EDIT_GIFT_CATEGORIES_BEGIN";
export const EDIT_GIFT_CATEGORIES_SUCCESS = "EDIT_GIFT_CATEGORIES_SUCCESS";
export const EDIT_GIFT_CATEGORIES_FAIL = "EDIT_GIFT_CATEGORIES_FAIL";

export const DELETE_GIFT_CATEGORIES = "DELETE_GIFT_CATEGORIES";
export const DELETE_GIFT_CATEGORIES_BEGIN = "DELETE_GIFT_CATEGORIES_BEGIN";
export const DELETE_GIFT_CATEGORIES_SUCCESS = "DELETE_GIFT_CATEGORIES_SUCCESS";
export const DELETE_GIFT_CATEGORIES_FAIL = "DELETE_GIFT_CATEGORIES_FAIL";

export const RESET_GIFT_CATEGORY_ERRORS = "RESET_GIFT_CATEGORY_ERRORS";
