
export const GET_ROLE_DATA = "GET_ROLE_DATA";
export const GET_ROLE_DATA_BEGIN = "GET_ROLE_DATA_BEGIN";
export const GET_ROLE_DATA_SUCCESS = "GET_ROLE_DATA_SUCCESS";
export const GET_ROLE_DATA_FAIL = "GET_ROLE_DATA_FAIL";

export const GET_ROLE_PERMISSIONS = "GET_ROLE_PERMISSIONS";
export const GET_ROLE_PERMISSIONS_BEGIN = "GET_ROLE_PERMISSIONS_BEGIN";
export const GET_ROLE_PERMISSIONS_SUCCESS = "GET_ROLE_PERMISSIONS_SUCCESS";
export const GET_ROLE_PERMISSIONS_FAIL = "GET_ROLE_PERMISSIONS_FAIL";

export const GET_PERMISSIONS_MASTER_DATA = "GET_PERMISSIONS_MASTER_DATA";
export const GET_PERMISSIONS_MASTER_DATA_BEGIN = "GET_PERMISSIONS_MASTER_DATA_BEGIN";
export const GET_PERMISSIONS_MASTER_DATA_SUCCESS = "GET_PERMISSIONS_MASTER_DATA_SUCCESS";
export const GET_PERMISSIONS_MASTER_DATA_FAIL = "GET_PERMISSIONS_MASTER_DATA_FAIL";

export const UPDATE_ROLE_PERMISSIONS = "UPDATE_ROLE_PERMISSIONS";
export const UPDATE_ROLE_PERMISSIONS_BEGIN = "UPDATE_ROLE_PERMISSIONS_BEGIN";
export const UPDATE_ROLE_PERMISSIONS_SUCCESS = "UPDATE_ROLE_PERMISSIONS_SUCCESS";
export const UPDATE_ROLE_PERMISSIONS_FAIL = "UPDATE_ROLE_PERMISSIONS_FAIL";

export const GET_ADMINS_ROLE = "GET_ADMINS_ROLE";
export const GET_ADMINS_ROLE_BEGIN = "GET_ADMINS_ROLE_BEGIN";
export const GET_ADMINS_ROLE_SUCCESS = "GET_ADMINS_ROLE_SUCCESS";
export const GET_ADMINS_ROLE_FAIL = "GET_ADMINS_ROLE_FAIL";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_BEGIN = "DELETE_ROLE_BEGIN";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";

export const ADD_ROLE = "ADD_ROLE";
export const ADD_ROLE_BEGIN = "ADD_ROLE_BEGIN";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAIL = "ADD_ROLE_FAIL";

export const STORE_ROLE_PERMISSIONS_REQUEST = "STORE_ROLE_PERMISSIONS_REQUEST";

export const RESET_MESSAGES = "RESET_MESSAGES";
export const SET_DELETE_ERROR_MESSAGE = "SET_DELETE_ERROR_MESSAGE";
export const SET_VIEW_ERROR_MESSAGE = "SET_VIEW_ERROR_MESSAGE";

export const RESET_ROLE_PERMISSIONS = "RESET_ROLE_PERMISSIONS";