import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_ADMINS,
  GET_ADMIN_DETAILS,
  GET_SAMPLE_PASSWORD,
  DELETE_ADMIN,
  ADD_ADMIN,
  BLOCK_ADMIN,
  UPDATE_ADMIN_DETAILS,
} from "./actionTypes";

import {
  getAdminsDataBegin,
  getAdminsDataFail,
  getAdminsDataSuccess,
  getAdminDetailBegin,
  getAdminDetailSuccess,
  getAdminDetailFail,
  getSamplePasswordBegin,
  getSamplePasswordSuccess,
  getSamplePasswordFail,
  deleteAdminDataBegin,
  deleteAdminDataFail,
  deleteAdminDataSuccess,
  addAdminBegin,
  addAdminSuccess,
  addAdminFail,
  blockAdminBegin,
  blockAdminSuccess,
  blockAdminFail,
  updateAdminDetailsBegin,
  updateAdminDetailsSuccess,
  updateAdminDetailsFail,
} from "./action";

import { get, del, post, updateFormData } from "../../../helpers/api_helper";
import {
  ADMIN_DATA,
  ADMIN_BY_ID,
  SAMPLE_PASSWORD,
} from "../../../helpers/url_helper";

function* getAdminData({ payload }) {
  try {
    yield put(getAdminsDataBegin());
    const response = yield call(get, ADMIN_DATA, payload.request);
    yield put(getAdminsDataSuccess(response.result));
  } catch (error) {
    yield put(getAdminsDataFail(error));
  }
}

function* getAdminDetails({ payload }) {
  try {
    yield put(getAdminDetailBegin());
    const response = yield call(get, ADMIN_BY_ID.replace("{id}", payload));
    yield put(getAdminDetailSuccess(response.result));
  } catch (error) {
    yield put(getAdminDetailFail(error));
  }
}

function* getSamplePassword() {
  try {
    yield put(getSamplePasswordBegin());
    const response = yield call(get, SAMPLE_PASSWORD);
    yield put(getSamplePasswordSuccess(response.result));
  } catch (error) {
    yield put(getSamplePasswordFail(error));
  }
}

function* deleteAdmin({ data, callback }) {
  try {
    yield put(deleteAdminDataBegin());
    const response = yield call(del, ADMIN_BY_ID.replace("{id}", data));
    callback && callback();
    yield put(deleteAdminDataSuccess(response.result));
  } catch (error) {
    yield put(deleteAdminDataFail(error));
  }
}

function* addAdmin(payload) {
  try {
    yield put(addAdminBegin());
    let formData = new FormData();
    formData.append("data", JSON.stringify(payload.params));
    formData.append("file", payload.file);
    const response = yield call(
      post,
      ADMIN_DATA,
      formData,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      }
    );

    yield put(addAdminSuccess(response.result));
    payload.callback && payload.callback();
  } 
  catch (error) {
    yield put(addAdminFail(error));
  }
}

function* blockAdmin({ data, callback }) {
  try {
    yield put(blockAdminBegin());
    let formData = new FormData();
    formData.append("data", JSON.stringify(data.value));
    const response = yield call(
      updateFormData,
      ADMIN_BY_ID.replace("{id}", data.id),
      formData,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    callback && callback();
    yield put(getAdminDetailSuccess(response.result));
    yield put(
      blockAdminSuccess(
        data.value.Blocked
          ? "Admin blocked successfully"
          : "Admin unblocked successfully"
      )
    );
  } catch (error) {
    yield put(blockAdminFail(error));
  }
}

function* updateAdminData(payload) {
  try {
    yield put(updateAdminDetailsBegin());
    let formData = new FormData();
    formData.append("data", JSON.stringify(payload.params));
    formData.append("file", payload.file);
    const response = yield call(
      updateFormData,
      ADMIN_BY_ID.replace("{id}", payload.params.id),
      formData,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      }
    );

    yield put(updateAdminDetailsSuccess(response.result));
    payload.callback && payload.callback();
  } catch (error) {
    yield put(updateAdminDetailsFail(error));
  }
}

function* adminsSaga() {
  yield takeLatest(GET_ADMINS, getAdminData);
  yield takeLatest(GET_ADMIN_DETAILS, getAdminDetails);
  yield takeLatest(GET_SAMPLE_PASSWORD, getSamplePassword);
  yield takeLatest(DELETE_ADMIN, deleteAdmin);
  yield takeLatest(ADD_ADMIN, addAdmin);
  yield takeLatest(BLOCK_ADMIN, blockAdmin);
  yield takeLatest(UPDATE_ADMIN_DETAILS, updateAdminData);
}

export default adminsSaga;
