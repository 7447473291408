import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import { tr } from "date-fns/locale";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, Col, Container, Row, UncontrolledAlert } from "reactstrap";
import Loader from "../../../components/Common/Loader";
import TagInput from "../../../components/Common/TagInput";
import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import { addPostatMusic } from "../../../store/postatManagement/actions";

const AddMusic = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [songName, setSongName] = useState("");
  const [movieName, setMovieName] = useState("");
  const [tags, setTags] = useState([]);
  const [composer, setComposer] = useState("");
  const [description, setDescription] = useState("");
  const [track, setTrack] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [error, setError] = useState("");

  //   useEffect(() => {
  //     dispatch(getGiftCategories());
  //   }, []);
  const data = useSelector((state) => state?.ReportedPostatPosts);
  const handleSongChange = (event) => {
    const musicTrack = event.target.files[0];
    if (musicTrack) {
      setTrack(musicTrack);
    }
  };

  const handleSubmit = (e) => {
    const formData = new FormData();
    track && formData?.append("file", track);
    formData?.append(
      "data",
      JSON.stringify({
        song_name: songName?.replace(/\s+/g, "_"),
        composer_name: composer,
        singers_name: tags,
        movie_name: movieName,
        description: description,
      })
    );
    dispatch(
      addPostatMusic({
        music: formData,
        callback: () => {
          history.push("/postat-music-library");
        },
      })
    );
  };
  useEffect(() => {
    if (songName === "" || track === null) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [songName, track]);

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        ref={pageWrapRef}
      >
        <BackButton
          label="Music Library"
          handleClick={() => history.push("/postat-music-library")}
        />
        <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Add Music" />
            {/* {(data?.error || data?.sucess) && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color={`${data?.error ? "danger" : "success"}`}
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error || data?.success}
                </UncontrolledAlert>
              </div>
            )} */}
            <Col xl="8">
              <Card className="mb-0 p-4 my-4">
                <div className="text-muted">
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v);
                    }}
                  >
                    <div
                      className="table-responsive mt-4"
                      style={{ overflowX: "hidden" }}
                    >
                      <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Name Of Song
                            <span className="mandatory">*</span>
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="text"
                              className="form-control mb-4 "
                              // value={purpose?.purpose}
                              id="name-input"
                              onChange={(e) => {
                                setSongName(e?.target.value);
                              }}
                              placeholder="Enter Song Name"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Name Of the Composer
                          </label>

                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="text"
                              className="form-control mb-4 "
                              // value={purpose?.purpose}
                              id="name-input"
                              onChange={(e) => {
                                setComposer(e.target.value);
                              }}
                              placeholder="Enter Name "
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Name Of the Movie
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="text"
                              className="form-control mb-4 "
                              // value={purpose?.purpose}
                              id="name-input"
                              onChange={(e) => {
                                setMovieName(e.target.value);
                              }}
                              placeholder="Enter Movie Name"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Upload Music
                            {/* <span className="mandatory">*</span> */}
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName6"
                              type="file"
                              className="form-control mb-4"
                              accept="audio/*"
                              id="name-input"
                              onChange={handleSongChange}
                            />
                            {error && (
                              <div style={{ color: "red" }}>{error}</div>
                            )}
                            {/* {audioUrl && (
                              <div>
                                <audio id="audio-player" controls>
                                  <source src={audioUrl} type="audio/mpeg" />
                                  Your browser does not support the audio
                                  element.
                                </audio>
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter Song Description
                          </label>
                          <div className="col-md-8 dobWrapper">
                            <AvInput
                              name="adminName"
                              type="text"
                              className="form-control mb-4 "
                              // value={purpose?.purpose}
                              id="name-input"
                              onChange={(e) => {
                                setDescription(e.target.value);
                              }}
                              placeholder="Enter Description"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex mb-4">
                        <div className="col-md-6">
                          <label className="col-md-8 col-form-label">
                            Enter The Name Of Singers
                          </label>

                          <div className="col-md-8 dobWrapper">
                            <TagInput
                              tags={tags}
                              setTags={setTags}
                              placeholder="Add a tag"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <button
                          type="submit"
                          className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                          // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                          disabled={disabled}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default AddMusic;
