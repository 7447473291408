import React from "react";
import { Modal } from "reactstrap";

const ImageModal = ({ modal_center, path, toggle }) => {
  return (
    <Modal
      isOpen={modal_center}
      toggle={toggle}
      centered={true}
      className="image-modal-container"
    >
      <img src={path} />
    </Modal>
  );
};

export default ImageModal;
