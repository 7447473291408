import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_BEGIN,
  GET_DASHBOARD_DATA_FAIL,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_GRAPH_DATA,
  GET_GRAPH_DATA_BEGIN,
  GET_GRAPH_DATA_FAIL,
  GET_GRAPH_DATA_SUCCESS,
} from "./actionTypes";

export const getDashboardData = () => ({
  type: GET_DASHBOARD_DATA,
});

export const getDashboardDataBegin = () => ({
  type: GET_DASHBOARD_DATA_BEGIN,
});

export const getDashboardDataSuccess = (users) => ({
  type: GET_DASHBOARD_DATA_SUCCESS,
  payload: users,
});

export const getDashboardDataFail = (error) => ({
  type: GET_DASHBOARD_DATA_FAIL,
  payload: error,
});

export const getGraphData = (request) => ({
  type: GET_GRAPH_DATA,
  payload: { request },
});

export const getGraphDataBegin = () => ({
  type: GET_GRAPH_DATA_BEGIN,
});

export const getGraphDataSuccess = (data) => ({
  type: GET_GRAPH_DATA_SUCCESS,
  payload: data,
});

export const getGraphDataFail = (error) => ({
  type: GET_GRAPH_DATA_FAIL,
  payload: error,
});
