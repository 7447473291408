import { call, put, takeLatest } from "@redux-saga/core/effects";
import { get } from "../../helpers/api_helper";
import {
  getPostatSignedCookiesBegin,
  getPostatSignedCookiesFail,
  getPostatSignedCookiesSuccess,
} from "./action";
import { GET_POSTAT_SIGNED_COOKIES } from "./actionTypes";

function* getPostatCookiesSaga({ payload }) {
  try {
    yield put(getPostatSignedCookiesBegin());
    const response = yield call(get, "/postat/signed_cookies", payload);
    console.log("lang", response);
    yield put(getPostatSignedCookiesSuccess(response.result));
  } catch (error) {
    yield put(getPostatSignedCookiesFail(error));
  }
}

function* cookiesSaga() {
  yield takeLatest(GET_POSTAT_SIGNED_COOKIES, getPostatCookiesSaga);
}

export default cookiesSaga;
