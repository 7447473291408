import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Modal, ModalBody, Row } from "reactstrap";
import Breadcrumbs from "../../../../components/UI/Breadcrumb";
import Datatable from "../../../../components/UI/Tables/Datatable";
import { userFlaxRatesColumnData } from "../../../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../../helpers/constants";
import {
  convertTime,
  convertToUAETime,
  formatDateTime,
} from "../../../../helpers/utils";

import Loader from "../../../../components/Common/Loader";
import { getUserFlaxRates } from "../../../../store/users/flaxRates/actions";

const FlaxRates = ({ toggle, modal, userId }) => {
  const dispatch = useDispatch();
  const basicRequest = {
    sort: "conversion_date",
    sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 10,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const data = useSelector((state) => state.userFlaxRates);
  useEffect(() => {
    request && userId && dispatch(getUserFlaxRates({ request, userId }));
  }, [request]);
  console.log("flax rates", data);

  const flaxRatesData =
    data?.userFlaxRatesData?.user_flax_rates?.length >= 0 &&
    data?.userFlaxRatesData?.user_flax_rates?.map((result, index) => {
      // Check if sender_details and receiver_details are not null

      return {
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,
        podium_speaker: (
          <div
            data-tag="allowRowEvents"
            title={`UTC:${formatDateTime(result?.time_created)}`}
          >
            {result?.podium_speaker}
          </div>
        ),

        huddle_posts: (
          <div
            data-tag="allowRowEvents"
            title={`UTC:${formatDateTime(result?.time_created)}`}
          >
            {result?.huddle_posts}
          </div>
        ),
        huddle_comments: (
          <div
            data-tag="allowRowEvents"
            title={`UTC:${formatDateTime(result?.time_created)}`}
          >
            {result?.huddle_comments}
          </div>
        ),
        admin_updated: (
          <div
            data-tag="allowRowEvents"
            title={`UTC:${formatDateTime(result?.time_created)}`}
          >
            {result?.admin_updated}
          </div>
        ),
        restore_rating: (
          <div
            data-tag="allowRowEvents"
            title={`UTC:${formatDateTime(result?.time_created)}`}
          >
            {result?.restore_rating ? "true" : "false"}
          </div>
        ),

        huddle_reply: (
          <div
            data-tag="allowRowEvents"
            title={`UTC:${formatDateTime(result?.time_created)}`}
          >
            {result?.huddle_reply}
          </div>
        ),

        date: (
          <>
            <div data-tag="allowRowEvents" title={result?.time_created}>
              UTC: {formatDateTime(result?.time_created)}
            </div>
            <div
              className="text-secondary"
              data-tag="allowRowEvents"
              title={result?.time_created}
            >
              UAE: {convertTime(result?.time_created)}
            </div>
          </>
        ),
      };
    });

  return (
    <div>
      <Modal
        centered
        isOpen={modal}
        toggle={toggle}
        style={{ minWidth: "900px", maxWidth: "1200px" }}
      >
        {/* <ModalHeader toggle={toggle}>Flax summary</ModalHeader> */}
        <ModalBody>
          <>
            <Container id="userManagementWrapper" data-testid="userMgmtWrap">
              <Row>
                <Breadcrumbs title="Tables" breadcrumbItem="FLiX Rates" />

                <Datatable
                  // defaultSortField={request?.sort || "transfered_date"}
                  // defaultSortAsc={
                  //   request?.sort_order === "asc" ? true : false
                  // }
                  // dateFilter
                  // dateType="Transfered Date Range"
                  tableID={"flaxTable"}
                  columns={userFlaxRatesColumnData}
                  rows={flaxRatesData}
                  setRequest={setRequest}
                  request={request}
                  totalRecords={data?.userFlaxRatesData?.total}
                  // search
                  // searchTerm={data?.request?.keyword}
                  // reqstatusFilter={request?.status_filter}
                  // loading={data?.loading}
                  tableCardClassName={"snoTable"}
                />
              </Row>
            </Container>
            {data?.loading && <Loader darkBg={true} />}
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default FlaxRates;
