import {
    GET_REGISTRATION_LOGIN_HISTORY_BEGIN,
    GET_REGISTRATION_LOGIN_HISTORY_SUCCESS,
    GET_REGISTRATION_LOGIN_HISTORY_FAIL
} from './actionTypes';

const INIT_STATE = {
    historyDetails:[],
    loading:false,
    error:""
}

const registrationLoginHistory = (state = INIT_STATE , action) => {
    switch(action.type){
        case GET_REGISTRATION_LOGIN_HISTORY_BEGIN :
            return {
                ...state,
                loading:true,
                historyDetails:[],
                error:""
            }

        case GET_REGISTRATION_LOGIN_HISTORY_SUCCESS :
            return {
                ...state,
                loading:false,
                historyDetails:action.payload
            }

        case GET_REGISTRATION_LOGIN_HISTORY_FAIL:
            return {
                ...state,
                loading:false,
                history:[],
                error:action.payload
            }

        default:
        return state;
    }
}

export default registrationLoginHistory;