import {
  GET_ACCOUNT_VERIFICATION_STATUS_BEGIN,
  GET_ACCOUNT_VERIFICATION_STATUS_SUCCESS,
  GET_ACCOUNT_VERIFICATION_STATUS_FAIL,
  ADD_ACCOUNT_VERIFICATION_STATUS_BEGIN,
  ADD_ACCOUNT_VERIFICATION_STATUS_FAIL,
  ADD_ACCOUNT_VERIFICATION_STATUS_SUCCESS,

} from "./actionTypes";

const INIT_STATE = {
  success:"",
  error: "",
  loading: false,

  userRequestData: {},
};

const AccountVerification = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACCOUNT_VERIFICATION_STATUS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        userRequestData: {},
      };
    case GET_ACCOUNT_VERIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        userRequestData: action.payload,
      };
    case GET_ACCOUNT_VERIFICATION_STATUS_FAIL:
      return {
        ...state,
        userRequestData: {},
        loading: false,
        error: action.payload,
      };

    /* ------------------------------------------- */
    case ADD_ACCOUNT_VERIFICATION_STATUS_BEGIN:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      };

    case ADD_ACCOUNT_VERIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ADD_ACCOUNT_VERIFICATION_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    default:
      return state;
  }
};

export default AccountVerification;
