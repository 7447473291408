import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router";
import { Card, Col, Container, Row, UncontrolledAlert } from "reactstrap";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Common/Loader";
import BackButton from "../../../components/UI/BackButton";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import {
  addHuddleCategories,
  getHuddlesCategories,
  resetHuddlesErrors,
} from "../../../store/huddles/actions";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import { AvForm } from "availity-reactstrap-validation";

const AddHuddleReportedUserCategory = () => {
  const [category, setCategory] = useState("");

  const history = useHistory();
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const { t } = useTranslation();
  const [backToListing, setBackToListing] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const warningAlertRef = useRef(null);
  const pageWrapRef = useRef(null);
  const params = useParams();
  const data = useSelector((state) => state.Huddles);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const ref = React.useRef(null);

  const categoryRef = React.useRef(null);
  const categoryTypeRef = React.useRef(null);

  const [newCategoryList, setNewCategoryList] = useState([
    {
      id: "",
      category_name: "",
      category_type: "",
      huddle_type: "",
      created_by: "",
      category: "",
      language: "",
    },
  ]);

  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

  const okHandler = () => {
    history.push("/huddle-reported-user-categories");
  };

  useEffect(() => {
    if (category) setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  }, [category]);

  useEffect(() => {
    backToListing && history.push("/huddle-categories");
  }, [backToListing, params.id, history]);

  useEffect(() => {
    scrollToAlertMessage();
  }, [data?.error]);

  const scrollToAlertMessage = () => {
    if (data?.error) {
      let element = warningAlertRef?.current;
      let top = element?.offsetTop;
      let height = element?.getBoundingClientRect()?.height;
      pageWrapRef?.current?.scrollTo(0, top - height - 30);
    }
  };

  useEffect(() => {
    for (let i = 0; i < data.categories?.categories?.length; i++)
      if (
        newCategoryList[0]?.category?.toLowerCase() ===
          data?.categories?.categories[i]?.category_name.toLowerCase() ||
        newCategoryList[0]?.category_name?.toLowerCase() ===
          data?.categories?.categories[i]?.category_name?.toLowerCase()
      ) {
        if (
          newCategoryList[0]?.huddle_type?.toLowerCase() ===
          data?.categories?.categories[i]?.huddle_type?.toLowerCase()
        ) {
          setMessage("Huddle Category Already Exists");
          break;
        } else {
          setMessage("");
        }
      } else {
        setMessage("");
      }
  }, [newCategoryList]);

  useEffect(() => {
    for (let i = 0; i < newCategoryList?.length; i++) {
      if (newCategoryList[i]?.language == "english") {
        if (
          newCategoryList[i]?.category.trim() == "" ||
          newCategoryList[i]?.category_type.trim() == "" ||
          message !== ""
        ) {
          setDisabled(true);
          break;
        } else {
          setDisabled(false);
        }
      }
      if (newCategoryList[i] == undefined) {
        setDisabled(true);
        break;
      } else {
        setDisabled(false);
      }
      if (
        newCategoryList[i]?.category_name.trim() == "" ||
        newCategoryList?.length != data?.categories?.languages?.length ||
        message !== ""
      ) {
        setDisabled(true);
        break;
      } else {
        setDisabled(false);
      }
    }
  }, [newCategoryList, message]);

  const confirmBrowserBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
    return backToListing ? true : false;
  };
  const confirmBack = () => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: "",
      content: t("Common.leavePage"),
      type: "back",
    });
  };

  const redirectMethod = () => {
    dispatch(resetHuddlesErrors());
    setBackToListing(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const list = [...newCategoryList];

    for (let i = 0; i < newCategoryList?.length; i++) {
      if (newCategoryList[i]?.language == "english") {
        list[i] = {
          id: "",
          category_name: newCategoryList[i]?.category_name.trim(),
          category_type: newCategoryList[i]?.category_type.trim(),
          huddle_type: ref.current.value,
          created_by: "",
          category: newCategoryList[1]?.category.trim(),
          language: newCategoryList[i]?.language,
        };
        setNewCategoryList(list);
        // newCategoryList[i]?.huddle_type =
        //   document.getElementById("huddleType");
      } else {
        list[i] = {
          id: "",
          category_name: newCategoryList[i]?.category_name.trim(),
          category_type: newCategoryList[i]?.category_type.trim(),
          huddle_type: ref.current.value,
          created_by: "",
          category: newCategoryList[i]?.category.trim(),
          language: newCategoryList[i]?.language,
        };
      }
    }
    const objs = convertArrayToObject(list, "language");
    dispatch(
      addHuddleCategories({
        category: objs,
        callback: () => history.push("/huddle-categories"),
      })
    );
  };

  const handleReportUsersChangeOrAdd = (index, key, value, language) => {
    const list = [...newCategoryList];

    if (list[index]) {
      list[index][key] = value;
      list[index]["language"] = language;
      list[index]["category"] = categoryRef.current.value;
      list[index]["category_type"] = categoryTypeRef.current.value;
      list[index]["huddle_type"] = ref.current.value;

      setNewCategoryList(list);
    } else {
      list[index] = {
        id: "",
        category_name: value,
        category_type: "",
        huddle_type: "",
        created_by: "",
        category: "",
        language: language,
      };
      setNewCategoryList(list);
    }
  };

  useEffect(() => {
    dispatch(getHuddlesCategories());
  }, []);

  return (
    <div
      className="page-content"
      data-testid="component-usermgmt"
      id="userMgmtList"
      ref={pageWrapRef}
    >
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            return confirmBrowserBack();
          }
        }}
        when={formChanged}
      />
      <BackButton
        label="Huddle Categories"
        handleClick={() => (formChanged ? confirmBack() : redirectMethod())}
      />
      <Container fluid id="userManagementWrapper" data-testid="userMgmtWrap">
        <Row>
          <Breadcrumbs title="Tables" breadcrumbItem="Add Category" />

          <div ref={warningAlertRef}>
            {data?.error && (
              <UncontrolledAlert
                color={
                  data?.error || data?.listing_error ? "danger" : "success"
                }
                className="alert-dismissible fade show"
                role="alert"
              >
                {data?.error}
              </UncontrolledAlert>
            )}
            {message !== "" && (
              <UncontrolledAlert
                color={"danger"}
                className="alert-dismissible fade show"
                role="alert"
              >
                {message}
              </UncontrolledAlert>
            )}
          </div>

          <Col xl="8">
            <Card className="mb-0 p-4 my-4">
              <div className="text-muted">
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleSubmit(e, v);
                  }}
                >
                  <div
                    className="table-responsive mt-4"
                    style={{ overflowX: "hidden" }}
                  >
                    {data?.categories?.languages?.length &&
                      data?.categories?.languages?.map((language, i) =>
                        language == "english" ? (
                          <div className="col-md-12">
                            <div className="d-flex mb-4">
                              <div className="col-md-6">
                                <label className="col-md-8 col-form-label">
                                  Enter Category Type
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="col-md-8 dobWrapper">
                                  <AvInput
                                    ref={categoryTypeRef}
                                    name="adminName"
                                    type="text"
                                    className="form-control mb-4 "
                                    value={category?.category_type}
                                    id="name-input"
                                    onChange={(e) => {
                                      handleReportUsersChangeOrAdd(
                                        i,
                                        "category_type",
                                        e.target.value,
                                        language
                                      );
                                    }}
                                    placeholder="Enter Category Type"
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <label className="col-md-8 col-form-label">
                                  Enter Category
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="col-md-8 dobWrapper">
                                  <AvInput
                                    ref={categoryRef}
                                    name="adminName"
                                    type="text"
                                    className="form-control mb-4 "
                                    value={category?.category}
                                    id="name-input"
                                    onChange={(e) => {
                                      handleReportUsersChangeOrAdd(
                                        i,
                                        "category",
                                        e.target.value,
                                        language
                                      );
                                    }}
                                    placeholder="Enter Category Text"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-flex mb-4">
                              <div className="col-md-6">
                                <label className="col-md-8 col-form-label">
                                  Huddle Type
                                  <span className="mandatory">*</span>
                                </label>
                                <div className="col-md-8 dobWrapper">
                                  <select
                                    className="form-control mb-4"
                                    id="huddleType"
                                    ref={ref}
                                    onChange={(e) => {
                                      handleReportUsersChangeOrAdd(
                                        i,
                                        "huddle_type",
                                        e.target.value,
                                        language
                                      );
                                    }}
                                  >
                                    <option value="Public">Public</option>
                                    <option value="Private">Private</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex mx-1">
                              <label className="col-md-2 lang-font col-form-label">
                                {`${language
                                  ?.slice(0, 1)
                                  .toUpperCase()}${language?.slice(1)}`}
                              </label>
                              <div className="col-md-6">
                                <div className="col-md-8 dobWrapper">
                                  <AvInput
                                    name="adminName"
                                    type="text"
                                    className="form-control mb-4 "
                                    value={category?.category_name}
                                    id="name-input"
                                    onChange={(e) => {
                                      handleReportUsersChangeOrAdd(
                                        i,
                                        "category_name",
                                        e.target.value,
                                        language
                                      );
                                    }}
                                    placeholder="Enter Category"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className=" d-flex mx-1">
                            <label className="col-md-2 lang-font col-form-label">
                              {`${language
                                ?.slice(0, 1)
                                .toUpperCase()}${language?.slice(1)}`}
                            </label>{" "}
                            <div className="col-md-6">
                              {/* <label className="col-md-8 col-form-label">
                              Enter Category Text
                              <span className="mandatory">*</span>
                            </label> */}
                              <div className="col-md-8 dobWrapper">
                                <AvInput
                                  name="adminName"
                                  type="text"
                                  className="form-control mb-4 "
                                  value={category?.category_name}
                                  id="name-input"
                                  onChange={(e) => {
                                    handleReportUsersChangeOrAdd(
                                      i,
                                      "category_name",
                                      e.target.value,
                                      language
                                    );
                                  }}
                                  placeholder="Enter Category Text"
                                />
                              </div>
                            </div>
                          </div>
                        )
                      )}

                    <div className="mt-4">
                      <button
                        type="submit"
                        // disabled={isButtonDisabled}
                        className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green cust-saveButton"
                        // className="btn waves-effect waves-light cust_no_shadow bgcolor-btn-green"
                        disabled={disabled}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </AvForm>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>

      <ConfirmationAlert
        {...promptMessage}
        modal_center={showPromptPopUp}
        setmodal_center={setShowPromptPopUp}
        onOK={okHandler}
      />
      {data?.loading && <Loader darkBg={true} />}
    </div>
  );
};

export default AddHuddleReportedUserCategory;
