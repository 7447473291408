import { call, put, takeLatest } from "redux-saga/effects";

import { GET_SUBSCRIPTION_DETAILS } from "./actionTypes";
import {
  getSubscriptionDetailsBegin,
  getSubscriptionDetailsSuccess,
  getSubscriptionDetailsFail,
} from "./action";

import { get } from "../../../helpers/api_helper";
import { GET_SUBSCRIPTION_DETAILS_API } from "../../../helpers/url_helper";

function* getSubscriptionDetails({ payload }) {
  try {
    yield put(getSubscriptionDetailsBegin());
    const response = yield call(
      get,
      GET_SUBSCRIPTION_DETAILS_API.replace("{id}", payload.id),
      payload.request
    );
    yield put(getSubscriptionDetailsSuccess(response.result));
  } catch (error) {
    yield put(getSubscriptionDetailsFail(error));
  }
}

function* subscriptionDetailsSaga() {
  yield takeLatest(GET_SUBSCRIPTION_DETAILS, getSubscriptionDetails);
}

export default subscriptionDetailsSaga;
