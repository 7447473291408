import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, UncontrolledAlert } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  deletePostat,
  getAllPostatPosts,
} from "../../store/postatManagement/actions";
import { blockUser } from "../../store/users/actions";
import Datatable from "../../components/UI/Tables/Datatable";
import Breadcrumbs from "../../components/UI/Breadcrumb";
import { allpostatColumnData } from "../../helpers/columns";
import { ImagePreview } from "../../components/Common/imagePreview";
import ConfirmationAlert from "../../components/UI/ConfirmationAlert";
import Loader from "../../components/Common/Loader";
import { checkUserPermission } from "../../helpers/utils";
import { PostatVideoPreview } from "./PostatVideoPreview";

const AllPostatPosts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [imgUrl, setImgUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const warningAlertRef = useRef();
  const basicRequest = {
    // sort: "transfered_date",
    // sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
    tab: "all",
  };
  const history = useHistory();
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [userPermissions, setUserPermissions] = useState({});
  const [postatPermissions, setPostatPermissions] = useState({});
  const isDeleteable = postatPermissions?.delete || false;
  const isBlockable = userPermissions?.edit || false;
  const loginData = useSelector((state) => state.Login);

  const [request, setRequest] = useState({ ...basicRequest });
  useEffect(() => {
    dispatch(getAllPostatPosts(request));
  }, [request]);

  const data = useSelector((state) => state?.ReportedPostatPosts);

  const previewImage = (url) => {
    setOpen(true);
    setImgUrl(url);
  };
  const previewVideo = (url) => {
    setVideoModal(true);
    setVideoUrl(url);
  };
  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setPostatPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Postat Management",
          "Postat"
        )
      );
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Users"
        )
      );
    }
  }, [loginData]);

  const formatPostatPosts = useCallback(() => {
    return (
      data?.allPosts?.responseList &&
      data?.allPosts?.responseList?.length > 0 &&
      data?.allPosts?.responseList?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        post_thumbnail: (
          <div
            onClick={(e) => {
              e.preventDefault();
              result?.media?.length > 0 &&
                result?.media?.[0]?.media_type === "IMAGE" &&
                previewImage(
                  result?.media?.length > 0 && result?.media?.[0]?.thumbnail
                );
              result?.media?.length > 0 &&
                result?.media?.[0]?.media_type === "VIDEO" &&
                previewVideo(
                  result?.media?.length > 0 &&
                    result?.media?.[0]?.media_cloudfront_url
                );
            }}
            title={"click to preview"}
          >
            <img
              width={28}
              src={
                result?.media?.length > 0 > 0 && result?.media?.[0]?.thumbnail
              }
              alt="user"
            />
          </div>
        ),
        post_owner_name: (
          // <div
          //   className="profileWrap"
          //   title={result?.post_owner_info?.name}
          //   data-tag="allowRowEvents"
          // >
          //   <div className="d-flex">
          //     <div data-tag="allowRowEvents">
          //       <img
          //         className="table_profileImg"
          //         src={result?.post_owner_info?.profile_url}
          //       />
          //     </div>

          //     <div className="mx-2 mt-3" title={result?.name}>
          //       {result?.post_owner_info?.name}{" "}
          //     </div>
          //   </div>
          // </div>
          <>
            <div data-tag="allowRowEvents" title={result?.sender_details?.name}>
              {result?.sender_details?.name}
            </div>
            <div
              data-tag="allowRowEvents"
              title={result?.sender_details?.profile_name}
            >
              {result?.sender_details?.profile_name}
            </div>
            {(result?.sender_details?.blocked_by_admin ||
              result?.sender_details?.blocked_by_admin) && (
              <div className="badge bg-soft-secondary font-size-6 my-auto ">
                Blocked user
              </div>
            )}
          </>
        ),
        reported_count: (
          <div data-tag="allowRowEvents" title={result?.reported_count}>
            {result?.reported_count}
          </div>
        ),
        actions: (
          <div>
            <div className="cust-table-actions-wrap">
              {!result?.sender_details?.blocked_by_admin &&
                !result?.sender_details?.blocked_by_leader && (
                  <button
                    onClick={() =>
                      blockPromptHandler(result?.user_id, result?.message_id)
                    }
                    className=" action-btn"
                    style={{
                      fontSize: "11px",
                      padding: "5px",
                      backgroundColor: "#dedfe0",
                      color: "#969696",
                      fontWeight: "bold",
                      opacity: !isDeleteable || !isBlockable ? "50%" : "",
                      cursor:
                        !isDeleteable || !isBlockable ? "not-allowed" : "",
                    }}
                    title="Block Owner"
                    disabled={!isDeleteable || !isBlockable}
                  >
                    BLOCK AND DELETE
                  </button>
                )}

              <button
                onClick={() => deletePromptHandler(result?.message_id)}
                className=" action-btn"
                title="Delete Post"
                disabled={!isDeleteable}
                style={{
                  fontSize: "11px",
                  padding: "5px",
                  backgroundColor: "#fadede",
                  fontWeight: "bold",
                  color: "#fa5f5f",
                  opacity: !isDeleteable ? "50%" : "",
                  cursor: !isDeleteable ? "not-allowed" : "",
                }}
              >
                DELETE
              </button>
            </div>
          </div>
        ),
      }))
    );
  }, [request, data?.allPosts?.responseList, userPermissions]);
  const allPosts = useMemo(() => formatPostatPosts(), [formatPostatPosts]);

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      id: id,
      content: `Are you sure you want to delete this Post from the App?`,
      type: "delete",
    });
  };

  const blockPromptHandler = (userId, postId) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      userId: userId,
      postId: postId,
      content: `Are you sure you want to block this user and delete the Post from the App? `,
      type: "block-del",
    });
  };

  const okHandler = () => {
    promptMessage.type === "delete"
      ? dispatch(
          deletePostat({
            id: promptMessage.id,
            callback: () => dispatch(getAllPostatPosts(request)),
          })
        )
      : dispatch(
          blockUser({
            data: { blocked: true },
            id: promptMessage.userId,
          })
        ) &&
        dispatch(
          deletePostat({
            id: promptMessage.postId,
            callback: () => dispatch(getAllPostatPosts(request)),
          })
        );
  };

  // const tableRowClickHandler = (param) => {
  //   // history.push(`/payout-requests/${param.id}`);
  //   history.push({
  //     pathname: `/postat-reported-posts/${param.post_id}/reports`,
  //     state: { postId: param.post_id },
  //   });
  // };

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        // id="userMgmtList"
        // ref={pageWrapRef}
      >
        <Container id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="All Postats" />
            {data?.error && (
              <div ref={warningAlertRef}>
                <UncontrolledAlert
                  color="danger"
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {data?.error}
                </UncontrolledAlert>
              </div>
            )}
            <div className="container-fluid">
              <Datatable
                tableID={"flaxTable"}
                columns={allpostatColumnData}
                rows={allPosts}
                setRequest={setRequest}
                request={request}
                // dateFilter
                totalRecords={data?.allPosts?.total}
                sp_search
                sp_search_param="userId"
                allPostatSearchIdentifier
                // search
                // dateType="Purchased Date Range"
                // add
                // dropDown
                // languages={languages}
                // addNewClickHandler={addNewClickHandler}
                // selectedLanguage={selectedLanguage}
                // setSelectedLanguage={setSelectedLanguage}
                // addNewLabel={t("Admins.addAdmin.addBtn")}
                // searchTerm={data?.request?.keyword}
                loading={data?.loading}
                // tableRowClicked={(param) => tableRowClickHandler(param)}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
          <ImagePreview
            imgUrl={imgUrl}
            isOpen={isOpen}
            setOpen={setOpen}
            title="Postat"
          />
          <PostatVideoPreview
            videoUrl={videoUrl}
            isOpen={videoModal}
            setOpen={setVideoModal}
          />
        </Container>
        <ConfirmationAlert
          {...promptMessage}
          modal_center={showPromptPopUp}
          setmodal_center={setShowPromptPopUp}
          onOK={okHandler}
        />

        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default AllPostatPosts;
