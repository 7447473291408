import {
  GET_HUDDLES_CATEGORIES,
  GET_HUDDLES_CATEGORIES_BEGIN,
  GET_HUDDLES_CATEGORIES_FAIL,
  GET_HUDDLES_CATEGORIES_SUCCESS,
  GET_HUDDLES_CATEGORIES_BY_ID,
  GET_HUDDLES_CATEGORIES_BY_ID_BEGIN,
  GET_HUDDLES_CATEGORIES_BY_ID_FAIL,
  GET_HUDDLES_CATEGORIES_BY_ID_SUCCESS,
  ADD_HUDDLES_CATEGORIES,
  ADD_HUDDLES_CATEGORIES_BEGIN,
  ADD_HUDDLES_CATEGORIES_SUCCESS,
  ADD_HUDDLES_CATEGORIES_FAIL,
  EDIT_HUDDLES_CATEGORIES,
  EDIT_HUDDLES_CATEGORIES_BEGIN,
  EDIT_HUDDLES_CATEGORIES_SUCCESS,
  EDIT_HUDDLES_CATEGORIES_FAIL,
  DELETE_HUDDLES_CATEGORIES,
  DELETE_HUDDLES_CATEGORIES_BEGIN,
  DELETE_HUDDLES_CATEGORIES_SUCCESS,
  DELETE_HUDDLES_CATEGORIES_FAIL,
  RESET_HUDDLES_ERRORS,
  GET_HUDDLES,
  GET_HUDDLES_BEGIN,
  GET_HUDDLES_SUCCESS,
  GET_HUDDLES_FAIL,
  GET_HUDDLES_BY_ID,
  GET_HUDDLES_BY_ID_BEGIN,
  GET_HUDDLES_BY_ID_SUCCESS,
  GET_HUDDLES_BY_ID_FAIL,
  EDIT_HUDDLES,
  EDIT_HUDDLES_BEGIN,
  EDIT_HUDDLES_SUCCESS,
  EDIT_HUDDLES_FAIL,
  DELETE_HUDDLES_BEGIN,
  DELETE_HUDDLES_SUCCESS,
  DELETE_HUDDLES_FAIL,
  DELETE_HUDDLES,
  BLOCK_HUDDLES_BEGIN,
  BLOCK_HUDDLES,
  BLOCK_HUDDLES_SUCCESS,
  BLOCK_HUDDLES_FAIL,
  GET_HUDDLES_REPORTED_USER_CATEGORIES,
  GET_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN,
  GET_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS,
  GET_HUDDLES_REPORTED_USER_CATEGORIES_FAIL,
  GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID,
  GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_BEGIN,
  GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_SUCCESS,
  GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_FAIL,
  ADD_HUDDLES_REPORTED_USER_CATEGORIES,
  ADD_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN,
  ADD_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS,
  ADD_HUDDLES_REPORTED_USER_CATEGORIES_FAIL,
  EDIT_HUDDLES_REPORTED_USER_CATEGORIES,
  EDIT_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN,
  EDIT_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS,
  EDIT_HUDDLES_REPORTED_USER_CATEGORIES_FAIL,
  GET_HUDDLES_REPORTED_MESSAGES_BEGIN,
  GET_HUDDLES_REPORTED_MESSAGES_SUCCESS,
  GET_HUDDLES_REPORTED_MESSAGES_FAIL,
  GET_HUDDLES_REPORTED_MESSAGES,
  GET_HUDDLES_PARTICIPANTS,
  GET_HUDDLES_PARTICIPANTS_BEGIN,
  GET_HUDDLES_PARTICIPANTS_SUCCESS,
  GET_HUDDLES_PARTICIPANTS_FAIL,
  DELETE_HUDDLES_LIST,
  GET_HUDDLES_STAT,
  DELETE_HUDDLES_REPORTED_USER_CATEGORIES,
  DELETE_HUDDLES_REPORTED_USER_CATEGORIES_FAIL,
  DELETE_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN,
  DELETE_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS,
} from "./actionTypes";

export const getHuddlesCategories = (request) => ({
  type: GET_HUDDLES_CATEGORIES,
  request: request,
});

export const getHuddlesCategoriesBegin = () => ({
  type: GET_HUDDLES_CATEGORIES_BEGIN,
});

export const getHuddlesCategoriesSuccess = (categories) => ({
  type: GET_HUDDLES_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getHuddlesCategoriesFail = (error) => ({
  type: GET_HUDDLES_CATEGORIES_FAIL,
  payload: error,
});

export const getHuddlesCategoriesById = ({ id }) => ({
  type: GET_HUDDLES_CATEGORIES_BY_ID,
  id: id,
});

export const getHuddlesCategoriesByIdBegin = () => ({
  type: GET_HUDDLES_CATEGORIES_BY_ID_BEGIN,
});

export const getHuddlesCategoriesByIdSuccess = (categories) => ({
  type: GET_HUDDLES_CATEGORIES_BY_ID_SUCCESS,
  payload: categories,
});

export const getHuddlesCategoriesByIdFail = (error) => ({
  type: GET_HUDDLES_CATEGORIES_BY_ID_FAIL,
  payload: error,
});

export const addHuddleCategories = ({ category, callback }) => ({
  type: ADD_HUDDLES_CATEGORIES,
  category: category,
  callback: callback,
});

export const addHuddleCategoriesBegin = () => ({
  type: ADD_HUDDLES_CATEGORIES_BEGIN,
});

export const addHuddleCategoriesSuccess = (categories) => ({
  type: ADD_HUDDLES_CATEGORIES_SUCCESS,
  payload: categories,
});

export const addHuddleCategoriesFail = (error) => ({
  type: ADD_HUDDLES_CATEGORIES_FAIL,
  payload: error,
});

export const editHuddleCategories = ({ category, id, callback }) => ({
  type: EDIT_HUDDLES_CATEGORIES,
  category: category,
  id: id,
  callback: callback,
});

export const editHuddleCategoriesBegin = () => ({
  type: EDIT_HUDDLES_CATEGORIES_BEGIN,
});

export const editHuddleCategoriesSuccess = (categories) => ({
  type: EDIT_HUDDLES_CATEGORIES_SUCCESS,
  payload: categories,
});

export const editHuddleCategoriesFail = (error) => ({
  type: EDIT_HUDDLES_CATEGORIES_FAIL,
  payload: error,
});

export const deleteHuddleCategories = ({ id, callback }) => ({
  type: DELETE_HUDDLES_CATEGORIES,
  id: id,
  callback: callback,
});

export const deleteHuddleCategoriesBegin = () => ({
  type: DELETE_HUDDLES_CATEGORIES_BEGIN,
});

export const deleteHuddleCategoriesSuccess = (categories) => ({
  type: DELETE_HUDDLES_CATEGORIES_SUCCESS,
  payload: categories,
});

export const deleteHuddleCategoriesFail = (error) => ({
  type: DELETE_HUDDLES_CATEGORIES_FAIL,
  payload: error,
});

export const resetHuddlesErrors = () => ({
  type: RESET_HUDDLES_ERRORS,
});

export const getHuddles = (request) => ({
  type: GET_HUDDLES,
  request: request,
});

export const getHuddleStatistics = (request) => ({
  type: GET_HUDDLES_STAT,
  request: request,
});

export const getHuddlesBegin = () => ({
  type: GET_HUDDLES_BEGIN,
});

export const getHuddlesSuccess = (huddles) => ({
  type: GET_HUDDLES_SUCCESS,
  payload: huddles,
});

export const getHuddlesFail = (error) => ({
  type: GET_HUDDLES_FAIL,
  payload: error,
});

export const getHuddlesById = ({ id }) => ({
  type: GET_HUDDLES_BY_ID,
  id: id,
});

export const getHuddlesByIdBegin = () => ({
  type: GET_HUDDLES_BY_ID_BEGIN,
});

export const getHuddlesByIdSuccess = (huddle) => ({
  type: GET_HUDDLES_BY_ID_SUCCESS,
  payload: huddle,
});

export const getHuddlesByIdFail = (error) => ({
  type: GET_HUDDLES_BY_ID_FAIL,
  payload: error,
});

export const editHuddle = (data, file, callback) => ({
  type: EDIT_HUDDLES,
  file: file,
  params: data,
  callback: callback,
});

export const editHuddleBegin = () => ({
  type: EDIT_HUDDLES_BEGIN,
});

export const editHuddleSuccess = (huddle) => ({
  type: EDIT_HUDDLES_SUCCESS,
  payload: huddle,
});

export const editHuddleFail = (error) => ({
  type: EDIT_HUDDLES_FAIL,
  payload: error,
});

export const deleteHuddle = ({ id, callback }) => ({
  type: DELETE_HUDDLES,
  id: id,
  callback: callback,
});

export const deleteHuddleBegin = () => ({
  type: DELETE_HUDDLES_BEGIN,
});

export const deleteHuddleSuccess = (msg) => ({
  type: DELETE_HUDDLES_SUCCESS,
  message: msg,
});

export const deleteHuddleFail = (error) => ({
  type: DELETE_HUDDLES_FAIL,
  payload: error,
});

export const blockHuddle = ({ id, callback }) => ({
  type: BLOCK_HUDDLES,
  id: id,
  callback: callback,
});

export const blockHuddleBegin = () => ({
  type: BLOCK_HUDDLES_BEGIN,
});

export const blockHuddleSuccess = (huddle) => ({
  type: BLOCK_HUDDLES_SUCCESS,
  payload: huddle,
});

export const blockHuddleFail = (error) => ({
  type: BLOCK_HUDDLES_FAIL,
  payload: error,
});

// Reported Users Categories

export const getHuddleReportedUsersCategories = (request) => ({
  type: GET_HUDDLES_REPORTED_USER_CATEGORIES,
  request: request,
});

export const getHuddleReportedUsersCategoriesBegin = () => ({
  type: GET_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN,
});

export const getHuddleReportedUsersCategoriesSuccess = (categories) => ({
  type: GET_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getHuddleReportedUsersCategoriesFail = (error) => ({
  type: GET_HUDDLES_REPORTED_USER_CATEGORIES_FAIL,
  payload: error,
});

export const getHuddleReportedUsersCategoriesById = ({ id }) => ({
  type: GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID,
  id: id,
});

export const getHuddleReportedUsersCategoriesByIdBegin = () => ({
  type: GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_BEGIN,
});

export const getHuddleReportedUsersCategoriesByIdSuccess = (categories) => ({
  type: GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_SUCCESS,
  payload: categories,
});

export const getHuddleReportedUsersCategoriesByIdFail = (error) => ({
  type: GET_HUDDLES_REPORTED_USER_CATEGORIES_BY_ID_FAIL,
  payload: error,
});

export const addHuddleReportedUsersCategories = ({ category, callback }) => ({
  type: ADD_HUDDLES_REPORTED_USER_CATEGORIES,
  category: category,
  callback: callback,
});

export const addHuddleReportedUsersCategoriesBegin = () => ({
  type: ADD_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN,
});

export const addHuddleReportedUsersCategoriesSuccess = (categories) => ({
  type: ADD_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS,
  payload: categories,
});

export const addHuddleReportedUsersCategoriesFail = (error) => ({
  type: ADD_HUDDLES_REPORTED_USER_CATEGORIES_FAIL,
  payload: error,
});

export const deleteHuddleReportedUserCategories = ({ id, callback }) => ({
  type: DELETE_HUDDLES_REPORTED_USER_CATEGORIES,
  id: id,
  callback: callback,
});

export const deleteHuddleReportedUserCategoriesBegin = () => ({
  type: DELETE_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN,
});

export const deleteHuddleReportedUserCategoriesSuccess = (
  deletedCategoryId
) => ({
  type: DELETE_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS,
  payload: deletedCategoryId,
});

export const deleteHuddleReportedUserCategoriesFail = (error) => ({
  type: DELETE_HUDDLES_REPORTED_USER_CATEGORIES_FAIL,
  payload: error,
});

export const editHuddleReportedUsersCategories = ({
  category,
  id,
  callback,
}) => ({
  type: EDIT_HUDDLES_REPORTED_USER_CATEGORIES,
  category: category,
  id: id,
  callback: callback,
});

export const editHuddleReportedUsersCategoriesBegin = () => ({
  type: EDIT_HUDDLES_REPORTED_USER_CATEGORIES_BEGIN,
});

export const editHuddleReportedUsersCategoriesSuccess = (categories) => ({
  type: EDIT_HUDDLES_REPORTED_USER_CATEGORIES_SUCCESS,
  payload: categories,
});

export const editHuddleReportedUsersCategoriesFail = (error) => ({
  type: EDIT_HUDDLES_REPORTED_USER_CATEGORIES_FAIL,
  payload: error,
});

export const getHuddleReportedMessages = ({ request, id }) => ({
  type: GET_HUDDLES_REPORTED_MESSAGES,
  request: request,
  id: id,
});

export const getHuddleReportedMessagesBegin = () => ({
  type: GET_HUDDLES_REPORTED_MESSAGES_BEGIN,
});

export const getHuddleReportedMessagesSuccess = (categories) => ({
  type: GET_HUDDLES_REPORTED_MESSAGES_SUCCESS,
  payload: categories,
});

export const getHuddleReportedMessagesFail = (error) => ({
  type: GET_HUDDLES_REPORTED_MESSAGES_FAIL,
  payload: error,
});

export const getHuddleParticipants = ({ request, id }) => ({
  type: GET_HUDDLES_PARTICIPANTS,
  request: request,
  id: id,
});

export const getHuddleParticipantsBegin = () => ({
  type: GET_HUDDLES_PARTICIPANTS_BEGIN,
});

export const getHuddleParticipantsSuccess = (categories) => ({
  type: GET_HUDDLES_PARTICIPANTS_SUCCESS,
  payload: categories,
});

export const getHuddleParticipantsFail = (error) => ({
  type: GET_HUDDLES_PARTICIPANTS_FAIL,
  payload: error,
});

export const deleteHuddleList = ({ ids, callback }) => ({
  type: DELETE_HUDDLES_LIST,
  ids,
  callback: callback,
});
