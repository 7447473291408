import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_BEGIN,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_BEGIN,
  GET_ALL_NOTIFICATIONS_FAIL,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  MARK_ALL_AS_READ,
  MARK_ALL_AS_READ_BEGIN,
  MARK_ALL_AS_READ_FAIL,
  MARK_ALL_AS_READ_SUCCESS,
  GET_UNSEEN_COUNT,
  GET_UNSEEN_COUNT_BEGIN,
  GET_UNSEEN_COUNT_FAIL,
  GET_UNSEEN_COUNT_SUCCESS,
  RESET_UNSEEN_COUNT
} from "./actionTypes";

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS,
});

export const getNotificationsBegin = () => ({
  type: GET_NOTIFICATIONS_BEGIN,
});

export const getNotificationsSuccess = (data) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const getNotificationsFail = (error) => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
});

/*************************************** */

export const getAllNotifications = (request) => ({
  type: GET_ALL_NOTIFICATIONS,
  payload: request
});

export const getAllNotificationsBegin = () => ({
  type: GET_ALL_NOTIFICATIONS_BEGIN,
});

export const getAllNotificationsSuccess = (data) => ({
  type: GET_ALL_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const getAllNotificationsFail = (error) => ({
  type: GET_ALL_NOTIFICATIONS_FAIL,
  payload: error,
});

/*************************************** */

export const markAllAsRead = (payload) => ({
  type: MARK_ALL_AS_READ,
  payload: payload
});

export const markAllAsReadBegin = () => ({
  type: MARK_ALL_AS_READ_BEGIN,
});

export const markAllAsReadSuccess = (data) => ({
  type: MARK_ALL_AS_READ_SUCCESS,
  payload: data,
});

export const markAllAsReadFail = (error) => ({
  type: MARK_ALL_AS_READ_FAIL,
  payload: error,
});

/**************************************** */

export const getUnseenCount = () => ({
  type: GET_UNSEEN_COUNT,
});

export const getUnseenCountBegin = () => ({
  type: GET_UNSEEN_COUNT_BEGIN,
});

export const getUnseenCountSuccess = (data) => ({
  type: GET_UNSEEN_COUNT_SUCCESS,
  payload: data,
});

export const getUnseenCountFail = (error) => ({
  type: GET_UNSEEN_COUNT_FAIL,
  payload: error,
});

/****************************************** */

export const resetUnseenCount = () => ({
  type: RESET_UNSEEN_COUNT,
});
