import { useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ManualPPForm } from "./ManualPPForm";
import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Input,
  FormGroup,
  UncontrolledAlert,
  CustomInput,
} from "reactstrap";

import {
  addManualPP,
  getAllManualPP,
  deletepp,
  resetManualPPStates,
} from "../../../../store/manualPP/actions";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { manualPPColumn } from "../../../../helpers/columns";
import Datatable from "../../../../components/UI/Tables/Datatable";
import ConfirmationAlert from "../../../../components/UI/ConfirmationAlert";
import Loader from "../../../../components/Common/Loader";
import { getLocalStorage } from "../../../../helpers/utils";
import Delete_icon from "../../../../assets/icons/ebud-icons/Delete.svg";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../../helpers/constants";
import { convertToUAETime } from "../../../../helpers/utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ManualPP = (props) => {
  const auth = getLocalStorage("AUTH_DETAILS");
  const match = useParams();
  let dispatch = useDispatch();
  const [selectedPP, setSelectedPP] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [transactionType, setTransactionType] = useState("credited");
  // const [successMessage, setSuccessMessage] = useState()

  useEffect(() => {
    match.id && props.getAllManualPP({ user_id: match?.id });
  }, [match.id]);

  const deleteConfirmation = (manualPP) => {
    setSelectedPP(manualPP);
    togglePopup();
  };

  const okHandler = () => {
    props.deletepp({ id: selectedPP?.id, user_id: match.id });
  };

  const onCancel = () => {
    setSelectedPP(null);
    togglePopup();
  };

  const togglePopup = () => setOpen((state) => !state);

  useEffect(() => {
    return () => {
      dispatch(resetManualPPStates());
    };
  }, [props.tabNum]);

  const manualPPData = useMemo(() => {
    return props?.manualPPs?.map((manualPP, index) => ({
      ...manualPP,
      no: index + 1,
      transactionType: manualPP?.action,
      addedOn: (
        <span>
          {convertToUAETime(manualPP.time_created, DATE_WITH_TIME_IN_SECONDS)}
        </span>
      ),
      created_by: manualPP.created_by_user_email,
      comments: <p style={{ whiteSpace: "pre-wrap" }}>{manualPP.comments}</p>,
      action: (
        <button
          className=" color-red action-btn"
          title="Delete"
          style={{ border: "none", background: "none", cursor: "pointer" }}
          onClick={() => deleteConfirmation(manualPP)}
        >
          <img src={Delete_icon} alt="Delete" />
        </button>
      ),
    }));
  }, [props.manualPPs]);

  if (props.loading || props.deleteLoading) {
    return <Loader />;
  }
  console.log("props error", props);
  return (
    <Container>
      {(props.error || props.success) && (
        <UncontrolledAlert
          color={props.error ? "danger" : "success"}
          className="alert-dismissible fade show"
          role="alert"
        >
          {props.error || props.success}
        </UncontrolledAlert>
      )}

      <Row>
        <Col md="4" sm="12">
          <Row>
            <Col className="mb-3">
              <p>Manually Credit or Debit FLiX</p>
            </Col>
          </Row>
          <Row className="justify-content-center mb-3" style={{ width: "50%" }}>
            <Col>
              <Form>
                <FormGroup tag="fieldset">
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <Label
                        className="d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                        for="credited"
                        onClick={() => setTransactionType("credited")}
                      >
                        <Input
                          type="radio"
                          id="credited"
                          name="transactionType"
                          checked={transactionType === "credited"}
                          onChange={() => setTransactionType("credited")}
                          style={{
                            accentColor:
                              transactionType === "credited"
                                ? "#B227C1"
                                : "gray",
                            cursor: "pointer",
                          }}
                        />
                        <span
                          className="ms-2 mb-0"
                          style={{
                            fontWeight:
                              transactionType === "credited"
                                ? "bold"
                                : "normal",
                            color:
                              transactionType === "credited"
                                ? "#B227C1"
                                : "black",
                          }}
                        >
                          Credit
                        </span>
                      </Label>
                    </Col>

                    <Col xs={6}>
                      <Label
                        className="d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                        for="debited"
                        onClick={() => setTransactionType("debited")}
                      >
                        <Input
                          type="radio"
                          id="debited"
                          name="transactionType"
                          checked={transactionType === "debited"}
                          onChange={() => setTransactionType("debited")}
                          style={{
                            accentColor:
                              transactionType === "debited"
                                ? "#B227C1"
                                : "gray",
                            cursor: "pointer",
                          }}
                        />
                        <span
                          className="ms-2 mb-0"
                          style={{
                            fontWeight:
                              transactionType === "debited" ? "bold" : "normal",
                            color:
                              transactionType === "debited"
                                ? "#B227C1"
                                : "black",
                          }}
                        >
                          Debit
                        </span>
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </Col>
          </Row>

          <ManualPPForm
            loading={props.loading}
            addLoading={props.addLoading}
            addManualPP={props.addManualPP}
            user={auth.user}
            userId={match.id}
            transactionType={transactionType}
          />
        </Col>
        <Col md="8" sm="12">
          <Datatable
            title="FLiX Credit & Debit History"
            tableID={"ppTable"}
            columns={manualPPColumn}
            rows={manualPPData}
            loading={props.manualPP?.loading}
          />
        </Col>
      </Row>

      <ConfirmationAlert
        content={"Do you want to delete this pp?"}
        modal_center={isOpen}
        title={"Are you sure?"}
        setmodal_center={onCancel}
        onOK={okHandler}
      />
    </Container>
  );
};

const mapStateToProps = (state) => state.manualPP;

export default connect(mapStateToProps, {
  addManualPP,
  getAllManualPP,
  deletepp,
})(ManualPP);
