export const GET_APP_SUBSCRIPTIONS = "GET_APP_SUBSCRIPTIONS";
export const GET_APP_SUBSCRIPTIONS_BEGIN = "GET_APP_SUBSCRIPTIONS_BEGIN";
export const GET_APP_SUBSCRIPTIONS_SUCCESS = "GET_APP_SUBSCRIPTIONS_SUCCESS";
export const GET_APP_SUBSCRIPTIONS_FAIL = "GET_APP_SUBSCRIPTIONS_FAIL";
export const STORE_APP_SUBSCRIPTIONS_REQUEST =
  "STORE_APP_SUBSCRIPTIONS_REQUEST";
export const GET_NEW_SUBSCRIPTIONS = "GET_NEW_SUBSCRIPTIONS";
export const GET_NEW_SUBSCRIPTIONS_BEGIN = "GET_NEW_SUBSCRIPTIONS_BEGIN";
export const GET_NEW_SUBSCRIPTIONS_SUCCESS = "GET_NEW_SUBSCRIPTIONS_SUCCESS";
export const GET_NEW_SUBSCRIPTIONS_FAIL = "GET_NEW_SUBSCRIPTIONS_FAIL";

export const EXPORT_APP_SUBSCRIPTION_DETAILS = "EXPORT_APP_SUBSCRIPTION_DETAILS";
export const EXPORT_APP_SUBSCRIPTION_DETAILS_BEGIN = "EXPORT_APP_SUBSCRIPTION_DETAILS_BEGIN"
export const EXPORT_APP_SUBSCRIPTION_DETAILS_SUCCESS = "EXPORT_APP_SUBSCRIPTION_DETAILS_SUCCESS"
export const EXPORT_APP_SUBSCRIPTION_DETAILS_FAIL = "EXPORT_APP_SUBSCRIPTION_DETAILS_FAIL"