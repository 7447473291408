import { call, put, takeLatest } from "@redux-saga/core/effects";

import { GET_PODIUM_CAMERA_PURCHASE_HISTORY } from "./actionTypes";
import { get } from "../../../helpers/api_helper";
import {
  getPodiumCameraPurchaseHistoryBegin,
  getPodiumCameraPurchaseHistoryFail,
  getPodiumCameraPurchaseHistorySuccess,
} from "./action";

function* getPodiumCameraPurchaseHistorySaga(res) {
  try {
    yield put(getPodiumCameraPurchaseHistoryBegin());
    const response = yield call(
      get,
      `/admin/camerapurchase/history?user_id=${res?.userId}`,
      res.request
    );

    yield put(getPodiumCameraPurchaseHistorySuccess(response.result));
  } catch (error) {
    yield put(getPodiumCameraPurchaseHistoryFail(error));
  }
}

function* podiumCameraPurchaseHistorySaga() {
  yield takeLatest(
    GET_PODIUM_CAMERA_PURCHASE_HISTORY,
    getPodiumCameraPurchaseHistorySaga
  );
}

export default podiumCameraPurchaseHistorySaga;
