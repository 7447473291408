import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import Loader from "../../../components/Common/Loader";
import Breadcrumbs from "../../../components/UI/Breadcrumb";
import Datatable from "../../../components/UI/Tables/Datatable";
import {
  reportedFlashColumnData,
  reportedFlashCommentsColumnData,
} from "../../../helpers/columns";
import { DATE_WITH_TIME_IN_SECONDS } from "../../../helpers/constants";
import { checkUserPermission, convertToUAETime } from "../../../helpers/utils";
import { getFlaxPurchaseHistory } from "../../../store/flaxPackages/actions";
import {
  deletePostat,
  getPostatReportedPosts,
} from "../../../store/postatManagement/actions";
import Block_icon from "../../../assets/icons/ebud-icons/Block.svg";
import Delete_icon from "../../../assets/icons/ebud-icons/Delete.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmationAlert from "../../../components/UI/ConfirmationAlert";

import { blockUser } from "../../../store/users/actions";
import { ImagePreview } from "../../../components/Common/imagePreview";
import {
  deleteFlashes,
  getFlashReportedComment,
  getReportedFlash,
  deleteFlashReportedComment,
} from "../../../store/flashManagement/actions";
import { VideoPreview } from "../../UserMangement/TabSection/Flashes/VideoPreview";

const FlashReportedComments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [imgUrl, setImgUrl] = useState("");
  const [isOpen, setOpen] = useState(false);
  const basicRequest = {
    // sort: "transfered_date",
    // sort_order: "desc",
    page: 1,
    // status_filter: ["active"],
    page_count: 50,
    keyword: "",
  };
  const history = useHistory();
  const [showPromptPopUp, setShowPromptPopUp] = useState(false);
  const [promptMessage, setPromptMessage] = useState({});
  const [selectedRow, setSelectedRow] = useState({});

  const [request, setRequest] = useState({ ...basicRequest });

  const loginData = useSelector((state) => state.Login);

  const [userPermissions, setUserPermissions] = useState({});
  const [flashPermissions, setFlashPermissions] = useState({});
  const isDeleteable = flashPermissions?.delete || false;
  const isBlockable = userPermissions?.edit || false;
  useEffect(() => {
    dispatch(getFlashReportedComment(request));
  }, [request]);
  useEffect(() => {
    if (loginData?.rolePermissions?.role) {
      setFlashPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "Flash Management",
          "Reported flash comments"
        )
      );
      setUserPermissions(
        checkUserPermission(
          loginData?.rolePermissions?.role,
          "User Management",
          "Users"
        )
      );
    }
  }, [loginData]);
  const data = useSelector((state) => state.FlashCategories);

  const previewVideo = (url) => {
    setOpen(true);
    setSelectedRow({ video_url: url });
  };

  const formatReportedComments = useCallback(() => {
    return (
      data?.flashReportedComment &&
      data?.flashReportedComment?.comments?.length > 0 &&
      data?.flashReportedComment?.comments?.map((result, index) => ({
        ...result,
        no: (request?.page - 1) * request?.page_count + index + 1,

        post_thumbnail: (
          <div
            onClick={(e) => {
              e.preventDefault();
              previewVideo(result?.flash?.video_url);
            }}
            title={"click to preview"}
          >
            <img width={28} src={result?.flash?.thumbnail} alt="user" />
          </div>
        ),
        post_owner_name: (
          // <div
          //   className="profileWrap"
          //   title={result?.post_owner_info?.name}
          //   data-tag="allowRowEvents"
          // >
          //   <div className="d-flex">
          //     <div data-tag="allowRowEvents">
          //       <img
          //         className="table_profileImg"
          //         src={result?.post_owner_info?.profile_url}
          //       />
          //     </div>

          //     <div className="mx-2 mt-3" title={result?.name}>
          //       {result?.post_owner_info?.name}{" "}
          //     </div>
          //   </div>
          // </div>
          <>
            <div data-tag="allowRowEvents" title={result?.flash_owner?.name}>
              {result?.flash_owner?.name}
            </div>
            <div
              data-tag="allowRowEvents"
              title={result?.flash_owner?.username}
            >
              {result?.flash_owner?.username}
            </div>
          </>
        ),
        reported_count: (
          <div data-tag="allowRowEvents" title={result?.report_counts}>
            {result?.report_counts}
          </div>
        ),
        reported_comment: (
          <div data-tag="allowRowEvents" title={result?.comment}>
            {result?.comment}
          </div>
        ),
        commenter: (
          <>
            <div data-tag="allowRowEvents" title={result?.sender_details?.name}>
              {result?.sender_details?.name}
            </div>
            <div
              data-tag="allowRowEvents"
              title={result?.sender_details?.username}
            >
              {result?.sender_details?.username}
            </div>
            {result?.sender_details?.user_blocked && (
              <div className="badge bg-soft-secondary font-size-6 my-auto ">
                Blocked user
              </div>
            )}
          </>
        ),
        actions: (
          <div>
            <div className="cust-table-actions-wrap">
              {!result?.sender_details?.user_blocked && (
                <button
                  onClick={() =>
                    blockPromptHandler(result?.sender_details?.id, result.id)
                  }
                  className=" action-btn"
                  style={{
                    fontSize: "11px",
                    padding: "5px",
                    backgroundColor: "#dedfe0",
                    color: "#969696",
                    fontWeight: "bold",
                    opacity: !isDeleteable || !isBlockable ? "50%" : "",
                    cursor: !isDeleteable || !isBlockable ? "not-allowed" : "",
                  }}
                  title="Block Owner"
                  disabled={!isDeleteable || !isBlockable}
                >
                  BLOCK AND DELETE
                </button>
              )}

              <button
                onClick={() => deletePromptHandler(result?.id)}
                className=" action-btn"
                title="Delete Comment"
                disabled={!isDeleteable}
                style={{
                  fontSize: "11px",
                  padding: "5px",
                  backgroundColor: "#fadede",
                  fontWeight: "bold",
                  color: "#fa5f5f",
                  opacity: !isDeleteable ? "50%" : "",
                  cursor: !isDeleteable ? "not-allowed" : "",
                }}
              >
                DELETE
              </button>
            </div>
          </div>
        ),
      }))
    );
  }, [request, data?.flashReportedComment?.comments]);
  const reportedComments = useMemo(
    () => formatReportedComments(),
    [formatReportedComments]
  );

  const deletePromptHandler = (id) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      commentId: id,
      content: `Are you sure you want to delete this Comment from the App?`,
      type: "delete",
    });
  };

  const blockPromptHandler = (userId, postId) => {
    setShowPromptPopUp(!showPromptPopUp);
    setPromptMessage({
      userId: userId,
      commentId: postId,
      content: `Are you sure you want to block this user and delete the comment from the Video? `,
      type: "block-del",
    });
  };

  const okHandler = () => {
    promptMessage.type === "delete"
      ? dispatch(
          deleteFlashReportedComment({
            id: promptMessage?.commentId,

            callback: () => dispatch(getFlashReportedComment(request)),
          })
        )
      : dispatch(
          blockUser({
            data: { blocked: true },
            id: promptMessage?.userId,
          })
        ) &&
        dispatch(
          deleteFlashReportedComment({
            id: promptMessage?.commentId,

            callback: () => dispatch(getFlashReportedComment(request)),
          })
        );
  };

  const tableRowClickHandler = (param) => {
    // history.push(`/payout-requests/${param.id}`);
    history.push({
      pathname: `/flash-reported-comments/${param.id}`,
      state: { commentId: param.id },
    });
  };

  return (
    <>
      <div
        className="page-content"
        data-testid="component-usermgmt"
        id="userMgmtList"
        // id="userMgmtList"
        // ref={pageWrapRef}
      >
        <Container id="userManagementWrapper" data-testid="userMgmtWrap">
          <Row>
            <Breadcrumbs title="Tables" breadcrumbItem="Reported Comments" />
            <div className="container-fluid">
              <Datatable
                tableID={"userTable"}
                columns={reportedFlashCommentsColumnData}
                rows={reportedComments}
                setRequest={setRequest}
                request={request}
                // dateFilter
                totalRecords={data?.flashReportedComment?.total}
                // search
                // dateType="Purchased Date Range"
                // add
                // dropDown
                // languages={languages}
                // addNewClickHandler={addNewClickHandler}
                // selectedLanguage={selectedLanguage}
                // setSelectedLanguage={setSelectedLanguage}
                // addNewLabel={t("Admins.addAdmin.addBtn")}
                // searchTerm={data?.request?.keyword}
                // loading={data?.loading}
                tableRowClicked={(param) => tableRowClickHandler(param)}
                tableCardClassName={"snoTable"}
              />
            </div>
          </Row>
          <VideoPreview
            selectedRow={selectedRow}
            isOpen={isOpen}
            setOpen={setOpen}
          />
          {/* <ImagePreview
            imgUrl={imgUrl}
            isOpen={isOpen}
            setOpen={setOpen}
            title="Reported Post"
          /> */}
        </Container>
        <ConfirmationAlert
          {...promptMessage}
          modal_center={showPromptPopUp}
          setmodal_center={setShowPromptPopUp}
          onOK={okHandler}
        />

        {data?.loading && <Loader darkBg={true} />}
      </div>
    </>
  );
};

export default FlashReportedComments;
